import { autoinject } from 'aurelia-framework';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { configureHooks } from '../../hooks/configureHooks';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { watch } from '../../hooks/watch';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { ChecklistProjectNameUtils } from 'common/Checklist/ChecklistProjectNameUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SocketService } from '../../services/SocketService';
import { ExportKukZipResponse } from 'common/EndpointTypes/ExportKukZipEndpointsHandler';
import { ExportType } from 'common/EndpointTypes/OperationsExportEndpointsHandler';
import { Dialogs } from '../../classes/Dialogs';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class KukExportDialog {
  protected dialog: RecordItDialog | null = null;
  protected thing: Thing | null = null;
  protected onExportStarted: ((reportId: string) => void) | null = null;

  protected defectsToExport: Array<Defect> = [];
  protected projectsToExport: Array<Project> = [];
  protected checklistProjectsToExport: Array<Project> = [];
  protected selectedDefectReportTypeId: string | null = null;
  protected selectedChecklistProjectReportTypeId: string | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService
  ) {}

  public static async open(options: DialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: DialogOptions): void {
    this.thing = options.thing;
    this.onExportStarted = options.onExportStarted ?? null;
    this.defectsToExport = [];
    this.projectsToExport = [];
    this.checklistProjectsToExport = [];
    this.selectedDefectReportTypeId = null;
    this.selectedChecklistProjectReportTypeId = null;
    this.dialog?.open();
  }

  // Needed for unmounting hooks
  public handleDialogClosed(): void {
    this.thing = null;
    this.onExportStarted = null;
  }

  protected async handleAcceptButtonClicked(): Promise<void> {
    const response = await new Promise<ExportKukZipResponse>((res) => {
      assertNotNullOrUndefined(
        this.thing,
        'cannot handleAcceptButtonClicked without thing'
      );
      assertNotNullOrUndefined(
        this.selectedDefectReportTypeId,
        'cannot handleAcceptButtonClicked without selectedDefectReportTypeId'
      );
      assertNotNullOrUndefined(
        this.selectedChecklistProjectReportTypeId,
        'cannot handleAcceptButtonClicked without selectedChecklistProjectReportTypeId'
      );

      this.socketService.exportKukZipFile(
        {
          thingId: this.thing.id,
          checklistProjectIds: this.checklistProjectsToExport.map((i) => i.id),
          defectIds: this.defectsToExport.map((i) => i.id),
          projectIds: this.projectsToExport.map((i) => i.id),
          defectReportTypeId: this.selectedDefectReportTypeId,
          checklistProjectReportTypeId:
            this.selectedChecklistProjectReportTypeId,
          exportType: ExportType.PDF
        },
        res
      );
    });

    if (response.success) {
      this.onExportStarted?.(response.reportId);
    } else {
      void Dialogs.errorDialog('Failed to export', String(response.error));
    }
  }

  /**
   * Adds `entity` to `arr` if `available === true`,
   * and removes `entity` from `arr` if `available === false`.
   */
  private toggleEntityInList<T>(
    available: boolean,
    arr: Array<T>,
    entity: T
  ): void {
    if (available) {
      ArrayUtils.pushUnique(arr, entity);
    } else {
      ArrayUtils.remove(arr, entity);
    }
  }

  protected handleDefectCheckChanged(
    defect: Defect,
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    this.toggleEntityInList(event.detail.checked, this.defectsToExport, defect);
  }

  protected handleProjectCheckChanged(
    project: Project,
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    this.toggleEntityInList(
      event.detail.checked,
      this.projectsToExport,
      project
    );
  }

  protected handleChecklistProjectCheckChanged(
    checklistProject: Project,
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    this.toggleEntityInList(
      event.detail.checked,
      this.checklistProjectsToExport,
      checklistProject
    );
  }

  protected getChecklistProjectName(projectName: string): string {
    return ChecklistProjectNameUtils.toDateString(projectName);
  }

  @computed(
    expression('selectedChecklistProjectReportTypeId'),
    expression('selectedDefectReportTypeId'),
    expression('projectsToExport.length'),
    expression('checklistProjectsToExport.length'),
    expression('defectsToExport.length')
  )
  private get canClickAcceptButton(): boolean {
    return (
      !!this.selectedChecklistProjectReportTypeId &&
      !!this.selectedDefectReportTypeId &&
      (this.projectsToExport.length > 0 ||
        this.checklistProjectsToExport.length > 0 ||
        this.defectsToExport.length > 0)
    );
  }

  @computed(expression('canClickAcceptButton'))
  protected get disabledButtons(): Array<string> {
    return this.canClickAcceptButton ? [] : ['accept'];
  }

  @computed(expression('thing'), model(EntityName.Defect))
  protected get defects(): Array<Defect> {
    if (!this.thing) return [];
    return this.entityManager.defectRepository.getByOwnerThingId(this.thing.id);
  }

  @watch(expression('defects'))
  protected updateDefectsToExport(): void {
    this.defectsToExport = this.defects;
  }

  @computed(expression('thing'), model(EntityName.Project))
  protected get projects(): Array<Project> {
    if (!this.thing) return [];
    return this.entityManager.projectRepository
      .getByThingIdAndProjectType(this.thing.id, ProjectType.BASIC)
      .filter((p) => !p.archived);
  }

  @watch(expression('projects'))
  protected updateProjectsToExport(): void {
    this.projectsToExport = this.projects;
  }

  @computed(expression('thing'), model(EntityName.Project))
  protected get checklistProjects(): Array<Project> {
    if (!this.thing) return [];
    return this.entityManager.projectRepository.getByThingIdAndProjectType(
      this.thing.id,
      ProjectType.CHECKLIST
    );
  }

  @watch(expression('checklistProjects'))
  protected updateChecklistProjectsToExport(): void {
    this.checklistProjectsToExport = this.checklistProjects;
  }
}

type DialogOptions = {
  /**
   * The thing to export as a KuK .zip file.
   */
  thing: Thing;
  onExportStarted?: (reportId: string) => void;
};
