import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntryRecursiveFilter } from '../../classes/EntityManager/entities/Entry/EntryRecursiveFilter';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { EntriesByParentEntryIdHandle } from '../../computedValues/computers/EntriesByParentEntryIdComputer/EntriesByParentEntryIdComputer';

export class EntryFilterFilterer {
  constructor(
    private readonly entityManager: AppEntityManager,
    private showMode: ShowMode,
    private filterMode: FilterMode,
    private filterString: string
  ) {}

  public filterEntries({
    entries,
    entriesByParentEntryIdHandle
  }: {
    entries: Array<Entry>;
    entriesByParentEntryIdHandle: EntriesByParentEntryIdHandle;
  }): Array<Entry> {
    switch (this.showMode) {
      case ShowMode.NON_EMPTY:
        return this.applyFilters({
          entries,
          filters: [[this.isNotEmpty.bind(this)]],
          entriesByParentEntryIdHandle
        });

      case ShowMode.EMPTY:
        return this.applyFilters({
          entries,
          filters: [[this.isEmpty.bind(this), this.hasNoStatusSet.bind(this)]],
          entriesByParentEntryIdHandle
        });

      case ShowMode.EDITED:
        return this.applyFilters({
          entries,
          filters: [
            [this.hasStatusSet.bind(this)],
            [this.isNotEmpty.bind(this)]
          ],
          entriesByParentEntryIdHandle
        });

      case ShowMode.EDITED_BUT_EMPTY:
        return this.applyFilters({
          entries,
          filters: [[this.hasStatusSet.bind(this)]],
          entriesByParentEntryIdHandle
        });

      case ShowMode.ALL:
      default:
        return this.applyFilters({
          entries,
          filters: [[]],
          entriesByParentEntryIdHandle
        });
    }
  }

  private applyFilters({
    entries,
    filters,
    entriesByParentEntryIdHandle
  }: {
    entries: Array<Entry>;
    filters: Array<Array<(context: FilterContext) => boolean>>;
    entriesByParentEntryIdHandle: EntriesByParentEntryIdHandle;
  }): Array<Entry> {
    const filter = new EntryRecursiveFilter();
    return filter.filter({
      entries,
      entryConditionCallback: (entry, remainingChildren, children) => {
        return (
          filters.some((or) =>
            or.every((and) => and({ entry, children, remainingChildren }))
          ) &&
          [
            this.filterByEntryName.bind(this),
            this.filterByPictureDescription.bind(this)
          ].some((or) =>
            or({
              entry,
              children,
              remainingChildren,
              filterString: this.filterString
            })
          )
        );
      },
      entriesByParentEntryIdHandle
    });
  }

  private isNotEmpty({ entry, remainingChildren }: FilterContext): boolean {
    if (remainingChildren.length > 0) {
      return true;
    }

    switch (this.filterMode) {
      case FilterMode.STRUCTURE_TEMPLATE_ENTRY_ID:
        if (entry.structureTemplateEntryId || entry.originId) {
          return false;
        }

        return true;

      case FilterMode.PICTURE:
      default:
        if (
          this.entityManager.pictureRepository.getByEntryId(entry.id).length > 0
        ) {
          return true;
        }

        return false;
    }
  }

  private isEmpty({
    entry,
    children,
    remainingChildren
  }: FilterContext): boolean {
    if (remainingChildren.length > 0) {
      return true;
    }

    switch (this.filterMode) {
      case FilterMode.STRUCTURE_TEMPLATE_ENTRY_ID:
        if (entry.structureTemplateEntryId || entry.originId) {
          const hasOnlyOriginChildren = children.every(
            (i) => !!i.structureTemplateEntryId || !!i.originId
          );
          return hasOnlyOriginChildren;
        }

        return false;

      case FilterMode.PICTURE:
      default:
        if (
          this.entityManager.pictureRepository.getByEntryId(entry.id).length ===
          0
        ) {
          return true;
        }

        return false;
    }
  }

  private hasStatusSet({ entry, remainingChildren }: FilterContext): boolean {
    switch (this.filterMode) {
      case FilterMode.STRUCTURE_TEMPLATE_ENTRY_ID:
        if (remainingChildren.length) {
          return true;
        }

        if (entry.structureTemplateEntryId || entry.originId) {
          const statusProperty = this.entityManager.propertyRepository
            .getByEntryId(entry.id)
            .find((p) => p.name === 'Status');
          const hasSetStatus = !!(statusProperty && statusProperty.value);

          return hasSetStatus;
        }

        return false;

      case FilterMode.PICTURE:
      default:
        return false;
    }
  }

  private hasNoStatusSet({ entry }: FilterContext): boolean {
    switch (this.filterMode) {
      case FilterMode.STRUCTURE_TEMPLATE_ENTRY_ID:
        if (entry.structureTemplateEntryId || entry.originId) {
          const statusProperty = this.entityManager.propertyRepository
            .getByEntryId(entry.id)
            .find((p) => p.name === 'Status');
          const hasSetStatus = !!(statusProperty && statusProperty.value);

          return !hasSetStatus;
        }

        return true;

      case FilterMode.PICTURE:
      default:
        return true;
    }
  }

  private filterByEntryName({
    entry,
    remainingChildren,
    filterString
  }: FilterByStringContext): boolean {
    if (!filterString) return true;

    if (remainingChildren.length > 0) return true;

    if (!entry.name) return false;

    const filterStringUpperCase = filterString.toUpperCase();
    const entryNameUpperCase = entry.name.toUpperCase();

    return !!(entryNameUpperCase.indexOf(filterStringUpperCase) > -1);
  }

  private filterByPictureDescription({
    entry,
    remainingChildren,
    filterString
  }: FilterByStringContext): boolean {
    if (!filterString) return true;

    if (remainingChildren.length > 0) return true;

    const pictures = this.entityManager.pictureRepository.getByEntryId(
      entry.id
    );
    if (pictures.length === 0) return false;

    const filterStringUpperCase = filterString.toUpperCase();

    return pictures.some((p) => {
      const pictureDescriptionUpperCase = p.description?.toUpperCase();
      if (!pictureDescriptionUpperCase) return false;

      return pictureDescriptionUpperCase.indexOf(filterStringUpperCase) > -1;
    });
  }
}

export enum ShowMode {
  /**
   * get all entries (actually doesn't filter anything)
   */
  ALL = 'all',

  /**
   * return all entries which are not empty
   * which entry is empty or not is defined in the filter mode
   */
  NON_EMPTY = 'non-empty',

  /**
   * return all entries which are empty
   * which entry is empty or not is defined in the filter mode
   */
  EMPTY = 'empty',

  /** only for structure projects */

  EDITED = 'edited',
  EDITED_BUT_EMPTY = 'edited_but_empty'
}

export enum FilterMode {
  /**
   * an entry is not empty when it or one of it's descendants has a picture
   */
  PICTURE = 'picture',

  /**
   * an entry is not empty when it or one of it's descendants has no structureTemplateEntryId/originId
   * useful for filtering for automatically created structures, like in the b1300
   */
  STRUCTURE_TEMPLATE_ENTRY_ID = 'structureTemplateEntryId'
}

type FilterContext = {
  entry: Entry;
  children: Array<Entry>;
  remainingChildren: Array<Entry>;
};

type FilterByStringContext = FilterContext & {
  filterString: string;
};
