import { PromiseContainer } from 'common/PromiseContainer/PromiseContainer';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { ErrorUtils } from 'common/Utils/ErrorUtils/ErrorUtils';
import { BaseEntity } from '../EntityManager/entities/BaseEntity';

export class JoinEntityQueue {
  private readonly sendJoinEntityRequest: SendJoinEntityRequest;
  private readonly sendLeaveEntityRequest: SendLeaveEntityRequest;
  private readonly onEntityActivelyJoined: OnEntityActivelyJoined;
  private readonly activelyJoinedEntityIds = new Set<string>();

  private readonly entityIdToQueue = new Map<string, EntitySpecificQueue>();

  private isStarted: boolean = false;

  constructor(options: JoinEntityQueueOptions) {
    this.sendJoinEntityRequest = options.sendJoinEntityRequest;
    this.sendLeaveEntityRequest = options.sendLeaveEntityRequest;
    this.onEntityActivelyJoined = options.onEntityActivelyJoined;
  }

  public start(): void {
    this.isStarted = true;
  }

  public stop(): void {
    this.isStarted = false;
    this.activelyJoinedEntityIds.clear();
    this.entityIdToQueue.clear();
  }

  public async entityIdUpgraded({
    entity
  }: {
    entity: BaseEntity;
  }): Promise<void> {
    const promises = entity.originalIds
      .map<Array<Promise<void>>>((originalId) => {
        const entityWillBeJoined = this.checkIfLastQueueItemIsOfCertainType({
          entityId: originalId,
          type: QueueItemType.JOIN
        });

        if (
          !this.activelyJoinedEntityIds.has(originalId) &&
          !entityWillBeJoined
        ) {
          return [];
        }

        return [this.leaveEntity(originalId), this.joinEntity(entity.id)];
      })
      .flat();

    await Promise.all(promises);
  }

  public joinEntity(entityId: string): JoinOrLeaveEntityPromise {
    const entityWillBeLeft = this.checkIfLastQueueItemIsOfCertainType({
      entityId,
      type: QueueItemType.LEAVE
    });

    if (
      !this.isStarted ||
      (this.entityIsActivelyJoined(entityId) && !entityWillBeLeft)
    ) {
      return this.createResolvedJoinOrLeaveEntityPromise();
    }

    const { queueItem, queuedNewItem } = this.addItemToQueueAndAutoStart({
      entityId,
      type: QueueItemType.JOIN
    });

    return this.createJoinOrLeaveEntityPromiseFromWorker({
      queuedNewItem,
      worker: async () => {
        await queueItem.promiseContainer.create();

        if (queuedNewItem) {
          this.activelyJoinedEntityIds.add(entityId);
          this.onEntityActivelyJoined(entityId);
        }
      }
    });
  }

  public leaveEntity(entityId: string): JoinOrLeaveEntityPromise {
    const entityWillBeJoined = this.checkIfLastQueueItemIsOfCertainType({
      entityId,
      type: QueueItemType.JOIN
    });

    if (
      !this.isStarted ||
      (!this.entityIsActivelyJoined(entityId) && !entityWillBeJoined)
    ) {
      return this.createResolvedJoinOrLeaveEntityPromise();
    }

    const { queueItem, queuedNewItem } = this.addItemToQueueAndAutoStart({
      entityId,
      type: QueueItemType.LEAVE
    });

    return this.createJoinOrLeaveEntityPromiseFromWorker({
      queuedNewItem,
      worker: async () => {
        await queueItem.promiseContainer.create();
        this.activelyJoinedEntityIds.delete(entityId);
      }
    });
  }

  public entityIsActivelyJoined(entityId: string): boolean {
    return this.activelyJoinedEntityIds.has(entityId);
  }

  public getActivelyJoinedEntityIds(): Array<string> {
    return Array.from(this.activelyJoinedEntityIds.values());
  }

  public isBusy(): boolean {
    for (const queue of this.entityIdToQueue.values()) {
      if (queue.isRunning) {
        return true;
      }
    }

    return false;
  }

  private addItemToQueueAndAutoStart({
    entityId,
    type
  }: {
    entityId: string;
    type: QueueItemType;
  }): {
    queue: EntitySpecificQueue;
    queueItem: QueueItem;
    queuedNewItem: boolean;
  } {
    const queueAndItem = this.addItemToQueue({ entityId, type });

    if (!queueAndItem.queue.isRunning) {
      void this.runQueue({ queue: queueAndItem.queue });
    }

    return queueAndItem;
  }

  private addItemToQueue({
    entityId,
    type
  }: {
    entityId: string;
    type: QueueItemType;
  }): {
    queue: EntitySpecificQueue;
    queueItem: QueueItem;
    queuedNewItem: boolean;
  } {
    let queue = this.entityIdToQueue.get(entityId);

    if (!queue) {
      queue = {
        entityId,
        isRunning: false,
        items: []
      };

      this.entityIdToQueue.set(entityId, queue);
    }

    const queueItem: QueueItem = {
      type,
      promiseContainer: new PromiseContainer(),
      isBeingSent: false
    };

    this.removeUnnecessaryItemsFromQueue({
      queue,
      queueItemThatWillBeAdded: queueItem
    });

    return this.addItemToQueueOrReuseExistingItem({
      queue,
      queueItem
    });
  }

  /**
   * removes itemes which are unnecessary because the next item wil override it anyway
   */
  private removeUnnecessaryItemsFromQueue({
    queue,
    queueItemThatWillBeAdded
  }: {
    queue: EntitySpecificQueue;
    queueItemThatWillBeAdded: QueueItem;
  }): void {
    const reversedItems = queue.items.slice().reverse();

    for (const item of reversedItems) {
      if (item.isBeingSent || item.type === queueItemThatWillBeAdded.type) {
        break;
      }

      queue.items.pop();
      item.promiseContainer.resolvePermanent();
    }
  }

  private addItemToQueueOrReuseExistingItem({
    queue,
    queueItem
  }: {
    queue: EntitySpecificQueue;
    queueItem: QueueItem;
  }): {
    queue: EntitySpecificQueue;
    queueItem: QueueItem;
    queuedNewItem: boolean;
  } {
    const lastItem = queue.items.at(-1);

    if (lastItem?.type === queueItem.type) {
      return {
        queue,
        queueItem: lastItem,
        queuedNewItem: false
      };
    }

    queue.items.push(queueItem);
    return { queue, queueItem, queuedNewItem: true };
  }

  private async runQueue({
    queue
  }: {
    queue: EntitySpecificQueue;
  }): Promise<void> {
    queue.isRunning = true;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const queueItem = queue.items[0];
      if (!queueItem) {
        break;
      }

      queueItem.isBeingSent = true;

      const sendRequestResult = await this.sendRequestForItem({
        queue,
        queueItem
      });

      if (!this.isStarted) {
        queueItem.promiseContainer.rejectPermanent(new Error('queue stopped'));
        queueItem.isBeingSent = false;
        break;
      }

      ArrayUtils.remove(queue.items, queueItem);
      queueItem.isBeingSent = false;

      if (sendRequestResult.success) {
        queueItem.promiseContainer.resolvePermanent();
      } else {
        queueItem.promiseContainer.rejectPermanent(sendRequestResult.error);
      }
    }

    queue.isRunning = false;
  }

  private async sendRequestForItem({
    queue,
    queueItem
  }: {
    queue: EntitySpecificQueue;
    queueItem: QueueItem;
  }): Promise<{ success: true } | { success: false; error: Error }> {
    try {
      switch (queueItem.type) {
        case QueueItemType.JOIN:
          await this.sendJoinEntityRequest(queue.entityId);
          break;

        case QueueItemType.LEAVE:
          await this.sendLeaveEntityRequest(queue.entityId);
          break;

        default:
          throw new Error(`unhandled queueItemType "${queueItem.type}"`);
      }

      return {
        success: true
      };
    } catch (error) {
      return {
        success: false,
        error: ErrorUtils.convertUnknownToError(error)
      };
    }
  }

  private checkIfLastQueueItemIsOfCertainType({
    entityId,
    type
  }: {
    entityId: string;
    type: QueueItemType;
  }): boolean {
    const queue = this.entityIdToQueue.get(entityId);
    const lastItem = queue?.items.at(-1);

    return lastItem?.type === type;
  }

  private createResolvedJoinOrLeaveEntityPromise(): JoinOrLeaveEntityPromise {
    const promise = Promise.resolve() as JoinOrLeaveEntityPromise;

    promise.queuedNewItem = false;

    return promise;
  }

  private createJoinOrLeaveEntityPromiseFromWorker({
    queuedNewItem,
    worker
  }: {
    queuedNewItem: boolean;
    worker: () => Promise<void>;
  }): JoinOrLeaveEntityPromise {
    const promise = worker() as JoinOrLeaveEntityPromise;

    promise.queuedNewItem = queuedNewItem;

    return promise;
  }
}

export type JoinEntityQueueOptions = {
  sendJoinEntityRequest: SendJoinEntityRequest;
  sendLeaveEntityRequest: SendLeaveEntityRequest;
  onEntityActivelyJoined: OnEntityActivelyJoined;
};

export type SendJoinEntityRequest = (entityId: string) => Promise<void>;
export type SendLeaveEntityRequest = (entityId: string) => Promise<void>;
export type OnEntityActivelyJoined = (entityId: string) => void;

export type JoinOrLeaveEntityPromise = Promise<void> & {
  queuedNewItem: boolean;
};

type EntitySpecificQueue = {
  entityId: string;
  isRunning: boolean;
  items: Array<QueueItem>;
};

type QueueItem = {
  type: QueueItemType;
  promiseContainer: PromiseContainer<void>;
  isBeingSent: boolean;
};

enum QueueItemType {
  JOIN = 'join',
  LEAVE = 'leave'
}
