import { autoinject } from 'aurelia-framework';

import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { expression, model } from '../../hooks/dependencies';
import { computed } from '../../hooks/computed';
import {
  GlobalUserDefinedEntity,
  ProjectUserDefinedEntity,
  ThingUserDefinedEntity,
  UserDefinedEntity
} from '../../classes/EntityManager/entities/UserDefinedEntity/types';
import { UserDefinedEntityUtils } from '../../classes/EntityManager/entities/UserDefinedEntity/UserDefinedEntityUtils';
import { FilterHelper } from '../../classes/FilterHelper';
import { SorterSortOption } from '../../aureliaAttributes/sorter';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SelectableItemList } from '../../aureliaComponents/selectable-item-list/selectable-item-list';
import { UserDefinedEntityListItem } from '../../aureliaComponents/user-defined-entity-list-item/user-defined-entity-list-item';
import { configureHooks } from '../../hooks/configureHooks';
import { UserDefinedEntityConfig } from '../../classes/EntityManager/entities/UserDefinedEntityConfig/types';
import { UserDefinedEntityConfigPropertyConfigCustomColumn } from 'common/Types/Entities/UserDefinedEntityConfigPropertyConfig/UserDefinedEntityConfigPropertyConfigDto';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class CopyUserDefinedEntityDialog<
  TBaseEntity extends SupportedUserDefinedEntityBaseEntity
> {
  protected dialog: RecordItDialog | null = null;

  protected selectableItemsListViewModel: SelectableItemList<
    UserDefinedEntity,
    UserDefinedEntityListItem
  > | null = null;

  private onDialogClosed: TOnDialogClosedCallback | null = null;
  private onCreateNewEntitiesCallback: OnCreateNewUserDefinedEntitiesCallback<TBaseEntity> | null =
    null;

  private baseEntity: TBaseEntity | null = null;

  private subscriptionManager: SubscriptionManager;

  private sortOptions = UserDefinedEntityUtils.sortOptions;

  protected availableUserDefinedEntities: Array<
    SupportedTemplateUserDefinedEntity<TBaseEntity>
  > = [];

  protected sortedUserDefinedEntities: Array<
    SupportedTemplateUserDefinedEntity<TBaseEntity>
  > = [];

  protected selectedUserDefinedEntities: Array<
    SupportedTemplateUserDefinedEntity<TBaseEntity>
  > = [];

  protected configFilteredUserDefinedEntities: Array<
    SupportedTemplateUserDefinedEntity<TBaseEntity>
  > = [];

  protected filteredUserDefinedEntities: Array<
    SupportedTemplateUserDefinedEntity<TBaseEntity>
  > = [];

  protected prefilteredByEntityConfigName: string | null = null;

  private filterString: string = '';
  private selectedUserDefinedEntityConfig: UserDefinedEntityConfig | null =
    null;

  protected currentSortOption: SorterSortOption<UserDefinedEntity> =
    this.sortOptions.name;

  private entityManager: AppEntityManager | null = null;

  constructor(subscriptionManagerService: SubscriptionManagerService) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public open(options: CopyUserDefinedEntityDialogOptions<TBaseEntity>): void {
    this.entityManager = options.entityManager;
    this.onDialogClosed = options.onDialogClosed || null;
    this.onCreateNewEntitiesCallback = options.onCreateNewEntities || null;
    this.baseEntity = options.baseEntity ?? null;
    this.availableUserDefinedEntities = options.availableUserDefinedEntities;
    this.prefilteredByEntityConfigName = options.prefilteredByEntityConfigName;

    this.dialog?.open();
  }

  public static async open<T extends SupportedUserDefinedEntityBaseEntity>(
    options: CopyUserDefinedEntityDialogOptions<T>
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    // @ts-ignore cannot properly assume class template parameter in static function
    view.getViewModel().open(options);
  }

  @computed(
    model(EntityName.UserDefinedEntity),
    expression('filterString'),
    expression('availableUserDefinedEntities')
  )
  protected get searchFilteredUserDefinedEntities(): Array<UserDefinedEntity> {
    return FilterHelper.filterItems(
      this.availableUserDefinedEntities,
      (entity) => entity.name + ' ' + (entity.customId ?? ''),
      this.filterString
    );
  }

  protected handleDialogClosed(): void {
    this.resetFilters();
    const onDialogClosed = this.onDialogClosed;
    this.onDialogClosed = null;

    this.subscriptionManager.disposeSubscriptions();

    this.selectableItemsListViewModel?.resetSelectionEnabled();
    this.entityManager = null;
    this.prefilteredByEntityConfigName = null;
    onDialogClosed?.();
  }

  protected handleAcceptButtonClicked(): void {
    if (!this.selectedUserDefinedEntities.length) return;
    assertNotNullOrUndefined(
      this.baseEntity,
      'cannot create new user-defined entities without set base entity'
    );
    assertNotNullOrUndefined(
      this.onCreateNewEntitiesCallback,
      'cannot create new user-defined entities without creation callback'
    );

    for (const selectedUserDefinedEntity of this.selectedUserDefinedEntities) {
      this.onCreateNewEntitiesCallback(
        selectedUserDefinedEntity,
        this.baseEntity
      );
    }
  }

  protected handleUserDefinedEntityDetailActionClick(
    userDefinedEntity: SupportedTemplateUserDefinedEntity<TBaseEntity>
  ): void {
    assertNotNullOrUndefined(
      this.baseEntity,
      'cannot create new user-defined entities without set base entity'
    );
    assertNotNullOrUndefined(
      this.onCreateNewEntitiesCallback,
      'cannot create new user-defined entities without creation callback'
    );

    this.onCreateNewEntitiesCallback(userDefinedEntity, this.baseEntity);
    this.removeEntityTemplateFromAvailableEntities(userDefinedEntity);
  }

  @computed(
    expression('filteredUserDefinedEntities'),
    model(EntityName.UserDefinedEntityConfigPropertyConfig),
    model(EntityName.UserDefinedEntityConfig),
    expression('entityManager')
  )
  protected get firstCustomColumnName(): string | null {
    // no assertion, because if the dialog is opened for second time, the getter fires before entityManager is available
    if (!this.entityManager) return null;
    return UserDefinedEntityUtils.getCustomColumnName({
      filteredUserDefinedEntities: this.filteredUserDefinedEntities,
      entityManager: this.entityManager,
      customColumnType: UserDefinedEntityConfigPropertyConfigCustomColumn.FIRST
    });
  }

  @computed(
    expression('filteredUserDefinedEntities'),
    model(EntityName.UserDefinedEntityConfigPropertyConfig),
    model(EntityName.UserDefinedEntityConfig),
    expression('entityManager')
  )
  protected get secondCustomColumnName(): string | null {
    // no assertion, because if the dialog is opened for second time, the getter fires before entityManager is available
    if (!this.entityManager) return null;
    return UserDefinedEntityUtils.getCustomColumnName({
      filteredUserDefinedEntities: this.filteredUserDefinedEntities,
      entityManager: this.entityManager,
      customColumnType: UserDefinedEntityConfigPropertyConfigCustomColumn.SECOND
    });
  }

  protected get showCustomColumns(): boolean {
    return UserDefinedEntityUtils.allShownEntitiesBelongToSameEntityConfig({
      filteredUserDefinedEntities: this.filteredUserDefinedEntities
    });
  }

  private removeEntityTemplateFromAvailableEntities(
    entityTemplate: SupportedTemplateUserDefinedEntity<TBaseEntity>
  ): void {
    const index = this.availableUserDefinedEntities.indexOf(entityTemplate);
    if (index === -1) {
      throw new Error(
        `tried to remove userDefinedEntity id ${entityTemplate.id} from available entities to copy, but entity was not in array of available entities.`
      );
    }
    this.availableUserDefinedEntities.splice(index, 1);
  }

  private resetFilters(): void {
    this.filterString = '';
    this.selectedUserDefinedEntityConfig = null;
  }
}

type CopyUserDefinedEntityDialogOptions<
  T extends SupportedUserDefinedEntityBaseEntity
> = {
  baseEntity: T;
  availableUserDefinedEntities: Array<SupportedTemplateUserDefinedEntity<T>>;
  onCreateNewEntities: OnCreateNewUserDefinedEntitiesCallback<T>;
  onDialogClosed?: TOnDialogClosedCallback | null;
  entityManager: AppEntityManager;
  prefilteredByEntityConfigName: string | null;
};

type TOnDialogClosedCallback = () => void;

export type OnCreateNewUserDefinedEntitiesCallback<
  T extends SupportedUserDefinedEntityBaseEntity
> = (
  entityTemplate: SupportedTemplateUserDefinedEntity<T>,
  baseEntity: T
) => void;

export type SupportedUserDefinedEntityBaseEntity = Thing | Project;

export type SupportedTemplateUserDefinedEntity<
  T extends SupportedUserDefinedEntityBaseEntity
> = T extends Thing
  ? GlobalUserDefinedEntity
  : T extends Project
    ? GlobalUserDefinedEntity | ThingUserDefinedEntity
    : never;

export type UserDefinedEntityOfBaseEntity<
  T extends SupportedUserDefinedEntityBaseEntity
> = T extends Thing
  ? ThingUserDefinedEntity
  : T extends Project
    ? ProjectUserDefinedEntity
    : never;
