import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { processTaskPositionDetailEntryEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/processTaskPositionDetailEntryEntityInfo';
import { ProcessTaskPositionDetailEntry } from '../../../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { ProcessTaskPositionAdapter } from '../ProcessTaskPositionAdapter/ProcessTaskPositionAdapter';

export class ProcessTaskPositionDetailEntryAdapter
  implements EntityAdapter<ProcessTaskPositionDetailEntry>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private processTaskPositionAdapter: ProcessTaskPositionAdapter | null = null;

  constructor(options: ProcessTaskPositionDetailEntryAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.ProcessTaskPosition,
        onNextAdapter: ({ adapter }) => {
          this.processTaskPositionAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return this.processTaskPositionAdapter != null;
  }

  public canEditField(
    processTaskPositionDetailEntry: ProcessTaskPositionDetailEntry
  ): boolean {
    return this.canEditProcessTaskPositionDetailEntry(
      processTaskPositionDetailEntry
    );
  }

  public canDeleteEntity(
    processTaskPositionDetailEntry: ProcessTaskPositionDetailEntry
  ): boolean {
    return this.canEditProcessTaskPositionDetailEntry(
      processTaskPositionDetailEntry
    );
  }

  public canEditProperties(
    processTaskPositionDetailEntry: ProcessTaskPositionDetailEntry
  ): boolean {
    return this.canEditProcessTaskPositionDetailEntry(
      processTaskPositionDetailEntry
    );
  }

  private canEditProcessTaskPositionDetailEntry(
    processTaskPositionDetailEntry: ProcessTaskPositionDetailEntry
  ): boolean {
    if (!this.processTaskPositionAdapter) {
      return false;
    }

    const processTaskPosition =
      this.entityManager.processTaskPositionRepository.getById(
        processTaskPositionDetailEntry.processTaskPositionId
      );

    if (!processTaskPosition) {
      return false;
    }

    return this.processTaskPositionAdapter.canEditDetailEntries(
      processTaskPosition
    );
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName,
    ProcessTaskPositionDetailEntry
  > {
    return processTaskPositionDetailEntryEntityInfo;
  }
}

export type ProcessTaskPositionDetailEntryAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
