import { computedFrom } from 'aurelia-framework';
import { ProcessConfiguration } from '../../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationAdapter } from '../EntityAdapter/ProcessConfigurationAdapter/ProcessConfigurationAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ProcessConfigurationPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ProcessConfigurationAdapter>,
  ProcessConfiguration
> {
  @computedFrom('combinedRevision')
  public get canEditConfigurableTexts(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditField');
  }

  @computedFrom('combinedRevision')
  public get canEditProcessTaskGroupRelations(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditField');
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationActionStates(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationActionStates'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationAuthorizationTypes(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationAuthorizationTypes'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationCategories(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationCategories'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationDevices(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationDevices'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationDeviceExports(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationDeviceExports'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationFollowUpAppointments(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationFollowUpAppointments'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationForms(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationForms'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationFormSendTargets(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationFormSendTargets'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationMeasurePointTypes(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationMeasurePointTypes'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationSteps(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationSteps'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationNoteCategories(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationNoteCategories'
    );
  }

  @computedFrom('combinedRevision')
  public get canImportProcessConfigurationStepPositions(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canImportProcessConfigurationStepPositions'
    );
  }
}
