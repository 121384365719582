import { autoinject } from 'aurelia-framework';
import { configureHooks } from '../../hooks/configureHooks';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { QuestionSet } from '../../classes/EntityManager/entities/QuestionSet/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProjectQuestionSet } from '../../classes/EntityManager/entities/ProjectQuestionSet/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { CreateChecklistEntitiesService } from '../../services/CreateChecklistEntitiesService';
import { ChecklistAddInspectionQuestionDialog } from '../checklist-add-inspection-question-dialog/checklist-add-inspection-question-dialog';

@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class ChecklistManageQuestionSetsDialog {
  protected dialog: RecordItDialog | null = null;
  protected project: Project | null = null;
  protected physicalArea: string | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly createChecklistEntitiesService: CreateChecklistEntitiesService
  ) {}

  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private open(options: OpenOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open ChecklistStartInspectionDialog without a RecordItDialog.'
    );

    this.project = options.project;
    this.physicalArea = options.physicalArea ?? null;

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.project = null;
    this.physicalArea = null;
  }

  protected handleRemoveProjectQuestionSetButtonClicked(
    projectQuestionSet: ProjectQuestionSet
  ): void {
    this.createChecklistEntitiesService.removeProjectQuestionSet(
      projectQuestionSet
    );
  }

  protected handleAddQuestionSetButtonClicked(questionSet: QuestionSet): void {
    assertNotNullOrUndefined(
      this.project,
      'cannot handleAddQuestionSetButtonClicked without project'
    );

    this.createChecklistEntitiesService.addQuestionSetToProject(
      questionSet,
      this.project,
      this.physicalArea
    );
  }

  protected handleAddQuestionButtonClicked(): void {
    assertNotNullOrUndefined(
      this.project,
      'cannot handleAddQuestionButtonClicked without project'
    );

    void ChecklistAddInspectionQuestionDialog.open({
      project: this.project,
      physicalArea: this.physicalArea
    });
  }

  /**
   * project question sets already related to the current project.
   */
  @computed(
    expression('project'),
    model(EntityName.ProjectQuestionSet),
    model(EntityName.ProjectQuestion)
  )
  protected get relatedProjectQuestionSets(): Array<ProjectQuestionSet> {
    if (!this.project) return [];

    const questionSets =
      this.entityManager.projectQuestionSetRepository.getByProjectId(
        this.project.id
      );

    const questions =
      this.entityManager.projectQuestionRepository.getByProjectQuestionSetIds(
        questionSets.map((q) => q.id)
      );

    // Filter out questionSets that do not contain questions within the current physical area
    return questionSets.filter((projectQuestionSet) => {
      return questions.some((question) => {
        return (
          question.projectQuestionSetId === projectQuestionSet.id &&
          question.physicalArea === this.physicalArea
        );
      });
    });
  }

  /**
   * Question sets that may be added to this inspection.
   */
  @computed(
    expression('relatedProjectQuestionSets'),
    model(EntityName.QuestionSet)
  )
  protected get availableQuestionSets(): Array<QuestionSet> {
    const relatedProjectQuestionSets = this.relatedProjectQuestionSets; // cache this because it's a getter
    return this.entityManager.questionSetRepository
      .getAll()
      .filter((questionSet) => {
        return !relatedProjectQuestionSets.some(
          (projectQuestionSet) =>
            projectQuestionSet.createdFromQuestionSetId === questionSet.id
        );
      });
  }
}

type OpenOptions = {
  project: Project;
  /**
   * The physical area to which new questions will be added.
   */
  physicalArea?: string | null;
};
