import {
  EndpointParameter,
  EndpointResult
} from 'common/WebSocketEndpoints/WebSocketEndpointConfigurations';
import { SocketCommandEmitter } from './SocketCommandEmitter';

export class EntityInfoModuleEndpoints extends SocketCommandEmitter {
  public async getThingAndThingGroupNames(
    request: EndpointParameter<'entityInfoModule', 'getThingAndThingGroupNames'>
  ): Promise<EndpointResult<'entityInfoModule', 'getThingAndThingGroupNames'>> {
    return this.emitCommand(
      'entityInfoModule',
      'getThingAndThingGroupNames',
      request
    );
  }

  public async getProjectQuestionRecommendations(): Promise<
    EndpointResult<'entityInfoModule', 'getProjectQuestionRecommendations'>
  > {
    return this.emitCommand(
      'entityInfoModule',
      'getProjectQuestionRecommendations',
      {}
    );
  }

  public async getProjectQuestionNotes(): Promise<
    EndpointResult<'entityInfoModule', 'getProjectQuestionNotes'>
  > {
    return this.emitCommand('entityInfoModule', 'getProjectQuestionNotes', {});
  }
}
