import { autoinject } from 'aurelia-framework';

import { DefectStatus } from 'common/Enums/DefectStatus';
import { ArrayUtils } from 'common/Utils/ArrayUtils';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  DefectSortOption,
  DefectSortOptions,
  DefectUtils
} from '../../classes/EntityManager/entities/Defect/DefectUtils';
import { computed } from '../../hooks/computed';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { arrayChanges, expression } from '../../hooks/dependencies';
import { ModuleName } from '../../classes/RecordItModuleHelper';
import { ThingAndThingGroupNameService } from '../../services/ThingAndThingGroupNameService/ThingAndThingGroupNameService';
import { DefectCopiedEvent } from '../edit-defect-details-widget/edit-defect-details-widget';

@autoinject()
export class DefectOverviewWorker {
  private defects: Array<Defect> = [];

  protected currentPageDefects: Array<Defect> = [];

  protected openedDefectInfo: Readonly<{
    defect: Defect | null;
    listItemElement: HTMLElement | null;
  }> | null = null;

  protected isDetailsWidgetOpen: boolean = false;

  protected defectListRef: HTMLElement | null = null;

  protected statusFilterSelectedOption = StatusFilterOptions.OPEN_AND_PROCESSED;

  protected statusFilterOptions = [
    {
      tk: 'defectComponents.defectOverviewWorker.statusFilter.all',
      value: StatusFilterOptions.ALL
    },
    {
      tk: 'defectComponents.defectOverviewWorker.statusFilter.onlyOpenAndProcessed',
      value: StatusFilterOptions.OPEN_AND_PROCESSED
    }
  ];

  protected selectedThingId: string | null = null;

  private currentUser: User | null = null;

  private subscriptionManager: SubscriptionManager;

  private readonly sortOptions: DefectSortOptions;
  protected currentSortOption: DefectSortOption;

  protected thingInfosFromDefects: Array<{
    thingId: string;
    thingName: string;
  }> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly thingAndThingGroupNameService: ThingAndThingGroupNameService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.sortOptions = DefectUtils.createSortOptions({ entityManager });
    this.currentSortOption = this.sortOptions.sequenceNumber;
  }

  // /////////// LIFECYCLE /////////////

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser(this.updateCurrentUser.bind(this))
    );

    this.subscriptionManager.subscribeToModelChanges(EntityName.Defect, () => {
      this.updateDefects();
      this.updateThingInfosFromDefects();
    });

    this.updateDefects();
    this.updateThingInfosFromDefects();

    this.subscriptionManager.addDisposable(
      this.thingAndThingGroupNameService.subscribeToNamesChanged(() => {
        this.updateThingInfosFromDefects();
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // /////////// METHODS /////////////

  private openDetailsView(defect: Defect): void {
    this.openedDefectInfo = {
      defect,
      listItemElement:
        this.defectListRef?.querySelector(`#defect-${defect.id}`) || null
    };

    this.isDetailsWidgetOpen = true;
  }

  private closeDetailsView(): void {
    // Don't set openedDefect to null because the widget closing animation plays
    // - The next openDetailsView will set it again anyway
    this.isDetailsWidgetOpen = false;
  }

  private matchesStatusFilter(defect: Defect): boolean {
    switch (this.statusFilterSelectedOption) {
      case StatusFilterOptions.ALL:
        return true;
      case StatusFilterOptions.OPEN_AND_PROCESSED:
        const status = defect.status;
        return (
          status === DefectStatus.OPEN || status === DefectStatus.PROCESSED
        );
      default:
        throw new Error('unknown StatusFilterOptions value');
    }
  }

  private matchesThingFilter(defect: Defect): boolean {
    if (!this.selectedThingId) return true;

    return defect.ownerThingId === this.selectedThingId;
  }

  // /////////// UPDATERS /////////////

  private updateCurrentUser(user: User | null): void {
    this.currentUser = user;

    this.updateDefects();
    this.updateThingInfosFromDefects();
  }

  private updateDefects(): void {
    if (!this.currentUser) {
      this.defects = [];
      return;
    }

    this.defects = this.entityManager.defectRepository
      .getAll()
      .filter((d) => d.assigneeId === this.currentUser!.id);
  }

  private updateThingInfosFromDefects(): void {
    const thingInfosFromDefects = this.defects.map((defect) => {
      return {
        thingId: defect.ownerThingId,
        thingName:
          this.thingAndThingGroupNameService.getThingNameByThingId(
            defect.ownerThingId
          ) ?? ''
      };
    });

    this.thingInfosFromDefects = ArrayUtils.unique(
      thingInfosFromDefects,
      ({ thingId }) => thingId
    );
  }

  protected handleDetailViewButtonClick(defect: Defect): void {
    this.openDetailsView(defect);
  }

  protected handleWidgetOverlayCloseIconClicked(): void {
    this.closeDetailsView();
  }

  protected handleDefectCopied(event: DefectCopiedEvent): void {
    this.openDetailsView(event.detail.defect);
  }

  @computed(expression('currentUser'))
  protected get useInlineView(): boolean {
    return !PermissionHelper.userHasPermissionForModule(
      this.currentUser,
      ModuleName.KUK
    );
  }

  @computed(
    arrayChanges('defects'),
    expression('statusFilterSelectedOption'),
    expression('selectedThingId')
  )
  protected get filteredDefects(): Array<Defect> {
    return this.defects.filter(
      (d) => this.matchesStatusFilter(d) && this.matchesThingFilter(d)
    );
  }
}

enum StatusFilterOptions {
  ALL = 'all',
  OPEN_AND_PROCESSED = 'openAndProcessed'
}
