import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { StructureTemplateStatus } from '../../../../common/src/Types/Entities/StructureTemplate/StructureTemplateDto';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { StructureTemplateEntry } from '../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { StructureTemplateEntryGroup } from '../../classes/EntityManager/entities/StructureTemplateEntryGroup/types';
import { StructureTemplateEntryGroupToStructureTemplateEntry } from '../../classes/EntityManager/entities/StructureTemplateEntryGroupToStructureTemplateEntry/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { StructureTemplateEntryTreeItem } from '../structure-template-entry-tree-item/structure-template-entry-tree-item';

@autoinject()
export class StructureTemplateEntryGroupItem {
  @bindable public structureTemplate: StructureTemplate | null = null;

  @bindable()
  public structureTemplateEntryGroup: StructureTemplateEntryGroup | null = null;

  @bindable()
  public structureTemplateEntry: StructureTemplateEntry | null = null;

  @bindable()
  public canDragStructureTemplateEntryTreeItems: boolean = false;

  @subscribableLifecycle()
  protected readonly structureTemplatePermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplate];

  @subscribableLifecycle()
  protected readonly structureTemplateEntryGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplateEntryGroup];

  @subscribableLifecycle()
  protected readonly structureTemplateEntryGroupToStructureTemplateEntryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplateEntryGroupToStructureTemplateEntry];

  protected structureTemplateEntries: Array<StructureTemplateEntry> = [];

  private subscriptionManager: SubscriptionManager;

  private domElement: HTMLElement;

  protected moreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk:
        'structureTemplateComponents.structureTemplateEntryGroupItem.deleteGroup',
      name: 'delete-group'
    }
  ];

  protected StructureTemplateStatus = StructureTemplateStatus;
  protected StructureTemplateEntryTreeItem = StructureTemplateEntryTreeItem;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();

    this.structureTemplatePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.StructureTemplate,
        context: this as StructureTemplateEntryGroupItem,
        propertyName: 'structureTemplate'
      });

    this.structureTemplateEntryGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.StructureTemplateEntryGroup,
        context: this as StructureTemplateEntryGroupItem,
        propertyName: 'structureTemplateEntryGroup'
      });

    this.structureTemplateEntryGroupToStructureTemplateEntryPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName:
          EntityName.StructureTemplateEntryGroupToStructureTemplateEntry,
        context: this,
        expression: 'structureTemplateEntryGroupToStructureTemplateEntry'
      });
  }

  // ********** Aurelia Life Cycle **********

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntry,
      this.updateStructureTemplateEntries.bind(this)
    );
    this.updateStructureTemplateEntries();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // ********** Aurelia Change Handler **********

  protected structureTemplateEntryGroupChanged(): void {
    this.updateStructureTemplateEntries();
  }

  // ********** Updaters **********

  private updateStructureTemplateEntries(): void {
    if (this.structureTemplateEntryGroup) {
      this.structureTemplateEntries =
        this.entityManager.structureTemplateEntryRepository.getByStructureTemplateEntryGroupId(
          this.structureTemplateEntryGroup.id
        );
    } else {
      this.structureTemplateEntries = [];
    }
  }

  // ********** Handlers **********

  protected handleGroupChanged(): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntryGroup,
      'cannot update if no structure template entry group is available'
    );
    this.entityManager.structureTemplateEntryGroupRepository.update(
      this.structureTemplateEntryGroup
    );
  }

  protected handleDeleteGroupFromEntryClick(): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntryGroup,
      'structure template entry group is not available'
    );
    DomEventHelper.fireEvent<DeleteStructureTemplateEntryGroupEvent>(
      this.domElement,
      {
        name: 'delete-structure-template-entry-group',
        detail: {
          structureTemplateEntryGroupId: this.structureTemplateEntryGroup.id
        }
      }
    );
  }

  protected handleAddEntryClick(): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntryGroup,
      'no structure template entry group available'
    );

    this.entityManager.structureTemplateEntryRepository.create({
      ownerUserGroupId: this.structureTemplateEntryGroup.ownerUserGroupId,
      ownerStructureTemplateId:
        this.structureTemplateEntryGroup.ownerStructureTemplateId,
      structureTemplateEntryGroupId: this.structureTemplateEntryGroup.id
    });
  }

  protected handleDeleteGroupClick(): void {
    const structureTemplateEntryGroup = this.structureTemplateEntryGroup;
    assertNotNullOrUndefined(
      structureTemplateEntryGroup,
      'structure template entry group not available'
    );

    void Dialogs.deleteEntityDialog(structureTemplateEntryGroup).then(() => {
      this.entityManager.structureTemplateEntryGroupRepository.delete(
        structureTemplateEntryGroup
      );
    });
  }

  protected handleActivateDropTarget(
    viewModel: StructureTemplateEntryTreeItem,
    entry: StructureTemplateEntry
  ): boolean {
    return viewModel.structureTemplateEntry?.id !== entry.id;
  }

  protected handleDropItem(
    item: StructureTemplateEntryTreeItem,
    entryToReplace: StructureTemplateEntry | null
  ): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntryGroup,
      'structure template entry group is not available'
    );

    const entry = item.structureTemplateEntry;
    assertNotNullOrUndefined(
      entry,
      'cannot drop element without a structure template entry'
    );

    const listPosition = entryToReplace?.listPosition ?? null;

    if (
      entry.structureTemplateEntryGroupId !==
      this.structureTemplateEntryGroup.id
    ) {
      this.entityManager.structureTemplateEntryRepository.addStructureTemplateEntryToGroup(
        entry,
        this.structureTemplateEntryGroup.id
      );
    }

    if (listPosition != null) {
      this.entityManager.structureTemplateEntryRepository.setListPositionOfStructureTemplateEntry(
        entry,
        listPosition
      );
    }
  }

  @computedFrom('structureTemplateEntry.id')
  protected get structureTemplateEntryGroupToStructureTemplateEntry(): StructureTemplateEntryGroupToStructureTemplateEntry | null {
    if (!this.structureTemplateEntry) {
      return null;
    }

    return this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.getByStructureTemplateEntryId(
      this.structureTemplateEntry.id
    );
  }
}

export type DeleteStructureTemplateEntryGroupEvent = NamedCustomEvent<
  'delete-structure-template-entry-group',
  { structureTemplateEntryGroupId: string }
>;
