import { bindable, autoinject } from 'aurelia-framework';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { Key } from '../../classes/Key';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import {
  FloatingLabelInputTheme,
  FloatingLabelInput
} from '../floating-label-input/floating-label-input';

/**
 * @slot label - allows for HTML content in the label. Requires the `label` property to be set to `true`.
 *
 * @event {TTextChangedEvent} text-changed this will get fired when the user finished editing the text
 */
@autoinject()
export class ClickableTextInput {
  @bindable public text: string | number | null = '';

  @bindable public placeholder = '';

  @bindable public multiline = false;

  @bindable public enabled = true;

  @bindable public readOnly = false;

  @bindable public label: string | boolean = '';

  @bindable public showButtons = true;

  @bindable public compactButton = false;

  @bindable public theme: FloatingLabelInputTheme =
    FloatingLabelInputTheme.DEFAULT;

  /**
   * set this to true to use the label of the floating-label-input instead of one to the left
   */
  @bindable public useFloatingLabel = false;

  /**
   * set this to true to only allow the input of one number, doesn't work in combination with multiline
   */
  @bindable public numberOnly = false;

  /**
   * set type of text input element, does not work together with 'numberOnly'
   */
  @bindable public type = 'text';

  /**
   * only works in combination with numberOnly
   */
  @bindable public min: number | null = null;

  /**
   * only works in combination with numberOnly
   */
  @bindable public max: number | null = null;

  @bindable public inputErrorText = '';

  @bindable public stackedStyle = false;

  /**
   * by default the label is uppercase, by enabling the normal case the label will displayed as is
   */
  @bindable public normalCaseLabel = false;

  /**
   * decide when the text will get updated, only use input for local variables and not for models, because it will result in too much updates
   */
  @bindable public textUpdateMethod: 'blur' | 'input' = 'blur';

  /**
   * decides whether multiline-inputs will be displayed in full size (=false) or limited to two lines+scrollable (=true)
   */
  @bindable public inTable = false;

  @bindable protected stepSize: number | null = null;

  @bindable public autocomplete: string | undefined;

  @bindable public iconClass: string | null = null;

  @bindable public isLoading: boolean = false;

  @bindable public monospace: boolean = false;

  protected currentlyEditing = false;

  protected isSaving = false;

  protected inputText: string | number | null = '';

  protected element: HTMLElement;

  protected floatingLabelInput: FloatingLabelInput | null = null;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  // /////////// PUBLIC METHODS /////////////

  public focus(): void {
    if (!this.currentlyEditing) {
      this.toggleEditMode();
    }
  }

  public reset(): void {
    this.inputText = this.text;
    this.stopEditMode();
  }

  public toggleEditMode(): void {
    if (!this.currentlyEditing && this.enabled && !this.readOnly) {
      this.startEditMode();
    } else if (this.currentlyEditing) {
      this.stopEditMode();
    }
  }

  // /////////// METHODS /////////////

  private fireTextChangedEvent({
    oldValue
  }: {
    oldValue: string | number | null;
  }): void {
    setTimeout(() => {
      DomEventHelper.fireEvent<TTextChangedEvent>(this.element, {
        name: 'text-changed',
        bubbles: true,
        detail: { value: this.text, oldValue: oldValue }
      });
    }, 0);
  }

  /**
   * @param signalIsSaving if true, pretend to be saving for a second.
   * @param trimText if true, inputText will be trimmed.
   */
  private updateText(signalIsSaving: boolean, trimText: boolean): void {
    let inputText = this.inputText;

    if (inputText !== null && trimText && typeof inputText === 'string') {
      inputText = inputText.trim();
    }

    if (this.text === inputText) {
      // No need to update
      return;
    }

    const oldValue = this.text;
    this.text = inputText;
    this.fireTextChangedEvent({ oldValue });

    if (signalIsSaving) {
      this.isSaving = true;
      setTimeout(() => {
        this.isSaving = false;
      }, 1000);
    }
  }

  private startEditMode(): void {
    this.currentlyEditing = true;
    setTimeout(() => {
      if (this.floatingLabelInput) this.floatingLabelInput.focus();
    }, 0);
  }

  private stopEditMode(): void {
    this.currentlyEditing = false;
    this.updateText(true, true);
  }

  protected incrementValue(sign: IncrementSign): void {
    if (!this.numberOnly || !this.stepSize || isNaN(this.stepSize)) return;

    const oldValue = this.text;
    if (!this.text) this.text = 0; // Init with 0 to allow increment/decrement
    if (typeof this.text === 'number') this.text += this.stepSize * sign;
    this.fireTextChangedEvent({ oldValue });
  }

  // /////////// OBSERVABLES /////////////

  protected textChanged(): void {
    this.inputText = this.text;
  }

  // /////////// EVENT HANDLERS /////////////

  protected handleEditIconClick(): void {
    this.toggleEditMode();
  }

  protected handleInputKeydownEvent(event: KeyboardEvent): boolean | undefined {
    if (event.key === Key.ENTER) {
      if (this.multiline) {
        return true;
      } else {
        this.stopEditMode();
        return false;
      }
    }
    return true;
  }

  protected handleInputBlurEvent(): void {
    this.stopEditMode();
  }

  protected handleInputValueChangedEvent(): void {
    if (this.textUpdateMethod === 'input') {
      this.updateText(false, false);
    }
  }

  protected IncrementSign = IncrementSign; // enum to aurelia

  @computed(expression('isSaving'), expression('isLoading'))
  protected get showLoading(): boolean {
    return this.isSaving || this.isLoading;
  }
}

export enum IncrementSign {
  PLUS = 1,
  MINUS = -1
}

export type TTextChangedEvent = NamedCustomEvent<
  'text-changed',
  {
    /**
     * The newly changed value of this text field.
     */
    value: string | number | null;
    /**
     * The value this text field was set to before.
     *
     * **WARNING**: `oldValue` is not necessarily the last string the user
     * saw, because changes of the `text` property while editing
     * will not be seen by the user, but still be saved as the old value.
     */
    oldValue: string | number | null;
  }
>;
