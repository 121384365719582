export class PathMatcher {
  public static pathIsMatched(
    path: string,
    pathPatterns?: Array<string>
  ): boolean {
    if (!pathPatterns?.length) return true;

    return pathPatterns.some((pathPattern) => {
      const regexString =
        '^' +
        pathPattern
          .replace(/\./g, '\\.')
          .replace(/\*\*/g, '([\\d.]+)')
          .replace(/\*/g, '(\\d+)') +
        '$';
      return new RegExp(regexString).exec(path);
    });
  }
}
