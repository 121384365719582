import { autoinject, bindable } from 'aurelia-framework';

import { FreehandTool } from '../../drawingComponents/drawing-area/tools/FreehandTool';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { DrawingArea } from '../../drawingComponents/drawing-area/drawing-area';

/**
 * @event {TSignatureChangedEvent} signature-changed
 */
@autoinject()
export class SignatureArea {
  @bindable public signatureImageUrl: string | null = null;

  @bindable public enabled = false;

  @bindable public text: string | null = null;

  @bindable public showText = true;

  @bindable public fillHeight = false;

  private dialog: RecordItDialog | null = null;
  private drawingArea: DrawingArea | null = null;
  private signaturePreview: HTMLImageElement | null = null;

  private isChanged = false;

  private tool: FreehandTool;

  constructor(private readonly element: Element) {
    this.tool = new FreehandTool();
  }

  protected attached(): void {
    this.tool.setColor('#000000');
    this.tool.setSize(1);
  }

  protected detached(): void {
    if (this.tool.isActive()) {
      this.tool.finish();
    }
  }

  protected handleSignaturePreviewClick(): void {
    if (this.enabled) {
      void this.openSignatureOverlay();
    }
  }

  protected handleClearButtonClicked(): void {
    if (!this.drawingArea || !this.dialog) return;
    this.tool.cancel();
    this.drawingArea.empty();
    this.dialog.close();
  }

  protected async handleOkButtonClicked(): Promise<void> {
    if (!this.dialog || !this.signaturePreview || !this.drawingArea) return;
    this.tool.finish();
    this.dialog.close();
    if (!this.isChanged) {
      return;
    }
    this.signatureImageUrl = this.drawingArea.exportSvgAsDataUrl(true);

    DomEventHelper.fireEvent(this.element, {
      name: 'signature-changed',
      detail: {
        dataUrl: this.signatureImageUrl
      }
    });
  }

  protected handleDrawingAreaChanged(): void {
    this.isChanged = true;
  }

  protected handleCloseButtonClick(): void {
    if (!this.dialog) return;
    this.dialog.close();
    this.isChanged = false;
    this.tool.finish();
  }

  protected async openSignatureOverlay(): Promise<void> {
    if (!this.dialog || !this.drawingArea) return;
    if (this.signatureImageUrl) {
      try {
        await Dialogs.yesNoDialogTk(
          'aureliaComponents.signatureArea.changeSignatureConfirmation'
        );
      } catch (error) {
        if (error instanceof Error && error.message === 'DialogCanceled')
          return;
        throw error;
      }
      this.drawingArea.empty();
    }
    this.isChanged = false;
    this.dialog.open();
    this.tool.activate(this.drawingArea, null);
  }
}

export type TSignatureChangedEvent = NamedCustomEvent<
  'signature-changed',
  { dataUrl: string }
>;
