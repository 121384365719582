import { HooksContainer } from '@record-it-npm/synchro-client';
import { DataStorageHelper } from '../../../DataStorageHelper/DataStorageHelper';
import { JoinEntityQueue } from '../../../JoinEntityQueue/JoinEntityQueue';
import {
  Disposable,
  DisposableContainer
} from '../../../Utils/DisposableContainer';
import { Utils } from '../../../Utils/Utils';
import { AppEntityManager } from '../AppEntityManager';
import { EntityName } from '../types';
import { Project } from './types';

export class JoinedProjectsManager {
  private static JOINED_PROJECT_INFOS_DATABASE_KEY =
    'JoinedProjectsManager::joinedProjectInfos';

  private static JOINED_PROJECTS_LIMIT = 20;

  private readonly joinEntityQueue: JoinEntityQueue;

  private initialized = false;
  private joinedProjectInfos: Array<JoinedProjectInfo> = [];
  private saveInfosRateLimited = Utils.rateLimitFunction(
    this.saveInfos.bind(this),
    200
  );

  private hooksContainer: HooksContainer<JoinedProjectsManagerHooks> =
    new HooksContainer();

  private disposableContainer: DisposableContainer = new DisposableContainer();

  private autoJoiningProjectsFinished: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    options: Options
  ) {
    this.joinEntityQueue = new JoinEntityQueue({
      sendJoinEntityRequest: options.joinProject,
      sendLeaveEntityRequest: options.leaveProject,
      onEntityActivelyJoined: (projectId) => {
        this.hooksContainer.callHooks('onActivelyJoinedProject', projectId);
      }
    });
  }

  public async init(): Promise<void> {
    await this.loadJoinedProjectInfos();

    this.disposableContainer.add(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.Project,
        this.handleProjectEntityIdUpdated.bind(this)
      )
    );

    this.disposableContainer.add(
      this.entityManager.projectRepository.registerHooks({
        afterEntityDeleted: this.handleProjectRemoved.bind(this),
        afterEntityRemovedLocally: this.handleProjectRemoved.bind(this)
      })
    );

    this.initialized = true;
  }

  public async flush(): Promise<void> {
    if (this.saveInfosRateLimited.isPending()) {
      this.saveInfosRateLimited.cancel();
      await this.saveInfos();
    }
  }

  public destroy(): void {
    if (this.saveInfosRateLimited.isPending()) {
      throw new Error(
        "can't destroy the JoinedProjectsManager while a save is pending"
      );
    }

    this.initialized = false;
    this.stop();
  }

  public start(): void {
    this.joinEntityQueue.start();
    this.updateJoinedProjectInfos();
    void this.autoJoinProjects();
  }

  public stop(): void {
    this.joinEntityQueue.stop();
    this.autoJoiningProjectsFinished = false;
  }

  public autoJoiningProjectsIsFinished(): boolean {
    return this.autoJoiningProjectsFinished;
  }

  public projectIsJoined(projectId: string): boolean {
    return (
      this.joinedProjectInfos.findIndex(
        (info) => info.projectId === projectId
      ) >= 0
    );
  }

  public projectIsJoinedPermanently(projectId: string): boolean {
    return (
      this.joinedProjectInfos.findIndex(
        (info) => info.projectId === projectId && !info.temporaryCount
      ) >= 0
    );
  }

  public getJoinedProjectIds(): Array<string> {
    this.assertInitialized();

    return this.joinedProjectInfos.map((info) => info.projectId);
  }

  public registerHooks(hooks: JoinedProjectsManagerHooks): Disposable {
    return this.hooksContainer.registerHooks(hooks);
  }

  /**
   * if the project is joined temporarily, the amount of join calls will be tracked
   * so to leave the project, you need the same amount of leave calls
   * this is implement so multiple components (which are just displaying project data) can join the project in the attached handler, and leave it in the detached handler
   * if the project is already joined normally (or will be joined normally), it will override the temporary behavior (and temporary leave project calls won't do anything)
   *
   */
  public async joinProject(
    projectId: string,
    ignoreInLimit = false,
    temporary = false
  ): Promise<void> {
    this.assertInitialized();

    this.updateInfoForJoinedProject(projectId, ignoreInLimit, temporary);

    await this.joinEntityQueue.joinEntity(projectId);

    this.hooksContainer.callHooks('onProjectJoined', projectId);
    this.hooksContainer.callHooks('onJoinedStatusChanged', projectId);
  }

  /**
   * @see JoinedProjectsManager.joinProject for more information on the temporary param
   */
  public async leaveProject(
    projectId: string,
    temporary = false
  ): Promise<void> {
    this.assertInitialized();
    await this.leaveProjectInternal(projectId, temporary);
  }

  public isBusy(): boolean {
    return this.joinEntityQueue.isBusy();
  }

  private async autoJoinProjects(): Promise<void> {
    const joinProjectPromises = [];

    for (const info of this.joinedProjectInfos) {
      joinProjectPromises.push(this.joinEntityQueue.joinEntity(info.projectId));
    }

    await Promise.all(joinProjectPromises);

    this.autoJoiningProjectsFinished = true;
    this.hooksContainer.callHooks('autoJoiningProjectsFinished');
  }

  private async leaveProjectInternal(
    projectId: string,
    temporary = false
  ): Promise<void> {
    const infoIndex = this.joinedProjectInfos.findIndex(
      (info) => info.projectId === projectId
    );
    const info = infoIndex >= 0 ? this.joinedProjectInfos[infoIndex] : null;
    if (info) {
      if (temporary && info.temporaryCount && info.temporaryCount > 1) {
        info.temporaryCount--;
        this.saveInfosRateLimited();
        return;
      }

      this.removeJoinedProjectInfoAtIndex(infoIndex);
      this.saveInfosRateLimited();
    }

    await this.joinEntityQueue.leaveEntity(projectId);

    this.hooksContainer.callHooks('onProjectLeft', projectId);
    this.hooksContainer.callHooks('onJoinedStatusChanged', projectId);
  }

  private handleProjectEntityIdUpdated(): void {
    this.assertInitialized();

    const affectedInfos: Array<IdUpdateAffectedInfo> = [];

    for (const info of this.joinedProjectInfos) {
      const project = this.entityManager.projectRepository.getByOriginalId(
        info.projectId
      );
      if (project) {
        const oldId = info.projectId;
        info.projectId = project.id;

        affectedInfos.push({
          info,
          oldId
        });

        this.entityManager.entityActualization.removeRelativeSynchronizationEntityId(
          EntityName.Project,
          oldId
        );
        this.entityManager.entityActualization.addRelativeSynchronizationEntityId(
          EntityName.Project,
          info.projectId
        );
      }
    }

    if (affectedInfos.length) {
      this.saveInfosRateLimited();
      this.updateJoinedProjectsInfoJoinedSocketRooms(affectedInfos);
    }
  }

  private handleProjectRemoved(project: Project): void {
    void this.leaveProject(project.id);
  }

  private updateJoinedProjectsInfoJoinedSocketRooms(
    affectedInfos: Array<IdUpdateAffectedInfo>
  ): void {
    for (const affectedInfo of affectedInfos) {
      void this.joinEntityQueue.leaveEntity(affectedInfo.oldId);
      void this.joinEntityQueue.joinEntity(affectedInfo.info.projectId);
    }
  }

  private async loadJoinedProjectInfos(): Promise<void> {
    let infos: Array<JoinedProjectInfo> | null =
      await DataStorageHelper.getItem(
        JoinedProjectsManager.JOINED_PROJECT_INFOS_DATABASE_KEY
      );
    if (!infos) {
      infos = [];
    }

    const permanentInfos: Array<JoinedProjectInfo> = [];

    for (const info of infos) {
      if (info.temporaryCount) {
        void this.leaveProjectInternal(info.projectId);
        this.entityManager.entityActualization.removeRelativeSynchronizationEntityId(
          EntityName.Project,
          info.projectId
        );
      } else {
        permanentInfos.push(info);
        this.entityManager.entityActualization.addRelativeSynchronizationEntityId(
          EntityName.Project,
          info.projectId
        );
      }
    }

    this.joinedProjectInfos = permanentInfos;
  }

  /**
   * creates a new info for the projectId if none exists, or moves the existing info to the back
   *
   * @see JoinedProjectsManager.joinProject for more information on the temporary param
   */
  private updateInfoForJoinedProject(
    projectId: string,
    ignoreInLimit = false,
    temporary = false
  ): void {
    const infoIndex = this.joinedProjectInfos.findIndex(
      (info) => info.projectId === projectId
    );
    let info = this.joinedProjectInfos[infoIndex];
    if (info) {
      this.joinedProjectInfos.splice(infoIndex, 1); // move it to the back
    } else {
      this.limitJoinedProjectInfos(
        JoinedProjectsManager.JOINED_PROJECTS_LIMIT - 1
      ); // -1 because we will add one right after it
      info = {
        projectId: projectId,
        ignoreInLimit: false,
        temporaryCount: null
      };
    }

    info.ignoreInLimit = ignoreInLimit;

    // only set/increment the temporaryCount if the info was already temporary (since we don't want to downgrade permanent subscriptions to temporary ones)
    // or if the info was newly created
    if (temporary && (info.temporaryCount !== null || infoIndex === -1)) {
      info.temporaryCount = (info.temporaryCount || 0) + 1;
    } else {
      info.temporaryCount = null;
    }

    this.joinedProjectInfos.push(info);
    this.entityManager.entityActualization.addRelativeSynchronizationEntityId(
      EntityName.Project,
      info.projectId
    );
    this.saveInfosRateLimited();
  }

  private limitJoinedProjectInfos(limit: number): void {
    let count = 0;
    const infos = this.joinedProjectInfos.slice(); // slice because the array will get edited in leaveProject
    infos.reverse();

    infos.forEach((info) => {
      if (!info.ignoreInLimit) {
        count++;
      }
    });

    let toRemove = count - limit;
    for (const info of this.joinedProjectInfos.slice()) {
      if (toRemove <= 0) {
        break;
      }

      if (!info.ignoreInLimit || info.temporaryCount != null) {
        void this.leaveProject(info.projectId);
        toRemove--;
      }
    }
  }

  private updateJoinedProjectInfos(): void {
    const infosWithIndex = this.joinedProjectInfos
      .map((info, index) => ({ info, index }))
      .reverse();
    for (const { index, info } of infosWithIndex) {
      if (!this.entityManager.projectRepository.getById(info.projectId)) {
        this.removeJoinedProjectInfoAtIndex(index);
      }
    }

    this.saveInfosRateLimited();
  }

  private removeJoinedProjectInfoAtIndex(index: number): void {
    const info = this.joinedProjectInfos[index];

    if (info) {
      this.joinedProjectInfos.splice(index, 1);
      this.entityManager.entityActualization.removeRelativeSynchronizationEntityId(
        EntityName.Project,
        info.projectId
      );
    }
  }

  private saveInfos(): Promise<void> {
    return DataStorageHelper.setItem(
      JoinedProjectsManager.JOINED_PROJECT_INFOS_DATABASE_KEY,
      this.joinedProjectInfos
    );
  }

  private assertInitialized(): void {
    if (!this.initialized) {
      throw new Error(
        "can't use the JoinedProjectsManager when it isn't initialized"
      );
    }
  }
}

type JoinedProjectInfo = {
  projectId: string;
  /**
   * ignore this project for the overall limit for joined projects (e.g. they are managed somewhere else)
   */
  ignoreInLimit: boolean;
  /**
   * do not save the joined state, project will not be rejoined when restarting the app/reloading the site, also tracks the amount of join/leave calls, so it will only get left when necessary
   */
  temporaryCount: number | null;
};

type IdUpdateAffectedInfo = {
  info: JoinedProjectInfo;
  oldId: string;
};

export type Options = {
  joinProject: (projectId: string) => Promise<void>;
  leaveProject: (projectId: string) => Promise<void>;
};

export type JoinedProjectsManagerHooks = {
  onJoinedStatusChanged?: (projectId: string) => void;
  onProjectJoined?: (projectId: string) => void;
  /**
   * this is called when a project is actively joined (and not only marked as joined locally)
   */
  onActivelyJoinedProject?: (projectId: string) => void;
  onProjectLeft?: (projectId: string) => void;
  autoJoiningProjectsFinished?: () => void;
};
