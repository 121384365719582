import { autoinject, bindable } from 'aurelia-framework';
import { ProjectQuestion } from '../../classes/EntityManager/entities/ProjectQuestion/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from 'common/Types/BaseEntities/EntityName';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { computed } from '../../hooks/computed';
import { model, expression } from '../../hooks/dependencies';
import { DataStorageCache } from '../../classes/DataStorageCache/DataStorageCache';
import { SocketService } from '../../services/SocketService';

@autoinject()
export class ChecklistProjectQuestionNoteWidget {
  @bindable public question: ProjectQuestion | null = null;

  public static readonly NOTE_CACHE = new DataStorageCache<
    Array<{
      projectQuestionId: string;
      note: string;
    }>
  >('ChecklistProjectQuestionNoteWidget::notes');

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService
  ) {}

  protected attached(): void {
    this.updateNotes();
  }

  protected handleQuestionUpdated(): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleQuestionUpdated without question'
    );

    this.entityManager.projectQuestionRepository.update(this.question);
  }

  protected updateNotes(): void {
    if (this.socketService.isConnected()) {
      void ChecklistProjectQuestionNoteWidget.NOTE_CACHE.update(
        this.socketService.entityInfoModuleEndpoints.getProjectQuestionNotes()
      );
    }
  }

  @computed(
    expression(ChecklistProjectQuestionNoteWidget, 'NOTE_CACHE.value'),
    model(EntityName.ProjectQuestion),
    expression('question.id')
  )
  protected get suggestions(): Array<string> {
    return ArrayUtils.unique(
      [
        ...(ChecklistProjectQuestionNoteWidget.NOTE_CACHE.value ?? []),
        ...this.entityManager.projectQuestionRepository.getAll().map((pq) => ({
          projectQuestionId: pq.id,
          note: pq.note
        }))
      ].filter((pq) => pq.projectQuestionId !== this.question?.id),
      (i) => i.note
    )
      .map((pq) => pq.note)
      .filter((note): note is string => !!note);
  }
}
