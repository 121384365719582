import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { TextBrickTemplate } from '../../classes/EntityManager/entities/TextBrickTemplate/types';
import { TextBrick } from '../../classes/EntityManager/entities/TextBrick/types';

import { TextBrickWidgetAdapter } from './TextBrickWidgetAdapter/TextBrickWidgetAdapter';

@autoinject()
export class TextBrickWidget {
  @bindable public adapter: TextBrickWidgetAdapter | null = null;

  protected selectedTextBricks: Array<TextBrick> = [];
  protected remainingTextBrickTemplates: Array<TextBrickTemplate> = [];
  protected canCreateTextBricks = false;

  private isAttached = false;
  private adapterSubscriptionManager: SubscriptionManager;

  constructor(subscriptionManagerService: SubscriptionManagerService) {
    this.adapterSubscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;
    this.updateAdapterSubscription();
  }

  protected detached(): void {
    this.isAttached = false;
    this.adapterSubscriptionManager.disposeSubscriptions();
  }

  protected adapterChanged(): void {
    if (!this.isAttached) return;
    this.updateAdapterSubscription();
  }

  protected handleClickOnRemainingTextBrickTemplate(
    brickTemplate: TextBrickTemplate
  ): void {
    assertNotNullOrUndefined(
      this.adapter,
      'cannot handleClickOnRemainingTextBrickTemplate without a textBrickWidgetAdapter'
    );
    this.adapter.createNewTextBrickFromTemplate(brickTemplate);
  }

  protected handleClickOnMoreButton(): void {
    assertNotNullOrUndefined(
      this.adapter,
      'cannot handleClickOnMoreButton without a textBrickWidgetAdapter'
    );
    this.adapter.openEditTextBrickTemplateDialog();
  }

  private updateAdapterSubscription(): void {
    this.adapterSubscriptionManager.disposeSubscriptions();

    if (this.adapter) {
      const disposable = this.adapter.subscribe({
        setCanCreateTextBricks: (canCreateTextBricks) => {
          this.canCreateTextBricks = canCreateTextBricks;
        },
        setRemainingTextBrickTemplates: (remainingTextBrickTemplates) => {
          this.remainingTextBrickTemplates = remainingTextBrickTemplates;
        },
        setSelectedTextBricks: (selectedTextBricks) => {
          this.selectedTextBricks = selectedTextBricks;
        }
      });

      this.adapterSubscriptionManager.addDisposable(disposable);
    }
  }
}
