import { TupleArray, TupleArrayWithOtherType } from 'common/Types/utilities';

/**
 * A cache which maps an array to values.
 * If the same item will be mapped again, the previous value will be used
 */
export class ArrayMapCache<TItem, TMappedItem> {
  private readonly createMappedItem: CreateMappedItem<TItem, TMappedItem>;
  private readonly onItemRemoved: OnItemRemoved<TItem, TMappedItem> | null;
  private readonly onItemUpdate: OnItemUpdate<TItem, TMappedItem> | null;
  private readonly getKeyForItem: GetKeyForItem<TItem>;

  private readonly keyToItemInfo = new Map<
    unknown,
    { item: TItem; mappedItem: TMappedItem }
  >();

  constructor(options: ArrayMapCacheOptions<TItem, TMappedItem>) {
    this.createMappedItem = options.createMappedItem;
    this.onItemRemoved = options.onItemRemoved ?? null;
    this.onItemUpdate = options.onItemUpdate ?? null;
    this.getKeyForItem = options.getKeyForItem ?? (({ item }) => item);
  }

  /**
   * Maps the items to the mappedItems.
   * If there already is an mappeditem, then the existing one will be reused.
   * If there is none, a new one will be generated.
   *
   * MappedItems for items which are not in this array will be discarded.
   */
  public mapItems<TItems extends Array<TItem>>({
    items
  }: {
    items: TupleArray<TItems>;
  }): TupleArrayWithOtherType<TItems, TMappedItem> {
    const mappedItems = this.getOrCreateMappedItemsForItems<TItems>({ items });

    this.removeUnusedItems({
      usedItems: items
    });

    return mappedItems;
  }

  /**
   * Gets the MappedItem for the Item.
   * The MappedItem will be cached and considered in the next mapItems call
   *
   * This is useful if you need the MappedItem before the next mapItems call but you also want it to be cached
   */
  public mapItem({ item }: { item: TItem }): TMappedItem {
    return this.getOrCreateMappedItem({ item });
  }

  /**
   * Removes all items from the cache and disposes them
   */
  public clear(): void {
    for (const key of this.keyToItemInfo.keys()) {
      this.removeKey({ key });
    }
  }

  private getOrCreateMappedItemsForItems<TItems extends Array<TItem>>({
    items
  }: {
    items: TupleArray<TItems>;
  }): TupleArrayWithOtherType<TItems, TMappedItem> {
    const mappedItems = items.map((item) => {
      return this.getOrCreateMappedItem({
        item
      });
    }) as TupleArrayWithOtherType<TItems, TMappedItem>;

    return mappedItems;
  }

  private getOrCreateMappedItem({ item }: { item: TItem }): TMappedItem {
    const key = this.getKeyForItem({ item });

    const itemInfo = this.keyToItemInfo.get(key);

    if (itemInfo) {
      if (item !== itemInfo.item) {
        this.onItemUpdate?.({ item, mappedItem: itemInfo.mappedItem });
        itemInfo.item = item;
      }

      return itemInfo.mappedItem;
    } else {
      const mappedItem = this.createMappedItem({ item });
      this.keyToItemInfo.set(key, {
        item,
        mappedItem
      });

      return mappedItem;
    }
  }

  private removeUnusedItems({ usedItems }: { usedItems: Array<TItem> }): void {
    const usedItemsSet = new Set(usedItems);

    for (const [key, { item }] of this.keyToItemInfo.entries()) {
      if (!usedItemsSet.has(item)) {
        this.removeKey({ key });
      }
    }
  }

  private removeKey({ key }: { key: unknown }): void {
    const info = this.keyToItemInfo.get(key);
    this.keyToItemInfo.delete(key);

    if (info) {
      this.onItemRemoved?.({ item: info.item, mappedItem: info.mappedItem });
    }
  }
}

export type ArrayMapCacheOptions<TItem, TMappedItem> = {
  createMappedItem: CreateMappedItem<TItem, TMappedItem>;

  /**
   * if an item gets removed from the cache, this will be called with the item
   */
  onItemRemoved?: OnItemRemoved<TItem, TMappedItem>;
  onItemUpdate?: OnItemUpdate<TItem, TMappedItem>;
  getKeyForItem?: GetKeyForItem<TItem>;
};

export type CreateMappedItem<TItem, TMappedItem> = (options: {
  item: TItem;
}) => TMappedItem;

export type OnItemRemoved<TItem, TMappedItem> = (options: {
  item: TItem;
  mappedItem: TMappedItem;
}) => void;

/**
 * you can update the mapped item here if this is called
 */
export type OnItemUpdate<TItem, TMappedItem> = (options: {
  item: TItem;
  /**
   * you can update/modify this here
   */
  mappedItem: TMappedItem;
}) => void;

export type GetKeyForItem<TItem> = (options: { item: TItem }) => unknown;
