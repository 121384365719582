import { autoinject, bindable } from 'aurelia-framework';

import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import {
  OnCreateNewUserDefinedEntitiesCallback,
  SupportedTemplateUserDefinedEntity
} from '../../dialogs/copy-user-defined-entity-dialog/copy-user-defined-entity-dialog';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import {
  GlobalUserDefinedEntity,
  ThingUserDefinedEntity
} from '../../classes/EntityManager/entities/UserDefinedEntity/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { UserDefinedEntityCreationService } from '../../classes/EntityManager/entities/UserDefinedEntity/UserDefinedEntityCreationService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { UserDefinedEntitiesFilterService } from '../../services/UserDefinedEntitiesFilterService/UserDefinedEntitiesFilterService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ThingUserDefinedEntities {
  @bindable public thing: Thing | null = null;

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  protected readonly onCreateNewThingUserDefinedEntitiesCallback: OnCreateNewUserDefinedEntitiesCallback<Thing>;

  constructor(
    private readonly entityManager: AppEntityManager,
    private userDefinedEntitiesFilterService: UserDefinedEntitiesFilterService,
    permissionsService: PermissionsService,
    userDefinedEntityCreationService: UserDefinedEntityCreationService
  ) {
    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Thing,
        context: this as ThingUserDefinedEntities,
        propertyName: 'thing'
      });

    this.onCreateNewThingUserDefinedEntitiesCallback =
      userDefinedEntityCreationService.createThingUserDefinedEntity.bind(
        userDefinedEntityCreationService
      );
  }

  @computed(model(EntityName.UserDefinedEntity), expression('thing'))
  private get userDefinedEntities(): Array<ThingUserDefinedEntity> {
    if (!this.thing) return [];
    return this.entityManager.userDefinedEntityRepository.getByThingId(
      this.thing.id
    );
  }

  @computed(model(EntityName.UserDefinedEntity), expression('userGroup'))
  private get availableGlobalUserDefinedEntities(): Array<GlobalUserDefinedEntity> {
    if (!this.thing) return [];
    return this.entityManager.userDefinedEntityRepository.getGlobalByOwnerUserGroupId(
      this.thing.ownerUserGroupId
    );
  }

  @computed(
    expression('availableGlobalUserDefinedEntities'),
    expression('userDefinedEntities')
  )
  protected get selectableUserDefinedEntityTemplates(): Array<
    SupportedTemplateUserDefinedEntity<Thing>
  > {
    return this.userDefinedEntitiesFilterService.filterGlobalUserDefinedEntityTemplates(
      this.userDefinedEntities,
      this.availableGlobalUserDefinedEntities
    );
  }
}
