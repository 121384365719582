import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskDeviceGroups,
  ProcessTaskDeviceGroupsForProcessTaskIdComputer
} from '../../computedValues/computers/ProcessTaskDeviceGroupsForProcessTaskIdComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class ProcessTaskAppointmentDevicesWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  protected groupedDevices: ProcessTaskDeviceGroups = [];

  constructor(
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessTaskDeviceGroupsForProcessTaskIdComputer,
        callback: (groupedDevices) => {
          this.groupedDevices = groupedDevices;
        },
        createComputeData: () => {
          return this.processTask
            ? { processTaskId: this.processTask.id }
            : null;
        },
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTask.id',
            updateSubscription
          );
        }
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }
}
