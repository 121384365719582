import {
  ExpressionEditorScopeCreationUtils,
  ValueType
} from '../../ExpressionEditorScopeCreationUtils';

export class SharepointFieldConfigs {
  public static thingFieldConfigs =
    ExpressionEditorScopeCreationUtils.createFieldConfigs({
      name: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'name',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk: 'modelProperties.name'
      })
    });

  public static projectFieldConfigs =
    ExpressionEditorScopeCreationUtils.createFieldConfigs({
      name: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'name',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk: 'modelProperties.name'
      })
    });

  public static sharepointExportSettingsFieldConfigs =
    ExpressionEditorScopeCreationUtils.createFieldConfigs({
      thing: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'thing',
        valueType: ValueType.NESTED,
        fieldConfigs: SharepointFieldConfigs.thingFieldConfigs,
        nullable: false,
        descriptionTk: 'models.thing'
      }),
      project: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'project',
        valueType: ValueType.NESTED,
        fieldConfigs: SharepointFieldConfigs.projectFieldConfigs,
        nullable: true,
        descriptionTk: 'models.project'
      })
    });
}
