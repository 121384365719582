import { autoinject, bindable } from 'aurelia-framework';
import {
  PositionInfo,
  PositionPrices,
  ProcessTaskPositionCalculator
} from 'common/Operations/ProcessTaskPositionCalculator';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import {
  ProcessTaskPositionDetailEntriesByProcessTaskPositionId,
  ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer
} from '../../../computedValues/computers/ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer';
import { CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer } from '../../../computedValues/computers/CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { ProcessTaskPositionUtils } from '../../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { ProcessTaskPositionDetailEntryUtils } from '../../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/ProcessTaskPositionDetailEntryUtils';
import { ComputedValueService } from '../../../computedValues/ComputedValueService';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskOffer } from '../../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { ProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskOfferToProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskPosition/types';
import { ProcessTaskPositionDetailEntry } from '../../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types';
import { ProcessTaskOfferRelationsWidgetPositionListItem } from '../process-task-offer-relations-widget-position-list-item/process-task-offer-relations-widget-position-list-item';

/**
 * this is just a sub-component of the ProcessTaskOfferTasksAndPositionsWidget and also uses its styling
 */
@autoinject()
export class ProcessTaskOfferRelationsWidgetPositions {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTaskOffer: ProcessTaskOffer | null = null;

  @bindable()
  public allPositionsSelected: boolean = false;

  /**
   * is null when positions are not loaded
   * read only
   */
  @bindable()
  public processTaskPositionCount: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private availablePositions: Array<ProcessTaskPosition> = [];
  private availableProcessTaskOfferToProcessTaskPositions: Array<ProcessTaskOfferToProcessTaskPosition> =
    [];

  private calculator: ProcessTaskPositionCalculator | null = null;
  private detailEntriesByPositionId: ProcessTaskPositionDetailEntriesByProcessTaskPositionId =
    new Map();

  private positionPrices: Array<
    PositionPrices<ProcessTaskPosition, ProcessTaskPositionDetailEntry>
  > = [];

  /**
   * all prices which are included in the offer
   */
  protected includedPositionPrices: Array<
    PositionPrices<ProcessTaskPosition, ProcessTaskPositionDetailEntry>
  > = [];

  private isAttached: boolean = false;

  protected positionListItems: Set<ProcessTaskOfferRelationsWidgetPositionListItem> =
    new Set();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public selectAll(): void {
    for (const positionListItem of this.positionListItems.values()) {
      positionListItem.setChecked(true);
    }

    this.updateAvailableProcessTaskOfferToProcessTaskPositions();
  }

  public deselectAll(): void {
    for (const positionListItem of this.positionListItems.values()) {
      positionListItem.setChecked(false);
    }

    this.updateAvailableProcessTaskOfferToProcessTaskPositions();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this.updateAvailablePositions.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationCategory,
      this.updateAvailablePositions.bind(this)
    );
    this.updateAvailablePositions();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTaskPosition,
      this.updateAvailableProcessTaskOfferToProcessTaskPositions.bind(this)
    );
    this.updateAvailableProcessTaskOfferToProcessTaskPositions();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () =>
          this.processTaskGroup
            ? { processTaskGroupId: this.processTaskGroup.id }
            : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTaskGroup.id',
            updateSubscription
          );
        },
        callback: (config) => {
          this.calculator = new ProcessTaskPositionCalculator(config);
          this.updatePositionPrices();
        },
        onNoComputeData: () => {
          this.calculator = null;
        }
      }),
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer,
        createComputeData: () =>
          this.processTask ? { ownerProcessTaskId: this.processTask.id } : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTask.id',
            updateSubscription
          );
        },
        callback: (result) => {
          this.detailEntriesByPositionId = result;
          this.updatePositionPrices();
        }
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateAvailablePositions();
      this.updateAvailableProcessTaskOfferToProcessTaskPositions();
    }
  }

  protected processTaskOfferChanged(): void {
    if (this.isAttached) {
      this.updateAvailablePositions();
      this.updateAvailableProcessTaskOfferToProcessTaskPositions();
    }
  }

  private updateAvailablePositions(): void {
    const processTask = this.processTask;
    const processTaskOffer = this.processTaskOffer;

    if (processTask && processTaskOffer) {
      const category = processTaskOffer.processConfigurationCategoryId
        ? this.entityManager.processConfigurationCategoryRepository.getById(
            processTaskOffer.processConfigurationCategoryId
          )
        : null;
      const offerIsGeneral = category ? category.general : false;
      const positions =
        this.entityManager.processTaskPositionRepository.getByProcessTaskIdWithoutSnapshots(
          processTask.id
        );
      const filteredPositions = positions.filter((p) => {
        return (
          offerIsGeneral ||
          processTaskOffer.processConfigurationCategoryId == null ||
          p.processConfigurationCategoryId == null ||
          processTaskOffer.processConfigurationCategoryId ===
            p.processConfigurationCategoryId
        );
      });

      this.availablePositions =
        ProcessTaskPositionUtils.sortProcessTaskPositions(filteredPositions);
      this.processTaskPositionCount = this.availablePositions.length;
    } else {
      this.availablePositions = [];
      this.processTaskPositionCount = null;
    }

    this.updatePositionPrices();
    this.updateAllPositionsSelected();
  }

  private updateAvailableProcessTaskOfferToProcessTaskPositions(): void {
    if (this.processTaskOffer) {
      this.availableProcessTaskOfferToProcessTaskPositions =
        this.entityManager.processTaskOfferToProcessTaskPositionRepository.getByProcessTaskOfferId(
          this.processTaskOffer.id
        );
    } else {
      this.availableProcessTaskOfferToProcessTaskPositions = [];
    }

    this.updateIncludedPositionPrices();
    this.updateAllPositionsSelected();
  }

  private updatePositionPrices(): void {
    if (this.calculator) {
      this.positionPrices = this.calculator.calculatePricesOfPositions(
        this.createPositionInfos()
      );
    } else {
      this.positionPrices = [];
    }
    this.updateIncludedPositionPrices();
  }

  private updateIncludedPositionPrices(): void {
    this.includedPositionPrices = this.positionPrices.filter((prices) => {
      return this.positionIdIsIncluded(
        prices.originalPosition.id,
        this.availableProcessTaskOfferToProcessTaskPositions
      );
    });
  }

  private updateAllPositionsSelected(): void {
    this.allPositionsSelected = this.availablePositions.every((position) => {
      return this.availableProcessTaskOfferToProcessTaskPositions.find(
        (otp) => otp.processTaskPositionId === position.id
      );
    });
  }

  private createPositionInfos(): Array<
    PositionInfo<ProcessTaskPosition, ProcessTaskPositionDetailEntry>
  > {
    return this.availablePositions.map((p) => {
      return {
        ownerProcessTaskId: p.ownerProcessTaskId,
        type: p.type,
        customType: p.customType,
        amount: p.amount,
        price: p.price,
        flatRate: p.flatRate,
        excludeFromMarkup: p.excludeFromMarkup,
        ignoreExcludeFromMarkup: p.ignoreExcludeFromMarkup,
        discount: p.discount,
        detailEnabled: p.detailEnabled,
        detailEntryInfos:
          ProcessTaskPositionDetailEntryUtils.getDetailEntryInfos(
            this.detailEntriesByPositionId.get(p.id) ?? []
          ),
        originalPosition: p
      };
    });
  }

  private positionIdIsIncluded(
    positionId: string,
    availableProcessTaskOfferToProcessTaskPositions: Array<ProcessTaskOfferToProcessTaskPosition>
  ): boolean {
    return !!availableProcessTaskOfferToProcessTaskPositions.find(
      (r) => r.processTaskPositionId === positionId
    );
  }
}
