import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { EntityName } from 'common/Types/BaseEntities/EntityName';
import { IPictureMarking } from 'common/Types/Entities/Picture/PictureDto';
import { PictureRevisionTransformationCalculator } from 'common/PictureRevision/PictureRevisionTransformationCalculator';

import { watch } from '../../hooks/watch';
import { expression, model } from '../../hooks/dependencies';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import {
  TZoomBoxResizerHelperPicturePositionInfo,
  ZoomBoxPictureResizer
} from '../../aureliaComponents/zoom-box/ZoomBoxPictureResizer';
import {
  MarkingsTransformation,
  PictureRevision
} from '../../classes/EntityManager/entities/PictureRevision/types';
import { ContentClickedEvent } from '../../aureliaComponents/zoom-box/zoom-box';
import { SelectionMode } from '../picture-revision-comparison-full-screen-dialog/picture-revision-comparison-full-screen-dialog';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

@autoinject()
export class PictureRevisionComparisonPicture {
  @bindable protected picture: Picture | null = null;
  @bindable protected pictureRevision: PictureRevision | null = null;
  @bindable protected pictureMarkings: Array<IPictureMarking> = [];
  @bindable protected positionedMarkings: Array<IPictureMarking> = [];

  @bindable protected requiredSelectionMode: SelectionMode = SelectionMode.NONE;
  @bindable protected currentSelectionMode: SelectionMode = SelectionMode.NONE;

  /**
   * read-only
   */
  @bindable
  protected referencePointData: MarkingCorrectionReferencePointData =
    this.createDefaultReferencePointData();

  private readonly zoomBoxElement: HTMLElement | null = null;
  private pictureElement: HTMLElement | null = null;
  private pictureResizer: ZoomBoxPictureResizer | null = null;
  protected picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo | null =
    null;

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public resetReferencePointData(): void {
    this.referencePointData = this.createDefaultReferencePointData();
  }

  public getTransformationFromPictureRevision(): MarkingsTransformation | null {
    return this.pictureRevision?.markingsTransformation ?? null;
  }

  public saveTransformationToPictureRevision(
    transformation: MarkingsTransformation
  ): void {
    assertNotNullOrUndefined(
      this.pictureRevision,
      'cannot saveTransformationToPictureRevision without pictureRevision'
    );

    this.pictureRevision.markingsTransformation = transformation;
    this.entityManager.pictureRevisionRepository.update(this.pictureRevision);
  }

  protected attached(): void {
    assertNotNullOrUndefined(
      this.pictureElement,
      "can't PictureFullScreenOverlay.handleContentAttached without pictureElementToCompareAndEdit"
    );
    assertNotNullOrUndefined(
      this.zoomBoxElement,
      "can't PictureFullScreenOverlay.handleContentAttached without zoomBoxElementToCompareAndEdit"
    );

    this.resetReferencePointData();

    this.pictureResizer = new ZoomBoxPictureResizer(
      this.pictureElement,
      this.zoomBoxElement
    );
    this.pictureResizer.onAfterUpdate((picturePositionInfo) => {
      this.picturePositionInfo = picturePositionInfo;
    });
    this.pictureResizer.update();

    this.updateMarkingPositions();
  }

  protected detached(): void {
    if (this.pictureResizer) {
      this.pictureResizer.destroy();
      this.pictureResizer = null;
    }
  }

  protected handleZoomBoxContentClicked(event: ContentClickedEvent): void {
    if (
      this.currentSelectionMode !== this.requiredSelectionMode ||
      this.referencePointData.allSet
    )
      return;

    assertNotNullOrUndefined(
      this.picturePositionInfo,
      "can't compute marking position without picturePositionInfo"
    );

    const marking =
      PictureRevisionTransformationCalculator.computeMarkerOnImageFromPixelPositionVector(
        event.detail.position,
        this.picturePositionInfo
      );
    this.setNextReferencePoint(marking);
  }

  private createDefaultReferencePointData(): MarkingCorrectionReferencePointData {
    return {
      point1: null,
      point2: null,
      point3: null,
      anySet: false,
      allSet: false
    };
  }

  @watch(
    expression('picture'),
    model(EntityName.Project),
    model(EntityName.Picture),
    model(EntityName.PictureRevision)
  )
  private updateMarkingPositions(): void {
    if (!this.pictureRevision?.markingsTransformation) {
      this.positionedMarkings = this.pictureMarkings;
      return;
    }

    this.positionedMarkings = [];

    for (const marking of this.pictureMarkings) {
      const updatedMarking =
        PictureRevisionTransformationCalculator.computeMarkingPositionsBasedOnTransformationMatrix(
          marking,
          this.pictureRevision.markingsTransformation
        );

      this.positionedMarkings.push(updatedMarking);
    }
  }

  private setNextReferencePoint(marking: IPictureMarking): void {
    if (!this.referencePointData.point1) {
      this.referencePointData.point1 = marking;
      this.referencePointData.anySet = true;
    } else if (!this.referencePointData.point2) {
      this.referencePointData.point2 = marking;
    } else if (!this.referencePointData.point3) {
      this.referencePointData.point3 = marking;
      this.referencePointData.allSet = true;
    }
  }
}

export type MarkingCorrectionReferencePointData = {
  point1: IPictureMarking | null;
  point2: IPictureMarking | null;
  point3: IPictureMarking | null;
  allSet: boolean;
  anySet: boolean;
};
