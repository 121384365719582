import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ExprEvalParser } from 'common/ExprEvalParser/ExprEvalParser';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { ThingType } from '../../classes/EntityManager/entities/ThingType/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Status } from '../../classes/RequestWithStatus';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { SocketService } from '../../services/SocketService';
import { RequestWithStatusService } from '../../services/RequestWithStatusService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ThingGroupSharepointSettingsWidget {
  @bindable public thingGroup: ThingGroup | null = null;

  protected readonly checkSharepointUrlAccessRequestStatus;
  protected readonly checkListExistenceRequestStatus;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingGroup];

  private exprEvalParser = new ExprEvalParser();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    requestWithStatusService: RequestWithStatusService,
    permissionsService: PermissionsService
  ) {
    this.checkSharepointUrlAccessRequestStatus =
      requestWithStatusService.createRequestWithStatus(async () => {
        if (!this.thingGroup) return Status.NO_STATUS;

        const siteUrl = this.thingGroup.sharepointSettings.sharepointUrl;
        if (!siteUrl) return Status.NO_STATUS;

        const response =
          await this.socketService.sharepointSocketEndpoints.checkSharepointAccess(
            {
              userGroupId: this.thingGroup.ownerUserGroupId,
              siteUrl
            }
          );

        if (response.success && response.hasAccess) return Status.OK;

        return Status.ERROR;
      });

    this.checkListExistenceRequestStatus =
      requestWithStatusService.createRequestWithStatus(async () => {
        if (!this.thingGroup) return Status.NO_STATUS;

        const siteUrl = this.thingGroup.sharepointSettings.sharepointUrl;
        const listName = this.thingGroup.sharepointSettings.listName;
        if (!siteUrl || !listName) return Status.NO_STATUS;

        const response =
          await this.socketService.sharepointSocketEndpoints.listExists({
            userGroupId: this.thingGroup.ownerUserGroupId,
            siteUrl,
            listName
          });

        if (response.success && response.listExists) return Status.OK;

        return Status.ERROR;
      });

    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.ThingGroup,
        context: this as ThingGroupSharepointSettingsWidget,
        propertyName: 'thingGroup'
      }
    );
  }

  protected attached(): void {
    this.checkSharepointUrlAccess();
    this.checkListExistence();
  }

  @computed(
    expression('thingGroup.ownerUserGroupId'),
    model(EntityName.ThingType)
  )
  protected get thingTypes(): Array<ThingType> {
    if (!this.thingGroup) return [];
    return this.entityManager.thingTypeRepository.getByUserGroupId(
      this.thingGroup.ownerUserGroupId
    );
  }

  protected handleSharepointUrlChanged(): void {
    this.handleThingGroupChanged();

    this.checkSharepointUrlAccess();
    this.checkListExistence();
  }

  protected handleSharepointListPathChanged(): void {
    this.handleThingGroupChanged();
    this.checkListExistence();
  }

  protected handleSelectedThingTypeChanged(): void {
    this.handleThingGroupChanged();
  }

  private handleThingGroupChanged(): void {
    assertNotNullOrUndefined(
      this.thingGroup,
      'cannot update without a thing group'
    );
    this.entityManager.thingGroupRepository.update(this.thingGroup);
  }

  private checkSharepointUrlAccess(): void {
    if (this.sharepointUrlHasTemplates()) return;

    this.checkSharepointUrlAccessRequestStatus.startRequest();
  }

  private checkListExistence(): void {
    if (this.sharepointListPathHasTemplates()) return;

    this.checkListExistenceRequestStatus.startRequest();
  }

  private sharepointUrlHasTemplates(): boolean {
    return this.exprEvalParser.stringHasExpressions(
      this.thingGroup?.sharepointSettings.sharepointUrl ?? ''
    );
  }

  private sharepointListPathHasTemplates(): boolean {
    return this.exprEvalParser.stringHasExpressions(
      this.thingGroup?.sharepointSettings.listName ?? ''
    );
  }
}
