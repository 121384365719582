import { Entry } from './types';

export class EntryUtils {
  private constructor() {}

  public static getPageDepthIndex({
    path,
    noTrailingDot = false
  }: {
    path: Array<Entry>;
    noTrailingDot?: boolean;
  }): string {
    const listPositions = [...path]
      .reverse()
      .map((entry) => entry.list_position + 1);

    let indexString = listPositions.join('.');

    if (!noTrailingDot) indexString += '.';

    return indexString;
  }

  public static sortInPlace(entries: Array<Entry>): Array<Entry> {
    return entries.sort((a, b) => {
      return a.list_position - b.list_position;
    });
  }

  /**
   * Since some/old entries have a pageDepth parent of '' instead of null, this function is needed for comparisons or indexing.
   *
   * Empty strings will be converted to null
   */
  public static getNormalizedPageDepthParent(
    pageDepthParent: string | null
  ): string | null {
    return pageDepthParent || null;
  }
}
