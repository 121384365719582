export class ArrayBufferReader {
  public readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result instanceof ArrayBuffer) {
          resolve(reader.result);
        } else {
          reject(
            new Error(`received wrong result type: "${typeof reader.result}"`)
          );
        }
      };

      reader.onerror = () => {
        reject(new Error(reader.error?.message));
      };

      reader.onabort = () => {
        reject(new Error('reader aborted'));
      };

      reader.readAsArrayBuffer(file);
    });
  }
}
