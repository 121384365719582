import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { ProcessTaskMeasurePoint } from '../../../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { processTaskMeasurePointEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskMeasurePoint/processTaskMeasurePointEntityInfo';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { ProcessTaskGroupAuthorizationComputer } from '../../../../computedValues/computers/ProcessTaskGroupAuthorizationComputer/ProcessTaskGroupAuthorizationComputer';

export class ProcessTaskMeasurePointAdapter
  implements EntityAdapter<ProcessTaskMeasurePoint>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private processTaskIdsWhereUserIsAuthorized: Set<string> | null = null;

  constructor(options: ProcessTaskMeasurePointAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskGroupAuthorizationComputer,
        computeData: {},
        callback: ({ processTaskIdsWhereUserIsAuthorized }) => {
          this.processTaskIdsWhereUserIsAuthorized =
            processTaskIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.processTaskIdsWhereUserIsAuthorized != null
    );
  }

  public canDeleteEntity(
    processTaskMeasurePoint: ProcessTaskMeasurePoint
  ): boolean {
    return this.checkPermission({
      processTaskMeasurePoint,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteProcessTaskMeasurePoints()
    });
  }

  public canEditField(
    processTaskMeasurePoint: ProcessTaskMeasurePoint
  ): boolean {
    return this.checkPermission({
      processTaskMeasurePoint,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskMeasurePoints()
    });
  }

  public canCreateProcessTaskMeasurePointReadings(
    processTaskMeasurePoint: ProcessTaskMeasurePoint
  ): boolean {
    return this.checkPermission({
      processTaskMeasurePoint,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateProcessTaskMeasurePointReadings()
    });
  }

  public canEditProcessTaskMeasurePointToPictures(
    processTaskMeasurePoint: ProcessTaskMeasurePoint
  ): boolean {
    return this.checkPermission({
      processTaskMeasurePoint,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskMeasurePoints()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskMeasurePoint,
    ProcessTaskMeasurePoint
  > {
    return processTaskMeasurePointEntityInfo;
  }

  private checkPermission({
    processTaskMeasurePoint,
    checkRolePermission
  }: {
    processTaskMeasurePoint: ProcessTaskMeasurePoint;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkProcessTaskSubEntityPermission({
      entity: processTaskMeasurePoint,
      processTaskIdsWhereUserIsAuthorized:
        this.processTaskIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ProcessTaskMeasurePointAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
