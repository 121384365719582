import { autoinject, observable, computedFrom } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';

import { SubscriptionManager } from '../../classes/SubscriptionManager';

import { Utils } from '../../classes/Utils/Utils';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';

import { EditStructureNavigator } from '../../classes/EditStructureNavigator';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { StructureTemplateEntryTreeItem } from '../../structureTemplateComponents/structure-template-entry-tree-item/structure-template-entry-tree-item';
import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { Dialogs } from '../../classes/Dialogs';
import { SocketService } from '../../services/SocketService';
import { GlobalMenu } from '../../aureliaComponents/global-menu/global-menu';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplateEntry } from '../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { StructureTemplateEntryRecursiveFilter } from '../../classes/EntityManager/entities/StructureTemplateEntry/StructureTemplateEntryRecursiveFilter';
import { StructureTemplateCsvImporter } from '../../classes/CsvImporter/StructureTemplateCsvImporter';
import { FileDownloadService } from '../../services/FileDownloadService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntitiesPermissionChecker } from '../../services/PermissionsService/EntitiesPermissionChecker/EntitiesPermissionChecker';

@autoinject()
export class EditStructureTemplate {
  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly structureTemplatePermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplate];

  @subscribableLifecycle()
  protected readonly structureTemplateEntryPermissionsChecker: EntitiesPermissionChecker<EntityName.StructureTemplateEntry>;

  private activeEntitiesService: ActiveEntitiesService;
  private socketService: SocketService;

  @observable private isMobile: boolean;

  private structureTemplate: StructureTemplate | null = null;

  private availableEntries: Array<StructureTemplateEntry> = [];

  private filteredEntries: Array<StructureTemplateEntry> = [];

  private domElement: Element;

  private treeListElement: HTMLElement | null = null;

  private navigator: EditStructureNavigator | null = null;

  private isAttached = false;
  private isOnline = false;

  @observable private entryFilterString: string;

  private moreButtonChoices: Array<MoreButtonChoice> = [
    {
      name: 'export-as-csv-file',
      labelTk: 'generalPages.editStructureTemplate.exportAsCsvFile',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'cannotExportStructureTemplateAsCsvFile'
    },
    {
      name: 'import-from-csv-file',
      labelTk: 'generalPages.editStructureTemplate.importFromCsvFile',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'cannotImportStructureTemplateFromCsvFile',
      isFileInput: true,
      fileInputAccept: 'text/plain,text/comma-separated-values,.csv'
    }
  ];

  private StructureTemplateEntryTreeItem = StructureTemplateEntryTreeItem;
  private StringUtils = StringUtils;
  private StructureTemplateStatus = StructureTemplateStatus;

  constructor(
    element: Element,
    private readonly fileDownloadService: FileDownloadService,
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService,
    subscriptionManagerService: SubscriptionManagerService,
    activeEntitiesService: ActiveEntitiesService,
    socketService: SocketService
  ) {
    this.domElement = element;

    this.subscriptionManager = subscriptionManagerService.create();

    this.activeEntitiesService = activeEntitiesService;
    this.socketService = socketService;

    this.isMobile = false;
    this.entryFilterString = '';

    this.structureTemplatePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.StructureTemplate,
        context: this,
        expression: 'structureTemplate'
      });

    this.structureTemplateEntryPermissionsChecker =
      permissionsService.getEntitiesPermissionChecker({
        entityName: EntityName.StructureTemplateEntry
      });
  }

  protected activate(params: { structure_template_id: string }): void {
    this.structureTemplate =
      this.entityManager.structureTemplateRepository.getById(
        params.structure_template_id
      );
    assertNotNullOrUndefined(
      this.structureTemplate,
      'structure template not found'
    );

    this.activeEntitiesService.setActiveStructureTemplate(
      this.structureTemplate
    );
  }

  protected deactivate(): void {
    this.activeEntitiesService.setActiveStructureTemplate(null);
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntry,
      this.updateStructureTemplateEntries.bind(this)
    );
    this.updateStructureTemplateEntries();

    GlobalMenu.takeControl(this, {
      visible: this.isMobile,
      choices: this.moreButtonChoices,
      selectedCallbacks: {
        'export-as-csv-file': this.handleExportAsCsvFile.bind(this)
      },
      fileChangedCallbacks: {
        'import-from-csv-file': (event) => {
          void this.handleImportFromCsvFile(event);
        }
      }
    });
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();

    GlobalMenu.releaseControl(this);
  }

  @computedFrom('isOnline')
  private get cannotExportStructureTemplateAsCsvFile(): boolean {
    return !this.isOnline;
  }

  @computedFrom(
    'structureTemplatePermissionsHandle.canCreateStructureTemplateEntries'
  )
  private get cannotImportStructureTemplateFromCsvFile(): boolean {
    return !this.structureTemplatePermissionsHandle
      .canCreateStructureTemplateEntries;
  }

  private isMobileChanged(): void {
    if (GlobalMenu.hasControl(this)) GlobalMenu.setVisible(this, this.isMobile);
  }

  protected entryFilterStringChanged(): void {
    this.updateFilteredEntries();
  }

  private updateFilteredEntries(): void {
    if (!this.entryFilterString) {
      this.filteredEntries = this.availableEntries;
    } else {
      const filter = new StructureTemplateEntryRecursiveFilter(
        this.entityManager
      );
      this.filteredEntries = filter.filter(
        this.availableEntries,
        this.getEntryFilterFunction()
      );
    }
  }

  private getEntryFilterFunction(): (
    entry: StructureTemplateEntry,
    remainingChildren: Array<StructureTemplateEntry>,
    children: Array<StructureTemplateEntry>
  ) => boolean {
    return (entry, remainingChildren) => {
      if (!entry.name) return true;
      const filterStringLowerCase = this.entryFilterString.toLowerCase();
      const entryNameLowerCase = entry.name.toLowerCase();
      return (
        remainingChildren.length > 0 ||
        !!(entryNameLowerCase.indexOf(filterStringLowerCase) > -1)
      );
    };
  }

  private entryHasFilterStringInName(
    entryName: string | null,
    filterString: string
  ): boolean {
    if (!filterString || !entryName) return false;
    const upperCaseEntryName = entryName.toUpperCase();
    const upperCaseFilterString = filterString.toUpperCase();
    return upperCaseEntryName.indexOf(upperCaseFilterString) > -1;
  }

  private async expandToEntry(parentId: string): Promise<void> {
    const parents =
      this.entityManager.structureTemplateEntryRepository.getPathByStructureTemplateEntryId(
        parentId
      );
    const parentsCopy = parents.slice();

    const firstParent = parentsCopy.pop();
    if (!firstParent) return;

    const parentElement = this.getElementForEntryId(firstParent.id);
    if (!parentElement) return;

    const parentElementVM =
      Utils.getViewModelOfElement<StructureTemplateEntryTreeItem>(
        parentElement
      );
    if (parentElementVM) {
      await parentElementVM.expandToEntry(parentsCopy);
    }
  }

  private updateStructureTemplateEntries(): void {
    if (!this.structureTemplate) {
      this.availableEntries = [];
    } else {
      this.availableEntries =
        this.entityManager.structureTemplateEntryRepository
          .getByParentId(this.structureTemplate.id)
          .sort((a, b) => {
            return a.listPosition - b.listPosition;
          });
    }
    this.updateFilteredEntries();
  }

  private getElementForEntryId(entryId: string): HTMLElement | null {
    assertNotNullOrUndefined(
      this.treeListElement,
      'treeListElement is not available'
    );
    return this.treeListElement.querySelector(`#entry-${entryId}`);
  }

  private handleCreateNewStructureTemplateEntryClicked(): void {
    assertNotNullOrUndefined(
      this.structureTemplate,
      'structure template is not available'
    );
    this.entityManager.structureTemplateEntryRepository.create({
      ownerStructureTemplateId: this.structureTemplate.id,
      ownerUserGroupId: this.structureTemplate.ownerUserGroupId
    });
  }

  protected handleDropItem(
    item: StructureTemplateEntryTreeItem,
    entryToReplace: StructureTemplateEntry | null
  ): void {
    assertNotNullOrUndefined(
      item.structureTemplateEntry,
      "structure template entry cannot be 'null' in dropped item"
    );
    const entry = item.structureTemplateEntry;
    const listPosition = entryToReplace ? entryToReplace.listPosition : null;

    if (entry.parentEntryId !== null || !entryToReplace) {
      this.entityManager.structureTemplateEntryRepository.setParentIdOfStructureTemplateEntry(
        entry,
        null
      );
    }

    if (listPosition != null) {
      this.entityManager.structureTemplateEntryRepository.setListPositionOfStructureTemplateEntry(
        entry,
        listPosition
      );
    }
  }

  protected handleActivateDropTarget(
    viewModel: StructureTemplateEntryTreeItem,
    subEntry: StructureTemplateEntry
  ): boolean {
    return viewModel.structureTemplateEntry?.id !== subEntry.id;
  }

  private handleExportAsCsvFile(): void {
    if (!this.structureTemplate) return;

    Dialogs.waitDialogTk();
    this.socketService.exportStructureTemplateAsCsvFile(
      {
        structureTemplateId: this.structureTemplate.id
      },
      (response) => {
        if (response.success) {
          Dialogs.closeAllDialogs();
          void this.fileDownloadService.downloadFileByToken(response.token);
        } else {
          const errorMessageKey = `serverResponses.${response.status}`;
          void Dialogs.errorDialogTk('general.downloadError', errorMessageKey);
        }
      }
    );
  }

  private async handleImportFromCsvFile(event: Event): Promise<void> {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.files || !inputElement.files[0]) return;

    assertNotNullOrUndefined(
      this.structureTemplate,
      'no structure template available'
    );

    const structureTemplateCsvImporter = new StructureTemplateCsvImporter(
      this.entityManager,
      this.structureTemplate,
      this.permissionsService
    );
    await structureTemplateCsvImporter.importStructureTemplateFromCsvFile(
      inputElement.files[0]
    );
    inputElement.value = '';
  }

  @computedFrom(
    'structureTemplateEntryPermissionsChecker.revision',
    'filteredEntries'
  )
  protected get canDragStructureTemplateEntryTreeItems(): boolean {
    return this.structureTemplateEntryPermissionsChecker.allEntitiesHavePermission(
      {
        entities: this.filteredEntries,
        checkPermission: ({ adapter, entity }) => {
          return adapter.canEditField(entity, 'listPosition');
        }
      }
    );
  }
}
