import { assertNotNullOrUndefined } from 'common/Asserts';
import { SortUtils } from 'common/Utils/SortUtils';

import {
  FieldInfosFromConfig,
  FieldType,
  ParsedLineData,
  ValidateCallbackResult
} from '../../aureliaComponents/csv-import-widget/csv-import-widget';

import {
  ImportFromCsvFileDialog,
  CallbackParamsWithoutUserGroup
} from '../../dialogs/import-from-csv-file-dialog/import-from-csv-file-dialog';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';
import { StructureTemplate } from '../EntityManager/entities/StructureTemplate/types';
import {
  StructureTemplateEntry,
  StructureTemplateEntryCreationEntity
} from '../EntityManager/entities/StructureTemplateEntry/types';
import { StructureTemplateEntryGroup } from '../EntityManager/entities/StructureTemplateEntryGroup/types';
import { EntityName } from '../EntityManager/entities/types';

export class StructureTemplateCsvImporter {
  private validationFunction = (
    parsedData: Array<ParsedLineData<FieldInfoConfiguration>>
  ): ValidateCallbackResult => {
    const valid = !parsedData.some(
      (lineData) => !lineData.fields.referenceId && !lineData.fields.customId
    );

    return {
      valid,
      errorMsgTk: valid
        ? null
        : 'classes.CsvImporter.StructureTemplateCsvImporter.missingReferenceAndCustomId'
    };
  };

  private importFromCsvFileFields: FieldInfosFromConfig<FieldInfoConfiguration> =
    [
      {
        field: 'referenceId',
        header: 'Referenz-ID',
        type: FieldType.STRING,
        validateCallback: this.validationFunction
      },
      {
        field: 'customId',
        header: 'ID',
        type: FieldType.STRING,
        validateCallback: this.validationFunction
      },
      { field: 'name', header: 'Name', type: FieldType.STRING },
      { field: 'label', header: 'Beschriftung', type: FieldType.STRING },
      { field: 'abbreviation', header: 'Kürzel', type: FieldType.STRING },
      {
        field: 'parentReferenceId',
        header: 'Elterneintrags-Referenz-ID',
        type: FieldType.STRING,
        extraField: true
      },
      {
        field: 'parentCustomId',
        header: 'Elterneintrags-ID',
        type: FieldType.STRING,
        extraField: true
      },
      {
        field: 'listPosition',
        header: 'Listenposition',
        type: FieldType.NUMBER,
        validateCallback: (parsedData) => {
          const valid = !parsedData.some(
            (data) =>
              Number.isNaN(data.fields.listPosition) ||
              !Number.isInteger(data.fields.listPosition) ||
              Number(data.fields.listPosition) < 0
          );

          return {
            valid,
            errorMsgTk: valid
              ? null
              : 'classes.CsvImporter.StructureTemplateCsvImporter.invalidListPositions'
          };
        },
        validationRequired: true
      },
      {
        field: 'showExcludeButton',
        header: 'Ausschließen-Schaltfläche zeigen',
        type: FieldType.BOOLEAN
      },
      {
        field: 'showCheckButton',
        header: 'Abhaken-Schaltfläche zeigen',
        type: FieldType.BOOLEAN
      },
      {
        field: 'alwaysAllowEntryCreation',
        header: 'Eintragserstellung immer erlauben',
        type: FieldType.BOOLEAN
      },
      {
        field: 'structureTemplateEntryGroupReferenceId',
        header: 'Gruppenzugehörigkeits-Referenz-ID',
        type: FieldType.STRING,
        extraField: true
      },
      {
        field: 'structureTemplateEntryGroupCustomId',
        header: 'Gruppenzugehörigkeits-ID',
        type: FieldType.STRING,
        extraField: true
      },
      {
        field: 'structureTemplateEntryGroupRelationGroupReferenceId',
        header: 'Gruppen-Referenz-ID',
        type: FieldType.STRING,
        extraField: true,
        validateCallback: (parsedData) => {
          const valid = parsedData.every((data) =>
            parsedData.find(
              (data2) =>
                data.fields
                  .structureTemplateEntryGroupRelationGroupReferenceId ===
                data2.fields.structureTemplateEntryGroupReferenceId
            )
          );

          return {
            valid,
            errorMsgTk: valid
              ? null
              : 'classes.CsvImporter.StructureTemplateCsvImporter.noMatchingGroupReferenceId'
          };
        },
        validationRequired: true
      },
      {
        field: 'structureTemplateEntryGroupRelationGroupCustomId',
        header: 'Gruppen-ID',
        type: FieldType.STRING,
        extraField: true,
        validateCallback: (parsedData) => {
          const valid = parsedData.every((data) =>
            parsedData.find(
              (data2) =>
                data.fields.structureTemplateEntryGroupRelationGroupCustomId ===
                data2.fields.structureTemplateEntryGroupCustomId
            )
          );

          return {
            valid,
            errorMsgTk: valid
              ? null
              : 'classes.CsvImporter.StructureTemplateCsvImporter.noMatchingGroupId'
          };
        }
      }
    ];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly structureTemplate: StructureTemplate,
    private readonly permissionsService: PermissionsService
  ) {}

  public async importStructureTemplateFromCsvFile(file: File): Promise<void> {
    await ImportFromCsvFileDialog.open<FieldInfoConfiguration>({
      entityNameTk: 'models.StructureTemplateModel_plural',
      file: file,
      fields: this.importFromCsvFileFields,
      showOverwriteCheckbox: true,
      showUserGroupSelect: false,
      showAdditionalFieldsAsParametersCheckbox: false,
      importFromCsvFileCallback:
        this.handleImportFromCsvFileSubmitted.bind(this)
    });
  }

  private async handleImportFromCsvFileSubmitted(
    detail: CallbackParamsWithoutUserGroup<FieldInfoConfiguration>
  ): Promise<void> {
    const pendingOperations: Array<Operation> = [];

    const lineData = detail.parsedContent;
    for (const lD of lineData) {
      const newStructureTemplateEntry: StructureTemplateEntryCreationEntity = {
        ownerStructureTemplateId: this.structureTemplate.id,
        ownerUserGroupId: this.structureTemplate.ownerUserGroupId,

        referenceId: lD.fields.referenceId,
        customId: lD.fields.customId,

        name: lD.fields.name,
        label: lD.fields.label,
        abbreviation: lD.fields.abbreviation,

        listPosition: Number(lD.fields.listPosition),

        showExcludeButton: Boolean(lD.fields.showExcludeButton),
        showCheckButton: Boolean(lD.fields.showCheckButton),

        alwaysAllowEntryCreation: Boolean(lD.fields.alwaysAllowEntryCreation)
      };

      pendingOperations.push({
        referenceId: lD.fields.referenceId || null,
        customId: lD.fields.customId || null,
        parentReferenceId: lD.fields.parentReferenceId || null,
        parentCustomId: lD.fields.parentCustomId || null,
        structureTemplateEntryGroupReferenceId:
          lD.fields.structureTemplateEntryGroupReferenceId || null,
        structureTemplateEntryGroupCustomId:
          lD.fields.structureTemplateEntryGroupCustomId || null,
        structureTemplateEntryGroupRelationGroupReferenceId:
          lD.fields.structureTemplateEntryGroupRelationGroupReferenceId || null,
        structureTemplateEntryGroupRelationGroupCustomId:
          lD.fields.structureTemplateEntryGroupRelationGroupCustomId || null,
        structureTemplateEntry: newStructureTemplateEntry
      });
    }

    this.sortOperations(pendingOperations);
    await this.executeOperations(pendingOperations, detail.overwrite);
  }

  private sortOperations(operations: Array<Operation>): void {
    operations.sort((op1, op2) => {
      const op1GroupId =
        op1.structureTemplateEntryGroupReferenceId ||
        op1.structureTemplateEntryGroupCustomId;
      const op2GroupId =
        op2.structureTemplateEntryGroupReferenceId ||
        op2.structureTemplateEntryGroupCustomId;

      if (op1GroupId || op2GroupId) {
        if (op1GroupId === op2GroupId) {
          return (
            (op1.structureTemplateEntry.listPosition ?? 0) -
            (op2.structureTemplateEntry.listPosition ?? 0)
          );
        }
        return SortUtils.localeCompareFalsyStrings(op1GroupId, op2GroupId);
      }

      const op1ParentId = op1.parentReferenceId || op1.parentCustomId || '0';
      const op2ParentId = op2.parentReferenceId || op2.parentCustomId || '0';

      if (op1ParentId === op2ParentId) {
        return (
          (op1.structureTemplateEntry.listPosition ?? 0) -
          (op2.structureTemplateEntry.listPosition ?? 0)
        );
      }

      return op1ParentId.localeCompare(op2ParentId);
    });
  }

  private async executeOperations(
    operations: Array<Operation>,
    overwrite = false
  ): Promise<void> {
    for (const operation of operations) {
      const structureTemplateEntries =
        this.entityManager.structureTemplateEntryRepository.getByStructureTemplateId(
          this.structureTemplate.id
        );

      const existingStructureTemplateEntry =
        structureTemplateEntries.find((entry) => {
          if (operation.referenceId) {
            return entry.referenceId === operation.referenceId;
          } else if (operation.customId) {
            return entry.customId === operation.customId;
          }
          return false;
        }) ?? null;

      const existingParentEntry = structureTemplateEntries.find((entry) => {
        if (operation.parentReferenceId) {
          return entry.referenceId === operation.parentReferenceId;
        } else if (operation.parentCustomId) {
          return entry.customId === operation.parentCustomId;
        }
        return false;
      });

      if (existingParentEntry) {
        operation.structureTemplateEntry.parentEntryId = existingParentEntry.id;
      }

      if (
        operation.structureTemplateEntryGroupReferenceId ||
        operation.structureTemplateEntryGroupCustomId
      ) {
        const addedEntryToGroup =
          await this.addEntryToGroupIfAllowed(operation);
        if (!addedEntryToGroup) {
          // since this is sub entry of a group and the entry couldn't get added to the group, we skip importing it
          continue;
        }
      }

      const newStructureTemplateEntry =
        await this.createOrUpdateStructureTemplateEntryIfAllowed({
          existingStructureTemplateEntry,
          overwrite,
          operation
        });

      if (
        newStructureTemplateEntry &&
        (operation.structureTemplateEntryGroupRelationGroupReferenceId ||
          operation.structureTemplateEntryGroupRelationGroupCustomId)
      ) {
        await this.addGroupToEntryIfAllowed(
          operation,
          newStructureTemplateEntry
        );
      }
    }
  }

  private async createOrUpdateStructureTemplateEntryIfAllowed({
    existingStructureTemplateEntry,
    overwrite,
    operation
  }: {
    existingStructureTemplateEntry: StructureTemplateEntry | null;
    overwrite: boolean;
    operation: Operation;
  }): Promise<StructureTemplateEntry | null> {
    if (existingStructureTemplateEntry && overwrite) {
      const canEditAllFields = await this.permissionsService.useAdapterOnce({
        entityName: EntityName.StructureTemplateEntry,
        useAdapter: (adapter) => {
          return (
            Object.keys(operation.structureTemplateEntry) as Array<
              keyof StructureTemplateEntryCreationEntity
            >
          ).every((fieldName) => {
            return adapter.canEditField(
              existingStructureTemplateEntry,
              fieldName
            );
          });
        }
      });

      if (canEditAllFields) {
        Object.assign(
          existingStructureTemplateEntry,
          operation.structureTemplateEntry
        );
        this.entityManager.structureTemplateEntryRepository.update(
          existingStructureTemplateEntry
        );
      }

      return existingStructureTemplateEntry;
    }

    const canCreateStructureTemplateEntries =
      await this.permissionsService.useAdapterOnce({
        entityName: EntityName.StructureTemplate,
        useAdapter: (adapter) => {
          return adapter.canCreateStructureTemplateEntries(
            this.structureTemplate
          );
        }
      });

    if (!canCreateStructureTemplateEntries) {
      return null;
    }

    return this.entityManager.structureTemplateEntryRepository.create(
      operation.structureTemplateEntry
    );
  }

  private async addEntryToGroupIfAllowed(
    operation: Operation
  ): Promise<boolean> {
    const structureTemplateEntryGroups =
      this.entityManager.structureTemplateEntryGroupRepository.getByStructureTemplateId(
        this.structureTemplate.id
      );
    const existingStructureTemplateEntryGroup =
      structureTemplateEntryGroups.find((group) => {
        if (operation.structureTemplateEntryGroupReferenceId) {
          return (
            group.referenceId ===
            operation.structureTemplateEntryGroupReferenceId
          );
        }
        if (operation.structureTemplateEntryGroupCustomId) {
          return (
            group.customId === operation.structureTemplateEntryGroupCustomId
          );
        }
        return false;
      });
    operation.structureTemplateEntry.parentEntryId = null;
    if (existingStructureTemplateEntryGroup) {
      operation.structureTemplateEntry.structureTemplateEntryGroupId =
        existingStructureTemplateEntryGroup.id;
    } else {
      const canCreateStructureTemplateEntryGroups =
        await this.permissionsService.useAdapterOnce({
          entityName: EntityName.StructureTemplate,
          useAdapter: (adapter) => {
            return adapter.canCreateStructureTemplateEntryGroups(
              this.structureTemplate
            );
          }
        });

      if (!canCreateStructureTemplateEntryGroups) {
        return false;
      }

      const newStructureTemplateEntryGroup =
        this.entityManager.structureTemplateEntryGroupRepository.create({
          ownerUserGroupId: this.structureTemplate.ownerUserGroupId,
          ownerStructureTemplateId: this.structureTemplate.id,
          customId: operation.structureTemplateEntryGroupCustomId,
          referenceId: operation.structureTemplateEntryGroupReferenceId
        });
      operation.structureTemplateEntry.structureTemplateEntryGroupId =
        newStructureTemplateEntryGroup.id;
    }

    return true;
  }

  private async addGroupToEntryIfAllowed(
    operation: Operation,
    structureTemplateEntry: StructureTemplateEntry
  ): Promise<void> {
    const existingStructureTemplateEntryGroup =
      this.getExistingStructureTemplateEntryGroup(operation);
    assertNotNullOrUndefined(
      existingStructureTemplateEntryGroup,
      'cannot add non-existing group to structure template entry'
    );

    const relation =
      this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.getByStructureTemplateEntryId(
        structureTemplateEntry.id
      );
    if (relation) {
      const canEditStructureTemplateEntryGroupId =
        await this.permissionsService.useAdapterOnce({
          entityName:
            EntityName.StructureTemplateEntryGroupToStructureTemplateEntry,
          useAdapter: (adapter) => {
            return adapter.canEditField(relation); // structureTemplateEntryGroupId
          }
        });

      if (!canEditStructureTemplateEntryGroupId) {
        return;
      }

      relation.structureTemplateEntryGroupId =
        existingStructureTemplateEntryGroup.id;
      this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.update(
        relation
      );
    } else {
      const canCreateStructureTemplateEntryGroupToStructureTemplateEntry =
        await this.permissionsService.useAdapterOnce({
          entityName: EntityName.StructureTemplateEntryGroup,
          useAdapter: (adapter) => {
            return adapter.canEditStructureTemplateEntryGroupToStructureTemplateEntries(
              existingStructureTemplateEntryGroup
            );
          }
        });

      if (!canCreateStructureTemplateEntryGroupToStructureTemplateEntry) {
        return;
      }

      this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.create(
        {
          ownerUserGroupId: this.structureTemplate.ownerUserGroupId,
          structureTemplateEntryId: structureTemplateEntry.id,
          structureTemplateEntryGroupId: existingStructureTemplateEntryGroup.id
        }
      );
    }
  }

  private getExistingStructureTemplateEntryGroup(
    operation: Operation
  ): StructureTemplateEntryGroup | null {
    return (
      this.entityManager.structureTemplateEntryGroupRepository
        .getByStructureTemplateId(this.structureTemplate.id)
        .find((group) => {
          if (operation.structureTemplateEntryGroupRelationGroupReferenceId) {
            return (
              group.referenceId ===
              operation.structureTemplateEntryGroupRelationGroupReferenceId
            );
          }
          if (operation.structureTemplateEntryGroupRelationGroupCustomId) {
            return (
              group.customId ===
              operation.structureTemplateEntryGroupRelationGroupCustomId
            );
          }
          return false;
        }) ?? null
    );
  }
}

type FieldInfoConfiguration = {
  entityType: StructureTemplateEntry;
  fieldInfos: [
    {
      field: 'referenceId';
      type: FieldType.STRING;
    },
    {
      field: 'customId';
      type: FieldType.STRING;
    },
    {
      field: 'name';
      type: FieldType.STRING;
    },
    {
      field: 'label';
      type: FieldType.STRING;
    },
    {
      field: 'abbreviation';
      type: FieldType.STRING;
    },
    {
      field: 'parentReferenceId';
      type: FieldType.STRING;
    },
    {
      field: 'parentCustomId';
      type: FieldType.STRING;
    },
    {
      field: 'listPosition';
      type: FieldType.NUMBER;
    },
    {
      field: 'showExcludeButton';
      type: FieldType.BOOLEAN;
    },
    {
      field: 'showCheckButton';
      type: FieldType.BOOLEAN;
    },
    {
      field: 'alwaysAllowEntryCreation';
      type: FieldType.BOOLEAN;
    },
    {
      field: 'structureTemplateEntryGroupReferenceId';
      type: FieldType.STRING;
    },
    {
      field: 'structureTemplateEntryGroupCustomId';
      type: FieldType.STRING;
    },
    {
      field: 'structureTemplateEntryGroupRelationGroupReferenceId';
      type: FieldType.STRING;
    },
    {
      field: 'structureTemplateEntryGroupRelationGroupCustomId';
      type: FieldType.STRING;
    }
  ];
};

type Operation = {
  referenceId: string | null;
  customId: string | null;
  parentReferenceId: string | null;
  parentCustomId: string | null;
  structureTemplateEntryGroupReferenceId: string | null;
  structureTemplateEntryGroupCustomId: string | null;
  structureTemplateEntryGroupRelationGroupReferenceId: string | null;
  structureTemplateEntryGroupRelationGroupCustomId: string | null;
  structureTemplateEntry: StructureTemplateEntryCreationEntity;
};
