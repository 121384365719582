import { Entry } from './types';
import {
  EntriesByParentEntryId,
  EntriesByParentEntryIdHandle
} from '../../../../computedValues/computers/EntriesByParentEntryIdComputer/EntriesByParentEntryIdComputer';

export class EntryRecursiveFilter {
  public filter({
    entries,
    entryConditionCallback,
    entriesByParentEntryIdHandle
  }: {
    entries: Array<Entry>;
    entryConditionCallback: EntryConditionCallback;
    entriesByParentEntryIdHandle: EntriesByParentEntryIdHandle;
  }): Array<Entry> {
    return this.filterRecursive({
      entries,
      entryConditionCallback,
      entriesByParentEntryId:
        entriesByParentEntryIdHandle.getEntriesByParentEntryId()
    });
  }

  private filterRecursive({
    entries,
    entryConditionCallback,
    entriesByParentEntryId
  }: {
    entries: Array<Entry>;
    entryConditionCallback: EntryConditionCallback;
    entriesByParentEntryId: EntriesByParentEntryId;
  }): Array<Entry> {
    const returnEntries = [];

    for (const entry of entries) {
      const children = entriesByParentEntryId.get(entry.id) ?? [];

      let remainingChildren: Array<Entry> = [];
      if (children.length) {
        remainingChildren = this.filterRecursive({
          entries: children,
          entryConditionCallback,
          entriesByParentEntryId
        });
      }

      if (entryConditionCallback(entry, remainingChildren, children)) {
        returnEntries.push(entry);
      }
    }

    return returnEntries;
  }
}

export type EntryConditionCallback = (
  entry: Entry,
  remainingChildren: Array<Entry>,
  children: Array<Entry>
) => boolean;
