import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ExpandableContainer } from '../../../aureliaComponents/expandable-container/expandable-container';
import { Dialogs } from '../../../classes/Dialogs';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { RegionProperty } from '../../../classes/EntityManager/entities/Property/types';
import { Region } from '../../../classes/EntityManager/entities/Region/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { computed } from '../../../hooks/computed';
import { expression, model } from '../../../hooks/dependencies';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class EditRegionsWidgetRegion {
  @bindable()
  public region: Region | null = null;

  @subscribableLifecycle()
  protected readonly regionPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Region];

  protected expandableContainer: ExpandableContainer | null = null;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.regionPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Region,
        context: this as EditRegionsWidgetRegion,
        propertyName: 'region'
      });
  }

  public expand(): void {
    this.expandableContainer?.expand();
  }

  protected handleRegionChanged(): void {
    assertNotNullOrUndefined(
      this.region,
      "can't EditRegionsWidgetRegion.handleRegionChanged without region"
    );

    this.entityManager.regionRepository.update(this.region);

    DomEventHelper.fireEvent<RegionChangedEvent>(this.element, {
      name: 'region-changed',
      detail: null
    });
  }

  protected handleDeleteClick(): void {
    const region = this.region;

    assertNotNullOrUndefined(
      region,
      "can't EditRegionsWidgetRegion.handleDeleteClick without region"
    );

    void Dialogs.deleteEntityDialog(region).then(() => {
      this.entityManager.regionRepository.delete(region);
    });
  }

  @computed(expression('region'), model(EntityName.Region))
  protected get properties(): Array<RegionProperty> {
    if (!this.region) {
      return [];
    }

    return this.entityManager.propertyRepository
      .getByRegionId(this.region.id)
      .filter((prop) => !prop.hidden);
  }
}

/**
 * this event gets fired after the changes were already persisted
 */
export type RegionChangedEvent = NamedCustomEvent<'region-changed', null>;
