import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  PropertyOption,
  PropertyOptionDefinition
} from 'common/Types/Entities/Property/PropertyDto';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';

import { PropertyDefinitionWidgetHandle } from '../PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle';
import { OptionChangedEvent } from '../property-option-definition-widget/property-option-definition-widget';

@autoinject
export class PropertyDefinitionWidgetOptionsWidget<TPropertyDefinition> {
  @bindable
  public handle: PropertyDefinitionWidgetHandle<TPropertyDefinition> | null =
    null;

  @computedFrom('handle.type')
  protected get optionDefinitions(): Array<PropertyOptionDefinition> {
    if (!this.handle) return [];

    return PropertyHelper.getOptionsOfPropertyType(this.handle.type);
  }

  protected handleAddPropertyOptionClick(): void {
    assertNotNullOrUndefined(
      this.handle,
      "can't add property option without a handle"
    );

    const options = this.handle.options.slice();
    ArrayUtils.pushUnique(options, { name: '', value: '' });

    this.handle.setOptions(options);
  }

  protected handlePropertyOptionChanged(
    index: number,
    event: OptionChangedEvent
  ): void {
    assertNotNullOrUndefined(
      this.handle,
      "can't update property option without a handle"
    );

    const options = this.handle.options.slice();
    options.splice(index, 1, event.detail.option);

    this.handle.setOptions(options);
  }

  protected handleDeletePropertyOptionClick(option: PropertyOption): void {
    assertNotNullOrUndefined(
      this.handle,
      "can't delete property option without a handle"
    );

    const options = this.handle.options.slice();
    ArrayUtils.remove(options, option);

    this.handle.setOptions(options);
  }
}
