import { autoinject } from 'aurelia-framework';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { ProcessTaskMeasurePoint } from '../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types';
import { ProcessTaskMeasurePointReading } from '../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { EditProcessTaskMeasurePointReadingTable } from '../edit-process-task-measure-point-reading-table/edit-process-task-measure-point-reading-table';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { configureHooks } from '../../hooks/configureHooks';

@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class EditProcessTaskMeasurePointReadingDialog {
  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private dialog: RecordItDialog | null = null;

  @subscribableLifecycle()
  protected readonly measurePointPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskMeasurePoint];

  private measurePoint: ProcessTaskMeasurePoint | null = null;
  protected measurePointReading: ProcessTaskMeasurePointReading | null = null;
  protected processTaskGroup: ProcessTaskGroup | null = null;
  private readingTable: EditProcessTaskMeasurePointReadingTable | null = null;

  private onDialogClosed: OnDialogClosed | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.entityManager = entityManager;

    this.measurePointPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskMeasurePoint,
        context: this,
        expression: 'measurePoint'
      });
  }

  public open(options: OpenOptions): void {
    this.measurePoint = options.measurePoint;
    this.measurePointReading = options.measurePointReading;
    this.processTaskGroup = options.processTaskGroup;
    this.onDialogClosed = options.onDialogClosed ?? null;

    if (this.dialog) {
      this.dialog.open();
    }
  }

  protected handleDialogClosed(): void {
    const onDialogClosed = this.onDialogClosed;

    this.measurePoint = null;
    this.measurePointReading = null;
    this.onDialogClosed = null;

    onDialogClosed && onDialogClosed();
  }

  protected handleMeasurePointChanged(): void {
    if (this.measurePoint) {
      this.entityManager.processTaskMeasurePointRepository.update(
        this.measurePoint
      );
    }
  }

  protected handleRecordItDialogOpened(): void {
    this.readingTable?.focus();
  }
}

export type OpenOptions = {
  processTaskGroup: ProcessTaskGroup;
  measurePoint: ProcessTaskMeasurePoint;
  measurePointReading: ProcessTaskMeasurePointReading;
  onDialogClosed?: OnDialogClosed | null;
};

type OnDialogClosed = () => void;
