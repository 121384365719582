import { autoinject, bindable } from 'aurelia-framework';
import { ProjectQuestion } from '../../classes/EntityManager/entities/ProjectQuestion/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { RecommendationTemplate } from 'common/Types/Entities/Question/QuestionDto';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { DataStorageCache } from '../../classes/DataStorageCache/DataStorageCache';
import { watch } from '../../hooks/watch';
import { SocketService } from '../../services/SocketService';
import { EntityName } from 'common/Types/BaseEntities/EntityName';

@autoinject()
export class ChecklistProjectQuestionRecommendationWidget {
  @bindable public question: ProjectQuestion | null = null;

  public static readonly RECOMMENDATIONS_CACHE = new DataStorageCache<
    Array<{
      projectQuestionId: string;
      recommendation: string;
    }>
  >('ChecklistProjectQuestionRecommendationWidget::recommendations');

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService
  ) {}

  protected attached(): void {
    this.updateRecommendations();
  }

  protected handleQuestionUpdated(): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleQuestionUpdated without question'
    );

    this.entityManager.projectQuestionRepository.update(this.question);
  }

  @watch(model(EntityName.ProjectQuestion), model(EntityName.Project))
  protected updateRecommendations(): void {
    if (this.socketService.isConnected()) {
      void ChecklistProjectQuestionRecommendationWidget.RECOMMENDATIONS_CACHE.update(
        this.socketService.entityInfoModuleEndpoints.getProjectQuestionRecommendations()
      );
    }
  }

  @computed(
    expression(
      ChecklistProjectQuestionRecommendationWidget,
      'RECOMMENDATIONS_CACHE.value'
    ),
    model(EntityName.ProjectQuestion),
    expression('question.id')
  )
  protected get previousRecommendations(): Array<string> {
    return ArrayUtils.unique(
      [
        ...(ChecklistProjectQuestionRecommendationWidget.RECOMMENDATIONS_CACHE
          .value ?? []),
        ...this.entityManager.projectQuestionRepository.getAll().map((pq) => ({
          projectQuestionId: pq.id,
          recommendation: pq.recommendation
        }))
      ].filter((pq) => pq.projectQuestionId !== this.question?.id),
      (i) => i.recommendation
    )
      .map((pq) => pq.recommendation)
      .filter((recommendation): recommendation is string => !!recommendation);
  }

  @computed(expression('question'), expression('previousRecommendations'))
  protected get suggestions(): Array<RecommendationTemplate> {
    return ArrayUtils.unique(
      [
        ...(this.question ? this.question?.recommendationTemplates : []),
        ...this.previousRecommendations.map((r) => ({ content: r }))
      ],
      (i) => i.content
    );
  }
}
