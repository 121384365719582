import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { DefectCopiedEvent } from '../../defectComponents/edit-defect-details-widget/edit-defect-details-widget';

/**
 * Dialog for editing details of a defect (name, description, dueAt, etc.)
 */
@autoinject()
export class EditDefectDialog {
  protected defect: Defect | null = null;

  protected dialog: RecordItDialog | null = null;

  protected showPictures: boolean = true;

  /**
   * true if a user is currently being invited over email,
   * false otherwise.
   */
  protected isInvitingUser: boolean = false;

  public static async open(
    options: EditDefectDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private open(options: EditDefectDialogOpenOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open EditDefectDialog without a RecordItDialog.'
    );
    this.defect = options.defect;
    this.showPictures = options.showPictures ?? true;

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.defect = null;
    this.showPictures = true;
  }

  protected handleDefectCopied(event: DefectCopiedEvent): void {
    this.defect = event.detail.defect;
  }
}

export type EditDefectDialogOpenOptions = {
  defect: Defect;
  /**
   * If true, the pictures of the defect will be shown as well, similar to the Defect Detail View.
   * If false, the pictures will be hidden, so only the defect details (title, description, dueAt, etc.) can be edited.
   */
  showPictures?: boolean;
};
