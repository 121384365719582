import { autoinject } from 'aurelia-framework';
import { Project } from '../classes/EntityManager/entities/Project/types';
import { QuestionCatalogue } from '../classes/EntityManager/entities/QuestionCatalogue/types';
import { QuestionSet } from '../classes/EntityManager/entities/QuestionSet/types';
import { Question } from '../classes/EntityManager/entities/Question/types';
import { QuestionCategory } from '../classes/EntityManager/entities/QuestionCategory/types';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { ProjectQuestionSet } from '../classes/EntityManager/entities/ProjectQuestionSet/types';
import { ProjectQuestionCategory } from '../classes/EntityManager/entities/ProjectQuestionCategory/types';
import { ProjectQuestionCatalogue } from '../classes/EntityManager/entities/ProjectQuestionCatalogue/types';
import { QuestionCatalogueToQuestionSet } from '../classes/EntityManager/entities/QuestionCatalogueToQuestionSet/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { ProjectQuestionCreationEntity } from '../classes/EntityManager/entities/ProjectQuestion/types';

/**
 * Creates necessary entities for an existing project
 * from existing QuestionCatalogues and their related entities.
 */
@autoinject()
export class CreateChecklistEntitiesService {
  private static TEMPORARY_GROUP_NAME = 'CreateChecklistEntitiesService';

  constructor(private readonly entityManager: AppEntityManager) {}

  /**
   * Creates all entities necessary for a checklist project
   * using a project and a question catalogue.
   */
  public create(
    project: Project,
    questionCatalogues: Array<QuestionCatalogue>
  ): void {
    try {
      this.createTemporaryEntities(project, questionCatalogues);
      this.entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
        CreateChecklistEntitiesService.TEMPORARY_GROUP_NAME
      );
    } catch (e) {
      this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
        CreateChecklistEntitiesService.TEMPORARY_GROUP_NAME
      );
      throw e;
    }
  }

  /**
   * returns `true` if checklist entities were already created for `project`, `false` otherwise.
   *
   * TODO: use cache approach in new synchro - see https://gitea.chax.at/recordit/record/pulls/35#issuecomment-6399
   */
  public hasChecklistData(project: Project): boolean {
    return (
      this.entityManager.projectQuestionCatalogueRepository.getByProjectId(
        project.id
      ).length > 0
    );
  }

  /**
   * Remove a project question set from a project.
   */
  public removeProjectQuestionSet(
    projectQuestionSet: ProjectQuestionSet
  ): void {
    // Remove project question set (and cascade delete questions)
    this.entityManager.projectQuestionSetRepository.delete(projectQuestionSet);
  }

  /**
   * Add a question set to a project.
   *
   * Will create new categories if questions in this set are linked to
   * categories which are not available yet.
   */
  public addQuestionSetToProject(
    questionSet: QuestionSet,
    project: Project,
    physicalArea: string | null
  ): void {
    // Create project question set
    const projectQuestionSet =
      this.entityManager.projectQuestionSetRepository.createFromQuestionSet(
        questionSet,
        project
      );

    // Load all related question categories (or create them if they do not exist yet)
    const questions = this.entityManager.questionRepository.getByQuestionSetId(
      questionSet.id
    );
    const questionCategoryIds = ArrayUtils.unique(
      questions.map((q) => q.questionCategoryId)
    ).filter((v): v is string => !!v);
    const questionCategories =
      this.entityManager.questionCategoryRepository.getByIds(
        questionCategoryIds
      );
    const projectCategoryByCategoryId: Map<string, ProjectQuestionCategory> =
      new Map();
    for (const category of questionCategories) {
      const projectQuestionCategory =
        this.entityManager.projectQuestionCategoryRepository.getByCreatedFromQuestionCategoryId(
          {
            questionCategoryId: category.id,
            projectId: project.id
          }
        ) ??
        this.entityManager.projectQuestionCategoryRepository.createFromQuestionCategory(
          category,
          project
        );
      projectCategoryByCategoryId.set(category.id, projectQuestionCategory);
    }

    // Create project questions
    for (const question of questions) {
      let projectQuestionCategoryId: string | null = null;
      if (question.questionCategoryId) {
        const entity = projectCategoryByCategoryId.get(
          question.questionCategoryId
        );
        if (!entity) {
          throw new Error(
            `ProjectQuestionCategory was not loaded for ${question.id}`
          );
        }
        projectQuestionCategoryId = entity.id;
      }

      this.entityManager.projectQuestionRepository.createFromQuestion(
        question,
        {
          projectQuestionCategoryId: projectQuestionCategoryId,
          projectQuestionSetId: projectQuestionSet.id,
          project: project,
          physicalArea
        }
      );
    }
  }

  public addCustomProjectQuestionToProject(
    question: Omit<
      ProjectQuestionCreationEntity,
      | 'createdFromQuestionId'
      | 'ownerProjectId'
      | 'ownerUserGroupId'
      | 'projectQuestionSetId'
    >,
    project: Project
  ): void {
    /**
     * We create a new question set for our custom question
     *
     * Since every custom project question will have their own set,
     * the user can easily delete them again in the UI without affecting
     * other questions.
     */
    const projectQuestionSet =
      this.entityManager.projectQuestionSetRepository.create({
        name: question.text,
        createdFromQuestionSetId: null,
        ownerProjectId: project.id,
        ownerUserGroupId: project.ownerUserGroupId
      });

    this.entityManager.projectQuestionRepository.create({
      ...question,
      createdFromQuestionId: null,
      ownerProjectId: project.id,
      ownerUserGroupId: project.ownerUserGroupId,
      projectQuestionSetId: projectQuestionSet.id
    });
  }

  private createTemporaryEntities(
    project: Project,
    questionCatalogues: Array<QuestionCatalogue>
  ): void {
    const relatedEntities = this.getRelatedEntities(questionCatalogues);
    const projectCatalogueByCatalogueId = new Map<
      string,
      ProjectQuestionCatalogue
    >();
    const projectCategoryByCategoryId = new Map<
      string,
      ProjectQuestionCategory
    >();
    const projectSetBySetId = new Map<string, ProjectQuestionSet>();

    // We create all entities here temporarily so that an issue doesn't leave the project in an unusable state
    const entityCreateOptions = {
      shadowEntity: true,
      temporaryGroupName: CreateChecklistEntitiesService.TEMPORARY_GROUP_NAME
    };

    // Create project question catalogues
    for (const questionCatalogue of questionCatalogues) {
      const projectQuestionCatalogue =
        this.entityManager.projectQuestionCatalogueRepository.createFromQuestionCatalogue(
          questionCatalogue,
          project,
          entityCreateOptions
        );
      projectCatalogueByCatalogueId.set(
        questionCatalogue.id,
        projectQuestionCatalogue
      );
    }

    // Create project question categories
    for (const questionCategory of relatedEntities.questionCategories) {
      const projectQuestionCategory =
        this.entityManager.projectQuestionCategoryRepository.createFromQuestionCategory(
          questionCategory,
          project,
          entityCreateOptions
        );
      projectCategoryByCategoryId.set(
        questionCategory.id,
        projectQuestionCategory
      );
    }

    // Create project question sets
    for (const questionSet of relatedEntities.questionSets) {
      const projectQuestionSet =
        this.entityManager.projectQuestionSetRepository.createFromQuestionSet(
          questionSet,
          project,
          entityCreateOptions
        );
      projectSetBySetId.set(questionSet.id, projectQuestionSet);
    }

    // Create project question catalogues to question sets
    for (const questionCatalogueToQuestionSet of relatedEntities.questionCataloguesToQuestionSets) {
      const projectQuestionSet = projectSetBySetId.get(
        questionCatalogueToQuestionSet.questionSetId
      );
      const projectQuestionCatalogue = projectCatalogueByCatalogueId.get(
        questionCatalogueToQuestionSet.questionCatalogueId
      );
      assertNotNullOrUndefined(
        projectQuestionSet,
        `Did not find projectQuestionSet for ${questionCatalogueToQuestionSet.questionSetId}`
      );
      assertNotNullOrUndefined(
        projectQuestionCatalogue,
        `Did not find projectQuestionCatalogue for ${questionCatalogueToQuestionSet.questionCatalogueId}`
      );

      this.entityManager.projectQuestionCatalogueToQuestionSetRepository.create(
        {
          projectQuestionSetId: projectQuestionSet.id,
          projectQuestionCatalogueId: projectQuestionCatalogue.id,
          ownerProjectId: project.id,
          ownerUserGroupId: project.ownerUserGroupId,
          temporaryGroupName: entityCreateOptions.temporaryGroupName,
          shadowEntity: entityCreateOptions.shadowEntity
        }
      );
    }

    // Create questions
    for (const question of relatedEntities.questions) {
      const projectQuestionCategory = question.questionCategoryId
        ? projectCategoryByCategoryId.get(question.questionCategoryId)
        : null;
      if (question.questionCategoryId)
        assertNotNullOrUndefined(
          projectQuestionCategory,
          `Did not find projectQuestionCategory for ${question.questionCategoryId}`
        );
      const projectQuestionSet = projectSetBySetId.get(question.questionSetId);
      assertNotNullOrUndefined(
        projectQuestionSet,
        `Did not find projectQuestionSet for ${question.questionSetId}`
      );

      this.entityManager.projectQuestionRepository.createFromQuestion(
        question,
        {
          projectQuestionCategoryId: projectQuestionCategory?.id ?? null,
          projectQuestionSetId: projectQuestionSet.id,
          project
        },
        entityCreateOptions
      );
    }
  }

  private getRelatedEntities(
    questionCatalogues: Array<QuestionCatalogue>
  ): QuestionCatalogueRelatedEntities {
    const questionCataloguesToQuestionSets =
      this.entityManager.questionCatalogueToQuestionSetRepository.getByQuestionCatalogueIds(
        questionCatalogues.map((e) => e.id)
      );
    const questionSets = this.entityManager.questionSetRepository.getByIds(
      questionCataloguesToQuestionSets.map((e) => e.questionSetId)
    );
    const questions = this.entityManager.questionRepository.getByQuestionSetIds(
      questionSets.map((e) => e.id)
    );
    const questionsToCopy = questions.filter(
      (q) =>
        q.copy &&
        (!q.questionCategoryId ||
          this.isCopyQuestionCategory(q.questionCategoryId))
    );

    const questionCategories =
      this.entityManager.questionCategoryRepository.getByIds(
        questionsToCopy
          .map((q) => q.questionCategoryId)
          .filter((id): id is string => !!id)
      );

    return {
      questionCataloguesToQuestionSets,
      questionSets: ArrayUtils.unique(questionSets, (e) => e.id),
      questions: ArrayUtils.unique(questionsToCopy, (e) => e.id),
      questionCategories: ArrayUtils.unique(questionCategories, (e) => e.id)
    };
  }

  private isCopyQuestionCategory(questionCategoryId: string): boolean {
    return (
      this.entityManager.questionCategoryRepository.getById(questionCategoryId)
        ?.copy ?? true
    );
  }
}

type QuestionCatalogueRelatedEntities = {
  questionCataloguesToQuestionSets: Array<QuestionCatalogueToQuestionSet>;
  questionSets: Array<QuestionSet>;
  questions: Array<Question>;
  questionCategories: Array<QuestionCategory>;
};
