import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskChecklistEntry } from '../../classes/EntityManager/entities/ProcessTaskChecklistEntry/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { MultiProcessTaskSelectionWidgetProcessTaskHandle } from '../multi-process-task-selection-widget/multi-process-task-selection-widget';
import {
  ChecklistEntryAttachments,
  ProcessTaskChecklistService
} from '../../services/ProcessTaskChecklistService/ProcessTaskChecklistService';
import {
  ProcessTaskChecklistListItemLayout,
  TextChangedEvent
} from './process-task-checklist-list-item-layout/process-task-checklist-list-item-layout';
import { GeneralFileHelper } from 'common/EntityHelper/GeneralFileHelper';
import { MultiFileInput } from '../../inputComponents/multi-file-input/multi-file-input';
import { InlineCreationSection } from '../../aureliaComponents/inline-creation-section/inline-creation-section';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { watch } from '../../hooks/watch';
import {
  arrayChangesWithItemExpression,
  model
} from '../../hooks/dependencies';
import { DisposableItemsCache } from '../../classes/DisposableItemsCache/DisposableItemsCache';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { computed } from '../../hooks/computed';

@autoinject()
export class ProcessTaskChecklist {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public entryCreationEnabled: boolean = false;

  /**
   * null if entries aren't loaded
   * read only
   */
  @bindable()
  public openChecklistEntryCount: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private readonly processTaskHandlesCache: DisposableItemsCache<
    ProcessTask,
    ProcessTaskHandle
  >;

  protected processTaskHandles: Array<ProcessTaskHandle> = [];

  protected availableChecklistEntries: Array<ProcessTaskChecklistEntry> = [];

  private isAttached: boolean = false;
  private newEntryText: string | null = null;
  private showDoneEntries: boolean = false;

  protected newEntryListItem: ProcessTaskChecklistListItemLayout | null = null;
  protected newEntryMultiFileInput: MultiFileInput | null = null;
  protected newEntryInlineCreationSection: InlineCreationSection | null = null;

  protected readonly GeneralFileHelper = GeneralFileHelper;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskChecklistService: ProcessTaskChecklistService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskHandlesCache = new DisposableItemsCache({
      createDisposableForItem: ({ item }) => {
        return new ProcessTaskHandle({
          permissionsService,
          processTask: item
        });
      }
    });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskChecklistEntry,
      this.updateAvailableChecklistEntries.bind(this)
    );
    this.updateAvailableChecklistEntries();

    let defaultShowDoneChecklistEntriesIsSet = false;
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'operations.defaultShowDoneChecklistEntries',
        (defaultShowDoneChecklistEntries) => {
          if (!defaultShowDoneChecklistEntriesIsSet) {
            this.showDoneEntries = defaultShowDoneChecklistEntries;
            this.handleShowDoneEntriesChanged();
            defaultShowDoneChecklistEntriesIsSet = true;
          }
        }
      )
    );

    this.updateProcessTaskHandles();
    this.resetProcessTaskSelection();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateAvailableChecklistEntries();
      this.updateProcessTaskHandles();
      this.resetProcessTaskSelection();
    }
  }

  private updateAvailableChecklistEntries(): void {
    let availableEntries: Array<ProcessTaskChecklistEntry> = [];

    if (this.processTask) {
      availableEntries =
        this.entityManager.processTaskChecklistEntryRepository.getByProcessTaskId(
          this.processTask.id
        );
      this.openChecklistEntryCount = availableEntries.filter(
        (a) => !a.done
      ).length;
    } else {
      this.openChecklistEntryCount = null;
    }

    if (!this.showDoneEntries) {
      availableEntries = availableEntries.filter((a) => !a.done);
    }

    availableEntries.sort((a, b) => {
      return (a.done ? 1 : 0) - (b.done ? 1 : 0);
    });

    this.availableChecklistEntries = availableEntries;
  }

  @watch(model(EntityName.ProcessTask))
  private updateProcessTaskHandles(): void {
    this.processTaskHandles = this.processTaskHandlesCache.mapItems({
      items: this.processTask
        ? this.entityManager.processTaskRepository.getByProcessTaskGroupId(
            this.processTask.ownerProcessTaskGroupId
          )
        : []
    });
  }

  protected handleShowDoneEntriesChanged(): void {
    this.updateAvailableChecklistEntries();
  }

  protected handleCreationSectionOpened(): void {
    assertNotNullOrUndefined(
      this.newEntryListItem,
      "can't handleCreationSectionOpened without newEntryListItem"
    );
    this.newEntryListItem.focus();
  }

  protected handleCreationSectionClosed(): void {
    if (this.newEntryMultiFileInput) {
      this.newEntryMultiFileInput.selectedFiles = [];
    }
  }

  protected handleNewEntryTextChanged(event: TextChangedEvent): void {
    this.newEntryText = event.detail.value as string | null;
  }

  protected handleCreateNewEntryClick(): true {
    void this.createChecklistEntries().then(() => {
      this.updateAvailableChecklistEntries();

      this.newEntryText = null;
      this.resetProcessTaskSelection();
    });

    return true; // do not cancel the event
  }

  protected handleCancelNewEntryClick(): true {
    this.newEntryText = null;

    return true; // do not cancel the event
  }

  private async createChecklistEntries(): Promise<void> {
    const attachments = await this.getAttachments();

    const selectedProcessTasks = this.processTaskHandles
      .filter((handle) => handle.isSelected)
      .map((handle) => handle.processTask);

    for (const processTask of selectedProcessTasks) {
      void this.processTaskChecklistService.createChecklistEntryForProcessTask({
        processTask,
        text: this.newEntryText,
        attachments
      });
    }
  }

  private async getAttachments(): Promise<Array<ChecklistEntryAttachments>> {
    const newEntryMultiFileInput = this.newEntryMultiFileInput;
    assertNotNullOrUndefined(
      newEntryMultiFileInput,
      "can't ProcessTaskCommentInlineCreationWidget.waitForFilesBeingLoaded without newEntryMultiFileInput"
    );

    this.newEntryInlineCreationSection?.startPreloader();
    await newEntryMultiFileInput.selectedFilesLoadedAwaitable();

    this.newEntryInlineCreationSection?.stopPreloader();
    this.newEntryInlineCreationSection?.close();

    return (newEntryMultiFileInput.selectedFiles ?? [])
      .map((selectedFile) => {
        return {
          name: selectedFile.name,
          dataUrl: selectedFile.dataUrl
        };
      })
      .filter((file): file is ChecklistEntryAttachments => {
        return file.dataUrl != null;
      });
  }

  private resetProcessTaskSelection(): void {
    for (const handle of this.processTaskHandles) {
      handle.setSelected(handle.processTask === this.processTask);
    }
  }

  @computed(
    arrayChangesWithItemExpression('processTaskHandles', 'canBeSelected')
  )
  protected get atLeastOneProcessTaskHandleCanBeSelected(): boolean {
    return this.processTaskHandles.some((handle) => handle.canBeSelected);
  }
}

export class ProcessTaskHandle
  implements MultiProcessTaskSelectionWidgetProcessTaskHandle
{
  private internalProcessTask: ProcessTask;

  private readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];
  private readonly processTaskPermissionsHandleDisposable: Disposable;

  private internalSelected = false;

  constructor({
    permissionsService,
    processTask
  }: {
    permissionsService: PermissionsService;
    processTask: ProcessTask;
  }) {
    this.internalProcessTask = processTask;

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTask,
        context: this,
        expression: 'processTask'
      });
    this.processTaskPermissionsHandleDisposable =
      this.processTaskPermissionsHandle.subscribe();
  }

  public setSelected(selected: boolean): void {
    this.internalSelected = selected;
  }

  public dispose(): void {
    this.processTaskPermissionsHandleDisposable.dispose();
  }

  @computedFrom('internalProcessTask')
  public get processTask(): ProcessTask {
    return this.internalProcessTask;
  }

  @computedFrom(
    'processTaskPermissionsHandle.canCreateProcessTaskChecklistEntries'
  )
  public get canBeSelected(): boolean {
    return this.processTaskPermissionsHandle
      .canCreateProcessTaskChecklistEntries;
  }

  @computedFrom(
    'processTaskPermissionsHandle.canCreateProcessTaskChecklistEntries'
  )
  public get canBeDeselected(): boolean {
    return this.processTaskPermissionsHandle
      .canCreateProcessTaskChecklistEntries;
  }

  @computedFrom('internalSelected')
  public get isSelected(): boolean {
    return this.internalSelected;
  }
}
