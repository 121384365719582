import mime from 'mime';
import moment from 'moment';
import JSZip from 'jszip';

import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { BaseEntityUtils } from 'common/Types/BaseEntities/BaseEntityUtils';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';

import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { DataUrlReader } from '../classes/Reader/DataUrlReader/DataUrlReader';
import { Entry } from '../classes/EntityManager/entities/Entry/types';
import { NotificationHelper } from '../classes/NotificationHelper';
import { PictureCreatorService } from '../classes/Picture/PictureCreatorService';
import { Project } from '../classes/EntityManager/entities/Project/types';
import { ProjectCreationService } from '../classes/EntityManager/entities/Project/ProjectCreationService';
import { ProjectProperty } from '../classes/EntityManager/entities/Property/types';
import { ReportType } from '../classes/EntityManager/entities/ReportType/types';
import { StructureTemplate } from '../classes/EntityManager/entities/StructureTemplate/types';
import { StructureTemplateEntry } from '../classes/EntityManager/entities/StructureTemplateEntry/types';
import { StructureTemplateUtils } from '../classes/EntityManager/entities/StructureTemplate/StructureTemplateUtils';
import { Thing } from '../classes/EntityManager/entities/Thing/types';
import { ArrayBufferReader } from '../classes/Reader/ArrayBufferReader/ArrayBufferReader';
import { Picture } from '../classes/EntityManager/entities/Picture/types';

@autoinject()
export class Inspect3dImporterService {
  private readonly projectFileName: string = 'project.json';

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly i18n: I18N,
    private readonly pictureCreatorService: PictureCreatorService,
    private readonly projectCreationService: ProjectCreationService
  ) {}

  public async importInspect3dProject(
    options: ImportInspect3dProjectOptions
  ): Promise<void> {
    const reader = new ArrayBufferReader();
    const fileContents = await reader.readFileAsArrayBuffer(
      options.projectZipFile
    );
    if (!fileContents) {
      NotificationHelper.notifyDanger(
        this.i18n.tr(
          'services.inspect3DImporterService.zipFileNotReadNotification'
        )
      );
      return;
    }

    const projectFolder = await JSZip.loadAsync(fileContents);

    const projectJson = await projectFolder
      .file(this.projectFileName)
      ?.async('string');
    if (!projectJson) {
      NotificationHelper.notifyDanger(
        this.i18n.tr(
          'services.inspect3dImporterService.projectDataNotParsedNotification'
        )
      );
      return;
    }
    const projectData = JSON.parse(projectJson) as ProjectData;

    const structureTemplate = this.getLatestPublishedStructureTemplateWithName(
      projectData.structureTemplateName
    );
    if (!structureTemplate) {
      NotificationHelper.notifyDanger(
        this.i18n.tr(
          'services.inspect3dImporterService.structureTemplateNotFoundNotification'
        )
      );
      return;
    }

    const project = this.ensureStructureProject({
      project: options.project || null,
      thing: options.thing,
      projectData: projectData,
      structureTemplate
    });

    if (!project) {
      NotificationHelper.notifyDanger(
        this.i18n.tr(
          'services.inspect3dImporterService.projectNotCreatedNotification'
        )
      );
      return;
    }

    await this.importProjectEntriesWithPictures(
      project,
      projectData,
      projectFolder,
      structureTemplate
    );
  }

  private getLatestPublishedStructureTemplateWithName(
    templateName: string
  ): StructureTemplate | null {
    const structureTemplates =
      this.entityManager.structureTemplateRepository.getAll();

    const candidates = structureTemplates.filter(
      (s) =>
        (s.status === StructureTemplateStatus.ACTIVE ||
          s.status === StructureTemplateStatus.PROVISIONALLY_ACTIVE) &&
        s.type ===
          StructureTemplateUtils.getStructureTemplateTypeFromProjectType(
            ProjectType.INSPECT
          ) &&
        s.name === templateName
    );
    const sortedCandidates =
      BaseEntityUtils.sortByCreationOrder(candidates).reverse();

    if (candidates.length > 1)
      NotificationHelper.notifyNeutral(
        this.i18n.tr(
          'services.inspect3dImporterService.multipleStructureTemplatesNotification'
        )
      );

    return sortedCandidates[0] || null;
  }

  private ensureStructureProject({
    project,
    thing,
    projectData,
    structureTemplate
  }: {
    project: Project | null;
    thing: Thing;
    projectData: ProjectData;
    structureTemplate: StructureTemplate;
  }): Project | null {
    if (project) {
      if (projectData.projectName !== project.name) {
        NotificationHelper.notifyDanger(
          this.i18n.tr(
            'services.inspect3dImporterService.projectNamesNotMatchingNotification'
          )
        );
        return null;
      }
      return project;
    }

    return this.importStructureProject({
      thing,
      projectData,
      structureTemplate
    });
  }

  private importStructureProject(options: {
    thing: Thing;
    projectData: ProjectData;
    structureTemplate: StructureTemplate;
  }): Project | null {
    const reportType = this.getReportType(options.projectData.reportType);

    const project = this.projectCreationService.createProject({
      thing: options.thing,
      projectType: ProjectType.INSPECT,
      structureTemplateId: options.structureTemplate.id,
      reportType,
      name: options.projectData.projectName ?? null
    });

    const { missingProperties } = this.updateExistingProperties(
      project.id,
      options.projectData.properties
    );
    for (const [name, value] of Object.entries(missingProperties)) {
      this.createProjectProperty(name, value, project);
    }
    return project;
  }

  private async importProjectEntriesWithPictures(
    project: Project,
    projectData: ProjectData,
    projectFolder: JSZip,
    structureTemplate: StructureTemplate
  ): Promise<void> {
    const structureTemplateEntries =
      this.entityManager.structureTemplateEntryRepository.getByStructureTemplateId(
        structureTemplate.id
      );

    const existingEntries = this.entityManager.entryRepository.getByProjectId(
      project.id,
      null
    );

    const picturesFolder = projectFolder.folder('pictures');
    if (!picturesFolder) return;

    for (const entryData of projectData.entries) {
      const currentEntry = this.ensureEntry(
        project,
        structureTemplateEntries,
        existingEntries,
        entryData
      );
      if (!currentEntry) continue;

      if (entryData.tags) {
        this.addTagsForEntry(currentEntry, entryData.tags);
      }

      const existingPictures =
        this.entityManager.pictureRepository.getByEntryId(currentEntry.id);

      for (const pictureData of entryData.pictures) {
        if (this.checkPictureAlreadyExists(existingPictures, pictureData)) {
          continue;
        }

        await this.createPicture(
          picturesFolder,
          pictureData.fileName + pictureData.fileExtension,
          project,
          currentEntry,
          pictureData
        );
      }
    }
  }

  private ensureEntry(
    project: Project,
    structureTemplateEntries: Array<StructureTemplateEntry>,
    existingEntries: Array<Entry>,
    entryData: EntryData
  ): Entry | null {
    let entry =
      existingEntries.find((e) => e.customId === entryData.customId) || null;

    if (!entry) {
      entry = this.createEntry(project, entryData, structureTemplateEntries);
    }

    return entry;
  }

  private checkPictureAlreadyExists(
    existingPictures: Array<Picture>,
    pictureData: PictureData
  ): boolean {
    return (
      existingPictures.find(
        (picture) => picture.customId === pictureData.customId
      ) != null
    );
  }

  private getReportType(reportTypeName: string | undefined): ReportType | null {
    if (!reportTypeName) return null;

    return (
      this.entityManager.reportTypeRepository
        .getAll()
        .find((rt) => rt.name === reportTypeName) || null
    );
  }

  private updateExistingProperties(
    projectId: string,
    propertiesToImport: Array<PropertyData>
  ): { missingProperties: Record<string, string> } {
    const projectProperties =
      this.entityManager.propertyRepository.getByProjectId(projectId);

    const missingProperties: Record<string, string> = {};
    for (const property of propertiesToImport) {
      const propertyToSet = projectProperties.find(
        (p) => p.name === property.name
      );
      if (!propertyToSet) {
        missingProperties[property.name] = property.value;
        continue;
      }
      this.setPropertyValue(propertyToSet, property.value);
      this.entityManager.propertyRepository.update(propertyToSet);
    }

    return { missingProperties };
  }

  private setPropertyValue(property: ProjectProperty, value: string): void {
    switch (property.type) {
      case PropertyType.NUMBER:
        property.value = value.replace(',', '.');
        break;
      case PropertyType.TIME:
        property.value = moment(value, ['h:m a', 'H:m']).toISOString();
        break;
      case PropertyType.DATE:
        property.value = moment(value, ['DD.MM.YYYY']).toISOString();
        break;
      default:
        property.value = value;
    }
  }

  private createProjectProperty(
    name: string,
    value: string,
    project: Project
  ): void {
    this.entityManager.propertyRepository.create({
      name: name,
      value: value,
      type: PropertyType.TEXT,
      ownerUserGroupId: project.ownerUserGroupId,
      ownerProjectId: project.id,
      project: project.id,
      alwaysVisible: true
    });
  }

  private createEntry(
    project: Project,
    entryData: EntryData,
    structureTemplateEntries: Array<StructureTemplateEntry>
  ): Entry | null {
    const currentProjectEntries =
      this.entityManager.entryRepository.getByProjectId(project.id, null);

    const parentEntry = this.getOrCreateProjectEntryForStructureTemplateEntryId(
      project,
      entryData.parentStructureEntry.id,
      structureTemplateEntries,
      currentProjectEntries
    );
    if (!parentEntry) return null;

    return this.entityManager.entryRepository.create(
      {
        project: project.id,
        ownerProjectId: project.id,
        ownerUserGroupId: project.ownerUserGroupId,
        page_depth_parent: parentEntry.id,
        customId: entryData.customId
      },
      { rearrangeEntryList: false }
    );
  }

  private createEntryFromStructureTemplate(
    project: Project,
    structureTemplateEntryId: string,
    structureTemplateEntries: Array<StructureTemplateEntry>,
    currentProjectEntries: Array<Entry>
  ): Entry | null {
    const structureTemplateEntry =
      structureTemplateEntries.find(
        (entry) => entry.id === structureTemplateEntryId
      ) || null;
    if (!structureTemplateEntry) return null;

    let parentEntry: Entry | null = null;
    if (structureTemplateEntry.parentEntryId) {
      parentEntry = this.getOrCreateProjectEntryForStructureTemplateEntryId(
        project,
        structureTemplateEntry.parentEntryId,
        structureTemplateEntries,
        currentProjectEntries
      );
      if (!parentEntry) return null;
    }

    const newEntry = this.entityManager.entryRepository.create(
      {
        project: project.id,
        ownerProjectId: project.id,
        ownerUserGroupId: project.ownerUserGroupId,
        page_depth_parent: parentEntry ? parentEntry.id : null,
        structureTemplateEntryId: structureTemplateEntryId,
        list_position: structureTemplateEntry.listPosition,
        name: structureTemplateEntry.name,
        shadowEntity: true
      },
      { rearrangeEntryList: false }
    );

    return newEntry;
  }

  private getOrCreateProjectEntryForStructureTemplateEntryId(
    project: Project,
    structureTemplateEntryId: string,
    structureTemplateEntries: Array<StructureTemplateEntry>,
    currentProjectEntries: Array<Entry>
  ): Entry | null {
    let entry =
      currentProjectEntries.find(
        (e) => e.structureTemplateEntryId === structureTemplateEntryId
      ) || null;

    if (!entry)
      entry = this.createEntryFromStructureTemplate(
        project,
        structureTemplateEntryId,
        structureTemplateEntries,
        currentProjectEntries
      );

    return entry;
  }

  private addTagsForEntry(entry: Entry, tags: Array<TagData>): void {
    const importnotes: Array<string> = [];

    for (const tag of tags) {
      importnotes.push(tag.abbrevation);
    }

    entry.importnotes = importnotes;
    this.entityManager.entryRepository.update(entry);
  }

  private async createPicture(
    projectFolder: JSZip,
    fileName: string,
    project: Project,
    entry: Entry,
    pictureData: PictureData
  ): Promise<void> {
    const fileType = mime.getType(fileName) || '';
    if (!this.isImageMimeType(fileType)) return;
    const imageFile = projectFolder.file(fileName);
    if (!imageFile) return;
    const imageData = await imageFile.async('arraybuffer');

    const reader = new DataUrlReader();
    const dataUrl = await reader.readBlob(
      new Blob([imageData], {
        type: fileType
      })
    );

    const creator = this.pictureCreatorService.withEntityInfos(() => ({
      mainEntityIdField: 'ownerProjectId',
      mainEntityId: project.id,
      subEntityField: 'entry',
      subEntityValue: entry.id,
      ownerProjectId: project.id,
      ownerUserGroupId: project.ownerUserGroupId,
      customId: pictureData.customId
    }));
    creator.createPictureFromDataUrl(dataUrl);
  }

  private isImageMimeType(fileType: string): boolean {
    if (
      fileType === 'image/jpeg' ||
      fileType === 'image/png' ||
      fileType === 'image/svg+xml'
    )
      return true;
    return false;
  }
}

export type ImportInspect3dProjectOptions = {
  projectZipFile: File;
  thing: Thing;
  project?: Project | null;
};

export type ProjectData = {
  $type: string;
  $version: string;
  projectName: string;
  reportType: string;
  structureTemplateName: string;
  properties: Array<PropertyData>;
  entries: Array<EntryData>;
};

type PropertyData = {
  name: string;
  value: string;
};

type EntryData = {
  customId: string;
  parentStructureEntry: StructureEntryData;
  tags?: Array<TagData>;
  pictures: Array<PictureData>;
};

type StructureEntryData = {
  id: string;
  index: number;
  name: string;
};

type TagData = {
  id: string;
  index: number;
  name: string;
  abbrevation: string;
};

type PictureData = {
  fileName: string;
  fileExtension: string;
  customId: string;
  relatedComponent?: string;
  translation: VectorData;
  rotation: QuaternionData;
};

type VectorData = {
  x: number;
  y: number;
  z: number;
};

type QuaternionData = {
  x: number;
  y: number;
  z: number;
  w: number;
};
