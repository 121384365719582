import { autoinject, bindable } from 'aurelia-framework';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { Report } from '../../classes/EntityManager/entities/Report/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ReportList } from '../../aureliaComponents/report-list/report-list';
import { assertNotNullOrUndefined } from 'common/Asserts';

@autoinject()
export class GalleryThingExportOverview {
  @bindable public thing: Thing | null = null;

  @bindable public editable = false;

  protected reportList: ReportList | null = null;

  private isAttached = false;

  protected availableReports: Array<Report> = [];

  private reportIdToAutoScrollTo: string | null = null;

  private subscriptionManager: SubscriptionManager;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Report,
      this.updateAvailableReports.bind(this)
    );
    this.updateAvailableReports();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.isAttached = false;
  }

  /**
   * automatically scroll to the reportId as soon as the report is available
   */
  public autoScrollToReportId(reportId: string): void {
    this.reportIdToAutoScrollTo = reportId;
    this.updateAvailableReports();
  }

  protected thingChanged(): void {
    if (this.isAttached) {
      this.updateAvailableReports();
    }
  }

  private updateAvailableReports(): void {
    this.availableReports = this.thing
      ? this.entityManager.reportRepository.getByThingId(this.thing.id)
      : [];

    if (this.reportIdToAutoScrollTo) {
      const report = this.entityManager.reportRepository.getById(
        this.reportIdToAutoScrollTo
      );
      if (report) {
        this.goToReport(report);
        this.reportIdToAutoScrollTo = null;
      }
    }
  }

  private goToReport(report: Report): void {
    assertNotNullOrUndefined(
      this.reportList,
      'cannot goToReport if reportList is undefined'
    );
    void this.reportList.scrollToReport(report);
  }
}
