import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { Dialogs } from '../../classes/Dialogs';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import { ValueChangedEvent } from '../../inputComponents/date-time-picker/date-time-picker';
import { UserPermissions } from 'common/Types/Entities/User/UserDto';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { UserPasswordService } from '../../classes/EntityManager/entities/User/UserPasswordService';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

@autoinject()
export class EditUserDialog {
  private user: User | null = null;
  private currentUser: User | null = null;

  private dialog: RecordItDialog | null = null;

  private userGroupsOfUser: Array<UserGroup> = [];

  private availableUserPermissions: Array<{
    key: keyof UserPermissions<string>;
    type?: 'subscription';
    onlyAdmin?: boolean;
  }> = [
    {
      key: 'canCreateGroups'
    },
    {
      key: 'canAdministerUsers',
      onlyAdmin: true
    },
    {
      key: 'canImpersonateUsers',
      onlyAdmin: true
    },
    {
      key: 'canImportBlowerdoorXmlFiles'
    },
    {
      key: 'canUsePersonExtension',
      type: 'subscription'
    },
    {
      key: 'canUseRegionExtension',
      type: 'subscription'
    },
    {
      key: 'canUseBasic',
      type: 'subscription'
    },
    {
      key: 'canUseVia',
      type: 'subscription'
    },
    {
      key: 'canUseViaWildbach'
    },
    {
      key: 'canUseDefectManagement'
    },
    {
      key: 'canUseB1300',
      type: 'subscription'
    },
    {
      key: 'canUseInspect',
      type: 'subscription'
    },
    {
      key: 'canUseOperations',
      type: 'subscription'
    },
    {
      key: 'canUseOperationsFieldUse',
      type: 'subscription'
    },
    {
      key: 'canUseChecklist',
      type: 'subscription'
    },
    {
      key: 'canUseKuK',
      type: 'subscription'
    },
    {
      key: 'canManageChecklistQuestions'
    },
    {
      key: 'canUseMapLayers'
    },
    {
      key: 'canUseStructureTemplates'
    },
    {
      key: 'canAssignNfcTagIds'
    },
    {
      key: 'canAdministerUserDefinedEntities'
    },
    {
      key: 'canAdministerValueCalculations'
    },
    {
      key: 'canViewValueCalculationResults'
    },
    {
      key: 'canUseInspect3dImport'
    }
  ];

  private subscriptionManager: SubscriptionManager;

  private onDialogClosed: (() => void) | null = null;

  constructor(
    private router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly userPasswordService: UserPasswordService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public open(options: IEditUserDialogOpenOptions): void {
    this.user = options.user;
    this.currentUser = options.currentUser;
    this.onDialogClosed = options.onDialogClosed ?? null;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this.updateUserGroupsOfUser.bind(this)
    );
    this.updateUserGroupsOfUser();

    this.dialog?.open();
  }

  public close(): void {
    this.dialog?.close();
  }

  private handleUserChanged(): void {
    assertNotNullOrUndefined(
      this.user,
      "can't EditUserDialog.handleUserChanged without a user"
    );
    this.entityManager.userRepository.update(this.user);
  }

  private updateUserGroupsOfUser(): void {
    if (this.user) {
      this.userGroupsOfUser =
        this.entityManager.userGroupRepository.getUserGroupsOfUser(this.user);
    } else {
      this.userGroupsOfUser = [];
    }
  }

  private getUserGroupsOfUser(user: User): Array<UserGroup> {
    return this.entityManager.userGroupRepository.getUserGroupsOfUser(user);
  }

  private handleUserGroupClick(userGroup: UserGroup): void {
    this.router.navigateToRoute('edit_usergroups', {
      user_group_id: userGroup.id
    });
  }

  private handleDialogClosed(): void {
    const onDialogClosed = this.onDialogClosed; // caching so we can reset everything before calling the callback

    this.user = null;
    this.currentUser = null;
    this.onDialogClosed = null;

    this.subscriptionManager.disposeSubscriptions();

    onDialogClosed && onDialogClosed();
  }

  private handleSetNewPasswordClick(): void {
    const user = this.user;
    assertNotNullOrUndefined(user, 'cannot change password without user');

    Dialogs.changePasswordDialog(user.username ?? '', (password) => {
      void this.userPasswordService.updatePassword(user, null, password);
    });
  }

  private userHasPermission(
    user: User,
    permissionName: PermissionName
  ): boolean {
    return PermissionHelper.userHasPermission(user, permissionName);
  }

  private getPermissionSubscription(
    user: User,
    permissionName: PermissionName
  ): string | boolean | null {
    return PermissionHelper.getRawPermissionValue(user, permissionName);
  }

  private handleCheckboxUserPermissionChanged(
    permissionName: PermissionName,
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    assertNotNullOrUndefined(
      this.user,
      'cannot change permissions without user'
    );
    this.entityManager.userRepository.setUserPermission(
      this.user,
      permissionName,
      event.detail.checked
    );
  }

  private handleDateUserPermissionChanged(
    permissionName: PermissionName,
    event: ValueChangedEvent
  ): void {
    assertNotNullOrUndefined(
      this.user,
      'cannot change permissions without user'
    );
    this.entityManager.userRepository.setUserPermission(
      this.user,
      permissionName,
      event.detail.value
    );
  }

  public static async open(options: IEditUserDialogOpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

interface IEditUserDialogOpenOptions {
  user: User;
  currentUser: User;
  onDialogClosed?: (() => void) | null;
}

type PermissionName = keyof UserPermissions<string>;
