import { bindable, autoinject, computedFrom } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { DisposableItemsCache } from '../../classes/DisposableItemsCache/DisposableItemsCache';
import { EntryToPerson } from '../../classes/EntityManager/entities/EntryToPerson/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';

@autoinject()
export class EntryPersonListMultiSelect {
  @bindable()
  public entry: Entry | null = null;

  @bindable()
  public filterByCategoryName: string | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private isAttached: boolean = false;

  private readonly personHandlesCache: DisposableItemsCache<
    { person: Person; entry: Entry | null },
    PersonHandle
  >;

  protected personHandles: Array<PersonHandle> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.personHandlesCache = new DisposableItemsCache({
      createDisposableForItem: ({ item: { entry, person } }) => {
        return new PersonHandle({
          entityManager,
          subscriptionManagerService,
          permissionsService,
          entry,
          person
        });
      },
      onItemUpdate: ({ item, mappedItem }) => {
        mappedItem.setEntry(item.entry);
      },
      getKeyForItem: ({ item }) => {
        return item.person;
      }
    });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Person,
      this.updatePersonHandles.bind(this)
    );
    this.updatePersonHandles();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected entryChanged(): void {
    if (this.isAttached) {
      this.updatePersonHandles();
    }
  }

  private updatePersonHandles(): void {
    const persons = this.getPersons().filter(
      (person) =>
        !this.filterByCategoryName ||
        this.filterByCategoryName === person.categoryName
    );

    const personHandles = this.personHandlesCache.mapItems({
      items: persons.map((person) => {
        return {
          person,
          entry: this.entry
        };
      })
    });

    personHandles.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });

    this.personHandles = personHandles;
  }

  private getPersons(): Array<Person> {
    if (this.entry) {
      return this.entityManager.personRepository.getByUserGroupId(
        this.entry.ownerUserGroupId
      );
    } else {
      return [];
    }
  }
}

class PersonHandle implements Disposable {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManager: SubscriptionManager;
  private readonly person: Person;

  private entryToPerson: EntryToPerson | null = null;

  private entry: Entry | null;

  private entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  private entryToPersonPermissionsHandle: EntityNameToPermissionsHandle[EntityName.EntryToPerson];

  constructor({
    entityManager,
    subscriptionManagerService,
    permissionsService,
    entry,
    person
  }: {
    entityManager: AppEntityManager;
    subscriptionManagerService: SubscriptionManagerService;
    permissionsService: PermissionsService;
    entry: Entry | null;
    person: Person;
  }) {
    this.entityManager = entityManager;
    this.subscriptionManager = subscriptionManagerService.create();
    this.entry = entry;
    this.person = person;

    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Entry,
        context: this,
        expression: 'entry'
      });

    this.subscriptionManager.addDisposable(
      this.entryPermissionsHandle.subscribe()
    );

    this.entryToPersonPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.EntryToPerson,
        context: this,
        expression: 'entryToPerson'
      });

    this.subscriptionManager.addDisposable(
      this.entryToPersonPermissionsHandle.subscribe()
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.EntryToPerson,
      () => {
        this.updateEntryToPerson();
      }
    );

    this.updateEntryToPerson();
  }

  public dispose(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public setEntry(entry: Entry | null): void {
    this.entry = entry;
    this.updateEntryToPerson();
  }

  @computedFrom('entryToPerson')
  public get isSelected(): boolean {
    return !!this.entryToPerson;
  }

  @computedFrom('entry', 'entryPermissionsHandle.canEditEntryToPersons')
  public get canBeSelected(): boolean {
    return !!this.entry && this.entryPermissionsHandle.canEditEntryToPersons;
  }

  @computedFrom('entry', 'entryToPersonPermissionsHandle.canDeleteEntity')
  public get canBeDeselected(): boolean {
    return !!this.entry && this.entryToPersonPermissionsHandle.canDeleteEntity;
  }

  @computedFrom(
    'person.company',
    'person.companyName',
    'person.title',
    'person.firstName',
    'person.lastName'
  )
  public get displayName(): string {
    return PersonUtils.getPersonDisplayName(
      this.person.company,
      this.person.companyName,
      this.person.title,
      this.person.firstName,
      this.person.lastName
    );
  }

  public setSelected(selected: boolean): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't PersonHandle.setSelected without entry"
    );
    if (selected) {
      if (!this.entryToPerson) {
        this.entityManager.entryToPersonRepository.create({
          entryId: this.entry.id,
          personId: this.person.id,
          ownerProjectId: this.entry.ownerProjectId,
          ownerUserGroupId: this.entry.ownerUserGroupId
        });
      }
    } else {
      if (this.entryToPerson) {
        this.entityManager.entryToPersonRepository.delete(this.entryToPerson);
      }
    }
  }

  private updateEntryToPerson(): void {
    this.entryToPerson = this.entry
      ? this.entityManager.entryToPersonRepository.getFirstByEntryAndPersonId({
          entryId: this.entry.id,
          personId: this.person.id
        })
      : null;
  }
}
