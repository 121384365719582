import { autoinject, bindable } from 'aurelia-framework';
import { ProcessConfigurationCategory } from '../../classes/EntityManager/entities/ProcessConfigurationCategory/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskPositionsByProcessTaskId,
  ProcessTaskPositionsByProcessTaskIdComputer
} from '../../computedValues/computers/ProcessTaskPositionsByProcessTaskIdComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class MultiProcessTaskSelectionWidget {
  @bindable()
  public processTaskHandles: Array<MultiProcessTaskSelectionWidgetProcessTaskHandle> =
    [];

  /**
   * show the available positions next to the processTask
   */
  @bindable()
  public showPositionCount: boolean = false;

  /**
   * only show positions of that category
   */
  @bindable()
  public processConfigurationCategory: ProcessConfigurationCategory | null =
    null;

  private readonly subscriptionManager: SubscriptionManager;
  protected processTaskPositionsByProcessTaskId: ProcessTaskPositionsByProcessTaskId =
    new Map();

  constructor(
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskPositionsByProcessTaskIdComputer,
        computeData: {},
        callback: (processTaskPositionsByProcessTaskId) => {
          this.processTaskPositionsByProcessTaskId =
            processTaskPositionsByProcessTaskId;
        }
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected getProcessTaskPositionCountAppendValue(
    processTaskId: string,
    showPositionCount: boolean,
    processConfigurationCategory: ProcessConfigurationCategory | null,
    processTaskPositionsByProcessTaskId: ProcessTaskPositionsByProcessTaskId
  ): string {
    if (!showPositionCount) {
      return '';
    }

    const processTaskPositions =
      processTaskPositionsByProcessTaskId.get(processTaskId) ?? [];
    const filteredPositions = processTaskPositions.filter(
      (p) =>
        !processConfigurationCategory ||
        processConfigurationCategory.general ||
        p.processConfigurationCategoryId === processConfigurationCategory.id
    );

    return ` (${filteredPositions.length})`;
  }
}

export type MultiProcessTaskSelectionWidgetProcessTaskHandle = {
  processTask: ProcessTask;
  isSelected: boolean;
  canBeDeselected: boolean;
  canBeSelected: boolean;
  setSelected(selected: boolean): void;
};
