import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationNoteCategory } from '../../../classes/EntityManager/entities/ProcessConfigurationNoteCategory/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationNoteCategoriesWidgetCategory {
  @bindable()
  public processConfigurationNoteCategory: ProcessConfigurationNoteCategory | null =
    null;

  @subscribableLifecycle()
  protected readonly processConfigurationNoteCategoryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationNoteCategory];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationNoteCategoryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationNoteCategory,
        context: this as ProcessConfigurationNoteCategoriesWidgetCategory,
        propertyName: 'processConfigurationNoteCategory'
      });
  }

  protected handleCategoryChanged(): void {
    assertNotNullOrUndefined(
      this.processConfigurationNoteCategory,
      "can't ProcessConfigurationNoteCategoriesWidgetCategory.handleCategoryChanged without processConfigurationNoteCategory"
    );

    this.entityManager.processConfigurationNoteCategoryRepository.update(
      this.processConfigurationNoteCategory
    );
  }

  protected handleDeleteClicked(): void {
    assertNotNullOrUndefined(
      this.processConfigurationNoteCategory,
      "can't ProcessConfigurationNoteCategoriesWidgetCategory.handleDeleteCategoryClicked without processConfigurationNoteCategory"
    );

    this.entityManager.processConfigurationNoteCategoryRepository.delete(
      this.processConfigurationNoteCategory
    );
  }
}
