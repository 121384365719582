import { autoinject, bindable, containerless } from 'aurelia-framework';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../../classes/SubscriptionManager';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import {
  ProcessTaskNamesByProcessTaskId,
  ProcessTaskNamesByProcessTaskIdComputer
} from '../../../../computedValues/computers/ProcessTaskNamesByProcessTaskIdComputer';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';

@containerless()
@autoinject()
export class ShowProcessAppointmentPicturesWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public expanded: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;
  private processTasks: Array<ProcessTask> = [];
  private appointmentPictureCount: number | null = null;

  protected processTaskNamesByProcessTaskId: ProcessTaskNamesByProcessTaskId =
    new Map();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  private handleToggleExpandedClick(): void {
    this.expanded = !this.expanded;
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      this.updateProcessTasks.bind(this)
    );
    this.updateProcessTasks();
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskNamesByProcessTaskIdComputer,
        computeData: {},
        callback: (processTaskNamesByProcessTaskId) => {
          this.processTaskNamesByProcessTaskId =
            processTaskNamesByProcessTaskId;
        }
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskAppointmentChanged(): void {
    this.updateProcessTasks();
  }

  private updateProcessTasks(): void {
    if (this.processTaskAppointment) {
      this.processTasks =
        this.entityManager.processTaskRepository.getByProcessTaskGroupId(
          this.processTaskAppointment.ownerProcessTaskGroupId
        );
    } else {
      this.processTasks = [];
    }
  }

  protected getProcessTaskName(
    processTaskId: string,
    processTaskNamesByProcessTaskId: ProcessTaskNamesByProcessTaskId
  ): string {
    return (
      processTaskNamesByProcessTaskId.get(processTaskId)
        ?.nameWithThingAndPerson ?? ''
    );
  }
}
