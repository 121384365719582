import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';
import { TTextChangedEvent } from '../../inputComponents/clickable-text-input/clickable-text-input';
import { SelectChangedEvent } from '../../inputComponents/custom-select/custom-select';
import {
  ChoiceAddedEvent,
  ChoiceRemovedEvent
} from '../../inputComponents/text-choice-widget/text-choice-widget';
import {
  PropertyDefinitionWidgetHandle,
  PropertyFeatures
} from './PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle';
import { PropertyWidgetConfiguration } from '../base-property-widget/config/PropertyWidgetConfiguration/PropertyWidgetConfiguration';
import {
  ComponentView,
  ViewCreationService
} from '../../services/ViewCreationService';
import { PropertyDefinitionWidgetAdditionalFieldsComponentConstructor } from '../property-definition-widget-additional-fields/property-definition-widget-additional-fields-component';

/**
 * A widget which uses a PropertyDefinitionWidgetHandle to edit a definition of a property.
 */
@autoinject()
export class PropertyDefinitionWidget<TPropertyDefinition> {
  @bindable()
  public handle: PropertyDefinitionWidgetHandle<TPropertyDefinition> | null =
    null;

  protected propertyWidgetConfiguration: PropertyWidgetConfiguration<
    any,
    TPropertyDefinition
  > | null = null;

  protected propertyTypeOptions: Array<PropertyTypeOption> = [];

  protected readonly PropertyHelper = PropertyHelper;

  private domElement: HTMLElement;

  private additionalFieldsView: ComponentView<
    PropertyDefinitionWidgetAdditionalFieldsComponentConstructor<TPropertyDefinition>
  > | null = null;

  constructor(
    element: Element,
    private readonly viewCreationService: ViewCreationService
  ) {
    this.domElement = element as HTMLElement;
  }

  protected async handleChanged(): Promise<void> {
    this.propertyWidgetConfiguration =
      this.handle?.createPropertyWidgetConfiguration() ?? null;
    this.updatePropertyTypeOptions();
    this.additionalFieldsView?.detach();
    if (this.handle?.additionalFieldsComponentClass) {
      this.additionalFieldsView = await this.createAdditionalFieldsView(
        this.handle.additionalFieldsComponentClass
      );
    }
  }

  protected handleNameChanged(event: TTextChangedEvent): void {
    assertNotNullOrUndefined(
      this.handle,
      "can't PropertyDefinitionWidget.handleNameChanged without handle"
    );

    this.handle.setName(event.detail.value as string | null);
  }

  protected handleTypeChanged(
    event: SelectChangedEvent<PropertyType, PropertyTypeOption>
  ): void {
    assertNotNullOrUndefined(
      this.handle,
      "can't PropertyDefinitionWidget.handleTypeChanged without handle"
    );
    assertNotNullOrUndefined(
      event.detail.value,
      "can't unselect propertyDefinition type"
    );

    this.handle.setType(event.detail.value);
  }

  protected handleChoiceCreated(event: ChoiceAddedEvent): void {
    assertNotNullOrUndefined(
      this.handle,
      "can't PropertyDefinitionWidget.handleChoiceCreated without handle"
    );

    const choices = this.handle.choices.slice() ?? [];
    ArrayUtils.pushUnique(choices, event.detail.choice);

    this.handle.setChoices(choices);
  }

  protected handleChoiceRemoved(event: ChoiceRemovedEvent): void {
    assertNotNullOrUndefined(
      this.handle,
      "can't PropertyDefinitionWidget.handleChoiceCreated without handle"
    );

    const choices = this.handle.choices.slice() ?? [];
    ArrayUtils.remove(choices, event.detail.choice);

    this.handle.setChoices(choices);
  }

  private updatePropertyTypeOptions(): void {
    const features = this.handle?.getPropertyFeatures();
    if (features) {
      this.propertyTypeOptions = this.getPropertyTypesForFeatures({
        features
      }).map((type) => {
        return {
          value: type,
          label: StringUtils.upperCaseFirstLetter(type)
        };
      });
    } else {
      this.propertyTypeOptions = [];
    }
  }

  private getPropertyTypesForFeatures({
    features
  }: {
    features: PropertyFeatures;
  }): Array<PropertyType> {
    return Object.values(PropertyType).filter((propertyType) => {
      if (
        !features.canHavePositions &&
        propertyType === PropertyType.POSITION
      ) {
        return false;
      }

      if (
        !features.canHaveProjectParameter &&
        propertyType === PropertyType.BERICHTPARAMETER
      ) {
        return false;
      }

      if (
        !features.canHaveTableEntries &&
        propertyType === PropertyType.TABLE
      ) {
        return false;
      }

      return true;
    });
  }

  @computedFrom('handle')
  protected get allowSettingOfPropertyValue(): boolean {
    if (this.handle?.getPropertyFeatures().allowDefaultValues) {
      return true;
    }

    return false;
  }

  @computedFrom('handle.type')
  protected get choicesAreVisible(): boolean {
    if (this.handle) {
      return PropertyHelper.propertyTypeNeedsChoices(this.handle.type);
    }

    return false;
  }

  @computedFrom('handle.type')
  protected get optionsAreVisible(): boolean {
    if (this.handle) {
      return (
        PropertyHelper.getOptionsOfPropertyType(this.handle.type).length > 0
      );
    }

    return false;
  }

  private async createAdditionalFieldsView(
    component: PropertyDefinitionWidgetAdditionalFieldsComponentConstructor<TPropertyDefinition>
  ): Promise<
    ComponentView<
      PropertyDefinitionWidgetAdditionalFieldsComponentConstructor<TPropertyDefinition>
    >
  > {
    assertNotNullOrUndefined(
      this.handle,
      'cannot create additional fields view without property handle'
    );
    const componentView = await this.viewCreationService.createViewForComponent(
      {
        component,
        initialBindables: {
          propertyDefinitionHandle: this.handle
        }
      }
    );
    componentView.attachToContainer(this.domElement);
    return componentView;
  }
}

type PropertyTypeOption = {
  label: string;
  value: PropertyType;
};
