import { computedFrom } from 'aurelia-framework';
import { StructureTemplate } from '../../../classes/EntityManager/entities/StructureTemplate/types';
import { StructureTemplateAdapter } from '../EntityAdapter/StructureTemplateAdapter/StructureTemplateAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class StructureTemplatePermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<StructureTemplateAdapter>,
  StructureTemplate
> {
  @computedFrom('combinedRevision')
  public get canSetStatusToActive(): boolean {
    return this.checkAdapterSpecificPermissionByName('canSetStatusToActive');
  }

  @computedFrom('combinedRevision')
  public get canSetStatusToArchived(): boolean {
    return this.checkAdapterSpecificPermissionByName('canSetStatusToArchived');
  }

  @computedFrom('combinedRevision')
  public get canSetStatusToProvisionallyActive(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canSetStatusToProvisionallyActive'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateStructureTemplateEntryProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateStructureTemplateEntryProperties'
    );
  }

  @computedFrom('combinedRevision')
  public get canEditStructureTemplateRatingCategories(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canEditStructureTemplateRatingCategories'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateStructureTemplateEntries(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateStructureTemplateEntries'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateStructureTemplateEntryGroups(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateStructureTemplateEntryGroups'
    );
  }
}
