import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { ProcessTaskInvoice } from '../../../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import {
  CanEditFieldFieldName,
  EntityAdapter,
  SubscribeOptions
} from '../EntityAdapter';
import { processTaskInvoiceEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskInvoice/processTaskInvoiceEntityInfo';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { ProcessTaskGroupAuthorizationComputer } from '../../../../computedValues/computers/ProcessTaskGroupAuthorizationComputer/ProcessTaskGroupAuthorizationComputer';

export class ProcessTaskInvoiceAdapter
  implements EntityAdapter<ProcessTaskInvoice>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private processTaskGroupIdsWhereUserIsAuthorized: Set<string> | null = null;

  constructor(options: ProcessTaskInvoiceAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskGroupAuthorizationComputer,
        computeData: {},
        callback: ({ processTaskGroupIdsWhereUserIsAuthorized }) => {
          this.processTaskGroupIdsWhereUserIsAuthorized =
            processTaskGroupIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoice,
      () => {
        updateBindings();
      }
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.processTaskGroupIdsWhereUserIsAuthorized != null
    );
  }

  public canDeleteEntity(processTaskInvoice: ProcessTaskInvoice): boolean {
    return this.checkPermissionWithDoneAtCheck({
      processTaskInvoice,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteProcessTaskInvoices()
    });
  }

  public canEditField(
    processTaskInvoice: ProcessTaskInvoice,
    fieldName: CanEditFieldFieldName<ProcessTaskInvoice>
  ): boolean {
    if (fieldName === 'inspectorUserId') {
      return this.checkPermission({
        processTaskInvoice,
        checkRolePermission: (permissions) =>
          permissions.getCanUpdateProcessTaskInvoices()
      });
    }

    return this.checkPermissionWithDoneAtCheck({
      processTaskInvoice,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskInvoices()
    });
  }

  public canEditProcessTaskInvoiceToProcessTasks(
    processTaskInvoice: ProcessTaskInvoice
  ): boolean {
    return this.checkPermissionWithDoneAtCheck({
      processTaskInvoice,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskInvoices()
    });
  }

  public canEditProcessTaskInvoiceToProcessTaskDevices(
    processTaskInvoice: ProcessTaskInvoice
  ): boolean {
    return this.checkPermissionWithDoneAtCheck({
      processTaskInvoice,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskInvoices()
    });
  }

  public canEditProcessTaskInvoiceToProcessTaskPositions(
    processTaskInvoice: ProcessTaskInvoice
  ): boolean {
    return this.checkPermissionWithDoneAtCheck({
      processTaskInvoice,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskInvoices()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskInvoice,
    ProcessTaskInvoice
  > {
    return processTaskInvoiceEntityInfo;
  }

  private checkPermissionWithDoneAtCheck({
    processTaskInvoice,
    checkRolePermission
  }: {
    processTaskInvoice: ProcessTaskInvoice;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (processTaskInvoice.doneAt) {
      return false;
    }

    return this.checkPermission({
      processTaskInvoice,
      checkRolePermission
    });
  }

  private checkPermission({
    processTaskInvoice,
    checkRolePermission
  }: {
    processTaskInvoice: ProcessTaskInvoice;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkProcessTaskGroupSubEntityPermission({
      entity: processTaskInvoice,
      processTaskGroupIdsWhereUserIsAuthorized:
        this.processTaskGroupIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ProcessTaskInvoiceAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
