import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import {
  QuestionChoice,
  QuestionChoiceAction,
  QuestionChoiceActionType
} from 'common/Types/Entities/Question/QuestionDto';
import { ExprEvalParser } from 'common/ExprEvalParser/ExprEvalParser';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { ProjectQuestionCategory } from '../../classes/EntityManager/entities/ProjectQuestionCategory/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ProjectQuestion } from '../../classes/EntityManager/entities/ProjectQuestion/types';
import { Dialogs } from '../../classes/Dialogs';
import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import { SelectedChoiceChangedEvent } from '../../inputComponents/selection-switch/selection-switch';
import { ProjectQuestionUtils } from '../../classes/EntityManager/entities/ProjectQuestion/ProjectQuestionUtils';
import { ProjectQuestionService } from '../../services/ProjectQuestionService';
import { ChecklistManageQuestionNoteDialog } from '../../dialogs/checklist-manage-question-note-dialog/checklist-manage-question-note-dialog';
import { ChecklistManageQuestionRecommendationDialog } from '../../dialogs/checklist-manage-question-recommendation-dialog/checklist-manage-question-recommendation-dialog';
import { ChecklistManageQuestionPicturesDialog } from '../../dialogs/checklist-manage-question-pictures-dialog/checklist-manage-question-pictures-dialog';

@autoinject()
export class ChecklistInspectionCategory {
  protected currentPageQuestions: Array<ProjectQuestion> = [];

  protected getSelectedChoice = ProjectQuestionUtils.getSelectedChoice;

  private projectId: string | null = null;
  private projectCategoryId: string | null = null;
  private physicalArea: string | null = null;

  private readonly exprEvalParser = new ExprEvalParser();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly activeEntitiesService: ActiveEntitiesService,
    private router: Router,
    private readonly projectQuestionService: ProjectQuestionService
  ) {}

  protected activate(params: {
    project_id: string;
    project_category_id: string;
    physical_area?: string | null;
  }): void {
    this.projectId = params.project_id;
    this.projectCategoryId = params.project_category_id;
    this.physicalArea = params.physical_area ?? null;

    const category =
      this.entityManager.projectQuestionCategoryRepository.getById(
        this.projectCategoryId
      );
    this.activeEntitiesService.setActiveChecklistInspectionCategoryConfig(
      category
        ? {
            category,
            physicalArea: this.physicalArea
          }
        : null
    );
  }

  protected deactivate(): void {
    this.activeEntitiesService.setActiveChecklistInspectionCategoryConfig(null);
  }

  protected handleToggleAnsweredQuestionsButtonClicked(): void {
    // TODO: Implement this
    void Dialogs.todoDialog();
  }

  protected handleToggleQuestionHiddenButtonClicked(
    question: ProjectQuestion
  ): void {
    question.hidden = !question.hidden;
    this.entityManager.projectQuestionRepository.update(question);
  }

  protected handleNoteActionClicked(question: ProjectQuestion): void {
    void ChecklistManageQuestionNoteDialog.open({
      question
    });
  }

  protected handleRecommendationActionClicked(question: ProjectQuestion): void {
    void ChecklistManageQuestionRecommendationDialog.open({
      question
    });
  }

  protected handlePictureActionClicked(question: ProjectQuestion): void {
    void ChecklistManageQuestionPicturesDialog.open({
      question
    });
  }

  protected handleDetailsActionClicked(question: ProjectQuestion): void {
    this.router.navigateToRoute('checklist_inspection_question', {
      project_id: this.projectId,
      project_category_id: this.projectCategoryId,
      project_question_id: question.id
    });
  }

  protected handleQuestionChoiceSelected(
    question: ProjectQuestion,
    event: SelectedChoiceChangedEvent<QuestionChoice>
  ): void {
    this.projectQuestionService.setQuestionAnswer(
      question,
      event.detail.selectedChoice
    );
  }

  @computed(expression('projectId'), model(EntityName.Project))
  protected get project(): Project | null {
    if (!this.projectId) return null;
    return this.entityManager.projectRepository.getById(this.projectId);
  }

  @computed(
    expression('currentPageQuestions'),
    model(EntityName.ProjectQuestion)
  )
  protected get redirectActions(): Array<RedirectAction> {
    const redirectActions: Array<RedirectAction> = [];
    for (const question of this.currentPageQuestions) {
      const actions = this.getActiveActionsOfTypeForQuestion(
        QuestionChoiceActionType.REDIRECT,
        question
      );

      for (const action of actions) {
        if (!action.redirectButtonText || !action.redirectUrl) continue;

        redirectActions.push({
          triggerQuestion: question,
          buttonText: action.redirectButtonText,
          url: action.redirectUrl
        });
      }
    }
    return redirectActions;
  }

  private getActiveActionsOfTypeForQuestion(
    type: QuestionChoiceActionType,
    question: ProjectQuestion
  ): Array<QuestionChoiceAction> {
    return question.choices
      .filter((c) => c.choiceText === question.answer)
      .flatMap((c) => c.actions)
      .filter((a) => a.actionType === type);
  }

  protected replaceTemplateParameters(url: string): string {
    const context: Record<string, any> = { thing: this.thing };

    return this.exprEvalParser.evaluateStringWithExpressions({
      template: url,
      context
    });
  }

  @computed(expression('project.thing'), model(EntityName.Thing))
  protected get thing(): Thing | null {
    if (!this.project) return null;
    return this.entityManager.thingRepository.getById(this.project.thing);
  }

  @computed(
    expression('projectCategoryId'),
    model(EntityName.ProjectQuestionCategory)
  )
  protected get category(): ProjectQuestionCategory | null {
    if (!this.projectCategoryId) return null;
    return this.entityManager.projectQuestionCategoryRepository.getById(
      this.projectCategoryId
    );
  }

  @computed(expression('projectCategoryId'), model(EntityName.ProjectQuestion))
  protected get questions(): Array<ProjectQuestion> {
    if (!this.projectCategoryId) return [];
    return (
      this.entityManager.projectQuestionRepository
        .getByProjectQuestionCategoryId(this.projectCategoryId)
        // Return only questions in the current physical area
        .filter((q) => q.physicalArea === this.physicalArea)
    );
  }

  @computed(expression('questions'))
  protected get amountOfAnsweredQuestions(): number {
    return this.questions.filter((q) => ProjectQuestionUtils.isCompleted(q))
      .length;
  }

  @computed(expression('questions'))
  protected get amountOfQuestions(): number {
    return this.questions.length;
  }

  @computed(
    expression('amountOfAnsweredQuestions'),
    expression('amountOfQuestions')
  )
  protected get progressInPercent(): string {
    const percent = this.amountOfAnsweredQuestions / this.amountOfQuestions;
    return percent.toLocaleString(undefined, { style: 'percent' });
  }

  @computed(expression('projectId'))
  protected get routeToInspection(): string {
    return this.router.generate('checklist_inspection', {
      project_id: this.projectId
    });
  }
}

export type RedirectAction = {
  triggerQuestion: ProjectQuestion;
  buttonText: string;
  url: string;
};
