import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskGroupSubEntityUtils } from '../../BaseEntities/ProcessTaskGroupSubEntityUtils';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { PictureDto } from './PictureDto';

export function createPictureEntityInfo<
  TEntity extends PictureDto<string, string>
>(
  options: CreateEntityInfoOptions<PictureDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.Picture,
  TEntity
> {
  return {
    entityName: EntityName.Picture,
    synchronizeRelativeTo: [EntityName.Project, EntityName.ProcessTaskGroup],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      PictureDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createOptionalBaseFieldInfos(),
        ...ProcessTaskGroupSubEntityUtils.createOptionalBaseFieldInfos(),
        takenByUserId: {
          name: 'takenByUserId',
          ref: EntityName.User,
          defaultValue: null
        },
        coords: {
          name: 'coords',
          innerInfo: {
            latitude: {
              name: 'latitude'
            },
            longitude: {
              name: 'longitude'
            }
          },
          defaultValue: null
        },
        location_info: {
          name: 'location_info',
          innerInfo: {
            bottomLeftPosition: {
              name: 'bottomLeftPosition',
              innerInfo: {
                latitude: {
                  name: 'latitude'
                },
                longitude: {
                  name: 'longitude'
                }
              }
            },
            bottomRightPosition: {
              name: 'bottomRightPosition',
              innerInfo: {
                latitude: {
                  name: 'latitude'
                },
                longitude: {
                  name: 'longitude'
                }
              }
            },
            topLeftPosition: {
              name: 'topLeftPosition',
              innerInfo: {
                latitude: {
                  name: 'latitude'
                },
                longitude: {
                  name: 'longitude'
                }
              }
            },
            topRightPosition: {
              name: 'topRightPosition',
              innerInfo: {
                latitude: {
                  name: 'latitude'
                },
                longitude: {
                  name: 'longitude'
                }
              }
            }
          },
          defaultValue: null
        },
        description: {
          name: 'description',
          defaultValue: null
        },
        takenAt: {
          name: 'takenAt',
          defaultValue: null
        },
        marking: {
          name: 'marking',
          innerInfo: {
            left: {
              name: 'left'
            },
            top: {
              name: 'top'
            },
            boxBottom: {
              name: 'boxBottom',
              defaultValue: null
            },
            boxLeft: {
              name: 'boxLeft',
              defaultValue: null
            },
            boxRight: {
              name: 'boxRight',
              defaultValue: null
            },
            boxTop: {
              name: 'boxTop',
              defaultValue: null
            }
          },
          defaultValue: null
        },
        additional_markings: {
          name: 'additional_markings',
          defaultValue: () => [],
          innerInfo: [
            {
              left: {
                name: 'left'
              },
              top: {
                name: 'top'
              },
              picture_id: {
                name: 'picture_id',
                ref: EntityName.Picture
              },
              boxBottom: {
                name: 'boxBottom',
                defaultValue: null
              },
              boxLeft: {
                name: 'boxLeft',
                defaultValue: null
              },
              boxRight: {
                name: 'boxRight',
                defaultValue: null
              },
              boxTop: {
                name: 'boxTop',
                defaultValue: null
              }
            }
          ]
        },
        coordsFromPositionedPictureInfo: {
          name: 'coordsFromPositionedPictureInfo',
          innerInfo: {
            pictureId: {
              name: 'pictureId',
              ref: EntityName.Picture
            },
            left: {
              name: 'left'
            },
            top: {
              name: 'top'
            },
            boxBottom: {
              name: 'boxBottom',
              defaultValue: null
            },
            boxLeft: {
              name: 'boxLeft',
              defaultValue: null
            },
            boxRight: {
              name: 'boxRight',
              defaultValue: null
            },
            boxTop: {
              name: 'boxTop',
              defaultValue: null
            }
          },
          defaultValue: null
        },
        originatedFromPictureInfo: {
          name: 'originatedFromPictureInfo',
          innerInfo: {
            pictureId: {
              name: 'pictureId',
              ref: EntityName.Picture
            },
            bottomRight: {
              name: 'bottomRight',
              innerInfo: {
                x: {
                  name: 'x'
                },
                y: {
                  name: 'y'
                }
              }
            },
            topLeft: {
              name: 'topLeft',
              innerInfo: {
                x: {
                  name: 'x'
                },
                y: {
                  name: 'y'
                }
              }
            }
          },
          defaultValue: null
        },
        tagIds: {
          name: 'tagIds',
          defaultValue: () => []
        },
        sequence_number: {
          name: 'sequence_number',
          defaultValue: null
        },
        selected: {
          name: 'selected',
          defaultValue: false
        },
        excludeFromExport: {
          name: 'excludeFromExport',
          defaultValue: false
        },
        project: {
          name: 'project',
          ref: EntityName.Project,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        is_global_project_picture: {
          name: 'is_global_project_picture',
          defaultValue: false
        },
        entry: {
          name: 'entry',
          ref: EntityName.Entry,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        picture_of_project: {
          name: 'picture_of_project',
          ref: EntityName.Project,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        projectQuestionId: {
          name: 'projectQuestionId',
          ref: EntityName.ProjectQuestion,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        property: {
          name: 'property',
          ref: EntityName.Property,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        galleryThingId: {
          name: 'galleryThingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        thing: {
          name: 'thing',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        titleThingId: {
          name: 'titleThingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        globalThingId: {
          name: 'globalThingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        thingSectionId: {
          name: 'thingSectionId',
          ref: EntityName.ThingSection,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        defect: {
          name: 'defect',
          ref: EntityName.Defect,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        ownerDefectId: {
          name: 'ownerDefectId',
          ref: EntityName.Defect,
          cascadeDeleteLocally: true,
          defaultValue: null,
          additionalData: {
            ownerId: true
          }
        },
        ownerThingId: {
          name: 'ownerThingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true,
          defaultValue: null,
          additionalData: {
            ownerId: true
          }
        },
        defectComment: {
          name: 'defectComment',
          ref: EntityName.DefectComment,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        ownerProcessConfigurationId: {
          name: 'ownerProcessConfigurationId',
          ref: EntityName.ProcessConfiguration,
          cascadeDeleteLocally: true,
          defaultValue: null,
          additionalData: {
            ownerId: true
          }
        },
        customId: {
          name: 'customId',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
