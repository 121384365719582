import { computedFrom } from 'aurelia-framework';

import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import {
  PropertyOption,
  PropertyType
} from 'common/Types/Entities/Property/PropertyDto';

import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import {
  PropertyDefinitionWidgetHandle,
  PropertyFeatures
} from '../PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle';
import { StructureTemplateEntryProperty } from '../../../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import {
  createStructureTemplateEntryPropertyDefinitionWidgetConfiguration,
  StructureTemplateEntryPropertyDefinitionWidgetConfiguration
} from '../../../base-property-widget/config/structureTemplateEntryPropertyDefinitionWidgetConfiguration/createStructureTemplateEntryPropertyDefinitionWidgetConfiguration';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../../../classes/EntityManager/entities/types';

export class StructureTemplateEntryPropertyDefinitionHandle
  implements PropertyDefinitionWidgetHandle<StructureTemplateEntryProperty>
{
  private readonly entityManager: AppEntityManager;
  private readonly property: StructureTemplateEntryProperty;
  private readonly propertyPermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplateEntryProperty];

  constructor(options: StructureTemplateEntryPropertyDefinitionHandleOptions) {
    this.entityManager = options.entityManager;
    this.property = options.property;
    this.propertyPermissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.StructureTemplateEntryProperty,
        entity: options.property
      });
  }

  public subscribe(): Disposable {
    return this.propertyPermissionsHandle.subscribe();
  }

  @computedFrom('property.name')
  public get name(): string | null {
    return this.property.name;
  }

  public setName(name: string | null): void {
    this.property.name = name;
    this.updateProperty();
  }

  @computedFrom('property.type')
  public get type(): PropertyType {
    return PropertyHelper.getTypeOrDefault(this.property.type);
  }

  public setType(type: PropertyType): void {
    this.property.type = type;
    this.updateProperty();
  }

  @computedFrom('property.choices')
  public get choices(): Array<string> {
    return this.property.choices;
  }

  public setChoices(choices: Array<string>): void {
    this.property.choices = choices;
    this.updateProperty();
  }

  @computedFrom('property.options')
  public get options(): Array<PropertyOption> {
    return this.property.options ?? [];
  }

  public setOptions(options: Array<PropertyOption>): void {
    this.property.options = options;
    this.updateProperty();
  }

  @computedFrom('propertyPermissionsHandle.canEditField.name')
  public get canEditName(): boolean {
    return this.propertyPermissionsHandle.canEditField.name;
  }

  @computedFrom('propertyPermissionsHandle.canEditField.type')
  public get canEditType(): boolean {
    return this.propertyPermissionsHandle.canEditField.type;
  }

  @computedFrom('propertyPermissionsHandle.canEditField.choices')
  public get canEditChoices(): boolean {
    return this.propertyPermissionsHandle.canEditField.choices;
  }

  @computedFrom('propertyPermissionsHandle.canEditField.options')
  public get canEditOptions(): boolean {
    return this.propertyPermissionsHandle.canEditField.options;
  }

  @computedFrom('propertyPermissionsHandle.canDeleteEntity')
  public get canBeDeleted(): boolean {
    return this.propertyPermissionsHandle.canDeleteEntity;
  }

  public getPropertyDefinition(): StructureTemplateEntryProperty {
    return this.property;
  }

  public getPropertyFeatures(): PropertyFeatures {
    return {
      canHavePositions: false,
      canHaveProjectParameter: false,
      canHaveTableEntries: false,
      allowDefaultValues: true
    };
  }

  public createPropertyWidgetConfiguration(): StructureTemplateEntryPropertyDefinitionWidgetConfiguration | null {
    return createStructureTemplateEntryPropertyDefinitionWidgetConfiguration();
  }

  private updateProperty(): void {
    this.entityManager.structureTemplateEntryPropertyRepository.update(
      this.property
    );
  }
}

export type StructureTemplateEntryPropertyDefinitionHandleOptions = {
  entityManager: AppEntityManager;
  permissionsService: PermissionsService;
  property: StructureTemplateEntryProperty;
};
