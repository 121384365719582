import { computedFrom } from 'aurelia-framework';
import { ProcessTaskOffer } from '../../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { ProcessTaskOfferAdapter } from '../EntityAdapter/ProcessTaskOfferAdapter/ProcessTaskOfferAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ProcessTaskOfferPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ProcessTaskOfferAdapter>,
  ProcessTaskOffer
> {
  @computedFrom('combinedRevision')
  public get canEditProcessTaskOfferToProcessTasks(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canEditProcessTaskOfferToProcessTasks'
    );
  }

  @computedFrom('combinedRevision')
  public get canEditProcessTaskOfferToProcessTaskDevices(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canEditProcessTaskOfferToProcessTaskDevices'
    );
  }

  @computedFrom('combinedRevision')
  public get canEditProcessTaskOfferToProcessTaskPositions(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canEditProcessTaskOfferToProcessTaskPositions'
    );
  }
}
