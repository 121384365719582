import { autoinject } from 'aurelia-framework';
import { SocketService } from './SocketService';

import {
  GisParsingHelper,
  GisPropertyName
} from 'common/GisParsingHelper/GisParsingHelper';
import {
  GstInfoOwner,
  GstInfoMetaData,
  GetGstInfoRequest,
  GetGstInfoSuccessResponse
} from 'common/EndpointTypes/GeoDataEndpointsHandler';

import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { Entry } from '../classes/EntityManager/entities/Entry/types';
import { Person } from '../classes/EntityManager/entities/Person/types';
import { EntryToPerson } from '../classes/EntityManager/entities/EntryToPerson/types';
import { EntryProperty } from '../classes/EntityManager/entities/Property/types';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { PermissionsService } from './PermissionsService/PermissionsService';
import { EntityName } from '../classes/EntityManager/entities/types';
import { UserGroup } from '../classes/EntityManager/entities/UserGroup/types';

@autoinject()
export class GisService {
  constructor(
    private readonly socketService: SocketService,
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService
  ) {}

  public async syncGstInfoWithEntry(
    entry: Entry,
    latitude: number,
    longitude: number,
    municipalityCode: string
  ): Promise<void> {
    const gstInfo = await this.getGstInfo({
      lat: latitude,
      long: longitude,
      gemeindeNr: municipalityCode
    });
    this.syncGisProperties(entry, gstInfo.meta);

    const userGroup = this.entityManager.userGroupRepository.getRequiredById(
      entry.ownerUserGroupId
    );

    await this.syncOwnersIfAllowed({
      entry,
      gstInfo,
      latitude,
      longitude,
      userGroup
    });
  }

  public async syncGstInfoWithEntryForAdditionalOwners(
    entry: Entry,
    latitude: number,
    longitude: number,
    municipalityCode: string
  ): Promise<void> {
    const gstInfo = await this.getGstInfo({
      lat: latitude,
      long: longitude,
      gemeindeNr: municipalityCode
    });

    const userGroup = this.entityManager.userGroupRepository.getRequiredById(
      entry.ownerUserGroupId
    );

    await this.syncAdditionalOwnersIfAllowed({
      entry,
      gstInfo,
      latitude,
      longitude,
      userGroup
    });
  }

  private getDummyPersonIdentifier(gstInfo: GetGstInfoSuccessResponse): string {
    return `DummyEstatePerson_Gst${gstInfo.meta.gstnr}_KG${gstInfo.meta.kgnr}`;
  }

  private async getPersonsFromGstInfo({
    gstInfo,
    userGroup
  }: {
    gstInfo: GetGstInfoSuccessResponse;
    userGroup: UserGroup;
  }): Promise<Array<Person>> {
    const currentContacts =
      this.entityManager.personRepository.getByUserGroupId(userGroup.id);

    const persons = gstInfo.owners.length
      ? this.ensurePersonsFromOwnerData(
          currentContacts,
          gstInfo.owners,
          userGroup.id
        )
      : [];

    if (!persons.length) {
      const person = await this.ensureDummyEstatePersonFromGstDataIfAllowed(
        currentContacts,
        gstInfo,
        userGroup
      );

      if (person) {
        // We have no person data available => create estate as dummy person
        persons.push(person);
      }
    }

    return persons;
  }

  private async syncAdditionalOwnersIfAllowed({
    entry,
    gstInfo,
    latitude,
    longitude,
    userGroup
  }: {
    entry: Entry;
    gstInfo: GetGstInfoSuccessResponse;
    latitude: number;
    longitude: number;
    userGroup: UserGroup;
  }): Promise<void> {
    const persons = await this.getPersonsFromGstInfo({
      gstInfo,
      userGroup
    });
    const entryToPersonEntries =
      this.entityManager.entryToPersonRepository.getByEntryId(entry.id);

    for (const person of persons) {
      await this.ensureRelationToPersonIfAllowed({
        entry,
        person,
        entryToPersonEntries,
        latitude,
        longitude
      });
    }
  }

  private async ensureCorrectCoordinatesForEntryToPersonRelationIfAllowed(
    entryToPersonRelation: EntryToPerson,
    latitude: number,
    longitude: number
  ): Promise<void> {
    if (
      entryToPersonRelation.coords?.latitude === latitude &&
      entryToPersonRelation.coords?.longitude === longitude
    ) {
      return;
    }

    const canEditCoords = await this.permissionsService.useAdapterOnce({
      entityName: EntityName.EntryToPerson,
      useAdapter: (adapter) => {
        return adapter.canEditField(entryToPersonRelation); // coords
      }
    });

    if (!canEditCoords) {
      console.warn(
        "didn't update coords because the user doesn't have permission"
      );
      return;
    }

    entryToPersonRelation.coords = { latitude, longitude };
    this.entityManager.entryToPersonRepository.update(entryToPersonRelation);
  }

  private async ensureRelationToPersonIfAllowed({
    entry,
    person,
    entryToPersonEntries,
    latitude,
    longitude
  }: {
    entry: Entry;
    person: Person;
    entryToPersonEntries: Array<EntryToPerson>;
    latitude: number;
    longitude: number;
  }): Promise<void> {
    const existingRelation = entryToPersonEntries.find(
      (entryToPerson) => entryToPerson.personId === person.id
    );

    if (existingRelation) {
      await this.ensureCorrectCoordinatesForEntryToPersonRelationIfAllowed(
        existingRelation,
        latitude,
        longitude
      );
    } else {
      const canEditEntryToPersons =
        await this.permissionsService.useAdapterOnce({
          entityName: EntityName.Entry,
          useAdapter: (adapter) => {
            return adapter.canEditEntryToPersons(entry);
          }
        });

      if (canEditEntryToPersons) {
        this.entityManager.entryToPersonRepository.create({
          entryId: entry.id,
          personId: person.id,
          mainContact: false,
          ownerUserGroupId: entry.ownerUserGroupId,
          ownerProjectId: entry.ownerProjectId,
          coords: {
            latitude: latitude,
            longitude: longitude
          }
        });
      } else {
        console.warn(
          "couldn't create entryToPerson because the user doesn't have permissions for it"
        );
      }
    }
  }

  private async syncOwnersIfAllowed({
    entry,
    gstInfo,
    latitude,
    longitude,
    userGroup
  }: {
    entry: Entry;
    gstInfo: GetGstInfoSuccessResponse;
    latitude: number;
    longitude: number;
    userGroup: UserGroup;
  }): Promise<void> {
    const persons = await this.getPersonsFromGstInfo({ gstInfo, userGroup });
    const entryToPersonEntries =
      this.entityManager.entryToPersonRepository.getByEntryId(entry.id);

    if (
      await this.ownersAreListedWithMatchingCoordinates(
        persons,
        entryToPersonEntries,
        latitude,
        longitude
      )
    ) {
      return;
    }

    const { deletedAllEntryToPersonEntries } =
      await this.deleteAllEntryToPersonEntriesIfAllowed({
        entryToPersonEntries
      });

    // since we couldn't delete the relations, we just stop syncing here or everything will be full of duplicates
    if (!deletedAllEntryToPersonEntries) {
      console.warn(
        "stopped syncing, because the user doesn't have permission to delete all entryToPersons"
      );
      return;
    }

    await this.createEntryToPersonsIfAllowed({
      entry,
      persons,
      latitude,
      longitude
    });
  }

  /**
   * This will check if all the given owners are already part of the current owner list.
   * Returns false if at least one is missing.
   */
  private async ownersAreListedWithMatchingCoordinates(
    persons: Array<Person>,
    entryToPersonEntries: Array<EntryToPerson>,
    latitude: number,
    longitude: number
  ): Promise<boolean> {
    for (const person of persons) {
      const existingRelation = entryToPersonEntries.find(
        (entry) => entry.personId === person.id
      );
      if (existingRelation)
        await this.ensureCorrectCoordinatesForEntryToPersonRelationIfAllowed(
          existingRelation,
          latitude,
          longitude
        );
      if (!existingRelation) return false; // We can return without updating coords of further relations because all relations will get deleted anyway.
    }
    return true;
  }

  private async deleteAllEntryToPersonEntriesIfAllowed({
    entryToPersonEntries
  }: {
    entryToPersonEntries: Array<EntryToPerson>;
  }): Promise<{ deletedAllEntryToPersonEntries: boolean }> {
    const canDeleteAllRelations = await this.permissionsService.useAdapterOnce({
      entityName: EntityName.EntryToPerson,
      useAdapter: (adapter) => {
        return entryToPersonEntries.every((entryToPerson) => {
          return adapter.canDeleteEntity(entryToPerson);
        });
      }
    });

    if (!canDeleteAllRelations) {
      return { deletedAllEntryToPersonEntries: false };
    }

    for (const entryToPerson of entryToPersonEntries) {
      this.entityManager.entryToPersonRepository.delete(entryToPerson);
    }

    return { deletedAllEntryToPersonEntries: true };
  }

  private async createEntryToPersonsIfAllowed({
    entry,
    persons,
    latitude,
    longitude
  }: {
    entry: Entry;
    persons: Array<Person>;
    latitude: number;
    longitude: number;
  }): Promise<void> {
    const canEditEntryToPersons = await this.permissionsService.useAdapterOnce({
      entityName: EntityName.Entry,
      useAdapter: (adapter) => {
        return adapter.canEditEntryToPersons(entry);
      }
    });

    if (!canEditEntryToPersons) {
      console.warn(
        "didn't create entryToPersons because the user is not allowed to"
      );
      return;
    }

    let mainContactSet = false;

    for (const person of persons) {
      const isMainContact = !mainContactSet && person.streetName !== '';
      if (isMainContact) mainContactSet = true;
      this.entityManager.entryToPersonRepository.create({
        entryId: entry.id,
        personId: person.id,
        mainContact: isMainContact,
        ownerUserGroupId: entry.ownerUserGroupId,
        ownerProjectId: entry.ownerProjectId,
        coords: {
          latitude: latitude,
          longitude: longitude
        }
      });
    }
  }

  private async ensureDummyEstatePersonFromGstDataIfAllowed(
    currentContacts: Array<Person>,
    gstInfo: GetGstInfoSuccessResponse,
    userGroup: UserGroup
  ): Promise<Person | null> {
    const dummyPerson = currentContacts.find(
      (contact) => contact.note === this.getDummyPersonIdentifier(gstInfo)
    );

    if (dummyPerson) {
      return dummyPerson;
    }

    const canCreatePersons = await this.permissionsService.useAdapterOnce({
      entityName: EntityName.UserGroup,
      useAdapter: (adapter) => {
        return adapter.canCreatePersons(userGroup);
      }
    });

    if (!canCreatePersons) {
      return null;
    }

    return this.entityManager.personRepository.create({
      firstName: 'Grundstück',
      streetName: `Gst-Nr.: ${gstInfo.meta.gstnr}`,
      zip: `KG: ${gstInfo.meta.kgnr}`,
      note: this.getDummyPersonIdentifier(gstInfo),
      ownerUserGroupId: userGroup.id
    });
  }

  private ensurePersonsFromOwnerData(
    currentContacts: Array<Person>,
    owners: Array<GstInfoOwner>,
    userGroupId: string
  ): Array<Person> {
    const persons = [];

    for (const owner of owners) {
      const parsedAddress = owner.address;
      let existing = currentContacts.find((contact) =>
        GisParsingHelper.arePersonsEqual(contact, owner, parsedAddress)
      );
      if (!existing) {
        const newOwner = {
          firstName: owner.name,
          lastName: owner.lastName,
          company: owner.company !== '',
          companyName: owner.company,
          ownerUserGroupId: userGroupId,
          streetName: parsedAddress.street,
          zip: parsedAddress.zipCode || null,
          municipality: parsedAddress.municipalityName || null
        };
        existing = this.entityManager.personRepository.create(newOwner);
      }
      persons.push(existing);
    }
    return persons;
  }

  private syncGisProperties(entry: Entry, gstMeta: GstInfoMetaData): void {
    const properties = this.entityManager.propertyRepository.getByEntryId(
      entry.id
    );
    this.ensureGisProperty(
      entry,
      properties,
      GisPropertyName.KGNR,
      gstMeta.kgnr
    );
    this.ensureGisProperty(
      entry,
      properties,
      GisPropertyName.GSTNR,
      gstMeta.gstnr
    );
    this.ensureGisProperty(entry, properties, GisPropertyName.EZ, gstMeta.ez);
  }

  private ensureGisProperty(
    entry: Entry,
    properties: Array<EntryProperty>,
    name: string,
    value: string
  ): void {
    let propertyToSet = properties.find((property) => property.name === name);
    if (!propertyToSet) {
      propertyToSet = this.entityManager.propertyRepository.create({
        entry: entry.id,
        name: name,
        type: PropertyType.TEXT,
        value: value,
        alwaysVisible: true,
        ownerUserGroupId: entry.ownerUserGroupId,
        ownerProjectId: entry.ownerProjectId
      }) as EntryProperty;
    } else {
      propertyToSet.value = value;
      this.entityManager.propertyRepository.update(propertyToSet);
    }
  }

  private async getGstInfo(
    locationData: GetGstInfoRequest
  ): Promise<GetGstInfoSuccessResponse> {
    return new Promise((resolve, reject) => {
      this.socketService.getGstInfo(locationData, (data) => {
        if (data.success) {
          resolve(data);
        } else {
          reject('Could not query owners');
        }
      });
    });
  }
}
