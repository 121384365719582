import { autoinject } from 'aurelia-framework';

import { DateUtils } from 'common/DateUtils';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { StructureTemplateEntry } from '../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { configureHooks } from '../../hooks/configureHooks';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class EditStructureTemplateEntryDialog {
  @subscribableLifecycle()
  protected readonly structureTemplateEntryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplateEntry];

  private dialog: RecordItDialog | null = null;

  private onDialogClosed: OnDialogClosedCallback | null = null;

  private structureTemplateEntry: StructureTemplateEntry | null = null;

  private DateUtils: DateUtils = DateUtils;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.structureTemplateEntryPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.StructureTemplateEntry,
        context: this,
        expression: 'structureTemplateEntry'
      });
  }

  public static async open(
    options: IEditStructureTemplateEntryDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: IEditStructureTemplateEntryDialogOptions): void {
    this.structureTemplateEntry = options.structureTemplateEntry;
    this.onDialogClosed = options.onDialogClosed || null;

    this.dialog?.open();
  }

  private handleDialogClosed(): void {
    const structureTemplateEntry = this.structureTemplateEntry;
    const onDialogClosed = this.onDialogClosed;

    this.structureTemplateEntry = null;
    this.onDialogClosed = null;

    structureTemplateEntry &&
      onDialogClosed &&
      onDialogClosed(structureTemplateEntry);
  }

  private handleStructureTemplateEntryChanged(): void {
    if (this.structureTemplateEntry)
      this.entityManager.structureTemplateEntryRepository.update(
        this.structureTemplateEntry
      );
  }
}

interface IEditStructureTemplateEntryDialogOptions {
  structureTemplateEntry: StructureTemplateEntry;
  onDialogClosed?: OnDialogClosedCallback | null;
}

type OnDialogClosedCallback = (
  structureTEmplateEntry: StructureTemplateEntry
) => void;
