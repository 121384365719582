import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import type { ProcessConfigurationNoteCategory } from './types';

export class ProcessConfigurationNoteCategoryRepository extends AppEntityRepository<EntityName.ProcessConfigurationNoteCategory> {
  public getByProcessConfigurationId(
    processConfigurationId: string
  ): Array<ProcessConfigurationNoteCategory> {
    return this.getAll().filter(
      (f) => f.ownerProcessConfigurationId === processConfigurationId
    );
  }
}
