import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntryUtils } from '../../../classes/EntityManager/entities/Entry/EntryUtils';
import { Entry } from '../../../classes/EntityManager/entities/Entry/types';
import { EntryPicture } from '../../../classes/EntityManager/entities/Picture/types';
import { Property } from '../../../classes/EntityManager/entities/Property/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { PictureSelectAndEditDialog } from '../../../dialogs/picture-select-and-edit-dialog/picture-select-and-edit-dialog';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { ColumnConfig } from '../edit-structure-table-widget';

@autoinject()
export class EditStructureTableWidgetEntryRow {
  @bindable()
  public entry: Entry | null = null;

  @bindable()
  public columnConfigs: Array<ColumnConfig> = [];

  @bindable()
  public forceDisabled: boolean = false;

  @subscribableLifecycle()
  protected readonly entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Entry,
        context: this as EditStructureTableWidgetEntryRow,
        propertyName: 'entry'
      });
  }

  protected forceDisabledChanged(): void {
    this.entryPermissionsHandle.overrideAllPermissions(
      this.forceDisabled ? false : null
    );
  }

  protected handleEntryChanged(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't EditStructureTableWidgetEntryRow.handleEntryChanged without entry"
    );

    this.entityManager.entryRepository.update(this.entry);
  }

  protected handlePictureClick(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't EditStructureTableWidgetEntryRow.handlePictureClick without entry"
    );

    void PictureSelectAndEditDialog.open({
      canCreatePictures: this.entryPermissionsHandle.canEditPictures,
      mainEntityIdField: 'project',
      mainEntityId: this.entry.project,
      subEntityField: 'entry',
      subEntityValue: this.entry.id,
      ownerUserGroupId: this.entry.ownerUserGroupId,
      ownerProjectId: this.entry.ownerProjectId
    });
  }

  protected getIndexForEntry(entry: Entry): string {
    return EntryUtils.getPageDepthIndex({
      path: this.entityManager.entryRepository.getPathByEntryId(entry.id)
    });
  }

  protected getSelectedPictureForEntry(entry: Entry): EntryPicture | null {
    return this.entityManager.pictureRepository.getSelectedEntryPicture(
      entry.id
    );
  }

  protected getPropertyOfEntryByName(
    entry: Entry,
    propertyName: string
  ): Property | undefined {
    const properties = this.entityManager.propertyRepository.getByEntryId(
      entry.id
    );
    return properties.find((prop) => prop.name === propertyName);
  }
}
