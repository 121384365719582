import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { QuestionSet } from '../../classes/EntityManager/entities/QuestionSet/types';
import { CreateEntityClickedEvent } from '../../aureliaComponents/create-entity-button/create-entity-button';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { EditQuestionDialog } from '../../dialogs/edit-question-dialog/edit-question-dialog';
import { I18N } from 'aurelia-i18n';

@autoinject()
export class EditChecklistQuestions {
  private questionSetId: string | null = null;

  protected readonly EntityName = EntityName;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly i18n: I18N
  ) {}

  protected activate(params: { question_set_id: string }): void {
    this.questionSetId = params.question_set_id;
  }

  @computed(expression('questionSetId'), model(EntityName.QuestionSet))
  protected get questionSet(): QuestionSet | null {
    if (!this.questionSetId) return null;

    return this.entityManager.questionSetRepository.getById(this.questionSetId);
  }

  protected handleCreateQuestionButtonClicked(
    event: CreateEntityClickedEvent
  ): void {
    assertNotNullOrUndefined(
      this.questionSetId,
      'cannot handleCreateSetButtonClicked without questionSetId'
    );

    const ownerUserGroupId = event.detail.userGroup?.id;
    if (ownerUserGroupId) {
      const question = this.entityManager.questionRepository.create({
        questionSetId: this.questionSetId,
        questionCategoryId: null,
        choices: [
          {
            choiceText: this.i18n.tr('general.yes'),
            actions: []
          },
          {
            choiceText: this.i18n.tr('general.no'),
            actions: []
          }
        ],
        ownerUserGroupId
      });
      void EditQuestionDialog.open({
        question
      });
    }
  }
}
