import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { PropertyType } from '../Property/PropertyDto';
import { StructureTemplateEntryPropertyDto } from './StructureTemplateEntryPropertyDto';

export function createStructureTemplateEntryPropertyEntityInfo<
  TEntity extends StructureTemplateEntryPropertyDto<string, string>
>(
  options: CreateEntityInfoOptions<
    StructureTemplateEntryPropertyDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.StructureTemplateEntryProperty,
  TEntity
> {
  return {
    entityName: EntityName.StructureTemplateEntryProperty,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      StructureTemplateEntryPropertyDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        type: {
          name: 'type',
          defaultValue: PropertyType.TEXT
        },
        value: {
          name: 'value',
          defaultValue: null
        },
        choices: {
          name: 'choices',
          defaultValue: () => []
        },
        options: {
          name: 'options',
          innerInfo: [
            {
              name: {
                name: 'name'
              },
              value: {
                name: 'value'
              }
            }
          ],
          defaultValue: () => []
        },
        order: {
          name: 'order',
          defaultValue: null
        },
        excludeFromFlawNumberComputationForValues: {
          name: 'excludeFromFlawNumberComputationForValues',
          defaultValue: () => []
        },
        structureTemplateEntryId: {
          name: 'structureTemplateEntryId',
          ref: EntityName.StructureTemplateEntry,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        ownerStructureTemplateId: {
          name: 'ownerStructureTemplateId',
          ref: EntityName.StructureTemplate,
          cascadeDeleteLocally: true,
          additionalData: {
            ownerId: true
          }
        }
      },
      options.additionalFieldInfos
    )
  };
}
