import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { structureTemplateEntryGroupToStructureTemplateEntryEntityInfo } from '../../../../classes/EntityManager/entities/StructureTemplateEntryGroupToStructureTemplateEntry/structureTemplateEntryGroupToStructureTemplateEntryEntityInfo';
import { StructureTemplateEntryGroupToStructureTemplateEntry } from '../../../../classes/EntityManager/entities/StructureTemplateEntryGroupToStructureTemplateEntry/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { StructureTemplateEntryGroupAdapter } from '../StructureTemplateEntryGroupAdapter/StructureTemplateEntryGroupAdapter';

export class StructureTemplateEntryGroupToStructureTemplateEntryAdapter
  implements EntityAdapter<StructureTemplateEntryGroupToStructureTemplateEntry>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private structureTemplateEntryGroupAdapter: StructureTemplateEntryGroupAdapter | null =
    null;

  constructor(
    options: StructureTemplateEntryGroupToStructureTemplateEntryAdapterOptions
  ) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.StructureTemplateEntryGroup,
        onNextAdapter: ({ adapter }) => {
          this.structureTemplateEntryGroupAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.structureTemplateEntryGroupAdapter != null &&
      this.structureTemplateEntryGroupAdapter.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(
    structureTemplateEntryGroupToStructureTemplateEntry: StructureTemplateEntryGroupToStructureTemplateEntry
  ): boolean {
    return this.canEditStructureTemplateEntryGroupToStructureTemplateEntry(
      structureTemplateEntryGroupToStructureTemplateEntry
    );
  }

  public canEditField(
    structureTemplateEntryGroupToStructureTemplateEntry: StructureTemplateEntryGroupToStructureTemplateEntry
  ): boolean {
    return this.canEditStructureTemplateEntryGroupToStructureTemplateEntry(
      structureTemplateEntryGroupToStructureTemplateEntry
    );
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.StructureTemplateEntryGroupToStructureTemplateEntry,
    StructureTemplateEntryGroupToStructureTemplateEntry
  > {
    return structureTemplateEntryGroupToStructureTemplateEntryEntityInfo;
  }

  private canEditStructureTemplateEntryGroupToStructureTemplateEntry(
    structureTemplateEntryGroupToStructureTemplateEntry: StructureTemplateEntryGroupToStructureTemplateEntry
  ): boolean {
    if (!this.structureTemplateEntryGroupAdapter) {
      return false;
    }

    const structureTemplateEntryGroup =
      this.entityManager.structureTemplateEntryGroupRepository.getById(
        structureTemplateEntryGroupToStructureTemplateEntry.structureTemplateEntryGroupId
      );

    if (!structureTemplateEntryGroup) {
      return false;
    }

    return this.structureTemplateEntryGroupAdapter.canEditStructureTemplateEntryGroupToStructureTemplateEntries(
      structureTemplateEntryGroup
    );
  }
}

export type StructureTemplateEntryGroupToStructureTemplateEntryAdapterOptions =
  {
    entityManager: AppEntityManager;
    subscriptionManagerService: SubscriptionManagerService;
  };
