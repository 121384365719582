import moment from 'moment';
import { autoinject } from 'aurelia-framework';

import {
  PhoneLinkClickedData,
  AutoAppointmentCreatedData,
  AutoAppointmentDeletedData,
  ProcessConfigurationActionStatusModifiedData,
  ProcessTaskGroupAssigneeChangedData,
  ProcessTaskNameModifiedData,
  ProcessTaskLogEntryAction,
  LogDataByProcessLogEntryAction
} from 'common/processTaskLogEntryActions';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { CurrentUserService } from '../classes/EntityManager/entities/User/CurrentUserService';
import { ProcessTask } from '../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskLogEntry } from '../classes/EntityManager/entities/ProcessTaskLogEntry/types';
import { ProcessTaskAppointment } from '../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessConfigurationStepAutoAppointment } from '../classes/EntityManager/entities/ProcessConfigurationStepAutoAppointment/types';
import { EntityName } from '../classes/EntityManager/entities/types';
import { Property } from '../classes/EntityManager/entities/Property/types';
import { PersonContact } from '../classes/EntityManager/entities/PersonContact/types';
import { Person } from '../classes/EntityManager/entities/Person/types';
import { PersonHelper } from '../../../common/src/EntityHelper/PersonHelper';
import { ProcessTaskChecklistEntry } from '../classes/EntityManager/entities/ProcessTaskChecklistEntry/types';
import { ProcessTaskSubEntityDto } from 'common/Types/BaseEntities/ProcessTaskSubEntityUtils';
import { ProcessTaskGroupAuthorization } from '../classes/EntityManager/entities/ProcessTaskGroupAuthorization/types';
import type { ProcessTaskNote } from '../classes/EntityManager/entities/ProcessTaskNote/types';

@autoinject()
export class ProcessTaskLoggingService {
  private static SUB_ENTITY_MODIFICATION_COMBINE_TIME = 10 * 60 * 1000;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService
  ) {}

  public logProcessTaskNameModified(
    processTask: ProcessTask
  ): Promise<ProcessTaskLogEntry> {
    const data: ProcessTaskNameModifiedData = {
      newName: processTask.name
    };

    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.PROCESS_TASK_NAME_MODIFIED,
      data
    );
  }

  public logProcessTaskDescriptionModified(
    processTask: ProcessTask
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.DESCRIPTION_MODIFIED,
      {}
    );
  }

  public logAssigneeChanged(
    processTask: ProcessTask,
    data: ProcessTaskGroupAssigneeChangedData
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.ASSIGNEE_CHANGED,
      data
    );
  }

  public logProcessTaskCreated(
    processTask: ProcessTask
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.CREATED,
      {}
    );
  }

  public logCurrentProcessConfigurationStepIdChanged(
    processTask: ProcessTask,
    newCurrentProcessConfigurationStepId: string
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.PROCESS_CONFIGURATION_STEP_ID_MODIFIED,
      {
        processConfigurationStepId: newCurrentProcessConfigurationStepId
      }
    );
  }

  public logProcessConfigurationActionStatusModified(
    processTask: ProcessTask,
    modifiedData: ProcessConfigurationActionStatusModifiedData
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.PROCESS_CONFIGURATION_ACTION_STATUS_MODIFIED,
      modifiedData
    );
  }

  public logAppointmentOpenedByPerson(
    processTask: ProcessTask,
    processTaskAppointmentId: string,
    personId: string
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.APPOINTMENT_OPENED_BY_PERSON,
      {
        processTaskAppointmentId: processTaskAppointmentId,
        personId: personId
      }
    );
  }

  public logAppointmentFinished(
    processTask: ProcessTask,
    processTaskAppointmentId: string,
    appointmentOpenedAt: string | null
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.APPOINTMENT_FINISHED,
      {
        openedAt: appointmentOpenedAt ?? null,
        processTaskAppointmentId: processTaskAppointmentId
      }
    );
  }

  public logAppointmentNoteConfirmed(
    processTask: ProcessTask,
    processTaskAppointment: ProcessTaskAppointment
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.APPOINTMENT_NOTE_CONFIRMED,
      {
        processTaskAppointmentId: processTaskAppointment.id,
        processTaskAppointmentNote: processTaskAppointment.note
      }
    );
  }

  public logAppointmentFinishedByPerson(
    processTask: ProcessTask,
    processTaskAppointmentId: string,
    personId: string,
    appointmentOpenedAt: string | null
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.APPOINTMENT_FINISHED_BY_PERSON,
      {
        openedAt: appointmentOpenedAt ?? null,
        processTaskAppointmentId: processTaskAppointmentId,
        personId: personId
      }
    );
  }

  public logAppointmentUndoFinished(
    processTask: ProcessTask,
    processTaskAppointmentId: string
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.APPOINTMENT_UNDO_FINISHED,
      {
        processTaskAppointmentId: processTaskAppointmentId
      }
    );
  }

  public logAppointmentUndoFinishedByPerson(
    processTask: ProcessTask,
    processTaskAppointmentId: string,
    personId: string
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.APPOINTMENT_UNDO_FINISHED_BY_PERSON,
      {
        processTaskAppointmentId: processTaskAppointmentId,
        personId: personId
      }
    );
  }

  public logAutoAppointmentCreated(
    processTask: ProcessTask,
    processTaskAppointment: ProcessTaskAppointment,
    processConfigurationStepAutoAppointment: ProcessConfigurationStepAutoAppointment
  ): Promise<ProcessTaskLogEntry> {
    const data: AutoAppointmentCreatedData = {
      name: processTaskAppointment.name || '',
      processConfigurationStepAutoAppointmentId:
        processConfigurationStepAutoAppointment.id,
      processTaskAppointmentId: processTaskAppointment.id
    };

    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.AUTO_APPOINTMENT_CREATED,
      data
    );
  }

  public logAutoAppointmentDeleted(
    processTask: ProcessTask,
    processTaskAppointment: ProcessTaskAppointment
  ): Promise<ProcessTaskLogEntry> {
    assertNotNullOrUndefined(
      processTaskAppointment.processConfigurationStepAutoAppointmentId,
      `appointment ${processTaskAppointment.id} is not an auto appointment`
    );

    const data: AutoAppointmentDeletedData = {
      name: processTaskAppointment.name || '',
      processConfigurationStepAutoAppointmentId:
        processTaskAppointment.processConfigurationStepAutoAppointmentId,
      processTaskAppointmentId: processTaskAppointment.id
    };

    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.AUTO_APPOINTMENT_DELETED,
      data
    );
  }

  public logProcessTaskSubEntityModified({
    entityName,
    entity,
    property,
    displayNameAtLogTime
  }: {
    entityName: EntityName;
    entity: ProcessTaskSubEntityDto;
    property: string | null;
    displayNameAtLogTime: string | null;
  }): void {
    void this.findLastProcessTaskSubEntityModifiedLog({ entity }).then(
      (lastLogEntry) => {
        if (lastLogEntry) {
          lastLogEntry.date = new Date().toISOString();
          if (lastLogEntry.logDataJson) {
            const logData = JSON.parse(lastLogEntry.logDataJson);

            logData.displayNameAtLogTime = displayNameAtLogTime;

            if (property) {
              logData.changes[property] = (entity as Record<string, any>)[
                property
              ];
            }

            lastLogEntry.logDataJson = JSON.stringify(logData);
          }
          this.entityManager.processTaskLogEntryRepository.update(lastLogEntry);
        } else {
          const changes: Record<string, any> = {};
          if (property)
            changes[property] = (entity as Record<string, any>)[property];
          void this.createProcessTaskSubEntityLog(
            entity,
            ProcessTaskLogEntryAction.SUB_ENTITY_MODIFIED,
            {
              entityId: entity.id,
              entityName: entityName,
              changes: changes,
              displayNameAtLogTime
            }
          );
        }
      }
    );
  }

  public logProcessTaskSubEntityDeleted({
    entityName,
    entity,
    displayNameAtLogTime
  }: {
    entityName: EntityName;
    entity: ProcessTaskSubEntityDto;
    displayNameAtLogTime: string | null;
  }): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskSubEntityLog(
      entity,
      ProcessTaskLogEntryAction.SUB_ENTITY_DELETED,
      {
        entityId: entity.id,
        entityName,
        displayNameAtLogTime
      }
    );
  }

  public logProcessTaskSubEntityCreated({
    entityName,
    entity,
    displayNameAtLogTime
  }: {
    entityName: EntityName;
    entity: ProcessTaskSubEntityDto;
    displayNameAtLogTime: string | null;
  }): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskSubEntityLog(
      entity,
      ProcessTaskLogEntryAction.SUB_ENTITY_CREATED,
      {
        entityId: entity.id,
        entityName,
        displayNameAtLogTime
      }
    );
  }

  public logProcessTaskPropertyModified(
    processTask: ProcessTask,
    property: Property
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.PROCESS_TASK_PROPERTY_MODIFIED,
      {
        propertyId: property.id
      }
    );
  }

  public logPhoneLinkClick(
    processTask: ProcessTask,
    person: Person,
    personContact: PersonContact
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.PHONE_LINK_CLICKED,
      {
        personName: PersonHelper.getPersonDisplayName(
          person.company,
          person.companyName,
          person.title,
          person.firstName,
          person.lastName
        ),
        phoneNumber: personContact.name
      } as PhoneLinkClickedData
    );
  }

  public logCheckListEntryCreated({
    processTask,
    checklistEntry,
    attachmentNames
  }: {
    processTask: ProcessTask;
    checklistEntry: ProcessTaskChecklistEntry;
    attachmentNames: Array<string>;
  }): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.CHECKLIST_ENTRY_CREATED,
      {
        processTaskChecklistEntryId: checklistEntry.id,
        text: checklistEntry.text,
        attachmentNames
      }
    );
  }

  public logCheckListEntryDoneChanged(
    processTask: ProcessTask,
    checklistEntry: ProcessTaskChecklistEntry
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.CHECKLIST_ENTRY_DONE_CHANGED,
      {
        processTaskChecklistEntryId: checklistEntry.id,
        done: checklistEntry.done
      }
    );
  }

  public logCheckListEntryTextChanged(
    processTask: ProcessTask,
    checklistEntry: ProcessTaskChecklistEntry,
    oldText: string | null
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.CHECKLIST_ENTRY_TEXT_CHANGED,
      {
        processTaskChecklistEntryId: checklistEntry.id,
        oldText,
        newText: checklistEntry.text
      }
    );
  }

  public logNoteCreated(
    processTask: ProcessTask
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.NOTE_CREATED,
      {}
    );
  }

  public async logNoteEdited(
    processTask: ProcessTask,
    processTaskNote: ProcessTaskNote,
    oldText: string | null
  ): Promise<ProcessTaskLogEntry> {
    const processTaskNoteId = processTaskNote.id;
    const newText = processTaskNote.content ?? '';

    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.NOTE_EDITED,
      {
        processTaskNoteId,
        newText,
        oldText
      }
    );
  }

  public logNoteFinished(
    processTask: ProcessTask,
    processTaskNote: ProcessTaskNote
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.NOTE_FINISHED,
      {
        processTaskNoteId: processTaskNote.id,
        text: processTaskNote.content ?? ''
      }
    );
  }

  public logNoteRestored(
    processTask: ProcessTask,
    processTaskNote: ProcessTaskNote
  ): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.NOTE_RESTORED,
      {
        text: processTaskNote.content ?? ''
      }
    );
  }

  public logProcessTaskGroupAuthorizationCreated({
    processTask,
    processTaskGroupAuthorization
  }: {
    processTask: ProcessTask;
    processTaskGroupAuthorization: ProcessTaskGroupAuthorization;
  }): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.PROCESS_TASK_GROUP_AUTHORIZATION_CREATED,
      {
        processConfigurationAuthorizationTypeId:
          processTaskGroupAuthorization.processConfigurationAuthorizationTypeId,
        userId: processTaskGroupAuthorization.userId
      }
    );
  }

  public logProcessTaskGroupAuthorizationDeleted({
    processTask,
    processTaskGroupAuthorization
  }: {
    processTask: ProcessTask;
    processTaskGroupAuthorization: ProcessTaskGroupAuthorization;
  }): Promise<ProcessTaskLogEntry> {
    return this.createProcessTaskLog(
      processTask,
      ProcessTaskLogEntryAction.PROCESS_TASK_GROUP_AUTHORIZATION_DELETED,
      {
        processConfigurationAuthorizationTypeId:
          processTaskGroupAuthorization.processConfigurationAuthorizationTypeId,
        userId: processTaskGroupAuthorization.userId
      }
    );
  }

  private async findLastProcessTaskSubEntityModifiedLog({
    entity
  }: {
    entity: ProcessTaskSubEntityDto;
  }): Promise<ProcessTaskLogEntry | null> {
    const currentUser = await this.currentUserService.waitForCurrentUser();

    return (
      this.entityManager.processTaskLogEntryRepository
        .getByProcessTaskId(entity.ownerProcessTaskId)
        .find(
          (logEntry) =>
            logEntry.logAction ===
              ProcessTaskLogEntryAction.SUB_ENTITY_MODIFIED &&
            logEntry.userId === currentUser.id &&
            moment().diff(logEntry.date) <
              ProcessTaskLoggingService.SUB_ENTITY_MODIFICATION_COMBINE_TIME &&
            logEntry.logDataJson &&
            JSON.parse(logEntry.logDataJson).entityId === entity.id
        ) ?? null
    );
  }

  private async createProcessTaskLog<T extends ProcessTaskLogEntryAction>(
    processTask: ProcessTask,
    logAction: T,
    logData: LogDataByProcessLogEntryAction[T]
  ): Promise<ProcessTaskLogEntry> {
    return this.createLog(
      () => processTask.ownerUserGroupId,
      () => processTask.id,
      () => processTask.ownerProcessTaskGroupId,
      logAction,
      logData
    );
  }

  private async createProcessTaskSubEntityLog<
    T extends ProcessTaskLogEntryAction
  >(
    entity: ProcessTaskSubEntityDto,
    logAction: T,
    logData: LogDataByProcessLogEntryAction[T]
  ): Promise<ProcessTaskLogEntry> {
    return this.createLog(
      () => entity.ownerUserGroupId,
      () => entity.ownerProcessTaskId,
      () => entity.ownerProcessTaskGroupId,
      logAction,
      logData
    );
  }

  private async createLog<T extends ProcessTaskLogEntryAction>(
    userGroupIdGetter: () => string,
    processTaskIdGetter: () => string,
    processTaskGroupIdGetter: () => string,
    logAction: T,
    logData: LogDataByProcessLogEntryAction[T]
  ): Promise<ProcessTaskLogEntry> {
    const currentUser = await this.currentUserService.waitForCurrentUser();
    return this.entityManager.processTaskLogEntryRepository.create({
      userId: currentUser.id,
      logAction: logAction,
      logDataJson: logData ? JSON.stringify(logData) : null,
      date: new Date().toISOString(),
      ownerProcessTaskGroupId: processTaskGroupIdGetter(),
      ownerProcessTaskId: processTaskIdGetter(),
      ownerUserGroupId: userGroupIdGetter()
    });
  }
}
