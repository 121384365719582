import { autoinject, computedFrom } from 'aurelia-framework';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { Dialogs } from '../../classes/Dialogs';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ThingViewType } from '../../aureliaComponents/switch-thing-type-button/switch-thing-type-button';
import { watch } from '../../hooks/watch';
import { Router } from 'aurelia-router';
import { UpgradeEntityIdInUrlService } from '../../services/UpgradeEntityIdInUrlService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { KukExportDialog } from '../../dialogs/kuk-export-dialog/kuk-export-dialog';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { CatastropheOverview } from '../../kukComponents/catastrophe-overview/catastrophe-overview';

@autoinject()
export class EditCatastrophe {
  private thingId: string | null = null;

  protected ThingViewType = ThingViewType;

  protected catastropheOverview: CatastropheOverview | null = null;

  @subscribableLifecycle()
  protected thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly upgradeEntityIdInUrlService: UpgradeEntityIdInUrlService,
    private readonly router: Router,
    permissionsService: PermissionsService
  ) {
    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Thing,
        context: this as EditCatastrophe,
        propertyName: 'thing'
      });
  }

  protected activate(params: { thing_id: string }): void {
    this.thingId = params.thing_id;
  }

  @watch(expression('thingId'), model(EntityName.Thing))
  protected upgradeThing(): void {
    this.upgradeEntityIdInUrlService.checkId(
      {
        id: this.thingId,
        entityName: EntityName.Thing
      },
      'thing_id'
    );
  }

  protected handleMoreButtonChoiceSelected(name: string): void {
    switch (name) {
      case 'exportZip':
        return this.exportZip();
      default:
        throw new Error(`Choice ${name} not implemented.`);
    }
  }

  protected exportZip(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleMoreButtonChoiceSelected'
    );

    void KukExportDialog.open({
      thing: this.thing,
      onExportStarted: (reportId) => {
        // Scroll to the newly created report
        const report = this.entityManager.reportRepository.getById(reportId);
        if (report) {
          setTimeout(() => {
            void this.catastropheOverview?.scrollToReport(report);
          }, 1000);
        }
      }
    });
  }

  protected async handleFinishCatastropheButtonClicked(): Promise<void> {
    assertNotNullOrUndefined(
      this.thing,
      'cannot finish catastrophe without a thing'
    );

    try {
      await Dialogs.yesNoDialogTk(
        'generalPages.editCatastrophe.header.finishCatastropheDialog.title',
        undefined,
        'generalPages.editCatastrophe.header.finishCatastropheDialog.text'
      );
    } catch (error) {
      if (error instanceof Error && error.message === 'DialogCanceled') return;
      throw error;
    }

    // TODO: REC-4234 send data to relevant parties

    this.thing.archived = true;
    this.entityManager.thingRepository.update(this.thing);
    this.router.navigateToRoute('edit_objects');
  }

  @computed(expression('thingId'), model(EntityName.Thing))
  public get thing(): Thing | null {
    if (!this.thingId) return null;

    return this.entityManager.thingRepository.getById(this.thingId);
  }

  @computedFrom('thingPermissionsHandle.canCreateReports')
  protected get cannotExportThing(): boolean {
    return !this.thingPermissionsHandle.canCreateReports;
  }

  @computedFrom('entry')
  protected get moreButtonChoices(): Array<MoreButtonChoice> {
    return [
      {
        labelTk: 'generalPages.editCatastrophe.moreOptions.exportZip',
        name: 'exportZip',
        iconClass: 'fal fa-file-zipper',
        disabledContext: this,
        disabledPropertyName: 'cannotExportThing'
      }
    ];
  }
}
