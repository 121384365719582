import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ColumnInfo } from 'common/EndpointTypes/SharepointEndpointsHandler';
import { SynchronizationDirection } from 'common/Types/Entities/SharepointListColumnToThingMappingItem/SharepointListColumnToThingMappingItemDto';
import { ExprEvalParser } from 'common/ExprEvalParser/ExprEvalParser';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SharepointListColumnToThingMappingItem } from '../../classes/EntityManager/entities/SharepointListColumnToThingMappingItem/types';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression, isAuthenticated, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SocketService } from '../../services/SocketService';
import { EditSharepointListColumnToThingMappingItemDialog } from '../edit-sharepoint-list-column-to-thing-mapping-item-dialog/edit-sharepoint-list-column-to-thing-mapping-item-dialog';

@autoinject()
export class SharepointListColumnToThingMappingWidget {
  private static TEMPORARY_GROUP_NAME =
    'SharepointListColumnToThingMappingWidget::ItemToCreate';

  @bindable public thingGroup: ThingGroup | null = null;

  protected isUpdatingSharepointColumns = false;

  private availableColumnInfos: Array<ColumnInfo> = [];

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingGroup];

  private exprEvalParser = new ExprEvalParser();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    permissionsService: PermissionsService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.ThingGroup,
        context: this as SharepointListColumnToThingMappingWidget,
        propertyName: 'thingGroup'
      }
    );
  }

  protected attached(): void {
    void this.updateAvailableColumns();
  }

  @computed(
    expression('thingGroup'),
    model(EntityName.SharepointListColumnToThingMappingItem)
  )
  protected get items(): Array<SharepointListColumnToThingMappingItem> {
    if (!this.thingGroup?.id) return [];

    return this.entityManager.sharepointListColumnToThingMappingItemRepository.getByThingGroupId(
      this.thingGroup.id
    );
  }

  @computed(expression('isUpdatingSharepointColumns'), isAuthenticated())
  protected get updateSharepointColumnsButtonDisabled(): boolean {
    return (
      this.isUpdatingSharepointColumns || !this.socketService.isAuthenticated()
    );
  }

  private async updateAvailableColumns(): Promise<void> {
    if (
      this.sharepointUrlHasTemplates() ||
      this.sharepointListPathHasTemplates()
    ) {
      this.availableColumnInfos = [];
      return;
    }

    const userGroupId = this.thingGroup?.ownerUserGroupId;
    const siteUrl = this.thingGroup?.sharepointSettings.sharepointUrl;
    const listName = this.thingGroup?.sharepointSettings.listName;

    if (!userGroupId || !siteUrl || !listName) {
      this.availableColumnInfos = [];
      return;
    }

    this.isUpdatingSharepointColumns = true;
    const response =
      await this.socketService.sharepointSocketEndpoints.getListColumns({
        userGroupId,
        siteUrl,
        listName
      });
    this.isUpdatingSharepointColumns = false;

    if (response.success) {
      this.availableColumnInfos = response.columnInfos;
    } else {
      this.availableColumnInfos = [];
    }
  }

  protected handleAddMappingItemClick(): void {
    assertNotNullOrUndefined(
      this.thingGroup,
      'cannot add mapping item without a thing group'
    );

    const sharepointListColumnToThingMappingItem =
      this.entityManager.sharepointListColumnToThingMappingItemRepository.create(
        {
          synchronizationDirection: SynchronizationDirection.SP_TO_THING,
          ownerThingGroupId: this.thingGroup.id,
          ownerUserGroupId: this.thingGroup.ownerUserGroupId,
          shadowEntity: true,
          temporaryGroupName:
            SharepointListColumnToThingMappingWidget.TEMPORARY_GROUP_NAME
        }
      );

    void EditSharepointListColumnToThingMappingItemDialog.open({
      thingGroup: this.thingGroup,
      listColumnInfos: this.availableColumnInfos,
      sharepointListColumnToThingMappingItem,
      showDeclineButton: true,
      onDialogClosed: (options) => {
        if (options.accepted) {
          this.entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
            SharepointListColumnToThingMappingWidget.TEMPORARY_GROUP_NAME
          );
        } else {
          this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
            SharepointListColumnToThingMappingWidget.TEMPORARY_GROUP_NAME
          );
        }
      }
    });
  }

  protected async handleUpdateSharepointColumnsClick(): Promise<void> {
    await this.updateAvailableColumns();
  }

  private sharepointUrlHasTemplates(): boolean {
    return this.exprEvalParser.stringHasExpressions(
      this.thingGroup?.sharepointSettings.sharepointUrl ?? ''
    );
  }

  private sharepointListPathHasTemplates(): boolean {
    return this.exprEvalParser.stringHasExpressions(
      this.thingGroup?.sharepointSettings.listName ?? ''
    );
  }
}
