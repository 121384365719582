import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { entryToPersonEntityInfo } from '../../../../classes/EntityManager/entities/EntryToPerson/entryToPersonEntityInfo';
import { EntryToPerson } from '../../../../classes/EntityManager/entities/EntryToPerson/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { EntryAdapter } from '../EntryAdapter/EntryAdapter';

export class EntryToPersonAdapter implements EntityAdapter<EntryToPerson> {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private entryAdapter: EntryAdapter | null = null;

  constructor(options: EntryToPersonAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.Entry,
        onNextAdapter: ({ adapter }) => {
          this.entryAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.entryAdapter != null && this.entryAdapter.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(entryToPerson: EntryToPerson): boolean {
    return this.canEditEntryToPerson(entryToPerson);
  }

  public canEditField(entryToPerson: EntryToPerson): boolean {
    return this.canEditEntryToPerson(entryToPerson);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.EntryToPerson,
    EntryToPerson
  > {
    return entryToPersonEntityInfo;
  }

  private canEditEntryToPerson(entryToPerson: EntryToPerson): boolean {
    if (!this.entryAdapter) {
      return false;
    }

    const entry = this.entityManager.entryRepository.getById(
      entryToPerson.entryId
    );

    if (!entry) {
      return false;
    }

    return this.entryAdapter.canEditEntryToPersons(entry);
  }
}

export type EntryToPersonAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
