import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { UrlParameterService } from '../classes/UrlParameterService';
import { assertNotNullOrUndefined } from 'common/Asserts';

/**
 * Check if an entity needs to be upgraded
 * (i.e. if the ID of an entity changes from a local one to one provided by the server),
 * which often means that a page has to be reloaded if the ID of the entity is used in the URL.
 */
@autoinject()
export class UpgradeEntityIdInUrlService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly router: Router,
    private readonly urlParameterService: UrlParameterService
  ) {}

  /**
   * Reloads the page if the entity of the provided ID was upgraded.
   *
   * @param config details about the entity to check.
   * @param param the route parameter to update whenever the entity changes.
   *
   * @example
   * // Whenever the thing with the ID stored in `thingId` updates, reload the current page,
   * // replacing the `thing_id` query parameter with the new ID
   * \@watch(expression('thingId'), model(EntityName.Thing))
   * protected upgradeThing(): void {
   *   this.upgradeEntityIdInUrlService.checkId(
   *     {
   *       id: this.thingId,
   *       entityName: EntityName.Thing
   *     },
   *     'thing_id'
   *   );
   * }
   */
  public checkId(config: EntityDetails, param: string): void {
    if (!config.id) return;

    const repository =
      this.entityManager.entityRepositoryContainer.getByEntityName(
        config.entityName
      );
    const upgradedEntity = repository.getByOriginalId(config.id);

    if (upgradedEntity) {
      const instruction = this.router.currentInstruction;
      assertNotNullOrUndefined(
        instruction.config.name,
        'cannot upgrade route without a route name'
      );
      this.router.navigateToRoute(
        instruction.config.name,
        {
          ...this.urlParameterService.getCurrentParameters(),
          [param]: upgradedEntity.id
        },
        { replace: true }
      );
    }
  }
}

export type EntityDetails = {
  /**
   * ID of the entity to check for an upgrade.
   */
  id: string | null;
  /**
   * The model of the entity.
   */
  entityName: EntityName;
};
