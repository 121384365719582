import { autoinject, bindable } from 'aurelia-framework';
import { CustomCheckboxCheckedChangedEvent } from '../../../inputComponents/custom-checkbox/custom-checkbox';
import { ProcessTaskOfferRelationsWidgetPositions } from '../process-task-offer-relations-widget-positions/process-task-offer-relations-widget-positions';
import { ProcessTaskOfferRelationsWidgetDevices } from '../process-task-offer-relations-widget-devices/process-task-offer-relations-widget-devices';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskOffer } from '../../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { ProcessConfigurationCategory } from '../../../classes/EntityManager/entities/ProcessConfigurationCategory/types';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';

@autoinject()
export class ProcessTaskOfferRelationsWidgetProcessTask {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTaskOffer: ProcessTaskOffer | null = null;

  @bindable()
  public processConfigurationCategory: ProcessConfigurationCategory | null =
    null;

  @subscribableLifecycle()
  protected readonly processTaskOfferPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskOffer];

  protected allDevicesSelected: boolean = false;
  protected processTaskDeviceCount: number | null = null;
  protected allPositionsSelected: boolean = false;
  protected processTaskPositionCount: number | null = null;

  private processTaskOfferRelationsWidgetDevices: ProcessTaskOfferRelationsWidgetDevices | null =
    null;

  private processTaskOfferRelationsWidgetPositions: ProcessTaskOfferRelationsWidgetPositions | null =
    null;

  constructor(permissionsService: PermissionsService) {
    this.processTaskOfferPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskOffer,
        context: this,
        expression: 'processTaskOffer'
      });
  }

  protected handleSelectAllDevicesCheckedChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    const devicesWidget = this.getProcessTaskOfferRelationsWidgetDevices();

    if (event.detail.checked) {
      devicesWidget.selectAll();
    } else {
      devicesWidget.deselectAll();
    }
  }

  protected handleSelectAllPositionsCheckedChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    const positionsWidget = this.getProcessTaskOfferRelationsWidgetPositions();

    if (event.detail.checked) {
      positionsWidget.selectAll();
    } else {
      positionsWidget.deselectAll();
    }
  }

  private getProcessTaskOfferRelationsWidgetDevices(): ProcessTaskOfferRelationsWidgetDevices {
    if (!this.processTaskOfferRelationsWidgetDevices) {
      throw new Error(
        'processTaskOfferRelationsWidgetDevices is not available'
      );
    }

    return this.processTaskOfferRelationsWidgetDevices;
  }

  private getProcessTaskOfferRelationsWidgetPositions(): ProcessTaskOfferRelationsWidgetPositions {
    if (!this.processTaskOfferRelationsWidgetPositions) {
      throw new Error(
        'processTaskOfferRelationsWidgetPositions is not available'
      );
    }

    return this.processTaskOfferRelationsWidgetPositions;
  }
}
