import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { ScrollHelper } from '../../classes/ScrollHelper';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { UserDefinedEntityConfig } from '../../classes/EntityManager/entities/UserDefinedEntityConfig/types';
import { Pagination } from '../../aureliaComponents/pagination/pagination';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { CreateEntityClickedEvent } from '../../aureliaComponents/create-entity-button/create-entity-button';
import { computed } from '../../hooks/computed';
import { model } from '../../hooks/dependencies';
import { EditUserDefinedEntityConfigDialog } from '../../dialogs/edit-user-defined-entity-config-dialog/edit-user-defined-entity-config-dialog';

@autoinject()
export class EditUserDefinedEntityConfigs {
  protected sortedUserDefinedEntityConfigs: Array<UserDefinedEntityConfig> = [];
  protected currentPageUserDefinedEntityConfigs: Array<UserDefinedEntityConfig> =
    [];

  private isAttached = false;

  private sortOptions = {
    name: {
      name: 'Name',
      sortFunction: function (
        a: UserDefinedEntityConfig,
        b: UserDefinedEntityConfig
      ) {
        const aName = a.name ? a.name : '';
        const bName = b.name ? b.name : '';
        return aName.localeCompare(bName);
      }
    }
  };

  protected currentSortOption = this.sortOptions.name;

  private pagination: Pagination<UserDefinedEntityConfig> | null = null;

  private subscriptionManager: SubscriptionManager;

  protected isMobile: boolean = false;
  protected readonly EntityName = EntityName;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  @computed(model(EntityName.UserDefinedEntityConfig))
  protected get availableUserDefinedEntityConfigs(): Array<UserDefinedEntityConfig> {
    return this.entityManager.userDefinedEntityConfigRepository.getAll();
  }

  protected handleCreateUserDefinedEntityConfigClick(
    event: CreateEntityClickedEvent
  ): void {
    const userGroupId = event.detail.userGroup?.id;
    assertNotNullOrUndefined(
      userGroupId,
      'cannot create user defined entity without a usergroup'
    );

    const userDefinedEntityConfig =
      this.entityManager.userDefinedEntityConfigRepository.create({
        ownerUserGroupId: userGroupId
      });

    this.editUserDefinedEntityConfig(userDefinedEntityConfig);
  }

  protected handleEditUserDefinedEntityConfigClick(
    userDefinedEntityConfig: UserDefinedEntityConfig
  ): void {
    this.editUserDefinedEntityConfig(userDefinedEntityConfig);
  }

  private editUserDefinedEntityConfig(
    userDefinedEntityConfig: UserDefinedEntityConfig
  ): void {
    void EditUserDefinedEntityConfigDialog.open({
      userDefinedEntityConfig: userDefinedEntityConfig,
      onDialogClosed: (entity) => {
        this.goToUserDefinedEntityConfig(entity);
      }
    });
  }

  private goToUserDefinedEntityConfig(
    userDefinedEntityConfig: UserDefinedEntityConfig
  ): void {
    void ScrollHelper.autoScrollToListItem(
      '#user-defined-entity-config-' + userDefinedEntityConfig.id,
      this.pagination,
      userDefinedEntityConfig,
      () => this.isAttached
    );
  }
}
