import { computedFrom } from 'aurelia-framework';
import { EndpointResult } from 'common/WebSocketEndpoints/WebSocketEndpointConfigurations';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Subscribable } from '../../classes/SubscribableArray/SubscribableArray';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../SubscriptionManagerService';
import { LastResponsePropertyBinder } from './ThingAndThingGroupNameService';

export class ThingNameHandle implements Subscribable {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly lastResponsePropertyBinder: LastResponsePropertyBinder;
  private readonly thingId: string;

  private thing: Thing | null = null;
  private lastResponse: EndpointResult<
    'entityInfoModule',
    'getThingAndThingGroupNames'
  > | null = null;

  constructor({
    entityManager,
    subscriptionManagerService,
    lastResponsePropertyBinder,
    thingId
  }: {
    entityManager: AppEntityManager;
    subscriptionManagerService: SubscriptionManagerService;
    lastResponsePropertyBinder: LastResponsePropertyBinder;
    thingId: string;
  }) {
    this.entityManager = entityManager;
    this.subscriptionManagerService = subscriptionManagerService;
    this.lastResponsePropertyBinder = lastResponsePropertyBinder;
    this.thingId = thingId;
  }

  public subscribe(): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.subscribeToModelChanges(
      EntityName.Thing,
      this.updateThing.bind(this)
    );
    this.updateThing();

    subscriptionManager.addDisposable(
      this.lastResponsePropertyBinder.registerBinding(
        'value',
        (lastResponse) => {
          this.lastResponse = lastResponse;
        }
      )
    );

    return subscriptionManager.toDisposable();
  }

  @computedFrom('thing.name', 'lastResponse')
  public get name(): string | null {
    if (this.thing) {
      return this.thing.name;
    }

    return this.lastResponse?.thingIdToThingData[this.thingId]?.name ?? null;
  }

  private updateThing(): void {
    this.thing = this.entityManager.thingRepository.getById(this.thingId);
  }
}
