import { autoinject } from 'aurelia-framework';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { computed } from '../../hooks/computed';
import { arrayChanges, expression, model } from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ChecklistProjectNameUtils } from 'common/Checklist/ChecklistProjectNameUtils';
import { ProjectQuestionCategory } from '../../classes/EntityManager/entities/ProjectQuestionCategory/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { Dialogs } from '../../classes/Dialogs';
import { ProjectQuestion } from '../../classes/EntityManager/entities/ProjectQuestion/types';
import { Router } from 'aurelia-router';
import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import { ChecklistManageQuestionSetsDialog } from '../../dialogs/checklist-manage-question-sets-dialog/checklist-manage-question-sets-dialog';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ChecklistExportInspectionDialog } from '../../dialogs/checklist-export-inspection-dialog/checklist-export-inspection-dialog';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { ProjectQuestionUtils } from '../../classes/EntityManager/entities/ProjectQuestion/ProjectQuestionUtils';
import { pipe } from 'rxjs';
import { ArrayUtils, DuplicateBehaviour } from 'common/Utils/ArrayUtils';
import {
  ButtonType,
  GlobalCustomDialog
} from '../../dialogs/global-custom-dialog/global-custom-dialog';
import { I18N } from 'aurelia-i18n';
import { Utils } from 'common/Utils';
import { NullCompareMode, SortUtils } from 'common/Utils/SortUtils';
import { ChecklistProjectQuestionRecommendationWidget } from '../../checklistComponents/checklist-project-question-recommendation-widget/checklist-project-question-recommendation-widget';
import { SocketService } from '../../services/SocketService';
import { ChecklistProjectQuestionNoteWidget } from '../../checklistComponents/checklist-project-question-note-widget/checklist-project-question-note-widget';

@autoinject()
export class ChecklistInspection {
  private projectId: string | null = null;

  @subscribableLifecycle()
  protected thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  /**
   * Physical groups that have been created manually by the user (via
   * {@link handleNewPhysicalAreaClicked}), and do not contain any questions yet.
   * In contrast to the physical groups that contain saved questions, these
   * will not persist.
   */
  protected temporaryPhysicalAreas: Array<string> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly activeEntitiesService: ActiveEntitiesService,
    private readonly permissionsService: PermissionsService,
    private readonly router: Router,
    private readonly i18n: I18N,
    private readonly socketService: SocketService
  ) {
    this.thingPermissionsHandle =
      this.permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Thing,
        context: this as ChecklistInspection,
        expression: 'thing'
      });
  }

  protected activate(params: { project_id: string }): void {
    this.projectId = params.project_id;
    this.activeEntitiesService.setActiveChecklistInspection(
      this.entityManager.projectRepository.getById(this.projectId)
    );

    void this.entityManager.joinedProjectsManager.joinProject(this.projectId);

    // Preemptively update all caches for inspections
    if (this.socketService.isConnected()) {
      void ChecklistProjectQuestionRecommendationWidget.RECOMMENDATIONS_CACHE.update(
        this.socketService.entityInfoModuleEndpoints.getProjectQuestionRecommendations()
      );
      void ChecklistProjectQuestionNoteWidget.NOTE_CACHE.update(
        this.socketService.entityInfoModuleEndpoints.getProjectQuestionNotes()
      );
    }
  }

  protected deactivate(): void {
    this.activeEntitiesService.setActiveChecklistInspection(null);
  }

  protected handleProjectQuestionCategoryClicked(
    projectQuestionCategory: ProjectQuestionCategory,
    physical_area: string | null
  ): void {
    this.router.navigateToRoute('checklist_inspection_category', {
      project_id: this.projectId,
      project_category_id: projectQuestionCategory.id,
      physical_area
    });
  }

  protected handleAddCategoryButtonClicked(physicalArea?: string): void {
    assertNotNullOrUndefined(
      this.project,
      'cannot handleAddCategoryButtonClicked without project'
    );

    void ChecklistManageQuestionSetsDialog.open({
      project: this.project,
      physicalArea
    });
  }

  protected handleNewPhysicalAreaClicked(): void {
    // get a unique name for the new physical area
    const physicalAreaNames = this.physicalAreaGroups.map(
      (e) => e.physicalArea
    );
    let name: string;
    let id = 0;
    do {
      name = `#${++id}`;
      if (!physicalAreaNames.includes(name)) break;
    } while (true);

    this.temporaryPhysicalAreas.push(name);
  }

  protected handlePhysicalAreaNamedClicked(
    event: Event,
    group: PhysicalAreaGroup
  ): void {
    event.preventDefault();

    void GlobalCustomDialog.open({
      titleTk: 'generalPages.checklistInspection.physicalAreaNameDialog.title',
      buttons: [
        {
          textTk: 'general.ok',
          value: 'ok',
          className: 'record-it-button-primary'
        },
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL
        }
      ],
      inputOptions: {
        type: 'text',
        placeholder: this.i18n.tr(
          'generalPages.checklistInspection.physicalAreaNameDialog.placeholder'
        ) as string,
        validator: (value) => {
          if (!value)
            return this.i18n.tr(
              'generalPages.checklistInspection.physicalAreaNameDialog.errors.mustNotBeEmpty'
            ) as string;

          if (this.physicalAreaGroups.some((e) => e.physicalArea === value)) {
            return this.i18n.tr(
              'generalPages.checklistInspection.physicalAreaNameDialog.errors.mustBeUnique'
            ) as string;
          }

          return true;
        }
      }
    }).then((result) => {
      assertNotNullOrUndefined(
        result.inputValue,
        'dialog resolved without an input - is the validator broken?'
      );

      // Depending on where the physical area group come from,
      // we need to update the name in the correct place
      if (group.source.type === 'temporary') {
        // If the group is temporary, update the name directly in the temporaryPhysicalAreas array
        // done with splice to trigger property watchers
        this.temporaryPhysicalAreas.splice(
          group.source.index,
          1,
          result.inputValue
        );
      } else {
        // Otherwise update all projectQuestions with the new name
        for (const projectQuestion of group.projectQuestions) {
          projectQuestion.physicalArea = result.inputValue;
          this.entityManager.projectQuestionRepository.update(projectQuestion);
        }
      }
    });
  }

  protected handleEndInspectionButtonClicked(): void {
    // TODO: Implement this
    void Dialogs.todoDialog();
  }

  protected handleExportInspectionButtonClicked(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleExportInspectionButtonClicked without thing'
    );

    assertNotNullOrUndefined(
      this.project,
      'cannot handleExportInspectionButtonClicked without project'
    );

    const thing = this.thing;

    void ChecklistExportInspectionDialog.open({
      thing: thing,
      project: this.project,
      onExport: () => {
        this.router.navigateToRoute('edit_checklist_object', {
          thing_id: thing.id
        });
      }
    });
  }

  @computed(expression('projectId'), model(EntityName.Project))
  protected get project(): Project | null {
    if (!this.projectId) return null;
    return this.entityManager.projectRepository.getById(this.projectId);
  }

  @computed(expression('project.thing'), model(EntityName.Thing))
  protected get thing(): Thing | null {
    if (!this.project) return null;
    return this.entityManager.thingRepository.getById(this.project.thing);
  }

  @computed(expression('project.name'))
  protected get projectDate(): string {
    if (!this.project || !this.project.name) return '...';
    return ChecklistProjectNameUtils.toDateString(this.project.name);
  }

  @computed(expression('project.createdByUserId'))
  protected get createdBy(): User | null {
    if (!this.project || !this.project.createdByUserId) return null;

    return this.entityManager.userRepository.getById(
      this.project.createdByUserId
    );
  }

  @computed(expression('projectId'), model(EntityName.ProjectQuestionCategory))
  protected get projectQuestionCategories(): Array<ProjectQuestionCategory> {
    if (!this.projectId) return [];
    return this.entityManager.projectQuestionCategoryRepository.getByProjectId(
      this.projectId
    );
  }

  @computed(
    expression('projectQuestionCategories'),
    model(EntityName.ProjectQuestion)
  )
  protected get projectQuestions(): Array<ProjectQuestion> {
    return this.entityManager.projectQuestionRepository.getByProjectQuestionCategoryIds(
      this.projectQuestionCategories.map((e) => e.id)
    );
  }

  @computed(expression('projectQuestions'))
  protected get projectQuestionsGroupedByPhysicalArea(): Array<PhysicalAreaGroup> {
    return pipe(
      (questions: Array<ProjectQuestion>) =>
        Utils.groupValues(questions, (q) => q.physicalArea),
      (questionMap) => [...questionMap.entries()],
      (entries) =>
        entries.map((e) => ({
          physicalArea: e[0],
          projectQuestions: e[1],
          source: { type: 'projectQuestions' as const }
        }))
    )(this.projectQuestions);
  }

  @computed(
    arrayChanges('temporaryPhysicalAreas'),
    expression('projectQuestionsGroupedByPhysicalArea')
  )
  protected get physicalAreaGroups(): Array<PhysicalAreaGroup> {
    const groups = ArrayUtils.unique(
      [
        ...this.temporaryPhysicalAreas.map((area, index) => ({
          physicalArea: area,
          projectQuestions: [],
          source: { type: 'temporary' as const, index }
        })),
        ...this.projectQuestionsGroupedByPhysicalArea
      ],
      (i) => i.physicalArea,
      // Use the last occurence so temporary physical areas are overwritten
      // if a physical area with the same name is available from the project questions
      DuplicateBehaviour.KEEP_LAST
    );
    groups.sort((a, b) =>
      SortUtils.localeCompareFalsyStrings(
        a.physicalArea,
        b.physicalArea,
        NullCompareMode.FalsyToBegin
      )
    );
    return groups;
  }

  @computed(expression('projectQuestions'))
  protected get amountOfAnsweredQuestions(): number {
    return this.projectQuestions.filter((q) =>
      ProjectQuestionUtils.isCompleted(q)
    ).length;
  }

  @computed(expression('projectQuestions'))
  protected get amountOfQuestions(): number {
    return this.projectQuestions.length;
  }

  @computed(
    expression('amountOfAnsweredQuestions'),
    expression('amountOfQuestions')
  )
  protected get progressInPercent(): string {
    if (this.amountOfQuestions === 0)
      return Number(0).toLocaleString(undefined, { style: 'percent' });
    const percent = this.amountOfAnsweredQuestions / this.amountOfQuestions;
    return percent.toLocaleString(undefined, { style: 'percent' });
  }

  protected getQuestionAmountForCategory(
    projectQuestions: typeof this.projectQuestions,
    category: ProjectQuestionCategory
  ): number {
    return projectQuestions.filter(
      (q) => q.projectQuestionCategoryId === category.id
    ).length;
  }

  protected categoryIsFinished(
    projectQuestions: typeof this.projectQuestions,
    category: ProjectQuestionCategory
  ): boolean {
    return (
      projectQuestions.length > 0 &&
      projectQuestions
        .filter((q) => q.projectQuestionCategoryId === category.id)
        .filter((q) => !ProjectQuestionUtils.isCompleted(q)).length === 0
    );
  }
}

export type PhysicalAreaGroup = {
  /**
   * The physical area name.
   *
   * If the physicalArea is `null`, this group represents the default physical area:
   * Its name will not be able to be changed.
   */
  physicalArea: string | null;
  /**
   * Project Questions associated with this physical area.
   */
  projectQuestions: Array<ProjectQuestion>;
  /**
   * The source of this group - either temporary (i.e. calculated from `temporaryPhysicalAreas`),
   * or from the project questions.
   *
   * This is important because the name of an physicalAreaGroup can be updated by the user. In
   * case of a temporary group, the name should be updated in the `temporaryPhysicalAreas` array,
   * otherwise the name should be changed by updating the `physicalArea` property of the project
   * questions.
   */
  source:
    | {
        type: 'temporary';
        index: number;
      }
    | {
        type: 'projectQuestions';
      };
};
