import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { TextBrickTemplate } from '../../classes/EntityManager/entities/TextBrickTemplate/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { configureHooks } from '../../hooks/configureHooks';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { TextBrickSelectionData } from './TextBrickSelectionData';
import { TextBrick } from '../../classes/EntityManager/entities/TextBrick/types';
import { TextBrickWidgetAdapter } from '../../aureliaComponents/text-brick-widget/TextBrickWidgetAdapter/TextBrickWidgetAdapter';

@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class EditTextBrickTemplatesDialog {
  protected dialog: RecordItDialog | null = null;

  private subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected userGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  private options: EditTextBrickTemplatesDialogOptions | null = null;

  protected textBrickTemplates: Array<TextBrickTemplate> = [];

  private readonly selectionData: TextBrickSelectionData;

  public static async open(
    options: EditTextBrickTemplatesDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.userGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.UserGroup,
        entity: null
      });

    this.selectionData = new TextBrickSelectionData({
      entityManager,
      subscriptionManagerService
    });
  }

  // Methods

  private open(options: EditTextBrickTemplatesDialogOptions): void {
    this.options = options;

    this.userGroupPermissionsHandle =
      this.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.UserGroup,
        entity: this.entityManager.userGroupRepository.getRequiredById(
          options.ownerUserGroupId
        )
      });

    this.subscriptionManager.addDisposable(
      this.selectionData.subscribe({ getTextBricks: options.getTextBricks })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.TextBrickTemplate,
      this.updateTextBrickTemplates.bind(this)
    );
    this.updateTextBrickTemplates();

    this.dialog?.open();
  }

  private updateTextBrickTemplates(): void {
    assertNotNullOrUndefined(
      this.options,
      'cannot update text bricks without options'
    );
    this.textBrickTemplates =
      this.entityManager.textBrickTemplateRepository.getByUserGroupIdAndPathWithWildcards(
        this.options.ownerUserGroupId,
        this.options.path
      );
  }

  // Handlers

  protected handleDialogClosed(): void {
    this.textBrickTemplates = [];
    this.subscriptionManager.disposeSubscriptions();
    this.options = null;
    this.dialog?.close();
  }

  protected handleAcceptButtonClicked(): void {
    assertNotNullOrUndefined(
      this.options,
      "can't EditTextBrickTemplatesDialog.handleAcceptButtonClicked without options"
    );
    const { textBricksCreationDatas, textBricksToDelete } =
      this.selectionData.getTextBricksModificationData();

    for (const creationData of textBricksCreationDatas) {
      this.options.createTextBrickFromTemplate(creationData);
    }

    for (const textBrickToDelete of textBricksToDelete) {
      this.entityManager.textBrickRepository.delete(textBrickToDelete);
    }

    this.options.onDialogAccept();
  }

  protected handleAddTextBrickTemplateClicked(): void {
    assertNotNullOrUndefined(
      this.options,
      'cannot create text brick template without options'
    );
    this.entityManager.textBrickTemplateRepository.create({
      ownerUserGroupId: this.options.ownerUserGroupId,
      path: this.options.path
    });
  }
}

export type EditTextBrickTemplatesDialogOptions = {
  path: Array<string>;
  textBrickTemplateIds: Array<string>;
  getTextBricks: () => Array<TextBrick>;
  createTextBrickFromTemplate: ({
    textBrickTemplateId,
    value
  }: {
    value: string;
    textBrickTemplateId: string;
  }) => void;
  ownerUserGroupId: string;
  createAdapterForPermissionsHandle: () => TextBrickWidgetAdapter;
  temporaryGroupName: string | null;
  onDialogAccept: () => void;
};
