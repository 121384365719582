import { computedFrom } from 'aurelia-framework';
import { Project } from '../../../classes/EntityManager/entities/Project/types';
import { ProjectAdapter } from '../EntityAdapter/ProjectAdapter/ProjectAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ProjectPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ProjectAdapter>,
  Project
> {
  @computedFrom('combinedRevision')
  public get canMarkOnThingSections(): boolean {
    return this.checkAdapterSpecificPermissionByName('canMarkOnThingSections');
  }

  @computedFrom('combinedRevision')
  public get canCreateReports(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateReports');
  }

  @computedFrom('combinedRevision')
  public get canCreateUserDefinedEntities(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateUserDefinedEntities'
    );
  }

  @computedFrom('combinedRevision')
  public get canViewValueCalculationResults(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canViewValueCalculationResults'
    );
  }

  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditField');
  }

  @computedFrom('combinedRevision')
  public get canCreateEntries(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateEntries');
  }

  @computedFrom('combinedRevision')
  public get canDragAndDropEntries(): boolean {
    return this.checkAdapterSpecificPermissionByName('canDragAndDropEntries');
  }
}
