import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { processTaskOfferToProcessTaskPositionEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskPosition/processTaskOfferToProcessTaskPositionEntityInfo';
import { ProcessTaskOfferToProcessTaskPosition } from '../../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskPosition/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { ProcessTaskOfferAdapter } from '../ProcessTaskOfferAdapter/ProcessTaskOfferAdapter';

export class ProcessTaskOfferToProcessTaskPositionAdapter
  implements EntityAdapter<ProcessTaskOfferToProcessTaskPosition>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private processTaskOfferAdapter: ProcessTaskOfferAdapter | null = null;

  constructor(options: ProcessTaskOfferToProcessTaskPositionAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.ProcessTaskOffer,
        onNextAdapter: ({ adapter }) => {
          this.processTaskOfferAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.processTaskOfferAdapter != null &&
      this.processTaskOfferAdapter.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(
    processTaskOfferToProcessTaskPosition: ProcessTaskOfferToProcessTaskPosition
  ): boolean {
    return this.canEditProcessTaskOfferToProcessTaskPosition(
      processTaskOfferToProcessTaskPosition
    );
  }

  public canEditField(
    processTaskOfferToProcessTaskPosition: ProcessTaskOfferToProcessTaskPosition
  ): boolean {
    return this.canEditProcessTaskOfferToProcessTaskPosition(
      processTaskOfferToProcessTaskPosition
    );
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskOfferToProcessTaskPosition,
    ProcessTaskOfferToProcessTaskPosition
  > {
    return processTaskOfferToProcessTaskPositionEntityInfo;
  }

  private canEditProcessTaskOfferToProcessTaskPosition(
    processTaskOfferToProcessTaskPosition: ProcessTaskOfferToProcessTaskPosition
  ): boolean {
    if (!this.processTaskOfferAdapter) {
      return false;
    }

    const processTaskOffer =
      this.entityManager.processTaskOfferRepository.getById(
        processTaskOfferToProcessTaskPosition.processTaskOfferId
      );

    if (!processTaskOffer) {
      return false;
    }

    return this.processTaskOfferAdapter.canEditProcessTaskOfferToProcessTaskPositions(
      processTaskOffer
    );
  }
}

export type ProcessTaskOfferToProcessTaskPositionAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
