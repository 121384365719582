export class ErrorUtils {
  private constructor() {}

  public static convertUnknownToError(error: unknown): Error {
    if (error instanceof Error) return error;

    if (
      typeof error === 'string' ||
      typeof error === 'bigint' ||
      typeof error === 'boolean' ||
      typeof error === 'number' ||
      error == null
    )
      return new Error(String(error));

    return new Error(this.serializeErrorToString(error));
  }

  /**
   * Convert the Error into a string so all enumerable properties can also be seen by a user/an admin
   */
  public static serializeErrorToString(error: any): string {
    try {
      return JSON.stringify(error);
    } catch (e) {
      return "Error couldn't be serialized";
    }
  }
}
