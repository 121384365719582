import { EntityInfo } from '@record-it-npm/synchro-common';
import { PictureDtoUtils } from 'common/Types/Entities/Picture/PictureDtoUtils';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { DeviceInfoHelper } from '../../../../classes/DeviceInfoHelper';
import { Picture } from '../../../../classes/EntityManager/entities/Picture/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SocketService } from '../../../SocketService';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { pictureEntityInfo } from '../../../../classes/EntityManager/entities/Picture/pictureEntityInfo';
import { BoundAdaptersContainer } from '../../utils/BoundAdaptersContainer/BoundAdaptersContainer';
import { AppEntityRepository } from '../../../../classes/EntityManager/base/AppEntityRepository';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { PictureDto } from 'common/Types/Entities/Picture/PictureDto';
import { PictureFileExtension } from 'common/Types/Entities/PictureFile/PictureFileDto';
import { PictureFileByActivePictureRevisionService } from '../../../../classes/EntityManager/entities/PictureFile/PictureFileByActivePictureRevisionService';

export class PictureAdapter implements EntityAdapter<Picture> {
  private readonly entityManager: AppEntityManager;
  private readonly pictureFileByActivePictureRevisionService: PictureFileByActivePictureRevisionService;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly socketService: SocketService;

  private readonly parentEntityAdaptersContainer =
    BoundAdaptersContainer.createCreationFunction<EntityAdapterWithCanEditPictures>()(
      [
        EntityName.Defect,
        EntityName.DefectComment,
        EntityName.Entry,
        EntityName.Thing,
        EntityName.Project,
        EntityName.ProjectQuestion,
        EntityName.Property,
        EntityName.ThingSection
      ]
    );

  private isOnline: boolean | null = null;

  private isApp = DeviceInfoHelper.isApp();

  constructor(options: PictureAdapterOptions) {
    this.entityManager = options.entityManager;
    this.pictureFileByActivePictureRevisionService =
      options.pictureFileByActivePictureRevisionService;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.socketService = options.socketService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
        updateBindings();
      })
    );

    subscriptionManager.addDisposable(
      this.parentEntityAdaptersContainer.subscribe({
        bindAdapter,
        updateBindings
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.isOnline != null &&
      this.parentEntityAdaptersContainer.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(picture: Picture): boolean {
    return this.canEditPicture(picture);
  }

  public canEditField(picture: Picture): boolean {
    return this.canEditPicture(picture);
  }

  public canCreatePictureSections(picture: Picture): boolean {
    return this.canEditPicture(picture);
  }

  public canEditPictureFiles(picture: Picture): boolean {
    return this.canEditPicture(picture);
  }

  public canEditPictureRevisions(picture: Picture): boolean {
    return this.canEditPicture(picture);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.Picture,
    Picture
  > {
    return pictureEntityInfo;
  }

  public canSketch(picture: Picture): boolean {
    return (
      this.canEditPicture(picture) &&
      (this.isApp || !!this.isOnline) &&
      !this.isDxfPicture(picture)
    );
  }

  public canResize(picture: Picture): boolean {
    return (
      this.canEditPicture(picture) &&
      (this.isApp || !!this.isOnline) &&
      !this.isDxfPicture(picture)
    );
  }

  public canMark(picture: Picture): boolean {
    return this.canEditPicture(picture);
  }

  private canEditPicture(picture: Picture): boolean {
    const parentEntityInfo = PictureDtoUtils.getParentEntityInfo({
      pictureDto: picture as PictureDto<string, string>
    });

    const parentEntity = (
      this.entityManager.entityRepositoryContainer.getByEntityName(
        parentEntityInfo.entityName
      ) as AppEntityRepository<any>
    ).getById(parentEntityInfo.id);

    if (!parentEntity) {
      return false;
    }

    const adapter =
      this.parentEntityAdaptersContainer.getAdapterForAnyEntityName(
        parentEntityInfo.entityName
      );

    if (!adapter) {
      return false;
    }

    return adapter.canEditPictures(parentEntity);
  }

  private isDxfPicture(picture: Picture): boolean {
    const originalPictureFile =
      this.pictureFileByActivePictureRevisionService.getOriginalPictureFileByPictureId(
        picture.id
      );

    return originalPictureFile?.file_extension === PictureFileExtension.DXF;
  }
}

export type PictureAdapterOptions = {
  entityManager: AppEntityManager;
  pictureFileByActivePictureRevisionService: PictureFileByActivePictureRevisionService;
  subscriptionManagerService: SubscriptionManagerService;
  socketService: SocketService;
};

type EntityAdapterWithCanEditPictures = EntityAdapter<any> & {
  canEditPictures: (entity: any) => boolean;
};
