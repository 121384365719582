import { autoinject, bindable } from 'aurelia-framework';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { Utils } from '../../classes/Utils/Utils';
import {
  EntryFilterFilterer,
  FilterMode,
  ShowMode
} from './EntryFilterFilterer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  EntriesByParentEntryIdComputer,
  EntriesByParentEntryIdHandle
} from '../../computedValues/computers/EntriesByParentEntryIdComputer/EntriesByParentEntryIdComputer';

@autoinject()
export class EntryFilter {
  @bindable public entries: Array<Entry> = [];

  /**
   * readonly!!
   */
  @bindable public filteredEntries: Array<Entry> = [];

  /**
   * For some reason aurelia won't propagate our initial to our parent view if we set it to the correct value right here.
   * Thats why the initial value is set in the constructor
   */
  @bindable public filterMode: FilterMode | null = null;

  /**
   * For some reason aurelia won't propagate our initial to our parent view if we set it to the correct value right here.
   * Thats why the initial value is set in the constructor
   */
  @bindable public showMode: ShowMode | null = null;

  @bindable public filterString: string | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private entriesByParentEntryIdHandle: EntriesByParentEntryIdHandle | null =
    null;

  protected showModeFilterOptions: Array<ShowModeFilterOption> = [];

  private rateLimitedUpdateFilteredEntries = Utils.rateLimitFunction(
    this.updateFilteredEntries.bind(this),
    0
  );

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.showMode = ShowMode.ALL;
    this.filterMode = FilterMode.PICTURE;
  }

  protected attached(): void {
    this.updateShowModeFilterOptions();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: EntriesByParentEntryIdComputer,
        computeData: {},
        callback: (entriesByParentEntryIdHandle) => {
          this.entriesByParentEntryIdHandle = entriesByParentEntryIdHandle;
          this.rateLimitedUpdateFilteredEntries();
        }
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected entriesChanged(): void {
    this.rateLimitedUpdateFilteredEntries();
  }

  protected filterModeChanged(): void {
    this.updateShowModeFilterOptions();
  }

  protected handleShowModeChanged(): void {
    this.rateLimitedUpdateFilteredEntries();
  }

  protected filterStringChanged(): void {
    this.rateLimitedUpdateFilteredEntries();
  }

  private updateFilteredEntries(): void {
    if (this.entriesByParentEntryIdHandle) {
      const showMode = this.showMode || ShowMode.ALL;
      const filterMode = this.filterMode || FilterMode.PICTURE;
      const filterer = new EntryFilterFilterer(
        this.entityManager,
        showMode,
        filterMode,
        this.filterString || ''
      );

      this.filteredEntries = filterer.filterEntries({
        entries: this.entries,
        entriesByParentEntryIdHandle: this.entriesByParentEntryIdHandle
      });
    } else {
      this.filteredEntries = [];
    }
  }

  private updateShowModeFilterOptions(): void {
    const showModeFilterOptions = [
      {
        labelTk: 'filterComponents.entryFilter.showAllEntries',
        value: ShowMode.ALL
      }
    ];

    switch (this.filterMode) {
      case FilterMode.STRUCTURE_TEMPLATE_ENTRY_ID:
        showModeFilterOptions.push(
          {
            labelTk:
              'filterComponents.entryFilter.showNonEmptyEntries_structureProject',
            value: ShowMode.NON_EMPTY
          },
          {
            labelTk:
              'filterComponents.entryFilter.showEmptyEntries_structureProject',
            value: ShowMode.EMPTY
          },
          {
            labelTk: 'filterComponents.entryFilter.showOnlyEdited',
            value: ShowMode.EDITED
          },
          {
            labelTk: 'filterComponents.entryFilter.showOnlyEditedButEmpty',
            value: ShowMode.EDITED_BUT_EMPTY
          }
        );
        break;

      default:
        showModeFilterOptions.push(
          {
            labelTk: 'filterComponents.entryFilter.showNonEmptyEntries',
            value: ShowMode.NON_EMPTY
          },
          {
            labelTk: 'filterComponents.entryFilter.showEmptyEntries',
            value: ShowMode.EMPTY
          }
        );
    }

    this.showModeFilterOptions = showModeFilterOptions;
  }
}

type ShowModeFilterOption = {
  labelTk: string;
  value: ShowMode;
};
