import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { processTaskInvoiceToProcessTaskDeviceEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTaskDevice/processTaskInvoiceToProcessTaskDeviceEntityInfo';
import { ProcessTaskInvoiceToProcessTaskDevice } from '../../../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTaskDevice/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { ProcessTaskInvoiceAdapter } from '../ProcessTaskInvoiceAdapter/ProcessTaskInvoiceAdapter';

export class ProcessTaskInvoiceToProcessTaskDeviceAdapter
  implements EntityAdapter<ProcessTaskInvoiceToProcessTaskDevice>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private processTaskInvoiceAdapter: ProcessTaskInvoiceAdapter | null = null;

  constructor(options: ProcessTaskInvoiceToProcessTaskDeviceAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.ProcessTaskInvoice,
        onNextAdapter: ({ adapter }) => {
          this.processTaskInvoiceAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.processTaskInvoiceAdapter != null &&
      this.processTaskInvoiceAdapter.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(
    processTaskInvoiceToProcessTaskDevice: ProcessTaskInvoiceToProcessTaskDevice
  ): boolean {
    return this.canEditProcessTaskInvoiceToProcessTaskDevice(
      processTaskInvoiceToProcessTaskDevice
    );
  }

  public canEditField(
    processTaskInvoiceToProcessTaskDevice: ProcessTaskInvoiceToProcessTaskDevice
  ): boolean {
    return this.canEditProcessTaskInvoiceToProcessTaskDevice(
      processTaskInvoiceToProcessTaskDevice
    );
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskInvoiceToProcessTaskDevice,
    ProcessTaskInvoiceToProcessTaskDevice
  > {
    return processTaskInvoiceToProcessTaskDeviceEntityInfo;
  }

  private canEditProcessTaskInvoiceToProcessTaskDevice(
    processTaskInvoiceToProcessTaskDevice: ProcessTaskInvoiceToProcessTaskDevice
  ): boolean {
    if (!this.processTaskInvoiceAdapter) {
      return false;
    }

    const processTaskInvoice =
      this.entityManager.processTaskInvoiceRepository.getById(
        processTaskInvoiceToProcessTaskDevice.processTaskInvoiceId
      );

    if (!processTaskInvoice) {
      return false;
    }

    return this.processTaskInvoiceAdapter.canEditProcessTaskInvoiceToProcessTaskDevices(
      processTaskInvoice
    );
  }
}

export type ProcessTaskInvoiceToProcessTaskDeviceAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
