import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from '../../../../common/src/DateUtils';
import {
  ExpirationDateButtonConfig,
  ProcessConfigurationOfferConfiguration
} from '../../../../common/src/Types/ProcessConfigurationOfferConfiguration';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';

import { ProcessTaskOffer } from '../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { ProcessConfigurationFromProcessTaskGroupIdComputer } from '../../computedValues/computers/ProcessConfigurationFromProcessTaskGroupIdComputer';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class ProcessTaskOfferExpirationDateInput {
  @bindable()
  public processTaskOffer: ProcessTaskOffer | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTaskOfferPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskOffer];

  protected buttonConfigs: Array<ExpirationDateButtonConfig> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskOfferPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskOffer,
        context: this as ProcessTaskOfferExpirationDateInput,
        propertyName: 'processTaskOffer'
      });
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () =>
          this.processTaskOffer
            ? {
                processTaskGroupId:
                  this.processTaskOffer.ownerProcessTaskGroupId
              }
            : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTaskOffer.ownerProcessTaskGroupId',
            updateSubscription
          );
        },
        callback: (processConfiguration) =>
          this.updateButtonConfigs(processConfiguration),
        onNoComputeData: () => this.updateButtonConfigs(null)
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateButtonConfigs(
    processConfiguration: ProcessConfiguration | null
  ): void {
    if (processConfiguration) {
      const config = processConfiguration.offerConfigurationJson
        ? (JSON.parse(
            processConfiguration.offerConfigurationJson
          ) as ProcessConfigurationOfferConfiguration)
        : null;
      this.buttonConfigs = config?.expirationDateButtons ?? [];
    } else {
      this.buttonConfigs = [];
    }
  }

  protected handleOfferChanged(): void {
    assertNotNullOrUndefined(
      this.processTaskOffer,
      "can't ProcessTaskOfferExpirationDateInput.handleOfferChanged without processTaskOffer"
    );
    this.entityManager.processTaskOfferRepository.update(this.processTaskOffer);
  }

  protected handleSetDateButtonClick(offset: number): void {
    assertNotNullOrUndefined(
      this.processTaskOffer,
      "can't ProcessTaskOfferExpirationDateInput.handleSetDateButtonClick without processTaskOffer"
    );

    const currentExpirationDateWithoutTimeInfo = this.processTaskOffer
      .expirationDate
      ? DateUtils.removeTimeInfoFromDate(
          new Date(this.processTaskOffer.expirationDate)
        )
      : null;
    const newExpirationDate = new Date(Date.now() + offset);
    const newExpirationDateWithoutTimeInfo = DateUtils.removeTimeInfoFromDate(
      new Date(newExpirationDate)
    );

    if (
      !currentExpirationDateWithoutTimeInfo ||
      currentExpirationDateWithoutTimeInfo.getTime() !==
        newExpirationDateWithoutTimeInfo.getTime()
    ) {
      this.processTaskOffer.expirationDate = newExpirationDate.toISOString();
      this.entityManager.processTaskOfferRepository.update(
        this.processTaskOffer
      );
    }
  }

  protected getButtonStyle(
    config: ExpirationDateButtonConfig
  ): Record<string, string> {
    if (config.color) {
      return { backgroundColor: config.color };
    }

    return {};
  }
}
