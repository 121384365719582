import {
  ManagePropertyDefinitionsBaseWidgetAdapter,
  ManagePropertyDefinitionsBaseWidgetAdapterSubscribeOptions
} from './ManagePropertyDefinitionsBaseWidgetAdapter';
import {
  ManagePropertyDefinitionsFromProviderWidgetAdapter,
  ManagePropertyDefinitionsFromProviderWidgetAdapterSubscribeOptions
} from './ManagePropertyDefinitionsFromProviderWidgetAdapter';

export type ManagePropertyDefinitionsWidgetAdapter<
  TPropertyDefinition extends { order: number | null },
  TPropertyProviderDefinition = never
> =
  | ManagePropertyDefinitionsBaseWidgetAdapter<TPropertyDefinition>
  | ManagePropertyDefinitionsFromProviderWidgetAdapter<
      TPropertyDefinition,
      TPropertyProviderDefinition
    >;

export type ManagePropertyDefinitionsWidgetAdapterSubscribeOptions<
  TPropertyDefinition,
  TPropertyProviderDefinition = never
> = ManagePropertyDefinitionsBaseWidgetAdapterSubscribeOptions<TPropertyDefinition> &
  ManagePropertyDefinitionsFromProviderWidgetAdapterSubscribeOptions<
    TPropertyDefinition,
    TPropertyProviderDefinition
  >;

export function isFromProviderAdapter<
  TPropertyDefinition extends { order: number | null },
  TPropertyProviderDefinition = never
>(
  adapter: ManagePropertyDefinitionsWidgetAdapter<
    TPropertyDefinition,
    TPropertyProviderDefinition
  >
): adapter is ManagePropertyDefinitionsFromProviderWidgetAdapter<
  TPropertyDefinition,
  TPropertyProviderDefinition
> {
  return !!(adapter as any)['createPropertyDefinitionsFromProvider'];
}
