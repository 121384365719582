import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { EntryToPerson } from './types';

export class EntryToPersonRepository extends AppEntityRepository<EntityName.EntryToPerson> {
  public getMainContactOrFallbackForEntryId(
    entryId: string
  ): EntryToPerson | null {
    const entryToPersons = this.getByEntryId(entryId);
    const mainEntryToPerson = entryToPersons.find((ttp) => ttp.mainContact);
    return mainEntryToPerson ? mainEntryToPerson : (entryToPersons[0] ?? null);
  }

  public deleteByEntryId(entryId: string): void {
    for (const entryToPerson of this.getByEntryId(entryId)) {
      this.delete(entryToPerson);
    }
  }

  public getByEntryId(entryId: string): Array<EntryToPerson> {
    return this.getAll().filter(
      (entryToPerson) => entryToPerson.entryId === entryId
    );
  }

  public getFirstByEntryAndPersonId({
    entryId,
    personId
  }: {
    entryId: string;
    personId: string;
  }): EntryToPerson | null {
    return (
      this.getAll().find(
        (entryToPerson) =>
          entryToPerson.entryId === entryId &&
          entryToPerson.personId === personId
      ) ?? null
    );
  }
}
