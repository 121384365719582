import { autoinject, bindable } from 'aurelia-framework';

import { EntityGroupUtils } from 'common/EntityGrouper/EntityGroupUtils';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { ComputedValueService } from '../../../computedValues/ComputedValueService';
import {
  ProcessTaskDeviceGroups,
  ProcessTaskDeviceGroupsForProcessTaskIdComputer
} from '../../../computedValues/computers/ProcessTaskDeviceGroupsForProcessTaskIdComputer';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskOffer } from '../../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { ProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { ProcessTaskOfferToProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskDevice/types';
import { ProcessTaskOfferRelationsWidgetDeviceListItem } from '../process-task-offer-relations-widget-device-list-item/process-task-offer-relations-widget-device-list-item';

/**
 * this is just a sub-component of the ProcessTaskOfferTasksAndPositionsWidget and also uses its styling
 */
@autoinject()
export class ProcessTaskOfferRelationsWidgetDevices {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskOffer: ProcessTaskOffer | null = null;

  @bindable()
  public enabled: boolean = false;

  @bindable()
  public allDevicesSelected: boolean = false;

  /**
   * is null when devices are not loaded
   * read only
   */
  @bindable()
  public processTaskDeviceCount: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  protected processTaskDevices: Array<ProcessTaskDevice> = [];
  protected processTaskOfferToProcessTaskDevices: Array<ProcessTaskOfferToProcessTaskDevice> =
    [];

  protected groupedDevices: ProcessTaskDeviceGroups = [];

  private isAttached: boolean = false;

  protected deviceListItems: Set<ProcessTaskOfferRelationsWidgetDeviceListItem> =
    new Set();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public selectAll(): void {
    for (const deviceListItem of this.deviceListItems.values()) {
      deviceListItem.setChecked(true);
    }

    this.updateProcessTaskOfferToProcessTaskDevices();
  }

  public deselectAll(): void {
    for (const deviceListItem of this.deviceListItems.values()) {
      deviceListItem.setChecked(false);
    }

    this.updateProcessTaskOfferToProcessTaskDevices();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTaskDevice,
      this.updateProcessTaskOfferToProcessTaskDevices.bind(this)
    );
    this.updateProcessTaskOfferToProcessTaskDevices();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessTaskDeviceGroupsForProcessTaskIdComputer,
        callback: (groupedDevices) => {
          this.groupedDevices = groupedDevices;
          this.processTaskDevices =
            EntityGroupUtils.getAllEntities(groupedDevices);
          this.processTaskDeviceCount = this.processTaskDevices.length;
          this.updateAllDevicesSelected();
        },
        createComputeData: () => {
          return this.processTask
            ? { processTaskId: this.processTask.id }
            : null;
        },
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTask.id',
            updateSubscription
          );
        }
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProcessTaskOfferToProcessTaskDevices();
    }
  }

  protected processTaskInvoiceChanged(): void {
    if (this.isAttached) {
      this.updateProcessTaskOfferToProcessTaskDevices();
    }
  }

  private updateProcessTaskOfferToProcessTaskDevices(): void {
    if (this.processTask && this.processTaskOffer) {
      this.processTaskOfferToProcessTaskDevices =
        this.entityManager.processTaskOfferToProcessTaskDeviceRepository.getByProcessTaskOfferIdAndProcessTaskId(
          this.processTaskOffer.id,
          this.processTask.id
        );
    } else {
      this.processTaskOfferToProcessTaskDevices = [];
    }

    this.updateAllDevicesSelected();
  }

  private updateAllDevicesSelected(): void {
    this.allDevicesSelected = this.processTaskDevices.every((device) => {
      return this.processTaskOfferToProcessTaskDevices.find(
        (otd) => otd.processTaskDeviceId === device.id
      );
    });
  }
}
