import { Disposable } from 'aurelia-binding';

import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';

import { Defect } from '../../../classes/EntityManager/entities/Defect/types';
import {
  TextBrickWidgetAdapterSubscribeOptions,
  TextBrickWidgetCommonAdapterOptions
} from './TextBrickWidgetAdapter';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { TextBrickTemplate } from '../../../classes/EntityManager/entities/TextBrickTemplate/types';
import { TextBrick } from '../../../classes/EntityManager/entities/TextBrick/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { EditTextBrickTemplatesDialog } from '../../../dialogs/edit-text-brick-templates-dialog/edit-text-brick-templates-dialog';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

export class DefectTextBrickWidgetAdapter {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly permissionsService: PermissionsService;

  private defect: Defect | null = null;
  private defectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Defect];

  private selectedTextBricks: Array<TextBrick> = [];
  private remainingTextBrickTemplates: Array<TextBrickTemplate> = [];

  private subscribeOptions: TextBrickWidgetAdapterSubscribeOptions | null =
    null;

  constructor(options: DefectTextBrickWidgetAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.permissionsService = options.permissionsService;

    this.defect = options.defect;
    this.defectPermissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Defect,
        entity: this.defect
      });
  }

  public subscribe(
    options: TextBrickWidgetAdapterSubscribeOptions
  ): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();
    this.subscribeOptions = options;

    subscriptionManager.addDisposable(this.defectPermissionsHandle.subscribe());
    subscriptionManager.subscribeToExpression(
      this.defectPermissionsHandle,
      'canCreateTextBricks',
      () => {
        options.setCanCreateTextBricks(
          this.defectPermissionsHandle.canCreateTextBricks
        );
      }
    );
    options.setCanCreateTextBricks(
      this.defectPermissionsHandle.canCreateTextBricks
    );

    subscriptionManager.subscribeToModelChanges(
      EntityName.TextBrick,
      this.updateTextBricks.bind(this)
    );
    this.updateTextBricks();

    subscriptionManager.subscribeToModelChanges(
      EntityName.TextBrickTemplate,
      this.updateRemainingTextBrickTemplates.bind(this)
    );
    this.updateRemainingTextBrickTemplates();

    return {
      dispose: () => {
        this.subscribeOptions = null;
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  private updateTextBricks(): void {
    if (this.defect) {
      this.selectedTextBricks =
        this.entityManager.textBrickRepository.getByDefectId(this.defect.id);
    } else {
      this.selectedTextBricks = [];
    }
    this.updateRemainingTextBrickTemplates();
    assertNotNullOrUndefined(
      this.subscribeOptions,
      'cannot updateTextBricks without subscribeOptions'
    );
    this.subscribeOptions.setSelectedTextBricks(this.selectedTextBricks);
  }

  private updateRemainingTextBrickTemplates(): void {
    if (this.defect) {
      const availableTextBricks =
        this.entityManager.textBrickTemplateRepository.getByUserGroupIdAndPathWithWildcards(
          this.defect.ownerUserGroupId,
          []
        );
      this.remainingTextBrickTemplates = availableTextBricks.filter(
        (aTB) =>
          !this.selectedTextBricks.find(
            (sTB) => sTB.textBrickTemplateId === aTB.id
          )
      );
    } else {
      this.remainingTextBrickTemplates = [];
    }
    assertNotNullOrUndefined(
      this.subscribeOptions,
      'cannot updateRemainingTextBrickTemplates without subscribeOptions'
    );
    this.subscribeOptions.setRemainingTextBrickTemplates(
      this.remainingTextBrickTemplates
    );
  }

  public createNewTextBrickFromTemplate(
    textBrickTemplate: TextBrickTemplate
  ): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot add text brick without a defect'
    );

    this.entityManager.textBrickRepository.create({
      ownerDefectId: this.defect.id,
      textBrickTemplateId: textBrickTemplate.id,
      ownerUserGroupId: this.defect.ownerUserGroupId,
      value: textBrickTemplate.value,
      temporaryGroupName: null,
      shadowEntity: false
    });

    this.updateTextBricks();
  }

  public openEditTextBrickTemplateDialog(): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot open EditTextBricksDialog without defect'
    );

    const textBrickTemplateIds = [];
    for (const textBrick of this.selectedTextBricks) {
      if (textBrick.textBrickTemplateId)
        textBrickTemplateIds.push(textBrick.textBrickTemplateId);
    }

    void EditTextBrickTemplatesDialog.open({
      path: [],
      textBrickTemplateIds: textBrickTemplateIds,
      createTextBrickFromTemplate: ({ value, textBrickTemplateId }) => {
        assertNotNullOrUndefined(
          this.defect,
          'cannot open EditTextBricksDialog without defect'
        );
        this.entityManager.textBrickRepository.create({
          value,
          textBrickTemplateId,
          ownerDefectId: this.defect.id,
          ownerUserGroupId: this.defect.ownerUserGroupId,
          temporaryGroupName: null,
          shadowEntity: false
        });
      },
      ownerUserGroupId: this.defect.ownerUserGroupId,
      getTextBricks: () => {
        assertNotNullOrUndefined(
          this.defect,
          'cannot open EditTextBricksDialog without defect'
        );
        return this.entityManager.textBrickRepository.getByDefectId(
          this.defect.id
        );
      },
      createAdapterForPermissionsHandle: () => {
        assertNotNullOrUndefined(
          this.defect,
          'cannot open EditTextBricksDialog without defect'
        );
        return new DefectTextBrickWidgetAdapter({
          defect: this.defect,
          permissionsService: this.permissionsService,
          entityManager: this.entityManager,
          subscriptionManagerService: this.subscriptionManagerService
        });
      },
      temporaryGroupName: null,
      onDialogAccept: () => {
        this.updateTextBricks();
      }
    });
  }
}

type DefectTextBrickWidgetAdapterOptions =
  TextBrickWidgetCommonAdapterOptions & {
    defect: Defect;
  };
