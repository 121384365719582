import { bindable, autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskLogEntry } from '../../classes/EntityManager/entities/ProcessTaskLogEntry/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';

@autoinject()
export class ProcessTaskLogEntriesViewer {
  @bindable()
  public processTask: ProcessTask | null = null;

  constructor(private readonly entityManager: AppEntityManager) {}

  @computed(expression('processTask.id'), model(EntityName.ProcessTaskLogEntry))
  protected get availableLogEntries(): Array<ProcessTaskLogEntry> {
    if (!this.processTask) {
      return [];
    }

    const entries = this.entityManager.processTaskLogEntryRepository
      .getByProcessTaskId(this.processTask.id)
      .slice();
    entries.sort((a, b) => b.date.localeCompare(a.date));
    return entries;
  }
}
