import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  PositionPrices,
  ProcessTaskPositionCalculator
} from 'common/Operations/ProcessTaskPositionCalculator';
import { Dialogs } from '../../../classes/Dialogs';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationCategory } from '../../../classes/EntityManager/entities/ProcessConfigurationCategory/types';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import {
  PositionCategoryGroup,
  ProcessTaskPositionUtils
} from '../../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { ProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskPositionDetailEntry } from '../../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { InstancePreserver } from '../../../classes/InstancePreserver/InstancePreserver';
import { ScrollHelper } from '../../../classes/ScrollHelper';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { ComputedValueService } from '../../../computedValues/ComputedValueService';
import { CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer } from '../../../computedValues/computers/CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer';
import {
  ProcessTaskPositionDetailEntriesByProcessTaskPositionId,
  ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer
} from '../../../computedValues/computers/ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { CustomCheckboxCheckedChangedEvent } from '../../../inputComponents/custom-checkbox/custom-checkbox';
import { EntitiesPermissionChecker } from '../../../services/PermissionsService/EntitiesPermissionChecker/EntitiesPermissionChecker';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { ProcessTaskLoggingService } from '../../../services/ProcessTaskLoggingService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { CreateProcessTaskPositionDialog } from '../../create-process-task-position-dialog/create-process-task-position-dialog';
import { EditProcessTaskPositionDialog } from '../../edit-process-task-position-dialog/edit-process-task-position-dialog';
import { ProcessTaskPositionListItem } from '../../process-task-position-list-item/process-task-position-list-item';
import { DragAndDropViewModelInfo } from '../../process-task-position-list/process-task-position-list';

/**
 * @event {ProcessTaskPositionCreatedEvent} process-task-position-created
 * @event {CreateAppointmentClickedEvent} create-appointment-clicked
 */
@autoinject()
export class ProcessTaskPositionsWidgetPositionsList {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskPositions: Array<ProcessTaskPosition> = [];

  @bindable()
  public selectedPositions: Array<ProcessTaskPosition> = [];

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  @subscribableLifecycle()
  protected readonly processTaskPositionsPermissionChecker: EntitiesPermissionChecker<EntityName.ProcessTaskPosition>;

  protected selectedPositionsCategoryId: string | null = null;
  private categoryIdsWhereAllPositionsAreSelected: Array<string | null> = [];
  private categorizedPositions: Array<PositionCategoryGroup> = [];
  private isAttached: boolean = false;

  private calculator: ProcessTaskPositionCalculator | null = null;
  protected positionPrices: Array<
    PositionPrices<ProcessTaskPosition, ProcessTaskPositionDetailEntry>
  > = [];

  private detailEntriesByPositionId: ProcessTaskPositionDetailEntriesByProcessTaskPositionId =
    new Map();

  protected readonly positionCategoryGroupIdGetter = (
    p: PositionCategoryGroup
  ): string | null => p.categoryId;

  protected readonly positionCategoryItemCountGetter = (
    items: Array<PositionCategoryGroup>
  ): number => items.reduce((sum, group) => sum + group.positions.length, 0);

  protected dragAndDropViewModelInfo: DragAndDropViewModelInfo<ProcessTaskPositionListItem> =
    {
      viewModelConstructor: ProcessTaskPositionListItem,
      getPositionFromViewModel: (viewModel) => {
        assertNotNullOrUndefined(
          viewModel.processTaskPosition,
          'dragged a position-list-item without a process-task-position'
        );
        return viewModel.processTaskPosition;
      }
    };

  constructor(
    private readonly domElement: Element,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTask,
        context: this as ProcessTaskPositionsWidgetPositionsList,
        propertyName: 'processTask'
      });

    this.processTaskPositionsPermissionChecker =
      permissionsService.getEntitiesPermissionChecker({
        entityName: EntityName.ProcessTaskPosition
      });
  }

  protected attached(): void {
    this.isAttached = true;
    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'selectedPositions',
      () => {
        this.updateSelectedPositionsCategoryId();
        this.updateCategoryIdsWhereAllPositionsAreSelected();
      }
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () =>
          this.processTaskGroup
            ? { processTaskGroupId: this.processTaskGroup.id }
            : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTaskGroup.id',
            updateSubscription
          );
        },
        callback: (config) => {
          this.calculator = new ProcessTaskPositionCalculator(config);
          this.updatePositionPrices();
        },
        onNoComputeData: () => {
          this.calculator = null;
        }
      }),
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer,
        createComputeData: () =>
          this.processTask ? { ownerProcessTaskId: this.processTask.id } : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTask.id',
            updateSubscription
          );
        },
        callback: (result) => {
          this.detailEntriesByPositionId = result;
          this.updatePositionPrices();
        }
      })
    );

    this.updateCategorizedPositions();
    this.updateCategoryIdsWhereAllPositionsAreSelected();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskPositionsChanged(): void {
    if (this.isAttached) {
      this.updateCategorizedPositions();
      this.updateCategoryIdsWhereAllPositionsAreSelected();
      this.updatePositionPrices();
    }
  }

  private updatePositionPrices(): void {
    if (this.calculator) {
      const positionInfos =
        ProcessTaskPositionUtils.createPositionInfosForCalculation(
          this.processTaskPositions,
          this.detailEntriesByPositionId
        );
      this.positionPrices =
        this.calculator.calculatePricesOfPositions(positionInfos);
    } else {
      this.positionPrices = [];
    }
  }

  private updateCategorizedPositions(): void {
    this.categorizedPositions = InstancePreserver.createNewArray({
      originalArray: this.categorizedPositions,
      newArray: ProcessTaskPositionUtils.categorizePositions(
        this.processTaskPositions
      ),
      getTrackingValue: (item) => item.categoryId
    });
  }

  protected handleAddPositionClick(
    category: ProcessConfigurationCategory | null
  ): void {
    if (!this.processTask) {
      return;
    }

    void CreateProcessTaskPositionDialog.open({
      processTask: this.processTask,
      processConfigurationCategory: category,
      onDialogClosed: (createdPosition) => {
        if (createdPosition) {
          DomEventHelper.fireEvent<ProcessTaskPositionCreatedEvent>(
            this.domElement,
            {
              name: 'process-task-position-created',
              detail: null
            }
          );
          this.goToPosition(createdPosition);
        }
      }
    });
  }

  protected handleEditButtonClicked(position: ProcessTaskPosition): void {
    void EditProcessTaskPositionDialog.open({
      position: position,
      onDialogClosed: () => {
        this.goToPosition(position);
      }
    });
  }

  protected async handleDeleteButtonClicked(
    position: ProcessTaskPosition
  ): Promise<void> {
    await Dialogs.deleteDialogTk(
      'operations.processTaskPositionsWidget.deleteConfirmation'
    );
    this.entityManager.processTaskPositionRepository.delete(position);
    void this.processTaskLoggingService.logProcessTaskSubEntityDeleted({
      entityName: EntityName.ProcessTaskPosition,
      entity: position,
      displayNameAtLogTime: position.name
    });
  }

  protected handlePositionCheckedChanged(
    position: ProcessTaskPosition,
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    const index = this.selectedPositions.indexOf(position);

    if (event.detail.checked) {
      if (index === -1) {
        this.selectedPositions.push(position);
      }
    } else {
      if (index >= 0) {
        this.selectedPositions.splice(index, 1);
      }
    }

    this.updateSelectedPositionsCategoryId();
    this.updateCategoryIdsWhereAllPositionsAreSelected();
  }

  protected handleSelectAllCheckedChanged(
    category: ProcessConfigurationCategory | null
  ): void {
    const categoryId = category ? category.id : null;

    const selectedPositions: Array<ProcessTaskPosition> = [];

    if (
      this.categoryIdsWhereAllPositionsAreSelected.indexOf(categoryId) === -1
    ) {
      for (const position of this.processTaskPositions) {
        if (position.processConfigurationCategoryId === categoryId) {
          selectedPositions.push(position);
        }
      }
    }

    this.selectedPositions = selectedPositions;
  }

  protected handleCreateAppointmentClick(): void {
    DomEventHelper.fireEvent<CreateAppointmentClickedEvent>(this.domElement, {
      name: 'create-appointment-clicked',
      detail: null
    });
  }

  private updateCategoryIdsWhereAllPositionsAreSelected(): void {
    const map = this.getCategoryPositionDataMap();

    const ids: Array<string | null> = [];

    for (const [categoryId, data] of map) {
      if (data.selectedPositionsCount === data.positionsCount) {
        ids.push(categoryId);
      }
    }

    this.categoryIdsWhereAllPositionsAreSelected = ids;
  }

  private updateSelectedPositionsCategoryId(): void {
    const position = this.selectedPositions.find(
      (p) => !!p.processConfigurationCategoryId
    );
    this.selectedPositionsCategoryId = position
      ? position.processConfigurationCategoryId
      : null;
  }

  private getCategoryPositionDataMap(): Map<
    string | null,
    { positionsCount: number; selectedPositionsCount: number }
  > {
    const categoryPositionData: Map<
      string | null,
      { positionsCount: number; selectedPositionsCount: number }
    > = new Map();

    for (const position of this.processTaskPositions) {
      let entry = categoryPositionData.get(
        position.processConfigurationCategoryId
      );
      if (!entry) {
        entry = {
          positionsCount: 0,
          selectedPositionsCount: 0
        };

        categoryPositionData.set(
          position.processConfigurationCategoryId,
          entry
        );
      }

      entry.positionsCount++;
      if (this.selectedPositions.indexOf(position) >= 0) {
        entry.selectedPositionsCount++;
      }
    }

    return categoryPositionData;
  }

  private goToPosition(position: ProcessTaskPosition): void {
    void ScrollHelper.autoScrollToListItem(
      '#' + this.getPositionListItemId(position.id),
      null,
      position,
      () => this.isAttached
    );
  }

  private getPositionListItemId(positionId: string): string {
    return 'process-task-positions-widget--position-' + positionId;
  }

  @computedFrom(
    'processTaskPositionsPermissionChecker.revision',
    'processTaskPositions'
  )
  protected get canDragPositionListItems(): boolean {
    return this.processTaskPositionsPermissionChecker.allEntitiesHavePermission(
      {
        entities: this.processTaskPositions,
        checkPermission: ({ adapter, entity }) => {
          return adapter.canEditField(entity); // order
        }
      }
    );
  }
}

export type ProcessTaskPositionCreatedEvent = NamedCustomEvent<
  'process-task-position-created',
  null
>;
export type CreateAppointmentClickedEvent = NamedCustomEvent<
  'create-appointment-clicked',
  null
>;
