import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { propertyToProcessTaskPositionEntityInfo } from '../../../../classes/EntityManager/entities/PropertyToProcessTaskPosition/propertyToProcessTaskPositionEntityInfo';
import { PropertyToProcessTaskPosition } from '../../../../classes/EntityManager/entities/PropertyToProcessTaskPosition/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { PropertyAdapter } from '../PropertyAdapter/PropertyAdapter';

export class PropertyToProcessTaskPositionAdapter
  implements EntityAdapter<PropertyToProcessTaskPosition>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private propertyAdapter: PropertyAdapter | null = null;

  constructor(options: PropertyToProcessTaskPositionAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.Property,
        onNextAdapter: ({ adapter }) => {
          this.propertyAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.propertyAdapter != null &&
      this.propertyAdapter.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(
    propertyToProcessTaskPosition: PropertyToProcessTaskPosition
  ): boolean {
    return this.canEditPropertyToProcessTaskPosition(
      propertyToProcessTaskPosition
    );
  }

  public canEditField(
    propertyToProcessTaskPosition: PropertyToProcessTaskPosition
  ): boolean {
    return this.canEditPropertyToProcessTaskPosition(
      propertyToProcessTaskPosition
    );
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.PropertyToProcessTaskPosition,
    PropertyToProcessTaskPosition
  > {
    return propertyToProcessTaskPositionEntityInfo;
  }

  private canEditPropertyToProcessTaskPosition(
    propertyToProcessTaskPosition: PropertyToProcessTaskPosition
  ): boolean {
    if (!this.propertyAdapter) {
      return false;
    }

    const property = this.entityManager.propertyRepository.getById(
      propertyToProcessTaskPosition.propertyId
    );

    if (!property) {
      return false;
    }

    return this.propertyAdapter.canEditPropertyToProcessTaskPositions(property);
  }
}

export type PropertyToProcessTaskPositionAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
