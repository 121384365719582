import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';

import { GeneralFileTypes } from 'common/Enums/GeneralFileTypes';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PersonHelper } from 'common/EntityHelper/PersonHelper';

import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ActiveEntitiesService } from '../../../services/ActiveEntitiesService';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../../services/PermissionBindingService';
import { ActiveUserCompanySettingService } from '../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { ProcessTaskLoggingService } from '../../../services/ProcessTaskLoggingService';
import { ProcessTaskGroupUtils } from '../../../classes/EntityManager/entities/ProcessTaskGroup/ProcessTaskGroupUtils';
import {
  FinishAppointmentOptions,
  ProcessTaskAppointmentFinishService
} from '../../../services/ProcessTaskAppointmentFinishService/ProcessTaskAppointmentFinishService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { ThingGroup } from '../../../classes/EntityManager/entities/ThingGroup/types';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { Thing } from '../../../classes/EntityManager/entities/Thing/types';
import { ProcessConfigurationStep } from '../../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskGroup } from '../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { GeneralFileUploadService } from '../../../classes/EntityManager/entities/GeneralFile/GeneralFileUploadService';
import { GeneralFileUtils } from '../../../classes/EntityManager/entities/GeneralFile/GeneralFileUtils';
import {
  ProcessTaskAppointmentDateInfo,
  ProcessTaskAppointmentDateInfoMap,
  ProcessTaskAppointmentDateInfoMapComputer
} from '../../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import { ComputedValueService } from '../../../computedValues/ComputedValueService';
import { ProcessTaskToProjectFormCreationService } from '../../../classes/EntityManager/entities/ProcessTaskToProject/ProcessTaskToProjectFormCreationService';
import { PersonContactLink } from '../../../personComponents/person-contact-link/person-contact-link';
import { ProcessConfiguration } from '../../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessAppointmentTabsKeys } from 'common/Types/Entities/ProcessConfiguration/ProcessConfigurationDto';
import { Person } from '../../../classes/EntityManager/entities/Person/types';
import { ShowProcessAppointmentConfiguration } from '../ShowProcessAppointmentConfiguration';

@autoinject()
export class ShowProcessAppointmentMainPageContent {
  private static FINISH_APPOINTMENT_UNDO_TIME = 2 * 60 * 60 * 1000; // give the user 2 hours time to revert the finishedAt in case he misclicks or something

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public appointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processConfiguration: ProcessConfiguration | null = null;

  @bindable()
  public processConfigurationStep: ProcessConfigurationStep | null = null;

  @bindable()
  public configuration: ShowProcessAppointmentConfiguration | null = null;

  /** The ID of the form (e.g. processTaskToProject) that should be expanded immediately */
  @bindable public openedFormId: string | null = null;

  private subscriptionManager: SubscriptionManager;
  private permissionBindingHandle: PermissionBindingHandle;

  private dateInfo: ProcessTaskAppointmentDateInfo | null = null;
  private thingGroup: ThingGroup | null = null;
  private thing: Thing | null = null;
  private processTaskContactPerson: Person | null = null;
  private processTaskGroupIsEditable: boolean = false;
  private positions: Array<ProcessTaskPosition> = [];

  private appointmentGeneralInfoEditing: boolean = false;
  private appointmentFinishWithNfcTag: boolean = false;

  private finishAppointmentState: FinishAppointmentStatus =
    FinishAppointmentStatus.UNFINISHED;

  private signatureImageUrl: string | null = null;
  private canUseOperations: boolean = false;
  private processTaskAppointmentDateInfoMap: ProcessTaskAppointmentDateInfoMap =
    new Map();

  private FinishAppointmentStatus = FinishAppointmentStatus;
  private ProcessTaskGroupUtils = ProcessTaskGroupUtils;
  private PersonHelper = PersonHelper;

  private isAttached: boolean = false;
  private selectedPositions: Array<ProcessTaskPosition> = [];

  protected signatureText: string | null = null;

  constructor(
    private readonly i18n: I18N,
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly generalFileUploadService: GeneralFileUploadService,
    private readonly computedValueService: ComputedValueService,
    private readonly processTaskToProjectFormCreationService: ProcessTaskToProjectFormCreationService,
    private readonly activeEntitiesService: ActiveEntitiesService,
    permissionBindingService: PermissionBindingService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly processTaskAppointmentFinishService: ProcessTaskAppointmentFinishService
  ) {
    this.i18n = i18n;
    this.router = router;

    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      entity: {
        property: 'processTaskGroup',
        userGroupPropertyOfEntity: 'ownerUserGroupId',
        editableProperty: 'processTaskGroupIsEditable'
      },
      permissionProperties: {
        canUseOperations: 'canUseOperations'
      }
    });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToExpression(
      this,
      'processTaskGroup.thingGroupId',
      this.updateThingGroup.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      this.updateThingGroup.bind(this)
    );
    this.updateThingGroup();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Thing,
      this.updateThing.bind(this)
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'processTask.thingId',
      this.updateThingGroup.bind(this)
    );
    this.updateThing();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      this.updateAppointmentData.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this.updatePositions.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskPosition,
      this.updatePositions.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Thing,
      this.updateThing.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingToPerson,
      this.updateContactPerson.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Person,
      this.updateContactPerson.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.GeneralFile,
      this.updateSignature.bind(this)
    );

    this.subscriptionManager.subscribeToInterval(
      this.updateFinishAppointmentState.bind(this),
      10000
    ); // updating the state every 10 seconds is accurate enough

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
        computeData: {},
        callback: (processTaskAppointmentDateInfoMap) => {
          this.processTaskAppointmentDateInfoMap =
            processTaskAppointmentDateInfoMap;
          this.updateDateInfo();
        }
      })
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'operations.appointmentGeneralInfoEditing',
        (appointmentGeneralInfoEditing) => {
          this.appointmentGeneralInfoEditing = appointmentGeneralInfoEditing;
        }
      ),
      this.activeUserCompanySettingService.bindSettingProperty(
        'operations.appointmentFinishWithNfcTag',
        (appointmentFinishWithNfcTag) => {
          this.appointmentFinishWithNfcTag = appointmentFinishWithNfcTag;
        }
      )
    );

    this.permissionBindingHandle.subscribe();

    this.subscriptionManager.addDisposable(
      PersonContactLink.addEventListener('phoneContactClicked', (payload) => {
        if (this.processTask) {
          void this.processTaskLoggingService.logPhoneLinkClick(
            this.processTask,
            payload.person,
            payload.personContact
          );
        }
      })
    );

    this.updateAppointmentData();
  }

  protected detached(): void {
    this.isAttached = false;
    this.activeEntitiesService.setActiveProcessTaskAppointment(null);
    this.subscriptionManager.disposeSubscriptions();
    this.permissionBindingHandle.unsubscribe();
  }

  protected appointmentChanged(): void {
    if (this.isAttached) {
      this.updateAppointmentData();
    }
  }

  protected processConfigurationStepChanged(): void {
    if (this.isAttached) {
      this.updateSignatureText();
    }
  }

  private handleNavigateToProcessTaskOverviewClick(): void {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ShowProcessAppointment.handleNavigateToProcessTaskOverviewClick without a processTask"
    );
    this.router.navigateToRoute('edit_process_task', {
      process_task_id: this.processTask.id
    });
  }

  private handleSignatureChanged(dataUrl: string): void {
    if (!this.appointment || !dataUrl) return;
    let signatureFile = null;
    const firstExistingSignatureFile =
      this.entityManager.generalFileRepository.getSignatureFilesByProcessTaskAppointmentId(
        this.appointment.id
      )[0];
    if (firstExistingSignatureFile) {
      signatureFile = firstExistingSignatureFile;
    } else {
      signatureFile = this.entityManager.generalFileRepository.create({
        ownerUserGroupId: this.appointment.ownerUserGroupId,
        ownerProcessTaskId: this.appointment.ownerProcessTaskId,
        ownerProcessTaskGroupId: this.appointment.ownerProcessTaskGroupId,
        processTaskAppointmentId: this.appointment.id,
        type: GeneralFileTypes.SIGNATURE,
        name: 'signature',
        extension: 'svg'
      });
    }
    this.generalFileUploadService.uploadGeneralFile(
      signatureFile,
      dataUrl,
      'svg'
    );
  }

  protected async handleFinishAppointmentClick(): Promise<void> {
    if (
      !this.appointment ||
      !this.processTask ||
      !this.processTaskGroup ||
      !this.configuration
    )
      return;

    const finishAppointmentOptions: FinishAppointmentOptions = {
      appointment: this.appointment,
      processTask: this.processTask,
      processTaskGroup: this.processTaskGroup,
      canEditGeneralProcessTaskInfo: this.appointmentGeneralInfoEditing,
      canSignTheAppointment: this.configuration.showSignature,
      canTakePictures: this.configuration.showPictures,
      canDeployDevices: this.configuration.showDevices
    };

    if (this.appointmentFinishWithNfcTag) {
      await this.processTaskAppointmentFinishService.finishAppointmentWithNfcTag(
        finishAppointmentOptions
      );
    } else {
      await this.processTaskAppointmentFinishService.finishAppointment(
        finishAppointmentOptions
      );
    }

    this.updateFinishAppointmentState();
  }

  protected async handleUndoFinishAppointmentClick(): Promise<void> {
    if (!this.appointment || !this.processTask) return;

    if (this.appointmentFinishWithNfcTag) {
      await this.processTaskAppointmentFinishService.undoFinishingAppointmentWithNfcTag(
        this.appointment,
        this.processTask
      );
    } else {
      this.processTaskAppointmentFinishService.undoFinishingAppointment(
        this.appointment,
        this.processTask
      );
    }

    this.updateFinishAppointmentState();
  }

  private updateAppointmentData(): void {
    this.setAppointmentOpened();
    this.updateDateInfo();
    this.updatePositions();
    this.updateSignature();
    this.updateFinishAppointmentState();

    if (this.processTask && this.appointment) {
      this.processTaskToProjectFormCreationService.createAutoForms(
        this.processTask,
        this.appointment
      );
    }
  }

  private updateDateInfo(): void {
    if (this.appointment) {
      this.dateInfo =
        this.processTaskAppointmentDateInfoMap.get(this.appointment.id) ?? null;
    } else {
      this.dateInfo = null;
    }
  }

  private updateThingGroup(): void {
    if (this.processTaskGroup) {
      this.thingGroup = this.entityManager.thingGroupRepository.getById(
        this.processTaskGroup.thingGroupId
      );
    } else {
      this.thingGroup = null;
    }
  }

  private updateThing(): void {
    if (this.processTask) {
      this.thing = this.entityManager.thingRepository.getById(
        this.processTask.thingId
      );
    } else {
      this.thing = null;
    }

    this.updateContactPerson();
  }

  private updateContactPerson(): void {
    this.processTaskContactPerson = null;
    if (this.thing) {
      const thingToPerson =
        this.entityManager.thingToPersonRepository.getMainContactOrFallbackForThingId(
          this.thing.id
        );
      this.processTaskContactPerson = thingToPerson
        ? this.entityManager.personRepository.getById(thingToPerson.personId)
        : null;
    }
  }

  private updateSignature(): void {
    if (!this.appointment) {
      this.signatureImageUrl = null;
      return;
    }
    const firstSignatureFile =
      this.entityManager.generalFileRepository.getSignatureFilesByProcessTaskAppointmentId(
        this.appointment.id
      )[0];
    if (!firstSignatureFile) {
      this.signatureImageUrl = null;
      return;
    }
    const path =
      GeneralFileUtils.getFullOnlineFilePathForGeneralFile(firstSignatureFile);
    if (!path) {
      this.signatureImageUrl = null;
      return;
    }
    this.signatureImageUrl = path;
  }

  private updatePositions(): void {
    this.positions = [];
    this.selectedPositions = [];
    if (!this.appointment) {
      return;
    }
    const processTaskAppointmentToProcessTaskPositions =
      this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskAppointmentId(
        this.appointment.id
      );
    for (const processTaskAppointmentToProcessTaskPosition of processTaskAppointmentToProcessTaskPositions) {
      const processTaskPosition =
        this.entityManager.processTaskPositionRepository.getById(
          processTaskAppointmentToProcessTaskPosition.processTaskPositionId
        );
      if (!processTaskPosition) continue;
      this.positions.push(processTaskPosition);
      if (processTaskAppointmentToProcessTaskPosition.done) {
        this.selectedPositions.push(processTaskPosition);
      }
    }

    this.updateSignatureText();
  }

  private updateSignatureText(): void {
    const positionsList = this.selectedPositions.map((p) => p.name);
    this.signatureText =
      this.i18n.tr(
        'generalPages.showProcessAppointment.showProcessAppointmentMainPageContent.positionsTextInSignature'
      ) +
      '\n\n' +
      positionsList.join(', ');

    if (
      this.processConfigurationStep &&
      this.processConfigurationStep.appointmentSignatureText
    ) {
      this.signatureText +=
        '\n\n' + this.processConfigurationStep.appointmentSignatureText;
    }
  }

  private updateFinishAppointmentState(): void {
    const finishedAt = this.appointment ? this.appointment.finishedAt : null;

    if (!finishedAt) {
      this.finishAppointmentState = FinishAppointmentStatus.UNFINISHED;
      return;
    }

    const finishedAtDate = new Date(finishedAt);
    const diff = Date.now() - finishedAtDate.getTime();

    if (
      diff > ShowProcessAppointmentMainPageContent.FINISH_APPOINTMENT_UNDO_TIME
    ) {
      this.finishAppointmentState = FinishAppointmentStatus.FINISHED;
    } else {
      this.finishAppointmentState = FinishAppointmentStatus.FINISHED_UNDO;
    }
  }

  private updateAppointmentToPositionRelation(
    position: ProcessTaskPosition,
    done: boolean
  ): void {
    assertNotNullOrUndefined(
      this.appointment,
      "can't ShowProcessAppointment.updateAppointmentToPositionRelation without an appointment"
    );

    const relation =
      this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskAppointmentIdAndProcessTaskPositionId(
        this.appointment.id,
        position.id
      );
    assertNotNullOrUndefined(
      relation,
      'ShowProcessAppointment.updateAppointmentToPositionRelation: processTaskAppointmentToProcessTaskPosition relation does not exist'
    );

    relation.done = done;
    this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.update(
      relation
    );
  }

  protected isInSelectedPositions(
    selectedPositions: Array<ProcessTaskPosition>,
    position: ProcessTaskPosition
  ): boolean {
    return !!selectedPositions.find((p) => p.id === position.id);
  }

  protected shouldExpand(tab: ProcessAppointmentTabsKeys): boolean {
    return (
      this.processConfiguration?.processAppointmentTabsToExpand?.[tab] === true
    );
  }

  private setAppointmentOpened(): void {
    if (!this.appointment) {
      return;
    }

    if (!this.appointment.openedAt) {
      this.appointment.openedAt = new Date().toISOString();
      this.entityManager.processTaskAppointmentRepository.update(
        this.appointment
      );
    }
  }
}

enum FinishAppointmentStatus {
  UNFINISHED = 'unfinished',
  FINISHED = 'finished',
  FINISHED_UNDO = 'finishedUndo'
}
