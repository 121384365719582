import { EntityInfo } from '@record-it-npm/synchro-common';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { StructureTemplate } from '../../../../classes/EntityManager/entities/StructureTemplate/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import {
  CanEditFieldFieldName,
  EntityAdapter,
  SubscribeOptions
} from '../EntityAdapter';
import { structureTemplateEntityInfo } from '../../../../classes/EntityManager/entities/StructureTemplate/structureTemplateEntityInfo';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';

export class StructureTemplateAdapter
  implements EntityAdapter<StructureTemplate>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private readonly fieldsThatCanOnlyBeEditedInDraftMode: Set<
    CanEditFieldFieldName<StructureTemplate>
  > = new Set(['name', 'description', 'type']);

  private roleBasedPermissions: RoleBasedPermissions | null = null;

  constructor(options: StructureTemplateAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplate,
      updateBindings
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return this.roleBasedPermissions != null;
  }

  public canDeleteEntity(structureTemplate: StructureTemplate): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(structureTemplate.ownerUserGroupId)
      .getCanDeleteStructureTemplates();
  }

  public canEditField(
    structureTemplate: StructureTemplate,
    fieldName: CanEditFieldFieldName<StructureTemplate>
  ): boolean {
    // since the status has special permissions based on the state, we generally deny editing the status field via this permission
    // this is so no one accidentaly allows editing the status via structureTemplatePermissionsHandle.canEditField.status
    if (fieldName === 'status') {
      return false;
    }

    if (
      this.fieldsThatCanOnlyBeEditedInDraftMode.has(fieldName) &&
      structureTemplate.status !== StructureTemplateStatus.DRAFT
    ) {
      return false;
    }

    return this.canUpdateStructureTemplate(structureTemplate);
  }

  public canSetStatusToProvisionallyActive(
    structureTemplate: StructureTemplate
  ): boolean {
    if (
      structureTemplate.status !== StructureTemplateStatus.DRAFT &&
      structureTemplate.status !== StructureTemplateStatus.ARCHIVED
    ) {
      return false;
    }

    return this.canUpdateStructureTemplate(structureTemplate);
  }

  public canSetStatusToActive(structureTemplate: StructureTemplate): boolean {
    if (
      structureTemplate.status !== StructureTemplateStatus.DRAFT &&
      structureTemplate.status !== StructureTemplateStatus.PROVISIONALLY_ACTIVE
    ) {
      return false;
    }

    return this.canUpdateStructureTemplate(structureTemplate);
  }

  public canSetStatusToArchived(structureTemplate: StructureTemplate): boolean {
    if (structureTemplate.status === StructureTemplateStatus.ARCHIVED) {
      return false;
    }

    return this.canUpdateStructureTemplate(structureTemplate);
  }

  public canGenerallyEditStructureTemplateEntryProperties(
    structureTemplate: StructureTemplate
  ): boolean {
    return this.canUpdateStructureTemplate(structureTemplate);
  }

  public canCreateStructureTemplateEntryProperties(
    structureTemplate: StructureTemplate
  ): boolean {
    if (
      structureTemplate.status !== StructureTemplateStatus.DRAFT &&
      structureTemplate.status !== StructureTemplateStatus.PROVISIONALLY_ACTIVE
    ) {
      return false;
    }

    return this.canUpdateStructureTemplate(structureTemplate);
  }

  public canEditStructureTemplateRatingCategories(
    structureTemplate: StructureTemplate
  ): boolean {
    return this.canUpdateStructureTemplate(structureTemplate);
  }

  public canCreateStructureTemplateEntries(
    structureTemplate: StructureTemplate
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    if (structureTemplate.status !== StructureTemplateStatus.DRAFT) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(structureTemplate.ownerUserGroupId)
      .getCanCreateStructureTemplateEntries();
  }

  public canCreateStructureTemplateEntryGroups(
    structureTemplate: StructureTemplate
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    if (structureTemplate.status !== StructureTemplateStatus.DRAFT) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(structureTemplate.ownerUserGroupId)
      .getCanCreateStructureTemplateEntryGroups();
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.StructureTemplate,
    StructureTemplate
  > {
    return structureTemplateEntityInfo;
  }

  private canUpdateStructureTemplate(
    structureTemplate: StructureTemplate
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(structureTemplate.ownerUserGroupId)
      .getCanUpdateStructureTemplates();
  }
}

export type StructureTemplateAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
