import { assertNotNullOrUndefined } from '../Asserts';
import { GaussJordan } from '../Geometry/GaussJordan/GaussJordan';
import { MatrixUtils } from '../Geometry/MatrixUtils';
import { Vector } from '../Geometry/Vector';
import {
  IPictureLocationInfo,
  IPictureLocationInfoPosition,
  IPictureMarking
} from '../Types/Entities/Picture/PictureDto';
import { MarkingsTransformation } from '../Types/Entities/PictureRevision/PictureRevisionDto';

export class PictureRevisionTransformationCalculator {
  public static computeMarkingsTransfomation({
    point1,
    point2,
    point3,
    leftPictureTransformation
  }: {
    point1: {
      oldPosition: IPictureMarking;
      newPosition: IPictureMarking;
    };
    point2: {
      oldPosition: IPictureMarking;
      newPosition: IPictureMarking;
    };
    point3: {
      oldPosition: IPictureMarking;
      newPosition: IPictureMarking;
    };
    leftPictureTransformation: MarkingsTransformation | null;
  }): MarkingsTransformation {
    const p1 = this.getPercentagePositionVectorFromMarking(point1.oldPosition);
    const p2 = this.getPercentagePositionVectorFromMarking(point2.oldPosition);
    const p3 = this.getPercentagePositionVectorFromMarking(point3.oldPosition);
    const p4 = this.getPercentagePositionVectorFromMarking(point1.newPosition);
    const p5 = this.getPercentagePositionVectorFromMarking(point2.newPosition);
    const p6 = this.getPercentagePositionVectorFromMarking(point3.newPosition);

    const relativeTransformation = GaussJordan.solve(
      [
        [p1.getX(), p1.getY(), 1, 0, 0, 0],
        [0, 0, 0, p1.getX(), p1.getY(), 1],
        [p2.getX(), p2.getY(), 1, 0, 0, 0],
        [0, 0, 0, p2.getX(), p2.getY(), 1],
        [p3.getX(), p3.getY(), 1, 0, 0, 0],
        [0, 0, 0, p3.getX(), p3.getY(), 1]
      ],
      [p4.getX(), p4.getY(), p5.getX(), p5.getY(), p6.getX(), p6.getY()]
    );

    const absoluteTransformation = leftPictureTransformation
      ? this.computeAbsoluteTransformation(
          this.markingsTransformationToMatrix(
            this.arrayToMarkingsTransformation(relativeTransformation)
          ),
          this.markingsTransformationToMatrix(leftPictureTransformation)
        )
      : this.arrayToMarkingsTransformation(relativeTransformation);

    assertNotNullOrUndefined(absoluteTransformation, '');
    return absoluteTransformation;
  }

  public static computeMarkingPositionsBasedOnTransformationMatrix(
    marking: IPictureMarking,
    transformation: MarkingsTransformation
  ): IPictureMarking {
    return this.applyTransformationToMarking(marking, transformation);
  }

  public static computeMarkingPositionBeforeTransformation(
    marking: IPictureMarking,
    transformation: MarkingsTransformation
  ): IPictureMarking {
    const inverseTransformation =
      this.computeInverseTransformation(transformation);

    assertNotNullOrUndefined(
      inverseTransformation,
      'cannot computeMarkingPositionBeforeTransformation without inverseTransformation'
    );

    return this.applyTransformationToMarking(marking, inverseTransformation);
  }

  public static computeLocationInfoBasedOnTransformationMatrix(
    position: IPictureLocationInfo,
    transformation: MarkingsTransformation
  ): IPictureLocationInfo {
    const inverseTransformation =
      this.computeInverseTransformation(transformation);

    assertNotNullOrUndefined(
      inverseTransformation,
      'cannot computeMarkingPositionBeforeTransformation without inverseTransformation'
    );

    return this.applyTransformationToPictureLocationInfo(
      position,
      inverseTransformation
    );
  }

  public static computeLocationInfoBeforeTransformation(
    position: IPictureLocationInfo,
    transformation: MarkingsTransformation
  ): IPictureLocationInfo {
    return this.applyTransformationToPictureLocationInfo(
      position,
      transformation
    );
  }

  public static computeMarkerOnImageFromPixelPositionVector(
    point: Vector,
    picturePositionInfo: PicturePositionInfo
  ): IPictureMarking {
    const position = point
      .clone()
      .substractVector(picturePositionInfo.topLeftPosition)
      .divideVector(picturePositionInfo.size)
      .scale(100);

    return {
      top: position.getY().toString() + '%',
      left: position.getX().toString() + '%',
      boxTop: 0,
      boxLeft: 0,
      boxBottom: 100,
      boxRight: 100
    };
  }

  private static applyTransformationToPictureLocationInfo(
    position: IPictureLocationInfo,
    transformation: MarkingsTransformation
  ): IPictureLocationInfo {
    const topLeft = new Vector(
      position.topLeftPosition.longitude,
      position.topLeftPosition.latitude
    );

    const topRight = new Vector(
      position.topRightPosition.longitude,
      position.topRightPosition.latitude
    );

    const bottomLeft = new Vector(
      position.bottomLeftPosition.longitude,
      position.bottomLeftPosition.latitude
    );

    const topLeftImageCorner = this.applyTransformationToVector(
      new Vector(0, 0),
      transformation
    );
    const topRightImageCorner = this.applyTransformationToVector(
      new Vector(100, 0),
      transformation
    );
    const bottomRightImageCorner = this.applyTransformationToVector(
      new Vector(100, 100),
      transformation
    );
    const bottomLeftImageCorner = this.applyTransformationToVector(
      new Vector(0, 100),
      transformation
    );

    return {
      topLeftPosition: this.vectorToPictureLocationInfoPosition(
        this.moveLocationInfoPosition({
          topLeft,
          topRight,
          bottomLeft,
          scale: new Vector(
            topLeftImageCorner.getX() / 100,
            topLeftImageCorner.getY() / 100
          )
        })
      ),
      topRightPosition: this.vectorToPictureLocationInfoPosition(
        this.moveLocationInfoPosition({
          topLeft,
          topRight,
          bottomLeft,
          scale: new Vector(
            topRightImageCorner.getX() / 100,
            topRightImageCorner.getY() / 100
          )
        })
      ),
      bottomLeftPosition: this.vectorToPictureLocationInfoPosition(
        this.moveLocationInfoPosition({
          topLeft,
          topRight,
          bottomLeft,
          scale: new Vector(
            bottomLeftImageCorner.getX() / 100,
            bottomLeftImageCorner.getY() / 100
          )
        })
      ),
      bottomRightPosition: this.vectorToPictureLocationInfoPosition(
        this.moveLocationInfoPosition({
          topLeft,
          topRight,
          bottomLeft,
          scale: new Vector(
            bottomRightImageCorner.getX() / 100,
            bottomRightImageCorner.getY() / 100
          )
        })
      )
    };
  }

  private static moveLocationInfoPosition({
    topLeft,
    topRight,
    bottomLeft,
    scale
  }: {
    topLeft: Vector;
    topRight: Vector;
    bottomLeft: Vector;
    scale: Vector;
  }): Vector {
    return topLeft
      .clone()
      .addVector(topRight.clone().substractVector(topLeft).scale(scale.getX()))
      .addVector(
        bottomLeft.clone().substractVector(topLeft).scale(scale.getY())
      );
  }

  private static vectorToPictureLocationInfoPosition(
    vector: Vector
  ): IPictureLocationInfoPosition {
    return {
      latitude: vector.getY(),
      longitude: vector.getX()
    };
  }

  private static applyTransformationToMarking(
    marking: IPictureMarking,
    transformation: MarkingsTransformation
  ): IPictureMarking {
    const result = this.applyTransformationToVector(
      this.getPercentagePositionVectorFromMarking(marking),
      transformation
    );

    return {
      top: result.getY().toString() + '%',
      left: result.getX().toString() + '%'
    };
  }

  private static applyTransformationToVector(
    vector: Vector,
    transformation: MarkingsTransformation
  ): Vector {
    const scale = new Vector(transformation.a, transformation.d);
    const skew = new Vector(transformation.c, transformation.b);
    const translation = new Vector(transformation.tx, transformation.ty);

    return new Vector(
      vector.getX() * scale.getX() +
        vector.getY() * skew.getY() +
        translation.getX(),
      vector.getX() * skew.getX() +
        vector.getY() * scale.getY() +
        translation.getY()
    );
  }

  private static getPercentagePositionVectorFromMarking(
    marking: IPictureMarking
  ): Vector {
    return new Vector(parseFloat(marking.left), parseFloat(marking.top));
  }

  private static computeInverseTransformation(
    transformation: MarkingsTransformation
  ): MarkingsTransformation | null {
    const inverseTransformationMatrix = GaussJordan.invertMatrix([
      [transformation.a, transformation.b, transformation.tx],
      [transformation.c, transformation.d, transformation.ty],
      [0, 0, 1]
    ]);

    return this.matrixToMarkingsTransformation(inverseTransformationMatrix);
  }

  private static computeAbsoluteTransformation(
    relativeTransformation: Array<Array<number>>,
    originalTansformation: Array<Array<number>>
  ): MarkingsTransformation | null {
    const matrix = MatrixUtils.multiplyMatrices(
      relativeTransformation,
      originalTansformation
    );

    assertNotNullOrUndefined(matrix, 'matrix multiplication failed');

    return this.matrixToMarkingsTransformation(matrix);
  }

  private static matrixToMarkingsTransformation(
    matrix: Array<Array<number>>
  ): MarkingsTransformation | null {
    const row1 = matrix[0];
    const row2 = matrix[1];

    if (!row1 || !row2) return null;

    return {
      a: row1[0] ?? 1,
      b: row1[1] ?? 0,
      c: row2[0] ?? 0,
      d: row2[1] ?? 1,
      tx: row1[2] ?? 0,
      ty: row2[2] ?? 0
    };
  }

  private static markingsTransformationToMatrix(
    transformation: MarkingsTransformation
  ): Array<Array<number>> {
    return [
      [transformation.a, transformation.b, transformation.tx],
      [transformation.c, transformation.d, transformation.ty],
      [0, 0, 1]
    ];
  }

  private static arrayToMarkingsTransformation(
    transformation: Array<number>
  ): MarkingsTransformation {
    return {
      a: transformation[0] ?? 1,
      b: transformation[1] ?? 0,
      c: transformation[3] ?? 0,
      d: transformation[4] ?? 1,
      tx: transformation[2] ?? 0,
      ty: transformation[5] ?? 0
    };
  }
}

export type PicturePositionInfo = {
  size: Vector;
  topLeftPosition: Vector;
  containerSize: Vector;
};
