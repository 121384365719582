import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { CheckboxCheckedChangedEvent } from '../../../aureliaComponents/expandable-dual-row-compact-list-item/expandable-dual-row-compact-list-item';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskAppointmentToProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskAppointmentToProcessTaskDevice/types';
import { ProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { computed } from '../../../hooks/computed';
import { expression, model } from '../../../hooks/dependencies';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { ProcessTaskLoggingService } from '../../../services/ProcessTaskLoggingService';
import { EditProcessTaskDeviceDialog } from '../../edit-process-task-device-dialog/edit-process-task-device-dialog';

@autoinject()
export class ProcessTaskAppointmentDevicesWidgetListItem {
  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processTaskDevice: ProcessTaskDevice | null = null;

  @subscribableLifecycle()
  protected readonly processTaskAppointmentToProcessTaskDevicePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointmentToProcessTaskDevice];

  @subscribableLifecycle()
  protected readonly processTaskAppointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointment];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    permissionsService: PermissionsService
  ) {
    this.processTaskAppointmentToProcessTaskDevicePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskAppointmentToProcessTaskDevice,
        context: this as ProcessTaskAppointmentDevicesWidgetListItem,
        expression: 'processTaskAppointmentToProcessTaskDevice'
      });

    this.processTaskAppointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskAppointment,
        context: this as ProcessTaskAppointmentDevicesWidgetListItem,
        propertyName: 'processTaskAppointment'
      });
  }

  protected handleCheckedChanged(event: CheckboxCheckedChangedEvent): void {
    assertNotNullOrUndefined(
      this.processTaskAppointment,
      "can't ProcessTaskAppointmentDevicesWidgetListItem.handleCheckedChanged without a processTaskAppointment"
    );
    assertNotNullOrUndefined(
      this.processTaskDevice,
      "can't ProcessTaskAppointmentDevicesWidgetListItem.handleCheckedChanged without a processTaskDevice"
    );

    if (event.detail.checked) {
      if (!this.processTaskAppointmentToProcessTaskDevice) {
        this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.create(
          {
            processTaskDeviceId: this.processTaskDevice.id,
            processTaskAppointmentId: this.processTaskAppointment.id,
            ownerProcessTaskGroupId:
              this.processTaskDevice.ownerProcessTaskGroupId,
            ownerProcessTaskId: this.processTaskDevice.ownerProcessTaskId,
            ownerUserGroupId: this.processTaskDevice.ownerUserGroupId
          }
        );
      }
    } else {
      if (this.processTaskAppointmentToProcessTaskDevice) {
        this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.delete(
          this.processTaskAppointmentToProcessTaskDevice
        );
      }
    }
    this.processTaskLoggingService.logProcessTaskSubEntityModified({
      entityName: EntityName.ProcessTaskAppointment,
      entity: this.processTaskAppointment,
      property: null,
      displayNameAtLogTime: this.processTaskAppointment.name
    });
  }

  protected handleEditDeviceClicked(): void {
    const processTaskDevice = this.processTaskDevice;
    assertNotNullOrUndefined(
      processTaskDevice,
      "can't ProcessTaskAppointmentDevicesWidgetListItem.handleEditDeviceClicked without processTaskDevice"
    );

    void EditProcessTaskDeviceDialog.open({
      processTaskDevice,
      onDialogClosed: () => {}
    });
  }

  @computed(
    model(EntityName.ProcessTaskAppointmentToProcessTaskDevice),
    expression('processTaskAppointment.id'),
    expression('processTaskDevice.id')
  )
  protected get processTaskAppointmentToProcessTaskDevice(): ProcessTaskAppointmentToProcessTaskDevice | null {
    if (!this.processTaskAppointment || !this.processTaskDevice) {
      return null;
    }

    return this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.getByProcessTaskAppointmentAndProcessTaskDeviceId(
      this.processTaskAppointment.id,
      this.processTaskDevice.id
    );
  }

  @computed(
    expression('processTaskAppointmentToProcessTaskDevice'),
    expression(
      'processTaskAppointmentPermissionsHandle.canCreateProcessTaskAppointmentToProcessTaskDevices'
    ),
    expression(
      'processTaskAppointmentToProcessTaskDevicePermissionsHandle.canDeleteEntity'
    )
  )
  protected get canEditCheckbox(): boolean {
    if (!this.processTaskAppointmentToProcessTaskDevice) {
      return this.processTaskAppointmentPermissionsHandle
        .canCreateProcessTaskAppointmentToProcessTaskDevices;
    }

    return this.processTaskAppointmentToProcessTaskDevicePermissionsHandle
      .canDeleteEntity;
  }
}
