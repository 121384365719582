import { assertNotNullOrUndefined } from 'common/Asserts';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { GalleryThingAdditionalMarkingsHelper } from 'common/GalleryThing/GalleryThingAdditionalMarkingsHelper';
import { TPictureAdditionalMarking } from 'common/Types/Entities/Picture/PictureDto';

import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';
import { PropertyCreationBaseData } from '../EntityManager/entities/Property/types';
import { ThingTag } from '../EntityManager/entities/Tag/types';
import { GalleryThingPictureOverviewEntry } from './GalleryThingPictureOverviewEntryHelper';
import {
  GalleryThingPicture,
  Picture
} from '../EntityManager/entities/Picture/types';

// TODO: REC-4516 add permission checking for usages + fix bulk editing since a lot of the operations don't work for online only overviewEntries
export class GalleryThingPictureBulkEditHelper {
  constructor(
    private readonly thingId: string,
    private readonly bulkEditOptions: GalleryThingPictureBulkEditOptions,
    private readonly entityManager: AppEntityManager
  ) {}

  public bulkEditPictures(
    pictureOverviewEntries: Array<GalleryThingPictureOverviewEntry>
  ): void {
    const galleryThingOverviewPictures =
      this.entityManager.pictureRepository.getByGalleryThingId(this.thingId);

    pictureOverviewEntries.forEach((pe) => {
      if (this.bulkEditOptions.regionId)
        this.editPictureRegionId(pe, this.bulkEditOptions.regionId);

      if (
        this.bulkEditOptions.personIds &&
        this.bulkEditOptions.personIds.length > 0
      )
        this.editPicturePersonIds(pe, this.bulkEditOptions.personIds);

      if (this.bulkEditOptions.properties.length > 0)
        this.editPictureProperties(pe, this.bulkEditOptions.properties);

      if (this.bulkEditOptions.tags.length > 0)
        this.editPictureTags(pe, this.bulkEditOptions.tags);

      if (
        this.bulkEditOptions.coordinates.latitude &&
        this.bulkEditOptions.coordinates.longitude
      )
        this.editPictureCoordinates({
          overviewEntry: pe,
          coordinates: {
            latitude: this.bulkEditOptions.coordinates.latitude,
            longitude: this.bulkEditOptions.coordinates.longitude
          },
          galleryThingOverviewPictures
        });
    });
  }

  private editPictureRegionId(
    overviewEntry: GalleryThingPictureOverviewEntry,
    regionId: string
  ): void {
    if (!overviewEntry.entryId) return;

    const entry = this.entityManager.entryRepository.getById(
      overviewEntry.entryId
    );
    if (!entry) return;

    entry.regionId = regionId;
    this.entityManager.entryRepository.update(entry);
  }

  private editPictureTags(
    overviewEntry: GalleryThingPictureOverviewEntry,
    newTags: Array<ThingTag>
  ): void {
    if (!overviewEntry.pictureId) return;

    const picture = this.entityManager.pictureRepository.getRequiredById(
      overviewEntry.pictureId
    );

    for (const tagId of newTags.map((t) => t.id)) {
      ArrayUtils.pushUnique(picture.tagIds, tagId);
    }
    this.entityManager.pictureRepository.update(picture);
  }

  private editPicturePersonIds(
    overviewEntry: GalleryThingPictureOverviewEntry,
    personIds: Array<string>
  ): void {
    assertNotNullOrUndefined(
      overviewEntry.projectId,
      "can't GalleryThingPictureBulkEditHelper.editPicturePersonIds without a ownerProjectId"
    );
    const entryId = overviewEntry.entryId;
    const projectId = overviewEntry.projectId;
    if (!entryId || !projectId) return;

    const entryToPersons =
      this.entityManager.entryToPersonRepository.getByEntryId(entryId);

    // check and add missing relations
    personIds.forEach((personId) => {
      if (
        entryToPersons.findIndex(
          (entryToPerson) => entryToPerson.personId === personId
        ) >= 0
      )
        return;
      this.entityManager.entryToPersonRepository.create({
        entryId: entryId,
        personId: personId,
        ownerUserGroupId: overviewEntry.ownerUserGroupId,
        ownerProjectId: projectId
      });
    });

    // remove superfluous relations
    entryToPersons.forEach((entryToPerson) => {
      if (personIds.includes(entryToPerson.personId)) return;
      this.entityManager.entryToPersonRepository.delete(entryToPerson);
    });
  }

  private editPictureProperties(
    overviewEntry: GalleryThingPictureOverviewEntry,
    properties: Array<PropertyCreationBaseData>
  ): void {
    assertNotNullOrUndefined(
      overviewEntry.projectId,
      "can't GalleryThingPictureBulkEditHelper.editPictureProperties without a ownerProjectId"
    );
    const entryId = overviewEntry.entryId;
    const projectId = overviewEntry.projectId;
    if (!entryId || !projectId) return;

    const entryProperties =
      this.entityManager.propertyRepository.getByEntryId(entryId);

    properties.forEach((property) => {
      const entryProperty = entryProperties.find(
        (entryProp) =>
          entryProp.name === property.name && entryProp.type === property.type
      );
      if (!entryProperty) {
        this.entityManager.propertyRepository.create({
          ...property,
          entry: entryId,
          ownerUserGroupId: overviewEntry.ownerUserGroupId,
          ownerProjectId: projectId
        });
      } else {
        entryProperty.value = property.value ?? null;
        this.entityManager.propertyRepository.update(entryProperty);
      }
    });
  }

  private editPictureCoordinates({
    overviewEntry,
    coordinates,
    galleryThingOverviewPictures
  }: {
    overviewEntry: GalleryThingPictureOverviewEntry;
    coordinates: { latitude: number; longitude: number };
    galleryThingOverviewPictures: Array<GalleryThingPicture>;
  }): void {
    if (!overviewEntry.pictureId) return;

    const picture = this.entityManager.pictureRepository.getRequiredById(
      overviewEntry.pictureId
    );

    picture.coords = {
      latitude: coordinates.latitude,
      longitude: coordinates.longitude
    };

    picture.additional_markings = this.getAdditionalMarkingsForViaPicture({
      picture,
      galleryThingOverviewPictures
    });

    this.entityManager.pictureRepository.update(picture);
  }

  public static createEmptyBulkEditOptions(): GalleryThingPictureBulkEditOptions {
    return {
      regionId: null,
      personIds: [],
      properties: [],
      tags: [],
      coordinates: {
        latitude: null,
        longitude: null
      },
      automaticallyMarkPicturesOnThingPicture: false
    };
  }

  private getAdditionalMarkingsForViaPicture({
    picture,
    galleryThingOverviewPictures
  }: {
    picture: Picture;
    galleryThingOverviewPictures: Array<GalleryThingPicture>;
  }): Array<TPictureAdditionalMarking> {
    if (
      !this.bulkEditOptions.automaticallyMarkPicturesOnThingPicture ||
      !picture.coords?.latitude ||
      !picture.coords?.longitude
    )
      return [];

    return GalleryThingAdditionalMarkingsHelper.getAdditionalMarkings(
      galleryThingOverviewPictures,
      picture.coords,
      'picture_id'
    );
  }
}

export type GalleryThingPictureBulkEditOptions = {
  regionId: string | null;
  personIds: Array<string> | null;
  properties: Array<PropertyCreationBaseData>;
  tags: Array<ThingTag>;
  coordinates: {
    latitude: number | null;
    longitude: number | null;
  };
  automaticallyMarkPicturesOnThingPicture: boolean;
};
