import { EntityInfo } from '@record-it-npm/synchro-common';
import { PropertyDtoUtils } from 'common/Types/Entities/Property/PropertyDtoUtils';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityRepository } from '../../../../classes/EntityManager/base/AppEntityRepository';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { propertyEntityInfo } from '../../../../classes/EntityManager/entities/Property/propertyEntityInfo';
import { Property } from '../../../../classes/EntityManager/entities/Property/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { BoundAdaptersContainer } from '../../utils/BoundAdaptersContainer/BoundAdaptersContainer';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class PropertyAdapter implements EntityAdapter<Property> {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private readonly parentEntityAdaptersContainer =
    BoundAdaptersContainer.createCreationFunction<EntityAdapterWithCanEditProperties>()(
      [
        EntityName.Defect,
        EntityName.Entry,
        EntityName.ProcessConfigurationDevice,
        EntityName.ProcessConfigurationStepPosition,
        EntityName.ProcessTaskAppointment,
        EntityName.ProcessTaskDevice,
        EntityName.ProcessTask,
        EntityName.ProcessTaskMeasurePointReading,
        EntityName.ProcessTaskPositionDetailEntry,
        EntityName.ProcessTaskPosition,
        EntityName.Project,
        EntityName.ThingSection,
        EntityName.Region,
        EntityName.Thing,
        EntityName.ThingGroup,
        EntityName.ThingType,
        EntityName.UserDefinedEntity
      ]
    );

  constructor(options: PropertyAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.parentEntityAdaptersContainer.subscribe({
        updateBindings,
        bindAdapter
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return this.parentEntityAdaptersContainer.getDependenciesAreLoaded();
  }

  public canDeleteEntity(property: Property): boolean {
    return this.canEditProperty(property);
  }

  public canEditField(property: Property): boolean {
    return this.canEditProperty(property);
  }

  public canEditPropertyToProcessTaskPositions(property: Property): boolean {
    return this.canEditProperty(property);
  }

  public canEditPropertyToPersons(property: Property): boolean {
    return this.canEditProperty(property);
  }

  public canEditGeneralFiles(property: Property): boolean {
    return this.canEditProperty(property);
  }

  public canEditPictures(property: Property): boolean {
    return this.canEditProperty(property);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.Property,
    Property
  > {
    return propertyEntityInfo;
  }

  private canEditProperty(property: Property): boolean {
    const parentEntityInfo = PropertyDtoUtils.getParentEntityInfo({
      propertyDto: property
    });

    const parentEntity = (
      this.entityManager.entityRepositoryContainer.getByEntityName(
        parentEntityInfo.entityName
      ) as AppEntityRepository<any>
    ).getById(parentEntityInfo.id);

    if (!parentEntity) {
      return false;
    }

    const adapter =
      this.parentEntityAdaptersContainer.getAdapterForAnyEntityName(
        parentEntityInfo.entityName
      );

    if (!adapter) {
      return false;
    }

    return adapter.canEditProperties(parentEntity);
  }
}

export type PropertyAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};

type EntityAdapterWithCanEditProperties = EntityAdapter<any> & {
  canEditProperties: (entity: any) => boolean;
};
