import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { PermissionHelper } from '../../classes/PermissionHelper';
import { Dialogs } from '../../classes/Dialogs';

import { SocketService } from '../../services/SocketService';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { AssignReportTypeToProjectService } from '../../classes/EntityManager/entities/Project/AssignReportTypeToProjectService';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { ProjectTag } from '../../classes/EntityManager/entities/Tag/types';
import { ProjectProperty } from '../../classes/EntityManager/entities/Property/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { User } from '../../classes/EntityManager/entities/User/types';
import { ExpandableContainer } from '../expandable-container/expandable-container';
import { BehaviorSubject } from 'rxjs';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { GroupedProperties } from '../../classes/EntityManager/entities/Property/GroupedPropertyHelper';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { ProjectValueCalculationResultAdapter } from '../../valueCalculationComponents/ValueCalculationResultAdapter/ProjectValueCalculationResultAdapter';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ProjectProperties {
  @bindable public project: Project | null = null;

  @bindable public editable = false;

  @bindable public globalProjectPicturesLabelTk =
    'aureliaComponents.projectProperties.globalProjectPictures';

  protected availableReportTypes: Array<ReportType> = [];
  protected selectedReportType: ReportType | null = null;
  protected currentReportType: ReportType | null = null;
  protected valueCalculationResultAdapter: ProjectValueCalculationResultAdapter | null =
    null;

  private isOnline = true;

  private projectTags: Array<ProjectTag> = [];

  private properties: GroupedProperties<ProjectProperty> = [];

  private currentUser: User | null = null;
  private editableUserGroups: Array<UserGroup> = [];

  private subscriptionManager: SubscriptionManager;

  protected globalProjectPicturesExpandableContainer: ExpandableContainer | null =
    null;

  protected titlePicturesExpandableContainer: ExpandableContainer | null = null;
  protected thing$ = new BehaviorSubject<Thing | null>(null);

  @subscribableLifecycle()
  protected thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  private boundUpdateExpandableContainerState =
    this.updateExpandableContainerState.bind(this);

  constructor(
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly socketService: SocketService,
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    private readonly assignReportTypeToProjectService: AssignReportTypeToProjectService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.thingPermissionsHandle = permissionsService.getPermissionsHandle({
      entityName: EntityName.Thing,
      entity$: this.thing$
    });
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ReportType,
      () => {
        this.updateCurrentReportType({ keepSelectedReportType: true });
        this.updateReportTypes();
      }
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this.updateEditableUserGroups.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this),
      150
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Tag,
      this.updateProjectTags.bind(this),
      150
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'project.report_type',
      this.updateCurrentReportType.bind(this)
    );

    this.subscriptionManager.subscribeToExpression(
      this,
      'currentReportType.features.hasGlobalProjectPictures',
      this.boundUpdateExpandableContainerState
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'currentReportType.features.hasTitlePictures',
      this.boundUpdateExpandableContainerState
    );

    this.updateReportTypes();
    this.updateCurrentReportType();
    this.updateProperties();
    this.updateProjectTags();
    this.updateValueCalculationResultAdapter();
  }

  protected detached(): void {
    this.valueCalculationResultAdapter = null;
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
    this.updateEditableUserGroups();
  }

  private updateEditableUserGroups(): void {
    if (this.currentUser) {
      this.editableUserGroups =
        this.entityManager.userGroupRepository.getEditableGroupsForUser(
          this.currentUser
        );
    } else {
      this.editableUserGroups = [];
    }
  }

  private updateCurrentReportType({
    keepSelectedReportType
  }: {
    keepSelectedReportType?: boolean;
  } = {}): void {
    this.currentReportType = this.project?.report_type
      ? this.entityManager.reportTypeRepository.getById(
          this.project.report_type
        )
      : null;

    if (this.currentReportType && !keepSelectedReportType) {
      this.selectedReportType = this.currentReportType;
    }

    this.updateExpandableContainerState();
  }

  private updateReportTypes(): void {
    this.availableReportTypes =
      this.entityManager.reportTypeRepository.getAll();
  }

  private updateProperties(): void {
    if (this.project) {
      this.properties =
        this.entityManager.propertyRepository.getGroupedPropertiesByProjectId(
          this.project.id
        );
    } else {
      this.properties = [];
    }
  }

  private updateProjectTags(): void {
    if (this.project) {
      this.projectTags = this.entityManager.tagRepository.getByProjectId(
        this.project.id
      );
    } else {
      this.projectTags = [];
    }
  }

  private updateThing$(): void {
    if (this.project) {
      this.thing$.next(
        this.entityManager.thingRepository.getById(this.project.thing)
      );
    } else {
      this.thing$.next(null);
    }
  }

  protected reportTypeIsEditable(
    reportType: ReportType,
    currentUser: User,
    availableUserGroups: Array<UserGroup>
  ): boolean {
    return (
      reportType &&
      PermissionHelper.userCanEditOwnerUserGroupIdEntity(
        reportType,
        currentUser,
        availableUserGroups
      )
    );
  }

  // TO BE DEPRECATED SOON
  @computedFrom('selectedReportType', 'isOnline')
  protected get canReportTypeBeSet(): boolean {
    if (
      this.selectedReportType &&
      Array.isArray(this.selectedReportType.tags)
    ) {
      return true;
    } else {
      return this.isOnline;
    }
  }

  protected handleSetReportTypeClick(): void {
    if (this.project) {
      this.assignReportTypeToProjectService.assignReportTypeToProject(
        this.project,
        this.selectedReportType
      );
    }
  }

  protected handleTagCreated(tagName: string): void {
    if (this.project)
      this.entityManager.tagRepository.getOrCreateProjectTag(
        tagName,
        this.project.id,
        this.project.ownerUserGroupId
      );
  }

  protected handleTagRemoved(tag: ProjectTag): void {
    void Dialogs.deleteEntityDialog(tag).then(() => {
      this.entityManager.tagRepository.delete(tag);
    });
  }

  protected projectChanged(): void {
    this.updateReportTypes();
    this.updateCurrentReportType();
    this.updateProperties();
    this.updateThing$();
    this.updateValueCalculationResultAdapter();
  }

  @computedFrom(
    'globalProjectPicturesLabelTk',
    'currentReportType.features.hasGlobalProjectPictures'
  )
  protected get getGlobalProjectPicturesLabelTk(): string {
    const labelTk = `[label]${this.globalProjectPicturesLabelTk}`;
    if (this.currentReportType?.features?.hasGlobalProjectPictures !== false) {
      return labelTk;
    } else {
      return labelTk + '_noExportWithThisReportType';
    }
  }

  @computedFrom('currentReportType.features.hasTitlePictures')
  protected get getTitlePicturesLabelTk(): string {
    const labelTk =
      '[label]aureliaComponents.projectProperties.titlePicturesLabel';
    if (this.currentReportType?.features?.hasTitlePictures !== false) {
      return labelTk;
    } else {
      return labelTk + '_noExportWithThisReportType';
    }
  }

  private updateExpandableContainerState(): void {
    if (!this.currentReportType?.features) return;

    if (this.currentReportType.features.hasGlobalProjectPictures !== false) {
      this.globalProjectPicturesExpandableContainer?.expand();
    } else {
      this.globalProjectPicturesExpandableContainer?.collapse();
    }

    if (this.currentReportType.features.hasTitlePictures !== false) {
      this.titlePicturesExpandableContainer?.expand();
    } else {
      this.titlePicturesExpandableContainer?.collapse();
    }
  }

  private updateValueCalculationResultAdapter(): void {
    this.valueCalculationResultAdapter = null;
    if (this.project) {
      this.valueCalculationResultAdapter =
        new ProjectValueCalculationResultAdapter({
          entityManager: this.entityManager,
          subscriptionManagerService: this.subscriptionManagerService,
          baseEntity: this.project
        });
    }
  }
}
