import { UserGroupSubEntity } from '../Base/UserGroupSubEntity';
import { OptionalProperties } from '../../utilities';
import { DateType, IdType } from '../Base/types';
import { BaseEntity } from '../Base/BaseEntity';
import { DialogIconType } from '../../../Enums/DialogIconType';

type FullQuestion<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  UserGroupSubEntity<TId> & {
    text: string;
    choices: Array<QuestionChoice>;
    /**
     * Whether to create this question in new project question sets.
     *
     * If false, this question will not show up in a new question set,
     * and can only be added through custom logic (see `QuestionChoiceAction`)
     */
    copy: boolean;
    questionCategoryId: TId | null;
    questionSetId: TId;
    units: Array<QuestionUnit>;
    targetGroups: Array<QuestionTargetGroup>;
    recommendationTemplates: Array<RecommendationTemplate>;
  };

export type QuestionChoice = {
  choiceText: string;
  actions: Array<QuestionChoiceAction>;
};

export type QuestionChoiceAction = {
  actionType: QuestionChoiceActionType;
  questionId: string | null;
  questionSetId: string | null;
  redirectButtonText: string | null;
  redirectUrl: string | null;
  dialogTitle: string | null;
  dialogText: string | null;
  dialogIconType: DialogIconType | null;
};

export enum QuestionChoiceActionType {
  /**
   * Adds a specific question to the current inspection.
   */
  ADD_QUESTION = 'addQuestion',
  /**
   * Adds a specific question set to the current inspection.
   */
  ADD_QUESTION_SET = 'addQuestionSet',
  /**
   * Adds a button to the question category that redirects to a specific URL.
   */
  REDIRECT = 'redirect',
  /**
   * Opens a dialog with a specific title and text.
   */
  OPEN_DIALOG = 'openDialog'
}

/**
 * An additional property that may be specified for certain questions.
 *
 * Use cases include useful additional measurements. For example, if the
 * question is "Is the elevator suitable for wheelchairs?", the question
 * may use a unit for specifying exact width ("Width [cm]").
 */
export type QuestionUnit = {
  title: string;
  unit: string;
};

/**
 * Predefined recommendations for an answer that can be quickly selected
 * by the user when answering questions.
 */
export type RecommendationTemplate = {
  content: string;
};

/**
 * The groups of people this question is aimed at ("hearing impaired", "blind", etc.).
 */
export type QuestionTargetGroup = {
  name: string;
};

export type QuestionDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullQuestion<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullQuestion<TId, TDate> = never
> = Omit<
  OptionalProperties<FullQuestion<TId, TDate>, TOptionalProperties>,
  TPropertiesToRemove
>;
