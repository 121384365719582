import { autoinject, bindable } from 'aurelia-framework';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { TitleThingPicture } from '../../classes/EntityManager/entities/Picture/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { PictureSelectAndEditDialog } from '../../dialogs/picture-select-and-edit-dialog/picture-select-and-edit-dialog';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { EditThingPersonRelationsDialog } from '../../dialogs/edit-thing-person-relations-dialog/edit-thing-person-relations-dialog';
import { EditThingPropertiesDialog } from '../../dialogs/edit-thing-properties-dialog/edit-thing-properties-dialog';
import { ThingProperty } from '../../classes/EntityManager/entities/Property/types';
import { ProjectCreationService } from '../../classes/EntityManager/entities/Project/ProjectCreationService';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { ChecklistProjectNameUtils } from 'common/Checklist/ChecklistProjectNameUtils';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EnterProjectClickedEvent } from '../checklist-project-list-item/checklist-project-list-item';
import { CreateChecklistEntitiesService } from '../../services/CreateChecklistEntitiesService';
import { QuestionCatalogueService } from '../../classes/EntityManager/entities/QuestionCatalogue/QuestionCatalogueService';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Dialogs } from '../../classes/Dialogs';
import { ChecklistStartInspectionDialog } from '../../dialogs/checklist-start-inspection-dialog/checklist-start-inspection-dialog';
import { ChecklistExportInspectionDialog } from '../../dialogs/checklist-export-inspection-dialog/checklist-export-inspection-dialog';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { ProjectReport } from '../../classes/EntityManager/entities/Report/types';

@autoinject()
export class ChecklistThingOverview {
  @bindable public thing: Thing | null = null;

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly projectCreationService: ProjectCreationService,
    private readonly createChecklistEntitiesService: CreateChecklistEntitiesService,
    private readonly questionCatalogueService: QuestionCatalogueService,
    private readonly i18n: I18N,
    private readonly router: Router,
    permissionsService: PermissionsService
  ) {
    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Thing,
        context: this as ChecklistThingOverview,
        propertyName: 'thing'
      });
  }

  @computed(expression('thing'), model(EntityName.Picture))
  protected get titlePictures(): Array<TitleThingPicture> {
    if (!this.thing) return [];

    return this.entityManager.pictureRepository.getByTitleThingId(
      this.thing.id
    );
  }

  @computed(expression('titlePictures'))
  protected get mainTitlePicture(): TitleThingPicture | null {
    if (this.titlePictures.length === 0) return null;

    return (
      this.titlePictures.find((p) => p.selected) ??
      this.titlePictures[0] ??
      null
    );
  }

  @computed(
    expression('thing'),
    model(EntityName.Person),
    model(EntityName.ThingToPerson)
  )
  protected get mainContact(): Person | null {
    if (!this.thing) return null;
    const thingToPerson =
      this.entityManager.thingToPersonRepository.getMainContactOrFallbackForThingId(
        this.thing.id
      );
    if (!thingToPerson) return null;
    return this.entityManager.personRepository.getById(thingToPerson.personId);
  }

  @computed(expression('thing'), model(EntityName.Property))
  protected get thingProperties(): Array<ThingProperty> {
    if (!this.thing) return [];

    return this.entityManager.propertyRepository.getByThingId(this.thing.id);
  }

  @computed(expression('thing.id'), model(EntityName.Project))
  protected get projectsOfThing(): Array<Project> {
    if (!this.thing) return [];
    return this.entityManager.projectRepository.getByThingId(this.thing.id);
  }

  @computed(expression('projectsOfThing'), model(EntityName.Report))
  protected get reports(): Array<ProjectReport> {
    return this.entityManager.reportRepository.getByProjectIds(
      this.projectsOfThing.map((p) => p.id)
    );
  }

  protected handleMainTitlePictureClicked(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleMainTitlePictureClicked without thing'
    );

    void PictureSelectAndEditDialog.open({
      canCreatePictures: true,
      mainEntityIdField: 'ownerThingId',
      mainEntityId: this.thing.id,
      subEntityField: 'titleThingId',
      subEntityValue: this.thing.id,
      ownerUserGroupId: this.thing.ownerUserGroupId
    });
  }

  protected handleObjectPropertiesEditButtonClicked(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleObjectPropertiesEditButtonClicked without thing'
    );

    void EditThingPropertiesDialog.open({
      thing: this.thing
    });
  }

  protected handleMainContactEditButtonClicked(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleMainContactEditButtonClicked without thing'
    );

    void EditThingPersonRelationsDialog.open({
      thing: this.thing
    });
  }

  protected handleCreateProjectButtonClicked(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleCreateProjectButtonClicked without thing'
    );

    this.projectCreationService.createProject({
      thing: this.thing,
      name: ChecklistProjectNameUtils.fromDate(new Date()),
      projectType: ProjectType.CHECKLIST,
      reportType: null
    });
  }

  protected handleProjectClicked(event: EnterProjectClickedEvent): void {
    if (
      this.createChecklistEntitiesService.hasChecklistData(event.detail.project)
    ) {
      this.router.navigateToRoute('checklist_inspection', {
        project_id: event.detail.project.id
      });
    } else {
      void ChecklistStartInspectionDialog.open({
        project: event.detail.project,
        onAcceptButtonClicked: () => {
          const questionCatalogues = this.questionCatalogueService.getByThingId(
            event.detail.project.thing
          );
          try {
            this.createChecklistEntitiesService.create(
              event.detail.project,
              questionCatalogues
            );
          } catch (e) {
            const message = e instanceof Error ? e.message : `${e}`;
            void Dialogs.errorDialog(
              this.i18n.tr(
                'checklistComponents.checklistProjectListItem.errors.entityCreation'
              ),
              message
            );
          }
          this.router.navigateToRoute('checklist_inspection', {
            project_id: event.detail.project.id
          });
        }
      });
    }
  }

  protected handleExportInspectionClicked(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleExportInspectionClicked without thing'
    );

    void ChecklistExportInspectionDialog.open({
      thing: this.thing
    });
  }
}
