import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { DateUtils } from '../../../DateUtils';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { EntryDto } from './EntryDto';

export function createEntryEntityInfo<TEntity extends EntryDto<string, string>>(
  options: CreateEntityInfoOptions<EntryDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.Entry,
  TEntity
> {
  return {
    entityName: EntityName.Entry,
    synchronizeRelativeTo: [EntityName.Project],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      EntryDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createBaseFieldInfos(),
        originId: {
          name: 'originId',
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        page_depth_parent: {
          name: 'page_depth_parent',
          defaultValue: null,
          ref: EntityName.Entry
        },
        list_position: {
          name: 'list_position',
          defaultValue: 0
        },
        importnotes: {
          name: 'importnotes',
          defaultValue: null
        },
        note: {
          name: 'note',
          defaultValue: null
        },
        exclude_from_export: {
          name: 'exclude_from_export',
          defaultValue: false
        },
        group_name: {
          name: 'group_name',
          defaultValue: null
        },
        created: {
          name: 'created',
          defaultValue: DateUtils.getCurrentTimestamp.bind(DateUtils)
        },
        project: {
          name: 'project',
          ref: EntityName.Project
        },
        regionId: {
          name: 'regionId',
          defaultValue: null,
          ref: EntityName.Region
        },
        structureTemplateEntryId: {
          name: 'structureTemplateEntryId',
          defaultValue: null,
          ref: EntityName.StructureTemplateEntry
        },
        customId: {
          name: 'customId',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
