import _ from 'lodash';

import { autoinject, observable, computedFrom } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { SorterSortOption } from '../../aureliaAttributes/sorter';
import { CreateEntityClickedEvent } from '../../aureliaComponents/create-entity-button/create-entity-button';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { FilterHelper } from '../../classes/FilterHelper';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { Dialogs } from '../../classes/Dialogs';
import { ActionClickedEvent } from '../../aureliaComponents/selectable-item-list/selectable-item-list';
import { GlobalMenu } from '../../aureliaComponents/global-menu/global-menu';
import { SocketService } from '../../services/SocketService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import {
  ImportFromCsvFileDialog,
  CallbackParamsWithUserGroup
} from '../../dialogs/import-from-csv-file-dialog/import-from-csv-file-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  TextBrickTemplate,
  TextBrickTemplateCreationEntity
} from '../../classes/EntityManager/entities/TextBrickTemplate/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { FieldType } from '../../aureliaComponents/csv-import-widget/csv-import-widget';
import { FileDownloadService } from '../../services/FileDownloadService';
import { EntitiesWithPermissionHandle } from '../../services/PermissionsService/EntitiesWithPermissionHandle/EntitiesWithPermissionHandle';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntitiesPermissionChecker } from '../../services/PermissionsService/EntitiesPermissionChecker/EntitiesPermissionChecker';
import { arrayChanges, expression } from '../../hooks/dependencies';
import { computed } from '../../hooks/computed';

@autoinject()
export class EditTextBrickTemplates {
  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  private readonly userGroupsWhereTextBrickTemplatesCanBeCreatedHandle: EntitiesWithPermissionHandle<EntityName.UserGroup>;

  @subscribableLifecycle()
  private readonly textBrickTemplatesPermissionChecker: EntitiesPermissionChecker<EntityName.TextBrickTemplate>;

  protected availableTextBrickTemplates: Array<TextBrickTemplate> = [];
  protected filteredTextBrickTemplates: Array<TextBrickTemplate> = [];
  protected userGroupFilteredTextBrickTemplates: Array<TextBrickTemplate> = [];

  protected selectedTextBrickTemplates: Array<TextBrickTemplate> = [];

  protected isMobile = false;
  protected isOnline = false;

  protected readonly sortOptions: Record<
    string,
    SorterSortOption<TextBrickTemplate>
  > = {
    path: {
      name: 'path',
      sortFunction: (tBT1, tBT2) => {
        const path1 = tBT1.path.join(' 🢒 ');
        const path2 = tBT2.path.join(' 🢒 ');
        return path1.localeCompare(path2);
      }
    }
  };

  protected currentSortOption = this.sortOptions.path;
  protected sortedTextBrickTemplates: Array<TextBrickTemplate> = [];

  @observable protected textBrickTemplateFilterString = '';

  protected readonly textBrickTemplateSelectionMoreButtonChoices: Array<MoreButtonChoice> =
    [
      {
        labelTk: 'general.delete',
        name: 'delete-text-brick-templates',
        iconClass: 'fal fa-trash-alt',
        disabledContext: this,
        disabledPropertyName: 'cannotDeleteSelectedTextBrickTemplates'
      }
    ];

  protected readonly moreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk:
        'generalPages.editTextBrickTemplates.exportTextBrickTemplatesAsCsvFile',
      name: 'export-text-brick-templates-as-csv',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'cannotExportTextBrickTemplatesAsCsvFile'
    },
    {
      labelTk:
        'generalPages.editTextBrickTemplates.importTextBrickTemplatesFromCsvFile',
      name: 'import-text-brick-templates-from-csv',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'cannotImportTextBrickTemplatesFromCsvFile',
      isFileInput: true,
      fileInputAccept: 'text/plain,text/comma-separated-values,.csv'
    }
  ];

  protected userGroupIdFromFilter: string | null = null;
  protected readonly EntityName = EntityName;

  constructor(
    private readonly fileDownloadService: FileDownloadService,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private socketService: SocketService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.userGroupsWhereTextBrickTemplatesCanBeCreatedHandle =
      permissionsService.getEntitiesWithPermissionHandleForPermissionName({
        entityName: EntityName.UserGroup,
        permissionName: 'canCreateTextBrickTemplates'
      });

    this.textBrickTemplatesPermissionChecker =
      permissionsService.getEntitiesPermissionChecker({
        entityName: EntityName.TextBrickTemplate
      });
  }

  // Aurelia Lifecycle

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    GlobalMenu.takeControl(this, {
      visible: this.isMobile,
      choices: this.moreButtonChoices,
      selectedCallbacks: {
        'export-text-brick-templates-as-csv':
          this.handleExportTextBrickTemplatesAsCsvFileClick.bind(this)
      },
      fileChangedCallbacks: {
        'import-text-brick-templates-from-csv': (event) => {
          void this.handleImportTextBrickTemplatesFromCsvFileChanged(event);
        }
      }
    });

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      () => {
        this.updateUserGroups();
      }
    );
    this.updateUserGroups();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.TextBrickTemplate,
      this.updateTextBrickTemplates.bind(this)
    );
    this.updateTextBrickTemplates();
  }

  protected detached(): void {
    GlobalMenu.releaseControl(this);
  }

  // Aurelia Change Handlers

  protected textBrickTemplateFilterStringChanged(): void {
    this.updateFilteredTextBrickTemplates();
  }

  protected isMobileChanged(): void {
    if (GlobalMenu.hasControl(this)) GlobalMenu.setVisible(this, this.isMobile);
  }

  // Entity Updaters

  private updateUserGroups(): void {
    this.userGroupsWhereTextBrickTemplatesCanBeCreatedHandle.setEntities(
      this.entityManager.userGroupRepository.getAll()
    );
  }

  private updateTextBrickTemplates(): void {
    this.availableTextBrickTemplates =
      this.entityManager.textBrickTemplateRepository.getAll();
    this.updateFilteredTextBrickTemplates();
  }

  // Getters

  @computed(
    arrayChanges('selectedTextBrickTemplates'),
    expression('textBrickTemplatesPermissionChecker.revision')
  )
  protected get cannotDeleteSelectedTextBrickTemplates(): boolean {
    return !this.textBrickTemplatesPermissionChecker.allEntitiesHavePermission({
      entities: this.selectedTextBrickTemplates,
      checkPermission: ({ adapter, entity }) => {
        return adapter.canDeleteEntity(entity);
      }
    });
  }

  @computedFrom('isOnline')
  protected get cannotExportTextBrickTemplatesAsCsvFile(): boolean {
    return !this.isOnline;
  }

  @computedFrom(
    'userGroupsWhereTextBrickTemplatesCanBeCreatedHandle.filteredEntities.length'
  )
  protected get cannotImportTextBrickTemplatesFromCsvFile(): boolean {
    return !this.userGroupsWhereTextBrickTemplatesCanBeCreatedHandle
      .filteredEntities.length;
  }

  // Handlers

  protected handleCreateNewTextBrickTemplateClicked(
    event: CreateEntityClickedEvent
  ): void {
    const userGroup = event.detail.userGroup;
    assertNotNullOrUndefined(
      userGroup,
      'cannot create new text brick template without a user group'
    );

    this.entityManager.textBrickTemplateRepository.create({
      ownerUserGroupId: userGroup.id,
      path: []
    });
  }

  protected async handleDeleteSelectedTextBrickTemplates(
    event: ActionClickedEvent<TextBrickTemplate>
  ): Promise<void> {
    await Dialogs.deleteEntitiesDialog({
      count: event.detail.selectedItems.length,
      entityName: EntityName.TextBrickTemplate
    });
    event.detail.selectedItems.forEach((tBT) =>
      this.entityManager.textBrickTemplateRepository.delete(tBT)
    );
  }

  protected handleExportTextBrickTemplatesAsCsvFileClick(): void {
    assertNotNullOrUndefined(
      this.userGroupIdFromFilter,
      'cannot export text brick templates without a user group id'
    );
    this.socketService.exportTextBrickTemplatesAsCsvFile(
      {
        userGroupId: this.userGroupIdFromFilter
      },
      (response) => {
        if (response.success) {
          void this.fileDownloadService.downloadFileByToken(response.token);
        } else {
          const errorMessageKey = `serverResponses.${response.status}`;
          void Dialogs.errorDialogTk('general.downloadError', errorMessageKey);
        }
      }
    );
  }

  protected async handleImportTextBrickTemplatesFromCsvFileChanged(
    event: Event
  ): Promise<void> {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement?.files?.[0];
    if (!file) return;

    await ImportFromCsvFileDialog.open<FieldInfoConfiguration>({
      entityNameTk: 'models.TextBrickTemplateModel_plural',
      file: file,
      fields: [
        {
          field: 'value',
          header: 'Text',
          type: FieldType.STRING
        }
      ],
      editableUserGroups:
        this.userGroupsWhereTextBrickTemplatesCanBeCreatedHandle
          .filteredEntities,
      showOverwriteCheckbox: false,
      showUserGroupSelect: true,
      showAdditionalFieldsAsParametersCheckbox: false,
      importFromCsvFileCallback:
        this.handleImportTextBrickTemplatesFromCsvFileSubmitted.bind(this)
    });

    inputElement.value = '';
  }

  // Methods

  private updateFilteredTextBrickTemplates(): void {
    this.filteredTextBrickTemplates = FilterHelper.filterItems(
      this.availableTextBrickTemplates,
      (tBT) => tBT.path.join(' ') + ' ' + tBT.value,
      this.textBrickTemplateFilterString
    );
  }

  private async handleImportTextBrickTemplatesFromCsvFileSubmitted(
    detail: CallbackParamsWithUserGroup<FieldInfoConfiguration>
  ): Promise<void> {
    const lineData = detail.parsedContent;
    for (const lD of lineData) {
      const sortedAdditionalFieldKeys = _.sortBy(_.keys(lD.additionalFields));

      const pathFromFields = sortedAdditionalFieldKeys.map(
        (key) => lD.additionalFields[key]
      );
      while (pathFromFields[pathFromFields.length - 1] === '') {
        pathFromFields.pop();
      }

      const newPath = pathFromFields.map((p) => p || '*');

      const newTextBrickTemplate: TextBrickTemplateCreationEntity = {
        ownerUserGroupId: detail.userGroupId,
        path: newPath,
        value: lD.fields.value
      };

      const existingTextBrickTemplate =
        this.entityManager.textBrickTemplateRepository
          .getByUserGroupIdAndPath(detail.userGroupId, newPath)
          .find((tBT) => tBT.value === newTextBrickTemplate.value);
      if (existingTextBrickTemplate) continue;

      this.entityManager.textBrickTemplateRepository.create(
        newTextBrickTemplate
      );
    }
  }
}

type FieldInfoConfiguration = {
  entityType: TextBrickTemplate;
  fieldInfos: [
    {
      field: 'value';
      type: FieldType.STRING;
    }
  ];
};
