import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { createProcessConfigurationNoteCategoryEntityInfo } from 'common/Types/Entities/ProcessConfigurationNoteCategory/createProcessConfigurationNoteCategoryEntityInfo';
import { ProcessConfigurationNoteCategory } from './types';

export const processConfigurationNoteCategoryEntityInfo =
  createProcessConfigurationNoteCategoryEntityInfo<ProcessConfigurationNoteCategory>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
