import { bindable } from 'aurelia-framework';

import { ScrollHelper } from '../../classes/ScrollHelper';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { UiUpdater } from '../../classes/UiUpdater';
import { RecordItHeader } from '../record-it-header/record-it-header';
import { SiteScrollLocker } from '../../classes/SiteScrollLocker';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';

/**
 * this element has to be in the same container (with position relative) as the entry elements which are returned by the entryElementGetter
 * also there shouldn't be any container with a position css property between the entry element and the container
 *
 * e.g.
 *  <div class="EntryListWrapper" style="position: relative">
 *    <entry-list-item repeat.for="entry of _entries" ...></entry-list-item>
 *    <edit-entry-widget-overlay ...></edit-entry-widget-overlay>
 *  </div>
 *
 *
 * @event entry-changed - look at the edit-entry-widget -> entry-changed event to see when this event is fired
 * detail: {entry: Entry}
 *
 * @event overlay-closed - fired when the overlay has been completely closed
 * detail: {entry: Entry}
 */
export class EditEntryWidgetOverlay {
  @bindable public project: Project | null = null;

  /**
   * a functions which retrieves the reference element for an entry
   */
  @bindable public entryElementGetter: ((entry: Entry) => HTMLElement) | null =
    null;

  @bindable public entryNavigationEnabled = true;
  @bindable public createSubEntriesEnabled = true;

  @bindable public structure: StructureTemplate | null = null;

  protected entryToEdit: Entry | null = null;

  protected editorHeight = 0;

  protected editorTopOffset = 0;

  protected referenceElement: HTMLElement | null = null;

  protected editorVisible = false;

  protected active = false;

  protected entryEditingAnimationDuration = 450;

  protected scrollToTopOffset = 10;

  protected domElement: HTMLElement;

  protected editEntryWrapperElement: HTMLElement | null = null;

  protected editEntryWrapperElementAnimator: ShowHideAnimator | null = null;

  protected completelyOpened = false;

  constructor(domElement: Element) {
    this.domElement = domElement as HTMLElement;
  }

  protected bind(): void {
    assertNotNullOrUndefined(
      this.editEntryWrapperElement,
      "can't register ShowHideAnimator without editEntryWrapperElement"
    );
    this.editEntryWrapperElementAnimator = new ShowHideAnimator(
      this.editEntryWrapperElement
    );
  }

  protected attached(): void {
    UiUpdater.registerResizeUpdateFunction(this.boundHandleResize);
  }

  protected detached(): void {
    SiteScrollLocker.unlockScrolling('edit-entry-widget-overlay');
    UiUpdater.unregisterResizeUpdateFunction(this.boundHandleResize);
  }

  public startEditingEntry(entry: Entry, animate = false): void {
    this.entryToEdit = entry;
    this.editorHeight = this.calculateEditorHeight();
    this.editorVisible = true;
    this.active = true;
    this.completelyOpened = false;

    // when we expand our scroll spacer (so we can scroll down far enough), we don't want the brwoser to automatically stick to the bottom
    const scrollTop = ScrollHelper.getMainScrollingElement().scrollTop;
    setTimeout(() => {
      void ScrollHelper.scrollToPosition(scrollTop, 0);

      // wait for the main element to be visible, or else the offsetParent will be null (because an element with display none has no offsetParent)
      this.updateReferenceElementAndEditorTopOffset(entry);

      if (this.referenceElement) {
        const animationDuration = animate
          ? this.entryEditingAnimationDuration
          : 0;
        void ScrollHelper.scrollToItem(
          this.referenceElement,
          animationDuration,
          {
            topOffset: this.scrollToTopOffset
          }
        );
      }
    }, 0); // wait for entry-widget-overlay to be completely expanded so we can actually scroll down

    if (this.editEntryWrapperElementAnimator) {
      void this.editEntryWrapperElementAnimator
        .fadeSlideDown(this.editorHeight)
        .then(() => {
          // reset the height from the animation and let the editEntryWrapperElement scale freely now
          if (this.editEntryWrapperElement) {
            this.editEntryWrapperElement.style.height = '';
          }

          this.completelyOpened = true;
        });
    }
    SiteScrollLocker.lockScrolling('edit-entry-widget-overlay');
  }

  /**
   * @param animate - animate the hiding of the editor
   */
  public stopEditingEntry(animate: boolean): void {
    assertNotNullOrUndefined(
      this.editEntryWrapperElementAnimator,
      "can't stop editing entry without an animator."
    );

    this.active = false;

    const animationDuration = animate ? this.entryEditingAnimationDuration : 0;
    this.editEntryWrapperElementAnimator.setDefaultAnimationDuration(
      animationDuration
    );
    this.scrollToReferenceElementCentered(animationDuration);

    void this.editEntryWrapperElementAnimator.fadeSlideUp().then(() => {
      SiteScrollLocker.unlockScrolling('edit-entry-widget-overlay');

      if (this.domElement) {
        DomEventHelper.fireEvent(this.domElement, {
          name: 'overlay-closed',
          detail: { entry: this.entryToEdit }
        });
      }

      this.editorVisible = false;
      this.entryToEdit = null;
      this.referenceElement = null;
    });
  }

  public isActive(): boolean {
    return this.active;
  }

  protected handleEditEntryWidgetCloseClicked(): void {
    this.stopEditingEntry(true);
  }

  protected handleEditEntryWidgetEntryChanged(): void {
    if (this.domElement) {
      DomEventHelper.fireEvent(this.domElement, {
        name: 'entry-changed',
        detail: { entry: this.entryToEdit }
      });
    }

    setTimeout(() => {
      if (this.entryToEdit) {
        this.updateReferenceElementAndEditorTopOffset(this.entryToEdit);
      }
      this.scrollToReferenceElement();
    }, 10); // give the parent view time to update their entry elements
  }

  protected handleResize(): void {
    if (this.active) {
      this.editorHeight = this.calculateEditorHeight();
      this.scrollToReferenceElement();
    }
  }

  private boundHandleResize = this.handleResize.bind(this);

  private scrollToReferenceElementCentered(animationDuration: number): void {
    if (!this.referenceElement) {
      return;
    }

    const scrollTop = ScrollHelper.getCenteredScrollTopForElement(
      this.referenceElement
    );
    const scrollElement = ScrollHelper.getMainScrollingElement();
    const maxScrollTop = Math.max(
      scrollElement.scrollHeight -
        this.domElement.clientHeight -
        scrollElement.clientHeight,
      0
    );
    void ScrollHelper.scrollToPosition(
      Math.min(scrollTop, maxScrollTop),
      animationDuration
    );
  }

  /**
   * only call this when the domElement already has display: block
   */
  private updateReferenceElementAndEditorTopOffset(entry: Entry): void {
    const referenceElement = this.entryElementGetter
      ? this.entryElementGetter(entry)
      : null;

    if (referenceElement) {
      this.referenceElement = referenceElement;
      this.editorTopOffset = referenceElement.offsetTop;
    } else {
      this.referenceElement = this.domElement.offsetParent as HTMLElement;
      this.editorTopOffset = 0;
    }
  }

  private scrollToReferenceElement(): void {
    if (this.referenceElement) {
      void ScrollHelper.scrollToItem(this.referenceElement, 0, {
        topOffset: this.scrollToTopOffset
      });
    }
  }

  private calculateEditorHeight(): number {
    const headerHeight = RecordItHeader.getHeaderHeight() || 0;
    return document.documentElement.clientHeight - headerHeight;
  }
}
