import { AppEntityRepository } from '../../base/AppEntityRepository';
import { BaseEntity } from '../BaseEntity';
import { Project } from '../Project/types';
import { Question } from '../Question/types';
import { EntityName } from '../types';
import { ProjectQuestion } from './types';

export class ProjectQuestionRepository extends AppEntityRepository<EntityName.ProjectQuestion> {
  public createFromQuestion(
    question: Pick<
      Question,
      | 'id'
      | 'text'
      | 'choices'
      | 'ownerUserGroupId'
      | 'units'
      | 'targetGroups'
      | 'recommendationTemplates'
    >,
    params: FromQuestionCreationParameters,
    options?: Pick<Partial<BaseEntity>, 'shadowEntity' | 'temporaryGroupName'>
  ): ProjectQuestion {
    return this.create({
      text: question.text,
      choices: question.choices,
      ownerUserGroupId: params.project.ownerUserGroupId,
      units: question.units.map((u) => ({ ...u, value: '' })),
      targetGroups: question.targetGroups,
      recommendationTemplates: question.recommendationTemplates,
      physicalArea: params.physicalArea ?? null,
      projectQuestionSetId: params.projectQuestionSetId,
      projectQuestionCategoryId: params.projectQuestionCategoryId,
      ownerProjectId: params.project.id,
      createdFromQuestionId: question.id,
      temporaryGroupName: options?.temporaryGroupName,
      shadowEntity: options?.shadowEntity
    });
  }

  public getByProjectQuestionCategoryIds(
    ids: Array<string>
  ): Array<ProjectQuestion> {
    return this.getAll().filter(
      (e) =>
        e.projectQuestionCategoryId && ids.includes(e.projectQuestionCategoryId)
    );
  }

  public getByProjectQuestionCategoryId(id: string): Array<ProjectQuestion> {
    return this.getByProjectQuestionCategoryIds([id]);
  }

  public getByProjectQuestionSetIds(
    ids: Array<string>
  ): Array<ProjectQuestion> {
    return this.getAll().filter(
      (e) => e.projectQuestionSetId && ids.includes(e.projectQuestionSetId)
    );
  }

  public getByProjectQuestionSetId(id: string): Array<ProjectQuestion> {
    return this.getByProjectQuestionSetIds([id]);
  }
}

export type FromQuestionCreationParameters = Pick<
  ProjectQuestion,
  'projectQuestionSetId' | 'projectQuestionCategoryId'
> & {
  project: Pick<Project, 'id' | 'ownerUserGroupId'>;
  physicalArea?: string | null;
};
