import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ColumnConfig } from '../../aureliaComponents/edit-structure-table-widget/edit-structure-table-widget';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntryUtils } from '../../classes/EntityManager/entities/Entry/EntryUtils';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { Property } from '../../classes/EntityManager/entities/Property/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { configureHooks } from '../../hooks/configureHooks';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditTableEntryDialog {
  public static async open(
    options: EditTableEntryDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  @subscribableLifecycle()
  protected entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  private entry: Entry | null = null;
  protected columnConfigs: Array<ColumnConfig> = [];

  protected dialog: RecordItDialog | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService
  ) {
    this.entryPermissionsHandle =
      this.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Entry,
        entity: null
      });
  }

  public open(options: EditTableEntryDialogOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't EditTableEntryDialog.open without a dialog"
    );

    this.entry = options.entry;
    this.columnConfigs = options.columnConfigs;

    this.entryPermissionsHandle =
      this.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Entry,
        entity: options.entry
      });

    this.dialog.open();
  }

  public close(): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't EditTableEntryDialog.close without a dialog"
    );
    this.dialog.close();
  }

  protected handleDialogClosed(): void {
    this.entry = null;
    this.columnConfigs = [];
  }

  protected getPropertyOfEntryByName(
    entry: Entry | null,
    propertyName: string
  ): Property | null {
    if (!entry) {
      return null;
    }

    const properties = this.entityManager.propertyRepository.getByEntryId(
      entry.id
    );
    return properties.find((prop) => prop.name === propertyName) ?? null;
  }

  protected getIndexForEntry(entry: Entry | null): string {
    if (!entry) {
      return '';
    }

    return EntryUtils.getPageDepthIndex({
      path: this.entityManager.entryRepository.getPathByEntryId(entry.id)
    });
  }

  protected getProjectById(projectId: string): Project | null {
    return this.entityManager.projectRepository.getById(projectId);
  }

  protected handleEntryChanged(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't EditTableEntryDialog.handleEntryChanged without an entry"
    );
    this.entityManager.entryRepository.update(this.entry);
  }
}

export type EditTableEntryDialogOptions = {
  entry: Entry;
  columnConfigs: Array<ColumnConfig>;
};
