import { autoinject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';
import { SubscriptionManager } from '../SubscriptionManager';
import { Thing } from '../EntityManager/entities/Thing/types';
import { GalleryThingPictureOverviewEntry } from './GalleryThingPictureOverviewEntryHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { EditDefectPicturesDialog } from '../../dialogs/edit-defect-pictures-dialog/edit-defect-pictures-dialog';
import { Dialogs } from '../Dialogs';
import { GalleryThingEditEntryDialog } from '../../galleryThing/gallery-thing-edit-entry-dialog/gallery-thing-edit-entry-dialog';
import { Type } from '../../aureliaComponents/ultra-rapid-fire-widget/ultra-rapid-fire-widget';
import { UltraRapidFireWidgetEditDialog } from '../../aureliaComponents/ultra-rapid-fire-widget-edit-dialog/ultra-rapid-fire-widget-edit-dialog';
import { ActiveUserCompanySettingService } from '../EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import {
  JoinedProjectHandle,
  ProjectStatus
} from '../../galleryThing/gallery-thing-picture-overview/JoinedProjectHandle';
import { Project } from '../EntityManager/entities/Project/types';
import { SocketService } from '../../services/SocketService';

export class GalleryThingPictureClickHandler {
  private projectHandleSubscriptionManager: SubscriptionManager | null = null;

  private useUltraRapidFireWidget = false;
  private thing: Thing | null = null;

  private readonly entityManager: AppEntityManager;
  private readonly socketService: SocketService;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly activeUserCompanySettingService: ActiveUserCompanySettingService;
  private readonly onProjectJoinedChanged: () => void;

  constructor({
    entityManager,
    socketService,
    subscriptionManagerService,
    activeUserCompanySettingService,
    onProjectJoinedChanged
  }: Options) {
    this.entityManager = entityManager;
    this.socketService = socketService;
    this.subscriptionManagerService = subscriptionManagerService;
    this.activeUserCompanySettingService = activeUserCompanySettingService;
    this.onProjectJoinedChanged = onProjectJoinedChanged;
  }

  public subscribe(): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();
    this.projectHandleSubscriptionManager =
      this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'ultraRapidFireWidget.useUltraRapidFireWidget',
        (useUltraRapidFireWidget) => {
          this.useUltraRapidFireWidget = useUltraRapidFireWidget;
        }
      )
    );
    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
        this.projectHandleSubscriptionManager?.disposeSubscriptions();
      }
    };
  }

  public setThing(thing: Thing | null): void {
    this.thing = thing;
  }

  public handlePictureClicked(
    pictureOverviewEntry: GalleryThingPictureOverviewEntry
  ): void {
    if (pictureOverviewEntry.ownerDefectId) {
      this.editDefectPicture(pictureOverviewEntry);
    } else {
      this.joinProjectAndOpenEntryPicture(pictureOverviewEntry);
    }
  }

  private editDefectPicture(
    pictureOverviewEntry: GalleryThingPictureOverviewEntry
  ): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot open EditDefectPicturesDialog without a thing'
    );
    assertNotNullOrUndefined(
      pictureOverviewEntry.ownerDefectId,
      'cannot open EditDefectPicturesDialog for picture without a defect'
    );

    void EditDefectPicturesDialog.open({
      thingId: this.thing.id,
      defectId: pictureOverviewEntry.ownerDefectId,
      selectedPicture: this.entityManager.pictureRepository.getById(
        pictureOverviewEntry.pictureId
      )
    });
  }

  private editEntryPicture(
    pictureOverviewEntry: GalleryThingPictureOverviewEntry
  ): void {
    if (!this.thing) return;

    const entry = pictureOverviewEntry.entryId
      ? this.entityManager.entryRepository.getById(pictureOverviewEntry.entryId)
      : null;

    const picture = this.entityManager.pictureRepository.getById(
      pictureOverviewEntry.pictureId
    );

    if (entry && picture) {
      if (this.useUltraRapidFireWidget) {
        void UltraRapidFireWidgetEditDialog.open({
          type: Type.GALLERY_THING,
          thingId: this.thing.id,
          userGroupId: this.thing.ownerUserGroupId,
          picture
        });
      } else {
        void GalleryThingEditEntryDialog.open({
          entry: entry,
          thingId: this.thing.id,
          userGroupId: this.thing.ownerUserGroupId
        });
      }
    } else {
      void Dialogs.errorDialogTk(
        'galleryThing.pictureOverview.entryNotAvailable'
      );
      throw new Error(
        `entry ${pictureOverviewEntry.entryId} of galleryThing project ${pictureOverviewEntry.projectId} not available.`
      );
    }
  }

  private joinProjectAndOpenEntryPicture(
    pictureOverviewEntry: GalleryThingPictureOverviewEntry
  ): void {
    assertNotNullOrUndefined(
      pictureOverviewEntry.projectId,
      'cannot join project without id'
    );
    assertNotNullOrUndefined(
      this.projectHandleSubscriptionManager,
      'cannot join project without projectHandleSubscriptionManager'
    );

    const project = this.entityManager.projectRepository.getRequiredById(
      pictureOverviewEntry.projectId
    );

    this.projectHandleSubscriptionManager.disposeSubscriptions();
    const projectHandle = this.createJoinedProjectHandle(
      project,
      pictureOverviewEntry
    );
    this.projectHandleSubscriptionManager.addDisposable(
      projectHandle.subscribe()
    );

    projectHandle.ensureProjectDataIsAvailable();
  }

  private createJoinedProjectHandle(
    project: Project,
    pictureOverviewEntry: GalleryThingPictureOverviewEntry
  ): JoinedProjectHandle {
    const handle = new JoinedProjectHandle({
      entityManager: this.entityManager,
      socketService: this.socketService,
      project,
      subscriptionManagerService: this.subscriptionManagerService,
      onStatusChanged: (state) => {
        try {
          switch (state) {
            case ProjectStatus.JOIN_REQUEST_SENT:
              Dialogs.waitOrCancelDialogTk(
                'galleryThing.pictureOverview.waitingForJoining'
              );
              break;
            case ProjectStatus.IS_JOINED:
              this.onProjectJoinedChanged();
              Dialogs.waitOrCancelDialogTk(
                'galleryThing.pictureOverview.waitingForActualizing'
              );
              break;
            case ProjectStatus.CLIENT_IS_OFFLINE:
            case ProjectStatus.LOCALLY_AVAILABLE:
              Dialogs.closeAllDialogs();
              this.editEntryPicture(pictureOverviewEntry);
              break;
            default:
              break;
          }
        } catch (error) {
          if (error instanceof Error && error.message === 'DialogCanceled') {
            handle.cancel();
          } else throw error;
        }
      }
    });
    return handle;
  }
}

type Options = {
  entityManager: AppEntityManager;
  socketService: SocketService;
  subscriptionManagerService: SubscriptionManagerService;
  activeUserCompanySettingService: ActiveUserCompanySettingService;
  onProjectJoinedChanged: () => void;
};
