import { PathUtils } from 'common/Utils/PathUtils/PathUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SharepointExpressionEditorScope } from 'common/ExpressionEditorScope/SpecificExpressionEditorScopes/Sharepoint/SharepointExpressionEditorScope';
import { ExprEvalParser } from 'common/ExprEvalParser/ExprEvalParser';
import { ExpressionEditorScopeEvaluationUtils } from 'common/ExpressionEditorScope/ExpressionEditorScopeEvaluationUtils';

import { AppEntityManager } from './EntityManager/entities/AppEntityManager';

export class SharepointHelper {
  public static async getSharepointExportUrlForThingId({
    entityManager,
    thingId
  }: {
    entityManager: AppEntityManager;
    thingId: string;
  }): Promise<string> {
    const thing = entityManager.thingRepository.getById(thingId);
    assertNotNullOrUndefined(thing, 'thing not found');

    const userGroup = entityManager.userGroupRepository.getRequiredById(
      thing.ownerUserGroupId
    );

    assertNotNullOrUndefined(
      userGroup.sharepointCredentials?.sharepointExportSite,
      'cannot get sharepoint url without a sharepoint export site'
    );

    const path = PathUtils.mergeOverlappingPaths(
      userGroup.sharepointCredentials.sharepointExportSite,
      userGroup.sharepointCredentials.sharepointExportPath ?? '',
      { caseInsensitive: true }
    );
    const scope = new SharepointExpressionEditorScope({
      getThing: async (id) => entityManager.thingRepository.getRequiredById(id),
      getProject: async (id) =>
        entityManager.projectRepository.getRequiredById(id)
    });

    const fieldConfigs =
      await scope.createFieldConfigsForSharepointExportSettingsScope({
        thingId,
        projectId: null
      });

    const exprEvalParser = new ExprEvalParser();

    return exprEvalParser.evaluateStringWithExpressions({
      template: path,
      context:
        await ExpressionEditorScopeEvaluationUtils.convertToPlainObject(
          fieldConfigs
        )
    });
  }
}
