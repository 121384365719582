import { NullCompareMode, SortUtils } from 'common/Utils/SortUtils';
import { AppEntityManager } from '../AppEntityManager';
import { Defect } from './types';
import { PictureCopyService } from '../Picture/PictureCopyService';
import { Picture } from '../Picture/types';
import { autoinject } from 'aurelia-framework';

@autoinject()
export class DefectPictureService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureCopyService: PictureCopyService
  ) {}

  /**
   * Every time a picture is created in a defect,
   * the picture should be copied to the parent thing
   * (if the parent thing does not have a main picture yet).
   *
   * @returns the picture if one was copied to the parent thing.
   *
   */
  public async ensureThingTitlePicture(
    defect: Defect
  ): Promise<Picture | null> {
    // Get the first created picture of the defect
    const firstDefectPicture = this.entityManager.pictureRepository
      .getByDefectId(defect.id)
      .sort((a, b) =>
        SortUtils.localeCompareFalsyStrings(
          a.takenAt ?? a.createdAt,
          b.takenAt ?? b.createdAt,
          NullCompareMode.FalsyToEnd
        )
      )
      .at(0);
    if (!firstDefectPicture) return null;

    // Abort if the parent thing already has a title picture
    const hasTitlePictures =
      this.entityManager.pictureRepository.getByTitleThingId(
        defect.ownerThingId
      ).length > 0;
    if (hasTitlePictures) return null;

    // Set the title picture of the parent thing
    const thing = this.entityManager.thingRepository.getById(
      defect.ownerThingId
    );
    if (!thing) return null;
    return this.pictureCopyService.copy({
      pictureToCopy: firstDefectPicture,
      createPicture: ({ baseData }) => {
        return this.entityManager.pictureRepository.create({
          ...baseData,
          ownerUserGroupId: thing.ownerUserGroupId,
          titleThingId: thing.id,
          ownerThingId: thing.id
        });
      }
    });
  }
}
