import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';

import { Dialogs } from '../../classes/Dialogs';
import { DateUtils } from '../../../../common/src/DateUtils';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { EditStructureTemplateDialog } from '../../dialogs/edit-structure-template-dialog/edit-structure-template-dialog';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { StructureTemplateCopyService } from '../../classes/EntityManager/entities/StructureTemplate/StructureTemplateCopyService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class StructureTemplateListItem {
  @bindable public structureTemplate: StructureTemplate | null = null;

  @subscribableLifecycle()
  protected readonly structureTemplatePermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplate];

  @subscribableLifecycle()
  protected readonly userGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  protected listItemElement: HTMLElement | null = null;

  private panelOpen = false;

  private domElement: HTMLElement;

  protected readonly DateUtils: DateUtils = DateUtils;
  protected readonly StringUtils = StringUtils;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly structureTemplateCopyService: StructureTemplateCopyService,
    permissionsService: PermissionsService
  ) {
    this.domElement = element as HTMLElement;

    this.structureTemplatePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.StructureTemplate,
        context: this as StructureTemplateListItem,
        propertyName: 'structureTemplate'
      });

    this.userGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForEntityIdOfPropertyValue({
        entityName: EntityName.UserGroup,
        context: this as StructureTemplateListItem,
        propertyName: 'structureTemplate',
        idPropertyName: 'ownerUserGroupId'
      });
  }

  @computedFrom('structureTemplate.status')
  protected get isDraftOrProvisionallyActive(): boolean {
    return (
      this.structureTemplate?.status === StructureTemplateStatus.DRAFT ||
      this.structureTemplate?.status ===
        StructureTemplateStatus.PROVISIONALLY_ACTIVE
    );
  }

  @computedFrom('structureTemplate.status')
  protected get isArchived(): boolean {
    return this.structureTemplate?.status === StructureTemplateStatus.ARCHIVED;
  }

  public highlight(): void {
    if (this.listItemElement)
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
  }

  protected handleMoreButtonClicked(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected handleEnterStructureTemplateClicked(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'enter-structure-template-clicked',
      detail: null
    });
  }

  protected handleEditStructureTemplateClicked(): void {
    if (!this.structureTemplate) return;

    void EditStructureTemplateDialog.open({
      structureTemplate: this.structureTemplate
    });
  }

  protected handleDeleteStructureTemplateClicked(): void {
    const structureTemplate = this.structureTemplate;
    if (!structureTemplate) return;

    void Dialogs.deleteEntityDialog(structureTemplate).then(() => {
      this.entityManager.structureTemplateRepository.delete(structureTemplate);
    });
  }

  protected async handleArchiveStructureTemplateClicked(): Promise<void> {
    if (!this.structureTemplate) return;

    await Dialogs.yesNoDialogTk(
      'listItems.structureTemplateListItem.archiveItemDialogTitle',
      {},
      'listItems.structureTemplateListItem.archiveItemInfo'
    );

    this.structureTemplate.status = StructureTemplateStatus.ARCHIVED;
    this.entityManager.structureTemplateRepository.update(
      this.structureTemplate
    );
  }

  protected handleCopyStructureTemplateClicked(): void {
    if (!this.structureTemplate) return;

    void this.structureTemplateCopyService.copyStructureTemplate({
      structureTemplate: this.structureTemplate
    });
  }

  protected async handlePublishStructureTemplateClicked(): Promise<void> {
    if (!this.structureTemplate) return;

    await Dialogs.yesNoDialogTk(
      'listItems.structureTemplateListItem.publishItemDialogTitle',
      {},
      'listItems.structureTemplateListItem.publishItemInfo'
    );

    this.structureTemplate.status = StructureTemplateStatus.ACTIVE;
    this.entityManager.structureTemplateRepository.update(
      this.structureTemplate
    );
  }

  protected async handleProvisionallyPublishStructureTemplateClicked(): Promise<void> {
    if (!this.structureTemplate) return;

    await Dialogs.yesNoDialogTk(
      'listItems.structureTemplateListItem.publishItemDialogTitle',
      {},
      'listItems.structureTemplateListItem.provisionallyPublishItemInfo'
    );

    this.structureTemplate.status =
      StructureTemplateStatus.PROVISIONALLY_ACTIVE;
    this.entityManager.structureTemplateRepository.update(
      this.structureTemplate
    );
  }
}
