import { computedFrom } from 'aurelia-framework';

import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import {
  PropertyOption,
  PropertyType
} from 'common/Types/Entities/Property/PropertyDto';

import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import {
  PropertyDefinitionWidgetHandle,
  PropertyFeatures
} from '../PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';
import { UserDefinedEntityConfig } from '../../../../classes/EntityManager/entities/UserDefinedEntityConfig/types';
import {
  createUserDefinedEntityConfigPropertyDefinitionWidgetConfiguration,
  UserDefinedEntityConfigPropertyDefinitionWidgetConfiguration
} from '../../../base-property-widget/config/userDefinedEntityConfigPropertyDefinitionConfiguration/createUserDefinedEntityConfigPropertyDefinitionWidgetConfiguration';
import { UserDefinedEntityConfigPropertyConfig } from '../../../../classes/EntityManager/entities/UserDefinedEntityConfigPropertyConfig/types';
import { UserDefinedEntityConfigPropertyDefinitionAdditionalFields } from '../../../property-definition-widget-additional-fields/UserDefinedEntityConfigPropertyDefinitionAdditionalFields/user-defined-entity-config-property-definition-additional-fields';
import {
  Disposable,
  DisposableContainer
} from '../../../../classes/Utils/DisposableContainer';
import { RxjsService } from '../../../../services/RxjsService/RxjsService';

export class UserDefinedEntityConfigPropertyDefinitionHandle
  implements
    PropertyDefinitionWidgetHandle<UserDefinedEntityConfigPropertyConfig>
{
  public readonly property: UserDefinedEntityConfigPropertyConfig;
  private readonly entityManager: AppEntityManager;
  private readonly rxjsService: RxjsService;
  private readonly userDefinedEntityConfigPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserDefinedEntityConfig];
  private readonly propertyPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserDefinedEntityConfigPropertyConfig];

  public readonly additionalFieldsComponentClass =
    UserDefinedEntityConfigPropertyDefinitionAdditionalFields;

  constructor(options: {
    entityManager: AppEntityManager;
    rxjsService: RxjsService;
    permissionsService: PermissionsService;
    property: UserDefinedEntityConfigPropertyConfig;
    userDefinedEntityConfig: UserDefinedEntityConfig;
  }) {
    this.entityManager = options.entityManager;
    this.rxjsService = options.rxjsService;
    this.property = options.property;

    this.userDefinedEntityConfigPermissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.UserDefinedEntityConfig,
        entity: options.userDefinedEntityConfig
      });

    this.propertyPermissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.UserDefinedEntityConfigPropertyConfig,
        entity: options.property
      });
  }

  public subscribe(): Disposable {
    const disposableContainer = new DisposableContainer();

    disposableContainer.add(
      this.userDefinedEntityConfigPermissionsHandle.subscribe()
    );

    disposableContainer.add(this.propertyPermissionsHandle.subscribe());

    return disposableContainer.toDisposable();
  }

  @computedFrom('property.name')
  public get name(): string | null {
    return this.property.name;
  }

  public setName(name: string | null): void {
    this.property.name = name;
    this.entityManager.userDefinedEntityConfigPropertyConfigRepository.update(
      this.property
    );
  }

  @computedFrom('property.type')
  public get type(): PropertyType {
    return PropertyHelper.getTypeOrDefault(this.property.type);
  }

  public setType(type: PropertyType): void {
    this.property.type = type;
    this.entityManager.userDefinedEntityConfigPropertyConfigRepository.update(
      this.property
    );
  }

  @computedFrom('property.choices')
  public get choices(): Array<string> {
    return this.property.choices ?? [];
  }

  public setChoices(choices: Array<string>): void {
    this.property.choices = choices;
    this.entityManager.userDefinedEntityConfigPropertyConfigRepository.update(
      this.property
    );
  }

  @computedFrom('property.options')
  public get options(): Array<PropertyOption> {
    return this.property.options ?? [];
  }

  public setOptions(options: Array<PropertyOption>): void {
    this.property.options = options;
    this.entityManager.userDefinedEntityConfigPropertyConfigRepository.update(
      this.property
    );
  }

  @computedFrom('propertyPermissionsHandle.canEditField.name')
  public get canEditName(): boolean {
    return this.propertyPermissionsHandle.canEditField.name;
  }

  @computedFrom('propertyPermissionsHandle.canEditField.type')
  public get canEditType(): boolean {
    return this.propertyPermissionsHandle.canEditField.type;
  }

  @computedFrom('propertyPermissionsHandle.canEditField.choices')
  public get canEditChoices(): boolean {
    return this.propertyPermissionsHandle.canEditField.choices;
  }

  @computedFrom('propertyPermissionsHandle.canEditField.options')
  public get canEditOptions(): boolean {
    return this.propertyPermissionsHandle.canEditField.options;
  }

  @computedFrom('propertyPermissionsHandle.canDeleteEntity')
  public get canBeDeleted(): boolean {
    return this.propertyPermissionsHandle.canDeleteEntity;
  }

  public getPropertyDefinition(): UserDefinedEntityConfigPropertyConfig {
    return this.property;
  }

  public getPropertyFeatures(): PropertyFeatures {
    return {
      canHavePositions: false,
      canHaveProjectParameter: false,
      canHaveTableEntries: false,
      allowDefaultValues: false
    };
  }

  public createPropertyWidgetConfiguration(): UserDefinedEntityConfigPropertyDefinitionWidgetConfiguration | null {
    return createUserDefinedEntityConfigPropertyDefinitionWidgetConfiguration();
  }
}
