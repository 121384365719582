import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskOffer } from '../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskOfferToProcessTask } from '../../classes/EntityManager/entities/ProcessTaskOfferToProcessTask/types';
import { ProcessConfigurationCategory } from '../../classes/EntityManager/entities/ProcessConfigurationCategory/types';
import { MultiProcessTaskSelectionWidgetProcessTaskHandle } from '../multi-process-task-selection-widget/multi-process-task-selection-widget';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { DisposableItemsCache } from '../../classes/DisposableItemsCache/DisposableItemsCache';
import { watch } from '../../hooks/watch';
import { expression, model } from '../../hooks/dependencies';

@autoinject()
export class ProcessTaskOfferRelationsWidget {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTaskOffer: ProcessTaskOffer | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private processTaskHandlesCache: DisposableItemsCache<
    {
      processTask: ProcessTask;
      processTaskOffer: ProcessTaskOffer;
      processTaskOfferToProcessTask: ProcessTaskOfferToProcessTask | null;
    },
    ProcessTaskHandle
  >;

  protected processTaskHandles: Array<ProcessTaskHandle> = [];

  protected category: ProcessConfigurationCategory | null = null;
  private isAttached = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskHandlesCache = new DisposableItemsCache({
      createDisposableForItem: ({ item }) => {
        return new ProcessTaskHandle({
          entityManager,
          permissionsService,
          processTask: item.processTask,
          processTaskOffer: item.processTaskOffer,
          processTaskOfferToProcessTask: item.processTaskOfferToProcessTask
        });
      },
      onItemUpdate: ({ item, mappedItem }) => {
        mappedItem.update({
          processTaskOffer: item.processTaskOffer,
          processTaskOfferToProcessTask: item.processTaskOfferToProcessTask
        });
      },
      getKeyForItem: ({ item }) => item.processTask
    });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationCategory,
      this.updateCategory.bind(this)
    );
    this.updateCategory();

    this.updateProcessTaskHandles();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskOfferChanged(): void {
    if (this.isAttached) {
      this.updateCategory();
    }
  }

  private updateCategory(): void {
    if (
      this.processTaskOffer &&
      this.processTaskOffer.processConfigurationCategoryId
    ) {
      this.category =
        this.entityManager.processConfigurationCategoryRepository.getById(
          this.processTaskOffer.processConfigurationCategoryId
        );
    } else {
      this.category = null;
    }
  }

  @watch(
    expression('processTaskOffer'),
    model(EntityName.ProcessTask),
    model(EntityName.ProcessTaskOfferToProcessTask)
  )
  protected updateProcessTaskHandles(): void {
    const processTaskOffer = this.processTaskOffer;

    if (processTaskOffer) {
      const processTasks =
        this.entityManager.processTaskRepository.getByProcessTaskGroupId(
          processTaskOffer.ownerProcessTaskGroupId
        );

      const processTaskOfferToProcessTasks =
        this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskOfferId(
          processTaskOffer.id
        );

      this.processTaskHandles = this.processTaskHandlesCache.mapItems({
        items: processTasks.map((processTask) => {
          return {
            processTask,
            processTaskOffer,
            processTaskOfferToProcessTask:
              processTaskOfferToProcessTasks.find(
                (relation) => relation.ownerProcessTaskId === processTask.id
              ) ?? null
          };
        })
      });
    } else {
      this.processTaskHandles = this.processTaskHandlesCache.mapItems({
        items: []
      });
    }
  }

  // no @computed is used here because we can't listen to properties of array items yet
  protected get selectedProcessTasks(): Array<ProcessTask> {
    return this.processTaskHandles
      .filter((handle) => handle.isSelected)
      .map((handle) => handle.processTask);
  }
}

export class ProcessTaskHandle
  implements MultiProcessTaskSelectionWidgetProcessTaskHandle
{
  private readonly entityManager: AppEntityManager;

  private internalProcessTask: ProcessTask;
  protected processTaskOffer: ProcessTaskOffer;
  private processTaskOfferToProcessTask: ProcessTaskOfferToProcessTask | null;

  private readonly processTaskOfferPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskOffer];
  private readonly processTaskOfferPermissionsHandleDisposable: Disposable;
  private readonly processTaskOfferToProcessTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskOfferToProcessTask];
  private readonly processTaskOfferToProcessTaskPermissionsHandleDisposable: Disposable;

  constructor({
    entityManager,
    permissionsService,
    processTask,
    processTaskOffer,
    processTaskOfferToProcessTask
  }: {
    entityManager: AppEntityManager;
    permissionsService: PermissionsService;
    processTask: ProcessTask;
    processTaskOffer: ProcessTaskOffer;
    processTaskOfferToProcessTask: ProcessTaskOfferToProcessTask | null;
  }) {
    this.entityManager = entityManager;
    this.internalProcessTask = processTask;
    this.processTaskOffer = processTaskOffer;
    this.processTaskOfferToProcessTask = processTaskOfferToProcessTask;

    this.processTaskOfferPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskOffer,
        context: this,
        expression: 'processTaskOffer'
      });
    this.processTaskOfferPermissionsHandleDisposable =
      this.processTaskOfferPermissionsHandle.subscribe();

    this.processTaskOfferToProcessTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskOfferToProcessTask,
        context: this,
        expression: 'processTaskOfferToProcessTask'
      });
    this.processTaskOfferToProcessTaskPermissionsHandleDisposable =
      this.processTaskOfferToProcessTaskPermissionsHandle.subscribe();
  }

  public update({
    processTaskOffer,
    processTaskOfferToProcessTask
  }: {
    processTaskOffer: ProcessTaskOffer;
    processTaskOfferToProcessTask: ProcessTaskOfferToProcessTask | null;
  }): void {
    this.processTaskOffer = processTaskOffer;
    this.processTaskOfferToProcessTask = processTaskOfferToProcessTask;
  }

  public setSelected(selected: boolean): void {
    if (selected) {
      if (!this.processTaskOfferToProcessTask) {
        this.processTaskOfferToProcessTask =
          this.entityManager.processTaskOfferToProcessTaskRepository.create({
            processTaskOfferId: this.processTaskOffer.id,
            ownerProcessTaskId: this.processTask.id,
            ownerProcessTaskGroupId: this.processTask.ownerProcessTaskGroupId,
            ownerUserGroupId: this.processTask.ownerUserGroupId,
            temporaryGroupName: this.processTaskOffer.temporaryGroupName
          });
      }
    } else {
      if (this.processTaskOfferToProcessTask) {
        this.entityManager.processTaskOfferToProcessTaskRepository.delete(
          this.processTaskOfferToProcessTask
        );
        this.processTaskOfferToProcessTask = null;
      }
    }
  }

  public dispose(): void {
    this.processTaskOfferPermissionsHandleDisposable.dispose();
    this.processTaskOfferToProcessTaskPermissionsHandleDisposable.dispose();
  }

  @computedFrom('internalProcessTask')
  public get processTask(): ProcessTask {
    return this.internalProcessTask;
  }

  @computedFrom(
    'processTaskOfferPermissionsHandle.canEditProcessTaskOfferToProcessTasks'
  )
  public get canBeSelected(): boolean {
    return this.processTaskOfferPermissionsHandle
      .canEditProcessTaskOfferToProcessTasks;
  }

  @computedFrom(
    'processTaskOfferToProcessTaskPermissionsHandle.canDeleteEntity'
  )
  public get canBeDeselected(): boolean {
    return this.processTaskOfferToProcessTaskPermissionsHandle.canDeleteEntity;
  }

  @computedFrom('processTaskOfferToProcessTask')
  public get isSelected(): boolean {
    return !!this.processTaskOfferToProcessTask;
  }
}
