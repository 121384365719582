import { assertNotNullOrUndefined } from 'common/Asserts';
import { TPictureAdditionalMarking } from 'common/Types/Entities/Picture/PictureDto';
import { PictureRevisionTransformationCalculator } from 'common/PictureRevision/PictureRevisionTransformationCalculator';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  AdditionalMarkingsByPictureId,
  PictureMarkingService
} from '../../classes/EntityManager/entities/Picture/PictureMarkingService';
import {
  Picture,
  PictureAdditionalMarking,
  PictureMarking
} from '../../classes/EntityManager/entities/Picture/types';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { PropertyBinder } from '../../classes/PropertyBinder/PropertyBinder';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { IUtilsRateLimitedFunction, Utils } from '../../classes/Utils/Utils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { PictureRevision } from '../../classes/EntityManager/entities/PictureRevision/types';

export class AdditionalMarkingsHandle {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly entityManager: AppEntityManager;
  private readonly pictureMarkingService: PictureMarkingService;
  private readonly onAdditionalMarkingsChanged: OnAdditionalMarkingsChanged;
  private readonly updateMarkedPictureInfosRateLimited: IUtilsRateLimitedFunction;
  private additionalMarkings: Array<PictureAdditionalMarking>;
  private project: Project | null;

  private propertyBinder: PropertyBinder<PropertyBinderConfig>;

  constructor(options: {
    subscriptionManagerService: SubscriptionManagerService;
    pictureMarkingService: PictureMarkingService;
    entityManager: AppEntityManager;
    additionalMarkings: Array<PictureAdditionalMarking> | null;
    onAdditionalMarkingsChanged: OnAdditionalMarkingsChanged;
    projectId: string | null;
  }) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.entityManager = options.entityManager;
    this.pictureMarkingService = options.pictureMarkingService;
    this.additionalMarkings = options.additionalMarkings ?? [];
    this.onAdditionalMarkingsChanged = options.onAdditionalMarkingsChanged;

    this.propertyBinder = new PropertyBinder<PropertyBinderConfig>({
      defaultValuesByName: {
        markedPictureInfos: []
      }
    });
    this.project = this.getProject(options.projectId);
    this.updateMarkedPictureInfosRateLimited = Utils.rateLimitFunction(
      this.updateMarkedPictureInfos.bind(this),
      10
    );
  }

  public additionalMarkingsBindableChanged(
    additionalMarkings: Array<PictureAdditionalMarking> | null
  ): void {
    this.additionalMarkings = additionalMarkings ?? [];

    this.updateMarkedPictureInfosRateLimited();
  }

  public projectIdBindableChanged(projectId: string | null): void {
    this.project = this.getProject(projectId);

    this.updateMarkedPictureInfosRateLimited();
  }

  public subscribe({
    markedPictureInfosBindingCallback
  }: {
    markedPictureInfosBindingCallback: (
      markedPictureInfos: Array<MarkedPictureInfo>
    ) => void;
  }): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.propertyBinder.registerBinding(
        'markedPictureInfos',
        markedPictureInfosBindingCallback
      )
    );

    subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updateMarkedPictureInfosRateLimited
    );
    this.updateMarkedPictureInfosRateLimited();

    return subscriptionManager.toDisposable();
  }

  public addMarking({ marking }: { marking: PictureAdditionalMarking }): void {
    this.additionalMarkings.push(
      this.getMarkingWithoutPictureRevisionTransformation(marking)
    );

    this.updateMarkedPictureInfosRateLimited();
    this.callOnAdditionalMarkingsChanged();
  }

  public upsertMarking({
    marking
  }: {
    marking: PictureAdditionalMarking;
  }): void {
    const existingMarking = this.getMarkingForPictureId({
      pictureId: marking.picture_id
    });

    if (existingMarking) {
      Object.assign(
        existingMarking,
        this.getMarkingWithoutPictureRevisionTransformation(marking)
      );
    } else {
      this.additionalMarkings.push(
        this.getMarkingWithoutPictureRevisionTransformation(marking)
      );
    }

    this.updateMarkedPictureInfosRateLimited();
    this.callOnAdditionalMarkingsChanged();
  }

  public removeMarking({
    marking
  }: {
    marking: PictureAdditionalMarking;
  }): void {
    const index = this.additionalMarkings.findIndex((m) => {
      return (
        m.picture_id === marking.picture_id &&
        m.top === marking.top &&
        m.left === marking.left
      );
    });

    if (index >= 0) {
      this.additionalMarkings.splice(index, 1);
    }

    this.updateMarkedPictureInfosRateLimited();
    this.callOnAdditionalMarkingsChanged();
  }

  public removeMarkingsForPicture({ picture }: { picture: Picture }): void {
    const index = this.additionalMarkings.findIndex(
      (i) => i.picture_id === picture.id
    );

    if (index >= 0) {
      this.additionalMarkings.splice(index, 1);
      this.updateMarkedPictureInfosRateLimited();
      this.callOnAdditionalMarkingsChanged();
    }
  }

  public getOtherMarkingsOfPicture({
    picture
  }: {
    picture: Picture;
  }): Array<PictureAdditionalMarking> {
    const markingsByPictureId = this.getMarkingsByPictureId();
    return markingsByPictureId.get(picture.id) ?? [];
  }

  public hasMarkingForPicture({ picture }: { picture: Picture }): boolean {
    return !!this.getMarkingForPictureId({ pictureId: picture.id });
  }

  private getMarkingForPictureId({
    pictureId
  }: {
    pictureId: string;
  }): PictureAdditionalMarking | null {
    return (
      this.additionalMarkings.find((m) => m.picture_id === pictureId) ?? null
    );
  }

  private updateMarkedPictureInfos(): void {
    const infos: Array<MarkedPictureInfo> = this.createMarkedPictureInfos();

    infos.sort(
      (a, b) =>
        (a.picture.sequence_number ?? 0) - (b.picture.sequence_number ?? 0)
    );

    const preservedMarkedPictureInfos = InstancePreserver.createNewArray({
      originalArray: this.propertyBinder.getRequiredValue('markedPictureInfos'),
      newArray: infos,
      getTrackingValue: (item) => item.picture
    });

    this.propertyBinder.setValue(
      'markedPictureInfos',
      preservedMarkedPictureInfos
    );
  }

  private createMarkedPictureInfos(): Array<MarkedPictureInfo> {
    const markingsByPictureId = this.getMarkingsByPictureId();

    const markingsOfPictureIdMap = new Map<
      string,
      Array<PictureAdditionalMarking>
    >();

    for (const marking of this.additionalMarkings) {
      const markingsOfPictureId = markingsOfPictureIdMap.get(
        marking.picture_id
      );
      if (markingsOfPictureId) {
        markingsOfPictureId.push(marking);
      } else {
        markingsOfPictureIdMap.set(marking.picture_id, [marking]);
      }
    }

    const infos: Array<MarkedPictureInfo> = [];

    for (const [pictureId, markings] of markingsOfPictureIdMap.entries()) {
      const picture = this.entityManager.pictureRepository.getById(pictureId);
      if (!picture) {
        continue;
      }

      const pictureRevision =
        this.entityManager.pictureRevisionRepository.getActiveRevisionByPictureId(
          pictureId
        );

      infos.push({
        picture,
        pictureRevision,
        markings,
        otherMarkings: markingsByPictureId.get(pictureId) ?? []
      });
    }

    return infos;
  }

  private getMarkingsByPictureId(): AdditionalMarkingsByPictureId {
    if (!this.project) {
      return new Map();
    }

    return this.pictureMarkingService.getAdditionalMarkingsByPictureIdForProjectId(
      this.project.id
    );
  }

  private callOnAdditionalMarkingsChanged(): void {
    this.onAdditionalMarkingsChanged(this.additionalMarkings);
  }

  private getProject(projectId: string | null): Project | null {
    if (projectId === null) {
      return null;
    }

    const project = this.entityManager.projectRepository.getById(projectId);
    assertNotNullOrUndefined(project, `no project found for ${projectId}`);

    return project;
  }

  private getMarkingWithoutPictureRevisionTransformation(
    marking: TPictureAdditionalMarking
  ): TPictureAdditionalMarking {
    const pictureRevision =
      this.entityManager.pictureRevisionRepository.getActiveRevisionByPictureId(
        marking.picture_id
      );

    if (pictureRevision?.markingsTransformation) {
      return {
        ...PictureRevisionTransformationCalculator.computeMarkingPositionBeforeTransformation(
          marking,
          pictureRevision.markingsTransformation
        ),
        picture_id: marking.picture_id
      };
    }

    return marking;
  }
}

export type OnAdditionalMarkingsChanged = (
  additionalMarkings: Array<PictureAdditionalMarking>
) => void;

export type MarkedPictureInfo = {
  picture: Picture;
  pictureRevision: PictureRevision | null;
  markings: Array<PictureMarking>;
  otherMarkings: Array<PictureAdditionalMarking>;
};

type PropertyBinderConfig = {
  markedPictureInfos: Array<MarkedPictureInfo>;
};
