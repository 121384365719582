import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { ProcessTaskOffer } from '../../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { ProcessTaskOfferToProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskDevice/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { computed } from '../../../hooks/computed';
import { expression, model } from '../../../hooks/dependencies';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { CustomCheckboxCheckedChangedEvent } from '../../../inputComponents/custom-checkbox/custom-checkbox';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { EditProcessTaskDeviceDialog } from '../../edit-process-task-device-dialog/edit-process-task-device-dialog';

@autoinject()
export class ProcessTaskOfferRelationsWidgetDeviceListItem {
  @bindable()
  public processTaskOffer: ProcessTaskOffer | null = null;

  @bindable()
  public processTaskDevice: ProcessTaskDevice | null = null;

  @subscribableLifecycle()
  private processTaskOfferPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskOffer];

  @subscribableLifecycle()
  private processTaskOfferToProcessTaskDevicePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskOfferToProcessTaskDevice];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processTaskOfferPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskOffer,
        context: this as ProcessTaskOfferRelationsWidgetDeviceListItem,
        propertyName: 'processTaskOffer'
      });

    this.processTaskOfferToProcessTaskDevicePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskOfferToProcessTaskDevice,
        context: this,
        expression: 'processTaskOfferToProcessTaskDevice'
      });
  }

  public setChecked(checked: boolean): void {
    this.ensureRelationStatus(checked);
  }

  protected handleCheckboxChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    this.ensureRelationStatus(event.detail.checked);
  }

  protected handleEditButtonClicked(): void {
    const processTaskDevice = this.processTaskDevice;
    assertNotNullOrUndefined(
      processTaskDevice,
      "can't ProcessTaskOfferRelationsWidgetDeviceListItem.handleEditButtonClicked without processTaskDevice"
    );

    void EditProcessTaskDeviceDialog.open({
      processTaskDevice,
      onDialogClosed: () => {}
    });
  }

  private ensureRelationStatus(relationShouldExist: boolean): void {
    assertNotNullOrUndefined(
      this.processTaskOffer,
      "can't ProcessTaskOfferRelationsWidgetDeviceListItem.ensureRelationStatusToDevice without processTaskOffer"
    );
    assertNotNullOrUndefined(
      this.processTaskDevice,
      "can't ProcessTaskOfferRelationsWidgetDeviceListItem.ensureRelationStatusToDevice without processTaskDevice"
    );

    const existingRelation =
      this.entityManager.processTaskOfferToProcessTaskDeviceRepository.getByProcessTaskOfferIdAndProcessTaskDeviceId(
        this.processTaskOffer.id,
        this.processTaskDevice.id
      );

    if (relationShouldExist) {
      if (!existingRelation) {
        this.entityManager.processTaskOfferToProcessTaskDeviceRepository.create(
          {
            processTaskOfferId: this.processTaskOffer.id,
            processTaskDeviceId: this.processTaskDevice.id,
            ownerProcessTaskId: this.processTaskDevice.ownerProcessTaskId,
            ownerProcessTaskGroupId:
              this.processTaskDevice.ownerProcessTaskGroupId,
            ownerUserGroupId: this.processTaskDevice.ownerUserGroupId,
            temporaryGroupName: this.processTaskDevice.temporaryGroupName
          }
        );
      }
    } else {
      if (existingRelation) {
        this.entityManager.processTaskOfferToProcessTaskDeviceRepository.delete(
          existingRelation
        );
      }
    }
  }

  @computed(
    expression('processTaskDevice'),
    expression('processTaskOffer'),
    model(EntityName.ProcessTaskOfferToProcessTaskDevice)
  )
  protected get processTaskOfferToProcessTaskDevice(): ProcessTaskOfferToProcessTaskDevice | null {
    if (this.processTaskDevice && this.processTaskOffer) {
      return this.entityManager.processTaskOfferToProcessTaskDeviceRepository.getByProcessTaskOfferIdAndProcessTaskDeviceId(
        this.processTaskOffer.id,
        this.processTaskDevice.id
      );
    } else {
      return null;
    }
  }

  @computed(
    expression(
      'processTaskOfferPermissionsHandle.canEditProcessTaskOfferToProcessTaskDevices'
    ),
    expression(
      'processTaskOfferToProcessTaskDevicePermissionsHandle.canDeleteEntity'
    )
  )
  protected get checkboxEnabled(): boolean {
    if (!this.processTaskOfferToProcessTaskDevice) {
      return this.processTaskOfferPermissionsHandle
        .canEditProcessTaskOfferToProcessTaskDevices;
    }

    return this.processTaskOfferToProcessTaskDevicePermissionsHandle
      .canDeleteEntity;
  }
}
