import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { UrlUtils } from '../../classes/Utils/UrlUtils';
import {
  ProcessConfigurationPositionType,
  ProcessConfigurationPositionTypeToConfiguration,
  TProcessConfigurationPositionTypeConfiguration
} from '../../../../common/src/Enums/ProcessConfigurationPositionType';
import { PriceInfo } from './PriceInfo';
import { PositionPrices } from 'common/Operations/ProcessTaskPositionCalculator';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer } from '../../computedValues/computers/ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskInvoice } from '../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { ProcessTaskOffer } from '../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskPositionDetailEntry } from '../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types';
import {
  CheckboxCheckedChangedEvent,
  ExpandableDualRowCompactListItem
} from '../../aureliaComponents/expandable-dual-row-compact-list-item/expandable-dual-row-compact-list-item';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { CustomTypeHandle } from './CustomTypeHandle';
import {
  ProcessTaskInvoiceMapComputer,
  ProcessTaskInvoicesByProcessTaskPositionId
} from '../../computedValues/computers/ProcessTaskInvoiceMapComputer/ProcessTaskInvoiceMapComputer';
import { ProcessTaskPositionAdapter } from '../list-item-related-entity-counts/adapter/ProcessTaskPositionAdapter/ProcessTaskPositionAdapter';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * @event edit-button-clicked
 * @event delete-button-clicked
 * @event navigation-triggered - fired when a link to e.g. an appointment has been clicked, bubbles
 * @event {CheckboxCheckedChangedEvent} checkbox-checked-changed
 */
@autoinject()
export class ProcessTaskPositionListItem {
  @bindable public processTaskPosition: ProcessTaskPosition | null = null;

  @bindable public processTaskGroup: ProcessTaskGroup | null = null;

  /**
   * enables/disables the edit button
   */
  @bindable public checkboxEnabled = false;

  /**
   * disable/hide the edit button
   */
  @bindable public disableEditing = false;

  /**
   * set this to true to have a checkbox displayed in the list item
   */
  @bindable public hasCheckbox = false;

  /**
   * state of the checkbox when `hasCheckbox = true`
   */
  @bindable public checked = false;

  @bindable public hideRelatedEntities = false;

  /**
   * prices of all positions in the processTask of the position
   * necessary to calculate markup positions
   *
   * the prices may further be limited to only positions included in an offer/invoices
   */
  @bindable public positionPrices: Array<
    PositionPrices<ProcessTaskPosition, ProcessTaskPositionDetailEntry>
  > | null = null;

  @bindable public showPrice: boolean = true;

  private subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTaskPositionPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskPosition];

  private domElement: Element;
  private listItem: ExpandableDualRowCompactListItem | null = null;
  private processTaskInvoicesByProcessTaskPositionId: ProcessTaskInvoicesByProcessTaskPositionId =
    new Map();

  protected priceInfo: PriceInfo;
  private readonly customTypeHandle: CustomTypeHandle;

  protected relatedOffers: Array<ProcessTaskOffer> = [];
  protected relatedInvoices: Array<ProcessTaskInvoice> = [];
  protected relatedAppointments: Array<ProcessTaskAppointment> = [];
  protected relatedAppointmentInfos: Array<IRelatedAppointmentInfo> = [];
  protected relatedEntityCountsAdapter: ProcessTaskPositionAdapter | null =
    null;

  protected typeConfiguration: TProcessConfigurationPositionTypeConfiguration | null =
    null;

  private isAttached = false;

  protected ProcessConfigurationPositionType = ProcessConfigurationPositionType;

  protected doneInAppointment = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    element: Element,
    permissionsService: PermissionsService
  ) {
    this.domElement = element;

    this.subscriptionManager = subscriptionManagerService.create();
    this.priceInfo = new PriceInfo(
      this.processTaskPosition,
      this.positionPrices,
      [],
      computedValueService,
      subscriptionManagerService
    );
    this.processTaskPositionPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskPosition,
        context: this,
        expression: 'processTaskPosition'
      });
    this.customTypeHandle = new CustomTypeHandle({
      computedValueService,
      subscriptionManagerService
    });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskPosition,
      this.updateRelatedAppointmentInfos.bind(this)
    );
    this.updateRelatedAppointmentInfos();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskInvoiceMapComputer,
        computeData: {},
        callback: (processTaskInvoiceMapComputeResult) => {
          this.processTaskInvoicesByProcessTaskPositionId =
            processTaskInvoiceMapComputeResult.processTaskInvoicesByProcessTaskPositionId;
          this.updateRelatedInvoices();
        }
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTaskPosition,
      this.updateRelatedOffers.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTask,
      this.updateRelatedOffers.bind(this)
    );
    this.updateRelatedOffers();

    this.subscriptionManager.subscribeToExpression(
      this,
      'processTaskPosition.type',
      this.updateTypeConfiguration.bind(this)
    );
    this.updateTypeConfiguration();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer,
        createComputeData: () =>
          this.processTaskPosition
            ? {
                ownerProcessTaskId: this.processTaskPosition.ownerProcessTaskId
              }
            : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTaskPosition.ownerProcessTaskId && processTaskPosition.id',
            updateSubscription
          );
        },
        callback: (result) => {
          if (this.processTaskPosition) {
            this.priceInfo.setProcessTaskPositionDetailEntries(
              result.get(this.processTaskPosition.id) ?? []
            );
          }
        }
      })
    );

    this.subscriptionManager.addDisposable(this.customTypeHandle.subscribe());
    this.customTypeHandle.setPosition(this.processTaskPosition);

    this.priceInfo.init();
    this.priceInfo.setProcessTaskPosition(this.processTaskPosition);
    this.priceInfo.setProcessTaskPositionPrices(this.positionPrices);
    this.updateRelatedEntityCountsAdapter();
  }

  protected detached(): void {
    this.isAttached = false;

    this.priceInfo.destroy();
    this.subscriptionManager.disposeSubscriptions();
  }

  public highlight(): void {
    this.listItem?.highlight();
  }

  protected processTaskPositionChanged(): void {
    if (this.isAttached) {
      this.updateRelatedAppointmentInfos();
      this.updateRelatedInvoices();
      this.updateRelatedOffers();
      this.priceInfo.setProcessTaskPosition(this.processTaskPosition);
      this.customTypeHandle.setPosition(this.processTaskPosition);
      this.updateRelatedEntityCountsAdapter();
    }
  }

  protected positionPricesChanged(): void {
    if (this.isAttached) {
      this.priceInfo.setProcessTaskPositionPrices(this.positionPrices);
    }
  }

  protected hideRelatedEntitiesChanged(): void {
    if (this.isAttached) {
      this.updateRelatedAppointmentInfos();
      this.updateRelatedInvoices();
      this.updateRelatedOffers();
    }
  }

  private updateRelatedEntityCountsAdapter(): void {
    if (this.processTaskPosition) {
      this.relatedEntityCountsAdapter = new ProcessTaskPositionAdapter({
        entityManager: this.entityManager,
        computedValueService: this.computedValueService,
        subscriptionManagerService: this.subscriptionManagerService,
        processTaskPosition: this.processTaskPosition
      });
    } else {
      this.relatedEntityCountsAdapter = null;
    }
  }

  private updateRelatedAppointmentInfos(): void {
    if (!this.processTaskPosition || this.hideRelatedEntities) {
      this.relatedAppointmentInfos = [];
      this.relatedAppointments = [];
      return;
    }

    const relations =
      this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskPositionId(
        this.processTaskPosition.id
      );

    const relatedAppointmentInfos: Array<IRelatedAppointmentInfo> = [];
    relations.forEach((r) => {
      const appointment =
        this.entityManager.processTaskAppointmentRepository.getById(
          r.processTaskAppointmentId
        );
      if (appointment) {
        relatedAppointmentInfos.push({
          appointment: appointment,
          done: r.done
        });
      }
    });

    this.relatedAppointmentInfos = relatedAppointmentInfos;
    this.relatedAppointments = relatedAppointmentInfos.map(
      (info) => info.appointment
    );

    this.doneInAppointment = relations.some((r) => r.done);
  }

  private updateRelatedOffers(): void {
    if (this.processTaskPosition && !this.hideRelatedEntities) {
      const offerRelations =
        this.entityManager.processTaskOfferToProcessTaskPositionRepository.getByProcessTaskPositionId(
          this.processTaskPosition.id
        );
      const activeOfferRelations = offerRelations.filter((or) => {
        return (
          this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskId(
            or.ownerProcessTaskId
          ).length > 0
        );
      });
      const relatedOfferIds = activeOfferRelations.map(
        (aor) => aor.processTaskOfferId
      );
      this.relatedOffers =
        this.entityManager.processTaskOfferRepository.getByIds(relatedOfferIds);
    } else {
      this.relatedOffers = [];
    }
  }

  private updateRelatedInvoices(): void {
    if (this.processTaskPosition && !this.hideRelatedEntities) {
      this.relatedInvoices =
        this.processTaskInvoicesByProcessTaskPositionId.get(
          this.processTaskPosition.id
        ) ?? [];
    } else {
      this.relatedInvoices = [];
    }
  }

  private updateTypeConfiguration(): void {
    this.typeConfiguration =
      ProcessConfigurationPositionTypeToConfiguration.get(
        this.processTaskPosition
          ? this.processTaskPosition.type
          : ProcessConfigurationPositionType.DEFAULT
      ) || null;
  }

  protected handleEditButtonClicked(): void {
    this.fireDomEvent('edit-button-clicked');
  }

  protected handleDeleteButtonClicked(): void {
    this.fireDomEvent('delete-button-clicked');
  }

  private fireDomEvent(eventName: string): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: eventName,
      detail: null
    });
  }

  protected handleCheckboxCheckedChanged(
    event: CheckboxCheckedChangedEvent
  ): void {
    DomEventHelper.fireEvent<CheckboxCheckedChangedEvent>(this.domElement, {
      name: 'checkbox-checked-changed',
      detail: event.detail
    });
  }

  protected handleNavigationLinkClick(event: Event): boolean {
    if (event.currentTarget) {
      UrlUtils.ensureAnchorNavigationTrigger(
        event.currentTarget as HTMLAnchorElement
      );
    }

    this.fireNavigationTriggeredEvent();

    return true;
  }

  private fireNavigationTriggeredEvent(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'navigation-triggered',
      detail: null,
      bubbles: true
    });
  }
}

interface IRelatedAppointmentInfo {
  appointment: ProcessTaskAppointment;
  done: boolean;
}
