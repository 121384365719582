import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';

import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  GetProcessTaskGroupsFilter,
  GetProcessTaskGroupsFilterType,
  TGetProcessTaskGroupsWithSubEntitiesResponse
} from 'common/EndpointTypes/OperationsEndpointsTypes';
import { ProcessTaskGroupFilterMode } from 'common/Enums/ProcessTaskGroupFilterMode';
import { DateUtils } from 'common/DateUtils';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { SocketService } from '../../services/SocketService';
import { EventAggregatorPromiseHelper } from '../../classes/Promise/EventAggregatorPromiseHelper';
import { NotificationHelper } from '../../classes/NotificationHelper';
import { ProcessTaskGroupUtils } from '../../classes/EntityManager/entities/ProcessTaskGroup/ProcessTaskGroupUtils';
import { GlobalLoadingOverlay } from '../../loadingComponents/global-loading-overlay/global-loading-overlay';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskGroupsWithSubEntitiesLoadingService } from '../../services/ProcessTaskGroupsWithSubEntitiesLoadingService';
import { Utils } from '../../classes/Utils/Utils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskGroupFilterSettingsService } from '../../services/ProcessTaskGroupFilterSettingsService';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  AppAnyEntityWithEntityName,
  EntityName
} from '../../classes/EntityManager/entities/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { HistoryService } from '../../services/HistoryService/HistoryService';
import { AutoScrollToProcessTaskHandler } from './AutoScrollToProcessTaskHandler';
import { ProcessTaskGroupsTableView } from '../../operationsComponents/process-task-groups-table-view/process-task-groups-table-view';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

@autoinject()
export class edit_process_task_groups {
  private static TASK_GROUPS_PER_PAGE = 10;
  private static TEMPORARY_GROUP_NAME = 'edit_process_task_groups';

  private readonly subscriptionManager: SubscriptionManager;
  private readonly autoScrollToProcessTaskHandler: AutoScrollToProcessTaskHandler;

  private processConfiguration: ProcessConfiguration | null = null;
  private isMobile: boolean = false;
  private availableProcessTaskGroups: Array<ProcessTaskGroup> = [];
  private currentPageIndex: number = 1;
  private currentPageIndexLoaded: boolean = false;
  protected maxPageIndex: number | null = null;
  private getProcessTaskGroupsRequestCounter: number = 0;
  private isConnected: boolean = false;

  private isAttached: boolean = false;
  private updateAvailableProcessTaskGroupsDebounced = Utils.debounceFunction(
    this.updateAvailableProcessTaskGroups.bind(this),
    700
  );

  private filterSettingsLoaded: boolean = false;

  protected currentProcessTaskGroupFilterId: string | null = null;
  protected tableView: ProcessTaskGroupsTableView | null = null;

  protected readonly EntityName = EntityName;

  constructor(
    private readonly eventAggregator: EventAggregator,
    private readonly i18n: I18N,
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    private readonly processTaskGroupsWithSubEntitiesLoadingService: ProcessTaskGroupsWithSubEntitiesLoadingService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly filterSettingsService: ProcessTaskGroupFilterSettingsService,
    historyService: HistoryService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.autoScrollToProcessTaskHandler = new AutoScrollToProcessTaskHandler({
      entityManager,
      historyService,
      scrollToProcessTask: ({ processTask, processTaskGroup }) => {
        assertNotNullOrUndefined(
          this.tableView,
          "can't scroll to processTask because there is no tableView available"
        );
        this.tableView.scrollToProcessTask({
          processTask,
          processTaskGroup
        });
      }
    });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToEvent('socket:authenticated', () => {
      void this.updateAvailableProcessTaskGroups();
    });
    void this.updateAvailableProcessTaskGroups();

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.registerHooks({
        entityIdUpgraded: this.handleEntitySynchronized.bind(this)
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      this.updateProcessConfiguration.bind(this)
    );
    this.updateProcessConfiguration();

    this.filterSettingsService.subscribeToChange(
      this,
      this.filterSettingsUpdated.bind(this)
    );

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
    this.filterSettingsService.unsubscribe(this);

    GlobalLoadingOverlay.setLoadingState(this, false);
  }

  private updateProcessConfiguration(): void {
    this.processConfiguration =
      this.entityManager.processConfigurationRepository.getAll()[0] ?? null;
  }

  private async updateAvailableProcessTaskGroups(): Promise<void> {
    if (
      !this.socketService.isAuthenticated() ||
      !this.currentPageIndexLoaded ||
      !this.filterSettingsLoaded
    ) {
      return;
    }

    try {
      GlobalLoadingOverlay.setLoadingState(this, true);

      const counter = ++this.getProcessTaskGroupsRequestCounter;

      const response = await this.getProcessTaskGroupsWithSubEntities();

      // in the case multiple requests get started, we only accept the newest one
      if (counter === this.getProcessTaskGroupsRequestCounter) {
        this.processGetProcessTaskGroupWithSubEntitiesResponse(response);
        GlobalLoadingOverlay.setLoadingState(this, false);
        this.autoScrollToProcessTaskHandler.processTaskGroupsLoaded(
          this.availableProcessTaskGroups
        );
      }
    } catch (e) {
      console.warn(
        'failed while trying to fetch the available process task groups',
        e
      );
      GlobalLoadingOverlay.setLoadingState(this, false);
    }
  }

  private getProcessTaskGroupsWithSubEntities(): Promise<
    TGetProcessTaskGroupsWithSubEntitiesResponse<string, string>
  > {
    return EventAggregatorPromiseHelper.createConnectedPromise(
      this.eventAggregator,
      new Promise((resolve) => {
        this.socketService.getProcessTaskGroupsWithSubEntities(
          this.getProcessTaskGroupsFilter(),
          (res) => {
            if (!res.success) {
              NotificationHelper.notifyDanger(
                this.translate('loadProcessTaskGroupsError')
              );
            }

            resolve(res);
          }
        );
      })
    );
  }

  private getProcessTaskGroupsFilter(): GetProcessTaskGroupsFilter {
    const filterSettings =
      this.filterSettingsService.getFilterSettingsWithDates();

    const commonFilterData = {
      addressFilterType: filterSettings.addressFilterType,
      pageOffset: this.currentPageIndex - 1,
      itemsPerPage: edit_process_task_groups.TASK_GROUPS_PER_PAGE,
      includeArchive: filterSettings.includeArchive,
      assigneeUserId: filterSettings.assigneeUserId,
      searchText: filterSettings.searchText,
      currentProcessConfigurationStepIds:
        filterSettings.selectedProcessConfigurationStepIds,
      excludedProcessConfigurationStepIds:
        filterSettings.excludedProcessConfigurationStepIds,
      processConfigurationActionStatusFilterType:
        filterSettings.selectedActionStatusOptionType,
      processConfigurationActionStatusId: filterSettings.selectedActionStatusId,
      customActionStatusSearchText: filterSettings.customActionStatusFilterText,
      lastEditedBeforeDateIso: filterSettings.lastEditedBeforeDaysEnabled
        ? DateUtils.getDateWithDayOffsetWithoutTime(
            filterSettings.lastEditedBeforeDays * -1
          ).toISOString()
        : null
    };

    switch (filterSettings.filterMode) {
      default:
        console.error(`unhandled filter mode ${filterSettings.filterMode}`);

      // eslint-disable-next-line no-fallthrough
      case ProcessTaskGroupFilterMode.NEXT_DAYS:
      case ProcessTaskGroupFilterMode.DATE_RANGE:
        return {
          ...commonFilterData,
          type: GetProcessTaskGroupsFilterType.DATE,
          dateFromIso: filterSettings.dateFromIso,
          dateToIso: filterSettings.dateToIso
        };

      case ProcessTaskGroupFilterMode.NO_FUTURE_APPOINTMENTS:
        return {
          ...commonFilterData,
          type: GetProcessTaskGroupsFilterType.NO_APPOINTMENTS_AFTER_DATE,
          dateIso: filterSettings.dateFromIso
        };

      case ProcessTaskGroupFilterMode.ALL:
        return {
          ...commonFilterData,
          type: GetProcessTaskGroupsFilterType.ALL
        };
    }
  }

  private processGetProcessTaskGroupWithSubEntitiesResponse(
    response: TGetProcessTaskGroupsWithSubEntitiesResponse<string, string>
  ): void {
    if (response.success) {
      this.processTaskGroupsWithSubEntitiesLoadingService.loadEntitiesTemporarily(
        response,
        edit_process_task_groups.TEMPORARY_GROUP_NAME
      );
    }
    this.availableProcessTaskGroups =
      this.processTaskGroupsWithSubEntitiesLoadingService.getTrackedProcessTaskGroups(
        response
      );
    this.maxPageIndex = Math.max(response.maxPageOffset + 1, 1);
  }

  private handleCreateProcessTaskGroupClicked(
    userGroup: UserGroup | null
  ): void {
    if (!!userGroup && !this.processConfiguration?.entityCreationUserGroupId) {
      ProcessTaskGroupUtils.navigateToCreateProcessTaskGroupPage(this.router, {
        user_group_id: userGroup.id
      });
      return;
    }
    assertNotNullOrUndefined(
      this.processConfiguration?.entityCreationUserGroupId,
      'cannot get default user group id without processConfiguration'
    );
    ProcessTaskGroupUtils.navigateToCreateProcessTaskGroupPage(this.router, {
      user_group_id: this.processConfiguration.entityCreationUserGroupId
    });
  }

  private handleCurrentPageIndexChanged(page: number): void {
    this.currentPageIndex = page;
    this.currentPageIndexLoaded = true;
    if (this.isAttached) {
      void this.updateAvailableProcessTaskGroups();
    }
  }

  private handleEntitySynchronized(
    entityWithEntityName: AppAnyEntityWithEntityName
  ): void {
    if (
      entityWithEntityName.entityName === EntityName.ProcessTaskGroup ||
      entityWithEntityName.entityName === EntityName.ProcessTask
    ) {
      this.updateAvailableProcessTaskGroupsDebounced();
    }
  }

  @computed(expression('processConfiguration.entityCreationUserGroupId'))
  protected get disableUserGroupSelection(): boolean {
    return !!this.processConfiguration?.entityCreationUserGroupId;
  }

  private translate(key: string): string {
    return this.i18n.tr('generalPages.editProcessTaskGroups.' + key);
  }

  private filterSettingsUpdated(): void {
    if (this.filterSettingsLoaded) {
      this.updateAvailableProcessTaskGroupsDebounced();
    } else {
      this.filterSettingsLoaded = true;
      void this.updateAvailableProcessTaskGroups();
    }
  }
}
