import { autoinject, bindable } from 'aurelia-framework';

import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { StructureTemplateEntry } from '../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { StructureTemplateStructureTemplateEntryProperty } from '../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import { watch } from '../../hooks/watch';
import { expression, model } from '../../hooks/dependencies';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class StructureTemplateEntryPropertiesDefaultValueWidget {
  @bindable public structureTemplateEntry: StructureTemplateEntry | null = null;
  @bindable public enabled = false;

  private subscriptionManager: SubscriptionManager;

  protected structureTemplateProperties: Array<StructureTemplateStructureTemplateEntryProperty> =
    [];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntryProperty,
      () => {
        this.updateStructureTemplateProperties();
      }
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected structureTemplateEntryChanged(): void {
    this.updateStructureTemplateProperties();
  }

  private updateStructureTemplateProperties(): void {
    if (this.structureTemplateEntry) {
      this.structureTemplateProperties =
        this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateId(
          this.structureTemplateEntry.ownerStructureTemplateId
        );
    } else {
      this.structureTemplateProperties = [];
    }
  }

  @watch(
    model(EntityName.StructureTemplateEntryProperty),
    expression('structureTemplateEntry')
  )
  protected async removeUnnecessaryProperties(): Promise<void> {
    if (!this.structureTemplateEntry) {
      return;
    }

    const structureTemplateProperties =
      this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateId(
        this.structureTemplateEntry.ownerStructureTemplateId
      );
    const entryProperties =
      this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateEntryId(
        this.structureTemplateEntry.id
      );

    for (const entryProperty of entryProperties) {
      const relatedStructureTemplateProperty = structureTemplateProperties.find(
        (structureTemplateProperty) => {
          return PropertyHelper.isTheSameProperty(
            structureTemplateProperty,
            entryProperty
          );
        }
      );

      if (relatedStructureTemplateProperty) {
        continue;
      }

      const canDeleteEnttryProperty =
        await this.permissionsService.useAdapterOnce({
          entityName: EntityName.StructureTemplateEntryProperty,
          useAdapter: (adapter) => {
            return adapter.canDeleteEntity(entryProperty);
          }
        });

      if (canDeleteEnttryProperty) {
        this.entityManager.structureTemplateEntryPropertyRepository.delete(
          entryProperty
        );
      }
    }
  }
}
