import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { TextBrickDto } from './TextBrickDto';

export function createTextBrickEntityInfo<
  TEntity extends TextBrickDto<string, string>
>(
  options: CreateEntityInfoOptions<TextBrickDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.TextBrick,
  TEntity
> {
  return {
    entityName: EntityName.TextBrick,
    synchronizeRelativeTo: [EntityName.Project],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      TextBrickDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createOptionalBaseFieldInfos(),
        entryId: {
          name: 'entryId',
          ref: EntityName.Entry,
          defaultValue: null
        },
        ownerDefectId: {
          name: 'ownerDefectId',
          ref: EntityName.Defect,
          defaultValue: null
        },
        textBrickTemplateId: {
          name: 'textBrickTemplateId',
          ref: EntityName.TextBrickTemplate,
          defaultValue: null
        },
        value: {
          name: 'value',
          defaultValue: ''
        }
      },
      options.additionalFieldInfos
    )
  };
}
