import { autoinject } from 'aurelia-dependency-injection';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../SubscriptionManager';
import { Utils } from '../../../Utils/Utils';
import { AppEntityManager } from '../AppEntityManager';
import { AppEntityManagerApiAdapter } from '../AppEntityManagerApiAdapter';

@autoinject()
export class AutoActualizationService {
  private readonly subscriptionManager: SubscriptionManager;
  private readonly authenticatedSubscriptionManager: SubscriptionManager;
  private autoJoiningProjectsIsFinished: boolean = false;
  private autoJoiningProcessTaskGroupsIsFinished: boolean = false;
  private autoJoiningDefectsIsFinished: boolean = false;
  private isAuthenticated: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly apiAdapter: AppEntityManagerApiAdapter,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.authenticatedSubscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    this.subscriptionManager.addDisposable(
      this.apiAdapter.bindIsAuthenticated((isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;

        if (isAuthenticated) {
          this.handleAuthenticated();
        } else {
          this.handleDisconnected();
        }
      })
    );
    const actualizeIfNoProjectIsJoinedRateLimited = Utils.rateLimitFunction(
      () => {
        if (!this.entityManager.joinedProjectsManager.isBusy()) {
          this.tryActualize();
        }
      },
      5
    );

    this.subscriptionManager.addDisposable(
      actualizeIfNoProjectIsJoinedRateLimited.toCancelDisposable()
    );
    this.subscriptionManager.addDisposable(
      this.entityManager.joinedProjectsManager.registerHooks({
        onActivelyJoinedProject: () => {
          if (this.autoJoiningProjectsIsFinished) {
            actualizeIfNoProjectIsJoinedRateLimited();
          }
        }
      })
    );
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.authenticatedSubscriptionManager.disposeSubscriptions();
  }

  private handleAuthenticated(): void {
    if (
      this.entityManager.joinedProjectsManager.autoJoiningProjectsIsFinished()
    ) {
      this.finishedAutoJoiningProjects();
    } else {
      this.authenticatedSubscriptionManager.addDisposable(
        this.entityManager.joinedProjectsManager.registerHooks({
          autoJoiningProjectsFinished:
            this.finishedAutoJoiningProjects.bind(this)
        })
      );
    }

    if (
      this.entityManager.joinedProcessTaskGroupManager.autoJoiningProcessTaskGroupsIsFinished()
    ) {
      this.finishedAutoJoiningProcessTaskGroups();
    } else {
      this.authenticatedSubscriptionManager.addDisposable(
        this.entityManager.joinedProcessTaskGroupManager.registerHooks({
          autoJoiningProcessTaskGroupsFinished:
            this.finishedAutoJoiningProcessTaskGroups.bind(this)
        })
      );
    }

    if (
      this.entityManager.joinedDefectsManager.autoJoiningDefectsIsFinished()
    ) {
      this.finishedAutoJoiningDefects();
    } else {
      this.authenticatedSubscriptionManager.addDisposable(
        this.entityManager.joinedDefectsManager.registerHooks({
          autoJoiningDefectsFinished: this.finishedAutoJoiningDefects.bind(this)
        })
      );
    }

    this.authenticatedSubscriptionManager.subscribeToTimeout(() => {
      this.finishedAutoJoiningProjects();
      this.finishedAutoJoiningProcessTaskGroups();
    }, 10000);
  }

  private handleDisconnected(): void {
    this.autoJoiningProjectsIsFinished = false;
    this.autoJoiningProcessTaskGroupsIsFinished = false;
    this.authenticatedSubscriptionManager.disposeSubscriptions();
  }

  private finishedAutoJoiningProjects(): void {
    if (!this.autoJoiningProjectsIsFinished) {
      this.autoJoiningProjectsIsFinished = true;
      this.tryActualize();
    }
  }

  private finishedAutoJoiningProcessTaskGroups(): void {
    if (!this.autoJoiningProcessTaskGroupsIsFinished) {
      this.autoJoiningProcessTaskGroupsIsFinished = true;
      this.tryActualize();
    }
  }

  private finishedAutoJoiningDefects(): void {
    if (!this.autoJoiningDefectsIsFinished) {
      this.autoJoiningDefectsIsFinished = true;
      this.tryActualize();
    }
  }

  private tryActualize(): void {
    if (
      !this.isAuthenticated ||
      !this.autoJoiningProjectsIsFinished ||
      !this.autoJoiningProcessTaskGroupsIsFinished ||
      !this.autoJoiningDefectsIsFinished
    ) {
      return;
    }

    void this.entityManager.entityActualization.actualize();
  }
}
