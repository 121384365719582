import _ from 'lodash';

import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import {
  EntryNavigationButton,
  NavigationDirection
} from '../entry-navigation-button/entry-navigation-button';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { EntryUtils } from '../../classes/EntityManager/entities/Entry/EntryUtils';
import { ApplyPropertiesService } from '../../classes/EntityManager/entities/Property/ApplyPropertiesService';
import { Property } from '../../classes/EntityManager/entities/Property/types';
import { EntryDeletionService } from '../../classes/EntityManager/entities/Entry/EntryDeletionService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { watch } from '../../hooks/watch';
import { arrayChanges, expression } from '../../hooks/dependencies';
import { EntryTextBrickWidgetAdapter } from '../text-brick-widget/TextBrickWidgetAdapter/EntryTextBrickWidgetAdapter';

/**
 * @event close-clicked - the widget can now be hidden (must be handled in the parent)
 * @event entry-changed - the widget has navigated to a new entry
 */
@autoinject()
export class EditEntryWidget {
  /**
   * entry will change when the user decides to navigate to the next entry
   * so you probably need to bind it two-way
   */
  @bindable public entry: Entry | null = null;

  @bindable public project: Project | null = null;

  @bindable public entryNavigationEnabled = true;
  @bindable public createSubEntriesEnabled = true;

  @bindable public structure: StructureTemplate | null = null;

  @subscribableLifecycle()
  protected readonly entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  private subscriptionManager: SubscriptionManager;

  private domElement: Element;

  protected contentWrapperElement: HTMLElement | null = null;

  protected mainContentElement: HTMLElement | null = null;

  private contentWrapperElementAnimator: ShowHideAnimator | null = null;

  protected siblings: Array<Entry> = [];

  protected subEntries: Array<Entry> = [];

  protected properties: Array<Property> = [];

  private isAttached = false;

  protected entryPath: Array<string> = [];

  private textBrickWidgetAdapter: EntryTextBrickWidgetAdapter | null = null;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly entryDeletionService: EntryDeletionService,
    private readonly applyPropertiesService: ApplyPropertiesService,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly permissionsService: PermissionsService
  ) {
    this.domElement = element;
    this.subscriptionManager = subscriptionManagerService.create();

    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Entry,
        context: this as EditEntryWidget,
        propertyName: 'entry'
      });
  }

  protected handleCloseIconClick(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'close-clicked',
      detail: null
    });
  }

  protected async handleDeleteIconClick(): Promise<void> {
    const entry = this.entry;
    assertNotNullOrUndefined(
      entry,
      "can't delete entry if there's no entry to delete"
    );

    if (await this.entryDeletionService.deleteEntryWithDialog(entry)) {
      const index = this.siblings.indexOf(entry);

      // If entry was the only item left in the list, close the widget
      if (this.siblings.length === 1) {
        this.handleCloseIconClick();
        return;
      }
      // Navigate to previous entry if not the first entry, navigate to the next otherwise
      setTimeout(() => {
        index !== 0
          ? this.navigateToPreviousEntry()
          : this.navigateToNextEntry();
      }, 10);
    }
  }

  protected bind(): void {
    if (!this.contentWrapperElement) {
      throw new Error('contentWrapperElement is not bound');
    }

    this.contentWrapperElementAnimator = new ShowHideAnimator(
      this.contentWrapperElement,
      { keepDisplayIntact: true }
    );
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(EntityName.Entry, () => {
      this.entriesChanged();
    });

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      () => {
        this.updateProperties();
      }
    );

    this.entriesChanged();
    this.updateProperties();

    this.updateEntryPath();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();

    if (EntryNavigationButton.hasControl(this)) {
      EntryNavigationButton.releaseControl(this);
    }
  }

  protected entryChanged(): void {
    if (this.isAttached) {
      this.entriesChanged();
      this.updateProperties();
      this.updateTextBrickWidgetAdapter();
      if (this.mainContentElement) this.mainContentElement.scrollTop = 0;
    }
  }

  private entriesChanged(): void {
    if (this.entry) {
      this.siblings = this.entityManager.entryRepository.getSiblingsOfEntry(
        this.entry
      );
      this.subEntries = this.entityManager.entryRepository.getSubEntriesOfEntry(
        this.entry
      );

      // only take control while having an entry, or else it will block higher level controls
      if (
        !EntryNavigationButton.hasControl(this) &&
        this.entryNavigationEnabled
      ) {
        EntryNavigationButton.takeControl(
          this,
          {
            navigateIn: this.handleNavigateInClick.bind(this),
            navigateOut: this.handleNavigateOutClick.bind(this)
          },
          {
            navigateIn: null,
            navigateOut: null
          }
        );
      }
    } else {
      this.siblings = [];
      this.subEntries = [];

      if (EntryNavigationButton.hasControl(this)) {
        EntryNavigationButton.releaseControl(this);
      }
    }

    if (EntryNavigationButton.hasControl(this)) {
      EntryNavigationButton.setVisible(
        this,
        NavigationDirection.NAVIGATE_OUT,
        !!(this.entry && this.entry.page_depth_parent)
      );
      EntryNavigationButton.setVisible(
        this,
        NavigationDirection.NAVIGATE_IN,
        !!this.subEntries.length
      );
    }
  }

  private updateProperties(): void {
    if (this.entry) {
      this.properties =
        this.entityManager.propertyRepository.getGroupedPropertiesByEntryId(
          this.entry.id
        );
    } else {
      this.properties = [];
    }
  }

  private updateEntryPath(): void {
    if (this.entry) {
      const path: Array<string> = [];
      const parents = this.entityManager.entryRepository
        .getPathByEntryId(this.entry.id)
        .reverse();
      for (const parentEntry of parents) {
        if (parentEntry === this.entry) continue;
        path.push(parentEntry.name ?? '');
      }
      this.entryPath = path;
    } else {
      this.entryPath = [];
    }

    this.updateTextBrickWidgetAdapter();
  }

  protected getPageDepthIndexOfEntryId(entryId: string): string {
    return EntryUtils.getPageDepthIndex({
      path: this.entityManager.entryRepository.getPathByEntryId(entryId)
    });
  }

  protected isTagActivated(tag: string, importnotes?: Array<string>): boolean {
    return importnotes ? importnotes.indexOf(tag) > -1 : false;
  }

  protected handleTagToggled(tag: string): void {
    if (!this.entry) return;
    if (!this.entry.importnotes) this.entry.importnotes = [];

    const index = this.entry.importnotes.indexOf(tag);
    let importnotes = _.clone(this.entry.importnotes);
    if (!importnotes) importnotes = [];
    if (index > -1) {
      importnotes.splice(index, 1);
    } else {
      importnotes.push(tag);
    }
    this.entry.importnotes = importnotes;
    this.handleEntryChanged(this.entry);
  }

  protected getPictureOfEntryId(entryId: string): Picture | null {
    return (
      this.entityManager.pictureRepository.getSelectedEntryPicture(entryId) ||
      null
    );
  }

  protected handleAddSubEntryClick(): void {
    if (!this.entry) return;

    let ownerUserGroupId = this.entry.ownerUserGroupId;
    if (!ownerUserGroupId) {
      // compatibility code, old entries don't necessarily have the user group stored already
      const project = this.entityManager.projectRepository.getById(
        this.entry.project
      );
      if (project) ownerUserGroupId = project.usergroup;
    }

    const entry = this.entityManager.entryRepository.create({
      page_depth_parent: this.entry.id,
      group_name: this.entry.group_name,
      project: this.entry.project,
      ownerProjectId: this.entry.project,
      ownerUserGroupId: ownerUserGroupId
    });

    this.navigateToEntry(entry);
  }

  @watch(
    expression('entryPermissionsHandle.canEditField.name'),
    expression('entry'),
    expression('project.customTagsInEntries'),
    expression('structure')
  )
  protected updateEntryProperties(): void {
    if (
      !this.entryPermissionsHandle.canEditProperties ||
      !this.entry ||
      !this.project
    ) {
      return;
    }

    if (this.structure) {
      const structureTemplateEntryProperties =
        this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateId(
          this.structure.id
        );
      this.applyPropertiesService.applyCustomPropertiesToStructureEntry(
        structureTemplateEntryProperties,
        this.entry,
        this.project
      );
    } else {
      const customTagsInEntries = this.project.customTagsInEntries
        ? this.project.customTagsInEntries
        : [];
      this.applyPropertiesService.applyRawCustomPropertiesToEntry(
        this.entry,
        customTagsInEntries
      );
    }
  }

  protected handleEntryChanged(entry: Entry): void {
    this.entityManager.entryRepository.update(entry);
  }

  private updateTextBrickWidgetAdapter(): void {
    if (!this.entry) return;
    this.textBrickWidgetAdapter = new EntryTextBrickWidgetAdapter({
      permissionsService: this.permissionsService,
      subscriptionManagerService: this.subscriptionManagerService,
      entityManager: this.entityManager,
      entry: this.entry,
      path: this.entryPath,
      temporaryGroupName: this.entry?.temporaryGroupName ?? null
    });
  }

  // *********** navigation handling ***********

  private handleNavigateInClick(): void {
    if (this.subEntries[0]) {
      this.navigateToEntry(this.subEntries[0]);
    }
  }

  private handleNavigateOutClick(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't EditEntryWidget.handleNavigateOutClick without an entry"
    );
    assertNotNullOrUndefined(
      this.entry.page_depth_parent,
      "can't EditEntryWidget.handleNavigateOutClick without an page_depth_parent"
    );

    const entry = this.entityManager.entryRepository.getById(
      this.entry.page_depth_parent
    );
    assertNotNullOrUndefined(
      entry,
      `no parent entry for "${this.entry.page_depth_parent}" found in EditEntryWidget.handleNavigateOutClick`
    );

    this.navigateToEntry(entry);
  }

  protected navigateToNextEntry(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't EditEntryWidget.navigateToNextEntry without an entry"
    );

    const index = this.siblings.indexOf(this.entry);
    const nextSibling = this.siblings[index + 1];
    if (nextSibling) {
      this.navigateToEntry(nextSibling);
    }
  }

  protected navigateToPreviousEntry(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't EditEntryWidget.navigateToPreviousEntry without an entry"
    );

    const index = this.siblings.indexOf(this.entry);
    const previousSibling = this.siblings[index - 1];

    if (previousSibling) {
      this.navigateToEntry(previousSibling);
    }
  }

  protected navigateToEntry(entry: Entry): void {
    const contentWrapperElementAnimator = this.contentWrapperElementAnimator;
    if (!contentWrapperElementAnimator) return;

    void contentWrapperElementAnimator.fadeOut().then(() => {
      // apparently some people manage to navigate to a new entry and then navigate to a new page within 250ms (animation duration)
      // I'm not sure if our users are humans or robots
      if (!this.domElement) {
        console.error("navigated to entry while this wasn't attached anymore");
        return;
      }

      this.entry = entry;

      setTimeout(() => {
        DomEventHelper.fireEvent(this.domElement, {
          name: 'entry-changed',
          detail: {}
        });
      }, 0);

      void contentWrapperElementAnimator.fadeIn();
    });
  }
}
