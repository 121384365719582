import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { StructureTemplateEntry } from '../../../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import {
  CanEditFieldFieldName,
  EntityAdapter,
  SubscribeOptions
} from '../EntityAdapter';
import { structureTemplateEntryEntityInfo } from '../../../../classes/EntityManager/entities/StructureTemplateEntry/structureTemplateEntryEntityInfo';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';

export class StructureTemplateEntryAdapter
  implements EntityAdapter<StructureTemplateEntry>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;

  constructor(options: StructureTemplateEntryAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return this.roleBasedPermissions != null;
  }

  public canDeleteEntity(
    structureTemplateEntry: StructureTemplateEntry
  ): boolean {
    return this.checkPermission({
      structureTemplateEntry,
      editableStructureTemplateStatuses: [StructureTemplateStatus.DRAFT],
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteStructureTemplateEntries()
    });
  }

  public canEditField(
    structureTemplateEntry: StructureTemplateEntry,
    fieldName: CanEditFieldFieldName<StructureTemplateEntry>
  ): boolean {
    if (fieldName === 'listPosition') {
      return this.checkPermission({
        structureTemplateEntry,
        editableStructureTemplateStatuses: [StructureTemplateStatus.DRAFT],
        checkRolePermission: (permissions) =>
          permissions.getCanUpdateStructureTemplateEntries()
      });
    }

    return this.checkPermission({
      structureTemplateEntry,
      editableStructureTemplateStatuses: null,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateStructureTemplateEntries()
    });
  }

  public canGenerallyEditStructureTemplateEntryProperties(
    structureTemplateEntry: StructureTemplateEntry
  ): boolean {
    return this.checkPermission({
      structureTemplateEntry,
      editableStructureTemplateStatuses: null,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateStructureTemplateEntries()
    });
  }

  public canCreateStructureTemplateEntryProperties(
    structureTemplateEntry: StructureTemplateEntry
  ): boolean {
    return this.checkPermission({
      structureTemplateEntry,
      editableStructureTemplateStatuses: [
        StructureTemplateStatus.DRAFT,
        StructureTemplateStatus.PROVISIONALLY_ACTIVE,
        StructureTemplateStatus.ACTIVE
      ],
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateStructureTemplateEntries()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.StructureTemplateEntry,
    StructureTemplateEntry
  > {
    return structureTemplateEntryEntityInfo;
  }

  private checkPermission({
    structureTemplateEntry,
    editableStructureTemplateStatuses,
    checkRolePermission
  }: {
    structureTemplateEntry: StructureTemplateEntry;
    /**
     * null when editing is allowed in all statuses, an Array for a list of statuses where editing is allowed
     */
    editableStructureTemplateStatuses: Array<StructureTemplateStatus> | null;
    checkRolePermission: (
      roleBasedUserGroupSpecificPermissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    if (editableStructureTemplateStatuses) {
      const structureTemplate =
        this.entityManager.structureTemplateRepository.getById(
          structureTemplateEntry.ownerStructureTemplateId
        );
      if (
        !structureTemplate ||
        !editableStructureTemplateStatuses.includes(structureTemplate.status)
      ) {
        return false;
      }
    }

    return checkRolePermission(
      this.roleBasedPermissions.inUserGroupId(
        structureTemplateEntry.ownerUserGroupId
      )
    );
  }
}

export type StructureTemplateEntryAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
