export class Utils {
  /**
   * stolen from https://stackoverflow.com/a/6969486
   * escapes the text so it doesn't contain any special regex characters
   */
  public static escapeStringForRegex(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  public static leftPad(
    string: string,
    desiredLength: number,
    character: string = '0'
  ): string {
    while (string.length < desiredLength) {
      string = character + string;
    }

    return string;
  }

  public static groupValues<TValue, TKey>(
    values: Readonly<Array<TValue>>,
    keyGetter: (value: TValue) => TKey
  ): Map<TKey, Array<TValue>> {
    const map: Map<TKey, Array<TValue>> = new Map();

    for (const value of values) {
      const key = keyGetter(value);

      let valuesForKey = map.get(key);
      if (!valuesForKey) {
        valuesForKey = [];
        map.set(key, valuesForKey);
      }

      valuesForKey.push(value);
    }

    return map;
  }

  /**
   * Maps a key to a single value.
   * This is pretty similar to groupValues, but it only maps to a single value instead of multiple ones.
   * In case of a key collision, the later value will be used.
   */
  public static keyValues<TValue, TKey>(
    values: Readonly<Array<TValue>>,
    keyGetter: (value: TValue) => TKey
  ): Map<TKey, TValue> {
    const map: Map<TKey, TValue> = new Map();

    for (const value of values) {
      map.set(keyGetter(value), value);
    }

    return map;
  }

  /**
   * Make a strict comparison between a and b if they are not null/undefined
   */
  public static compareOptionalValues<T>(
    a: T | null | undefined,
    b: T | null | undefined
  ): boolean {
    return a === b || (a == null && b == null);
  }

  /**
   * Wait a specified amount of milliseconds.
   */
  public static wait(timeMs: number): Promise<void> {
    return new Promise((res) => {
      setTimeout(() => res(), timeMs);
    });
  }

  /**
   * @param {Map<TKey, TValue>} a - gets modified in place, values of b will be merged into this
   * @param {Map<TKey, TValue>} b
   * @param {(valueA: TValue, valueB: TValue) => TValue} valueMerger
   */
  public static mergeMaps<TKey, TValue>(
    a: Map<TKey, TValue>,
    b: Map<TKey, TValue>,
    valueMerger: (valueA: TValue, valueB: TValue) => TValue = (
      valueA,
      valueB
    ) => valueB
  ): void {
    b.forEach((value, key) => {
      if (a.has(key)) {
        const mergedValue = valueMerger(a.get(key) as TValue, value);
        a.set(key, mergedValue);
      } else {
        a.set(key, value);
      }
    });
  }
}
