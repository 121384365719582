import { computedFrom } from 'aurelia-binding';
import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { Vector } from 'common/Geometry/Vector';
import {
  IPictureCoords,
  IPictureLocationInfo
} from 'common/Types/Entities/Picture/PictureDto';
import { GalleryThingAdditionalMarkingsHelper } from 'common/GalleryThing/GalleryThingAdditionalMarkingsHelper';
import { PictureRevisionTransformationCalculator } from 'common/PictureRevision/PictureRevisionTransformationCalculator';

import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { Utils } from '../../classes/Utils/Utils';
import { TZoomBoxResizerHelperPicturePositionInfo } from '../zoom-box/ZoomBoxPictureResizer';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

/**
 * a marker which is meant to be used for a positioned picture inside a zoom box
 * the marker will position itself automatically based on the coordinates
 */
@autoinject()
export class PositionedPictureZoomBoxOverlayMarker {
  @bindable()
  public picture: Picture | null = null;

  @bindable()
  public picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo | null =
    null;

  @bindable()
  public coordinates: IPictureCoords | null = null;

  /**
   * any css color
   */
  @bindable()
  public color: string = '#ff401f';

  private readonly domElement: HTMLElement;
  protected markerElement: HTMLElement | null = null;
  private isAttached: boolean = false;
  protected position: Position | null = null;
  protected rotation: string = '0deg';
  private rateLimitedUpdateRotation = Utils.rateLimitFunction(
    this.updateRotation.bind(this),
    0
  );

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager
  ) {
    this.domElement = element as HTMLElement;
  }

  protected attached(): void {
    this.isAttached = true;
    this.update();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected pictureChanged(): void {
    if (this.isAttached) {
      this.update();
    }
  }

  protected picturePositionInfoChanged(): void {
    if (this.isAttached) {
      this.update();
    }
  }

  protected coordinatesChanged(): void {
    if (this.isAttached) {
      this.update();
    }
  }

  private update(): void {
    if (
      this.coordinates &&
      this.picture?.location_info &&
      this.picturePositionInfo
    ) {
      this.position = this.calculatePosition(
        this.coordinates,
        this.picture.location_info,
        this.picturePositionInfo
      );
    } else {
      this.position = null;
    }

    this.rateLimitedUpdateRotation();
  }

  private calculatePosition(
    coordinates: IPictureCoords,
    locationInfo: IPictureLocationInfo,
    picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo
  ): Position | null {
    const transformedLocationInfo = this.transformLocationInfo(locationInfo);

    const relativePosition =
      GalleryThingAdditionalMarkingsHelper.getRelativePositionOfCoordsToOverviewPicture(
        coordinates,
        transformedLocationInfo
      );
    if (!relativePosition) {
      return null;
    }

    const markingPosition = picturePositionInfo.size
      .clone()
      .multiplyVector(relativePosition) // convert % to px
      .addVector(picturePositionInfo.topLeftPosition) // add the offset
      .divideVector(picturePositionInfo.containerSize) // get a percentual value relative to the container
      .scale(100); // convert to percent

    return {
      top: markingPosition.getY() + '%',
      left: markingPosition.getX() + '%'
    };
  }

  private transformLocationInfo(
    locationInfo: IPictureLocationInfo
  ): IPictureLocationInfo {
    if (!this.picture) return locationInfo;

    const revision =
      this.entityManager.pictureRevisionRepository.getActiveRevisionByPictureId(
        this.picture.id
      );
    if (!revision?.markingsTransformation) return locationInfo;

    const transformedLocationInfo =
      PictureRevisionTransformationCalculator.computeLocationInfoBasedOnTransformationMatrix(
        locationInfo,
        revision.markingsTransformation
      );

    return transformedLocationInfo;
  }

  private updateRotation(): void {
    if (!this.domElement.offsetParent || !this.markerElement) {
      this.rotation = '0deg';
      return;
    }

    const containerComputed = window.getComputedStyle(
      this.domElement.offsetParent
    );

    // we receive the bottomMiddle Vector here because the marker is marker is moved to the top left
    const bottomMiddleVector = Vector.createHtmlVector(
      this.domElement.offsetLeft,
      this.domElement.offsetTop
    );
    const heightVector = Vector.createHtmlVector(
      0,
      this.markerElement.clientHeight
    );
    const widthVector = Vector.createHtmlVector(
      this.markerElement.clientWidth,
      0
    );
    const topLeftVector = bottomMiddleVector
      .clone()
      .substractVector(widthVector.clone().scale(0.5))
      .substractVector(heightVector);
    const topRightVector = topLeftVector.clone().addVector(widthVector);

    // we don't care if only that amount is on the outside since everything is still recognizeable
    const acceptableOutsideDistance = 1;
    const leftOutside = topLeftVector.getX() < acceptableOutsideDistance * -1;
    const rightOutside =
      topRightVector.getX() >
      parseFloat(containerComputed.width) + acceptableOutsideDistance;
    const topOutside = topLeftVector.getY() < acceptableOutsideDistance * -1;

    if (leftOutside && topOutside) {
      this.rotation = '135deg';
    } else if (rightOutside && topOutside) {
      this.rotation = '225deg';
    } else if (topOutside) {
      this.rotation = '180deg';
    } else {
      this.rotation = '0deg';
    }
  }

  @computedFrom('color')
  protected get markerOutlineStyle(): string {
    return `fill:${this.color};fill-opacity:1;stroke:#ffffff;stroke-opacity:1;stroke-width:2;stroke-miterlimit:4;stroke-dasharray:none`;
  }
}

type Position = {
  top: string;
  left: string;
};
