import { autoinject, bindable } from 'aurelia-framework';
import { ProcessTaskInvoiceRelationsWidgetDevices } from '../process-task-invoice-relations-widget-devices/process-task-invoice-relations-widget-devices';
import { CustomCheckboxCheckedChangedEvent } from '../../../inputComponents/custom-checkbox/custom-checkbox';
import { ProcessTaskInvoiceRelationsWidgetPositions } from '../process-task-invoice-relations-widget-positions/process-task-invoice-relations-widget-positions';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskInvoice } from '../../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { ProcessConfigurationCategory } from '../../../classes/EntityManager/entities/ProcessConfigurationCategory/types';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessTaskInvoiceRelationsWidgetProcessTask {
  @bindable public processTask: ProcessTask | null = null;

  @bindable public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable public processTaskInvoice: ProcessTaskInvoice | null = null;

  @bindable
  public processConfigurationCategory: ProcessConfigurationCategory | null =
    null;

  @subscribableLifecycle()
  protected readonly processTaskInvoicePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskInvoice];

  private allDevicesSelected: boolean = false;
  private processTaskDeviceCount: number | null = null;
  private allPositionsSelected: boolean = false;
  private processTaskPositionCount: number | null = null;

  private processTaskInvoiceRelationsWidgetDevices: ProcessTaskInvoiceRelationsWidgetDevices | null =
    null;

  private processTaskInvoiceRelationsWidgetPositions: ProcessTaskInvoiceRelationsWidgetPositions | null =
    null;

  constructor(permissionsService: PermissionsService) {
    this.processTaskInvoicePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskInvoice,
        context: this as ProcessTaskInvoiceRelationsWidgetProcessTask,
        propertyName: 'processTaskInvoice'
      });
  }

  private handleSelectAllDevicesCheckedChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    const devicesWidget = this.getProcessTaskInvoiceRelationsWidgetDevices();

    if (event.detail.checked) {
      devicesWidget.selectAll();
    } else {
      devicesWidget.deselectAll();
    }
  }

  private handleSelectAllPositionsCheckedChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    const positionsWidget =
      this.getProcessTaskInvoiceRelationsWidgetPositions();

    if (event.detail.checked) {
      positionsWidget.selectAll();
    } else {
      positionsWidget.deselectAll();
    }
  }

  private getProcessTaskInvoiceRelationsWidgetDevices(): ProcessTaskInvoiceRelationsWidgetDevices {
    if (!this.processTaskInvoiceRelationsWidgetDevices) {
      throw new Error(
        'processTaskInvoiceRelationsWidgetDevices is not available'
      );
    }

    return this.processTaskInvoiceRelationsWidgetDevices;
  }

  private getProcessTaskInvoiceRelationsWidgetPositions(): ProcessTaskInvoiceRelationsWidgetPositions {
    if (!this.processTaskInvoiceRelationsWidgetPositions) {
      throw new Error(
        'processTaskInvoiceRelationsWidgetPositions is not available'
      );
    }

    return this.processTaskInvoiceRelationsWidgetPositions;
  }
}
