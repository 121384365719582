import { EntityInfo } from '@record-it-npm/synchro-common';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processConfigurationEntityInfo } from '../../../../classes/EntityManager/entities/ProcessConfiguration/processConfigurationEntityInfo';
import { ProcessConfiguration } from '../../../../classes/EntityManager/entities/ProcessConfiguration/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessConfigurationAdapter
  implements EntityAdapter<ProcessConfiguration>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;

  constructor(options: ProcessConfigurationAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return this.roleBasedPermissions != null;
  }

  public canDeleteEntity(processConfiguration: ProcessConfiguration): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processConfiguration.ownerUserGroupId)
      .getCanDeleteProcessConfigurations();
  }

  public canEditField(processConfiguration: ProcessConfiguration): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processConfiguration.ownerUserGroupId)
      .getCanUpdateProcessConfigurations();
  }

  public canCreateProcessConfigurationActionStates(
    processConfiguration: ProcessConfiguration
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processConfiguration.ownerUserGroupId)
      .getCanCreateProcessConfigurationActionStates();
  }

  public canCreateProcessConfigurationAuthorizationTypes(
    processConfiguration: ProcessConfiguration
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processConfiguration.ownerUserGroupId)
      .getCanCreateProcessConfigurationAuthorizationTypes();
  }

  public canCreateProcessConfigurationCategories(
    processConfiguration: ProcessConfiguration
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processConfiguration.ownerUserGroupId)
      .getCanCreateProcessConfigurationCategories();
  }

  public canCreateProcessConfigurationDevices(
    processConfiguration: ProcessConfiguration
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processConfiguration.ownerUserGroupId)
      .getCanCreateProcessConfigurationDevices();
  }

  public canCreateProcessConfigurationDeviceExports(
    processConfiguration: ProcessConfiguration
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processConfiguration.ownerUserGroupId)
      .getCanCreateProcessConfigurationDeviceExports();
  }

  public canCreateProcessConfigurationFollowUpAppointments(
    processConfiguration: ProcessConfiguration
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processConfiguration.ownerUserGroupId)
      .getCanCreateProcessConfigurationFollowUpAppointments();
  }

  public canCreateProcessConfigurationForms(
    processConfiguration: ProcessConfiguration
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processConfiguration.ownerUserGroupId)
      .getCanCreateProcessConfigurationForms();
  }

  public canCreateProcessConfigurationFormSendTargets(
    processConfiguration: ProcessConfiguration
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processConfiguration.ownerUserGroupId)
      .getCanCreateProcessConfigurationFormSendTargets();
  }

  public canCreateProcessConfigurationMeasurePointTypes(
    processConfiguration: ProcessConfiguration
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processConfiguration.ownerUserGroupId)
      .getCanCreateProcessConfigurationMeasurePointTypes();
  }

  public canCreateProcessConfigurationSteps(
    processConfiguration: ProcessConfiguration
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processConfiguration.ownerUserGroupId)
      .getCanCreateProcessConfigurationSteps();
  }

  public canCreateProcessConfigurationNoteCategories(
    processConfiguration: ProcessConfiguration
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(processConfiguration.ownerUserGroupId)
      .getCanCreateProcessConfigurationNoteCategories();
  }

  public canImportProcessConfigurationStepPositions(
    processConfiguration: ProcessConfiguration
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    const userGroupSpecificPermissions =
      this.roleBasedPermissions.inUserGroupId(
        processConfiguration.ownerUserGroupId
      );

    return (
      userGroupSpecificPermissions.getCanCreateProcessConfigurationStepPositions() &&
      userGroupSpecificPermissions.getCanUpdateProcessConfigurationStepPositions()
    );
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessConfiguration,
    ProcessConfiguration
  > {
    return processConfigurationEntityInfo;
  }
}

export type ProcessConfigurationAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
