import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import {
  PropertyOption,
  PropertyOptionDefinition,
  PropertyOptionType
} from 'common/Types/Entities/Property/PropertyDto';

import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';

@autoinject()
export class PropertyOptionDefinitionWidget {
  @bindable public option: PropertyOption | null = null;

  @bindable public optionDefinitions: Array<PropertyOptionDefinition> = [];

  @bindable public enabled: boolean = false;

  protected optionName = '';

  protected optionValue: string | number = '';

  constructor(private readonly element: Element) {}

  protected optionChanged(): void {
    this.optionName = this.option?.name ?? '';
    this.optionValue = this.option?.value ?? '';
  }

  @computedFrom('optionDefinitions')
  protected get optionDefinitionsWithLabelTks(): Array<
    PropertyOptionDefinition & { labelTk: string }
  > {
    return this.optionDefinitions.map((o) => ({
      ...o,
      labelTk: `modelsDetail.PropertyModel.optionLabels.${o.name}`
    }));
  }

  @computedFrom('optionDefinitions', 'optionName')
  private get selectedOptionDefinition(): PropertyOptionDefinition | null {
    return (
      this.optionDefinitions.find((o) => o.name === this.optionName) ?? null
    );
  }

  @computedFrom('selectedOptionDefinition')
  protected get optionDefinitionValueChoices(): Array<string> {
    if (
      this.selectedOptionDefinition &&
      'choices' in this.selectedOptionDefinition
    ) {
      return this.selectedOptionDefinition.choices;
    }

    return [];
  }

  @computedFrom('selectedOptionDefinition.type')
  protected get isNumberOptionDefinition(): boolean {
    return this.selectedOptionDefinition?.type === PropertyOptionType.NUMBER;
  }

  protected handlePropertyOptionChanged(): void {
    DomEventHelper.fireEvent<OptionChangedEvent>(this.element, {
      name: 'option-changed',
      detail: {
        option: {
          name: this.optionName,
          value: String(this.optionValue)
        }
      }
    });
  }
}

export type OptionChangedEvent = NamedCustomEvent<
  'option-changed',
  { option: PropertyOption }
>;
