import { Thing, ThingCreationEntity } from './types';
import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../AppEntityManager';
import { ProjectCreationService } from '../Project/ProjectCreationService';
import { ActiveUserCompanySettingService } from '../UserCompanySetting/ActiveUserCompanySettingService';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { PropertyCopyService } from '../Property/PropertyCopyService';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';

/**
 * Service for creating a new Thing.
 *
 * Users have the ability to set options so that projects are automatically created within
 * a new thing, which is handled here.
 */
@autoinject()
export class ThingCreationService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly projectCreationService: ProjectCreationService,
    private readonly propertyCopyService: PropertyCopyService,
    private readonly computedValueService: ComputedValueService
  ) {}

  public create(creationEntity: ThingCreationEntity): Thing {
    const thing = this.entityManager.thingRepository.create(creationEntity);

    this.createPropertiesFromThingTypes(thing);
    this.createProjectsFromAutomaticProjectCreationConfig(thing);

    // since all thing sub entity permission checks are dependent on this, we need to flush/recompute it
    // maybe implement a way to flush all relevant dependencies of all entities/adapters for synchronous checking
    this.computedValueService.recompute(ThingAuthorizationComputer);

    return thing;
  }

  /**
   * Loads all ThingTypes, checks if `importOnThingCreation` is set,
   * and imports all it's properties into the provided `thing` if it is.
   */
  private createPropertiesFromThingTypes(thing: Thing): void {
    const thingTypesToImport =
      this.entityManager.thingTypeRepository.getEntitiesToImportOnThingCreation();
    const propertiesToImport =
      this.entityManager.propertyRepository.getByThingTypeIds(
        thingTypesToImport.map((tt) => tt.id)
      );

    for (const propertyToCopy of propertiesToImport) {
      this.propertyCopyService.copy({
        newProperty: {
          thing: thing.id,
          ownerUserGroupId: thing.ownerUserGroupId
        },
        propertyToCopy
      });
    }
  }

  /**
   * Reads all report templates set in `general.automaticProjectCreationConfigs`
   * and creates projects from them for the provided `thing`.
   */
  private createProjectsFromAutomaticProjectCreationConfig(thing: Thing): void {
    const reportTypeIds =
      this.activeUserCompanySettingService.getSettingProperty(
        'general.automaticProjectCreationConfigs'
      ) as Array<{ reportTemplateId: string }> | null;

    if (reportTypeIds) {
      for (const { reportTemplateId } of reportTypeIds) {
        const reportType =
          this.entityManager.reportTypeRepository.getById(reportTemplateId);
        if (!reportType) continue;

        this.projectCreationService.createProject({
          thing,
          name: reportType.name,
          projectType: ProjectType.BASIC,
          reportType,
          shadowEntity: thing.shadowEntity,
          temporaryGroupName: thing.temporaryGroupName
        });
      }
    }
  }
}
