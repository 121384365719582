import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';

import { StructureTemplateEntryProperty } from '../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class StructureTemplateExcludeFromFlawNumbersWidget {
  @bindable()
  public structureTemplateEntryProperties: Array<StructureTemplateEntryProperty> =
    [];

  @bindable() public structureTemplate: StructureTemplate | null = null;

  @subscribableLifecycle()
  protected readonly structureTemplatePermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplate];

  protected selectedPropertyToAdd: StructureTemplateEntryProperty | null = null;

  private propertiesToShow: Array<StructureTemplateEntryProperty> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.structureTemplatePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.StructureTemplate,
        context: this as StructureTemplateExcludeFromFlawNumbersWidget,
        propertyName: 'structureTemplate'
      });
  }

  @computedFrom('structureTemplateEntryProperties', 'propertiesToShow.length')
  protected get parameterSelectOptions(): Array<StructureTemplateEntryProperty> {
    return this.structureTemplateEntryProperties.filter((obj1) => {
      const safeType = PropertyHelper.getTypeOrDefault(obj1.type);
      return (
        !this.propertiesToShow.some((obj2) => obj2.id === obj1.id) &&
        PropertyHelper.propertyTypeHasEditableValue(safeType) &&
        PropertyHelper.propertyTypeHasPrimitiveRepresentation(safeType)
      );
    });
  }

  @computedFrom(
    'structureTemplateEntryProperties.length',
    'propertiesToShow.length'
  )
  protected get canAddNewConfig(): boolean {
    return (
      this.structureTemplateEntryProperties.length !==
      this.propertiesToShow.length
    );
  }

  protected handleStructureTemplateChanged(): void {
    if (!this.structureTemplate) return;
    this.entityManager.structureTemplateRepository.update(
      this.structureTemplate
    );
  }

  protected structureTemplateEntryPropertiesChanged(): void {
    this.propertiesToShow = this.structureTemplateEntryProperties.filter(
      (p) => p.excludeFromFlawNumberComputationForValues?.length
    );
  }

  protected handleAddPropertyConfigurationButtonClicked(): void {
    if (!this.selectedPropertyToAdd) return;
    this.propertiesToShow.push(this.selectedPropertyToAdd);
    this.selectedPropertyToAdd = null;
  }

  protected handleStructureTemplateEntryPropertyExcludeFromFlawNumbersCleared(
    structureTemplateEntryProperty: StructureTemplateEntryProperty
  ): void {
    ArrayUtils.remove(this.propertiesToShow, structureTemplateEntryProperty);
  }
}
