import { DateType, IdType } from '../../../Types/Entities/Base/types';
import { ProjectDto } from '../../../Types/Entities/Project/ProjectDto';
import { ThingDto } from '../../../Types/Entities/Thing/ThingDto';
import {
  ContextSpecificFieldConfigs,
  ExpressionEditorScopeCreationUtils
} from '../../ExpressionEditorScopeCreationUtils';
import { SharepointFieldConfigs } from './SharepointFieldConfigs';

export class SharepointExpressionEditorScope<
  TId extends IdType,
  TDate extends DateType
> {
  constructor(private readonly fetcher: Fetcher<TId, TDate>) {}

  public async createFieldConfigsForSharepointExportSettingsScope({
    thingId,
    projectId
  }: {
    thingId: string;
    projectId: string | null;
  }): Promise<
    ContextSpecificFieldConfigs<
      typeof SharepointFieldConfigs.sharepointExportSettingsFieldConfigs
    >
  > {
    return this.createContextSpecificSharepointExportSettingsFieldConfigs({
      thing: await this.fetcher.getThing(thingId),
      project: projectId ? await this.fetcher.getProject(projectId) : null
    });
  }

  private createContextSpecificSharepointExportSettingsFieldConfigs =
    ExpressionEditorScopeCreationUtils.createContextSpecificFieldConfigsFactory(
      {
        fieldConfigs:
          SharepointFieldConfigs.sharepointExportSettingsFieldConfigs,
        createContextSpecificFieldConfigData: ({
          thing,
          project
        }: {
          thing: ThingDto<TId, TDate>;
          project: ProjectDto<TId, TDate> | null;
        }) => ({
          thing: this.createContextSpecificThingFieldConfigs({
            thing
          }),
          project: this.createContextSpecificProjectFieldConfigs({
            project
          })
        })
      }
    );

  private createContextSpecificThingFieldConfigs =
    ExpressionEditorScopeCreationUtils.createContextSpecificFieldConfigsFactory(
      {
        fieldConfigs: SharepointFieldConfigs.thingFieldConfigs,
        createContextSpecificFieldConfigData: ({
          thing
        }: {
          thing: ThingDto<TId, TDate>;
        }) => ({
          name: {
            getValue: async () => thing.name ?? ''
          }
        })
      }
    );

  private createContextSpecificProjectFieldConfigs =
    ExpressionEditorScopeCreationUtils.createContextSpecificFieldConfigsFactory(
      {
        fieldConfigs: SharepointFieldConfigs.projectFieldConfigs,
        createContextSpecificFieldConfigData: ({
          project
        }: {
          project: ProjectDto<TId, TDate> | null;
        }) => ({
          name: {
            getValue: async () => project?.name ?? ''
          }
        })
      }
    );
}

type Fetcher<TId extends IdType, TDate extends DateType> = {
  getThing(thingId: string): Promise<ThingDto<TId, TDate>>;
  getProject(projectId: string): Promise<ProjectDto<TId, TDate>>;
};
