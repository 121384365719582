import { autoinject } from 'aurelia-framework';
import { Utils } from 'common/Utils';
import { EntityName } from 'common/Types/BaseEntities/EntityName';
import { ValueComputer } from '../ValueComputer';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntryUtils } from '../../../classes/EntityManager/entities/Entry/EntryUtils';
import { Entry } from '../../../classes/EntityManager/entities/Entry/types';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';

@autoinject()
export class EntriesByParentEntryIdComputer extends ValueComputer<
  Record<never, never>,
  EntriesByParentEntryIdHandle
> {
  private readonly subscriptionManager: SubscriptionManager;

  private readonly entryChangeCountHandle: EntryChangeCountHandle = {
    changeCount: 0
  };

  private entriesByParentEntryIdHandle: EntriesByParentEntryIdHandle | null =
    null;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(): void {
    this.subscriptionManager.subscribeToModelChanges(EntityName.Entry, () => {
      this.entryChangeCountHandle.changeCount++;
    });
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): EntriesByParentEntryIdHandle {
    if (!this.entriesByParentEntryIdHandle) {
      this.entriesByParentEntryIdHandle = new EntriesByParentEntryIdHandle({
        entityManager: this.entityManager,
        entryChangeCountHandle: this.entryChangeCountHandle
      });
    }

    return this.entriesByParentEntryIdHandle;
  }

  public computeDataAreEqual(): boolean {
    return true;
  }
}

export class EntriesByParentEntryIdHandle {
  private readonly entityManager: AppEntityManager;
  private readonly entryChangeCountHandle: EntryChangeCountHandle;

  private entriesByParentEntryId: EntriesByParentEntryId | null = null;
  private entriesByParentEntryIdChangeCount: number | null = null;

  constructor({
    entityManager,
    entryChangeCountHandle
  }: {
    entityManager: AppEntityManager;
    entryChangeCountHandle: EntryChangeCountHandle;
  }) {
    this.entityManager = entityManager;
    this.entryChangeCountHandle = entryChangeCountHandle;
  }

  public getEntriesByParentEntryId(): EntriesByParentEntryId {
    if (
      this.entriesByParentEntryId &&
      this.entriesByParentEntryIdChangeCount ===
        this.entryChangeCountHandle.changeCount
    ) {
      return this.entriesByParentEntryId;
    }

    this.entriesByParentEntryId = this.createEntriesByParentEntryId();
    this.entriesByParentEntryIdChangeCount =
      this.entryChangeCountHandle.changeCount;

    return this.entriesByParentEntryId;
  }

  private createEntriesByParentEntryId(): EntriesByParentEntryId {
    const entries = EntryUtils.sortInPlace(
      this.entityManager.entryRepository.getAll()
    );

    const entriesByParentEntryId = Utils.groupValues(
      entries,
      (entry) => entry.page_depth_parent || null
    );

    return entriesByParentEntryId;
  }
}

export type EntriesByParentEntryId = Map<string | null, Array<Entry>>;

type EntryChangeCountHandle = {
  changeCount: number;
};
