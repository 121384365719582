import { bindable, containerless } from 'aurelia-framework';
import { ProcessTask } from '../../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../../../classes/EntityManager/entities/ProcessTaskGroup/types';

@containerless()
export class ShowProcessAppointmentAppointmentOverviewWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public currentAppointmentId: string | null = null;

  @bindable()
  public expanded: boolean = false;

  private appointmentCount: number | null = null;

  private handleToggleExpandedClick(): void {
    this.expanded = !this.expanded;
  }
}
