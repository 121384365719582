import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { processTaskMeasurePointToPictureEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskMeasurePointToPicture/processTaskMeasurePointToPictureEntityInfo';
import { ProcessTaskMeasurePointToPicture } from '../../../../classes/EntityManager/entities/ProcessTaskMeasurePointToPicture/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { ProcessTaskMeasurePointAdapter } from '../ProcessTaskMeasurePointAdapter/ProcessTaskMeasurePointAdapter';

export class ProcessTaskMeasurePointToPictureAdapter
  implements EntityAdapter<ProcessTaskMeasurePointToPicture>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private processTaskMeasurePointAdapter: ProcessTaskMeasurePointAdapter | null =
    null;

  constructor(options: ProcessTaskMeasurePointToPictureAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.ProcessTaskMeasurePoint,
        onNextAdapter: ({ adapter }) => {
          this.processTaskMeasurePointAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.processTaskMeasurePointAdapter != null &&
      this.processTaskMeasurePointAdapter.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(
    processTaskMeasurePointToPicture: ProcessTaskMeasurePointToPicture
  ): boolean {
    return this.canEditProcessTaskMeasurePointToPicture(
      processTaskMeasurePointToPicture
    );
  }

  public canEditField(
    processTaskMeasurePointToPicture: ProcessTaskMeasurePointToPicture
  ): boolean {
    return this.canEditProcessTaskMeasurePointToPicture(
      processTaskMeasurePointToPicture
    );
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskMeasurePointToPicture,
    ProcessTaskMeasurePointToPicture
  > {
    return processTaskMeasurePointToPictureEntityInfo;
  }

  private canEditProcessTaskMeasurePointToPicture(
    processTaskMeasurePointToPicture: ProcessTaskMeasurePointToPicture
  ): boolean {
    if (!this.processTaskMeasurePointAdapter) {
      return false;
    }

    const processTaskMeasurePoint =
      this.entityManager.processTaskMeasurePointRepository.getById(
        processTaskMeasurePointToPicture.processTaskMeasurePointId
      );

    if (!processTaskMeasurePoint) {
      return false;
    }

    return this.processTaskMeasurePointAdapter.canEditProcessTaskMeasurePointToPictures(
      processTaskMeasurePoint
    );
  }
}

export type ProcessTaskMeasurePointToPictureAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
