import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { regionEntityInfo } from '../../../../classes/EntityManager/entities/Region/regionEntityInfo';
import { Region } from '../../../../classes/EntityManager/entities/Region/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';

export class RegionAdapter implements EntityAdapter<Region> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private thingIdsWhereUserIsAuthorized: Set<string> | null = null;

  constructor(options: RegionAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ThingAuthorizationComputer,
        computeData: {},
        callback: ({ thingIdsWhereUserIsAuthorized }) => {
          this.thingIdsWhereUserIsAuthorized = thingIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.thingIdsWhereUserIsAuthorized != null
    );
  }

  public canDeleteEntity(region: Region): boolean {
    return this.checkPermission({
      region,
      checkRolePermission: (permissions) => permissions.getCanDeleteRegions()
    });
  }

  public canEditField(region: Region): boolean {
    return this.checkPermission({
      region,
      checkRolePermission: (permissions) => permissions.getCanUpdateRegions()
    });
  }

  public canEditProperties(region: Region): boolean {
    return this.checkPermission({
      region,
      checkRolePermission: (permissions) => permissions.getCanUpdateRegions()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.Region,
    Region
  > {
    return regionEntityInfo;
  }

  private checkPermission({
    region,
    checkRolePermission
  }: {
    region: Region;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkPermissionBasedOnThingId({
      thingId: region.thingId,
      ownerUserGroupId: region.ownerUserGroupId,
      thingIdsWhereUserIsAuthorized: this.thingIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type RegionAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
