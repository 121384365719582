import { computedFrom, autoinject } from 'aurelia-framework';
import { ProcessTaskAppointmentSmsNotification } from 'common/Enums/ProcessTaskAppointmentSmsNotification';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { DateRangeChangeHandler } from '../../classes/Date/DateRangeChangeHandler';
import { ProcessConfigurationFollowUpAppointment } from '../../classes/EntityManager/entities/ProcessConfigurationFollowUpAppointment/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../classes/EntityManager/entities/types';

@autoinject()
export class CreateFollowUpProcessTaskAppointmentDialog {
  private dialog: RecordItDialog | null = null;

  private appointment: ProcessTaskAppointment | null = null;
  private processTask: ProcessTask | null = null;
  private processConfigurationFollowUpAppointment: ProcessConfigurationFollowUpAppointment | null =
    null;

  private appointmentData: AppointmentData | null = null;
  private errorTk: string | null = null;
  private dateRangeChangeHandler = new DateRangeChangeHandler<AppointmentData>({
    dateFormat: 'iso',
    getDateFrom: (a) => a.dateFrom,
    getDateTo: (a) => a.dateTo,
    setDateFrom: (a, date) => {
      a.dateFrom = date;
    },
    setDateTo: (a, date) => {
      a.dateTo = date;
    }
  });

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    private readonly permissionsService: PermissionsService
  ) {}

  public open(options: TOpenOptions): void {
    if (!this.dialog) {
      throw new Error("can't open without a dialog");
    }

    this.appointment = options.appointment;
    this.processTask = options.processTask;
    this.processConfigurationFollowUpAppointment =
      options.processConfigurationFollowUpAppointment;

    if (options.appointment) {
      this.appointmentData = this.createAppointmentDataForAppointment(
        options.appointment,
        this.processConfigurationFollowUpAppointment,
        options.relativeTo
      );
    } else {
      this.appointmentData = this.createDefaultAppointmentData(
        this.processConfigurationFollowUpAppointment
      );
    }

    this.dateRangeChangeHandler.setModel(this.appointmentData);

    this.dialog.open();
  }

  private handleDialogClosed(): void {
    this.appointment = null;
    this.processConfigurationFollowUpAppointment = null;
  }

  private handleDeclineButtonClicked(): void {
    this.dialog?.close();
  }

  private async handleAcceptButtonClicked(): Promise<void> {
    const validatedAppointmentData = this.validateAppointmentData();
    if (!validatedAppointmentData) {
      return;
    }

    const appointment = await this.createAppointment(validatedAppointmentData);

    this.entityManager.processTaskAppointmentToUserRepository.create({
      ownerUserGroupId: appointment.ownerUserGroupId,
      ownerProcessTaskGroupId: appointment.ownerProcessTaskGroupId,
      ownerProcessTaskId: appointment.ownerProcessTaskId,
      processTaskAppointmentId: appointment.id,
      userId: validatedAppointmentData.assigneeUserId,
      dateFrom: validatedAppointmentData.dateFrom,
      dateTo: validatedAppointmentData.dateTo
    });

    this.dialog?.close();
  }

  private handleAppointmentDateChanged(): void {
    this.dateRangeChangeHandler.autoAdjustAndAccept();
  }

  private async createAppointment(
    appointmentData: ValidatedAppointmentData
  ): Promise<ProcessTaskAppointment> {
    if (this.appointment) {
      const newAppointment = this.createAppointmentFromAppointment(
        appointmentData,
        this.appointment
      );

      await this.copyPositionRelationsIfAllowed(
        this.appointment,
        newAppointment
      );

      return newAppointment;
    } else if (this.processTask) {
      return this.createAppointmentForProcessTask(
        appointmentData,
        this.processTask
      );
    } else {
      throw new Error(
        "can't create an appointment without an _appointment and _processTask"
      );
    }
  }

  private createAppointmentDataForAppointment(
    appointment: ProcessTaskAppointment,
    processConfigurationFollowUpAppointment: ProcessConfigurationFollowUpAppointment,
    relativeTo: RelativeTo
  ): AppointmentData {
    const prefix = processConfigurationFollowUpAppointment.prefix
      ? processConfigurationFollowUpAppointment.prefix + ' '
      : '';

    const relations =
      this.entityManager.processTaskAppointmentToUserRepository.getByProcessTaskAppointmentId(
        appointment.id
      );
    const dateFroms = relations
      .map((r) => r.dateFrom)
      .filter((i): i is string => i != null)
      .sort((a, b) => a.localeCompare(b));
    const dateTos = relations
      .map((r) => r.dateTo)
      .filter((i): i is string => i != null)
      .sort((a, b) => a.localeCompare(b));
    const firstDateFrom = dateFroms[0] ?? null;
    const lastDateTo = dateTos[dateTos.length - 1] ?? null;

    const baseDate = this.getBaseDate(relativeTo, firstDateFrom);

    return {
      name: prefix + (appointment.name || ''),
      assigneeUserId: relations[0]?.userId ?? null,
      note: appointment.note,
      dateFrom: this.createFollowUpDate(
        baseDate,
        firstDateFrom,
        processConfigurationFollowUpAppointment
      ),
      dateTo: this.createFollowUpDate(
        baseDate,
        lastDateTo,
        processConfigurationFollowUpAppointment
      )
    };
  }

  private createDefaultAppointmentData(
    processConfigurationFollowUpAppointment: ProcessConfigurationFollowUpAppointment
  ): AppointmentData {
    const prefix = processConfigurationFollowUpAppointment.prefix
      ? processConfigurationFollowUpAppointment.prefix + ' '
      : '';
    const currentUser = this.currentUserService.getRequiredCurrentUser();

    return {
      name: prefix,
      assigneeUserId: currentUser.id,
      note: null,
      dateFrom: this.createFollowUpDate(
        new Date(),
        null,
        processConfigurationFollowUpAppointment
      ),
      dateTo: this.createFollowUpDate(
        new Date(),
        null,
        processConfigurationFollowUpAppointment
      )
    };
  }

  private getBaseDate(relativeTo: RelativeTo, dateFrom: string | null): Date {
    switch (relativeTo) {
      case RelativeTo.Appointment:
        if (dateFrom) {
          return new Date(dateFrom);
        } else {
          return new Date();
        }

      case RelativeTo.Today:
      default:
        return new Date();
    }
  }

  private createFollowUpDate(
    baseDate: Date,
    originalIsoDate: string | null,
    processConfigurationFollowUpAppointment: ProcessConfigurationFollowUpAppointment
  ): string {
    const offset = processConfigurationFollowUpAppointment.offset || 0;
    const followUpDate = new Date(baseDate.getTime() + offset);

    if (originalIsoDate) {
      const originalDate = new Date(originalIsoDate);
      followUpDate.setMilliseconds(originalDate.getMilliseconds());
      followUpDate.setSeconds(originalDate.getSeconds());
      followUpDate.setMinutes(originalDate.getMinutes());
      followUpDate.setHours(originalDate.getHours());
    }

    return followUpDate.toISOString();
  }

  private createAppointmentFromAppointment(
    appointmentData: ValidatedAppointmentData,
    originalAppointment: ProcessTaskAppointment
  ): ProcessTaskAppointment {
    return this.entityManager.processTaskAppointmentRepository.create({
      name: appointmentData.name,
      coordinatorUserId: originalAppointment.coordinatorUserId,
      createdFromAppointmentId: originalAppointment.id,
      note: appointmentData.note,
      smsNotification: originalAppointment.smsNotification,
      processConfigurationStepId:
        originalAppointment.processConfigurationStepId,
      temporaryGroupName: originalAppointment.temporaryGroupName,
      ownerProcessTaskGroupId: originalAppointment.ownerProcessTaskGroupId,
      ownerProcessTaskId: originalAppointment.ownerProcessTaskId,
      ownerUserGroupId: originalAppointment.ownerUserGroupId
    });
  }

  private createAppointmentForProcessTask(
    appointmentData: ValidatedAppointmentData,
    processTask: ProcessTask
  ): ProcessTaskAppointment {
    return this.entityManager.processTaskAppointmentRepository.create({
      name: appointmentData.name,
      coordinatorUserId: appointmentData.assigneeUserId,
      note: appointmentData.note,
      smsNotification: ProcessTaskAppointmentSmsNotification.NO,
      processConfigurationStepId: processTask.currentProcessConfigurationStepId,
      temporaryGroupName: processTask.temporaryGroupName,
      ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
      ownerProcessTaskId: processTask.id,
      ownerUserGroupId: processTask.ownerUserGroupId
    });
  }

  private async copyPositionRelationsIfAllowed(
    source: ProcessTaskAppointment,
    target: ProcessTaskAppointment
  ): Promise<void> {
    const canCreateProcessTaskAppointmentToProcessTaskPositions =
      await this.permissionsService.useAdapterOnce({
        entityName: EntityName.ProcessTaskAppointment,
        useAdapter: (adapter) => {
          return adapter.canCreateProcessTaskAppointmentToProcessTaskPositions(
            target
          );
        }
      });

    if (!canCreateProcessTaskAppointmentToProcessTaskPositions) {
      return;
    }

    const relations =
      this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskAppointmentId(
        source.id
      );

    for (const relation of relations) {
      this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.create(
        {
          processTaskAppointmentId: target.id,
          processTaskPositionId: relation.processTaskPositionId,
          ownerProcessTaskGroupId: target.ownerProcessTaskGroupId,
          ownerProcessTaskId: target.ownerProcessTaskId,
          ownerUserGroupId: target.ownerUserGroupId,
          temporaryGroupName: target.temporaryGroupName
        }
      );
    }
  }

  @computedFrom('appointment.ownerUserGroupId', 'processTask.ownerUserGroupId')
  private get ownerUserGroupId(): string | null {
    if (this.appointment) {
      return this.appointment.ownerUserGroupId;
    } else if (this.processTask) {
      return this.processTask.ownerUserGroupId;
    }

    return null;
  }

  private validateAppointmentData(): ValidatedAppointmentData | null {
    assertNotNullOrUndefined(
      this.appointmentData,
      "can't validateAppointmentData without appointmentData"
    );

    if (!this.appointmentData.assigneeUserId) {
      this.errorTk =
        'operationsComponents.createFollowUpProcessTaskAppointmentDialog.noUserSelected';
      return null;
    }

    this.errorTk = null;
    return {
      ...(this.appointmentData as ValidatedAppointmentData)
    };
  }

  public static async open(options: TOpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

export type TOpenOptions = IAppointmentOpenOptions | IProcessTaskOpenOptions;

export interface IAppointmentOpenOptions {
  appointment: ProcessTaskAppointment;
  processTask: null;
  relativeTo: RelativeTo;
  processConfigurationFollowUpAppointment: ProcessConfigurationFollowUpAppointment;
}

export interface IProcessTaskOpenOptions {
  appointment: null;
  processTask: ProcessTask;
  processConfigurationFollowUpAppointment: ProcessConfigurationFollowUpAppointment;
}

export enum RelativeTo {
  Appointment = 'appointment',
  Today = 'today'
}

type AppointmentData = {
  name: string | null;
  assigneeUserId: string | null;
  dateFrom: string | null;
  dateTo: string | null;
  note: string | null;
};

type ValidatedAppointmentData = AppointmentData & {
  assigneeUserId: string;
};
