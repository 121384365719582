import { autoinject } from 'aurelia-framework';
import { TDefaultReadingPropertyValues } from 'common/Types/ProcessConfigurationMeasurePointType';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskMeasurePoint } from '../classes/EntityManager/entities/ProcessTaskMeasurePoint/types';
import { ProcessTaskMeasurePointReading } from '../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types';
import { defaultProcessTaskMeasurePointReadingProperties as defaultReadingProperties } from '../data/Trockenmax/defaultProcessTaskMeasurePointReadingProperties';

@autoinject()
export class ProcessTaskMeasurePointReadingCreationService {
  constructor(private readonly entityManager: AppEntityManager) {}

  public createReadingWithProperties(
    measurePoint: ProcessTaskMeasurePoint,
    appointment: ProcessTaskAppointment
  ): ProcessTaskMeasurePointReading {
    const measurePointReading = this.createReading(measurePoint, appointment);

    this.createReadingProperties(measurePoint, measurePointReading);

    return measurePointReading;
  }

  private createReading(
    measurePoint: ProcessTaskMeasurePoint,
    appointment: ProcessTaskAppointment
  ): ProcessTaskMeasurePointReading {
    const measurePointReading =
      this.entityManager.processTaskMeasurePointReadingRepository.create({
        ownerProcessTaskGroupId: measurePoint.ownerProcessTaskGroupId,
        ownerProcessTaskId: measurePoint.ownerProcessTaskId,
        ownerUserGroupId: measurePoint.ownerUserGroupId,
        processTaskAppointmentId: appointment.id,
        processTaskMeasurePointId: measurePoint.id,
        date: new Date().toISOString()
      });

    return measurePointReading;
  }

  private createReadingProperties(
    measurePoint: ProcessTaskMeasurePoint,
    measurePointReading: ProcessTaskMeasurePointReading
  ): void {
    const type = measurePoint.processConfigurationMeasurePointTypeId
      ? this.entityManager.processConfigurationMeasurePointTypeRepository.getById(
          measurePoint.processConfigurationMeasurePointTypeId
        )
      : null;
    const json = type ? type.defaultReadingPropertyValuesJson : null;
    const defaultValues: TDefaultReadingPropertyValues = json
      ? JSON.parse(json)
      : [];

    for (const property of defaultReadingProperties) {
      this.entityManager.propertyRepository.create({
        ...property,
        ...this.getDefaultReadingPropertyValueOverride(
          property.name,
          defaultValues
        ),
        ownerProcessTaskGroupId: measurePointReading.ownerProcessTaskGroupId,
        ownerProcessTaskId: measurePointReading.ownerProcessTaskId,
        ownerUserGroupId: measurePointReading.ownerUserGroupId,
        processTaskMeasurePointReadingId: measurePointReading.id
      });
    }
  }

  private getDefaultReadingPropertyValueOverride(
    propertyName: string | null,
    defaultValues: TDefaultReadingPropertyValues
  ): { value?: string } {
    const item = defaultValues.find((i) => i.propertyName === propertyName);

    if (item) {
      return {
        value: item.defaultValue
      };
    }

    return {};
  }
}
