import { computedFrom } from 'aurelia-framework';
import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';
import { EndpointResult } from 'common/WebSocketEndpoints/WebSocketEndpointConfigurations';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Subscribable } from '../../classes/SubscribableArray/SubscribableArray';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../SubscriptionManagerService';
import { LastResponsePropertyBinder } from './ThingAndThingGroupNameService';

export class ThingGroupNameHandle implements Subscribable {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly lastResponsePropertyBinder: LastResponsePropertyBinder;
  private readonly thingId: string;

  private thingGroup: ThingGroup | null = null;
  private lastResponse: EndpointResult<
    'entityInfoModule',
    'getThingAndThingGroupNames'
  > | null = null;

  constructor({
    entityManager,
    subscriptionManagerService,
    lastResponsePropertyBinder,
    thingId
  }: {
    entityManager: AppEntityManager;
    subscriptionManagerService: SubscriptionManagerService;
    lastResponsePropertyBinder: LastResponsePropertyBinder;
    thingId: string;
  }) {
    this.entityManager = entityManager;
    this.subscriptionManagerService = subscriptionManagerService;
    this.lastResponsePropertyBinder = lastResponsePropertyBinder;
    this.thingId = thingId;
  }

  public subscribe(): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      this.updateThingGroup.bind(this)
    );
    this.updateThingGroup();

    subscriptionManager.addDisposable(
      this.lastResponsePropertyBinder.registerBinding(
        'value',
        (lastResponse) => {
          this.lastResponse = lastResponse;
        }
      )
    );

    return subscriptionManager.toDisposable();
  }

  @computedFrom('thingGroup.name', 'lastResponse')
  public get name(): string | null {
    if (this.thingGroup) {
      return this.thingGroup.name;
    }

    if (!this.lastResponse) {
      return null;
    }

    const thingGroupId =
      this.lastResponse.thingIdToThingData[this.thingId]?.thingGroupId;

    if (!thingGroupId) {
      return null;
    }

    return (
      this.lastResponse.thingGroupIdToThingGroupData[thingGroupId]?.name ?? null
    );
  }

  @computedFrom(
    'thingGroup.streetName',
    'thingGroup.zip',
    'thingGroup.municipality',
    'lastResponse'
  )
  public get address(): string | null {
    if (this.thingGroup) {
      return ThingGroupHelper.getThingGroupAddressString(
        this.thingGroup.streetName,
        this.thingGroup.zip,
        this.thingGroup.municipality
      );
    }

    if (!this.lastResponse) {
      return null;
    }

    const thingGroupId =
      this.lastResponse.thingIdToThingData[this.thingId]?.thingGroupId;

    if (!thingGroupId) {
      return null;
    }

    const thingGroupData =
      this.lastResponse.thingGroupIdToThingGroupData[thingGroupId];
    if (!thingGroupData) {
      return null;
    }

    return ThingGroupHelper.getThingGroupAddressString(
      thingGroupData.streetName,
      thingGroupData.zip,
      thingGroupData.municipality
    );
  }

  private updateThingGroup(): void {
    const thing = this.entityManager.thingRepository.getById(this.thingId);

    this.thingGroup = thing?.thingGroupId
      ? this.entityManager.thingGroupRepository.getById(thing.thingGroupId)
      : null;
  }
}
