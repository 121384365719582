import { autoinject, bindable } from 'aurelia-framework';
import { EntityUtils } from '@record-it-npm/synchro-client';

import {
  UserDefinedEntityConfigPropertyConfigCustomColumn,
  UserDefinedEntityConfigPropertyConfigCustomColumnPermission
} from 'common/Types/Entities/UserDefinedEntityConfigPropertyConfig/UserDefinedEntityConfigPropertyConfigDto';

import { UserDefinedEntityConfigPropertyConfig } from '../../../classes/EntityManager/entities/UserDefinedEntityConfigPropertyConfig/types';
import { UserDefinedEntityConfigPropertyDefinitionHandle } from '../../property-definition-widget/PropertyDefinitionWidgetHandle/UserDefinedEntityConfigPropertyDefinitionHandle/UserDefinedEntityConfigPropertyDefinitionHandle';
import { PropertyDefinitionWidgetAdditionalFieldsComponent } from '../property-definition-widget-additional-fields-component';
import { computed } from '../../../hooks/computed';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { expression, model } from '../../../hooks/dependencies';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';

@autoinject()
export class UserDefinedEntityConfigPropertyDefinitionAdditionalFields
  implements
    PropertyDefinitionWidgetAdditionalFieldsComponent<UserDefinedEntityConfigPropertyConfig>
{
  @bindable()
  public propertyDefinitionHandle: UserDefinedEntityConfigPropertyDefinitionHandle | null =
    null;

  @subscribableLifecycle()
  protected readonly propertyPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserDefinedEntityConfigPropertyConfig];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.propertyPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.UserDefinedEntityConfigPropertyConfig,
        context: this,
        expression: 'propertyDefinitionHandle.property'
      });
  }

  @computed(
    expression('propertyDefinitionHandle'),
    model(EntityName.UserDefinedEntityConfigPropertyConfig)
  )
  protected get customColumConfigOption(): Array<CustomColumnConfigOption> {
    const configs: Array<CustomColumnConfigOption> = [];

    if (this.canUseFirstCustomColumn()) {
      configs.push({
        labelTk:
          'propertyComponents.propertyDefinitionWidget.additionalFields.userDefinedEntityProperty.customColumnConfig.displayInFirstColumn',
        value: UserDefinedEntityConfigPropertyConfigCustomColumn.FIRST
      });
    }

    if (this.canUseSecondCustomColumn()) {
      configs.push({
        labelTk:
          'propertyComponents.propertyDefinitionWidget.additionalFields.userDefinedEntityProperty.customColumnConfig.displayInSecondColumn',
        value: UserDefinedEntityConfigPropertyConfigCustomColumn.SECOND
      });
    }

    return configs;
  }

  protected handleUserDefinedEntityPropertyConfigChanged(): void {
    assertNotNullOrUndefined(
      this.propertyDefinitionHandle,
      'cannot update property config without handle'
    );
    this.entityManager.userDefinedEntityConfigPropertyConfigRepository.update(
      this.propertyDefinitionHandle.property
    );
  }

  protected customColumnPermissionOptions: Array<CustomColumnConfigPermissionOption> =
    [
      {
        labelTk:
          'propertyComponents.propertyDefinitionWidget.additionalFields.userDefinedEntityProperty.customColumnConfig.permissionMode.readOnly',
        value:
          UserDefinedEntityConfigPropertyConfigCustomColumnPermission.READ_ONLY
      },
      {
        labelTk:
          'propertyComponents.propertyDefinitionWidget.additionalFields.userDefinedEntityProperty.customColumnConfig.permissionMode.readWrite',
        value:
          UserDefinedEntityConfigPropertyConfigCustomColumnPermission.READ_WRITE
      }
    ];

  @computed(expression('propertyDefinitionHandle'))
  protected get permissionsRadioButtonGroupName(): string {
    const trackingKey = this.propertyDefinitionHandle
      ? EntityUtils.getTrackingKey(this.propertyDefinitionHandle.property)
      : '';
    return (
      'property-definition-widget-additional-fields--PermissionsRadioButton' +
      trackingKey
    );
  }

  private canUseFirstCustomColumn(): boolean {
    if (
      this.propertyDefinitionHandle?.property.customColumn ===
      UserDefinedEntityConfigPropertyConfigCustomColumn.FIRST
    )
      return true;
    return this.canUseCustomColumn(
      UserDefinedEntityConfigPropertyConfigCustomColumn.FIRST
    );
  }

  private canUseSecondCustomColumn(): boolean {
    if (
      this.propertyDefinitionHandle?.property.customColumn ===
      UserDefinedEntityConfigPropertyConfigCustomColumn.SECOND
    )
      return true;
    return this.canUseCustomColumn(
      UserDefinedEntityConfigPropertyConfigCustomColumn.SECOND
    );
  }

  private canUseCustomColumn(
    column: UserDefinedEntityConfigPropertyConfigCustomColumn
  ): boolean {
    const properties = this.propertyDefinitionHandle?.property
      .userDefinedEntityConfigId
      ? this.entityManager.userDefinedEntityConfigPropertyConfigRepository.getByUserDefinedEntityConfigId(
          this.propertyDefinitionHandle.property.userDefinedEntityConfigId
        )
      : [];
    return properties.every((p) => p.customColumn !== column);
  }
}

type CustomColumnConfigOption = {
  labelTk: string;
  value: UserDefinedEntityConfigPropertyConfigCustomColumn;
};

type CustomColumnConfigPermissionOption = {
  labelTk: string;
  value: UserDefinedEntityConfigPropertyConfigCustomColumnPermission;
};
