import { bindable, autoinject } from 'aurelia-framework';

import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { Utils } from '../../classes/Utils/Utils';

/**
 * @slot content
 * @slot separatorMiddleContent
 * @slot separatorLabel - custom label content for the separator row
 *
 * @attribute data-full-width-content - normally the label will be aside the content, this will stack the label above the content
 *
 * @attribute data-full-width-content-for-nested-containers - nested containers will behave like having data-full-width-content set
 *
 * @attribute data-reduced-content-margin - less margin between the content and header, only works for bigLabel=true atm
 *
 * @attribute data-no-margin-top - can be used if this is the first element in a container
 * @attribute data-no-margin-bottom - can be used if this is the last element in a container
 *
 * @event starts-expanding - fired when container starts expanding
 * @event has-expanded - fired when container has finished expanding
 * @event checked-changed - fired when the checkbox state changed (detail: TCheckedChangedEventDetail)
 */
@autoinject()
export class ExpandableContainer {
  private static storeName = 'permanent';

  /**
   * text that will be shown besides content
   * will also be shown when the the container is not expanded
   */
  @bindable()
  public label: string | null = null;

  /**
   * if a name is given, than the expanded/collapsed state will be saved/loaded to this name
   * names can be shared, but expanded states will not be updated on runtime, it will only be restored when creating the element again
   */
  @bindable()
  public name: string | null = null;

  /**
   * shows the checkbox in the label
   */
  @bindable()
  public showCheckbox: boolean = false;

  /**
   * sets the state of the checkbox in the label
   */
  @bindable()
  public checked: boolean = false;

  /**
   * dis-/enables the checkbox in the label
   */
  @bindable()
  public checkedEnabled: boolean = false;

  /**
   * positions the label in the separator row and makes it more prominent
   */
  @bindable()
  public bigLabel: boolean = false;

  @bindable()
  public collapseIf: boolean = false;

  /**
   * show a close button in the footer
   */
  @bindable()
  public showFooterCloseButton: boolean = false;

  /**
   * shows a centered toggle icon instead of the separator row
   */
  @bindable()
  public useToggleIcon: boolean = false;

  private expanded: boolean = true;
  private contentElement: HTMLElement | null = null;
  private readonly domElement: HTMLElement;
  private checkboxElement: HTMLElement | null = null;
  private separatorMiddleContentElement: HTMLElement | null = null;
  private clickEventToIgnore: MouseEvent | null = null;
  private animator: ShowHideAnimator | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  public ignoreClickEvent(event: MouseEvent): void {
    this.clickEventToIgnore = event;
  }

  public expand(): void {
    // shouldn't be called before the element is bound
    if (this.expanded || !this.animator) {
      return;
    }

    this.expanded = true;
    this.fireEvent('starts-expanding');
    void this.animator.slideDown().then(() => {
      this.fireEvent('has-expanded');
    });
    this.saveExpandedState();
  }

  public collapse(): void {
    // shouldn't be called before the element is bound
    if (!this.expanded || !this.animator) {
      return;
    }

    this.expanded = false;
    void this.animator.slideUp();
    this.saveExpandedState();
  }

  public isExpanded(): boolean {
    return this.expanded;
  }

  protected bind(): void {
    if (!this.contentElement) {
      throw new Error("content element isn't bound");
    }

    if (!this.animator) {
      this.animator = new ShowHideAnimator(this.contentElement);
    }

    void this.loadStoredExpandedState();

    if (this.collapseIf || (this.showCheckbox && this.checked === false)) {
      this.collapse();
    }
  }

  private collapseIfChanged(): void {
    if (this.collapseIf) {
      this.collapse();
    }
  }

  private handleToggleExpandedClick(event: MouseEvent): boolean {
    if (event.defaultPrevented) return true;

    if (this.consumeClickEventToIgnore(event)) {
      return true;
    }

    if (this.showCheckbox && this.checked === false) return true;

    if (
      this.checkboxElement &&
      this.mouseEventIsInsideElement(event, this.checkboxElement)
    )
      return true;
    if (
      this.separatorMiddleContentElement &&
      this.mouseEventIsInsideElement(event, this.separatorMiddleContentElement)
    )
      return true;

    if (this.expanded) {
      this.collapse();
    } else {
      this.expand();
    }

    return false;
  }

  private handleCloseButtonClick(): void {
    this.collapse();
  }

  private consumeClickEventToIgnore(event: MouseEvent): boolean {
    const toIgnore = this.clickEventToIgnore;
    this.clickEventToIgnore = null;

    return event === toIgnore;
  }

  private mouseEventIsInsideElement(
    event: MouseEvent,
    element: HTMLElement
  ): boolean {
    if (!event.target) {
      return false;
    }

    let inside = false;

    Utils.walkThroughParentElements(
      event.target as HTMLElement,
      (currentElement) => {
        if (currentElement === element) {
          inside = true;
          return false;
        }

        return true;
      }
    );

    return inside;
  }

  private nameChanged(): void {
    void this.loadStoredExpandedState();
  }

  private checkedChanged(): void {
    if (this.checked) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  private async loadStoredExpandedState(): Promise<void> {
    if (!this.name) {
      return;
    }

    const state = await DataStorageHelper.getItem(
      this.getStorageName(this.name),
      ExpandableContainer.storeName
    );
    this.expanded = state !== false;
    if (this.contentElement) {
      this.contentElement.style.display = this.expanded ? '' : 'none';
    }
  }

  private saveExpandedState(): void {
    if (this.name) {
      void DataStorageHelper.setItem(
        this.getStorageName(this.name),
        this.expanded,
        ExpandableContainer.storeName
      );
    }
  }

  private getStorageName(name: string): string {
    return `ExpandableContainer::${name}_expanded`;
  }

  private handleCheckboxCheckedChanged(): void {
    DomEventHelper.fireEvent<CheckedChangedEvent>(this.domElement, {
      name: 'checked-changed',
      detail: {
        checked: this.checked
      }
    });
  }

  private fireEvent(name: string): void {
    DomEventHelper.fireEvent(this.domElement, { name: name, detail: null });
  }
}

export type CheckedChangedEvent = NamedCustomEvent<
  'checked-changed',
  {
    checked: boolean;
  }
>;
