import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { GetProcessTaskGroupsFilterAddressFilterType } from 'common/EndpointTypes/OperationsEndpointsTypes';
import { DialogIconType } from 'common/Enums/DialogIconType';
import { ProcessTaskGroupFilterMode } from 'common/Enums/ProcessTaskGroupFilterMode';

import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';

import { Dialogs } from '../../classes/Dialogs';
import { NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import {
  ProcessTaskGroupFilter,
  ProcessTaskGroupFilterCreationEntity
} from '../../classes/EntityManager/entities/ProcessTaskGroupFilter/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import {
  ButtonType,
  GlobalCustomDialog
} from '../../dialogs/global-custom-dialog/global-custom-dialog';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import {
  FilterSettings,
  ProcessTaskGroupFilterSettingsService
} from '../../services/ProcessTaskGroupFilterSettingsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event process-task-group-filter-changed
 */
@autoinject()
export class ProcessTaskGroupFilterSelect {
  @bindable()
  public processConfiguration: ProcessConfiguration | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly userGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  @subscribableLifecycle()
  protected readonly selectedFilterPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskGroupFilter];

  protected processTaskGroupFilters: Array<ProcessTaskGroupFilter> = [];

  protected selectedFilter: ProcessTaskGroupFilter | null = null;

  protected boundHandleCreateNewFilter = this.handleCreateNewFilter.bind(this);

  protected moreButtonChoices: Array<MoreButtonChoice> = [
    {
      name: 'saveFilter',
      labelTk: 'operationsComponents.processTaskGroupFilterSelect.saveFilter',
      iconClass: 'fal fa-save',
      disabledContext: this,
      disabledPropertyName: 'saveFilterChoiceDisabled'
    },
    {
      name: 'deleteFilter',
      labelTk: 'operationsComponents.processTaskGroupFilterSelect.deleteFilter',
      iconClass: 'fal fa-trash-alt',
      disabledContext: this,
      disabledPropertyName: 'deleteSelectedFilterChoiceDisabled'
    }
  ];

  protected boundHandleMoreButtonChoiceSelected =
    this.handleMoreButtonChoiceSelected.bind(this);

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskGroupFilterSettingsService: ProcessTaskGroupFilterSettingsService,
    private readonly activeUserCompanySettingsService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.selectedFilterPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskGroupFilter,
        context: this,
        expression: 'selectedFilter'
      });

    this.userGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForEntityIdOfPropertyValue({
        entityName: EntityName.UserGroup,
        context: this as ProcessTaskGroupFilterSelect,
        propertyName: 'processConfiguration',
        idPropertyName: 'ownerUserGroupId'
      });
  }

  // ********** Aurelia Lifecycle **********

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroupFilter,
      this.updateProcessTaskGroupFilters.bind(this)
    );

    this.processTaskGroupFilterSettingsService.subscribeToChange(
      this,
      this.filterSettingsUpdated.bind(this)
    );

    this.updateProcessTaskGroupFilters();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // ********** Updater **********

  private updateProcessTaskGroupFilters(): void {
    this.processTaskGroupFilters =
      this.entityManager.processTaskGroupFilterRepository
        .getAll()
        .sort((f1, f2) => (f1.name ?? '').localeCompare(f2.name ?? ''));
    this.updateProcessTaskGroupFilterIdIfNecessary();
  }

  private updateProcessTaskGroupFilterIdIfNecessary(): void {
    const isIncluded = this.selectedFilter
      ? this.processTaskGroupFilters.includes(this.selectedFilter)
      : false;

    if (!isIncluded) {
      this.selectedFilter = null;
    }
  }

  // ********** Handlers **********

  protected handleFilterSelected(): void {
    const fallbackFilterMode =
      this.activeUserCompanySettingsService.getSettingProperty(
        'operations.defaultEditProcessTaskGroupFilterMode'
      ) ?? ProcessTaskGroupFilterMode.ALL;

    this.processTaskGroupFilterSettingsService.modifyFilterSettings({
      selectedProcessTaskGroupFilterId: this.selectedFilter?.id ?? null,
      assigneeUserId: this.selectedFilter?.assigneeUserId ?? null,
      includeArchive: this.selectedFilter?.includeArchive ?? false,
      customActionStatusFilterText:
        this.selectedFilter?.customActionStatusFilterText ?? null,
      selectedActionStatusId:
        this.selectedFilter?.selectedActionStatusId ?? null,
      selectedActionStatusOptionType:
        this.selectedFilter?.selectedActionStatusOptionType ?? null,
      selectedProcessConfigurationStepIds:
        this.selectedFilter?.selectedProcessConfigurationStepIds ?? [],
      excludedProcessConfigurationStepIds:
        this.selectedFilter?.excludedProcessConfigurationStepIds ?? [],
      filterMode: this.selectedFilter?.filterMode ?? fallbackFilterMode,
      addressFilterType:
        this.selectedFilter?.addressFilterType ??
        GetProcessTaskGroupsFilterAddressFilterType.ALL,
      lastEditedBeforeDays: this.selectedFilter?.lastEditedBeforeDays ?? 0,
      lastEditedBeforeDaysEnabled:
        this.selectedFilter?.lastEditedBeforeDaysEnabled ?? false
    });
  }

  protected handleCreateNewFilter(name: string): void {
    assertNotNullOrUndefined(
      this.processConfiguration,
      'cannot create new process task group filter without a process configuration'
    );

    this.entityManager.processTaskGroupFilterRepository.create({
      ownerUserGroupId: this.processConfiguration.ownerUserGroupId,
      processConfigurationId: this.processConfiguration.id,
      name,
      ...this.compileProcessTaskGroupFilterData()
    });
  }

  protected handleUpdateCurrentFilter(): void {
    const selectedFilter = this.selectedFilter;
    assertNotNullOrUndefined(
      selectedFilter,
      "can't ProcessTaskGroupFilterSelect.handleUpdateCurrentFilter without selectedFilter"
    );

    void GlobalCustomDialog.open({
      titleTk:
        'operationsComponents.processTaskGroupFilterSelect.saveProcessTaskGroupFilterDialogTitle',
      textTk:
        'operationsComponents.processTaskGroupFilterSelect.saveProcessTaskGroupFilterDialogText',
      textTkParams: { filterName: selectedFilter.name },
      icon: DialogIconType.WARNING,
      buttons: [
        {
          textTk: 'general.yes',
          className: 'record-it-button-primary'
        },
        {
          textTk: 'general.no',
          type: ButtonType.CANCEL
        }
      ]
    }).then(() => {
      Object.assign(selectedFilter, this.compileProcessTaskGroupFilterData());
      this.entityManager.processTaskGroupFilterRepository.update(
        selectedFilter
      );
    });
  }

  protected handleMoreButtonChoiceSelected(params: {
    name: string | null;
  }): void {
    switch (params.name) {
      case 'saveFilter':
        this.handleUpdateCurrentFilter();
        break;

      case 'deleteFilter':
        this.handleDeleteCurrentFilter();
        break;

      default:
    }
  }

  private handleDeleteCurrentFilter(): void {
    const selectedFilter = this.selectedFilter;
    if (!selectedFilter) return;

    void Dialogs.deleteEntityDialog(selectedFilter).then(() =>
      this.entityManager.processTaskGroupFilterRepository.delete(selectedFilter)
    );
  }

  protected handleQuickSelectButtonClicked(
    filter: ProcessTaskGroupFilter
  ): void {
    if (filter === this.selectedFilter) {
      this.selectedFilter = null;
    } else {
      this.selectedFilter = filter;
    }

    this.handleFilterSelected();
  }

  @computedFrom('selectedFilterPermissionsHandle.revision')
  protected get saveFilterChoiceDisabled(): boolean {
    const fieldsToCheck = Object.keys(
      this.compileProcessTaskGroupFilterData()
    ) as Array<keyof ProcessTaskGroupFilterData>;

    return !fieldsToCheck.every((field) => {
      return this.selectedFilterPermissionsHandle.canEditField[field];
    });
  }

  @computedFrom('selectedFilterPermissionsHandle.canDeleteEntity')
  protected get deleteSelectedFilterChoiceDisabled(): boolean {
    return !this.selectedFilterPermissionsHandle.canDeleteEntity;
  }

  private filterSettingsUpdated(filterSettings: FilterSettings): void {
    if (
      filterSettings.selectedProcessTaskGroupFilterId !==
      (this.selectedFilter?.id ?? null)
    ) {
      this.selectedFilter = filterSettings.selectedProcessTaskGroupFilterId
        ? this.entityManager.processTaskGroupFilterRepository.getById(
            filterSettings.selectedProcessTaskGroupFilterId
          )
        : null;
    }
  }

  private compileProcessTaskGroupFilterData(): ProcessTaskGroupFilterData {
    const filterSettings =
      this.processTaskGroupFilterSettingsService.getFilterSettings();
    return {
      selectedProcessConfigurationStepIds:
        filterSettings.selectedProcessConfigurationStepIds,
      excludedProcessConfigurationStepIds:
        filterSettings.excludedProcessConfigurationStepIds,

      includeArchive: filterSettings.includeArchive,
      assigneeUserId: filterSettings.assigneeUserId,
      selectedActionStatusOptionType:
        filterSettings.selectedActionStatusOptionType,
      selectedActionStatusId: filterSettings.selectedActionStatusId,
      customActionStatusFilterText: filterSettings.customActionStatusFilterText,
      filterMode: filterSettings.filterMode,
      addressFilterType: filterSettings.addressFilterType,
      lastEditedBeforeDaysEnabled: filterSettings.lastEditedBeforeDaysEnabled,
      lastEditedBeforeDays: filterSettings.lastEditedBeforeDays
    };
  }
}

export type ProcessTaskGroupFilterChangedEvent = NamedCustomEvent<
  'process-task-group-filter-changed',
  { processTaskGroupFilterId: string | null }
>;

type ProcessTaskGroupFilterData = Omit<
  ProcessTaskGroupFilterCreationEntity,
  'name' | 'ownerUserGroupId' | 'processConfigurationId'
>;
