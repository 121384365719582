import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  CheckUsersAreAvailableResponse,
  UserRequest,
  UserResponse
} from 'common/EndpointTypes/ProcessTaskAppointmentEndpointsHandler';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskAppointmentToUser } from '../../classes/EntityManager/entities/ProcessTaskAppointmentToUser/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { DirtyChecker } from '../../classes/Performance/DirtyChecker';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Utils } from '../../classes/Utils/Utils';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskAppointmentToUsersByProcessTaskAppointmentId,
  ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer
} from '../../computedValues/computers/ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskAppointmentToUserDeleteClickedEvent } from '../process-task-appointment-user-widget/process-task-appointment-user-widget';

@autoinject()
export class ProcessTaskAppointmentUsersWidget {
  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTaskAppointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointment];

  private isAttached: boolean = false;

  private relationsByAppointmentId: ProcessTaskAppointmentToUsersByProcessTaskAppointmentId =
    new Map();

  private relations: Array<ProcessTaskAppointmentToUser> = [];
  protected hints: Array<Hint> = [];
  protected userAvailabilityError: boolean = false;
  protected userResponses: Array<UserResponse> = [];
  private userRequestsDirtyChecker = new DirtyChecker<{
    userRequests: Array<UserRequest>;
  }>();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    private readonly currentUserService: CurrentUserService,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskAppointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskAppointment,
        context: this as ProcessTaskAppointmentUsersWidget,
        propertyName: 'processTaskAppointment'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass:
          ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer,
        computeData: {},
        callback: (map) => {
          this.relationsByAppointmentId = map;
          this.updateRelations();
        }
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskAppointmentChanged(): void {
    if (this.isAttached) {
      this.updateRelations();
    }
  }

  private updateRelations(): void {
    if (this.processTaskAppointment) {
      this.relations =
        this.relationsByAppointmentId.get(this.processTaskAppointment.id) ?? [];
    } else {
      this.relations = [];
    }

    void this.updateUserResponses();
    this.updateHints();
  }

  protected handleAddRelationClick(): void {
    assertNotNullOrUndefined(
      this.processTaskAppointment,
      "can't ProcessTaskAppointmentUsersWidget.handleAddRelationClick without processTaskAppointment"
    );

    const lastRelation = this.relations[this.relations.length - 1];
    const relation =
      this.entityManager.processTaskAppointmentToUserRepository.create({
        ownerProcessTaskGroupId:
          this.processTaskAppointment.ownerProcessTaskGroupId,
        ownerProcessTaskId: this.processTaskAppointment.ownerProcessTaskId,
        ownerUserGroupId: this.processTaskAppointment.ownerUserGroupId,
        processTaskAppointmentId: this.processTaskAppointment.id,
        userId: this.currentUserService.getRequiredCurrentUser().id,
        dateFrom: lastRelation?.dateFrom ?? null,
        dateTo: lastRelation?.dateTo ?? null
      });

    this.relations.push(relation);
    this.logChange();
    this.updateHints();
  }

  protected handleRelationChanged(): void {
    this.logChange();
    this.updateHints();
  }

  protected handleRelationDeleteClicked(
    event: ProcessTaskAppointmentToUserDeleteClickedEvent
  ): void {
    void Dialogs.deleteEntityDialog(
      event.detail.processTaskAppointmentToUser
    ).then(() => {
      this.entityManager.processTaskAppointmentToUserRepository.delete(
        event.detail.processTaskAppointmentToUser
      );
      this.logChange();
    });
  }

  private logChange(): void {
    if (this.processTaskAppointment) {
      this.processTaskLoggingService.logProcessTaskSubEntityModified({
        entityName: EntityName.ProcessTaskAppointment,
        entity: this.processTaskAppointment,
        property: null,
        displayNameAtLogTime: this.processTaskAppointment.name
      });
    }
  }

  private updateHints(): void {
    const relationsByUserId = Utils.groupBy(this.relations, (r) => r.userId);
    const hints: Array<Hint> = [];

    for (const [userId, relations] of relationsByUserId.entries()) {
      if (relations.length > 1) {
        const userName =
          this.entityManager.userRepository.getById(userId)?.username;

        hints.push({
          tk: 'operationsComponents.processTaskAppointmentUsersWidget.userIsRelatedMultipleTimes',
          tkParams: { userName }
        });
      }
    }

    this.hints = hints;
  }

  private async updateUserResponses(): Promise<void> {
    if (this.processTaskAppointment) {
      const userRequests = this.getUserRequests();
      if (!this.userRequestsDirtyChecker.updateValueIfDirty({ userRequests })) {
        return;
      }

      const response = await this.checkUsersAreAvailable(
        userRequests,
        this.processTaskAppointment
      );

      if (response.success) {
        this.userResponses = response.userResponses;
        this.userAvailabilityError = false;
      } else {
        this.userAvailabilityError = true;
      }
    } else {
      this.userRequestsDirtyChecker.setValue(null);
      this.userResponses = [];
    }
  }

  private getUserRequests(): Array<UserRequest> {
    const requests: Array<UserRequest> = [];

    for (const relation of this.relations) {
      if (relation.dateFrom && relation.dateTo) {
        requests.push({
          userId: relation.userId,
          dateFromIso: relation.dateFrom,
          dateToIso: relation.dateTo
        });
      }
    }

    return requests;
  }

  private async checkUsersAreAvailable(
    userRequests: Array<UserRequest>,
    appointment: ProcessTaskAppointment
  ): Promise<CheckUsersAreAvailableResponse> {
    if (userRequests.length) {
      return this.socketService.checkUsersAreAvailable({
        userRequests,
        excludedAppointmentIds: [appointment.id]
      });
    }

    return {
      success: true,
      userResponses: []
    };
  }
}

type Hint = {
  tk: string;
  tkParams: Record<string, unknown>;
};
