import { autoinject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskMeasurePoint } from '../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types';
import { ProcessTaskMeasurePointReading } from '../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { configureHooks } from '../../hooks/configureHooks';

@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class EditProcessTaskMeasurePointDialog {
  public static async open(
    options: EditProcessTaskMeasurePointDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly measurePointPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskMeasurePoint];

  private measurePoint: ProcessTaskMeasurePoint | null = null;
  private onDialogClosed: (() => void) | null = null;
  protected measurePointReadings: Array<ProcessTaskMeasurePointReading> = [];
  protected processTaskGroup: ProcessTaskGroup | null = null;

  protected dialog: RecordItDialog | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.entityManager = entityManager;
    this.subscriptionManager = subscriptionManagerService.create();

    this.measurePointPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskMeasurePoint,
        context: this,
        expression: 'measurePoint'
      });
  }

  public open(options: EditProcessTaskMeasurePointDialogOpenOptions): void {
    this.measurePoint = options.measurePoint;
    this.onDialogClosed = options.onDialogClosed ?? null;
    this.processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        options.measurePoint.ownerProcessTaskGroupId
      );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskMeasurePointReading,
      this.updateMeasurePointReadings.bind(this)
    );
    this.updateMeasurePointReadings();

    if (this.dialog) {
      this.dialog.open();
    }
  }

  protected handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;

    this.measurePoint = null;
    this.onDialogClosed = null;
    this.processTaskGroup = null;
    this.subscriptionManager.disposeSubscriptions();

    onClosed && onClosed();
  }

  private updateMeasurePointReadings(): void {
    if (this.measurePoint) {
      const readings =
        this.entityManager.processTaskMeasurePointReadingRepository.getByProcessTaskMeasurePointId(
          this.measurePoint.id
        );

      readings.sort((a, b) => b.date.localeCompare(a.date));

      this.measurePointReadings = readings;
    } else {
      this.measurePointReadings = [];
    }
  }

  protected handleMeasurePointChanged(): void {
    if (this.measurePoint) {
      this.entityManager.processTaskMeasurePointRepository.update(
        this.measurePoint
      );
    }
  }
}

export type EditProcessTaskMeasurePointDialogOpenOptions = {
  measurePoint: ProcessTaskMeasurePoint;
  onDialogClosed?: (() => void) | null;
};
