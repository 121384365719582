import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplateEntryProperty } from '../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import { StructureTemplateRatingCategory } from '../../classes/EntityManager/entities/StructureTemplateRatingCategory/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { ValueWithLabel } from '../../types/ValueWithLabel';

@autoinject()
export class StructureTemplateRatingCategoryWidget {
  @bindable()
  public structureTemplateRatingCategory: StructureTemplateRatingCategory | null =
    null;

  @bindable()
  public structureTemplateEntryProperties: Array<StructureTemplateEntryProperty> =
    [];

  @subscribableLifecycle()
  protected structureTemplateRatingCategoryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplateRatingCategory];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.structureTemplateRatingCategoryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.StructureTemplateRatingCategory,
        context: this as StructureTemplateRatingCategoryWidget,
        propertyName: 'structureTemplateRatingCategory'
      });
  }

  protected handleStructureTemplateRatingCategoryUpdated(): void {
    assertNotNullOrUndefined(
      this.structureTemplateRatingCategory,
      'cannot handleStructureTemplateRatingCategoryUpdated without structureTemplateRatingCategory'
    );

    this.entityManager.structureTemplateRatingCategoryRepository.update(
      this.structureTemplateRatingCategory
    );
  }

  protected handleDeleteButtonClicked(): void {
    assertNotNullOrUndefined(
      this.structureTemplateRatingCategory,
      'cannot handleDeleteButtonClicked without structureTemplateRatingCategory'
    );

    this.entityManager.structureTemplateRatingCategoryRepository.delete(
      this.structureTemplateRatingCategory
    );
  }

  @computedFrom('structureTemplateEntryProperties')
  protected get parameterSelectOptions(): Array<ValueWithLabel<string>> {
    return this.structureTemplateEntryProperties.map((p) => ({
      label: p.name ?? '',
      value: p.id
    }));
  }
}
