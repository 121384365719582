import { autoinject } from 'aurelia-framework';
import { configureHooks } from '../../hooks/configureHooks';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import {
  allExportTypes,
  ExportChecklistInspectionResponse,
  ExportType
} from 'common/EndpointTypes/ReportFunctionsEndpointsHandler';
import { SocketService } from '../../services/SocketService';
import { Router } from 'aurelia-router';
import { ChecklistProjectNameUtils } from 'common/Checklist/ChecklistProjectNameUtils';

@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class ChecklistExportInspectionDialog {
  protected dialog: RecordItDialog | null = null;

  protected userCanSelectProject = false;
  protected thing: Thing | null = null;
  protected project: Project | null = null;
  protected selectedReportTypeId: string | null = null;
  protected selectedExportType = ExportType.DOCX;
  protected allExportTypes = allExportTypes;
  private onExport:
    | ((response: ExportChecklistInspectionResponse) => void)
    | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    private readonly router: Router
  ) {}

  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private open(options: OpenOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open ChecklistExportInspectionDialog without a RecordItDialog.'
    );

    this.thing = options.thing;
    this.project = options.project ?? null;
    this.userCanSelectProject = !options.project;
    this.onExport = options.onExport ?? null;

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.thing = null;
    this.project = null;
    this.userCanSelectProject = false;
    this.selectedReportTypeId = null;
    this.selectedExportType = ExportType.DOCX;
    this.onExport = null;
  }

  protected projectNameTransformationFunction(name: string): string {
    if (name.startsWith('$$') && name.endsWith('$$')) {
      return ChecklistProjectNameUtils.toDateString(name);
    }
    return name;
  }

  protected async handleAcceptButtonClicked(): Promise<void> {
    const response = await new Promise<ExportChecklistInspectionResponse>(
      (res) => {
        assertNotNullOrUndefined(
          this.project,
          'cannot handleAcceptButtonClicked without project'
        );
        assertNotNullOrUndefined(
          this.selectedReportTypeId,
          'cannot handleAcceptButtonClicked without selectedReportType'
        );
        assertNotNullOrUndefined(
          this.selectedExportType,
          'cannot handleAcceptButtonClicked without selectedExportType'
        );

        void this.entityManager.joinedProjectsManager.joinProject(
          this.project.id
        );

        this.socketService.exportChecklistInspection(
          {
            projectId: this.project.id,
            reportTypeId: this.selectedReportTypeId,
            exportType: this.selectedExportType
          },
          res
        );
      }
    );

    this.onExport?.(response);
  }

  @computed(
    expression('thing'),
    expression('userCanSelectProject'),
    expression('project'),
    model(EntityName.Project)
  )
  protected get availableProjects(): Array<Project> {
    if (!this.thing) return [];
    if (!this.userCanSelectProject && this.project) return [this.project];

    return this.entityManager.projectRepository.getByThingId(this.thing.id);
  }

  @computed(expression('selectedReportTypeId'), expression('project'))
  private get canClickAcceptButton(): boolean {
    return !!this.selectedReportTypeId && !!this.project;
  }

  @computed(expression('canClickAcceptButton'))
  protected get disabledButtons(): Array<string> {
    return this.canClickAcceptButton ? [] : ['accept'];
  }
}

type OpenOptions = {
  /**
   * The parent thing of the checklist inspection to export.
   */
  thing: Thing;
  /**
   * If set, the selected checklist inspection corresponding to the project will be exported.
   * If not set, the user will be able to select one project from `thing` for which the corresponding
   * inspection will be exported.
   */
  project?: Project;
  /**
   * Callback called when the export is started.
   */
  onExport?: (response: ExportChecklistInspectionResponse) => void;
};
