import { computedFrom } from 'aurelia-framework';
import { StructureTemplateEntryGroup } from '../../../classes/EntityManager/entities/StructureTemplateEntryGroup/types';
import { StructureTemplateEntryGroupAdapter } from '../EntityAdapter/StructureTemplateEntryGroupAdapter/StructureTemplateEntryGroupAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class StructureTemplateEntryGroupPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<StructureTemplateEntryGroupAdapter>,
  StructureTemplateEntryGroup
> {
  @computedFrom('combinedRevision')
  public get canEditStructureTemplateEntryGroupToStructureTemplateEntries(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canEditStructureTemplateEntryGroupToStructureTemplateEntries'
    );
  }
}
