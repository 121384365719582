import { autoinject } from 'aurelia-dependency-injection';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { DefectStatus } from 'common/Enums/DefectStatus';
import { IPictureCoords } from 'common/Types/Entities/Picture/PictureDto';
import { ZoomBoxPictureResizer } from '../../aureliaComponents/zoom-box/ZoomBoxPictureResizer';
import { TZoomBoxResizerHelperPicturePositionInfo } from '../../aureliaComponents/zoom-box/ZoomBoxResizerHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { GalleryThingPicture } from '../../classes/EntityManager/entities/Picture/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import {
  CoordinatesSelectedResult,
  PositionedPictureZoomBoxClickHandler
} from '../../classes/PositionedPicture/PositionedPictureZoomBoxClickHandler/PositionedPictureZoomBoxClickHandler';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { RecordItFullScreenDialog } from '../../dialogs/record-it-full-screen-dialog/record-it-full-screen-dialog';
import { GalleryThingPictureCreatorService } from '../../services/GalleryThingPictureCreatorService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { observable } from 'aurelia-framework';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';

/**
 * An overlay which provides an overview of all plans/thing pictures and allows the creation of defects/pictures
 */
@autoinject()
export class GalleryThingPlanBasedOverviewOverlay {
  public static async open(
    options: GalleryThingPlanBasedOverviewOverlayOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly subscriptionManager: SubscriptionManager;

  protected readonly zoomBoxClickHandler;

  protected dialog: RecordItFullScreenDialog | null = null;

  protected zoomBoxElement: HTMLElement | null = null;

  protected pictureElement: HTMLElement | null = null;

  private thing: Thing | null = null;

  private pictureResizer: ZoomBoxPictureResizer | null = null;

  protected picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo | null =
    null;

  protected pictureOptions: Array<PictureOption> = [];

  protected selectedPicture: GalleryThingPicture | null = null;

  protected availableMarkerInfos: Array<AvailableMarkerInfo> = [];

  protected visibleMarkerInfos: Array<MarkerInfo> = [];

  protected selectedDefectStatuses: Array<DefectStatus> = [
    DefectStatus.OPEN,
    DefectStatus.PROCESSED
  ];

  @observable()
  protected showAllDefects = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly galleryThingPictureCreatorService: GalleryThingPictureCreatorService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.zoomBoxClickHandler = new PositionedPictureZoomBoxClickHandler({
      entityManager,
      getPictureElement: () => {
        assertNotNullOrUndefined(
          this.pictureElement,
          'no pictureElement available'
        );
        return this.pictureElement;
      },
      onCoordinatesSelected: (result) => {
        this.startUrfm(result);
      }
    });
  }

  public open(options: GalleryThingPlanBasedOverviewOverlayOpenOptions): void {
    assertNotNullOrUndefined(
      this.pictureElement,
      'cannot open without pictureElement'
    );
    assertNotNullOrUndefined(
      this.zoomBoxElement,
      'cannot open without zoomBoxElement'
    );

    this.thing = options.thing;
    this.getDialog().open();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Defect,
      this.updateAvailableMarkerInfos.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updateAvailableMarkerInfos.bind(this)
    );
    this.updateAvailableMarkerInfos();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updatePictureOptions.bind(this)
    );
    this.updatePictureOptions();

    this.pictureResizer?.destroy();
    this.pictureResizer = new ZoomBoxPictureResizer(
      this.pictureElement,
      this.zoomBoxElement
    );
    this.pictureResizer.onAfterUpdate((picturePositionInfo) => {
      this.picturePositionInfo = picturePositionInfo;
    });
    this.pictureResizer.update();

    void this.updateShowAllDefects();
  }

  public close(): void {
    this.getDialog().close();
  }

  protected handleDialogClosed(): void {
    this.thing = null;
    this.subscriptionManager.disposeSubscriptions();
    this.pictureResizer?.destroy();
    this.pictureResizer = null;
  }

  protected handlePictureSelected(): void {
    this.updateVisibleMarkerInfos();
  }

  protected handleSelectedDefectStatusesChanged(): void {
    this.updateVisibleMarkerInfos();
  }

  protected showAllDefectsChanged(): void {
    this.updateAvailableMarkerInfos();
    void DataStorageHelper.setItem(
      this.getShowAllDefectsStorageName(),
      this.showAllDefects
    );
  }

  private async updateShowAllDefects(): Promise<void> {
    this.showAllDefects =
      (await DataStorageHelper.getItem(this.getShowAllDefectsStorageName())) ??
      false;
  }

  private getShowAllDefectsStorageName(): string {
    return `GalleryThingPlanBasedOverviewOverlay::showAllDefects::${this.thing?.id}`;
  }

  private startUrfm({
    coords,
    coordsFromPositionedPictureInfo
  }: CoordinatesSelectedResult): void {
    const thing = this.thing;
    assertNotNullOrUndefined(thing, "can't startUrfm without thing");

    this.galleryThingPictureCreatorService.capturePictureWithUltraRapidFireWidgetWithSetCoords(
      {
        thing,
        coords,
        coordsFromPositionedPictureInfo
      }
    );
  }

  private updatePictureOptions(): void {
    if (this.thing) {
      const pictures = this.entityManager.pictureRepository.getByGalleryThingId(
        this.thing.id
      );
      this.pictureOptions = pictures.map((picture) => ({
        label: picture.description ?? '',
        picture: picture
      }));

      const index = this.selectedPicture
        ? pictures.indexOf(this.selectedPicture)
        : -1;
      if (index === -1) {
        this.selectedPicture = pictures[0] ?? null;
        this.updateVisibleMarkerInfos();
      }
    } else {
      this.pictureOptions = [];
    }
  }

  private updateAvailableMarkerInfos(): void {
    if (this.thing) {
      const defects = this.entityManager.defectRepository.getByOwnerThingId(
        this.thing.id
      );
      const markerInfos: Array<AvailableMarkerInfo> = [];

      for (const defect of defects) {
        const pictureInfos = this.getPictureInfosForDefect(defect);

        if (pictureInfos.length) {
          markerInfos.push({
            defect,
            pictureInfos
          });
        }
      }

      this.availableMarkerInfos = InstancePreserver.createNewArray({
        originalArray: this.availableMarkerInfos,
        newArray: markerInfos,
        getTrackingValue: (item) => item.defect
      });
    } else {
      this.availableMarkerInfos = [];
    }

    this.updateVisibleMarkerInfos();
  }

  private getPictureInfosForDefect(defect: Defect): Array<PictureInfo> {
    const pictures = this.entityManager.pictureRepository.getByDefectId(
      defect.id
    );
    const pictureInfos: Array<PictureInfo> = [];

    for (const picture of pictures) {
      if (this.showAllDefects) {
        if (picture.coords) {
          pictureInfos.push({
            referencedPictureId: null,
            coordinates: picture.coords
          });
        }
      } else {
        if (picture.coords && picture.coordsFromPositionedPictureInfo) {
          pictureInfos.push({
            referencedPictureId:
              picture.coordsFromPositionedPictureInfo.pictureId,
            coordinates: picture.coords
          });
        }
      }
    }

    return pictureInfos;
  }

  private updateVisibleMarkerInfos(): void {
    const visibleMarkerInfos: Array<MarkerInfo> = [];

    for (const info of this.availableMarkerInfos) {
      if (!this.selectedDefectStatuses.includes(info.defect.status)) {
        continue;
      }

      const firstPictureInfo = info.pictureInfos.find((pictureInfo) => {
        const hasSelectedPicture = !!this.selectedPicture;
        const referencesSelectedPicture =
          pictureInfo.referencedPictureId === null ||
          pictureInfo.referencedPictureId === this.selectedPicture?.id;
        return hasSelectedPicture && referencesSelectedPicture;
      });
      if (firstPictureInfo) {
        visibleMarkerInfos.push({
          defect: info.defect,
          coordinates: firstPictureInfo.coordinates
        });
      }
    }

    this.visibleMarkerInfos = visibleMarkerInfos;
  }

  private getDialog(): RecordItFullScreenDialog {
    assertNotNullOrUndefined(this.dialog, 'no dialog available');
    return this.dialog;
  }
}

export type GalleryThingPlanBasedOverviewOverlayOpenOptions = {
  thing: Thing;
};

type PictureOption = {
  picture: GalleryThingPicture;
  label: string;
};

type AvailableMarkerInfo = {
  defect: Defect;
  pictureInfos: Array<PictureInfo>;
};

type PictureInfo = {
  /**
   * If this is set to `null`, it should be displayed for all pictures.
   */
  referencedPictureId: string | null;
  coordinates: IPictureCoords;
};

type MarkerInfo = {
  defect: Defect;
  coordinates: IPictureCoords;
};
