import { Disposable } from 'aurelia-binding';
import { BaseEntityUtils } from 'common/Types/BaseEntities/BaseEntityUtils';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { ArrayMapCache } from '../../classes/ArrayMapCache/ArrayMapCache';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProjectEntityDashboardInfo } from '../../classes/EntityManager/entities/EntityDashboardInfo/types';
import { ProjectActionService } from '../../classes/EntityManager/entities/Project/ProjectActionService';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityWidgetHandleUtils } from '../EntityWidgetHandle/EntityWidgetHandleUtils';
import { ProjectWidgetHandle } from '../EntityWidgetHandle/ProjectWidgetHandle';
import {
  EntityWidgetAdapter,
  EntityWidgetAdapterOptions,
  EntityWidgetAdapterSubscribeOptions
} from './EntityWidgetAdapter';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

export class CatastropheProjectWidgetAdapter implements EntityWidgetAdapter {
  private entityManager: AppEntityManager;

  private subscriptionManagerService: SubscriptionManagerService;

  private currentUserService: CurrentUserService;

  private activeUserCompanySettingService: ActiveUserCompanySettingService;

  private projectActionService: ProjectActionService;

  private socketService: SocketService;

  private handleCache: ArrayMapCache<Project, ProjectWidgetHandle>;

  private getThingId: CatastropheProjectWidgetAdapterOptions['getThingId'];

  private createSubscriptionsForThingId: CatastropheProjectWidgetAdapterOptions['createSubscriptionsForThingId'];

  constructor(options: CatastropheProjectWidgetAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.currentUserService = options.currentUserService;
    this.activeUserCompanySettingService =
      options.activeUserCompanySettingService;
    this.projectActionService = options.projectActionService;
    this.socketService = options.socketService;
    this.getThingId = options.getThingId;
    this.createSubscriptionsForThingId = options.createSubscriptionsForThingId;

    this.handleCache = new ArrayMapCache({
      createMappedItem: ({ item }) =>
        new ProjectWidgetHandle(item, {
          entityManager: this.entityManager,
          projectActionService: this.projectActionService,
          socketService: this.socketService,
          subscriptionManagerService: this.subscriptionManagerService,
          permissionsService: options.permissionsService
        })
    });
  }

  public subscribe(options: EntityWidgetAdapterSubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'kuk.initialNumberOfProjectsShown',
        (initialNumberOfProjectsShown: number | null) => {
          options.setEntityMinNumberToShow(initialNumberOfProjectsShown);
        }
      )
    );

    subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'kuk.totalNumberOfProjectsShown',
        (totalNumberOfProjectsShown: number | null) => {
          options.setEntityMaxNumberToShow(totalNumberOfProjectsShown);
        }
      )
    );

    const updateProjects = (): void => {
      const handles = this.handleCache.mapItems({ items: this.getProjects() });
      const sortedHandles = this.applyDashboardInfoToHandles(handles);
      options.setEntityHandles(sortedHandles);
    };
    subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      updateProjects
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.EntityDashboardInfo,
      updateProjects
    );
    subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        updateProjects.bind(this)
      )
    );
    this.createSubscriptionsForThingId(
      subscriptionManager,
      updateProjects.bind(this)
    );
    updateProjects();

    return subscriptionManager.toDisposable();
  }

  private applyDashboardInfoToHandles(
    handles: Array<ProjectWidgetHandle>
  ): Array<ProjectWidgetHandle> {
    const projectDashboardInfo: Array<ProjectEntityDashboardInfo> =
      BaseEntityUtils.sortByUpdatedAt(
        this.entityManager.entityDashboardInfoRepository
          .getAll()
          .filter((x): x is ProjectEntityDashboardInfo => !!x.projectId)
      );

    const dashboardInfoMap: Map<string, ProjectEntityDashboardInfo> = new Map(
      projectDashboardInfo.map((x) => [x.projectId, x])
    );
    const handlesWithoutHidden = EntityWidgetHandleUtils.removeHiddenHandles(
      handles,
      dashboardInfoMap
    );
    return handlesWithoutHidden.sort((a, b) =>
      EntityWidgetHandleUtils.compareHandles(a, b, dashboardInfoMap)
    );
  }

  private getProjects(): Array<Project> {
    const currentUser = this.currentUserService.getCurrentUser();

    const thingId = this.getThingId();
    if (!thingId) return [];

    return BaseEntityUtils.sortByUpdatedAt(
      this.entityManager.projectRepository
        .getByThingId(thingId)
        .filter((p) => !p.archived && p.projectType === ProjectType.BASIC)
    );
  }
}

export type CatastropheProjectWidgetAdapterOptions =
  EntityWidgetAdapterOptions & {
    projectActionService: ProjectActionService;
    socketService: SocketService;
    permissionsService: PermissionsService;
    /**
     * The id of the thing to retrieve all catastrophe checklist projects for.
     */
    getThingId(): string | null;
    /**
     * Create additional subscriptions here which should trigger a call to `getThingId`.
     */
    createSubscriptionsForThingId(
      subscriptionManager: SubscriptionManager,
      updateItems: () => void
    ): void;
  };
