import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplateEntry } from '../../../classes/EntityManager/entities/StructureTemplateEntry/types';
import {
  StructureTemplateEntryStructureTemplateEntryProperty,
  StructureTemplateStructureTemplateEntryProperty
} from '../../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { expression, model } from '../../../hooks/dependencies';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { watch } from '../../../hooks/watch';
import { TTextChangedEvent } from '../../../inputComponents/clickable-text-input/clickable-text-input';
import { SelectChangedEvent } from '../../../inputComponents/custom-select/custom-select';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class StructureTemplateEntryPropertiesDefaultValueWidgetProperty {
  @bindable()
  public structureTemplateEntry: StructureTemplateEntry | null = null;

  @bindable()
  public structureTemplateProperty: StructureTemplateStructureTemplateEntryProperty | null =
    null;

  @subscribableLifecycle()
  private structureTemplateEntryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplateEntry];

  @subscribableLifecycle()
  private structureTemplateEntryPropertyPermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplateEntryProperty];

  private structureTemplateEntryProperty: StructureTemplateEntryStructureTemplateEntryProperty | null =
    null;

  protected PropertyHelper: PropertyHelper = PropertyHelper;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.structureTemplateEntryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.StructureTemplateEntry,
        context:
          this as StructureTemplateEntryPropertiesDefaultValueWidgetProperty,
        propertyName: 'structureTemplateEntry'
      });

    this.structureTemplateEntryPropertyPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.StructureTemplateEntryProperty,
        context:
          this as StructureTemplateEntryPropertiesDefaultValueWidgetProperty,
        expression: 'structureTemplateEntryProperty'
      });
  }

  @watch(
    model(EntityName.StructureTemplateEntryProperty),
    expression('structureTemplateProperty.name'),
    expression('structureTemplateProperty.type')
  )
  protected updateStructureTemplateEntryProperty(): void {
    const structureTemplateEntryProperties = this.structureTemplateEntry
      ? this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateEntryId(
          this.structureTemplateEntry.id
        )
      : [];

    this.structureTemplateEntryProperty =
      structureTemplateEntryProperties.find((p) => {
        if (!this.structureTemplateProperty) {
          return;
        }

        return PropertyHelper.isTheSameProperty(
          {
            type: p.type,
            name: p.name
          },
          {
            type: this.structureTemplateProperty.type,
            name: this.structureTemplateProperty.name
          }
        );
      }) ?? null;
  }

  protected handleValueSelectChanged(
    event: SelectChangedEvent<string, string>
  ): void {
    this.updateStructureTemplateEntryPropertyValue(event.detail.value);
  }

  protected handleValueTextInputChanged(event: TTextChangedEvent): void {
    this.updateStructureTemplateEntryPropertyValue(String(event.detail.value));
  }

  private updateStructureTemplateEntryPropertyValue(
    value: string | null
  ): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntry,
      "can't StructureTemplateEntryPropertiesDefaultValueWidgetProperty.updateStructureTemplateEntryPropertyValue without structureTemplateEntry"
    );
    assertNotNullOrUndefined(
      this.structureTemplateProperty,
      "can't StructureTemplateEntryPropertiesDefaultValueWidgetProperty.updateStructureTemplateEntryPropertyValue without structureTemplateProperty"
    );

    if (value) {
      if (!this.structureTemplateEntryProperty) {
        this.structureTemplateEntryProperty =
          this.entityManager.structureTemplateEntryPropertyRepository.create({
            name: this.structureTemplateProperty.name,
            type: this.structureTemplateProperty.type,
            choices: this.structureTemplateProperty.choices,
            value,

            structureTemplateEntryId: this.structureTemplateEntry.id,
            ownerStructureTemplateId:
              this.structureTemplateEntry.ownerStructureTemplateId,
            ownerUserGroupId: this.structureTemplateEntry.ownerUserGroupId
          }) as StructureTemplateEntryStructureTemplateEntryProperty;
      } else {
        this.structureTemplateEntryProperty.value = value;
        this.entityManager.structureTemplateEntryPropertyRepository.update(
          this.structureTemplateEntryProperty
        );
      }
    } else {
      if (this.structureTemplateEntryProperty) {
        this.entityManager.structureTemplateEntryPropertyRepository.delete(
          this.structureTemplateEntryProperty
        );
        this.structureTemplateEntryProperty = null;
      }
    }
  }

  @computedFrom(
    'structureTemplateEntryProperty.value',
    'structureTemplateProperty.value'
  )
  protected get valueToDisplay(): string | null {
    if (this.structureTemplateEntryProperty) {
      return this.structureTemplateEntryProperty.value;
    }

    if (this.structureTemplateProperty) {
      return this.structureTemplateProperty.value;
    }

    return null;
  }

  @computedFrom(
    'structureTemplateEntryProperty.choices',
    'structureTemplateProperty.choices'
  )
  protected get choicesToDisplay(): Array<string> {
    if (this.structureTemplateEntryProperty) {
      return this.structureTemplateEntryProperty.choices;
    }

    if (this.structureTemplateProperty) {
      return this.structureTemplateProperty.choices;
    }

    return [];
  }

  @computedFrom(
    'structureTemplateEntryProperty',
    'structureTemplateEntryPermissionsHandle.canCreateStructureTemplateEntryProperties'
  )
  protected get canEditValue(): boolean {
    if (!this.structureTemplateEntryProperty) {
      return this.structureTemplateEntryPermissionsHandle
        .canCreateStructureTemplateEntryProperties;
    }

    // the canDeleteEntity check is here because the property will be deleted if the value gets set to an empty string
    return (
      this.structureTemplateEntryPropertyPermissionsHandle.canEditField.value &&
      this.structureTemplateEntryPropertyPermissionsHandle.canDeleteEntity
    );
  }
}
