import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { Utils } from 'common/Utils';
import { AppEntityManager } from '../AppEntityManager';
import { StructureTemplate } from './types';
import { StructureTemplateEntry } from '../StructureTemplateEntry/types';
import { StructureTemplateEntryGroupToStructureTemplateEntry } from '../StructureTemplateEntryGroupToStructureTemplateEntry/types';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { EntityName } from '../types';

@autoinject()
export class StructureTemplateCopyService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService
  ) {}

  public async copyStructureTemplate({
    structureTemplate
  }: {
    structureTemplate: StructureTemplate;
  }): Promise<StructureTemplate> {
    const newStructureTemplate =
      this.entityManager.structureTemplateRepository.create({
        ...structureTemplate,
        name: structureTemplate.name + ' (Kopie)',
        oldTemplateName: null,
        status: StructureTemplateStatus.DRAFT
      });

    const { groupIdMapping } = await this.copyGroupsIfAllowed({
      structureTemplate,
      newStructureTemplate
    });

    const entriesByParentId = Utils.groupValues(
      this.entityManager.structureTemplateEntryRepository.getByStructureTemplateId(
        structureTemplate.id
      ),
      (entry) => entry.parentEntryId
    );

    const entryGroupRelationsByEntryId = Utils.groupValues(
      this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.getAll(),
      (relation) => relation.structureTemplateEntryId
    );

    const { entryIdMapping } = await this.copyStructureTemplateEntriesIfAllowed(
      {
        entriesByParentId,
        newStructureTemplate,
        groupIdMapping,
        entryGroupRelationsByEntryId,
        entries: entriesByParentId.get(null) ?? [],
        parentEntry: null
      }
    );

    const { propertyIdMapping } = await this.copyPropertiesIfAllowed({
      structureTemplate,
      newStructureTemplate,
      entryIdMapping
    });

    await this.copyRatingCategoriesIfAllowed({
      structureTemplate,
      newStructureTemplate,
      propertyIdMapping
    });

    return newStructureTemplate;
  }

  private async copyGroupsIfAllowed({
    structureTemplate,
    newStructureTemplate
  }: {
    structureTemplate: StructureTemplate;
    newStructureTemplate: StructureTemplate;
  }): Promise<{ groupIdMapping: IdMapping }> {
    const groupIdMapping = new IdMapping();

    const canCreateStructureTemplateEntryGroups =
      await this.permissionsService.useAdapterOnce({
        entityName: EntityName.StructureTemplate,
        useAdapter: (adapter) => {
          return adapter.canCreateStructureTemplateEntryGroups(
            newStructureTemplate
          );
        }
      });

    if (!canCreateStructureTemplateEntryGroups) {
      return { groupIdMapping };
    }

    const groups =
      this.entityManager.structureTemplateEntryGroupRepository.getByStructureTemplateId(
        structureTemplate.id
      );
    for (const group of groups) {
      const newGroup =
        this.entityManager.structureTemplateEntryGroupRepository.create({
          ...group,
          ownerStructureTemplateId: newStructureTemplate.id
        });

      groupIdMapping.set(group.id, newGroup.id);
    }

    return { groupIdMapping };
  }

  private async copyStructureTemplateEntriesIfAllowed({
    entriesByParentId,
    entries,
    parentEntry,
    newStructureTemplate,
    groupIdMapping,
    entryGroupRelationsByEntryId
  }: {
    entriesByParentId: StructureTemplateEntriesByParentId;
    entries: Array<StructureTemplateEntry>;
    parentEntry: StructureTemplateEntry | null;
    newStructureTemplate: StructureTemplate;
    groupIdMapping: IdMapping;
    entryGroupRelationsByEntryId: StructureTemplateEntryGroupRelationsByEntryId;
  }): Promise<{ entryIdMapping: IdMapping }> {
    const entryIdMapping = new IdMapping();

    const canCreateStructureTemplateEntries =
      await this.permissionsService.useAdapterOnce({
        entityName: EntityName.StructureTemplate,
        useAdapter: (adapter) => {
          return adapter.canCreateStructureTemplateEntries(
            newStructureTemplate
          );
        }
      });

    if (!canCreateStructureTemplateEntries) {
      return { entryIdMapping };
    }

    for (const entry of entries) {
      if (
        entry.structureTemplateEntryGroupId &&
        !groupIdMapping.has(entry.structureTemplateEntryGroupId)
      ) {
        // skip entries where the group for it doesn't exist
        // this can happen when the user has no permissions to create groups
        continue;
      }

      const newEntry =
        this.entityManager.structureTemplateEntryRepository.create({
          ...entry,
          ownerStructureTemplateId: newStructureTemplate.id,
          parentEntryId: parentEntry?.id || null,
          structureTemplateEntryGroupId: entry.structureTemplateEntryGroupId
            ? groupIdMapping.get(entry.structureTemplateEntryGroupId)
            : null
        });

      entryIdMapping.set(entry.id, newEntry.id);

      await this.copyGroupRelationsIfAllowed({
        entry,
        entryGroupRelationsByEntryId,
        newEntry,
        groupIdMapping
      });

      const childrenCopyResult =
        await this.copyStructureTemplateEntriesIfAllowed({
          entriesByParentId,
          entries: entriesByParentId.get(entry.id) ?? [],
          parentEntry: newEntry,
          newStructureTemplate,
          groupIdMapping,
          entryGroupRelationsByEntryId
        });

      entryIdMapping.mergeIntoSelf(childrenCopyResult.entryIdMapping);
    }

    return { entryIdMapping };
  }

  private async copyGroupRelationsIfAllowed({
    entry,
    newEntry,
    entryGroupRelationsByEntryId,
    groupIdMapping
  }: {
    entry: StructureTemplateEntry;
    newEntry: StructureTemplateEntry;
    entryGroupRelationsByEntryId: StructureTemplateEntryGroupRelationsByEntryId;
    groupIdMapping: IdMapping;
  }): Promise<void> {
    const relations = entryGroupRelationsByEntryId.get(entry.id) ?? [];

    for (const relation of relations) {
      if (!groupIdMapping.has(relation.structureTemplateEntryGroupId)) {
        // this can happen e.g. when the user has no permission to create groups
        continue;
      }

      const structureTemplateEntryGroup =
        this.entityManager.structureTemplateEntryGroupRepository.getRequiredById(
          groupIdMapping.get(relation.structureTemplateEntryGroupId)
        );

      const canEditStructureTemplateEntryGroupToStructureTemplateEntries =
        await this.permissionsService.useAdapterOnce({
          entityName: EntityName.StructureTemplateEntryGroup,
          useAdapter: (adapter) => {
            return adapter.canEditStructureTemplateEntryGroupToStructureTemplateEntries(
              structureTemplateEntryGroup
            );
          }
        });

      if (!canEditStructureTemplateEntryGroupToStructureTemplateEntries) {
        continue;
      }

      this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.create(
        {
          ownerUserGroupId: newEntry.ownerUserGroupId,
          structureTemplateEntryGroupId: structureTemplateEntryGroup.id,
          structureTemplateEntryId: newEntry.id
        }
      );
    }
  }

  private async copyPropertiesIfAllowed({
    structureTemplate,
    newStructureTemplate,
    entryIdMapping
  }: {
    structureTemplate: StructureTemplate;
    newStructureTemplate: StructureTemplate;
    entryIdMapping: IdMapping;
  }): Promise<{ propertyIdMapping: IdMapping }> {
    const propertyIdMapping = new IdMapping();

    const canCreateStructureTemplateEntryProperties =
      await this.permissionsService.useAdapterOnce({
        entityName: EntityName.StructureTemplate,
        useAdapter: (adapter) => {
          return adapter.canCreateStructureTemplateEntryProperties(
            newStructureTemplate
          );
        }
      });

    if (!canCreateStructureTemplateEntryProperties) {
      return { propertyIdMapping };
    }

    const properties =
      this.entityManager.structureTemplateEntryPropertyRepository.getByOwnerStructureTemplateId(
        structureTemplate.id
      );
    for (const property of properties) {
      const newProperty =
        this.entityManager.structureTemplateEntryPropertyRepository.create({
          ...property,
          structureTemplateEntryId: property.structureTemplateEntryId
            ? entryIdMapping.get(property.structureTemplateEntryId)
            : null,
          ownerStructureTemplateId: newStructureTemplate.id
        });

      propertyIdMapping.set(property.id, newProperty.id);
    }

    return { propertyIdMapping };
  }

  private async copyRatingCategoriesIfAllowed({
    structureTemplate,
    newStructureTemplate,
    propertyIdMapping
  }: {
    structureTemplate: StructureTemplate;
    newStructureTemplate: StructureTemplate;
    propertyIdMapping: IdMapping;
  }): Promise<void> {
    const canEditStructureTemplateRatingCategories =
      await this.permissionsService.useAdapterOnce({
        entityName: EntityName.StructureTemplate,
        useAdapter: (adapter) => {
          return adapter.canEditStructureTemplateRatingCategories(
            newStructureTemplate
          );
        }
      });

    if (!canEditStructureTemplateRatingCategories) {
      return;
    }

    const ratingCategories =
      this.entityManager.structureTemplateRatingCategoryRepository.getByStructureTemplateId(
        structureTemplate.id
      );
    for (const category of ratingCategories) {
      this.entityManager.structureTemplateRatingCategoryRepository.create({
        ...category,
        structureTemplateEntryPropertyId:
          category.structureTemplateEntryPropertyId
            ? propertyIdMapping.get(category.structureTemplateEntryPropertyId)
            : null,
        ownerStructureTemplateId: newStructureTemplate.id
      });
    }
  }
}

type StructureTemplateEntriesByParentId = Map<
  string | null,
  Array<StructureTemplateEntry>
>;
type StructureTemplateEntryGroupRelationsByEntryId = Map<
  string,
  Array<StructureTemplateEntryGroupToStructureTemplateEntry>
>;

/**
 * a map from the previous id to the new id
 */
class IdMapping {
  private map = new Map<string, string>();

  public get(previousId: string): string {
    const newId = this.map.get(previousId);
    assertNotNullOrUndefined(newId, `no new id found for "${previousId}"`);

    return newId;
  }

  public has(previousId: string): boolean {
    return this.map.has(previousId);
  }

  public set(previousId: string, newId: string): void {
    this.map.set(previousId, newId);
  }

  public mergeIntoSelf(otherIdMapping: IdMapping): void {
    Utils.mergeMaps(this.map, otherIdMapping.map);
  }
}
