import _ from 'lodash';

// { push?: never } is a hack to not allow arrays
export class DirtyChecker<
  TValue extends Record<never, never> & { push?: never }
> {
  private value: TValue | null = null;

  public setValue(value: TValue | null): void {
    this.value = _.cloneDeep(value);
  }

  /**
   * @returns {boolean} - returns true if the new value is different
   */
  public isDirty(newValue: TValue | null): boolean {
    return !_.isEqual(this.value, newValue);
  }

  /**
   * @returns {boolean} - returns true if the new value was different
   */
  public updateValueIfDirty(newValue: TValue | null): boolean {
    const isDirty = this.isDirty(newValue);
    if (isDirty) {
      this.setValue(newValue);
    }

    return isDirty;
  }
}
