import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import type { EndpointResult } from 'common/WebSocketEndpoints/WebSocketEndpointConfigurations';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { Logger } from '../../classes/Logger/Logger';
import { FileChunkUploadService } from '../../services/FileChunkUploadService/FileChunkUploadService';
import { SocketService } from '../../services/SocketService';

@autoinject()
export class ProcessTaskSharepointUploadWidget<
  TTargetEntityType extends Project | ProcessTask
> {
  @bindable public activeTargetEntity: TTargetEntityType | null = null;
  @bindable public processTask: ProcessTask | null = null;

  private fileInput: HTMLInputElement | null = null;
  private queue: Array<FileQueueItem> = [];

  private isUploading = false;
  private showUploadInfo = false;

  constructor(
    private readonly socketService: SocketService,
    private readonly fileChunkUploadService: FileChunkUploadService
  ) {}

  protected async handleFileInputChanged(): Promise<void> {
    if (!this.fileInput) {
      return;
    }
    const files = this.fileInput.files;
    if (!files || files.length === 0) {
      return;
    }
    const processTaskId = this.processTask?.id;
    assertNotNullOrUndefined(
      processTaskId,
      'Cannot unpload file without processtask'
    );

    this.isUploading = true;
    this.showUploadInfo = true;
    this.queue = Array.from(files).map((file) => ({
      filename: file.name,
      status: 'idle',
      file
    }));
    for (const uploadItem of this.queue) {
      try {
        uploadItem.status = 'uploading';
        const response = await this.upload(uploadItem.file, processTaskId);
        if (response.success) {
          if (response.fileAlreadyUploaded) {
            uploadItem.status = 'exists';
          } else {
            uploadItem.status = 'uploaded';
          }
        } else {
          uploadItem.status = 'failed';
        }
      } catch (error) {
        uploadItem.status = 'failed';
        Logger.logError({ error });
      }
    }
    this.isUploading = false;
  }

  private async upload(
    file: File,
    processTaskId: string
  ): Promise<
    EndpointResult<'sharepointFileUploadModule', 'operationsFileUpload/1'>
  > {
    const uploader = await this.fileChunkUploadService.startUpload({
      fileData: new Uint8Array(await file.arrayBuffer()),
      chunkedFileId: null
    });
    await uploader.upload();

    return this.socketService.sharepointSocketEndpoints.uploadUserFile({
      chunkedFileId: uploader.getChunkedFileId(),
      fileName: file.name,
      processTaskId
    });
  }

  private handleNextFilesClick(): void {
    this.showUploadInfo = false;
    this.queue = [];
    if (this.fileInput) {
      this.fileInput.value = '';
    }
  }
}

interface FileQueueItem {
  filename: string;
  status: 'idle' | 'uploading' | 'uploaded' | 'exists' | 'failed';
  file: File;
}
