import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EditProcessTaskMeasurePointDialog } from '../edit-process-task-measure-point-dialog/edit-process-task-measure-point-dialog';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { ProcessTaskMeasurePointMarkingDialog } from '../process-task-measure-point-marking-dialog/process-task-measure-point-marking-dialog';
import { SocketService } from '../../services/SocketService';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { FileDownloadService } from '../../services/FileDownloadService';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskMeasurePoint } from '../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { TMeasurePointClickedEvent } from '../process-task-measure-point-markings-overlay/process-task-measure-point-markings-overlay';

@autoinject()
export class ProcessTaskMeasurePointOverviewWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public enabled: boolean = false;

  /**
   * null if measurePoints aren't loaded
   * read only
   */
  @bindable()
  public processTaskMeasurePointCount: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private measurePoints: Array<ProcessTaskMeasurePoint> = [];
  private displayedPicture: Picture | null = null;
  private isAttached: boolean = false;

  constructor(
    private readonly fileDownloadService: FileDownloadService,
    private readonly entityManager: AppEntityManager,
    private socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskMeasurePoint,
      this.updateMeasurePoints.bind(this)
    );
    this.updateMeasurePoints();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateMeasurePoints();
    }
  }

  private updateMeasurePoints(): void {
    if (this.processTask) {
      this.measurePoints =
        this.entityManager.processTaskMeasurePointRepository.getByProcessTaskId(
          this.processTask.id
        );
      this.processTaskMeasurePointCount = this.measurePoints.length;
    } else {
      this.measurePoints = [];
      this.processTaskMeasurePointCount = null;
    }
  }

  protected handleEditMeasurePointClick(
    measurePoint: ProcessTaskMeasurePoint
  ): void {
    void EditProcessTaskMeasurePointDialog.open({
      measurePoint: measurePoint,
      onDialogClosed: () => {
        this.goToMeasurePoint(measurePoint);
      }
    });
  }

  protected handleMarkMeasurePointClick(
    measurePoint: ProcessTaskMeasurePoint
  ): void {
    if (!this.displayedPicture || !this.processTaskGroup) {
      return;
    }

    void ProcessTaskMeasurePointMarkingDialog.open({
      picture: this.displayedPicture,
      measurePoint: measurePoint,
      processTaskGroup: this.processTaskGroup
    });
  }

  protected handlePictureMeasurePointClicked(
    event: TMeasurePointClickedEvent
  ): void {
    this.goToMeasurePoint(event.detail.measurePoint);
  }

  protected handleExportButtonClick(): void {
    if (!this.processTask) {
      return;
    }

    this.socketService.downloadMeasurePoints(
      { processTaskId: this.processTask.id },
      (response) => {
        if (response.success) {
          Dialogs.closeAllDialogs();
          void this.fileDownloadService.downloadFileByToken(response.token);
        } else {
          const errorMessageKey = `serverResponses.${
            response.status ? response.status : 'unspecifiedError'
          }`;
          void Dialogs.errorDialogTk('general.downloadError', errorMessageKey);
        }
      }
    );
  }

  private goToMeasurePoint(measurePoint: ProcessTaskMeasurePoint): void {
    void ScrollHelper.autoScrollToListItem(
      '#' + this.getMeasurePointListItemElementId(measurePoint.id),
      null,
      measurePoint,
      () => this.isAttached
    );
  }

  private getMeasurePointListItemElementId(measurePointId: string): string {
    return (
      'process-task-measure-point-overview-widget--measure-point-' +
      measurePointId
    );
  }
}
