import { computedFrom } from 'aurelia-framework';
import { StructureTemplateEntry } from '../../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { StructureTemplateEntryAdapter } from '../EntityAdapter/StructureTemplateEntryAdapter/StructureTemplateEntryAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class StructureTemplateEntryPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<StructureTemplateEntryAdapter>,
  StructureTemplateEntry
> {
  @computedFrom('combinedRevision')
  public get canCreateStructureTemplateEntryProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateStructureTemplateEntryProperties'
    );
  }
}
