import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { processTaskInvoiceToProcessTaskEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTask/processTaskInvoiceToProcessTaskEntityInfo';
import { ProcessTaskInvoiceToProcessTask } from '../../../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTask/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { ProcessTaskInvoiceAdapter } from '../ProcessTaskInvoiceAdapter/ProcessTaskInvoiceAdapter';

export class ProcessTaskInvoiceToProcessTaskAdapter
  implements EntityAdapter<ProcessTaskInvoiceToProcessTask>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private processTaskInvoiceAdapter: ProcessTaskInvoiceAdapter | null = null;

  constructor(options: ProcessTaskInvoiceToProcessTaskAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.ProcessTaskInvoice,
        onNextAdapter: ({ adapter }) => {
          this.processTaskInvoiceAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.processTaskInvoiceAdapter != null &&
      this.processTaskInvoiceAdapter.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(
    processTaskInvoiceToProcessTask: ProcessTaskInvoiceToProcessTask
  ): boolean {
    return this.canEditProcessTaskInvoiceToProcessTask(
      processTaskInvoiceToProcessTask
    );
  }

  public canEditField(
    processTaskInvoiceToProcessTask: ProcessTaskInvoiceToProcessTask
  ): boolean {
    return this.canEditProcessTaskInvoiceToProcessTask(
      processTaskInvoiceToProcessTask
    );
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskInvoiceToProcessTask,
    ProcessTaskInvoiceToProcessTask
  > {
    return processTaskInvoiceToProcessTaskEntityInfo;
  }

  private canEditProcessTaskInvoiceToProcessTask(
    processTaskInvoiceToProcessTask: ProcessTaskInvoiceToProcessTask
  ): boolean {
    if (!this.processTaskInvoiceAdapter) {
      return false;
    }

    const processTaskInvoice =
      this.entityManager.processTaskInvoiceRepository.getById(
        processTaskInvoiceToProcessTask.processTaskInvoiceId
      );

    if (!processTaskInvoice) {
      return false;
    }

    return this.processTaskInvoiceAdapter.canEditProcessTaskInvoiceToProcessTasks(
      processTaskInvoice
    );
  }
}

export type ProcessTaskInvoiceToProcessTaskAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
