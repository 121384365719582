import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { SortUtils } from 'common/Utils/SortUtils';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { Project } from './types';

export class ProjectRepository extends AppEntityRepository<EntityName.Project> {
  public getFirstOfThingId(thingId: string): Project | null {
    return (
      this.getAll().find((project) => {
        return project.thing === thingId;
      }) ?? null
    );
  }

  public getFirstByThingIdAndCustomId(
    thingId: string,
    customId: string
  ): Project | undefined {
    const existingProjects = this.getByThingId(thingId);
    return existingProjects.find((p) => p.customId === customId);
  }

  public getByThingId(thingId: string): Array<Project> {
    return this.getAll().filter((entity) => {
      return entity.thing === thingId;
    });
  }

  public getByThingIdAndProjectType(
    thingId: string,
    projectType: ProjectType
  ): Array<Project> {
    return this.getAll().filter((entity) => {
      return entity.thing === thingId && entity.projectType === projectType;
    });
  }

  public getByThingIdWithoutOperationsProjects(
    thingId: string
  ): Array<Project> {
    return this.getAll().filter((entity) => {
      return (
        entity.thing === thingId &&
        entity.projectType !== ProjectType.OPERATIONS
      );
    });
  }

  public getByThingIdAndTypeReverseSortedByName(
    thingId: string,
    type: ProjectType
  ): Array<Project> {
    return this.getByThingId(thingId)
      .filter((p) => p.projectType === type)
      .sort((p1, p2) => {
        return SortUtils.localeCompareFalsyStrings(p1.name, p2.name);
      })
      .reverse();
  }

  public getByType(type: ProjectType): Array<Project> {
    return this.getAll().filter((entity) => {
      return entity.projectType === type;
    });
  }
}
