import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskMeasurePoint } from '../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * @event edit-clicked
 * @event mark-clicked
 */
@autoinject()
export class ProcessTaskMeasurePointOverviewWidgetListItem {
  @bindable()
  public measurePoint: ProcessTaskMeasurePoint | null = null;

  @subscribableLifecycle()
  protected measurePointPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskMeasurePoint];

  protected listElement: HTMLElement | null = null;

  protected panelOpen: boolean = false;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.measurePointPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        context: this as ProcessTaskMeasurePointOverviewWidgetListItem,
        entityName: EntityName.ProcessTaskMeasurePoint,
        propertyName: 'measurePoint'
      });
  }

  public highlight(): void {
    if (this.listElement) {
      EntityListItemHelper.highlightListItemElement(this.listElement);
    }
  }

  protected handleEditButtonClick(): void {
    if (!this.measurePoint || !this.element) {
      return;
    }

    DomEventHelper.fireEvent(this.element, {
      name: 'edit-clicked',
      detail: null
    });
  }

  protected handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected handleMarkButtonClick(): void {
    if (!this.measurePoint || !this.element) {
      return;
    }

    DomEventHelper.fireEvent(this.element, {
      name: 'mark-clicked',
      detail: null
    });
  }

  protected handleDeleteClick(): void {
    const measurePoint = this.measurePoint;

    if (measurePoint) {
      void Dialogs.deleteEntityDialog(measurePoint).then(() => {
        this.entityManager.processTaskMeasurePointRepository.delete(
          measurePoint
        );
      });
    }
  }
}
