import { autoinject, bindable } from 'aurelia-framework';

import {
  AnimationFunctionNamesWhichDontRequireArguments,
  ShowHideAnimator
} from '../classes/Animation/ShowHideAnimator';

/**
 * provides the animations of the ShowHideAnimator controllable over a flag/binding
 * the default visible value will not be animated, so if you don't want your element to be visible by default,
 * make sure to set visible to false at initialization time and set your element to e.g. display: none; (this is dependent on the animation,
 * some don't require a display: none, just look at the documenation for the animation in the ShowHideAnimator)
 *
 * import this custom attribute into your element, and just add the attribute to an element you want to animate
 * Example:
 *    <div id="animateMe" show-hide-animation="visible.one-way: _animateMeIsVisible; show-animation-name: fadeIn; hide-animation-name: fadeOut;"> content </div>
 */
@autoinject()
export class ShowHideAnimationCustomAttribute {
  /**
   * an "in" animation function name from the ShowHideAnimator
   */
  @bindable
  public showAnimationName: AnimationFunctionNamesWhichDontRequireArguments =
    'fadeIn';

  /**
   * an "out" animation function name from the ShowHideAnimator
   */
  @bindable
  public hideAnimationName: AnimationFunctionNamesWhichDontRequireArguments =
    'fadeOut';

  @bindable public visible: true = true;

  /**
   * Useful if the element initially has the desired animation end state e.g. the element is already visible.
   * This is also great for performance reasons, so e.g. if you display 50 items, you can prevent 50 animations from playing when loading a page
   */
  @bindable public skipInitialAnimation: boolean = false;

  private isAttached: boolean = false;
  private animator: ShowHideAnimator;

  private initialAnimationStarted: boolean = false;

  constructor(element: Element) {
    this.animator = new ShowHideAnimator(element as HTMLElement);
  }

  protected attached(): void {
    this.isAttached = true;

    this.startAnimation();
    this.initialAnimationStarted = true;
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected visibleChanged(): void {
    if (!this.isAttached) {
      return;
    }

    this.startAnimation();
  }

  private startAnimation(): void {
    if (this.skipInitialAnimation && !this.initialAnimationStarted) {
      return;
    }

    if (this.visible) {
      this.doAnimation(this.getShowAnimationNameOrDefault());
    } else {
      this.doAnimation(this.getHideAnimationNameOrDefault());
    }
  }

  private doAnimation(
    animationName: AnimationFunctionNamesWhichDontRequireArguments
  ): void {
    this.animator.clearAnimations();
    void this.animator[animationName]();
  }

  private getShowAnimationNameOrDefault(): AnimationFunctionNamesWhichDontRequireArguments {
    return this.getAnimationNameOrDefault(this.showAnimationName, 'fadeIn');
  }

  private getHideAnimationNameOrDefault(): AnimationFunctionNamesWhichDontRequireArguments {
    return this.getAnimationNameOrDefault(this.hideAnimationName, 'fadeOut');
  }

  private getAnimationNameOrDefault(
    animationName: AnimationFunctionNamesWhichDontRequireArguments,
    defaultAnimationName: AnimationFunctionNamesWhichDontRequireArguments
  ): AnimationFunctionNamesWhichDontRequireArguments {
    if (typeof this.animator[animationName] === 'function') {
      return animationName;
    } else {
      console.warn(
        `[ShowHideAnimationCustomAttribute] animation with the name "${animationName}" doesn't exist`
      );
      return defaultAnimationName;
    }
  }
}
