import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PositionPrices } from 'common/Operations/ProcessTaskPositionCalculator';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskInvoice } from '../../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { ProcessTaskInvoiceToProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTaskPosition/types';
import { ProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskPositionDetailEntry } from '../../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { computed } from '../../../hooks/computed';
import { expression, model } from '../../../hooks/dependencies';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { CustomCheckboxCheckedChangedEvent } from '../../../inputComponents/custom-checkbox/custom-checkbox';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { ProcessTaskLoggingService } from '../../../services/ProcessTaskLoggingService';
import { EditProcessTaskPositionDialog } from '../../edit-process-task-position-dialog/edit-process-task-position-dialog';

@autoinject()
export class ProcessTaskInvoiceRelationsWidgetPositionListItem {
  @bindable()
  public processTaskGroup: ProcessTaskInvoice | null = null;

  @bindable()
  public processTaskInvoice: ProcessTaskInvoice | null = null;

  @bindable()
  public processTaskPosition: ProcessTaskPosition | null = null;

  /**
   * all prices which are included in the invoice
   */
  @bindable()
  public includedPositionPrices: Array<
    PositionPrices<ProcessTaskPosition, ProcessTaskPositionDetailEntry>
  > = [];

  @subscribableLifecycle()
  private processTaskInvoicePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskInvoice];

  @subscribableLifecycle()
  private processTaskInvoiceToProcessTaskPositionPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskInvoiceToProcessTaskPosition];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    permissionsService: PermissionsService
  ) {
    this.processTaskInvoicePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskInvoice,
        context: this as ProcessTaskInvoiceRelationsWidgetPositionListItem,
        propertyName: 'processTaskInvoice'
      });

    this.processTaskInvoiceToProcessTaskPositionPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskInvoiceToProcessTaskPosition,
        context: this,
        expression: 'processTaskInvoiceToProcessTaskPosition'
      });
  }

  public setChecked(checked: boolean): void {
    this.ensureRelationStatus(checked);
  }

  protected handleCheckboxChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    this.ensureRelationStatus(event.detail.checked);
  }

  protected handleEditButtonClicked(): void {
    assertNotNullOrUndefined(
      this.processTaskPosition,
      "can't ProcessTaskInvoiceRelationsWidgetPositionListItem.handleEditButtonClicked without processTaskPosition"
    );

    void EditProcessTaskPositionDialog.open({
      position: this.processTaskPosition
    });
  }

  protected handleDeleteButtonClicked(): void {
    const processTaskPosition = this.processTaskPosition;
    assertNotNullOrUndefined(
      processTaskPosition,
      "can't ProcessTaskInvoiceRelationsWidgetPositionListItem.handleDeleteButtonClicked without processTaskPosition"
    );

    void Dialogs.deleteEntityDialog(processTaskPosition).then(() => {
      this.entityManager.processTaskPositionRepository.delete(
        processTaskPosition
      );
      void this.processTaskLoggingService.logProcessTaskSubEntityDeleted({
        entityName: EntityName.ProcessTaskPosition,
        entity: processTaskPosition,
        displayNameAtLogTime: processTaskPosition.name
      });
    });
  }

  private ensureRelationStatus(relationShouldExist: boolean): void {
    assertNotNullOrUndefined(
      this.processTaskInvoice,
      "can't ProcessTaskInvoiceRelationsWidgetPositionListItem.ensureRelationStatus without processTaskInvoice"
    );
    assertNotNullOrUndefined(
      this.processTaskPosition,
      "can't ProcessTaskInvoiceRelationsWidgetPositionListItem.ensureRelationStatus without processTaskPosition"
    );

    const existingRelation =
      this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.getByProcessTaskInvoiceIdAndProcessTaskPositionId(
        this.processTaskInvoice.id,
        this.processTaskPosition.id
      );

    if (relationShouldExist) {
      if (!existingRelation) {
        this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.create(
          {
            processTaskInvoiceId: this.processTaskInvoice.id,
            processTaskPositionId: this.processTaskPosition.id,
            ownerProcessTaskId: this.processTaskPosition.ownerProcessTaskId,
            ownerProcessTaskGroupId:
              this.processTaskPosition.ownerProcessTaskGroupId,
            ownerUserGroupId: this.processTaskPosition.ownerUserGroupId,
            temporaryGroupName: this.processTaskPosition.temporaryGroupName
          }
        );
      }
    } else {
      if (existingRelation) {
        this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.delete(
          existingRelation
        );
      }
    }
  }

  @computed(
    expression('processTaskPosition'),
    expression('processTaskInvoice'),
    model(EntityName.ProcessTaskInvoiceToProcessTaskPosition)
  )
  protected get processTaskInvoiceToProcessTaskPosition(): ProcessTaskInvoiceToProcessTaskPosition | null {
    if (this.processTaskPosition && this.processTaskInvoice) {
      return this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.getByProcessTaskInvoiceIdAndProcessTaskPositionId(
        this.processTaskInvoice.id,
        this.processTaskPosition.id
      );
    } else {
      return null;
    }
  }

  @computed(
    expression(
      'processTaskInvoicePermissionsHandle.canEditProcessTaskInvoiceToProcessTaskPositions'
    ),
    expression(
      'processTaskInvoiceToProcessTaskPositionPermissionsHandle.canDeleteEntity'
    )
  )
  protected get checkboxEnabled(): boolean {
    if (!this.processTaskInvoiceToProcessTaskPosition) {
      return this.processTaskInvoicePermissionsHandle
        .canEditProcessTaskInvoiceToProcessTaskPositions;
    }

    return this.processTaskInvoiceToProcessTaskPositionPermissionsHandle
      .canDeleteEntity;
  }
}
