import { autoinject, bindable } from 'aurelia-framework';
import { TDefaultPropertyConfig } from 'common/Types/DefaultPropertyConfig';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Utils } from '../../classes/Utils/Utils';
import { IProcessConfigurationPositionPropertiesConfiguration } from '../../../../common/src/Types/ProcessConfigurationPositionPropertiesConfiguration';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskPositionDetailEntry } from '../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types';
import { ProcessTaskPositionDetailEntryProperty } from '../../classes/EntityManager/entities/Property/types';

@autoinject()
export class ProcessTaskPositionDetailEntriesWidget {
  @bindable()
  public processTaskPosition: ProcessTaskPosition | null = null;

  private subscriptionManager: SubscriptionManager;

  protected detailEntries: Array<ProcessTaskPositionDetailEntry> = [];
  protected defaultProperties: Array<TDefaultPropertyConfig> = [];
  protected defaultPropertiesLoaded: boolean = false;
  protected detailEntryPropertiesByDetailEntryId: Map<
    string,
    Array<ProcessTaskPositionDetailEntryProperty>
  > = new Map();

  private isAttached: boolean = false;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPositionDetailEntry,
      this.updateDetailEntries.bind(this)
    );
    this.updateDetailEntries();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );
    this.updateProperties();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      this.updateDefaultProperties.bind(this)
    );
    this.updateDefaultProperties();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskPositionChanged(): void {
    if (this.isAttached) {
      this.updateDetailEntries();
      this.updateProperties();
      this.updateDefaultProperties();
    }
  }

  private updateDetailEntries(): void {
    if (this.processTaskPosition) {
      this.detailEntries =
        this.entityManager.processTaskPositionDetailEntryRepository.getByProcessTaskPositionId(
          this.processTaskPosition.id
        );
    } else {
      this.detailEntries = [];
    }
  }

  private updateProperties(): void {
    if (this.processTaskPosition) {
      const properties =
        this.entityManager.propertyRepository.getProcessTaskPositionDetailEntryPropertiesByProcessTaskId(
          this.processTaskPosition.ownerProcessTaskId
        );
      this.detailEntryPropertiesByDetailEntryId = Utils.groupBy(
        properties,
        (p) => p.processTaskPositionDetailEntryId
      );
    } else {
      this.detailEntryPropertiesByDetailEntryId = new Map();
    }
  }

  private updateDefaultProperties(): void {
    const processTaskGroup = this.processTaskPosition
      ? this.entityManager.processTaskGroupRepository.getById(
          this.processTaskPosition.ownerProcessTaskGroupId
        )
      : null;
    const processConfiguration = processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          processTaskGroup.processConfigurationId
        )
      : null;
    const configJson = processConfiguration
      ? processConfiguration.positionPropertiesConfigurationJson
      : null;
    const config: IProcessConfigurationPositionPropertiesConfiguration | null =
      configJson ? JSON.parse(configJson) : null;
    this.defaultProperties = config?.detailEntryProperties
      ? config.detailEntryProperties
      : [];
    this.defaultPropertiesLoaded = !!config;
  }
}
