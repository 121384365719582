import { FileTypeUtils } from '../../Utils/FileTypeUtils';
import { ArrayBufferReader } from '../ArrayBufferReader/ArrayBufferReader';

export class DataUrlReader {
  public async readFile(file: File): Promise<string> {
    // normal we could just pass the file into the `new Blob`, but the cordova-plugin-file overwrites the File type with it's own custom implementation
    // so we have to convert it into an ArrayBuffer first so we can pass it into the `new Blob`
    // REC-3465
    const reader = new ArrayBufferReader();
    const arrayBuffer = await reader.readFileAsArrayBuffer(file);

    return this.readBlob(
      new Blob([arrayBuffer], {
        type: FileTypeUtils.getFileType(file)?.toString()
      })
    );
  }

  public readBlob(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(
            new Error(`received wrong result type: "${typeof reader.result}"`)
          );
        }
      };

      reader.onerror = () => {
        reject(new Error(reader.error?.message));
      };

      reader.onabort = () => {
        reject(new Error('reader aborted'));
      };

      reader.readAsDataURL(blob);
    });
  }
}
