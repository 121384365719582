import { computedFrom } from 'aurelia-framework';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskAppointmentAdapter } from '../EntityAdapter/ProcessTaskAppointmentAdapter/ProcessTaskAppointmentAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ProcessTaskAppointmentPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ProcessTaskAppointmentAdapter>,
  ProcessTaskAppointment
> {
  @computedFrom('combinedRevision')
  public get canSeeSmsNotificationSetting(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canSeeSmsNotificationSetting'
    );
  }

  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditField');
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessTaskAppointmentContacts(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessTaskAppointmentContacts'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessTaskAppointmentToUsers(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessTaskAppointmentToUsers'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessTaskAppointmentToProcessTaskPositions(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessTaskAppointmentToProcessTaskPositions'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessTaskAppointmentToProcessTaskDevices(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessTaskAppointmentToProcessTaskDevices'
    );
  }
}
