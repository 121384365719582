import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { watch } from '../../hooks/watch';
import { UpgradeEntityIdInUrlService } from '../../services/UpgradeEntityIdInUrlService';
import { ThingActionService } from '../../classes/EntityManager/entities/Thing/ThingActionService';
import { DefectCopiedEvent } from '../../defectComponents/edit-defect-details-widget/edit-defect-details-widget';

@autoinject()
export class EditDefect {
  private defectId: string | null = null;

  /**
   * Whether to redirect to the parent thing whenever the close button is clicked.
   *
   * set by query string.
   */
  private closeToThing = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly thingActionService: ThingActionService,
    private readonly router: Router,
    private readonly upgradeEntityIdInUrlService: UpgradeEntityIdInUrlService
  ) {}

  protected activate(params: {
    defect_id: string;
    close_to_thing?: boolean;
  }): void {
    this.defectId = params.defect_id;
    this.closeToThing = params.close_to_thing ?? false;
  }

  @watch(expression('defectId'), model(EntityName.Defect))
  protected upgradeDefect(): void {
    this.upgradeEntityIdInUrlService.checkId(
      {
        id: this.defectId,
        entityName: EntityName.Defect
      },
      'defect_id'
    );
  }

  protected handleCloseIconClicked(): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot handleCloseIconClicked without defect'
    );

    if (this.closeToThing) {
      const thing = this.entityManager.thingRepository.getById(
        this.defect.ownerThingId
      );
      assertNotNullOrUndefined(
        thing,
        'cannot handleCloseIconClicked without thing'
      );
      this.thingActionService.navigateToThing(thing);
    } else {
      this.router.navigateToRoute('edit_via_worker_defects');
    }
  }

  protected handleDefectCopied(event: DefectCopiedEvent): void {
    this.defectId = event.detail.defect.id;
  }

  @computed(expression('defectId'), model(EntityName.Defect))
  protected get defect(): Defect | null {
    if (!this.defectId) return null;
    return this.entityManager.defectRepository.getById(this.defectId);
  }
}
