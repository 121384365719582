import { autoinject, Disposable } from 'aurelia-framework';

import { TDefaultPropertyConfig } from 'common/Types/DefaultPropertyConfig';
import { DefectStatus } from 'common/Enums/DefectStatus';

import { AppEntityManager } from '../AppEntityManager';
import { CurrentUserService } from '../User/CurrentUserService';
import { Defect, DefectCreationEntity } from './types';
import { DefectUtils } from './DefectUtils';
import {
  PropertyBinder,
  PropertyBinderCallback,
  PropertyBinderName
} from '../../../PropertyBinder/PropertyBinder';
import { ActiveUserCompanySettingService } from '../UserCompanySetting/ActiveUserCompanySettingService';
import { Picture } from '../Picture/types';
import { PictureCopyService } from '../Picture/PictureCopyService';

@autoinject()
export class DefectCreationService {
  private readonly propertyBinder =
    new PropertyBinder<DefectCreationServicePropertyBinderConfig>();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly pictureCopyService: PictureCopyService
  ) {}

  public registerBinding<
    TName extends PropertyBinderName<DefectCreationServicePropertyBinderConfig>
  >(
    name: TName,
    callback: PropertyBinderCallback<
      DefectCreationServicePropertyBinderConfig,
      TName
    >
  ): Disposable {
    return this.propertyBinder.registerBinding(name, callback);
  }

  public getPredefinedDefectProperties(): Array<TDefaultPropertyConfig> {
    const currentUser = this.currentUserService.getCurrentUser();
    if (!currentUser || !currentUser.userCompanySettingId) return [];

    const settings = this.entityManager.userCompanySettingRepository.getById(
      currentUser.userCompanySettingId
    );
    if (!settings) return [];

    return settings.defectManagement.predefinedProperties
      ? (JSON.parse(settings.defectManagement.predefinedProperties)
          ?.properties ?? [])
      : [];
  }

  public createDefectWithoutProperties(
    creationEntity: DefectCreationEntity
  ): Defect {
    const defect = this.entityManager.defectRepository.create({
      assigneeId: this.getDefaultAssigneeId(),
      ...creationEntity
    });
    this.addLastCreatedDefect(defect);
    return defect;
  }

  public createDefect(creationEntity: DefectCreationEntity): Defect {
    const defect = this.createDefectWithoutProperties(creationEntity);
    const predefinedProperties = this.getPredefinedDefectProperties();

    for (const [index, property] of predefinedProperties.entries()) {
      this.entityManager.propertyRepository.create({
        ownerUserGroupId: defect.ownerUserGroupId,
        defect: defect.id,
        ownerDefectId: defect.id,
        shadowEntity: defect.shadowEntity,
        temporaryGroupName: defect.temporaryGroupName,
        order: index,
        ...property
      });
    }

    return defect;
  }

  /**
   * Creates a new defect which has the same set of properties as the parameter. All other defect data is blank.
   */
  public createdDefectBasedOnExistingProperties(
    existingDefect: Defect
  ): Defect {
    const properties = this.entityManager.propertyRepository.getByDefectId(
      existingDefect.id
    );

    const duplicatedDefect = this.createDefectWithoutProperties({
      name: '',
      description: '',
      dueAt: null,
      status: DefectStatus.OPEN,
      ownerThingId: existingDefect.ownerThingId,
      ownerUserGroupId: existingDefect.ownerUserGroupId
    });

    for (const propertyCreationData of properties) {
      DefectUtils.createExistingPropertyForDefect(
        this.entityManager,
        propertyCreationData,
        duplicatedDefect
      );
    }

    return duplicatedDefect;
  }

  public async createDefectWithExistingPictures({
    creationEntity,
    pictures
  }: {
    creationEntity: DefectCreationEntity;
    pictures: Array<Picture>;
  }): Promise<Defect> {
    const defect = this.createDefect(creationEntity);

    await this.copyPicturesToDefect({
      defect,
      pictures
    });

    if (
      !this.activeUserCompanySettingService.getSettingProperty(
        'via.copyPicturesForNewDefect'
      )
    ) {
      for (const picture of pictures) {
        this.entityManager.pictureRepository.delete(picture);
      }
    }

    return defect;
  }

  private async copyPicturesToDefect({
    defect,
    pictures
  }: {
    defect: Defect;
    pictures: Array<Picture>;
  }): Promise<void> {
    for (const [index, picture] of pictures.entries()) {
      await this.pictureCopyService.copy({
        pictureToCopy: picture,
        createPicture: ({ baseData }) => {
          return this.entityManager.pictureRepository.create({
            ownerUserGroupId: defect.ownerUserGroupId,
            ownerDefectId: defect.id,
            defect: defect.id,
            ...baseData,
            selected: index === 0
          });
        }
      });
    }
  }

  private addLastCreatedDefect(defect: Defect): void {
    const lastCreatedDefects =
      this.propertyBinder.getValue('lastCreatedDefects') || [];
    const firstIndex = lastCreatedDefects.findIndex(
      (d) => d.ownerThingId === defect.ownerThingId
    );
    const allDefectsOfCurrentThing = lastCreatedDefects.filter(
      (d) => d.ownerThingId === defect.ownerThingId
    );

    if (allDefectsOfCurrentThing.length === 2) {
      lastCreatedDefects.splice(firstIndex, 1);
    }

    lastCreatedDefects.push(defect);

    this.propertyBinder.setValue('lastCreatedDefects', lastCreatedDefects);
  }

  /**
   * Retrieves the assignee id to be set on newly created defects.
   *
   * Determined from the `defaultAssignedUserEmail` in the user company settings.
   */
  private getDefaultAssigneeId(): string | null {
    // Retrieve user company setting
    const defaultAssignedUserEmail =
      this.activeUserCompanySettingService.getSettingProperty(
        'defectManagement.defaultAssignedUserEmail'
      );
    if (
      !defaultAssignedUserEmail ||
      typeof defaultAssignedUserEmail !== 'string'
    )
      return null;

    // Get user with that specific email
    const defaultAssignedUser = this.entityManager.userRepository.getByEmail(
      defaultAssignedUserEmail
    );
    if (!defaultAssignedUser) return null;

    return defaultAssignedUser.id;
  }
}

type DefectCreationServicePropertyBinderConfig = {
  lastCreatedDefects: Array<Defect>;
};
