import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  ExpressionEditorScopeCreationUtils,
  FieldConfigs,
  ValueType
} from 'common/ExpressionEditorScope/ExpressionEditorScopeCreationUtils';
import { ProcessConfigurationAppointmentPropertiesConfiguration } from 'common/Types/ProcessConfigurationAppointmentPropertiesConfiguration';

import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

@autoinject()
export class ProcessConfigurationConfigurableEmailTextWidget {
  @bindable()
  public processConfiguration: ProcessConfiguration | null = null;

  @subscribableLifecycle()
  protected permissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  constructor(
    permissionsService: PermissionsService,
    private readonly entityManager: AppEntityManager
  ) {
    this.permissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfiguration,
        context: this,
        expression: 'processConfiguration'
      });
  }

  @computed(
    expression('processConfiguration.appointmentPropertiesConfigurationJson')
  )
  protected get fieldConfigs(): FieldConfigs {
    const propertiesConfiguration: ProcessConfigurationAppointmentPropertiesConfiguration | null =
      this.processConfiguration?.appointmentPropertiesConfigurationJson
        ? JSON.parse(
            this.processConfiguration.appointmentPropertiesConfigurationJson
          )
        : null;

    const properties = propertiesConfiguration?.properties ?? [];

    const propertyFieldConfigs = properties.reduce<FieldConfigs>(
      (config, propertyConfig) => {
        if (propertyConfig.name) {
          config[propertyConfig.name] =
            ExpressionEditorScopeCreationUtils.createFieldConfig({
              name: propertyConfig.name,
              valueType: ValueType.STRING,
              nullable: false,
              descriptionTk: ''
            });
        }

        return config;
      },
      {}
    );

    return ExpressionEditorScopeCreationUtils.createFieldConfigs({
      properties: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'properties',
        valueType: ValueType.NESTED,
        fieldConfigs:
          ExpressionEditorScopeCreationUtils.createFieldConfigs(
            propertyFieldConfigs
          ),
        nullable: false,
        descriptionTk:
          'operationsComponents.processConfigurationConfigurableEmailTextWidget.propertiesFieldConfigDescription'
      } as const)
    } as const);
  }

  protected handleProcessConfigurationChanged(): void {
    assertNotNullOrUndefined(
      this.processConfiguration,
      'cannot update process configuration if it is not available'
    );

    this.entityManager.processConfigurationRepository.update(
      this.processConfiguration
    );
  }
}
