import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskLoggingService } from '../../../services/ProcessTaskLoggingService';

/**
 * @event {ConfirmClicked} confirm-clicked
 */
@autoinject()
export class ShowProcessAppointmentConfirmNotePageContent {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public appointment: ProcessTaskAppointment | null = null;

  constructor(
    private readonly element: Element,
    private readonly router: Router,
    private readonly processTaskLoggingService: ProcessTaskLoggingService
  ) {}

  protected handleCancelClick(): void {
    this.router.navigateToRoute('show_user_calendar');
  }

  protected handleConfirmClick(): void {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ShowProcessAppointmentConfirmNotePageContent.handleConfirmClick without processTask"
    );
    assertNotNullOrUndefined(
      this.appointment,
      "can't ShowProcessAppointmentConfirmNotePageContent.handleConfirmClick without appointment"
    );

    void this.processTaskLoggingService.logAppointmentNoteConfirmed(
      this.processTask,
      this.appointment
    );

    DomEventHelper.fireEvent<ConfirmClicked>(this.element, {
      name: 'confirm-clicked',
      detail: null
    });
  }
}

export type ConfirmClicked = NamedCustomEvent<'confirm-clicked', null>;
