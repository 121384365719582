import { autoinject, computedFrom } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { UserDefinedEntityConfigPropertyConfigHelper } from 'common/EntityHelper/UserDefinedEntityConfigPropertyConfigHelper';

import { ScrollHelper } from '../../classes/ScrollHelper';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import {
  UserDefinedEntity,
  UserDefinedEntityCreationEntity
} from '../../classes/EntityManager/entities/UserDefinedEntity/types';
import { Pagination } from '../../aureliaComponents/pagination/pagination';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { CreateEntityClickedEvent } from '../../aureliaComponents/create-entity-button/create-entity-button';
import { computed } from '../../hooks/computed';
import { arrayChanges, expression, model } from '../../hooks/dependencies';
import { EditUserDefinedEntityDialog } from '../../dialogs/edit-user-defined-entity-dialog/edit-user-defined-entity-dialog';
import { SorterSortOption } from '../../aureliaAttributes/sorter';
import {
  MoreButtonChoice,
  MoreButtonFileChangedEvent
} from '../../aureliaComponents/more-button/more-button';
import { Dialogs } from '../../classes/Dialogs';
import { ActionClickedEvent } from '../../aureliaComponents/selectable-item-list/selectable-item-list';
import { SocketService } from '../../services/SocketService';
import {
  CallbackParamsWithUserGroup,
  ImportFromCsvFileDialog
} from '../../dialogs/import-from-csv-file-dialog/import-from-csv-file-dialog';
import {
  FieldInfosFromConfig,
  FieldType,
  ParsedLineData,
  ValidateCallbackResult
} from '../../aureliaComponents/csv-import-widget/csv-import-widget';
import { PropertyImporter } from '../../classes/CsvImporter/PropertyImporter';
import { FilterHelper } from '../../classes/FilterHelper';
import { UserDefinedEntityUtils } from '../../classes/EntityManager/entities/UserDefinedEntity/UserDefinedEntityUtils';
import { FileDownloadService } from '../../services/FileDownloadService';
import { EntitiesPermissionChecker } from '../../services/PermissionsService/EntitiesPermissionChecker/EntitiesPermissionChecker';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntitiesWithPermissionHandle } from '../../services/PermissionsService/EntitiesWithPermissionHandle/EntitiesWithPermissionHandle';
import { watch } from '../../hooks/watch';

@autoinject()
export class EditUserDefinedEntities {
  protected sortedUserDefinedEntities: Array<UserDefinedEntity> = [];
  protected selectedUserDefinedEntities: Array<UserDefinedEntity> = [];
  protected filteredUserDefinedEntities: Array<UserDefinedEntity> = [];
  private filterString: string = '';

  private isAttached = false;

  private sortOptions = UserDefinedEntityUtils.sortOptions;

  protected moreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk: 'generalPages.editUserDefinedEntities.importFromCsv',
      name: 'import-user-defined-entities-from-csv',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'importUserDefinedEntitiesFromCsvDisabled',
      isFileInput: true,
      fileInputAccept: 'text/plain,text/comma-separated-values,.csv'
    },
    {
      labelTk: 'generalPages.editUserDefinedEntities.exportFromCsv',
      name: 'export-user-defined-entities-to-csv',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'isOffline'
    }
  ];

  protected currentSortOption: SorterSortOption<UserDefinedEntity> =
    this.sortOptions.name;

  protected multiSelectMoreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk: 'general.delete',
      name: 'delete-user-defined-entities',
      iconClass: 'fal fa-trash-alt',
      disabledContext: this,
      disabledPropertyName: 'cannotDeleteSelectedUserDefinedEntities'
    }
  ];

  private csvImportFields: FieldInfosFromConfig<FieldInfoConfiguration> = [
    {
      field: 'customId',
      headerTk: 'generalPages.editUserDefinedEntities.csvColumns.customId',
      type: FieldType.STRING,
      required: true
    },
    {
      field: 'userDefinedEntityConfigName',
      headerTk:
        'generalPages.editUserDefinedEntities.csvColumns.userDefinedEntityConfig',
      type: FieldType.STRING,
      extraField: true,
      validateCallback: this.validateUserDefinedEntityConfigColumn.bind(this),
      required: true,
      validationRequired: true
    },
    {
      field: 'name',
      headerTk: 'generalPages.editUserDefinedEntities.csvColumns.name',
      type: FieldType.STRING
    }
  ];

  private pagination: Pagination<UserDefinedEntity> | null = null;

  private subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly userDefinedEntitiesPermissionChecker: EntitiesPermissionChecker<EntityName.UserDefinedEntity>;

  @subscribableLifecycle()
  protected readonly userGroupsWhereUserDefinedEntitiesCanBeCreatedHandle: EntitiesWithPermissionHandle<EntityName.UserGroup>;

  private propertyImporter: PropertyImporter;

  protected isMobile: boolean = false;
  protected isOnline = false;
  protected readonly EntityName = EntityName;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    private readonly fileDownloadService: FileDownloadService,
    private readonly permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.propertyImporter = new PropertyImporter({ entityManager });

    this.userDefinedEntitiesPermissionChecker =
      permissionsService.getEntitiesPermissionChecker({
        entityName: EntityName.UserDefinedEntity
      });

    this.userGroupsWhereUserDefinedEntitiesCanBeCreatedHandle =
      permissionsService.getEntitiesWithPermissionHandleForPermissionName({
        entityName: EntityName.UserGroup,
        permissionName: 'canCreateGlobalUserDefinedEntities'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.updateUserGroups();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  @watch(model(EntityName.UserGroup))
  private updateUserGroups(): void {
    this.userGroupsWhereUserDefinedEntitiesCanBeCreatedHandle.setEntities(
      this.entityManager.userGroupRepository.getAll()
    );
  }

  @computed(model(EntityName.UserDefinedEntity), expression('filterString'))
  protected get searchFilteredUserDefinedEntities(): Array<UserDefinedEntity> {
    return FilterHelper.filterItems(
      this.availableUserDefinedEntities,
      (entity) => entity.name + ' ' + (entity.customId ?? ''),
      this.filterString
    );
  }

  @computed(model(EntityName.UserDefinedEntity))
  protected get availableUserDefinedEntities(): Array<UserDefinedEntity> {
    return this.entityManager.userDefinedEntityRepository.getGlobalEntities();
  }

  @computed(
    expression('userDefinedEntitiesPermissionChecker.revision'),
    arrayChanges('selectedUserDefinedEntities')
  )
  protected get cannotDeleteSelectedUserDefinedEntities(): boolean {
    return this.userDefinedEntitiesPermissionChecker.allEntitiesHavePermission({
      entities: this.selectedUserDefinedEntities,
      checkPermission: ({ adapter, entity }) => {
        return adapter.canDeleteEntity(entity);
      }
    });
  }

  @computedFrom(
    'isOnline',
    'userGroupsWhereUserDefinedEntitiesCanBeCreatedHandle.filteredEntities.length '
  )
  protected get importUserDefinedEntitiesFromCsvDisabled(): boolean {
    return (
      !this.isOnline ||
      this.userGroupsWhereUserDefinedEntitiesCanBeCreatedHandle.filteredEntities
        .length === 0
    );
  }

  @computedFrom('isOnline')
  protected get isOffline(): boolean {
    return !this.isOnline;
  }

  protected async handleImportUserDefinedEntitiesFromCsvFileChanged(
    event: MoreButtonFileChangedEvent<any>['detail']
  ): Promise<void> {
    if (!event.target.files || !event.target.files[0]) return;

    await ImportFromCsvFileDialog.open<FieldInfoConfiguration>({
      file: event.target.files[0],
      editableUserGroups:
        this.userGroupsWhereUserDefinedEntitiesCanBeCreatedHandle
          .filteredEntities,
      entityNameTk: 'models.UserDefinedEntityModel_plural',
      fields: this.csvImportFields,
      showOverwriteCheckbox: true,
      showUserGroupSelect: true,
      showAdditionalFieldsAsParametersCheckbox: true,
      importFromCsvFileCallback:
        this.handleImportUserDefinedEntitiesFromCsvFileSubmitted.bind(this)
    });

    event.target.value = '';
  }

  protected handleExportUserDefinedEntitiesToCsvFileClicked(): void {
    const userDefinedEntityIdsToExport = this.selectedUserDefinedEntities.length
      ? this.selectedUserDefinedEntities.map((x) => x.id)
      : this.filteredUserDefinedEntities.map((x) => x.id);

    Dialogs.waitDialogTk(
      'generalPages.editUserDefinedEntities.csvExportStarted'
    );

    this.socketService.exportUserDefinedEntitiesAsCsvFile(
      userDefinedEntityIdsToExport,
      (response) => {
        if (response.success) {
          Dialogs.closeAllDialogs();
          void this.fileDownloadService.downloadFile(
            response.filePath.replace(/\\/g, '/')
          );
        } else {
          void Dialogs.errorDialogTk(
            'generalPages.editUserDefinedEntities.csvExportFailed',
            response.error && response.error.message
              ? response.error.message
              : ''
          );
        }
      }
    );
  }

  protected handleCreateUserDefinedEntityClick(
    event: CreateEntityClickedEvent
  ): void {
    const userGroupId = event.detail.userGroup?.id;
    assertNotNullOrUndefined(
      userGroupId,
      'cannot create user defined entity without a usergroup'
    );

    const userDefinedEntity =
      this.entityManager.userDefinedEntityRepository.create({
        ownerUserGroupId: userGroupId,
        isGlobal: true
      });

    this.editUserDefinedEntity(userDefinedEntity);
  }

  protected handleUserDefinedEntityDetailActionClick(
    userDefinedEntity: UserDefinedEntity
  ): void {
    this.editUserDefinedEntity(userDefinedEntity);
  }

  protected async handleDeleteSelectedUserDefinedEntities(
    event: ActionClickedEvent<UserDefinedEntity>
  ): Promise<void> {
    await Dialogs.deleteDialogTk('pages.editUserDefinedEntities.bulkDelete', {
      count: event.detail.selectedItems.length
    });

    event.detail.selectedItems.forEach((x) =>
      this.entityManager.userDefinedEntityRepository.delete(x)
    );
  }

  private editUserDefinedEntity(userDefinedEntity: UserDefinedEntity): void {
    void EditUserDefinedEntityDialog.open({
      userDefinedEntity: userDefinedEntity,
      onDialogClosed: (entity) => {
        this.goToUserDefinedEntity(entity);
      }
    });
  }

  private goToUserDefinedEntity(userDefinedEntity: UserDefinedEntity): void {
    void ScrollHelper.autoScrollToListItem(
      '#user-defined-entity-' + userDefinedEntity.id,
      this.pagination,
      userDefinedEntity,
      () => this.isAttached
    );
  }

  private validateUserDefinedEntityConfigColumn(
    parsedData: Array<ParsedLineData<FieldInfoConfiguration>>
  ): ValidateCallbackResult {
    const names = this.entityManager.userDefinedEntityConfigRepository
      .getAll()
      .map((g) => g.name);

    const valid = parsedData.every((data) =>
      names.includes(data.fields.userDefinedEntityConfigName ?? '')
    );

    return {
      valid,
      errorMsgTk: valid
        ? null
        : 'generalPages.editUserDefinedEntities.invalidUserDefinedEntityConfigNames'
    };
  }

  private async handleImportUserDefinedEntitiesFromCsvFileSubmitted(
    detail: CallbackParamsWithUserGroup<FieldInfoConfiguration>
  ): Promise<void> {
    const lineData = detail.parsedContent;
    const userDefinedEntityConfigs =
      this.entityManager.userDefinedEntityConfigRepository.getByUserGroupId(
        detail.userGroupId
      );

    for (const lD of lineData) {
      const config = userDefinedEntityConfigs.find(
        (x) => x.name === lD.fields.userDefinedEntityConfigName
      );
      if (!config) {
        throw new Error(
          'cannot find entity config with name ' +
            lD.fields.userDefinedEntityConfigName
        );
      }
      await this.createOrUpdateUserDefinedEntityIfAllowed(
        lD,
        config.id,
        detail
      );
    }
  }

  private async createOrUpdateUserDefinedEntityIfAllowed(
    lD: ParsedLineData<FieldInfoConfiguration>,
    userDefinedEntityConfigId: string,
    detail: CallbackParamsWithUserGroup<FieldInfoConfiguration>
  ): Promise<void> {
    const existingEntity = lD.fields.customId
      ? this.entityManager.userDefinedEntityRepository
          .getGlobalEntities()
          .find((x) => {
            return x.customId === lD.fields.customId;
          })
      : null;

    if (existingEntity && !detail.overwrite) return;

    const newCreationEntity: UserDefinedEntityCreationEntity = {
      ownerUserGroupId: detail.userGroupId,
      userDefinedEntityConfigId,
      isGlobal: true
    };

    this.applyValuesToNewUserDefinedEntity(newCreationEntity, lD);

    let userDefinedEntity;
    if (existingEntity) {
      await this.updateUserDefinedEntityIfAllowed({
        existingEntity,
        newCreationEntity
      });

      userDefinedEntity = existingEntity;
    } else {
      userDefinedEntity = await this.createUserDefinedEntityIfAllowed({
        newCreationEntity,
        userDefinedEntityConfigId
      });
    }

    if (userDefinedEntity && detail.fieldsAsProperties) {
      this.updatePropertyValues({
        userDefinedEntity,
        lineData: lD,
        detail
      });
    }
  }

  private async updateUserDefinedEntityIfAllowed({
    existingEntity,
    newCreationEntity
  }: {
    existingEntity: UserDefinedEntity;
    newCreationEntity: UserDefinedEntityCreationEntity;
  }): Promise<void> {
    const canEditFields = await this.permissionsService.useAdapterOnce({
      entityName: EntityName.UserDefinedEntity,
      useAdapter: (adapter) => {
        return adapter.canEditField(existingEntity);
      }
    });

    if (!canEditFields) {
      return;
    }

    Object.assign(existingEntity, newCreationEntity);
    this.entityManager.userDefinedEntityRepository.update(existingEntity);
  }

  private async createUserDefinedEntityIfAllowed({
    newCreationEntity,
    userDefinedEntityConfigId
  }: {
    newCreationEntity: UserDefinedEntityCreationEntity;
    userDefinedEntityConfigId: string;
  }): Promise<UserDefinedEntity | null> {
    const canCreateGlobalUserDefinedEntities =
      await this.permissionsService.useAdapterOnce({
        entityName: EntityName.UserGroup,
        useAdapter: (adapter) => {
          return adapter.canCreateGlobalUserDefinedEntities(
            this.entityManager.userGroupRepository.getRequiredById(
              newCreationEntity.ownerUserGroupId
            )
          );
        }
      });

    if (!canCreateGlobalUserDefinedEntities) {
      return null;
    }

    const userDefinedEntity =
      this.entityManager.userDefinedEntityRepository.create(newCreationEntity);

    const propertyConfigs =
      this.entityManager.userDefinedEntityConfigPropertyConfigRepository.getByUserDefinedEntityConfigId(
        userDefinedEntityConfigId
      );

    for (const pConfig of propertyConfigs ?? []) {
      this.entityManager.propertyRepository.create({
        ...UserDefinedEntityConfigPropertyConfigHelper.reduceToPropertyConfig(
          pConfig
        ),
        ownerUserGroupId: userDefinedEntity.ownerUserGroupId,
        userDefinedEntityId: userDefinedEntity.id,
        ownerProjectId: userDefinedEntity.ownerProjectId ?? null
      });
    }

    return userDefinedEntity;
  }

  private applyValuesToNewUserDefinedEntity(
    newUserDefinedEntity: UserDefinedEntityCreationEntity,
    lineData: ParsedLineData<FieldInfoConfiguration>
  ): void {
    for (const fieldKey in lineData.fields) {
      const fieldInfo = this.csvImportFields.find(
        (fInfo) => fInfo.field === fieldKey
      );
      if (!fieldInfo || fieldInfo.extraField) continue;

      newUserDefinedEntity[fieldInfo.field] =
        lineData.fields[
          fieldKey as keyof ParsedLineData<FieldInfoConfiguration>['fields']
        ];
    }
  }

  private updatePropertyValues({
    userDefinedEntity,
    lineData,
    detail
  }: {
    userDefinedEntity: UserDefinedEntity;
    lineData: ParsedLineData<FieldInfoConfiguration>;
    detail: CallbackParamsWithUserGroup<FieldInfoConfiguration>;
  }): void {
    this.propertyImporter.import({
      properties:
        this.entityManager.propertyRepository.getByUserDefinedEntityId(
          userDefinedEntity.id
        ),
      propertyValues: lineData.additionalFields,
      decimalSeparator: detail.decimalSeparator
    });
  }
}

type FieldInfoConfiguration = {
  entityType: UserDefinedEntity;
  fieldInfos: [
    {
      field: 'customId';
      type: FieldType.STRING;
    },
    {
      field: 'userDefinedEntityConfigName';
      type: FieldType.STRING;
    },
    {
      field: 'name';
      type: FieldType.STRING;
    }
  ];
};
