import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';

import { defaultProcessTaskMeasurePointReadingProperties as defaultReadingProperties } from '../../data/Trockenmax/defaultProcessTaskMeasurePointReadingProperties';
import { Utils } from '../../classes/Utils/Utils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskMeasurePointReading } from '../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types';
import { ProcessTaskMeasurePointReadingProperty } from '../../classes/EntityManager/entities/Property/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { PropertyInputField } from '../../aureliaComponents/property-input-field/property-input-field';

@autoinject()
export class ProcessTaskMeasurePointReadingItem {
  @bindable()
  public measurePointReading: ProcessTaskMeasurePointReading | null = null;

  @bindable()
  public forceDisabled: boolean = false;

  @bindable()
  public showLabels: boolean = true;

  @bindable()
  public showDate: boolean = true;

  private readonly subscriptionManager: SubscriptionManager;

  private properties: Array<ProcessTaskMeasurePointReadingProperty> = [];

  protected isMobile: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public focus(): void {
    const property = this.getPropertyToFocus();
    if (!property) {
      return;
    }

    const inputField = this.getPropertyInputFieldViewModel(property);
    if (!inputField) {
      return;
    }

    inputField.focus();
  }

  protected attached(): void {
    this.updateProperties();
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );
    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isSmallMobile', (isSmallMobile) => {
        this.isMobile = isSmallMobile;
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected measurePointReadingChanged(): void {
    this.updateProperties();
  }

  private updateProperties(): void {
    if (!this.measurePointReading) {
      this.properties = [];
      return;
    }
    const measurePointReading = this.measurePointReading;
    this.properties =
      this.entityManager.propertyRepository.getSortedPropertiesByProcessTaskMeasurePointReadingId(
        measurePointReading.id
      );
  }

  private getPropertyToFocus(): ProcessTaskMeasurePointReadingProperty | null {
    const defaultProperty = defaultReadingProperties.find(
      (i) => i.focusProperty
    );
    let propertyToFocus: ProcessTaskMeasurePointReadingProperty | null = null;

    if (defaultProperty) {
      propertyToFocus =
        this.properties.find((p) => p.name === defaultProperty.name) || null;
    }

    if (!propertyToFocus) {
      propertyToFocus = this.properties[0] || null; // focus the first one as fallback
    }

    return propertyToFocus;
  }

  private getPropertyInputFieldViewModel(
    property: ProcessTaskMeasurePointReadingProperty
  ): PropertyInputField | null {
    const element = document.getElementById(
      this.getPropertyElementId(property.id)
    );
    if (!element) {
      return null;
    }

    return Utils.getViewModelOfElement<PropertyInputField>(element);
  }

  private getPropertyElementId(propertyId: string): string {
    return 'process-task-measure-point-reading-item--property' + propertyId;
  }
}
