import { bindable, autoinject } from 'aurelia-framework';

import {
  ProcessConfigurationPositionType,
  ProcessConfigurationPositionTypeToConfiguration,
  TProcessConfigurationPositionTypeConfiguration
} from 'common/Enums/ProcessConfigurationPositionType';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ClickableTextInput } from '../../inputComponents/clickable-text-input/clickable-text-input';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskPositionProperty } from '../../classes/EntityManager/entities/Property/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';

/**
 * @event process-task-position-changed - fired when some value of the position has been changed by the user, e.g. you can notify the ProcessTaskPositionService that it has been updated here
 */
@autoinject()
export class ProcessTaskPositionEditWidget {
  @bindable()
  public processTaskPosition: ProcessTaskPosition | null = null;

  private subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected processTaskPositionPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskPosition];

  protected processTaskGroup: ProcessTaskGroup | null = null;
  protected properties: Array<ProcessTaskPositionProperty> = [];
  private isAttached: boolean = false;

  protected typeConfiguration: TProcessConfigurationPositionTypeConfiguration | null =
    null;

  private positionAmountTextInputViewModel: ClickableTextInput | null = null;
  protected readonly ProcessConfigurationPositionType =
    ProcessConfigurationPositionType;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    permissionsService: PermissionsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskPositionPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskPosition,
        context: this,
        expression: 'processTaskPosition'
      });
  }

  public focusInput(): void {
    if (!this.positionAmountTextInputViewModel) return;
    this.positionAmountTextInputViewModel.focus();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );
    this.updateProperties();

    this.subscriptionManager.subscribeToExpression(
      this,
      'processTaskPosition.type',
      this.updateTypeConfiguration.bind(this)
    );
    this.updateTypeConfiguration();

    this.updateProcessTaskGroup();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskPositionChanged(): void {
    if (this.isAttached) {
      this.updateProperties();
      this.updateProcessTaskGroup();
    }
  }

  private updateProperties(): void {
    if (this.processTaskPosition) {
      this.properties =
        this.entityManager.propertyRepository.getByProcessTaskPositionId(
          this.processTaskPosition.id
        );
    } else {
      this.properties = [];
    }
  }

  private updateTypeConfiguration(): void {
    const type = this.processTaskPosition
      ? this.processTaskPosition.type
      : ProcessConfigurationPositionType.DEFAULT;
    this.typeConfiguration =
      ProcessConfigurationPositionTypeToConfiguration.get(type) || null;
  }

  protected handleProcessTaskPositionChanged(property?: string): void {
    assertNotNullOrUndefined(
      this.processTaskPosition,
      "can't ProcessTaskPositionEditWidget.handleProcessTaskPositionChanged without processTaskPosition"
    );

    this.entityManager.processTaskPositionRepository.update(
      this.processTaskPosition
    );
    this.processTaskLoggingService.logProcessTaskSubEntityModified({
      entityName: EntityName.ProcessTaskPosition,
      entity: this.processTaskPosition,
      property: property ?? null,
      displayNameAtLogTime: this.processTaskPosition.name
    });
  }

  private updateProcessTaskGroup(): void {
    if (this.processTaskPosition) {
      this.processTaskGroup =
        this.entityManager.processTaskGroupRepository.getById(
          this.processTaskPosition.ownerProcessTaskGroupId
        );
    } else {
      this.processTaskGroup = null;
    }
  }

  protected convertToString(value: any): string {
    return String(value);
  }
}
