import {
  autoinject,
  bindable,
  computedFrom,
  observable
} from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ScrollHelper } from '../../classes/ScrollHelper';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';

import { regionProperties as wildbachRegionProperties } from '../../data/Wildbach/regionProperties';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { Region } from '../../classes/EntityManager/entities/Region/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Utils } from '../../classes/Utils/Utils';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EditRegionsWidgetRegion } from './edit-regions-widget-region/edit-regions-widget-region';
import { EntitiesPermissionChecker } from '../../services/PermissionsService/EntitiesPermissionChecker/EntitiesPermissionChecker';

@autoinject()
export class EditRegionsWidget {
  @bindable public thing: Thing | null = null;

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  @subscribableLifecycle()
  protected readonly regionPermissionChecker: EntitiesPermissionChecker<EntityName.Region>;

  private regions: Array<Region> = [];
  protected filteredRegions: Array<Region> = [];
  private canUseViaWildbach = false;
  @observable private regionFilterString: string;
  private subscriptionManager: SubscriptionManager;
  private permissionBindings: PermissionBindingHandle;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    permissionBindingService: PermissionBindingService,
    private entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionBindings = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canUseViaWildbach: 'canUseViaWildbach'
      }
    });

    this.regionFilterString = '';

    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Thing,
        context: this as EditRegionsWidget,
        propertyName: 'thing'
      });

    this.regionPermissionChecker =
      permissionsService.getEntitiesPermissionChecker({
        entityName: EntityName.Region
      });
  }

  protected attached(): void {
    this.permissionBindings.subscribe();
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Region,
      this.updateRegions.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateRegions.bind(this)
    );
    this.updateRegions();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.permissionBindings.unsubscribe();
  }

  protected thingChanged(): void {
    this.updateRegions();
  }

  protected regionFilterStringChanged(): void {
    this.filterRegions();
  }

  private filterRegions(): void {
    this.filteredRegions = this.regions.filter((region) =>
      this.matchesRegionFilter(region.name)
    );
  }

  private matchesRegionFilter(name: string | null): boolean {
    return (
      name == null ||
      name === '' ||
      name
        .toLocaleUpperCase()
        .includes(this.regionFilterString.toLocaleUpperCase())
    );
  }

  protected handleAddRegionClick(): void {
    if (!this.thing) return;
    const region = this.entityManager.regionRepository.create({
      thingId: this.thing.id,
      ownerUserGroupId: this.thing.ownerUserGroupId
    });

    if (this.canUseViaWildbach) {
      for (const property of wildbachRegionProperties) {
        this.entityManager.propertyRepository.create({
          ...property,
          regionId: region.id,
          ownerUserGroupId: region.ownerUserGroupId
        });
      }
    }

    this.updateRegions();
    setTimeout(() => {
      this.goToRegion(region);
    }, 10);
  }

  protected handleSyncRegionPropertiesClick(): void {
    if (!this.canUseViaWildbach) return;
    for (const region of this.regions) {
      const currentProperties = this.entityManager.propertyRepository
        .getByRegionId(region.id)
        .filter((prop) => !prop.hidden);

      for (const property of wildbachRegionProperties) {
        if (!currentProperties.find((prop) => prop.name === property.name)) {
          this.entityManager.propertyRepository.create({
            ...property,
            regionId: region.id,
            ownerUserGroupId: region.ownerUserGroupId
          });
        }
      }
    }
  }

  protected handleRegionChanged(region: Region): void {
    this.updateRegions();
    this.goToRegion(region);
  }

  private updateRegions(): void {
    if (!this.thing) {
      this.regions = [];
      return;
    }
    this.regions = this.entityManager.regionRepository.getByThingId(
      this.thing.id
    );
    this.filterRegions();
  }

  private goToRegion(region: Region): void {
    if (this.matchesRegionFilter(region.name)) {
      const element = document.querySelector(
        '#' + this.getRegionElementId(region.id)
      ) as HTMLElement | null;

      if (!element) {
        return;
      }

      void ScrollHelper.scrollToItem(element);

      const viewModel =
        Utils.getViewModelOfElement<EditRegionsWidgetRegion>(element);
      viewModel?.expand();
    }
  }

  private getRegionElementId(regionId: string): string {
    return 'edit-regions-widget--region-' + regionId;
  }

  @computedFrom(
    'canUseViaWildbach',
    'regionPermissionChecker.revision',
    'regions'
  )
  protected get canSyncRegionProperties(): boolean {
    if (!this.canUseViaWildbach) {
      return false;
    }

    return this.regionPermissionChecker.allEntitiesHavePermission({
      entities: this.regions,
      checkPermission: ({ adapter, entity }) => {
        return adapter.canEditProperties(entity);
      }
    });
  }
}
