import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';

import { Dialogs } from '../../../../classes/Dialogs';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplate } from '../../../../classes/EntityManager/entities/StructureTemplate/types';
import { StructureTemplateEntryProperty } from '../../../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { StructureTemplateEntryPropertyDefinitionHandle } from '../../../property-definition-widget/PropertyDefinitionWidgetHandle/StructureTemplateEntryPropertyDefinitionHandle/StructureTemplateEntryPropertyDefinitionHandle';
import {
  ManagePropertyDefinitionsBaseWidgetAdapter,
  ManagePropertyDefinitionsWidgetAdapterDeleteOptions,
  ManagePropertyDefinitionsBaseWidgetAdapterSubscribeOptions
} from '../ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsBaseWidgetAdapter';
import { ManagePropertyDefinitionsWidgetAdapterHandleCache } from '../ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsWidgetAdapterHandleCache';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';

export class ManagePropertyDefinitionsWidgetStructureTemplateEntryPropertyAdapter
  implements
    ManagePropertyDefinitionsBaseWidgetAdapter<StructureTemplateEntryProperty>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly permissionsService: PermissionsService;
  private readonly handleCache: ManagePropertyDefinitionsWidgetAdapterHandleCache<
    StructureTemplateEntryProperty,
    StructureTemplateEntryPropertyDefinitionHandle
  >;

  private readonly structureTemplate: StructureTemplate;

  constructor(
    options: ManageDefinedPropertiesWidgetStructureTemplateEntryAdapterOptions
  ) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.permissionsService = options.permissionsService;
    this.structureTemplate = options.structureTemplate;

    this.handleCache = new ManagePropertyDefinitionsWidgetAdapterHandleCache({
      createPropertyDefinitionHandle: ({ propertyDefinition }) => {
        return new StructureTemplateEntryPropertyDefinitionHandle({
          entityManager: options.entityManager,
          permissionsService: this.permissionsService,
          property: propertyDefinition
        });
      }
    });
  }

  public subscribe({
    setPropertyDefinitionHandles,
    setCanCreatePropertyDefinitions
  }: ManagePropertyDefinitionsBaseWidgetAdapterSubscribeOptions<StructureTemplateEntryProperty>): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(this.handleCache.subscribe());

    const { updatePropertyDefinitions } =
      this.handleCache.createUpdatePropertyDefinitionsFunction({
        setPropertyDefinitionHandles,
        getPropertyDefinitions: () => {
          return this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateId(
            this.structureTemplate.id
          );
        }
      });

    subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntryProperty,
      updatePropertyDefinitions
    );
    updatePropertyDefinitions();

    const structureTemplatePermissionsHandle =
      this.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.StructureTemplate,
        entity: this.structureTemplate
      });

    subscriptionManager.subscribeToExpression(
      structureTemplatePermissionsHandle,
      'canCreateStructureTemplateEntryProperties',
      () => {
        setCanCreatePropertyDefinitions(
          structureTemplatePermissionsHandle.canCreateStructureTemplateEntryProperties
        );
      }
    );

    setCanCreatePropertyDefinitions(
      structureTemplatePermissionsHandle.canCreateStructureTemplateEntryProperties
    );

    subscriptionManager.addDisposable(
      structureTemplatePermissionsHandle.subscribe()
    );

    return subscriptionManager.toDisposable();
  }

  public createPropertyDefinition(
    order: number
  ): StructureTemplateEntryPropertyDefinitionHandle {
    const property =
      this.entityManager.structureTemplateEntryPropertyRepository.create({
        ownerStructureTemplateId: this.structureTemplate.id,
        ownerUserGroupId: this.structureTemplate.ownerUserGroupId,
        order
      });

    return this.handleCache.createCachedPropertyDefinitionHandle({
      propertyDefinition: property
    });
  }

  public async deletePropertyDefinition({
    propertyDefinition
  }: ManagePropertyDefinitionsWidgetAdapterDeleteOptions<StructureTemplateEntryProperty>): Promise<void> {
    return Dialogs.deleteEntityDialog(
      propertyDefinition,
      EntityName.Property
    ).then(() => {
      this.entityManager.structureTemplateEntryPropertyRepository.delete(
        propertyDefinition
      );
    });
  }

  public arePropertyDefinitionsEqual(
    p1: StructureTemplateEntryProperty,
    p2: StructureTemplateEntryProperty
  ): boolean {
    return PropertyHelper.isTheSameProperty(p1, p2);
  }
}

export type ManageDefinedPropertiesWidgetStructureTemplateEntryAdapterOptions =
  {
    entityManager: AppEntityManager;
    subscriptionManagerService: SubscriptionManagerService;
    permissionsService: PermissionsService;
    structureTemplate: StructureTemplate;
  };
