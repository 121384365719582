import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskOfferEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskOffer/processTaskOfferEntityInfo';
import { ProcessTaskOffer } from '../../../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { ProcessTaskGroupAuthorizationComputer } from '../../../../computedValues/computers/ProcessTaskGroupAuthorizationComputer/ProcessTaskGroupAuthorizationComputer';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import {
  CanEditFieldFieldName,
  EntityAdapter,
  SubscribeOptions
} from '../EntityAdapter';

export class ProcessTaskOfferAdapter
  implements EntityAdapter<ProcessTaskOffer>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private processTaskGroupIdsWhereUserIsAuthorized: Set<string> | null = null;

  constructor(options: ProcessTaskOfferAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskGroupAuthorizationComputer,
        computeData: {},
        callback: ({ processTaskGroupIdsWhereUserIsAuthorized }) => {
          this.processTaskGroupIdsWhereUserIsAuthorized =
            processTaskGroupIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.processTaskGroupIdsWhereUserIsAuthorized != null
    );
  }

  public canDeleteEntity(processTaskOffer: ProcessTaskOffer): boolean {
    return this.checkPermission({
      processTaskOffer,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteProcessTaskOffers()
    });
  }

  public canEditField(
    processTaskOffer: ProcessTaskOffer,
    fieldName: CanEditFieldFieldName<ProcessTaskOffer>
  ): boolean {
    if (fieldName === 'acceptedAt') {
      return this.checkPermission({
        processTaskOffer,
        checkRolePermission: (permissions) =>
          permissions.getCanUpdateProcessTaskOffers()
      });
    }

    return this.checkPermissionWithAcceptedAtCheck({
      processTaskOffer,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskOffers()
    });
  }

  public canEditProcessTaskOfferToProcessTasks(
    processTaskOffer: ProcessTaskOffer
  ): boolean {
    return this.checkPermissionWithAcceptedAtCheck({
      processTaskOffer,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskOffers()
    });
  }

  public canEditProcessTaskOfferToProcessTaskDevices(
    processTaskOffer: ProcessTaskOffer
  ): boolean {
    return this.checkPermissionWithAcceptedAtCheck({
      processTaskOffer,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskOffers()
    });
  }

  public canEditProcessTaskOfferToProcessTaskPositions(
    processTaskOffer: ProcessTaskOffer
  ): boolean {
    return this.checkPermissionWithAcceptedAtCheck({
      processTaskOffer,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskOffers()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskOffer,
    ProcessTaskOffer
  > {
    return processTaskOfferEntityInfo;
  }

  private checkPermissionWithAcceptedAtCheck({
    processTaskOffer,
    checkRolePermission
  }: {
    processTaskOffer: ProcessTaskOffer;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (processTaskOffer.acceptedAt) {
      return false;
    }

    return this.checkPermission({
      processTaskOffer,
      checkRolePermission
    });
  }

  private checkPermission({
    processTaskOffer,
    checkRolePermission
  }: {
    processTaskOffer: ProcessTaskOffer;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkProcessTaskGroupSubEntityPermission({
      entity: processTaskOffer,
      processTaskGroupIdsWhereUserIsAuthorized:
        this.processTaskGroupIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ProcessTaskOfferAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
