import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { DefectTextBrick, EntryTextBrick } from './types';

export class TextBrickRepository extends AppEntityRepository<EntityName.TextBrick> {
  public getByEntryId(entryId: string): Array<EntryTextBrick> {
    return this.getAll().filter(
      (tB): tB is EntryTextBrick => tB.entryId === entryId
    );
  }

  public getByDefectId(defectId: string): Array<DefectTextBrick> {
    return this.getAll().filter(
      (tB): tB is DefectTextBrick => tB.ownerDefectId === defectId
    );
  }
}
