import { autoinject, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { CheckedChangedEvent } from '../../aureliaComponents/expandable-container/expandable-container';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Region } from '../../classes/EntityManager/entities/Region/types';
import { ThingCreationService } from '../../classes/EntityManager/entities/Thing/ThingCreationService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';

@autoinject()
export class CopyThingDialog {
  public static async open(options: CopyThingDialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private options: Required<CopyThingDialogOptions> | null = null;

  protected loading = false;
  protected dialog: RecordItDialog | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly thingCreationService: ThingCreationService,
    private readonly permissionsService: PermissionsService
  ) {}

  public open(options: CopyThingDialogOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't CopyThingDialog.open without dialog"
    );

    if (this.options) {
      Object.assign(this.options, options);
    } else {
      this.options = {
        ...this.getDefaultOptionsWithoutThingId(),
        ...options
      };
    }

    this.dialog.open();
  }

  protected handleAllSelectedCheckedChanged(event: CheckedChangedEvent): void {
    assertNotNullOrUndefined(
      this.options,
      "can't CopyThingDialog.handleAllSelectedCheckedChanged without options"
    );

    this.options.copyTags = event.detail.checked;

    this.options.copyRegions = event.detail.checked;
    this.options.copyRegionProperties = event.detail.checked;
    this.options.copyRegionPropertyValues = event.detail.checked;

    this.options.copyProperties = event.detail.checked;
    this.options.copyPropertyValues = event.detail.checked;
  }

  @computedFrom(
    'options.copyTags',
    'options.copyRegions',
    'options.copyRegionProperties',
    'options.copyRegionPropertyValues',
    'options.copyProperties',
    'options.copyPropertyValues'
  )
  protected get allSelected(): boolean {
    if (!this.options) {
      return false;
    }

    return (
      this.options.copyTags &&
      this.options.copyRegions &&
      this.options.copyRegionProperties &&
      this.options.copyRegionPropertyValues &&
      this.options.copyProperties &&
      this.options.copyPropertyValues
    );
  }

  protected handleCloseDialogClick(): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't CopyThingDialog.handleCloseDialogClick without dialog"
    );

    this.dialog.close();
  }

  protected async handleCopyThingClick(): Promise<void> {
    assertNotNullOrUndefined(
      this.options,
      "can't CopyThingDialog.handleCopyThingClick without options"
    );

    this.loading = true;

    const originalThing = this.entityManager.thingRepository.getById(
      this.options.thingId
    );
    if (!originalThing) {
      throw new Error(`Thing with id "${this.options.thingId}" not found!`);
    }

    const newThing = this.thingCreationService.create({
      ...originalThing,
      name: this.options.newName
    });

    if (this.options.copyTags) {
      this.copyThingTags({ originalThing, newThing });
    }

    if (this.options.copyRegions) {
      await this.copyThingRegionsIfAllowed({
        originalThing,
        newThing,
        copyRegionProperties: this.options.copyRegionProperties,
        copyRegionPropertyValues: this.options.copyRegionProperties
      });
    }

    if (this.options.copyProperties) {
      await this.copyThingPropertiesIfAllowed({
        originalThing,
        newThing,
        copyPropertyValues: this.options.copyPropertyValues
      });
    }

    this.loading = false;
    this.handleCloseDialogClick();
  }

  private copyThingTags({
    originalThing,
    newThing
  }: {
    originalThing: Thing;
    newThing: Thing;
  }): void {
    const originalTags = this.entityManager.tagRepository.getByThingId(
      originalThing.id
    );

    // TODO: REC-4516 check for tag creation permissions
    for (const tag of originalTags) {
      this.entityManager.tagRepository.create({
        ...tag,
        thing: newThing.id
      });
    }
  }

  private async copyThingRegionsIfAllowed({
    originalThing,
    newThing,
    copyRegionProperties,
    copyRegionPropertyValues
  }: {
    originalThing: Thing;
    newThing: Thing;
    copyRegionProperties: boolean;
    copyRegionPropertyValues: boolean;
  }): Promise<void> {
    const canCreateRegions = await this.permissionsService.useAdapterOnce({
      entityName: EntityName.Thing,
      useAdapter: (adapter) => {
        return adapter.canCreateRegions(newThing);
      }
    });

    if (!canCreateRegions) {
      console.warn(
        'skipped copying regions because the user is not allowed to'
      );
      return;
    }

    const originalRegions = this.entityManager.regionRepository.getByThingId(
      originalThing.id
    );

    for (const originalRegion of originalRegions) {
      const newRegion = this.entityManager.regionRepository.create({
        ...originalRegion,
        thingId: newThing.id
      });

      if (copyRegionProperties)
        await this.copyRegionPropertiesIfAllowed({
          originalRegion,
          newRegion,
          copyRegionPropertyValues
        });
    }
  }

  private async copyThingPropertiesIfAllowed({
    originalThing,
    newThing,
    copyPropertyValues
  }: {
    originalThing: Thing;
    newThing: Thing;
    copyPropertyValues: boolean;
  }): Promise<void> {
    const canEditProperties = await this.permissionsService.useAdapterOnce({
      entityName: EntityName.Thing,
      useAdapter: (adapter) => {
        return adapter.canEditProperties(newThing);
      }
    });

    if (!canEditProperties) {
      console.warn(
        'skipped copying thingProperties because the user is not allowed to'
      );
      return;
    }

    const originalProperties =
      this.entityManager.propertyRepository.getByThingId(originalThing.id);

    for (const property of originalProperties) {
      this.entityManager.propertyRepository.create({
        ...property,
        thing: newThing.id,
        ...(copyPropertyValues ? {} : { value: null, custom_choice: null })
      });
    }
  }

  private async copyRegionPropertiesIfAllowed({
    originalRegion,
    newRegion,
    copyRegionPropertyValues
  }: {
    originalRegion: Region;
    newRegion: Region;
    copyRegionPropertyValues: boolean;
  }): Promise<void> {
    const canEditProperties = await this.permissionsService.useAdapterOnce({
      entityName: EntityName.Region,
      useAdapter: (adapter) => {
        return adapter.canEditProperties(newRegion);
      }
    });

    if (!canEditProperties) {
      console.warn(
        'skipped copying regionProperties because the user is not allowed to'
      );
      return;
    }

    const originalProperties = this.entityManager.propertyRepository
      .getByRegionId(originalRegion.id)
      .filter(
        (property) => !property.name || !property.name.startsWith('description')
      );

    for (const property of originalProperties) {
      this.entityManager.propertyRepository.create({
        ...property,
        regionId: newRegion.id,
        ...(copyRegionPropertyValues
          ? {}
          : { value: null, custom_choice: null })
      });
    }
  }

  private getDefaultOptionsWithoutThingId(): Omit<
    Required<CopyThingDialogOptions>,
    'thingId'
  > {
    return {
      newName: '',

      copyTags: false,

      copyRegions: false,
      copyRegionProperties: false,
      copyRegionPropertyValues: false,

      copyProperties: false,
      copyPropertyValues: false
    };
  }
}

export type CopyThingDialogOptions = {
  thingId: string;
  newName: string;
  copyTags?: boolean;
  copyRegions?: boolean;
  copyRegionProperties?: boolean;
  copyRegionPropertyValues?: boolean;
  copyProperties?: boolean;
  copyPropertyValues?: boolean;
};
