import { assertNotNullOrUndefined } from 'common/Asserts';
import { User } from '../EntityManager/entities/User/types';
import { LoggerAdapter } from './adapter/LoggerAdapter';
import { DataStorageHelper } from '../DataStorageHelper/DataStorageHelper';

/**
 * This logger is not completed yet.
 * It is only implemented so we can already have some necessary logs prepared for REC-2950.
 */
export class Logger {
  private static readonly suppressedErrorMessages: Set<string> = new Set([
    'DialogCanceled',
    'Promise reacted to the "socket:disconnected" event',
    DataStorageHelper.AFTER_LOGOUT_ERROR_MSG
  ]);

  private static adapter: LoggerAdapter | null = null;

  private constructor() {}

  public static init(adapter: LoggerAdapter): void {
    this.adapter = adapter;
    this.setupErrorListeners();
  }

  public static setUser({ user }: { user: User }): void {
    this.getRequiredAdapter().setUser({ user });
  }

  public static logError({ error: unknownError }: { error: unknown }): void {
    let error: Error;

    if (unknownError instanceof Error) {
      error = unknownError;
    } else {
      error = this.simulateError({
        error: unknownError,
        file: 'unknown',
        lineNumber: 0,
        columnNumber: 0,
        messagePrefix: 'General Error:'
      });
    }

    console.error(unknownError);
    this.logErrorWithAdapter({ error });
  }

  public static createMessageWithTags(
    message: string,
    tags: Array<LogMessageTag>
  ): string {
    return `${tags.join()} ${message}`;
  }

  private static setupErrorListeners(): void {
    window.onerror = (_errorMessage, file, lineNumber, columnNumber, error) => {
      if (error instanceof Error) {
        this.logErrorWithAdapter({ error });
      } else {
        this.logErrorWithAdapter({
          error: this.simulateError({
            error,
            file,
            lineNumber,
            columnNumber
          })
        });
      }
    };

    window.onunhandledrejection = (event) => {
      const reason = event.reason;

      if (reason instanceof Error) {
        this.logErrorWithAdapter({ error: reason });
      } else {
        this.logErrorWithAdapter({
          error: this.simulateError({
            error: reason,
            file: 'unknown',
            lineNumber: 0,
            columnNumber: 0,
            messagePrefix: 'Unhandled Promise rejection: '
          })
        });
      }
    };
  }

  private static simulateError({
    error,
    file,
    lineNumber,
    columnNumber,
    messagePrefix
  }: {
    error: unknown;
    file: string | undefined;
    lineNumber: number | undefined;
    columnNumber: number | undefined;
    messagePrefix?: string;
  }): Error {
    let message = messagePrefix ? messagePrefix : '';

    try {
      message += JSON.stringify(error);
    } catch (e) {
      console.error(e);
      message += `Unserializable error, constructor: ${
        error && error.constructor ? error.constructor.name : String(error)
      }`;
    }

    const simulatedError = new Error(message);
    simulatedError.stack = `Error: ${message}
at unknown (${file}:${lineNumber}:${columnNumber})`;

    return simulatedError;
  }

  private static logErrorWithAdapter({ error }: { error: Error }): void {
    if (!this.suppressedErrorMessages.has(error.message)) {
      this.getRequiredAdapter().logError({ error });
    }
  }

  private static getRequiredAdapter(): LoggerAdapter {
    assertNotNullOrUndefined(
      this.adapter,
      'no rollbarInstance available, is the Logger not initialized?'
    );
    return this.adapter;
  }
}

export enum LogMessageTag {
  /**
   * Because we sometimes have quite big logs (e.g. actualizations) we don't want to fully see them in the history to keep the memory consumption low.
   */
  TRUNCATE_IN_HISTORY = '[truncateInHistory]'
}
