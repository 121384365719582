import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { ProcessTaskInvoice } from '../../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { ProcessTaskInvoiceToProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTaskDevice/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { computed } from '../../../hooks/computed';
import { expression, model } from '../../../hooks/dependencies';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { CustomCheckboxCheckedChangedEvent } from '../../../inputComponents/custom-checkbox/custom-checkbox';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { EditProcessTaskDeviceDialog } from '../../edit-process-task-device-dialog/edit-process-task-device-dialog';

@autoinject()
export class ProcessTaskInvoiceRelationsWidgetDeviceListItem {
  @bindable()
  public processTaskInvoice: ProcessTaskInvoice | null = null;

  @bindable()
  public processTaskDevice: ProcessTaskDevice | null = null;

  @subscribableLifecycle()
  private processTaskInvoicePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskInvoice];

  @subscribableLifecycle()
  private processTaskInvoiceToProcessTaskDevicePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskInvoiceToProcessTaskDevice];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processTaskInvoicePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskInvoice,
        context: this as ProcessTaskInvoiceRelationsWidgetDeviceListItem,
        propertyName: 'processTaskInvoice'
      });

    this.processTaskInvoiceToProcessTaskDevicePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskInvoiceToProcessTaskDevice,
        context: this,
        expression: 'processTaskInvoiceToProcessTaskDevice'
      });
  }

  public setChecked(checked: boolean): void {
    this.ensureRelationStatus(checked);
  }

  protected handleCheckboxChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    this.ensureRelationStatus(event.detail.checked);
  }

  protected handleEditButtonClicked(): void {
    const processTaskDevice = this.processTaskDevice;
    assertNotNullOrUndefined(
      processTaskDevice,
      "can't ProcessTaskOfferRelationsWidgetDeviceListItem.handleEditButtonClicked without processTaskDevice"
    );

    void EditProcessTaskDeviceDialog.open({
      processTaskDevice,
      onDialogClosed: () => {}
    });
  }

  private ensureRelationStatus(relationShouldExist: boolean): void {
    assertNotNullOrUndefined(
      this.processTaskInvoice,
      "can't ProcessTaskInvoiceRelationsWidgetDeviceListItem.ensureRelationStatusToDevice without processTaskInvoice"
    );
    assertNotNullOrUndefined(
      this.processTaskDevice,
      "can't ProcessTaskInvoiceRelationsWidgetDeviceListItem.ensureRelationStatusToDevice without processTaskDevice"
    );

    const existingRelation =
      this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.getByProcessTaskInvoiceIdAndProcessTaskDeviceId(
        this.processTaskInvoice.id,
        this.processTaskDevice.id
      );

    if (relationShouldExist) {
      if (!existingRelation) {
        this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.create(
          {
            processTaskInvoiceId: this.processTaskInvoice.id,
            processTaskDeviceId: this.processTaskDevice.id,
            ownerProcessTaskId: this.processTaskDevice.ownerProcessTaskId,
            ownerProcessTaskGroupId:
              this.processTaskDevice.ownerProcessTaskGroupId,
            ownerUserGroupId: this.processTaskDevice.ownerUserGroupId,
            temporaryGroupName: this.processTaskDevice.temporaryGroupName
          }
        );
      }
    } else {
      if (existingRelation) {
        this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.delete(
          existingRelation
        );
      }
    }
  }

  @computed(
    expression('processTaskDevice'),
    expression('processTaskInvoice'),
    model(EntityName.ProcessTaskInvoiceToProcessTaskDevice)
  )
  protected get processTaskInvoiceToProcessTaskDevice(): ProcessTaskInvoiceToProcessTaskDevice | null {
    if (this.processTaskDevice && this.processTaskInvoice) {
      return this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.getByProcessTaskInvoiceIdAndProcessTaskDeviceId(
        this.processTaskInvoice.id,
        this.processTaskDevice.id
      );
    } else {
      return null;
    }
  }

  @computed(
    expression(
      'processTaskInvoicePermissionsHandle.canEditProcessTaskInvoiceToProcessTaskDevices'
    ),
    expression(
      'processTaskInvoiceToProcessTaskDevicePermissionsHandle.canDeleteEntity'
    )
  )
  protected get checkboxEnabled(): boolean {
    if (!this.processTaskInvoiceToProcessTaskDevice) {
      return this.processTaskInvoicePermissionsHandle
        .canEditProcessTaskInvoiceToProcessTaskDevices;
    }

    return this.processTaskInvoiceToProcessTaskDevicePermissionsHandle
      .canDeleteEntity;
  }
}
