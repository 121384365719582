import { DateType, IdType } from '../Base/types';
import { ProcessTaskSubEntity } from '../Base/ProcessTaskSubEntity';
import { OptionalProjectSubEntity } from '../Base/ProjectSubEntity';
import {
  SetPropertiesToType,
  OptionalProperties,
  Nullable
} from '../../utilities';
import { BaseEntity } from '../Base/BaseEntity';
import { DefectSubEntity } from '../Base/DefectSubEntity';

/**
 * the property entity without any ids
 * useful e.g. if you want to prepare some data and insert them later without needing to specify a target entity
 */
export type PropertyBaseData<TDate extends DateType> = BaseEntity<TDate> & {
  name: string | null;
  created: number;
  /**
   * value formats:
   *  multi-dropdown:
   *    This property stores a JSON array or null as it's value.
   *    Type of the JSON array: Array<IPropertyMultiDropdownValueItem>
   *    Example of a value: [{ value: 'Wasserschaden' }, { value: null }]
   *    If there is a JSON Object with the value null, then the property.custom_choice should be used.
   */
  value: string | null;
  type: PropertyType | null;
  choices: Array<string>;
  custom_choice: string | null;
  active: boolean | null;
  order: number | null;
  options: Array<PropertyOption>;
  alwaysVisible: boolean | null; // if this is true the property will always be displayed in the frontend
  hidden: boolean | null;
};

type PropertyIdFields<TId extends IdType> = DefectSubEntity<TId> &
  ProcessTaskSubEntity<TId> &
  OptionalProjectSubEntity<TId> & {
    thing: TId;
    thingType: TId;
    thingGroupId: TId;
    project: TId;
    entry: TId;
    defect: TId;
    regionId: TId;
    userDefinedEntityId: TId;

    /**
     * a thingSectionId for properties at the thing level
     */
    thingThingSectionId: TId;

    /**
     * a thingSectionId for properties at the project level
     */
    projectThingSectionId: TId;

    processTaskMeasurePointReadingId: TId;
    processConfigurationDeviceId: TId;
    processTaskDeviceId: TId;
    processTaskPositionId: TId;
    processTaskPositionDetailEntryId: TId;
    processTaskAppointmentId: TId;
    processTaskId: TId;

    processConfigurationStepPositionId: TId;
    ownerProcessConfigurationId: TId;
  };

type ProjectSubEntityKeys = keyof OptionalProjectSubEntity<string>;
type ProcessTaskSubEntityKeys = keyof ProcessTaskSubEntity<string>;
type DefectSubEntityKeys = keyof DefectSubEntity<string>;

type EntitySpecificPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TEntityIds extends keyof PropertyIdFields<TId>,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate>,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate>
> = Omit<
  OptionalProperties<PropertyBaseData<TDate>, TOptionalBaseDataProperties>,
  TBaseDataPropertiesToRemove
> &
  Pick<PropertyIdFields<TId>, TEntityIds | 'ownerUserGroupId'> &
  Partial<
    SetPropertiesToType<
      Omit<PropertyIdFields<TId>, TEntityIds | 'ownerUserGroupId'>,
      null
    >
  >;

export type ThingPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'thing',
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type ThingThingSectionPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'thingThingSectionId',
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type ProjectThingSectionPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'projectThingSectionId' | ProjectSubEntityKeys,
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type ThingTypePropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'thingType',
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type ThingGroupPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'thingGroupId',
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type RegionPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'regionId',
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type ProjectPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'project' | ProjectSubEntityKeys,
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type EntryPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'entry' | ProjectSubEntityKeys,
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type DefectPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'defect' | DefectSubEntityKeys,
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type ProcessTaskMeasurePointReadingPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'processTaskMeasurePointReadingId' | ProcessTaskSubEntityKeys,
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type ProcessConfigurationDevicePropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'processConfigurationDeviceId' | 'ownerProcessConfigurationId',
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type ProcessTaskDevicePropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'processTaskDeviceId' | ProcessTaskSubEntityKeys,
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type ProcessTaskPositionPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'processTaskPositionId' | ProcessTaskSubEntityKeys,
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type ProcessTaskPositionDetailEntryPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'processTaskPositionDetailEntryId' | ProcessTaskSubEntityKeys,
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type ProcessTaskAppointmentPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'processTaskAppointmentId' | ProcessTaskSubEntityKeys,
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type ProcessTaskPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'processTaskId' | ProcessTaskSubEntityKeys,
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type ProcessConfigurationStepPositionPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'processConfigurationStepPositionId' | 'ownerProcessConfigurationId',
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type UserDefinedEntityPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> = EntitySpecificPropertyDto<
  TId,
  TDate,
  'userDefinedEntityId' | Partial<ProjectSubEntityKeys>,
  TOptionalBaseDataProperties,
  TBaseDataPropertiesToRemove
>;

export type PropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof PropertyBaseData<TDate> = never,
  TBaseDataPropertiesToRemove extends keyof PropertyBaseData<TDate> = never
> =
  | ThingPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | ThingTypePropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | ThingGroupPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | RegionPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | UserDefinedEntityPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | ProjectPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | EntryPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | DefectPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | ThingThingSectionPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | ProjectThingSectionPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | ProcessTaskMeasurePointReadingPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | ProcessConfigurationDevicePropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | ProcessTaskDevicePropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | ProcessTaskPositionPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | ProcessTaskPositionDetailEntryPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | ProcessTaskAppointmentPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | ProcessTaskPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >
  | ProcessConfigurationStepPositionPropertyDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TBaseDataPropertiesToRemove
    >;

export type PropertyAllSubtypesIntersection<
  TId extends IdType,
  TDate extends DateType
> = PropertyBaseData<TDate> & Partial<Nullable<PropertyIdFields<TId>>>;

export type PropertyOption = {
  name: string;
  value: string | null;
};

export type PropertyEntityIdField = keyof PropertyIdFields<string>;

export enum PropertyType {
  CHECKBOX = 'checkbox',
  DATE = 'datum',
  TIME = 'zeit',
  MULTILINE = 'mehrzeilig',
  HEADING = 'überschrift',
  INVISIBLE_TEXT = 'text_unsichtbar',
  VISIBLE_TEXT = 'text_sichtbar',
  RADIOBUTTON = 'radiobutton',
  DROPDOWN = 'dropdown',
  MULTI_DROPDOWN = 'multi-dropdown',
  PICTURE = 'bild',
  TABLE = 'tabelle',
  TEXT = 'text',
  NUMBER = 'nummer',
  SIGNATURE = 'unterschrift',
  PRIORITY = 'priority',
  COORDINATE = 'coordinate',
  BERICHTPARAMETER = 'berichtparameter',
  PERSON = 'person',
  POSITION = 'position'
}

export type PropertyMultiDropdownValueItem = {
  value: string | null;
};

type PropertyOptionCommonDefinition = {
  name: string;
  type: PropertyOptionType;
};

type PropertyOptionDefinitionWithChoices = PropertyOptionCommonDefinition & {
  type: PropertyOptionType.SELECT;
  choices: Array<string>;
};

export type PropertyOptionDefinition =
  | PropertyOptionCommonDefinition
  | PropertyOptionDefinitionWithChoices;

export enum PropertyOptionType {
  STRING = 'string',
  NUMBER = 'number',
  SELECT = 'select'
}

export const propertyTypeInfos: Record<
  PropertyType,
  {
    valueIsEditable: boolean;
    hasPrimitiveRepresentation: boolean;
    needsChoices: boolean;
    options: Array<PropertyOptionDefinition>;
    getTemplateIdentifier: (
      name: string,
      type: PropertyType
    ) => [string, ...Array<string>];
    hasPicture: boolean;
  }
> = {
  [PropertyType.TEXT]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: true,
    needsChoices: false,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndOptionalTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.NUMBER]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: true,
    needsChoices: false,
    options: [
      ...getDefaultPropertyOptionDefinitions(),
      {
        name: 'stepSize',
        type: PropertyOptionType.NUMBER
      }
    ],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.MULTILINE]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: true,
    needsChoices: false,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.DROPDOWN]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: true,
    needsChoices: true,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.MULTI_DROPDOWN]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: true,
    needsChoices: true,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.RADIOBUTTON]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: true,
    needsChoices: true,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.CHECKBOX]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: true,
    needsChoices: false,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.DATE]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: true,
    needsChoices: false,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.TIME]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: true,
    needsChoices: false,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.PICTURE]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: false,
    needsChoices: false,
    options: [
      ...getDefaultPropertyOptionDefinitions(),
      ...getPictureSizePropertyOptionDefinitions()
    ],
    getTemplateIdentifier: getNameOnlyIdentifierForTemplate,
    hasPicture: true
  },
  [PropertyType.COORDINATE]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: true,
    needsChoices: false,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.PERSON]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: true,
    needsChoices: false,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.SIGNATURE]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: false,
    needsChoices: false,
    options: [
      ...getDefaultPropertyOptionDefinitions(),
      ...getPictureSizePropertyOptionDefinitions()
    ],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.PRIORITY]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: true,
    needsChoices: true,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.TABLE]: {
    valueIsEditable: false,
    hasPrimitiveRepresentation: false,
    needsChoices: false,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameOnlyIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.HEADING]: {
    valueIsEditable: false,
    hasPrimitiveRepresentation: true,
    needsChoices: false,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.VISIBLE_TEXT]: {
    valueIsEditable: false,
    hasPrimitiveRepresentation: true,
    needsChoices: false,
    options: [
      ...getDefaultPropertyOptionDefinitions(),
      ...getFormattingPropertyOptionDefinitions()
    ],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.INVISIBLE_TEXT]: {
    valueIsEditable: false,
    hasPrimitiveRepresentation: true,
    needsChoices: false,
    options: [
      ...getDefaultPropertyOptionDefinitions(),
      ...getFormattingPropertyOptionDefinitions()
    ],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.BERICHTPARAMETER]: {
    valueIsEditable: false,
    hasPrimitiveRepresentation: true,
    needsChoices: false,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  },
  [PropertyType.POSITION]: {
    valueIsEditable: true,
    hasPrimitiveRepresentation: true,
    needsChoices: false,
    options: [...getDefaultPropertyOptionDefinitions()],
    getTemplateIdentifier: getNameAndTypeIdentifierForTemplate,
    hasPicture: false
  }
};

function getNameAndTypeIdentifierForTemplate(
  name: string,
  type: PropertyType
): [string, ...Array<string>] {
  return [`${name}:${type}`];
}

function getNameOnlyIdentifierForTemplate(
  name: string,
  _type: PropertyType
): [string, ...Array<string>] {
  return [name];
}

function getNameAndOptionalTypeIdentifierForTemplate(
  name: string,
  type: PropertyType
): [string, ...Array<string>] {
  return [name, `${name}:${type}`];
}

function getDefaultPropertyOptionDefinitions(): Array<PropertyOptionDefinition> {
  return [
    {
      name: 'path',
      type: PropertyOptionType.STRING
    }
  ];
}

function getPictureSizePropertyOptionDefinitions(): Array<PropertyOptionDefinition> {
  return [
    {
      name: 'size',
      type: PropertyOptionType.NUMBER
    },
    {
      name: 'width',
      type: PropertyOptionType.NUMBER
    },
    {
      name: 'height',
      type: PropertyOptionType.NUMBER
    }
  ];
}

function getFormattingPropertyOptionDefinitions(): Array<PropertyOptionDefinition> {
  return [
    {
      name: 'theme',
      type: PropertyOptionType.SELECT,
      choices: ['Heading1', 'Heading2', 'Heading3', 'Heading4']
    },
    {
      name: 'textDecoration',
      type: PropertyOptionType.SELECT,
      choices: ['Underline']
    },
    {
      name: 'fontStyle',
      type: PropertyOptionType.SELECT,
      choices: ['Italic']
    }
  ];
}
