import {
  autoinject,
  bindable,
  computedFrom,
  PLATFORM
} from 'aurelia-framework';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { DateUtils } from 'common/DateUtils';
import { User } from '../../classes/EntityManager/entities/User/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskAppointmentCalendarWidgetMode } from 'common/Enums/ProcessTaskAppointmentCalendarWidgetMode';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { CalendarEntryDataSource } from './CalendarEntryDataSource/CalendarEntryDataSource';
import { NamedCustomEvent } from '../../classes/DomEventHelper';
import { CalendarEntry } from './CalendarEntryDataSource/CalendarEntry';

/**
 * @event {CalendarEntryClickedEvent} calendar-entry-clicked
 */
@autoinject()
export class ProcessTaskAppointmentCalendarWidget {
  private static modeToHeaderTemplateMap: Record<
    ProcessTaskAppointmentCalendarWidgetMode,
    string
  > = {
    singleDay: PLATFORM.moduleName('./templates/single-day-header.html'),
    usersPerDay: PLATFORM.moduleName('./templates/users-per-day-header.html'),
    week: PLATFORM.moduleName('./templates/week-header.html'),
    usersWeek: PLATFORM.moduleName('./templates/users-week-header.html'),
    year: PLATFORM.moduleName('./templates/year-header.html')
  };

  private static modeToContentTemplateMap: Record<
    ProcessTaskAppointmentCalendarWidgetMode,
    string
  > = {
    singleDay: PLATFORM.moduleName('./templates/single-day-content.html'),
    usersPerDay: PLATFORM.moduleName('./templates/users-per-day-content.html'),
    week: PLATFORM.moduleName('./templates/week-content.html'),
    usersWeek: PLATFORM.moduleName('./templates/users-week-content.html'),
    year: PLATFORM.moduleName('./templates/year-content.html')
  };

  @bindable()
  public dataSource: CalendarEntryDataSource | null = null;

  @bindable()
  public mode: ProcessTaskAppointmentCalendarWidgetMode =
    ProcessTaskAppointmentCalendarWidgetMode.SINGLE_DAY;

  @bindable()
  public useUserColors: boolean = false;

  @bindable()
  public activeDayDate: Date = new Date();

  @bindable()
  public users: Array<User> = [];

  @bindable()
  public config: ProcessTaskAppointmentCalendarWidgetConfig | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  protected startHour: number = 6;
  protected endHour: number = 23;
  protected currentMonthsInYear: Array<Date> = [];
  protected currentWeekDays: Array<Date> = [];
  protected displayDays: Array<number> = [...Array(31).keys()].map(
    (i) => i + 1
  );

  protected currentHour: number = 0;
  protected currentMinute: number = 0;
  protected publicHolidayBackgroundColor: string | null = null;

  protected noteInAppointmentNotificationColor: string | null = null;

  private isAttached: boolean = false;
  private currentTimeMarker: HTMLElement | null = null;
  private domElement: HTMLElement;
  private zIndexForMultipleDayAppointmentWidgetsInYearCalendar = 1;

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly activeUserCompanySettingsService: ActiveUserCompanySettingService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.domElement = element as HTMLElement;
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToTimeout(() => {
      if (!this.currentTimeMarker) return;
      void ScrollHelper.scrollToItemCentered(this.currentTimeMarker);
    }, 0);
    this.updateCurrentTimeMarker();

    this.subscriptionManager.subscribeToInterval(
      this.updateCurrentTimeMarker.bind(this),
      60000
    );

    this.updateCurrentWeekDays(this.activeDayDate);
    this.updateCurrentMonthsInYear(this.activeDayDate);

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingsService.bindSettingProperty(
        'operations.calendarStartHour',
        (value) => {
          if (value != null) this.startHour = value;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingsService.bindSettingProperty(
        'operations.calendarEndHour',
        (value) => {
          if (value != null) this.endHour = value;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingsService.bindSettingProperty(
        'operations.publicHolidayBackgroundColor',
        (value) => {
          this.publicHolidayBackgroundColor = value;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingsService.bindSettingProperty(
        'operations.noteInAppointmentNotificationColor',
        (value) => {
          this.noteInAppointmentNotificationColor = value;
        }
      )
    );

    this.scrollToFirstAppointment();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected activeDayDateChanged(): void {
    if (this.isAttached) {
      this.updateCurrentWeekDays(this.activeDayDate);
      this.updateCurrentMonthsInYear(this.activeDayDate);

      this.scrollToFirstAppointment();
    }
  }

  protected modeChanged(): void {
    if (this.isAttached) {
      this.updateCurrentWeekDays(this.activeDayDate);
      this.updateCurrentMonthsInYear(this.activeDayDate);

      this.scrollToFirstAppointment();
    }
  }

  private updateCurrentWeekDays(day: Date): void {
    let currentDay = DateUtils.getStartDateOfWeek(day);
    const currentWeekDays = [currentDay];
    for (let i = 1; i < 5; i++) {
      currentDay = DateUtils.getNextDay(currentDay);
      currentWeekDays.push(currentDay);
    }

    this.currentWeekDays = currentWeekDays;
  }

  private updateCurrentMonthsInYear(day: Date): void {
    let currentMonth = DateUtils.getStartDateOfYear(day);
    const currentMonthsInYear = [currentMonth];
    for (let i = 1; i < 12; i++) {
      currentMonth = DateUtils.getDateOneMonthAfter(currentMonth);
      currentMonthsInYear.push(currentMonth);
    }

    this.currentMonthsInYear = currentMonthsInYear;
  }

  private updateCurrentTimeMarker(): void {
    const date = new Date();
    this.currentHour = date.getHours();
    this.currentMinute = date.getMinutes();
  }

  private scrollToFirstAppointment(): void {
    if (
      !this.config?.shouldAutoScrollToFirstAppointmentInSingleDayView ||
      this.mode !== ProcessTaskAppointmentCalendarWidgetMode.SINGLE_DAY
    )
      return;

    setTimeout(() => {
      const firstAppointment = this.domElement.querySelector(
        '.process-task-appointment-calendar-widget-day--Appointment'
      ) as HTMLElement | null;

      if (firstAppointment) {
        if (firstAppointment.offsetTop < this.domElement.offsetTop) {
          throw new Error(
            "Cannot scroll to appointment because parent's offsetTop is larger than child element's offsetTop"
          );
        }
        void ScrollHelper.scrollElement(
          this.domElement,
          'top',
          firstAppointment.offsetTop - this.domElement.offsetTop
        );
      }
    }, 10);
  }

  protected isActiveDay(dayDate: Date): boolean {
    return DateUtils.isOnSameDay(dayDate, this.activeDayDate);
  }

  protected getHeaderViewForMode(
    mode: ProcessTaskAppointmentCalendarWidgetMode
  ): string {
    return ProcessTaskAppointmentCalendarWidget.modeToHeaderTemplateMap[mode];
  }

  protected getContentViewForMode(
    mode: ProcessTaskAppointmentCalendarWidgetMode
  ): string {
    return ProcessTaskAppointmentCalendarWidget.modeToContentTemplateMap[mode];
  }

  protected getTimeGridPaddingTopForMode(
    mode: ProcessTaskAppointmentCalendarWidgetMode
  ): string {
    switch (mode) {
      case ProcessTaskAppointmentCalendarWidgetMode.YEAR:
        return '0px';
      default:
        return '';
    }
  }

  protected getColumnCount(
    mode: ProcessTaskAppointmentCalendarWidgetMode,
    usersLength: number,
    currentWeekDaysLength: number
  ): number {
    switch (mode) {
      case ProcessTaskAppointmentCalendarWidgetMode.SINGLE_DAY:
        return 1;
      case ProcessTaskAppointmentCalendarWidgetMode.USERS_PER_DAY:
        return usersLength;
      case ProcessTaskAppointmentCalendarWidgetMode.WEEK:
        return currentWeekDaysLength;
      case ProcessTaskAppointmentCalendarWidgetMode.USERS_WEEK:
        return currentWeekDaysLength;
      case ProcessTaskAppointmentCalendarWidgetMode.YEAR:
        return 12;
      default:
        throw new Error(`mode "${mode}" not supported`);
    }
  }

  protected getIncreasedZIndex(): number {
    return ++this.zIndexForMultipleDayAppointmentWidgetsInYearCalendar;
  }

  @computedFrom('users')
  protected get userIds(): Array<string> {
    return this.users.map((user) => user.id);
  }

  @computedFrom('mode')
  protected get showTimeLabelColumn(): boolean {
    return ![
      ProcessTaskAppointmentCalendarWidgetMode.YEAR,
      ProcessTaskAppointmentCalendarWidgetMode.USERS_WEEK
    ].includes(this.mode);
  }

  @computedFrom('mode')
  protected get showDayLabelColumn(): boolean {
    return this.mode === ProcessTaskAppointmentCalendarWidgetMode.YEAR;
  }

  @computedFrom('mode', 'currentHour', 'startHour', 'endHour')
  protected get showCurrentTimeMarker(): boolean {
    const isNotYearMode =
      this.mode !== ProcessTaskAppointmentCalendarWidgetMode.YEAR;
    const isNotUsersWeekMode =
      this.mode !== ProcessTaskAppointmentCalendarWidgetMode.USERS_WEEK;
    const isBetweenStartAndEndHour =
      this.currentHour >= this.startHour && this.currentHour < this.endHour;
    return isNotYearMode && isNotUsersWeekMode && isBetweenStartAndEndHour;
  }

  @computedFrom('startHour', 'endHour')
  protected get displayHours(): Array<number> {
    return Array.from(
      Array(this.endHour - this.startHour),
      (e, i) => i + this.startHour
    );
  }
}

export type CalendarEntryClickedEventData = {
  entry: CalendarEntry;
};

export type CalendarEntryClickedEvent = NamedCustomEvent<
  'calendar-entry-clicked',
  CalendarEntryClickedEventData
>;

export type ProcessTaskAppointmentCalendarWidgetConfig = {
  shouldAutoScrollToFirstAppointmentInSingleDayView: boolean;
};
