import { computedFrom } from 'aurelia-framework';
import { ProcessTaskInvoice } from '../../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { ProcessTaskInvoiceAdapter } from '../EntityAdapter/ProcessTaskInvoiceAdapter/ProcessTaskInvoiceAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ProcessTaskInvoicePermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ProcessTaskInvoiceAdapter>,
  ProcessTaskInvoice
> {
  @computedFrom('combinedRevision')
  public get canEditProcessTaskInvoiceToProcessTasks(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canEditProcessTaskInvoiceToProcessTasks'
    );
  }

  @computedFrom('combinedRevision')
  public get canEditProcessTaskInvoiceToProcessTaskDevices(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canEditProcessTaskInvoiceToProcessTaskDevices'
    );
  }

  @computedFrom('combinedRevision')
  public get canEditProcessTaskInvoiceToProcessTaskPositions(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canEditProcessTaskInvoiceToProcessTaskPositions'
    );
  }
}
