import { MomentInput } from 'moment';

import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { UserDefinedEntity } from '../../classes/EntityManager/entities/UserDefinedEntity/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { UserDefinedEntityConfig } from '../../classes/EntityManager/entities/UserDefinedEntityConfig/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { UserDefinedEntityPropertyAdapter } from '../../aureliaComponents/property-input-field-list-with-default-properties/PropertyAdapter/UserDefinedEntityPropertyAdapter';
import { configureHooks } from '../../hooks/configureHooks';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditUserDefinedEntityDialog {
  protected userDefinedEntity: UserDefinedEntity | null = null;

  private onDialogClosed: OnDialogClosedCallback | null = null;

  private dialog: RecordItDialog | null = null;

  protected propertyAdapter: UserDefinedEntityPropertyAdapter | null = null;

  @subscribableLifecycle()
  protected readonly userDefinedEntityPermissionHandle: EntityNameToPermissionsHandle[EntityName.UserDefinedEntity];

  private subscriptionManager: SubscriptionManager;

  public static async open(
    options: EditUserDefinedEntityDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.userDefinedEntityPermissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.UserDefinedEntity,
        context: this,
        expression: 'userDefinedEntity'
      });
  }

  public open(options: EditUserDefinedEntityDialogOptions): void {
    this.userDefinedEntity = options.userDefinedEntity;
    this.onDialogClosed = options.onDialogClosed ?? null;

    this.propertyAdapter = new UserDefinedEntityPropertyAdapter({
      entityManager: this.entityManager,
      permissionsService: this.permissionsService,
      subscriptionManagerService: this.subscriptionManagerService,
      userDefinedEntity: options.userDefinedEntity
    });

    this.dialog?.open();
  }

  protected handleDialogClosed(): void {
    this.subscriptionManager.disposeSubscriptions();
    if (this.userDefinedEntity) this.onDialogClosed?.(this.userDefinedEntity);
    this.propertyAdapter = null;
  }

  protected handleUserDefinedEntityChanged(): void {
    assertNotNullOrUndefined(
      this.userDefinedEntity,
      "can't EditUserDefinedEntityDialog.handleUserDefinedEntityChanged without a userDefinedEntity"
    );
    this.entityManager.userDefinedEntityRepository.update(
      this.userDefinedEntity
    );
  }

  protected handleSelectedUserDefinedEntityConfigChanged(): void {
    assertNotNullOrUndefined(
      this.userDefinedEntity,
      "can't EditUserDefinedEntityDialog.handleSelectedUserDefinedEntityConfigChanged without a userDefinedEntity"
    );
    const configId = this.userDefinedEntity.userDefinedEntityConfigId;

    const existingProperties =
      this.entityManager.propertyRepository.getByUserDefinedEntityId(
        this.userDefinedEntity.id
      );
    const currentlySelectedConfig = configId
      ? this.entityManager.userDefinedEntityConfigRepository.getById(configId)
      : null;

    const propertiesOfSelectedConfig = currentlySelectedConfig
      ? this.entityManager.userDefinedEntityConfigPropertyConfigRepository.getByUserDefinedEntityConfigId(
          currentlySelectedConfig.id
        )
      : [];

    for (const p of existingProperties) {
      const isInCurrentConfig = propertiesOfSelectedConfig.some((config) =>
        PropertyHelper.isTheSameProperty(config, p)
      );
      if (!isInCurrentConfig) {
        this.entityManager.propertyRepository.delete(p);
      }
    }

    this.handleUserDefinedEntityChanged();
  }

  protected formatToDateString(date: MomentInput): string {
    return DateUtils.formatToDateString(date);
  }

  protected getUsergroupNameById(usergroupId: string): string {
    const group = this.entityManager.userGroupRepository.getById(usergroupId);
    return group?.name ?? '';
  }

  @computed(
    model(EntityName.UserDefinedEntityConfig),
    expression('userDefinedEntity')
  )
  protected get userDefinedEntityConfigs(): Array<UserDefinedEntityConfig> {
    if (this.userDefinedEntity) {
      return this.entityManager.userDefinedEntityConfigRepository.getByUserGroupId(
        this.userDefinedEntity.ownerUserGroupId
      );
    }
    return this.entityManager.userDefinedEntityConfigRepository.getAll();
  }
}

type EditUserDefinedEntityDialogOptions = {
  userDefinedEntity: UserDefinedEntity;
  onDialogClosed?: OnDialogClosedCallback;
};

type OnDialogClosedCallback = (userDefinedEntity: UserDefinedEntity) => void;
