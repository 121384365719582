import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { TDefaultPropertyConfig } from 'common/Types/DefaultPropertyConfig';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { assertNotNullOrUndefined } from '../../../../../common/src/Asserts';
import { DomEventHelper } from '../../../classes/DomEventHelper';
import { ProcessTaskPositionDetailEntry } from '../../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types';
import { ProcessTaskPositionDetailEntryProperty } from '../../../classes/EntityManager/entities/Property/types';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';

/**
 * @event {TDetailEntryChangedEvent} detail-entry-changed
 */
@autoinject()
export class ProcessTaskPositionDetailEntriesWidgetTableRow {
  @bindable()
  public detailEntry: ProcessTaskPositionDetailEntry | null = null;

  /**
   * the default property for the given row, should have a value already
   */
  @bindable()
  public defaultProperty: TDefaultPropertyConfig | null = null;

  /**
   * all properties of the given detailEntry
   */
  @bindable()
  public detailEntryProperties: Array<ProcessTaskPositionDetailEntryProperty> =
    [];

  @bindable()
  public unit: string | null = null;

  @bindable()
  public canCreateDetailEntry: boolean = false;

  /**
   * is required if no detailEntry is given, so it can be created on the fly
   */
  @bindable()
  public createDetailEntry: (() => ProcessTaskPositionDetailEntry) | null =
    null;

  @bindable()
  public deleteDetailEntry:
    | ((detailEntry: ProcessTaskPositionDetailEntry) => void)
    | null = null;

  private domElement: HTMLElement;

  @subscribableLifecycle()
  protected readonly detailEntryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskPositionDetailEntry];

  protected readonly PropertyHelper = PropertyHelper;

  constructor(element: Element, permissionsService: PermissionsService) {
    this.domElement = element as HTMLElement;

    this.detailEntryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskPositionDetailEntry,
        context: this as ProcessTaskPositionDetailEntriesWidgetTableRow,
        propertyName: 'detailEntry'
      });
  }

  protected handleAmountChanged(): void {
    if (this.detailEntry?.amount !== 0) {
      this.saveDetailEntry();
    } else {
      this.handleNoAmount();
    }
  }

  private saveDetailEntry(): void {
    // weird logic because if no detailEntry is given, aurelia automatically creates an object with just the modified fields set
    let changesToApply: Partial<ProcessTaskPositionDetailEntry> | null = null;
    if (this.detailEntry && !this.detailEntry.id) {
      changesToApply = this.detailEntry;
      this.detailEntry = null;
    }

    this.autoCreateDetailEntryIfNoneIsGiven();

    if (changesToApply) {
      Object.assign(this.detailEntry, changesToApply);
    }

    DomEventHelper.fireEvent(this.domElement, {
      name: 'detail-entry-changed',
      detail: {
        detailEntry: this.detailEntry
      }
    });
  }

  private handleNoAmount(): void {
    const detailEntry:
      | Partial<ProcessTaskPositionDetailEntry>
      | ProcessTaskPositionDetailEntry
      | null = this.detailEntry;

    if (!detailEntry?.id) {
      // this detail entry is just an object which has been created by aurelia, we can't really delete it
      this.detailEntry = null;
      return;
    }

    if (this.detailEntryPermissionsHandle.canDeleteEntity) {
      this.deleteDetailEntry?.(detailEntry as ProcessTaskPositionDetailEntry);
      this.detailEntry = null;
    } else {
      DomEventHelper.fireEvent(this.domElement, {
        name: 'detail-entry-changed',
        detail: {
          detailEntry: this.detailEntry
        }
      });
    }
  }

  private autoCreateDetailEntryIfNoneIsGiven(): asserts this is {
    detailEntry: ProcessTaskPositionDetailEntry;
  } {
    if (!this.detailEntry) {
      assertNotNullOrUndefined(
        this.createDetailEntry,
        'ProcessTaskPositionDetailEntriesWidgetTableRow.handleDetailEntryChanged: createDetailEntry is required when there is no detailEntry'
      );
      this.detailEntry = this.createDetailEntry();
    }
  }

  @computedFrom(
    'detailEntryPermissionsHandle.canEditField.amount',
    'canCreateDetailEntry'
  )
  protected get amountInputEnabled(): boolean {
    if (this.detailEntry) {
      return this.detailEntryPermissionsHandle.canEditField.amount;
    }

    return this.canCreateDetailEntry;
  }
}

export type TDetailEntryChangedEvent = CustomEvent<{
  detailEntry: ProcessTaskPositionDetailEntry;
}>;
