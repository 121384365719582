import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntryToPerson } from '../../../classes/EntityManager/entities/EntryToPerson/types';
import { PersonUtils } from '../../../classes/EntityManager/entities/Person/PersonUtils';
import { Person } from '../../../classes/EntityManager/entities/Person/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class GalleryThingEntryToPersonWidgetPerson {
  @bindable()
  public person: Person | null = null;

  @bindable()
  public entryToPerson: EntryToPerson | null = null;

  @subscribableLifecycle()
  protected readonly entryToPersonPermissionsHandle: EntityNameToPermissionsHandle[EntityName.EntryToPerson];

  protected PersonUtils = PersonUtils;

  constructor(
    protected readonly router: Router,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.entryToPersonPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.EntryToPerson,
        context: this as GalleryThingEntryToPersonWidgetPerson,
        propertyName: 'entryToPerson'
      });
  }

  protected handleDeleteClicked(): void {
    const entryToPerson = this.entryToPerson;
    assertNotNullOrUndefined(
      entryToPerson,
      "can't GalleryThingEntryToPersonWidgetPerson.handleDeleteClicked without entryToPerson"
    );
    void Dialogs.deleteDialogTk(
      'galleryThing.entryToPersonWidget.removePersonDialogText'
    ).then(() => {
      this.entityManager.entryToPersonRepository.delete(entryToPerson);
    });
  }
}
