import { autoinject } from 'aurelia-dependency-injection';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { EntityName } from 'common/Types/BaseEntities/EntityName';
import { IPictureMarking } from 'common/Types/Entities/Picture/PictureDto';
import { PictureRevisionTransformationCalculator } from 'common/PictureRevision/PictureRevisionTransformationCalculator';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { RecordItFullScreenDialog } from '../../dialogs/record-it-full-screen-dialog/record-it-full-screen-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { PictureRevision } from '../../classes/EntityManager/entities/PictureRevision/types';
import {
  MarkingCorrectionReferencePointData,
  PictureRevisionComparisonPicture
} from '../picture-revision-comparison-picture/picture-revision-comparison-picture';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { configureHooks } from '../../hooks/configureHooks';
import {
  AdditionalMarkingWithSourcePicture,
  PictureMarkingService
} from '../../classes/EntityManager/entities/Picture/PictureMarkingService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../classes/EntityManager/entities/Project/types';

@configureHooks({})
@autoinject()
export class PictureRevisionComparisonFullScreenDialog {
  public static async open(options: Options): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  protected dialog: RecordItFullScreenDialog | null = null;

  protected options: Options | null = null;

  protected isRendered = false;

  protected selectionMode: SelectionMode = SelectionMode.NONE;
  protected readonly SelectionMode = SelectionMode;

  private comparisonPictureLeft: PictureRevisionComparisonPicture | null = null;

  private comparisonPictureRight: PictureRevisionComparisonPicture | null =
    null;

  private referencePointDataLeftPicture: MarkingCorrectionReferencePointData | null =
    null;

  private referencePointDataRightPicture: MarkingCorrectionReferencePointData | null =
    null;

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly pictureMarkingService: PictureMarkingService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public open(options: Options): void {
    this.options = options;
    this.getDialog().open();
    this.isRendered = true;

    this.subscriptionManager.subscribeToExpression(
      this,
      'referencePointDataLeftPicture.allSet',
      this.toggleReferencePointSelectionAutomatically.bind(this)
    );

    this.subscriptionManager.subscribeToExpression(
      this,
      'referencePointDataRightPicture.allSet',
      this.toggleReferencePointSelectionAutomatically.bind(this)
    );
  }

  @computed(
    expression('referencePointDataLeftPicture.allSet'),
    expression('referencePointDataRightPicture.allSet')
  )
  protected get allReferencePointsSelected(): boolean {
    return (
      (this.referencePointDataLeftPicture?.allSet &&
        this.referencePointDataRightPicture?.allSet) ??
      false
    );
  }

  @computed(
    expression('referencePointDataLeftPicture.anySet'),
    expression('referencePointDataRightPicture.anySet')
  )
  protected get anyReferencePointsSelected(): boolean {
    return (
      (this.referencePointDataLeftPicture?.anySet ||
        this.referencePointDataRightPicture?.anySet) ??
      false
    );
  }

  @computed(
    expression('options.picture'),
    model(EntityName.Project),
    model(EntityName.Picture)
  )
  protected get showNotAllMarkingsVisibleWarning(): boolean {
    return (
      !!this.options?.picture.globalThingId &&
      !this.options?.picture.is_global_project_picture &&
      !this.options?.picture.galleryThingId
    );
  }

  @computed(
    expression('options.picture'),
    model(EntityName.Project),
    model(EntityName.Picture)
  )
  protected get showPictureTypeNotHandledWarning(): boolean {
    return (
      !this.options?.picture.globalThingId &&
      !this.options?.picture.is_global_project_picture &&
      !this.options?.picture.galleryThingId
    );
  }

  @computed(
    expression('options.picture'),
    model(EntityName.Project),
    model(EntityName.Picture)
  )
  protected get markings(): Array<IPictureMarking> {
    if (!this.options?.picture) return [];
    let projects: Array<Project> = [];

    if (this.options?.picture?.globalThingId) {
      projects = this.entityManager.projectRepository.getByThingId(
        this.options.picture.globalThingId
      );
    } else if (
      this.options.picture.is_global_project_picture &&
      this.options.picture.ownerProjectId
    ) {
      const project = this.entityManager.projectRepository.getById(
        this.options.picture.ownerProjectId
      );

      if (project) projects.push(project);
    }

    const additionalMarkingsWithSourcePicture: Array<AdditionalMarkingWithSourcePicture> =
      [];

    for (const project of projects) {
      const additionalMarkingsForPicture =
        this.pictureMarkingService
          .getAdditionalMarkingsByPictureIdForProjectId(project.id)
          .get(this.options.picture.id) || [];

      for (const marking of additionalMarkingsForPicture)
        additionalMarkingsWithSourcePicture.push(marking);
    }

    return additionalMarkingsWithSourcePicture.map((marking) => {
      return {
        top: marking.top,
        left: marking.left,
        boxTop: marking.boxTop,
        boxBottom: marking.boxBottom,
        boxLeft: marking.boxLeft,
        boxRight: marking.boxRight
      };
    });
  }

  protected handleDialogClosed(): void {
    this.isRendered = false;
    this.selectionMode = SelectionMode.NONE;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected toggleReferencePointSelectionOnButtonClick(): void {
    if (this.selectionMode === SelectionMode.NONE)
      this.selectionMode = this.referencePointDataLeftPicture?.allSet
        ? SelectionMode.RIGHT
        : SelectionMode.LEFT;
    else this.selectionMode = SelectionMode.NONE;
  }

  protected toggleReferencePointSelectionAutomatically(): void {
    if (this.selectionMode === SelectionMode.NONE) return;

    if (
      !this.referencePointDataLeftPicture?.allSet &&
      !this.referencePointDataRightPicture?.allSet
    ) {
      this.selectionMode = SelectionMode.LEFT;
    } else if (
      this.referencePointDataLeftPicture?.allSet &&
      !this.referencePointDataRightPicture?.allSet
    ) {
      this.selectionMode = SelectionMode.RIGHT;
    } else if (
      this.referencePointDataLeftPicture?.allSet &&
      this.referencePointDataRightPicture?.allSet
    ) {
      this.selectionMode = SelectionMode.NONE;
    }
  }

  protected clearReferencePointSelection(): void {
    this.comparisonPictureLeft?.resetReferencePointData();
    this.comparisonPictureRight?.resetReferencePointData();
  }

  protected moveMarkings(): void {
    if (
      !this.referencePointDataLeftPicture?.point1 ||
      !this.referencePointDataRightPicture?.point1 ||
      !this.referencePointDataLeftPicture?.point2 ||
      !this.referencePointDataRightPicture?.point2 ||
      !this.referencePointDataLeftPicture?.point3 ||
      !this.referencePointDataRightPicture?.point3
    ) {
      console.warn(
        'Reference points for transformation matrix computation missing. This should not happen.'
      );
      this.clearReferencePointSelection();
      return;
    }

    const transformationMatrix =
      PictureRevisionTransformationCalculator.computeMarkingsTransfomation({
        point1: {
          oldPosition: this.referencePointDataLeftPicture.point1,
          newPosition: this.referencePointDataRightPicture.point1
        },
        point2: {
          oldPosition: this.referencePointDataLeftPicture.point2,
          newPosition: this.referencePointDataRightPicture.point2
        },
        point3: {
          oldPosition: this.referencePointDataLeftPicture.point3,
          newPosition: this.referencePointDataRightPicture.point3
        },
        leftPictureTransformation:
          this.comparisonPictureLeft?.getTransformationFromPictureRevision() ??
          null
      });

    assertNotNullOrUndefined(
      transformationMatrix,
      'cannot moveMarkings without transformation matrix'
    );

    this.comparisonPictureRight?.saveTransformationToPictureRevision(
      transformationMatrix
    );

    this.clearReferencePointSelection();
  }

  private getDialog(): RecordItFullScreenDialog {
    assertNotNullOrUndefined(this.dialog, 'no dialog available');
    return this.dialog;
  }
}

export type Options = {
  picture: Picture;
  revisionLeft: PictureRevision;
  revisionRight: PictureRevision;
};

export enum SelectionMode {
  NONE = 'none',
  LEFT = 'left',
  RIGHT = 'right'
}
