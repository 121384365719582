import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { ShowProcessAppointmentConfiguration } from './ShowProcessAppointmentConfiguration';
import { ProcessConfigurationFromProcessTaskGroupIdComputer } from '../../computedValues/computers/ProcessConfigurationFromProcessTaskGroupIdComputer';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { TemporaryAppointmentService } from '../../services/TemporaryAppointmentService';

@autoinject()
export class ShowProcessAppointment {
  private readonly subscriptionManager: SubscriptionManager;
  private readonly configuration: ShowProcessAppointmentConfiguration;

  private appointmentId: string | null = null;
  private processTask: ProcessTask | null = null;
  private processTaskGroup: ProcessTaskGroup | null = null;
  private appointment: ProcessTaskAppointment | null = null;
  private processConfiguration: ProcessConfiguration | null = null;
  private processConfigurationStep: ProcessConfigurationStep | null = null;

  protected needsNoteConfirmation: boolean = true;
  protected noteConfirmed: boolean = false;

  private logOpenedBy: string | null = null;
  private openedFormId: string | null = null;

  constructor(
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    private readonly activeEntitiesService: ActiveEntitiesService,
    subscriptionManagerService: SubscriptionManagerService,
    currentUserService: CurrentUserService,
    activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly temporaryAppointmentService: TemporaryAppointmentService
  ) {
    this.router = router;

    this.subscriptionManager = subscriptionManagerService.create();

    this.configuration = new ShowProcessAppointmentConfiguration(
      activeUserCompanySettingService,
      subscriptionManagerService,
      computedValueService,
      currentUserService
    );
  }

  protected activate(params: {
    appointment_id: string;
    log_opened_by?: string;
    open_form?: string;
  }): void {
    this.appointmentId = params.appointment_id;
    this.noteConfirmed = false;

    this.logOpenedBy = this.logOpenedBy ?? null;
    this.openedFormId = params.open_form ?? null;

    this.redirectToNewIdIfIdIsLocal();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      this.updateAppointment.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      this.updateProcessTaskGroup.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      this.updateProcessTask.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationStep,
      this.updateProcessConfigurationStep.bind(this)
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () =>
          this.appointment
            ? { processTaskGroupId: this.appointment.ownerProcessTaskGroupId }
            : null,
        createUpdaters: (update) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'appointment.ownerProcessTaskGroupId',
            update
          );
        },
        callback: (configuration) => {
          this.processConfiguration = configuration;
        }
      })
    );

    this.subscriptionManager.addDisposable(this.configuration.subscribe());

    this.updateAppointment();
    this.updateProcessTask();

    this.temporaryAppointmentService.startPersistingOnChanges(this.logOpenedBy);
  }

  private redirectToNewIdIfIdIsLocal(): void {
    let newAppointmentId: string | null = null;
    let newOpenedFormId: string | null = null;
    if (this.appointmentId) {
      const newEntity =
        this.entityManager.processTaskAppointmentRepository.getByOriginalId(
          this.appointmentId
        );
      if (newEntity) {
        newAppointmentId = newEntity.id;
      }
    }
    if (this.openedFormId) {
      const newEntity =
        this.entityManager.processTaskToProjectRepository.getByOriginalId(
          this.openedFormId
        );
      if (newEntity) {
        newOpenedFormId = newEntity.id;
      }
    }
    if (newAppointmentId || newOpenedFormId) {
      this.router.navigateToRoute('show_process_appointment', {
        appointment_id: newAppointmentId ?? this.appointmentId,
        log_opened_by: this.logOpenedBy ?? undefined,
        open_form: newOpenedFormId ?? this.openedFormId ?? undefined
      });
    }
  }

  protected deactivate(): void {
    this.temporaryAppointmentService.stopPersistingOnChanges();
    this.temporaryAppointmentService.clearTemporaryAppointments();
    this.activeEntitiesService.setActiveProcessTaskAppointment(null);
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleConfirmNotePageConfirmClicked(): void {
    this.noteConfirmed = true;
  }

  private updateAppointment(): void {
    const lastAppointment = this.appointment;

    this.appointment = this.appointmentId
      ? this.entityManager.processTaskAppointmentRepository.getById(
          this.appointmentId
        )
      : null;
    this.redirectToNewIdIfIdIsLocal();

    if (this.appointment !== lastAppointment) {
      this.needsNoteConfirmation =
        !!this.appointment?.note && !this.appointment.finishedAt;
    }

    this.activeEntitiesService.setActiveProcessTaskAppointment(
      this.appointment || null
    );
    this.configuration.setAppointment(this.appointment);
  }

  private updateProcessTaskGroup(): void {
    if (this.appointment) {
      this.processTaskGroup =
        this.entityManager.processTaskGroupRepository.getById(
          this.appointment.ownerProcessTaskGroupId
        );
    } else {
      this.processTaskGroup = null;
    }
  }

  private updateProcessTask(): void {
    if (this.appointment) {
      this.processTask = this.entityManager.processTaskRepository.getById(
        this.appointment.ownerProcessTaskId
      );
    } else {
      this.processTask = null;
    }
  }

  private updateProcessConfigurationStep(): void {
    if (this.appointment && this.appointment.processConfigurationStepId) {
      this.processConfigurationStep =
        this.entityManager.processConfigurationStepRepository.getById(
          this.appointment.processConfigurationStepId
        ) || null;
    } else {
      this.processConfigurationStep = null;
    }
  }
}
