import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { EntityName } from 'common/Types/BaseEntities/EntityName';
import { IPictureMarking } from 'common/Types/Entities/Picture/PictureDto';
import { PictureRevisionTransformationCalculator } from 'common/PictureRevision/PictureRevisionTransformationCalculator';
import { GalleryThingPictureOverviewEntry } from '../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { computed } from '../../hooks/computed';
import { arrayChanges, expression, model } from '../../hooks/dependencies';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

@autoinject()
export class GalleryThingPlanBasedOverviewMarker {
  @bindable
  public pictureOverviewEntry: GalleryThingPictureOverviewEntry | null = null;

  @bindable
  public planPicture: Picture | null = null;

  /**
   * any css color
   */
  @bindable
  public color: string = '#ff401f';

  constructor(private readonly entityManager: AppEntityManager) {}

  @computedFrom('color')
  protected get markerOutlineStyle(): string {
    return `fill:${this.color};fill-opacity:1;stroke:#ffffff;stroke-opacity:1;stroke-width:2;stroke-miterlimit:4;stroke-dasharray:none`;
  }

  @computed(
    expression('pictureOverviewEntry.coordsFromPositionedPictureInfo'),
    arrayChanges('pictureOverviewEntry.additional_markings'),
    model(EntityName.PictureRevision)
  )
  protected get position(): Position | null {
    if (!this.planPicture) return null;
    const additionalMarking =
      this.pictureOverviewEntry?.additional_markings.find(
        (e) => e.picture_id === this.planPicture?.id
      );

    if (additionalMarking) {
      return this.transformMarking(additionalMarking, this.planPicture);
    }
    const coordsFromPositionedPictureInfo =
      this.pictureOverviewEntry?.coordsFromPositionedPictureInfo;
    if (
      coordsFromPositionedPictureInfo?.pictureId === this.planPicture?.id &&
      coordsFromPositionedPictureInfo.top &&
      coordsFromPositionedPictureInfo.left
    ) {
      return this.transformMarking(
        coordsFromPositionedPictureInfo,
        this.planPicture
      );
    }

    return null;
  }

  private transformMarking(
    marking: IPictureMarking,
    planPicture: Picture
  ): Position {
    const revision =
      this.entityManager.pictureRevisionRepository.getActiveRevisionByPictureId(
        planPicture.id
      );
    if (!revision?.markingsTransformation)
      return { top: marking.top, left: marking.left };

    const transformedMarking =
      PictureRevisionTransformationCalculator.computeMarkingPositionsBasedOnTransformationMatrix(
        marking,
        revision.markingsTransformation
      );

    return { top: transformedMarking.top, left: transformedMarking.left };
  }
}

type Position = {
  top: string;
  left: string;
};
