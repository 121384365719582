import { Disposable } from 'aurelia-binding';

import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';

import { Entry } from '../../../classes/EntityManager/entities/Entry/types';
import {
  TextBrickWidgetAdapter,
  TextBrickWidgetAdapterSubscribeOptions,
  TextBrickWidgetCommonAdapterOptions
} from './TextBrickWidgetAdapter';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { TextBrickTemplate } from '../../../classes/EntityManager/entities/TextBrickTemplate/types';
import { TextBrick } from '../../../classes/EntityManager/entities/TextBrick/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { EditTextBrickTemplatesDialog } from '../../../dialogs/edit-text-brick-templates-dialog/edit-text-brick-templates-dialog';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

export class EntryTextBrickWidgetAdapter implements TextBrickWidgetAdapter {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly permissionsService: PermissionsService;

  private entry: Entry | null = null;
  private path: Array<string> = [];
  private temporaryGroupName: string | null = null;
  private entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  private selectedTextBricks: Array<TextBrick> = [];
  private remainingTextBrickTemplates: Array<TextBrickTemplate> = [];

  private subscribeOptions: TextBrickWidgetAdapterSubscribeOptions | null =
    null;

  constructor(options: EntryTextBrickWidgetAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.permissionsService = options.permissionsService;

    this.entry = options.entry;
    this.path = options.path;
    this.temporaryGroupName = options.temporaryGroupName;
    this.entryPermissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Entry,
        entity: this.entry
      });
  }

  public subscribe(
    options: TextBrickWidgetAdapterSubscribeOptions
  ): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();
    this.subscribeOptions = options;

    subscriptionManager.addDisposable(this.entryPermissionsHandle.subscribe());
    subscriptionManager.subscribeToExpression(
      this.entryPermissionsHandle,
      'canCreateTextBricks',
      () => {
        options.setCanCreateTextBricks(
          this.entryPermissionsHandle.canCreateTextBricks
        );
      }
    );
    options.setCanCreateTextBricks(
      this.entryPermissionsHandle.canCreateTextBricks
    );

    subscriptionManager.subscribeToModelChanges(
      EntityName.TextBrick,
      this.updateTextBricks.bind(this)
    );
    this.updateTextBricks();

    subscriptionManager.subscribeToModelChanges(
      EntityName.TextBrickTemplate,
      this.updateRemainingTextBrickTemplates.bind(this)
    );
    this.updateRemainingTextBrickTemplates();

    return {
      dispose: () => {
        this.subscribeOptions = null;
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  private updateTextBricks(): void {
    if (this.entry) {
      this.selectedTextBricks =
        this.entityManager.textBrickRepository.getByEntryId(this.entry.id);
    } else {
      this.selectedTextBricks = [];
    }
    this.updateRemainingTextBrickTemplates();
    assertNotNullOrUndefined(
      this.subscribeOptions,
      'cannot updateTextBricks without subscribeOptions'
    );
    this.subscribeOptions.setSelectedTextBricks(this.selectedTextBricks);
  }

  private updateRemainingTextBrickTemplates(): void {
    if (this.entry) {
      const availableTextBricks =
        this.entityManager.textBrickTemplateRepository.getByUserGroupIdAndPathWithWildcards(
          this.entry.ownerUserGroupId,
          this.path
        );
      this.remainingTextBrickTemplates = availableTextBricks.filter(
        (aTB) =>
          !this.selectedTextBricks.find(
            (sTB) => sTB.textBrickTemplateId === aTB.id
          )
      );
    } else {
      this.remainingTextBrickTemplates = [];
    }
    assertNotNullOrUndefined(
      this.subscribeOptions,
      'cannot updateRemainingTextBrickTemplates without subscribeOptions'
    );
    this.subscribeOptions.setRemainingTextBrickTemplates(
      this.remainingTextBrickTemplates
    );
  }

  public createNewTextBrickFromTemplate(
    textBrickTemplate: TextBrickTemplate
  ): void {
    assertNotNullOrUndefined(
      this.entry,
      'cannot add text brick without an entry'
    );
    assertNotNullOrUndefined(
      this.entry.ownerProjectId,
      'cannot add text brick without an ownerProjectId'
    );

    this.entityManager.textBrickRepository.create({
      entryId: this.entry.id,
      textBrickTemplateId: textBrickTemplate.id,
      ownerProjectId: this.entry.ownerProjectId,
      ownerUserGroupId: this.entry.ownerUserGroupId,
      value: textBrickTemplate.value,
      temporaryGroupName: this.temporaryGroupName,
      shadowEntity: !!this.temporaryGroupName
    });

    this.updateTextBricks();
  }

  public openEditTextBrickTemplateDialog(): void {
    assertNotNullOrUndefined(
      this.entry,
      'cannot open EditTextBricksDialog without entry'
    );

    const textBrickTemplateIds = [];
    for (const textBrick of this.selectedTextBricks) {
      if (textBrick.textBrickTemplateId)
        textBrickTemplateIds.push(textBrick.textBrickTemplateId);
    }

    void EditTextBrickTemplatesDialog.open({
      path: this.path,
      textBrickTemplateIds: textBrickTemplateIds,
      createTextBrickFromTemplate: ({ value, textBrickTemplateId }) => {
        assertNotNullOrUndefined(
          this.entry,
          'cannot open EditTextBricksDialog without entry'
        );
        this.entityManager.textBrickRepository.create({
          value,
          textBrickTemplateId,
          entryId: this.entry.id,
          ownerProjectId: this.entry.ownerProjectId,
          ownerUserGroupId: this.entry.ownerUserGroupId,
          temporaryGroupName: this.temporaryGroupName,
          shadowEntity: !!this.temporaryGroupName
        });
      },
      ownerUserGroupId: this.entry.ownerUserGroupId,
      getTextBricks: () => {
        assertNotNullOrUndefined(
          this.entry,
          'cannot open EditTextBricksDialog without entry'
        );
        return this.entityManager.textBrickRepository.getByEntryId(
          this.entry.id
        );
      },
      createAdapterForPermissionsHandle: () => {
        assertNotNullOrUndefined(
          this.entry,
          'cannot open EditTextBricksDialog without entry'
        );
        return new EntryTextBrickWidgetAdapter({
          entry: this.entry,
          path: this.path,
          temporaryGroupName: this.temporaryGroupName,
          permissionsService: this.permissionsService,
          entityManager: this.entityManager,
          subscriptionManagerService: this.subscriptionManagerService
        });
      },
      temporaryGroupName: this.temporaryGroupName,
      onDialogAccept: () => {
        this.updateTextBricks();
      }
    });
  }
}

type EntryTextBrickWidgetAdapterOptions =
  TextBrickWidgetCommonAdapterOptions & {
    entry: Entry;
    path: Array<string>;
    temporaryGroupName: string | null;
  };
