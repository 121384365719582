import { computedFrom } from 'aurelia-binding';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';

/**
 * Caches data using the {@link DataStorageHelper}.
 *
 * @example
 * protected valueCache: DataStorageCache<string> = new DataStorageCache('my-key');
 *
 * \@watch('valueFromServer')
 * protected updateValue(): void {
 *   if(this.socketService.isConnected()) {
 *     void this.valueCache.update(this.valueFromServer);
 *   }
 * }
 *
 * \@computed(expression('valueCache.value'))
 * protected get value(): string {
 *   return this.valueCache.value;
 * }
 */
export class DataStorageCache<T> {
  private _value: T | null = null;
  private hasUpdated = false;
  private _hasLoaded = false;

  constructor(private readonly key: string) {
    void DataStorageHelper.getItem(this.key).then((v) => {
      // If a value has already been updated through `update`, skip
      if (!this.hasUpdated) {
        this._value = v;
      }
      this._hasLoaded = true;
    });
  }

  /**
   * Update the cache with a new value.
   *
   * @example
   * void this.valueCache.update(this.valueFromServer);
   */
  public async update(value: T | Promise<T>): Promise<void> {
    const v = await Promise.resolve(value);
    this._value = v;
    this.hasUpdated = true;
    await DataStorageHelper.setItem(this.key, v);
  }

  /**
   * The value of the cache. Initially `null`, since this is set asynchronously.
   */
  @computedFrom('_value')
  public get value(): T | null {
    return this._value;
  }

  /**
   * Returns `true` if a value has been loaded from storage.
   */
  @computedFrom('_hasLoaded')
  public get hasLoaded(): boolean {
    return this._hasLoaded;
  }
}
