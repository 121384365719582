import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { DateUtils } from 'common/DateUtils';
import { CalendarHolidays } from '../../classes/Calendar/CalendarHolidays';
import { CalendarEntryClickedEvent } from './process-task-appointment-calendar-widget';
import {
  CalendarEntriesHandle,
  CalendarEntryDataSource
} from './CalendarEntryDataSource/CalendarEntryDataSource';
import { GetCalendarEntriesOptions } from './CalendarEntryDataSource/strategies/CalendarEntryDataSourceStrategy';
import {
  CalendarEntryClamper,
  ClampedCalendarEntry
} from './CalendarEntryClamper/CalendarEntryClamper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { CalendarEntryType } from './CalendarEntryDataSource/CalendarEntry';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

/**
 * @event {CalendarEntryClickedEvent} calendar-entry-clicked
 * @cssvariable --time-grid-cell-background-color
 */
@autoinject()
export class ProcessTaskAppointmentCalendarWidgetDay {
  @bindable()
  public dataSource: CalendarEntryDataSource | null = null;

  @bindable()
  public userIds: Array<string> | null = null;

  @bindable()
  public displayHours: Array<number> = [];

  @bindable()
  public day: Date | null = null;

  @bindable()
  public useUserColors: boolean = false;

  /** If true, will expand appointments horizontally on hover in order to display more content, similar to the yearly calendar view. */
  @bindable()
  public expandAppointmentsHorizontallyOnHover: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;
  private readonly calendarEntryClamper: CalendarEntryClamper;
  private calendarEntriesHandle: CalendarEntriesHandle | null = null;
  private isAttached: boolean = false;
  private noteInAppointmentNotificationEnabled: boolean = false;
  protected cachedColumns: Array<Array<ClampedCalendarEntry>> = [];

  constructor(
    private readonly element: Element,
    private readonly activeUserCompanySettingsService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.calendarEntryClamper = new CalendarEntryClamper({
      calendarEntries: [],
      displayHours: this.displayHours
    });

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingsService.bindSettingProperty(
        'operations.noteInAppointmentNotificationEnabled',
        (value) => {
          this.noteInAppointmentNotificationEnabled = value;
        }
      )
    );
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'displayHours',
      () => {
        this.calendarEntryClamper.setDisplayHours(this.displayHours);
      }
    );
    this.calendarEntryClamper.setDisplayHours(this.displayHours);

    this.subscriptionManager.addDisposable(
      this.calendarEntryClamper.subscribe({
        onNewClampedCalendarEntries: (clampedCalendarEntries) => {
          this.updateCachedColumns(clampedCalendarEntries);
        }
      })
    );

    this.updateCalendarEntriesHandle();
  }

  protected detached(): void {
    this.isAttached = false;

    this.calendarEntriesHandle?.destroy();
    this.calendarEntriesHandle = null;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected dayChanged(): void {
    this.calendarEntriesHandle?.updateGetCalendarEntriesOptions(
      this.getGetCalendarEntriesOptions()
    );
  }

  protected userIdsChanged(): void {
    this.calendarEntriesHandle?.updateGetCalendarEntriesOptions(
      this.getGetCalendarEntriesOptions()
    );
  }

  protected useUserColorsChanged(): void {
    this.calendarEntriesHandle?.updateGetCalendarEntriesOptions(
      this.getGetCalendarEntriesOptions()
    );
  }

  private updateCalendarEntriesHandle(): void {
    this.calendarEntriesHandle?.destroy();
    this.calendarEntriesHandle = null;

    if (this.isAttached && this.dataSource) {
      this.calendarEntriesHandle = this.dataSource?.createHandle({
        getCalendarEntriesOptions: this.getGetCalendarEntriesOptions(),
        onNewCalendarEntries: (calendarEntries) => {
          this.calendarEntryClamper.setCalendarEntries(calendarEntries);
        }
      });
    }
  }

  private getGetCalendarEntriesOptions(): GetCalendarEntriesOptions {
    assertNotNullOrUndefined(
      this.userIds,
      'cannot getGetCalendarEntriesOptions without userIds'
    );
    assertNotNullOrUndefined(
      this.day,
      'cannot getGetCalendarEntriesOptions without day'
    );

    return {
      useUserColors: this.useUserColors,
      filter: {
        userIds: this.userIds,
        date: {
          dateFrom: DateUtils.getStartDateOfDay(this.day),
          dateTo: DateUtils.getEndDateOfDay(this.day)
        }
      }
    };
  }

  protected handleEntryClick(entry: ClampedCalendarEntry): void {
    DomEventHelper.fireEvent<CalendarEntryClickedEvent>(this.element, {
      name: 'calendar-entry-clicked',
      detail: {
        entry: entry.calendarEntry
      },
      bubbles: true
    });
  }

  private updateCachedColumns(entries: Array<ClampedCalendarEntry>): void {
    const sortedCalendarEntries = entries.slice().sort((a, b) => {
      if (a.clampedTime.startTimestamp < b.clampedTime.startTimestamp)
        return -1;
      if (a.clampedTime.startTimestamp > b.clampedTime.startTimestamp) return 1;
      if (a.clampedTime.endTimestamp < b.clampedTime.endTimestamp) return -1;
      if (a.clampedTime.endTimestamp > b.clampedTime.endTimestamp) return 1;
      return 0;
    });

    const columns: Array<Array<ClampedCalendarEntry>> = [];

    for (const entry of sortedCalendarEntries) {
      let placed = false;
      for (const currentColumn of columns) {
        const lastEntry = currentColumn[currentColumn.length - 1];
        if (!lastEntry || !this.collidesWith(lastEntry, entry)) {
          currentColumn.push(entry);
          placed = true;
          break;
        }
      }
      if (!placed) {
        columns.push([entry]);
      }
    }

    this.cachedColumns = columns;
  }

  private collidesWith(
    a: ClampedCalendarEntry,
    b: ClampedCalendarEntry
  ): boolean {
    return (
      a.clampedTime.endTimestamp > b.clampedTime.startTimestamp &&
      a.clampedTime.startTimestamp < b.clampedTime.endTimestamp
    );
  }

  @computedFrom('day')
  protected get isWorkFreeDay(): boolean {
    if (!this.day) return false;

    return (
      DateUtils.isWeekend(this.day) || CalendarHolidays.isHoliday(this.day)
    );
  }

  protected hasNotificationIcon(
    clampedCalendarEntry: ClampedCalendarEntry,
    noteInAppointmentNotificationEnabled: boolean
  ): boolean {
    const entry = clampedCalendarEntry.calendarEntry;
    if (entry.type !== CalendarEntryType.NORMAL) return false;
    return (
      noteInAppointmentNotificationEnabled &&
      !entry.done &&
      (!!entry.processTaskNote || !!entry.processTaskAppointmentNote)
    );
  }
}
