import { autoinject, bindable } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationNoteCategory } from '../../classes/EntityManager/entities/ProcessConfigurationNoteCategory/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject
export class ProcessConfigurationNoteCategoriesWidget {
  @bindable
  public processConfiguration: ProcessConfiguration | null = null;

  @subscribableLifecycle()
  protected processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfiguration,
        context: this as ProcessConfigurationNoteCategoriesWidget,
        propertyName: 'processConfiguration'
      });
  }

  @computed(
    model(EntityName.ProcessConfigurationNoteCategory),
    expression('processConfiguration')
  )
  protected get categories(): Array<ProcessConfigurationNoteCategory> {
    return this.processConfiguration
      ? this.entityManager.processConfigurationNoteCategoryRepository.getByProcessConfigurationId(
          this.processConfiguration.id
        )
      : [];
  }

  protected handleAddCategoryClick(): void {
    if (this.processConfiguration) {
      this.entityManager.processConfigurationNoteCategoryRepository.create({
        ownerProcessConfigurationId: this.processConfiguration.id,
        ownerUserGroupId: this.processConfiguration.ownerUserGroupId
      });
    }
  }
}
