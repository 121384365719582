import { QuestionChoice } from 'common/Types/Entities/Question/QuestionDto';
import { ProjectQuestion } from './types';

export namespace ProjectQuestionUtils {
  /**
   * Returns the currently selected choice from an array of choices.
   */
  export function getSelectedChoice(
    answer: string,
    choices: Array<QuestionChoice>
  ): QuestionChoice | null {
    return choices.find((choice) => choice.choiceText === answer) ?? null;
  }

  /**
   * Returns whether a question should be displayed in the UI as completed.
   * (i.e. it has an answer or is hidden)
   */
  export function isCompleted(question: ProjectQuestion): boolean {
    return question.answer !== null || question.hidden;
  }
}
