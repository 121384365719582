import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { UserCompanySetting } from '../../classes/EntityManager/entities/UserCompanySetting/types';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

/**
 * @event edit-user-company-setting-clicked
 */
@autoinject()
export class UserCompanySettingListItem {
  @bindable public userCompanySetting: UserCompanySetting | null = null;

  private panelOpened: boolean = false;
  private domElement: HTMLElement;
  private listItemElement: HTMLElement | null = null;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly i18n: I18N
  ) {
    this.domElement = element as HTMLElement;
  }

  public highlight(): void {
    assertNotNullOrUndefined(
      this.listItemElement,
      "can't UserCompanySettingListItem.highlight without listItemElement"
    );
    EntityListItemHelper.highlightListItemElement(this.listItemElement);
  }

  protected handleMoreButtonClick(): void {
    this.panelOpened = !this.panelOpened;
  }

  protected handleEditSettingClick(): void {
    DomEventHelper.fireEvent<EditUserCompanySettingClickedEvent>(
      this.domElement,
      {
        name: 'edit-user-company-setting-clicked',
        detail: null
      }
    );
  }

  protected handleCopyUserCompanySettingClick(): void {
    assertNotNullOrUndefined(
      this.userCompanySetting,
      'cannot dupplicate without userCompanySetting'
    );

    this.entityManager.userCompanySettingRepository.create({
      ...this.userCompanySetting,
      name:
        this.userCompanySetting.name +
        ` (${this.i18n.tr('general.copySubstantive')})`
    });
  }

  protected handleDeleteSettingClick(): void {
    const userCompanySetting = this.userCompanySetting;
    assertNotNullOrUndefined(
      userCompanySetting,
      'no userCompanySetting to delete'
    );

    void Dialogs.deleteEntityDialog(userCompanySetting).then(() => {
      this.entityManager.userCompanySettingRepository.delete(
        userCompanySetting
      );
    });
  }
}

export type EditUserCompanySettingClickedEvent =
  NamedCustomEvent<'edit-user-company-setting-clicked'>;
