import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { ReportDto } from './ReportDto';

export function createReportEntityInfo<
  TEntity extends ReportDto<string, string>
>(
  options: CreateEntityInfoOptions<ReportDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.Report,
  TEntity
> {
  return {
    entityName: EntityName.Report,
    synchronizeRelativeTo: [EntityName.Project],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ReportDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createOptionalBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        created: {
          name: 'created'
        },
        status: {
          name: 'status',
          defaultValue: null
        },
        uploadedToExternalLocationStatus: {
          name: 'uploadedToExternalLocationStatus',
          defaultValue: null
        },
        externalSyncStatus: {
          name: 'externalSyncStatus',
          defaultValue: null,
          innerInfo: {
            sharepoint: {
              name: 'sharepoint',
              defaultValue: null,
              innerInfo: {
                status: {
                  name: 'status',
                  defaultValue: null
                },
                url: {
                  name: 'url',
                  defaultValue: null
                },
                retries: {
                  name: 'retries',
                  defaultValue: null
                },
                lastAttempt: {
                  name: 'lastAttempt',
                  defaultValue: null
                }
              }
            },
            sap: {
              name: 'sap',
              defaultValue: null,
              innerInfo: {
                status: {
                  name: 'status',
                  defaultValue: null
                },
                url: {
                  name: 'url',
                  defaultValue: null
                },
                retries: {
                  name: 'retries',
                  defaultValue: null
                },
                lastAttempt: {
                  name: 'lastAttempt',
                  defaultValue: null
                }
              }
            }
          }
        },
        file: {
          name: 'file',
          defaultValue: null
        },
        type: {
          name: 'type'
        },
        exported: {
          name: 'exported',
          defaultValue: null
        },
        file_size: {
          name: 'file_size',
          defaultValue: null
        },
        project: {
          name: 'project',
          ref: EntityName.Project,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        thing: {
          name: 'thing',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        report_type: {
          name: 'report_type',
          ref: EntityName.ReportType
        },
        signatureTime: {
          name: 'signatureTime',
          defaultValue: null
        },
        signatureUserId: {
          name: 'signatureUserId',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
