import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { FieldInfoHandlerCache } from '@record-it-npm/synchro-common';
import { BaseEntity } from '../../../classes/EntityManager/entities/BaseEntity';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import {
  CanEditFieldFieldName,
  EntityAdapter
} from '../EntityAdapter/EntityAdapter';
import {
  EntityAdapterContainer,
  EntityAdapterOfEntityAdapterContainer,
  EntityOfEntityAdapterContainer
} from '../EntityAdapterContainer/EntityAdapterContainer';
import { computedFrom } from 'aurelia-binding';

/**
 * Manages the permissions to the entity.
 * E.g. if a field can be edited
 *
 * To implement other adapter functions (in the child classes, e.g. DefectPermissionsHandle) you should use the following code:
 *
 * @computedFrom('combinedRevision')
 * public get canEditPictures(): boolean {
 *   return this.checkAdapterSpecificPermissionByName('canEditPictures');
 * }
 */
export class EntitySpecificPermissionsHandle<
  TAdapterContainer extends EntityAdapterContainer<EntityAdapter<BaseEntity>>,
  TEntity extends
    EntityOfEntityAdapterContainer<TAdapterContainer> = EntityOfEntityAdapterContainer<TAdapterContainer>
> {
  public readonly canEditField: CanEditField<TEntity>;

  private readonly adapterContainer: EntityAdapterContainer<any>; // any for performance reasons
  private readonly entity$: Observable<any>; // any for performance reasons
  private readonly override$ = new BehaviorSubject<boolean | null>(null);
  private readonly permissionCache = new Map<
    string | symbol | number,
    boolean
  >();

  private readonly canEditFieldBindings: Array<CanEditFieldBinding<TEntity>> =
    [];

  private entity: any = null; // any for performance reasons
  private entityRevision: number = 1;

  private adapter: EntityAdapterOfEntityAdapterContainer<TAdapterContainer> | null =
    null;

  private adapterRevision: number = 1;
  private override: boolean | null = null;
  private overrideRevision: number = 1;

  /**
   * a simple value for @computedFrom to observe so they know when they need to update
   */
  protected combinedRevision: string = '0';

  constructor(
    options: EntitySpecificPermissionsHandleOptions<TAdapterContainer, TEntity>
  ) {
    this.adapterContainer = options.adapterContainer;
    this.entity$ = options.entity$;
    this.canEditField = this.createCanEditField(options.adapterContainer);
  }

  public subscribe(): Disposable {
    const disposable = this.adapterContainer.bindAdapter((options) => {
      this.adapter = options.adapter;
      this.adapterRevision++;
      this.updatePermissions();
    });

    const entitySubscription = this.entity$.subscribe((entity) => {
      this.entity = entity;
      this.entityRevision++;
      this.updatePermissions();
    });

    return {
      dispose: () => {
        disposable.dispose();
        entitySubscription.unsubscribe();

        this.adapter = null;
        this.adapterRevision++;

        this.entity = null;
        this.entityRevision++;

        this.updatePermissions();
      }
    };
  }

  /**
   * The binding is automatically linked to the subscribe lifecycle.
   * Disposing it is only necessary if e.g. the binding gets registered during the attached function.
   * If the binding is registered right after creating the handle, it doesn't need to get disposed
   */
  public bindCanEditField(
    fieldName: keyof CanEditField<TEntity>,
    callback: (fieldIsEditable: boolean) => void
  ): Disposable {
    const binding: CanEditFieldBinding<TEntity> = {
      fieldName,
      subject$: new BehaviorSubject(this.canEditField[fieldName])
    };

    const subscription = binding.subject$
      .pipe(distinctUntilChanged())
      .subscribe(callback);

    this.canEditFieldBindings.push(binding);

    return {
      dispose: () => {
        subscription.unsubscribe();

        const index = this.canEditFieldBindings.indexOf(binding);
        if (index >= 0) {
          this.canEditFieldBindings.splice(index, 1);
        }
      }
    };
  }

  @computedFrom('combinedRevision')
  public get canDeleteEntity(): boolean {
    return this.checkAdapterSpecificPermission({
      permissionName: 'canDeleteEntity',
      checkPermission: ({ adapter, entity }) => {
        return adapter.canDeleteEntity(entity);
      }
    });
  }

  /**
   * If the value is set to null, there will be no override.
   */
  public overrideAllPermissions(value: boolean | null): void {
    if (value === this.override) {
      return;
    }

    this.override = value;
    this.overrideRevision++;
    this.updatePermissions();

    this.override$.next(value);
  }

  /**
   * Normally you should use the specific permissions as dependencies (e.g. permissionsHandle.canEditField.theField) for @computedFrom s etc,
   * but if the dependencies are dynamic, you can use the revision here
   */
  @computedFrom('adapterRevision')
  public get revision(): string {
    return this.combinedRevision;
  }

  protected checkAdapterSpecificPermissionByName(
    name: AdapterKeyWithEntityAsArg<TAdapterContainer>
  ): boolean {
    return this.checkAdapterSpecificPermission({
      permissionName: name,
      checkPermission: ({ adapter, entity }) => {
        if (typeof adapter[name] !== 'function') {
          throw new Error(
            `${String(name)} does not exist in the adapter for ${
              adapter.getEntityInfo().entityName
            }`
          );
        }

        return (adapter[name] as (entity: TEntity) => boolean)(entity);
      }
    });
  }

  protected checkAdapterSpecificPermission({
    permissionName,
    checkPermission
  }: {
    permissionName: string | symbol | number;
    checkPermission: (options: {
      entity: TEntity;
      adapter: EntityAdapterOfEntityAdapterContainer<TAdapterContainer>;
    }) => boolean;
  }): boolean {
    const cachedValue = this.permissionCache.get(permissionName);
    if (cachedValue != null) {
      return cachedValue;
    }

    const value = this.checkAdapterSpecificPermissionUncached({
      checkPermission
    });
    this.permissionCache.set(permissionName, value);

    return value;
  }

  private createCanEditField(
    adapterContainer: EntityAdapterContainer<any>
  ): CanEditField<TEntity> {
    const obj: Record<string, boolean> = {};

    // internal value, this is necessary so we can emulate a @computedFrom for the specific getter
    Object.defineProperty(obj, '__permissionsHandle__', {
      enumerable: false,
      configurable: true,
      value: this
    });

    FieldInfoHandlerCache.getForEntityInfo(
      adapterContainer.getEntityInfo()
    ).forEachFieldInfo((fieldInfo) => {
      const fieldName = fieldInfo.path[0];
      if (!fieldName || fieldInfo.path.length > 1) {
        return;
      }

      const getFunction = (): boolean => {
        return this.checkAdapterSpecificPermission({
          permissionName: `canEditField.${fieldName}`,
          checkPermission: ({ entity, adapter }) => {
            return adapter.canEditField(entity, fieldName as any);
          }
        });
      };

      // this emulates a @computedFrom and prevents aurelia from calling this all the time/dirty checking
      (getFunction as any).dependencies = [
        '__permissionsHandle__.combinedRevision'
      ];

      Object.defineProperty(obj, fieldName, {
        get: getFunction,
        configurable: true
      });
    });

    return obj as CanEditField<TEntity>;
  }

  private updatePermissions(): void {
    this.permissionCache.clear();

    // to update all computeds
    this.combinedRevision = `${this.adapterRevision}_${this.entityRevision}_${this.overrideRevision}`;

    for (const binding of this.canEditFieldBindings) {
      binding.subject$.next(this.canEditField[binding.fieldName]);
    }
  }

  private checkAdapterSpecificPermissionUncached({
    checkPermission
  }: {
    checkPermission: (options: {
      entity: TEntity;
      adapter: EntityAdapterOfEntityAdapterContainer<TAdapterContainer>;
    }) => boolean;
  }): boolean {
    if (this.override !== null) {
      return this.override;
    }

    if (!this.adapter || !this.entity) {
      return false;
    }

    return checkPermission({
      entity: this.entity,
      adapter: this.adapter
    });
  }
}

export type EntitySpecificPermissionsHandleOptions<
  TAdapterContainer extends EntityAdapterContainer<EntityAdapter<BaseEntity>>,
  TEntity extends
    EntityOfEntityAdapterContainer<TAdapterContainer> = EntityOfEntityAdapterContainer<TAdapterContainer>
> = {
  adapterContainer: TAdapterContainer;
  entity$: Observable<TEntity | null | undefined>;
};

export type CanEditField<TEntity extends BaseEntity> = {
  readonly [key in CanEditFieldFieldName<TEntity>]: boolean;
};

export type AdapterKeyWithEntityAsArg<
  TAdapterContainer extends EntityAdapterContainer<EntityAdapter<BaseEntity>>,
  TAdapter extends
    EntityAdapterOfEntityAdapterContainer<TAdapterContainer> = EntityAdapterOfEntityAdapterContainer<TAdapterContainer>,
  TEntity extends
    EntityOfEntityAdapterContainer<TAdapterContainer> = EntityOfEntityAdapterContainer<TAdapterContainer>
> = {
  [key in keyof TAdapter]: TAdapter[key] extends (entity: TEntity) => boolean
    ? key
    : TAdapter[key] extends () => boolean
      ? key
      : never;
}[keyof TAdapter];

type CanEditFieldBinding<TEntity extends BaseEntity> = {
  fieldName: keyof CanEditField<TEntity>;
  subject$: BehaviorSubject<boolean>;
};
