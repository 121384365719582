import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import type { ProcessTaskNote } from './types';

export class ProcessTaskNoteRepository extends AppEntityRepository<EntityName.ProcessTaskNote> {
  public getByProcessTaskId(processTaskId: string): Array<ProcessTaskNote> {
    return this.getAll().filter((f) => f.processTaskId === processTaskId);
  }

  public getByCategoryId(categoryId: string): Array<ProcessTaskNote> {
    return this.getAll().filter(
      (ptn) => ptn.processConfigurationNoteCategoryId === categoryId
    );
  }

  public getByCategoryIds(categoryIds: Array<string>): Array<ProcessTaskNote> {
    return this.getAll().filter(
      (ptn) =>
        ptn.processConfigurationNoteCategoryId &&
        categoryIds.includes(ptn.processConfigurationNoteCategoryId)
    );
  }
}
