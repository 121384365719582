import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import {
  StructureTemplateEntryProperty,
  StructureTemplateEntryStructureTemplateEntryProperty,
  StructureTemplateStructureTemplateEntryProperty
} from './types';

export class StructureTemplateEntryPropertyRepository extends AppEntityRepository<EntityName.StructureTemplateEntryProperty> {
  public getByStructureTemplateId(
    structureTemplateId: string
  ): Array<StructureTemplateStructureTemplateEntryProperty> {
    return this.getSortedProperties(
      (p): p is StructureTemplateStructureTemplateEntryProperty => {
        return (
          p.ownerStructureTemplateId === structureTemplateId &&
          !p.structureTemplateEntryId
        );
      }
    );
  }

  public getByStructureTemplateEntryId(
    structureTemplateEntryId: string
  ): Array<StructureTemplateEntryStructureTemplateEntryProperty> {
    return this.getSortedProperties(
      (p): p is StructureTemplateEntryStructureTemplateEntryProperty => {
        return p.structureTemplateEntryId === structureTemplateEntryId;
      }
    );
  }

  public getByOwnerStructureTemplateId(
    structureTemplateId: string
  ): Array<StructureTemplateEntryProperty> {
    return this.getSortedProperties(
      (p): p is StructureTemplateEntryProperty => {
        return p.ownerStructureTemplateId === structureTemplateId;
      }
    );
  }

  private getSortedProperties<T extends StructureTemplateEntryProperty>(
    filterFunction: (p: StructureTemplateEntryProperty) => p is T
  ): Array<T> {
    const filteredEntities = this.getAll().filter((p): p is T =>
      filterFunction(p)
    );

    filteredEntities.sort((p1, p2) => {
      const p1Order = p1.order ?? Number.MAX_SAFE_INTEGER;
      const p2Order = p2.order ?? Number.MAX_SAFE_INTEGER;

      if (p1Order !== p2Order) return p1Order - p2Order;

      return p1.id.localeCompare(p2.id);
    });

    return filteredEntities;
  }
}
