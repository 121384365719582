import { BindingEngine, ICollectionObserverSplice } from 'aurelia-binding';
import { DisposableItemsCache } from '../DisposableItemsCache/DisposableItemsCache';
import { Disposable } from '../Utils/DisposableContainer';

export class ObserverUtils {
  private constructor() {}

  /**
   * the callback will get called if a new array gets assigned to the property
   */
  public static observeArrayPropertyItemSubObservations({
    bindingEngine,
    context,
    propertyName,
    callback,
    createSubObservation
  }: {
    bindingEngine: BindingEngine;
    context: Record<string, any>;
    propertyName: string;
    createSubObservation: (options: {
      item: any;
      onSubObservationChanged: () => void;
    }) => Disposable;
    callback: () => void;
  }): Disposable {
    const onSubObservationChanged = (): void => {
      callback();
    };

    const subObservationCache = new DisposableItemsCache<any, Disposable>({
      createDisposableForItem: ({ item }) => {
        return createSubObservation({ item, onSubObservationChanged });
      }
    });

    const instanceChanged = (): void => {
      const value = context[propertyName];

      subObservationCache.mapItems({
        items: value instanceof Array ? value : []
      });
    };

    const arrayPropertyChangesDisposable = this.observeArrayPropertyChanges({
      bindingEngine,
      context,
      propertyName,
      callback: () => {
        instanceChanged();
        callback();
      }
    });

    instanceChanged();

    return {
      dispose: () => {
        arrayPropertyChangesDisposable.dispose();
        subObservationCache.disposeAndClear();
      }
    };
  }

  /**
   * subscribes to changes on a certain property of the context
   * the callback will be called if an new array gets assigned, or if the array itself has internal changes (e.g. an item has been pushed into it)
   */
  public static observeArrayPropertyChanges({
    bindingEngine,
    context,
    propertyName,
    callback
  }: {
    bindingEngine: BindingEngine;
    context: Record<string, any>;
    propertyName: string;
    callback: CollectionObserverCallback;
  }): Disposable {
    let arrayItemChangesDisposable: Disposable | null = null;

    const instanceChanged = (): void => {
      arrayItemChangesDisposable?.dispose();
      if (context[propertyName]) {
        arrayItemChangesDisposable = ObserverUtils.observeArrayItemChanges({
          bindingEngine,
          context: context[propertyName],
          callback
        });
      }
    };

    const expressionDisposable = ObserverUtils.observeExpression({
      bindingEngine,
      context,
      expression: propertyName,
      callback: () => {
        instanceChanged();
        callback([]);
      }
    });

    instanceChanged();

    return {
      dispose: () => {
        arrayItemChangesDisposable?.dispose();
        expressionDisposable.dispose();
      }
    };
  }

  /**
   * callback will be called everytime an item in the array gets added or removed
   */
  public static observeArrayItemChanges({
    bindingEngine,
    context,
    callback
  }: {
    bindingEngine: BindingEngine;
    context: Array<any>;
    callback: CollectionObserverCallback;
  }): Disposable {
    return bindingEngine.collectionObserver(context).subscribe(callback);
  }

  public static observeExpression({
    bindingEngine,
    context,
    expression,
    callback
  }: {
    bindingEngine: BindingEngine;
    context: Record<string, any>;
    expression: string;
    callback: (newValue: any, oldValue: any) => void;
  }): Disposable {
    return bindingEngine
      .expressionObserver(context, expression)
      .subscribe(callback);
  }
}

export type CollectionObserverCallback = (
  changeRecords: Array<ICollectionObserverSplice>
) => void;
