import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { ProcessTaskAppointmentToProcessTaskPosition } from '../../../../classes/EntityManager/entities/ProcessTaskAppointmentToProcessTaskPosition/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { processTaskAppointmentToProcessTaskPositionEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskAppointmentToProcessTaskPosition/processTaskAppointmentToProcessTaskPositionEntityInfo';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { ProcessTaskGroupAuthorizationComputer } from '../../../../computedValues/computers/ProcessTaskGroupAuthorizationComputer/ProcessTaskGroupAuthorizationComputer';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';

export class ProcessTaskAppointmentToProcessTaskPositionAdapter
  implements EntityAdapter<ProcessTaskAppointmentToProcessTaskPosition>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private processTaskIdsWhereUserIsAuthorized: Set<string> | null = null;

  constructor(
    options: ProcessTaskAppointmentToProcessTaskPositionAdapterOptions
  ) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskGroupAuthorizationComputer,
        computeData: {},
        callback: ({ processTaskIdsWhereUserIsAuthorized }) => {
          this.processTaskIdsWhereUserIsAuthorized =
            processTaskIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.processTaskIdsWhereUserIsAuthorized != null
    );
  }

  public canDeleteEntity(
    processTaskAppointmentToProcessTaskPosition: ProcessTaskAppointmentToProcessTaskPosition
  ): boolean {
    return this.checkPermission({
      processTaskAppointmentToProcessTaskPosition,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteProcessTaskAppointmentToProcessTaskPositions()
    });
  }

  public canEditField(
    processTaskAppointmentToProcessTaskPosition: ProcessTaskAppointmentToProcessTaskPosition
  ): boolean {
    return this.checkPermission({
      processTaskAppointmentToProcessTaskPosition,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskAppointmentToProcessTaskPositions()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskAppointmentToProcessTaskPosition,
    ProcessTaskAppointmentToProcessTaskPosition
  > {
    return processTaskAppointmentToProcessTaskPositionEntityInfo;
  }

  private checkPermission({
    processTaskAppointmentToProcessTaskPosition,
    checkRolePermission
  }: {
    processTaskAppointmentToProcessTaskPosition: ProcessTaskAppointmentToProcessTaskPosition;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkProcessTaskSubEntityPermission({
      entity: processTaskAppointmentToProcessTaskPosition,
      processTaskIdsWhereUserIsAuthorized:
        this.processTaskIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ProcessTaskAppointmentToProcessTaskPositionAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
