import { autoinject } from 'aurelia-framework';
import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { Utils } from '../../classes/Utils/Utils';
import { rateLimit } from '../../rxjs/operators/rateLimit/rateLimit';
import { SubscriptionManagerService } from '../SubscriptionManagerService';

@autoinject()
export class LastOpenedProcessTaskGroupsService {
  private static readonly MAX_LAST_OPENED_INFOS = 3;
  private static readonly LAST_OPENED_INFOS_STORAGE_KEY =
    'LastOpenedProcessTaskGroupService.lastOpenedInfos';

  private static lastOrder = 0;

  private readonly subscriptionManager: SubscriptionManager;
  private readonly lastOpenedInfos$ = new BehaviorSubject<
    Array<LastOpenedInfo>
  >([]);

  private saveSubscription: Subscription | null = null;
  private initialized: boolean = false;
  private orderByLastOpenedInfo = new WeakMap<LastOpenedInfo, number>();

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    await this.loadLastOpenedInfos();

    this.saveSubscription?.unsubscribe();
    this.saveSubscription = this.lastOpenedInfos$
      .pipe(rateLimit({ rateInterval: 500 }))
      .subscribe((lastOpenedInfos) => {
        void DataStorageHelper.setItem(
          LastOpenedProcessTaskGroupsService.LAST_OPENED_INFOS_STORAGE_KEY,
          lastOpenedInfos
        );
      });

    const updateLastOpenedInfosRateLimited = Utils.rateLimitFunction(
      this.updateLastOpenedInfos.bind(this),
      250
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      updateLastOpenedInfosRateLimited,
      0
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      updateLastOpenedInfosRateLimited,
      0
    );

    this.initialized = true;
  }

  public destroy(): void {
    this.saveSubscription?.unsubscribe();
    this.saveSubscription = null;

    this.subscriptionManager.disposeSubscriptions();

    this.lastOpenedInfos$.next([]);
    this.initialized = false;
  }

  public processTaskGroupOpened({
    processTaskGroup,
    processTask
  }: {
    processTaskGroup: ProcessTaskGroup;
    processTask: ProcessTask;
  }): void {
    this.assertInitialized();

    this.lastOpenedInfos$.next(
      this.generateNewLastOpenedInfos({
        processTaskGroup,
        processTask,
        lastOpenedInfos: this.lastOpenedInfos$.getValue()
      })
    );
  }

  public bindLastOpenedInfos(
    callback: (lastOpenedInfos: Array<LastOpenedInfo>) => void
  ): Disposable {
    this.assertInitialized();

    const subscription = this.lastOpenedInfos$.subscribe(callback);

    return {
      dispose: () => {
        subscription.unsubscribe();
      }
    };
  }

  private async loadLastOpenedInfos(): Promise<void> {
    const infos: Array<LastOpenedInfo> =
      (await DataStorageHelper.getItem(
        LastOpenedProcessTaskGroupsService.LAST_OPENED_INFOS_STORAGE_KEY
      )) ?? [];

    for (const info of infos) {
      this.setNewOrder({ info });
    }

    this.lastOpenedInfos$.next(infos ?? []);
  }

  private updateLastOpenedInfos(): void {
    const infos = this.lastOpenedInfos$.getValue();

    for (const info of infos) {
      this.updateLastOpenedInfo({ info });
    }
  }

  private updateLastOpenedInfo({ info }: { info: LastOpenedInfo }): void {
    const processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        info.processTaskGroupId
      );
    if (processTaskGroup) {
      info.thingGroupId = processTaskGroup.thingGroupId;
    }

    const thingGroup = this.entityManager.thingGroupRepository.getById(
      info.thingGroupId
    );
    if (thingGroup) {
      info.thingGroupAddress = ThingGroupHelper.getThingGroupAddressString(
        thingGroup.streetName,
        thingGroup.zip,
        thingGroup.municipality
      );
    }
  }

  private generateNewLastOpenedInfos({
    processTaskGroup,
    processTask,
    lastOpenedInfos: lastOpenedInfosParam
  }: {
    processTaskGroup: ProcessTaskGroup;
    processTask: ProcessTask;
    lastOpenedInfos: Array<LastOpenedInfo>;
  }): Array<LastOpenedInfo> {
    const lastOpenedInfos = [...lastOpenedInfosParam];

    const existingInfo = this.getExistingInfo({
      processTaskGroup,
      lastOpenedInfos
    });

    if (existingInfo) {
      this.setNewOrder({ info: existingInfo });
      existingInfo.processTaskId = processTask.id;
    } else {
      this.addInfoToTheFront({
        processTaskGroup,
        processTask,
        lastOpenedInfos
      });

      // only resort when a new site has been visited to not distract the user when he is navigating via the last opened links
      this.sortInfos({
        lastOpenedInfos
      });
    }

    return this.limitLastOpenedInfosLength({ lastOpenedInfos });
  }

  private getExistingInfo({
    processTaskGroup,
    lastOpenedInfos
  }: {
    processTaskGroup: ProcessTaskGroup;
    lastOpenedInfos: Array<LastOpenedInfo>;
  }): LastOpenedInfo | null {
    return (
      lastOpenedInfos.find((info) => {
        return info.processTaskGroupId === processTaskGroup.id;
      }) ?? null
    );
  }

  private addInfoToTheFront({
    processTaskGroup,
    processTask,
    lastOpenedInfos
  }: {
    processTaskGroup: ProcessTaskGroup;
    processTask: ProcessTask;
    lastOpenedInfos: Array<LastOpenedInfo>;
  }): void {
    const thingGroup = this.entityManager.thingGroupRepository.getById(
      processTaskGroup.thingGroupId
    );

    const info: LastOpenedInfo = {
      processTaskGroupId: processTaskGroup.id,
      processTaskId: processTask.id,
      thingGroupId: processTaskGroup.thingGroupId,
      thingGroupAddress: thingGroup
        ? ThingGroupHelper.getThingGroupAddressString(
            thingGroup.streetName,
            thingGroup.zip,
            thingGroup.municipality
          )
        : 'no address found'
    };

    this.setNewOrder({ info });

    lastOpenedInfos.unshift(info);
  }

  private sortInfos({
    lastOpenedInfos
  }: {
    lastOpenedInfos: Array<LastOpenedInfo>;
  }): void {
    lastOpenedInfos.sort((a, b) => {
      const aOrder = this.orderByLastOpenedInfo.get(a) ?? 0;
      const bOrder = this.orderByLastOpenedInfo.get(b) ?? 0;

      return bOrder - aOrder;
    });
  }

  private setNewOrder({ info }: { info: LastOpenedInfo }): void {
    this.orderByLastOpenedInfo.set(
      info,
      ++LastOpenedProcessTaskGroupsService.lastOrder
    );
  }

  private limitLastOpenedInfosLength({
    lastOpenedInfos
  }: {
    lastOpenedInfos: Array<LastOpenedInfo>;
  }): Array<LastOpenedInfo> {
    return lastOpenedInfos.slice(
      0,
      LastOpenedProcessTaskGroupsService.MAX_LAST_OPENED_INFOS
    );
  }

  private assertInitialized(): void {
    if (!this.initialized) {
      throw new Error('LastOpenedProcessTaskGroupsService is not initialized');
    }
  }
}

export type LastOpenedInfo = {
  processTaskGroupId: string;
  processTaskId: string;
  thingGroupId: string;
  thingGroupAddress: string;
};
