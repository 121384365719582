import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import {
  RegionIdChangedEvent,
  WidgetConfiguration
} from './ultra-rapid-fire-widget-overlay-widget';
import { SavePictureCallbackAnyOptions } from './ultra-rapid-fire-widget';
import { TagIdsChangedEvent } from './urfw-categorized-tags-button/urfw-categorized-tags-button';
import { TagDeletedEvent } from '../tags-widget-list/tags-widget-list';

/**
 * @slot default - slot for more buttons
 */
@autoinject()
export class UltraRapidFireWidgetOverlay {
  constructor(private readonly element: Element) {}

  @bindable public configuration: TUltraRapidFireWidgetOverlayConfiguration = {
    widgets: []
  };

  /*
   * This is needed by some overlay widgets
   */

  @bindable public data: SavePictureCallbackAnyOptions | null = null;

  protected handleTagIdsChanged(evt: TagIdsChangedEvent): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'tag-ids-changed',
      detail: evt.detail
    });
  }

  protected handleTagDeleted(evt: TagDeletedEvent): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'tag-deleted',
      detail: evt.detail
    });
  }

  protected handleRegionIdChanged(evt: RegionIdChangedEvent): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'region-id-changed',
      detail: evt.detail
    });
  }
}

export type TUltraRapidFireWidgetOverlayConfiguration = {
  widgets: Array<WidgetConfiguration>;
};
