import { computedFrom } from 'aurelia-framework';

import { Defect } from '../../../classes/EntityManager/entities/Defect/types';
import { DefectAdapter } from '../EntityAdapter/DefectAdapter/DefectAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class DefectPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<DefectAdapter>,
  Defect
> {
  @computedFrom('combinedRevision')
  public get canEditDefect(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditDefect');
  }

  @computedFrom('combinedRevision')
  public get canEditPictures(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditPictures');
  }

  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditProperties');
  }

  @computedFrom('combinedRevision')
  public get canCreateDefectComments(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateDefectComments');
  }

  @computedFrom('combinedRevision')
  public get canSetDefectStatusToNone(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canSetDefectStatusToNone'
    );
  }

  @computedFrom('combinedRevision')
  public get canSetDefectStatusToOpen(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canSetDefectStatusToOpen'
    );
  }

  @computedFrom('combinedRevision')
  public get canSetDefectStatusToProcessed(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canSetDefectStatusToProcessed'
    );
  }

  @computedFrom('combinedRevision')
  public get canSetDefectStatusToDone(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canSetDefectStatusToDone'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateTextBricks(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateTextBricks');
  }
}
