import { autoinject, bindable } from 'aurelia-framework';
import { Report } from '../../classes/EntityManager/entities/Report/types';
import { ReportUtils } from '../../classes/EntityManager/entities/Report/ReportUtils';
import { Pagination } from '../pagination/pagination';
import { computed } from '../../hooks/computed';
import { isConnected } from '../../hooks/dependencies';
import { SocketService } from '../../services/SocketService';
import { ScrollHelper } from '../../classes/ScrollHelper';

@autoinject()
export class ReportList {
  @bindable() public reports: Array<Report> = [];

  /**
   * Translation key for the text that is displayed when no reports are available
   * (or, in some cases, when no reports are available offline).
   */
  @bindable() public noReportsAvailableTKey: string =
    'aureliaComponents.reportList.noReportsAvailable';

  protected paginationViewModel: Pagination<Report> | null = null;

  constructor(private readonly socketService: SocketService) {}

  protected reportSortOptions = ReportUtils.sortOptions;
  protected currentSortOption = this.reportSortOptions.exported;

  protected sortedReports: Array<Report> = [];
  protected currentPageReports: Array<Report> = [];

  public async scrollToReport(report: Report): Promise<void> {
    return ScrollHelper.autoScrollToListItem(
      '#' + this.getReportListItemId(report.id),
      this.paginationViewModel,
      report
    );
  }

  private getReportListItemId(reportId: string): string {
    return 'report-list--report-' + reportId;
  }

  @computed(isConnected())
  protected get isConnected(): boolean {
    return this.socketService.isConnected();
  }
}
