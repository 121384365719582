import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskMeasurePointReading } from '../../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { ProcessTaskMeasurePointReadingItem } from '../../process-task-measure-point-reading-item/process-task-measure-point-reading-item';

@autoinject()
export class ProcessTaskMeasurePointListItemReading {
  @bindable()
  public processTaskMeasurePointReading: ProcessTaskMeasurePointReading | null =
    null;

  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @subscribableLifecycle()
  protected processTaskMeasurePointReadingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskMeasurePointReading];

  protected processTaskMeasurePointReadingItem: ProcessTaskMeasurePointReadingItem | null =
    null;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processTaskMeasurePointReadingPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskMeasurePointReading,
        context: this as ProcessTaskMeasurePointListItemReading,
        propertyName: 'processTaskMeasurePointReading'
      });
  }

  public focus(): void {
    this.processTaskMeasurePointReadingItem?.focus();
  }

  protected handleDeleteClick(): void {
    const processTaskMeasurePointReading = this.processTaskMeasurePointReading;
    assertNotNullOrUndefined(
      processTaskMeasurePointReading,
      "can't ProcessTaskMeasurePointListItemReading.handleDeleteClick without processTaskMeasurePointReading"
    );

    void Dialogs.deleteDialogTk(
      'operations.processTaskMeasurePointListWidget.measurePointReadingDeleteConfirmation'
    ).then(() => {
      this.entityManager.processTaskMeasurePointReadingRepository.delete(
        processTaskMeasurePointReading
      );
    });
  }
}
