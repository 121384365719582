import { bindable } from 'aurelia-framework';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

/**
 * @attribute data-background - supported values 'white'
 * @attribute data-fill - scales the preloader indicator to fill the whole space of the circle-preloader
 */
export class CirclePreloader {
  @bindable public forceRunning = false;
  @bindable public wrapperSideLength: string | null = null;

  private loadingCount = 0;

  /**
   * you have to call stop for every start call
   */
  public start(): void {
    this.loadingCount++;
  }

  public stop(): void {
    if (this.loadingCount > 0) this.loadingCount--;
  }

  /**
   * call reset instead of stop if you don't want the counting functionality
   */
  public reset(): void {
    this.loadingCount = 0;
  }

  @computed(expression('wrapperSideLength'))
  protected get wrapperStyle(): { width: string; height: string } | null {
    if (this.wrapperSideLength == null) return null;
    return {
      width: `${this.wrapperSideLength}px`,
      height: `${this.wrapperSideLength}px`
    };
  }
}
