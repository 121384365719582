import { bindable, autoinject } from 'aurelia-framework';

import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Utils } from '../../classes/Utils/Utils';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import {
  StateConfig,
  ValueChangedEvent
} from '../../inputComponents/multistate-checkbox/multistate-checkbox';
import {
  ProcessConfigurationStepBarDimension,
  ProcessConfigurationStepBarDimensionCalculator
} from './ProcessConfigurationStepBarDimensionCalculator';

/**
 * @event {ProcessConfigurationStepClickedEvent} process-configuration-step-clicked
 * @event {StepIdsChangedEvent} step-ids-changed - fired when either the selectedStepIds or the excludedStepIds changed
 *
 * @attribute data-header-style - no activeStep will be shown and "inactive" steps will not be displayed transparently
 * @attribute data-no-scroll - disables the scrolling functionality
 */
@autoinject()
export class ProcessConfigurationStepBar {
  @bindable()
  public processConfigurationId: string | null = null;

  @bindable()
  public activeStepId: string | null = null;

  /**
   * set this to true to automatically cycle through the steps and modify the activeStepId accordingly
   */
  @bindable()
  public previewMode: boolean = false;

  /**
   * only works when stepCheckboxesEnabled is true
   */
  @bindable()
  public selectedStepIds: Array<string> = [];

  /**
   * only works when stepCheckboxesEnabled is true
   *
   * @type {Array<string>}
   */
  @bindable()
  public excludedStepIds: Array<string> = [];

  /**
   * enables little checkboxes for each processConfigurationStep
   * which checkboxes are checked and not can be read from the selectedStepIds
   */
  @bindable()
  public stepSelectionEnabled: boolean = false;

  /**
   * activates the clicking on the step feature
   *
   * this can't be controlled with stepClickEnabled only, because then the not-allowed cursor would be shown in cases where the clicking on the step feature is not used
   */
  @bindable()
  public stepClickActivated: boolean = false;

  /**
   * enables clicking on the step icons
   */
  @bindable()
  public stepClickEnabled: boolean = false;

  /**
   * readonly
   */
  @bindable()
  public dimension: ProcessConfigurationStepBarDimension =
    ProcessConfigurationStepBarDimensionCalculator.getFallbackDimension();

  private readonly subscriptionManager: SubscriptionManager;
  private readonly calculator: ProcessConfigurationStepBarDimensionCalculator;

  private processConfigurationSteps: Array<ProcessConfigurationStep> = [];
  private isAttached: boolean = false;
  private previewModeIntervalHandle: number | null = null;
  protected stepGroups: Array<ProcessConfigurationStepBarGroup> = [];
  protected activeStepIndicatorLeftOffset: number | null = null;

  protected contentScrollContainerContentElement: HTMLElement | null = null;
  protected activeStepIndicatorElement: HTMLElement | null = null;

  protected stepSelectionStates: Array<StateConfig<null | boolean>> = [
    {
      name: 'none',
      value: null
    },
    {
      name: 'selected',
      value: true,
      iconConfig: {
        iconType: 'far',
        iconName: 'fa-check'
      }
    },
    {
      name: 'unselected',
      value: false,
      iconConfig: {
        iconType: 'far',
        iconName: 'fa-minus'
      }
    }
  ];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.calculator = new ProcessConfigurationStepBarDimensionCalculator({
      getContentScrollContainerContentElement: () => {
        assertNotNullOrUndefined(
          this.contentScrollContainerContentElement,
          'no contentScrollContainerContentElement available'
        );
        return this.contentScrollContainerContentElement;
      }
    });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationStep,
      this.updateProcessConfigurationSteps.bind(this)
    );
    this.updateProcessConfigurationSteps();

    this.calculator.attached();
    this.subscriptionManager.addDisposable(
      this.calculator.bindDimension((dimension) => {
        this.dimension = dimension;
      })
    );

    if (this.previewMode) {
      this.startPreviewMode();
    }
  }

  protected detached(): void {
    this.isAttached = false;

    this.calculator.detached();

    this.stopPreviewMode();
    this.subscriptionManager.disposeSubscriptions();
  }

  protected previewModeChanged(): void {
    if (this.isAttached) {
      if (this.previewMode) {
        this.startPreviewMode();
      } else {
        this.stopPreviewMode();
      }
    }
  }

  protected processConfigurationIdChanged(): void {
    if (this.isAttached) {
      this.updateProcessConfigurationSteps();
    }
  }

  protected activeStepIdChanged(): void {
    if (this.isAttached) {
      this.updateActiveStepIndicatorPosition();
    }
  }

  private updateProcessConfigurationSteps(): void {
    if (this.processConfigurationId) {
      this.processConfigurationSteps =
        this.entityManager.processConfigurationStepRepository.getOrderedProcessConfigurationStepsByProcessConfigurationId(
          this.processConfigurationId
        );
    } else {
      this.processConfigurationSteps = [];
    }
    this.updateStepGroups();
  }

  private updateStepGroups(): void {
    this.stepGroups = this.groupProcessConfigurationSteps(
      this.processConfigurationSteps
    );

    setTimeout(() => {
      this.updateActiveStepIndicatorPosition();
    }, 10); // time to update for the view
  }

  private updateActiveStepIndicatorPosition(): void {
    if (!this.contentScrollContainerContentElement) {
      return;
    }

    const stepElement = this.contentScrollContainerContentElement.querySelector(
      `[data-step-id="${this.activeStepId}"]`
    ) as HTMLElement | null;
    if (stepElement) {
      const stepElementOffset = Utils.getRelativeElementOffset(
        stepElement,
        this.contentScrollContainerContentElement
      );
      const stepElementComputed = window.getComputedStyle(stepElement);

      assertNotNullOrUndefined(
        this.activeStepIndicatorElement,
        'cannot update active step indicator position without _activeStepIndicatorElement'
      );
      const indicatorComputed = window.getComputedStyle(
        this.activeStepIndicatorElement
      );

      const stepElementWidth = parseFloat(stepElementComputed.width || '0');
      const indicatorElementWidth = parseFloat(indicatorComputed.width || '0');
      this.activeStepIndicatorLeftOffset =
        stepElementOffset.left + (stepElementWidth - indicatorElementWidth) / 2;
    } else {
      this.activeStepIndicatorLeftOffset = 0;
    }
  }

  protected handleProcessConfigurationStepClick(
    step: ProcessConfigurationStep
  ): void {
    if (!this.stepClickActivated || !this.stepClickEnabled) {
      return;
    }

    DomEventHelper.fireEvent<ProcessConfigurationStepClickedEvent>(
      this.element,
      {
        name: 'process-configuration-step-clicked',
        detail: {
          processConfigurationStep: step
        }
      }
    );
  }

  protected handleStepCheckboxValueChanged(
    event: ValueChangedEvent<null | boolean>,
    step: ProcessConfigurationStep
  ): void {
    switch (event.detail.value) {
      case true:
        ArrayUtils.pushUnique(this.selectedStepIds, step.id);
        ArrayUtils.remove(this.excludedStepIds, step.id);
        break;

      case false:
        ArrayUtils.remove(this.selectedStepIds, step.id);
        ArrayUtils.pushUnique(this.excludedStepIds, step.id);
        break;

      case null:
        ArrayUtils.remove(this.selectedStepIds, step.id);
        ArrayUtils.remove(this.excludedStepIds, step.id);
        break;

      default:
        throw new Error(`unhandled value ${event.detail.value}`);
    }

    DomEventHelper.fireEvent(this.element, {
      name: 'step-ids-changed',
      detail: null
    });
  }

  private startPreviewMode(): void {
    this.stopPreviewMode();
    setInterval(() => {
      let index =
        this.processConfigurationSteps.findIndex(
          (step) => step.id === this.activeStepId
        ) + 1;
      if (index >= this.processConfigurationSteps.length) {
        index = 0;
      }

      this.activeStepId = this.processConfigurationSteps[index]?.id ?? null;
    }, 1000);
  }

  private stopPreviewMode(): void {
    if (this.previewModeIntervalHandle) {
      clearInterval(this.previewModeIntervalHandle);
    }
  }

  private groupProcessConfigurationSteps(
    steps: Array<ProcessConfigurationStep>
  ): Array<ProcessConfigurationStepBarGroup> {
    const groups: Array<ProcessConfigurationStepBarGroup> = [];

    for (const step of steps) {
      let group = groups.find((g) => g.groupName === step.groupName);

      if (!group) {
        group = {
          groupName: step.groupName || '',
          steps: []
        };
        groups.push(group);
      }

      group.steps.push(step);
    }

    return groups;
  }

  protected stepsContainActiveStep(
    steps: Array<ProcessConfigurationStep>,
    activeStepId: string | null
  ): boolean {
    return steps.some((step) => step.id === activeStepId);
  }

  protected getValueForStepCheckbox(
    stepId: string,
    selectedStepIds: Array<string>,
    excludedStepIds: Array<string>,
    _selectedStepIdsLength: number,
    _excludedStepIdsLength: number
  ): boolean | null {
    const selectedStepIdIndex = selectedStepIds.indexOf(stepId);
    const excludedStepIdIndex = excludedStepIds.indexOf(stepId);
    if (excludedStepIdIndex >= 0) {
      return false;
    } else if (selectedStepIdIndex >= 0) {
      return true;
    }
    return null;
  }
}

type ProcessConfigurationStepBarGroup = {
  groupName: string;
  steps: Array<ProcessConfigurationStep>;
};

export type ProcessConfigurationStepClickedEvent = NamedCustomEvent<
  'process-configuration-step-clicked',
  { processConfigurationStep: ProcessConfigurationStep }
>;

export type StepIdsChangedEvent = NamedCustomEvent<'step-ids-changed', null>;
