import { autoinject } from 'aurelia-framework';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { ProcessTaskPositionEditWidget } from '../process-task-position-edit-widget/process-task-position-edit-widget';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';

@autoinject()
export class EditProcessTaskPositionDialog {
  private position: ProcessTaskPosition | null = null;
  private onDialogClosed: (() => void) | null = null;

  protected dialog: RecordItDialog | null = null;
  protected positionEditWidgetViewModel: ProcessTaskPositionEditWidget | null =
    null;

  public open(options: EditProcessTaskPositionDialogOpenOptions): void {
    assertNotNullOrUndefined(this.dialog, "can't open without a dialog");

    this.position = options.position;
    this.onDialogClosed = options.onDialogClosed ?? null;

    this.dialog.open();
  }

  protected handleDialogOpened(): void {
    this.positionEditWidgetViewModel?.focusInput();
  }

  protected handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;

    this.position = null;
    this.onDialogClosed = null;

    onClosed && onClosed();
  }

  public static async open(
    options: EditProcessTaskPositionDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

export type EditProcessTaskPositionDialogOpenOptions = {
  position: ProcessTaskPosition;
  onDialogClosed?: (() => void) | null;
};
