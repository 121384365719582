import { autoinject, bindable } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { TitleThingPicture } from '../../classes/EntityManager/entities/Picture/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PictureSelectAndEditDialog } from '../../dialogs/picture-select-and-edit-dialog/picture-select-and-edit-dialog';
import { ThingProperty } from '../../classes/EntityManager/entities/Property/types';
import { Dialogs } from '../../classes/Dialogs';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EditCatastropheContactsDialog } from '../../dialogs/edit-catastrophe-contacts-dialog/edit-catastrophe-contacts-dialog';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { EditThingPropertiesDialog } from '../../dialogs/edit-thing-properties-dialog/edit-thing-properties-dialog';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { ProjectCreationService } from '../../classes/EntityManager/entities/Project/ProjectCreationService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ProjectActionService } from '../../classes/EntityManager/entities/Project/ProjectActionService';
import { SocketService } from '../../services/SocketService';
import { ChecklistProjectNameUtils } from 'common/Checklist/ChecklistProjectNameUtils';
import { EnterProjectClickedEvent } from '../../checklistComponents/checklist-project-list-item/checklist-project-list-item';
import { CreateChecklistEntitiesService } from '../../services/CreateChecklistEntitiesService';
import { QuestionCatalogueService } from '../../classes/EntityManager/entities/QuestionCatalogue/QuestionCatalogueService';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { CatastropheProjectWidgetAdapter } from '../../homePageComponents/EntityWidgetAdapter/CatastropheProjectWidgetAdapter';
import { CatastropheDefectWidgetAdapter } from '../../homePageComponents/EntityWidgetAdapter/CatastropheDefectWidgetAdapter';
import { DefectActionService } from '../../classes/EntityManager/entities/Defect/DefectActionService';
import { DefectCreationService } from '../../classes/EntityManager/entities/Defect/DefectCreationService';
import { DefectStatus } from 'common/Enums/DefectStatus';
import { KukRemindersService } from '../../services/KukRemindersService';
import { CreateProjectClickedEvent } from '../../aureliaComponents/create-project-button/create-project-button';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { AssignReportTypeToProjectService } from '../../classes/EntityManager/entities/Project/AssignReportTypeToProjectService';
import { DateUtils } from 'common/DateUtils';
import { EditProjectDialog } from '../../dialogs/edit-project-dialog/edit-project-dialog';
import { Report } from '../../classes/EntityManager/entities/Report/types';
import { ReportList } from '../../aureliaComponents/report-list/report-list';

@autoinject()
export class CatastropheOverview {
  @bindable public thing: Thing | null = null;

  protected reportList: ReportList | null = null;

  @subscribableLifecycle()
  protected thingPermissionHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  protected catastropheProjectWidgetAdapter: CatastropheProjectWidgetAdapter;

  protected catastropheDefectWidgetAdapter: CatastropheDefectWidgetAdapter;

  protected ProjectType = ProjectType;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly projectCreationService: ProjectCreationService,
    private readonly createChecklistEntitiesService: CreateChecklistEntitiesService,
    private readonly questionCatalogueService: QuestionCatalogueService,
    private readonly i18n: I18N,
    private readonly router: Router,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly defectCreationService: DefectCreationService,
    private readonly kukRemindersService: KukRemindersService,
    private readonly assignReportTypeToProjectService: AssignReportTypeToProjectService,
    permissionsService: PermissionsService,
    currentUserService: CurrentUserService,
    projectActionService: ProjectActionService,
    defectActionService: DefectActionService,
    socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.thingPermissionHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Thing,
        context: this as CatastropheOverview,
        propertyName: 'thing'
      });

    this.catastropheProjectWidgetAdapter = new CatastropheProjectWidgetAdapter({
      activeUserCompanySettingService: activeUserCompanySettingService,
      currentUserService: currentUserService,
      entityManager: entityManager,
      projectActionService: projectActionService,
      socketService: socketService,
      subscriptionManagerService: subscriptionManagerService,
      permissionsService,
      getThingId: () => this.thing?.id ?? null,
      createSubscriptionsForThingId: (subscriptionManager, updateItems) => {
        subscriptionManager.subscribeToExpression(
          this,
          'thing.id',
          updateItems
        );
      }
    });

    this.catastropheDefectWidgetAdapter = new CatastropheDefectWidgetAdapter({
      activeUserCompanySettingService: activeUserCompanySettingService,
      currentUserService: currentUserService,
      entityManager: entityManager,
      defectActionService: defectActionService,
      socketService: socketService,
      subscriptionManagerService: subscriptionManagerService,
      permissionsService: permissionsService,
      router: this.router,
      getThingId: () => this.thing?.id ?? null,
      createSubscriptionsForThingId: (subscriptionManager, updateItems) => {
        subscriptionManager.subscribeToExpression(
          this,
          'thing.id',
          updateItems
        );
      }
    });
  }

  public async scrollToReport(report: Report): Promise<void> {
    assertNotNullOrUndefined(
      this.reportList,
      'cannot goToReport without reportList'
    );

    return this.reportList.scrollToReport(report);
  }

  protected handleMainTitlePictureClicked(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleMainTitlePictureClicked without thing'
    );

    void PictureSelectAndEditDialog.open({
      canCreatePictures: true,
      mainEntityIdField: 'ownerThingId',
      mainEntityId: this.thing.id,
      subEntityField: 'titleThingId',
      subEntityValue: this.thing.id,
      ownerUserGroupId: this.thing.ownerUserGroupId
    });
  }

  protected handleObjectPropertiesEditButtonClicked(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleObjectPropertiesEditButtonClicked without thing'
    );

    void EditThingPropertiesDialog.open({
      thing: this.thing
    });
  }

  protected handleMessageContactsButtonClicked(): void {
    // TODO: KuK Message contacts
    void Dialogs.todoDialog();
  }

  protected handleContactsEditButtonClicked(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleContactsEditButtonClicked without thing'
    );

    void EditCatastropheContactsDialog.open({
      thing: this.thing
    });
  }

  protected handleCreateNewDefectClicked(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleCreateNewDefectClicked without thing'
    );

    this.defectCreationService.createDefect({
      name: this.i18n.tr<string>(
        'kukComponents.catastropheOverview.newEntityNames.defect'
      ),
      description: '',
      dueAt: null,
      status: DefectStatus.OPEN,
      ownerThingId: this.thing.id,
      ownerUserGroupId: this.thing.ownerUserGroupId
    });
  }

  protected handleCreateNewProjectClicked(
    event: CreateProjectClickedEvent
  ): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleCreateNewProjectClicked without thing'
    );

    let project: Project;
    const date = new Date();
    const projectName = [
      DateUtils.formatToDateString(date),
      DateUtils.formatToHourMinuteString(date),
      event.detail.reportType?.name
    ]
      .filter((n): n is string => !!n)
      .join('-');
    if (event.detail.projectType === ProjectType.BASIC) {
      project = this.projectCreationService.createProject({
        thing: this.thing,
        projectType: event.detail.projectType,
        reportType: event.detail.reportType ?? null,
        name: projectName
      });
    } else {
      project = this.projectCreationService.createProject({
        thing: this.thing,
        projectType: event.detail.projectType,
        structureTemplateId: event.detail.structureTemplate.id,
        reportType: event.detail.reportType ?? null,
        name: projectName
      });
    }

    if (event.detail.reportType) {
      this.assignReportTypeToProjectService.assignReportTypeToProject(
        project,
        event.detail.reportType
      );
    }

    void EditProjectDialog.open({
      project
    });
  }

  protected handleCreateChecklistProjectButtonClicked(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleCreateProjectButtonClicked without thing'
    );

    this.projectCreationService.createProject({
      thing: this.thing,
      name: ChecklistProjectNameUtils.fromDateWithTime(new Date()),
      projectType: ProjectType.CHECKLIST,
      reportType: null
    });
  }

  protected handleProjectClicked(event: EnterProjectClickedEvent): void {
    if (
      this.createChecklistEntitiesService.hasChecklistData(event.detail.project)
    ) {
      this.router.navigateToRoute('checklist_inspection', {
        project_id: event.detail.project.id
      });
    } else {
      const questionCatalogueId =
        this.activeUserCompanySettingService.getSettingProperty(
          'kuk.questionCatalogueId'
        ) ?? null;
      const questionCatalogue = questionCatalogueId
        ? this.entityManager.questionCatalogueRepository.getById(
            questionCatalogueId
          )
        : null;

      if (!questionCatalogue) {
        void Dialogs.errorDialogTk(
          'kukComponents.catastropheOverview.checklistProjectList.errors.questionCatalogueId.title',
          'kukComponents.catastropheOverview.checklistProjectList.errors.questionCatalogueId.description'
        );
        return;
      }

      try {
        this.createChecklistEntitiesService.create(event.detail.project, [
          questionCatalogue
        ]);
      } catch (e) {
        const message = e instanceof Error ? e.message : `${e}`;
        void Dialogs.errorDialog(
          this.i18n.tr(
            'checklistComponents.checklistProjectListItem.errors.entityCreation'
          ),
          message
        );
      }

      this.router.navigateToRoute('checklist_inspection', {
        project_id: event.detail.project.id
      });
    }
  }

  @computed(expression('thing'), model(EntityName.Picture))
  protected get titlePictures(): Array<TitleThingPicture> {
    if (!this.thing) return [];

    return this.entityManager.pictureRepository.getByTitleThingId(
      this.thing.id
    );
  }

  @computed(expression('titlePictures'))
  protected get mainTitlePicture(): TitleThingPicture | null {
    return (
      this.titlePictures.find((p) => p.selected) ??
      this.titlePictures[0] ??
      null
    );
  }

  @computed(expression('thing'), model(EntityName.Property))
  protected get catastropheProperties(): Array<ThingProperty> {
    if (!this.thing) return [];

    return this.entityManager.propertyRepository.getByThingId(this.thing.id);
  }

  @computed(
    expression('thing'),
    model(EntityName.Person),
    model(EntityName.ThingToPerson)
  )
  protected get catastropheContacts(): Array<Person> {
    if (!this.thing) return [];
    const thingToPersons =
      this.entityManager.thingToPersonRepository.getByThingId(this.thing.id);
    return this.entityManager.personRepository.getByIds(
      thingToPersons.map((ttp) => ttp.personId)
    );
  }

  @computed(expression('thing'), ...KukRemindersService.getByThingDependencies)
  protected get reminders(): Array<string> {
    if (!this.thing) return [];
    return this.kukRemindersService.getByThingId(this.thing.id);
  }

  @computed(expression('thing.id'), model(EntityName.Project))
  protected get projectsOfThing(): Array<Project> {
    if (!this.thing) return [];
    return this.entityManager.projectRepository.getByThingId(this.thing.id);
  }

  @computed(
    expression('projectsOfThing'),
    expression('thing.id'),
    model(EntityName.Report)
  )
  protected get reports(): Array<Report> {
    return [
      ...this.entityManager.reportRepository.getByProjectIds(
        this.projectsOfThing.map((p) => p.id)
      ),
      ...(this.thing?.id
        ? this.entityManager.reportRepository.getByThingId(this.thing.id)
        : [])
    ];
  }
}
