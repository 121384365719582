/*
  THIS FILE IS AUTOMATICALLY CREATED BY A GENERATION SCRIPT.
  Manual changes will not be persisted.
*/

export enum EntityName {
  Defect = 'DefectModel',
  DefectChangeLogEntry = 'DefectChangeLogEntryModel',
  DefectComment = 'DefectCommentModel',
  EntityDashboardInfo = 'EntityDashboardInfoModel',
  Entry = 'EntryModel',
  EntryToPerson = 'EntryToPersonModel',
  EntryUsageStatistic = 'EntryUsageStatisticModel',
  ExternalUserCredential = 'ExternalUserCredentialModel',
  GeneralFile = 'GeneralFileModel',
  MapLayer = 'MapLayerModel',
  NfcToken = 'NfcTokenModel',
  NfcTokenToPerson = 'NfcTokenToPersonModel',
  NfcTokenToThing = 'NfcTokenToThingModel',
  Person = 'PersonModel',
  PersonContact = 'PersonContactModel',
  PersonToPerson = 'PersonToPersonModel',
  Picture = 'PictureModel',
  PictureFile = 'PictureFileModel',
  PictureRevision = 'PictureRevisionModel',
  ProcessConfiguration = 'ProcessConfigurationModel',
  ProcessConfigurationActionStatus = 'ProcessConfigurationActionStatusModel',
  ProcessConfigurationAuthorizationType = 'ProcessConfigurationAuthorizationTypeModel',
  ProcessConfigurationCategory = 'ProcessConfigurationCategoryModel',
  ProcessConfigurationDevice = 'ProcessConfigurationDeviceModel',
  ProcessConfigurationDeviceExport = 'ProcessConfigurationDeviceExportModel',
  ProcessConfigurationFollowUpAppointment = 'ProcessConfigurationFollowUpAppointmentModel',
  ProcessConfigurationForm = 'ProcessConfigurationFormModel',
  ProcessConfigurationFormSendTarget = 'ProcessConfigurationFormSendTargetModel',
  ProcessConfigurationMeasurePointType = 'ProcessConfigurationMeasurePointTypeModel',
  ProcessConfigurationNoteCategory = 'ProcessConfigurationNoteCategoryModel',
  ProcessConfigurationStep = 'ProcessConfigurationStepModel',
  ProcessConfigurationStepAutoAppointment = 'ProcessConfigurationStepAutoAppointmentModel',
  ProcessConfigurationStepAutoForm = 'ProcessConfigurationStepAutoFormModel',
  ProcessConfigurationStepPosition = 'ProcessConfigurationStepPositionModel',
  ProcessTask = 'ProcessTaskModel',
  ProcessTaskAppointment = 'ProcessTaskAppointmentModel',
  ProcessTaskAppointmentContact = 'ProcessTaskAppointmentContactModel',
  ProcessTaskAppointmentToProcessTaskDevice = 'ProcessTaskAppointmentToProcessTaskDeviceModel',
  ProcessTaskAppointmentToProcessTaskPosition = 'ProcessTaskAppointmentToProcessTaskPositionModel',
  ProcessTaskAppointmentToUser = 'ProcessTaskAppointmentToUserModel',
  ProcessTaskChecklistEntry = 'ProcessTaskChecklistEntryModel',
  ProcessTaskComment = 'ProcessTaskCommentModel',
  ProcessTaskDevice = 'ProcessTaskDeviceModel',
  ProcessTaskGroup = 'ProcessTaskGroupModel',
  ProcessTaskGroupAuthorization = 'ProcessTaskGroupAuthorizationModel',
  ProcessTaskGroupFilter = 'ProcessTaskGroupFilterModel',
  ProcessTaskInvoice = 'ProcessTaskInvoiceModel',
  ProcessTaskInvoiceToProcessTask = 'ProcessTaskInvoiceToProcessTaskModel',
  ProcessTaskInvoiceToProcessTaskDevice = 'ProcessTaskInvoiceToProcessTaskDeviceModel',
  ProcessTaskInvoiceToProcessTaskPosition = 'ProcessTaskInvoiceToProcessTaskPositionModel',
  ProcessTaskLogEntry = 'ProcessTaskLogEntryModel',
  ProcessTaskMeasurePoint = 'ProcessTaskMeasurePointModel',
  ProcessTaskMeasurePointReading = 'ProcessTaskMeasurePointReadingModel',
  ProcessTaskMeasurePointToPicture = 'ProcessTaskMeasurePointToPictureModel',
  ProcessTaskNote = 'ProcessTaskNoteModel',
  ProcessTaskOffer = 'ProcessTaskOfferModel',
  ProcessTaskOfferToProcessTask = 'ProcessTaskOfferToProcessTaskModel',
  ProcessTaskOfferToProcessTaskDevice = 'ProcessTaskOfferToProcessTaskDeviceModel',
  ProcessTaskOfferToProcessTaskPosition = 'ProcessTaskOfferToProcessTaskPositionModel',
  ProcessTaskPosition = 'ProcessTaskPositionModel',
  ProcessTaskPositionDetailEntry = 'ProcessTaskPositionDetailEntryModel',
  ProcessTaskRecurringAppointment = 'ProcessTaskRecurringAppointmentModel',
  ProcessTaskToProject = 'ProcessTaskToProjectModel',
  Project = 'ProjectModel',
  ProjectQuestion = 'ProjectQuestionModel',
  ProjectQuestionCatalogue = 'ProjectQuestionCatalogueModel',
  ProjectQuestionCatalogueToQuestionSet = 'ProjectQuestionCatalogueToQuestionSetModel',
  ProjectQuestionCategory = 'ProjectQuestionCategoryModel',
  ProjectQuestionSet = 'ProjectQuestionSetModel',
  Property = 'PropertyModel',
  PropertyToPerson = 'PropertyToPersonModel',
  PropertyToProcessTaskPosition = 'PropertyToProcessTaskPositionModel',
  Question = 'QuestionModel',
  QuestionCatalogue = 'QuestionCatalogueModel',
  QuestionCatalogueToQuestionSet = 'QuestionCatalogueToQuestionSetModel',
  QuestionCatalogueToThing = 'QuestionCatalogueToThingModel',
  QuestionCategory = 'QuestionCategoryModel',
  QuestionSet = 'QuestionSetModel',
  Region = 'RegionModel',
  Report = 'ReportModel',
  ReportType = 'ReportTypeModel',
  SharepointListColumnToThingMappingItem = 'SharepointListColumnToThingMappingItemModel',
  StructurePictureArea = 'StructurePictureAreaModel',
  StructureTemplate = 'StructureTemplateModel',
  StructureTemplateEntry = 'StructureTemplateEntryModel',
  StructureTemplateEntryGroup = 'StructureTemplateEntryGroupModel',
  StructureTemplateEntryGroupToStructureTemplateEntry = 'StructureTemplateEntryGroupToStructureTemplateEntryModel',
  StructureTemplateEntryProperty = 'StructureTemplateEntryPropertyModel',
  StructureTemplateRatingCategory = 'StructureTemplateRatingCategoryModel',
  Tag = 'TagModel',
  TextBrick = 'TextBrickModel',
  TextBrickTemplate = 'TextBrickTemplateModel',
  Thing = 'ThingModel',
  ThingAuthorization = 'ThingAuthorizationModel',
  ThingGroup = 'ThingGroupModel',
  ThingSection = 'ThingSectionModel',
  ThingSectionConfigProperty = 'ThingSectionConfigPropertyModel',
  ThingToPerson = 'ThingToPersonModel',
  ThingType = 'ThingTypeModel',
  User = 'UserModel',
  UserCompanySetting = 'UserCompanySettingModel',
  UserDefinedEntity = 'UserDefinedEntityModel',
  UserDefinedEntityConfig = 'UserDefinedEntityConfigModel',
  UserDefinedEntityConfigPropertyConfig = 'UserDefinedEntityConfigPropertyConfigModel',
  UserGroup = 'UserGroupModel',
  UserRole = 'UserRoleModel',
  UserRoleToUser = 'UserRoleToUserModel',
  ValueCalculationConfig = 'ValueCalculationConfigModel',
  ValueCalculationResult = 'ValueCalculationResultModel'
}
