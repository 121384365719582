import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

export class JoinedProjectHandle {
  private readonly project: Project;
  private onStatusChanged: OnStatusChanged;

  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManager: SubscriptionManager;
  private readonly socketService: SocketService;

  private currentStatus: ProjectStatus = ProjectStatus.NOT_AVAILABLE;
  private cancelReceived = false;

  constructor(options: JoinedProjectHandleOptions) {
    this.project = options.project;
    this.entityManager = options.entityManager;
    this.subscriptionManager = options.subscriptionManagerService.create();
    this.socketService = options.socketService;

    this.onStatusChanged = options.onStatusChanged;
  }

  public ensureProjectDataIsAvailable(): void {
    if (!this.socketService.isConnected()) {
      this.setStatus(ProjectStatus.CLIENT_IS_OFFLINE);
      return;
    }

    const actualizedAfterJoining =
      this.entityManager.projectMetadataManager.projectWasActualizedAfterJoining(
        this.project.id
      );
    if (actualizedAfterJoining) {
      this.setStatus(ProjectStatus.LOCALLY_AVAILABLE);
      return;
    }

    const currentlyJoined =
      this.entityManager.joinedProjectsManager.projectIsJoined(this.project.id);

    if (currentlyJoined) {
      this.setStatus(ProjectStatus.IS_JOINED);
      return;
    }

    void this.entityManager.joinedProjectsManager.joinProject(
      this.project.id,
      true,
      true
    );
    this.setStatus(ProjectStatus.JOIN_REQUEST_SENT);
  }

  public subscribe(): Disposable {
    this.subscriptionManager.addDisposable(
      this.entityManager.joinedProjectsManager.registerHooks({
        onJoinedStatusChanged: () => {
          if (!this.cancelReceived) this.onJoinedStatusChanged();
        }
      })
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entityActualization.registerHooks({
        afterActualization: ({ success }) => {
          if (success && !this.cancelReceived) this.hasActualized();
        }
      })
    );

    return {
      dispose: () => {
        this.subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public cancel(): void {
    this.cancelReceived = true;
  }

  private onJoinedStatusChanged(): void {
    if (this.currentStatus === ProjectStatus.JOIN_REQUEST_SENT) {
      if (
        this.entityManager.joinedProjectsManager.projectIsJoined(
          this.project.id
        )
      ) {
        this.setStatus(ProjectStatus.IS_JOINED);
      }
    }
  }

  private hasActualized(): void {
    if (this.currentStatus === ProjectStatus.IS_JOINED) {
      this.setStatus(ProjectStatus.LOCALLY_AVAILABLE);
    }
  }

  private setStatus(status: ProjectStatus): void {
    this.currentStatus = status;
    this.onStatusChanged(this.currentStatus);
  }
}

export type JoinedProjectHandleOptions = {
  project: Project;
  entityManager: AppEntityManager;
  socketService: SocketService;
  subscriptionManagerService: SubscriptionManagerService;
  onStatusChanged: OnStatusChanged;
};

export enum ProjectStatus {
  NOT_AVAILABLE = 'notAvailable',
  JOIN_REQUEST_SENT = 'joinRequestSent',
  IS_JOINED = 'isJoined',
  LOCALLY_AVAILABLE = 'locallyAvailable',
  CLIENT_IS_OFFLINE = 'clientIsOffline'
}

type OnStatusChanged = (state: ProjectStatus) => void;
