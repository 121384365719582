import { computedFrom } from 'aurelia-framework';
import { ProcessTaskGroup } from '../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskGroupAdapter } from '../EntityAdapter/ProcessTaskGroupAdapter/ProcessTaskGroupAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ProcessTaskGroupPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ProcessTaskGroupAdapter>,
  ProcessTaskGroup
> {
  @computedFrom('combinedRevision')
  public get canCreateProcessTaskGroupAuthorizations(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessTaskGroupAuthorizations'
    );
  }

  @computedFrom('combinedRevision')
  public get canSeeProcessTaskGroupAuthorizations(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canSeeProcessTaskGroupAuthorizations'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessTasks(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateProcessTasks');
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessTaskInvoices(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessTaskInvoices'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessTaskOffers(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessTaskOffers'
    );
  }

  @computedFrom(
    'canEditField.assignedFromUserId',
    'canEditField.assignedProcessTaskName',
    'canEditField.assigneeUserId',
    'canEditField.assigneeHint'
  )
  public get canSetAssignee(): boolean {
    return (
      this.canEditField.assignedFromUserId &&
      this.canEditField.assignedProcessTaskName &&
      this.canEditField.assigneeUserId &&
      this.canEditField.assigneeHint
    );
  }
}
