import * as Sentry from '@sentry/capacitor';

import { LoggerAdapter } from '../LoggerAdapter';
import { environment } from '../../../../environment';
import { User } from '../../../EntityManager/entities/User/types';
import { LogMessageTag } from '../../Logger';

export class SentryAdapter implements LoggerAdapter {
  constructor() {
    Sentry.init({
      dsn: 'https://c0c448e2f6994fee96afc5cf622bc90a@sentry.chax.at/8',
      release: environment.lastCommitHash,
      enabled: environment.enableSentryLogging,
      environment: 'production',
      normalizeDepth: 100,
      maxBreadcrumbs: 50,
      beforeBreadcrumb: (breadcrumb) => {
        if (
          breadcrumb.category !== 'console' ||
          !breadcrumb.data ||
          !Array.isArray(breadcrumb.data.arguments)
        ) {
          return breadcrumb;
        }

        const firstMessage = breadcrumb.data.arguments[0];
        if (
          typeof firstMessage === 'string' &&
          firstMessage.includes(LogMessageTag.TRUNCATE_IN_HISTORY)
        ) {
          return null;
        }

        return breadcrumb;
      }
    });
  }

  public setUser({ user }: { user: User }): void {
    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: user.username
    });
  }

  public logError({ error }: { error: Error }): void {
    Sentry.captureException(error);
  }
}
