import {
  ModuleName,
  RecordItModuleHelper
} from '../classes/RecordItModuleHelper';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Project } from '../classes/EntityManager/entities/Project/types';
import { CurrentUserService } from '../classes/EntityManager/entities/User/CurrentUserService';
import { PermissionHelper } from '../classes/PermissionHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { OpenThingOptions } from '../classes/EntityManager/entities/Thing/ThingActionService';
import { OpenProjectOptions } from '../classes/EntityManager/entities/Project/ProjectActionService';

@autoinject()
export class NavigationService {
  constructor(
    private readonly router: Router,
    private readonly currentUserService: CurrentUserService
  ) {}

  /**
   * Navigates to a thing WITHOUT setting thing metadata for the homepage.
   *
   * @internal
   * @see {ThingActionService.navigateToThing}
   */
  public navigateToThing(
    thingId: string,
    additionalRouteParams?: OpenThingOptions | null,
    projectType?: ProjectType
  ): void {
    const routeParams = { thing_id: thingId, ...additionalRouteParams };
    this.router.navigateToRoute(
      this.getThingRouteName(projectType),
      routeParams
    );
  }

  public navigateToProject(
    project: Project,
    additionalRouteParams?: OpenProjectOptions | null
  ): void {
    const routeName = this.getProjectPageUrl(
      project.id,
      project.projectType,
      additionalRouteParams
    );
    assertNotNullOrUndefined(
      routeName,
      'no route name for editing project received'
    );

    this.router.navigate(routeName);
  }

  public getExportPageUrl(project: Project): string {
    let routeName: string;

    switch (project.projectType) {
      default:
      case null:
      case RecordItModuleHelper.getProjectTypeForModuleName(ModuleName.BASIC):
        routeName = 'export_project';
        break;

      case RecordItModuleHelper.getProjectTypeForModuleName(ModuleName.B1300):
        routeName = 'export_b1300_project';
        break;

      case RecordItModuleHelper.getProjectTypeForModuleName(ModuleName.INSPECT):
        routeName = 'export_inspect_project';
        break;
    }

    return this.router.generate(routeName, { project_id: project.id });
  }

  public getProjectPageUrl(
    projectId: string,
    projectType: ProjectType,
    additionalRouteParams?: Object | null
  ): string | null {
    let routeName = null;
    let routeParams = null;

    switch (projectType) {
      default:
      case null:
      case RecordItModuleHelper.getProjectTypeForModuleName(ModuleName.BASIC):
        routeName = 'project';
        routeParams = { project_id: projectId };
        break;

      case RecordItModuleHelper.getProjectTypeForModuleName(ModuleName.B1300):
        routeName = 'edit_b1300_project';
        routeParams = { project_id: projectId };
        break;

      case RecordItModuleHelper.getProjectTypeForModuleName(ModuleName.INSPECT):
        routeName = 'edit_inspect_project';
        routeParams = { project_id: projectId };
        break;
    }

    if (routeName) {
      return this.router.generate(routeName, {
        ...routeParams,
        ...additionalRouteParams
      });
    }

    return null;
  }

  public getThingPageUrl(
    thingId: string,
    additionalRouteParams?: Object | null
  ): string {
    const routeName = this.getThingRouteName();
    const routeParams = { thing_id: thingId, ...additionalRouteParams };
    return this.router.generate(routeName, routeParams);
  }

  public getThingRouteName(projectType = ProjectType.BASIC): string {
    if (
      PermissionHelper.userHasPermissionForModule(
        this.currentUserService.getCurrentUser(),
        ModuleName.KUK
      )
    ) {
      return 'edit_catastrophe';
    } else if (
      PermissionHelper.userHasPermissionForModule(
        this.currentUserService.getCurrentUser(),
        ModuleName.CHECKLIST
      )
    ) {
      return 'edit_checklist_object';
    } else if (projectType === ProjectType.GALLERY) {
      return 'edit_gallery_object';
    }

    return 'object';
  }
}
