import { bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { Disposable } from '../../classes/Utils/DisposableContainer';
import { PropertyDefinitionWidgetHandle } from '../property-definition-widget/PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import {
  isFromProviderAdapter,
  ManagePropertyDefinitionsWidgetAdapter
} from './ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsWidgetAdapter';
import { TPropertyProviderConfig } from './ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsFromProviderWidgetAdapter';

/**
 * A widget to update/add and delete definitions of properties for some entity.
 * For example, this is used to define the properties of thingTypes so they can later be applied to the thing when assigning the thingType.
 */
export class ManagePropertyDefinitionsWidget<
  TPropertyDefinition extends { order: number | null },
  TPropertyProviderDefinition = never
> {
  @bindable()
  public adapter: ManagePropertyDefinitionsWidgetAdapter<
    TPropertyDefinition,
    TPropertyProviderDefinition
  > | null = null;

  private adapterDisposable: Disposable | null = null;
  private canCreatePropertyDefinitions: boolean = false;

  private isAttached: boolean = false;
  protected propertyDefinitionHandles: Array<
    PropertyDefinitionWidgetHandle<TPropertyDefinition>
  > = [];

  protected selectedPropertyProvider: TPropertyProviderDefinition | null = null;
  protected propertyProviderConfigs: Array<
    TPropertyProviderConfig<TPropertyProviderDefinition>
  > = [];

  protected attached(): void {
    this.isAttached = true;

    this.subscribeToAdapter();
  }

  protected detached(): void {
    this.isAttached = false;

    this.unsubscribeFromAdapter();
  }

  protected adapterChanged(): void {
    if (this.isAttached) {
      this.subscribeToAdapter();
    }
  }

  private subscribeToAdapter(): void {
    this.unsubscribeFromAdapter();

    if (this.adapter) {
      this.adapterDisposable = this.adapter.subscribe({
        setPropertyDefinitionHandles: (propertyDefinitionHandles) => {
          this.propertyDefinitionHandles = propertyDefinitionHandles;
        },
        setPropertyProviderConfigs: (configs) => {
          this.propertyProviderConfigs = configs;
        },
        setCanCreatePropertyDefinitions: (canCreatePropertyDefinitions) => {
          this.canCreatePropertyDefinitions = canCreatePropertyDefinitions;
        }
      });
    }
  }

  private unsubscribeFromAdapter(): void {
    this.adapterDisposable?.dispose();
    this.adapterDisposable = null;
    this.propertyDefinitionHandles = [];
    this.canCreatePropertyDefinitions = false;
  }

  protected handleDeleteHandleClick(
    handle: PropertyDefinitionWidgetHandle<TPropertyDefinition>
  ): void {
    assertNotNullOrUndefined(
      this.adapter,
      "can't ManagePropertyDefinitionsWidget.handleDeleteHandleClick without adapter"
    );

    void this.adapter
      .deletePropertyDefinition({
        propertyDefinition: handle.getPropertyDefinition()
      })
      .then(() => {
        this.propertyDefinitionHandles = this.propertyDefinitionHandles.filter(
          (h) => h !== handle
        );
      });
  }

  protected handleAddButtonClick(): void {
    assertNotNullOrUndefined(
      this.adapter,
      "can't ManagePropertyDefinitionsWidget.handleAddButtonClick without adapter"
    );

    const lastPropertyDefinitionHandle =
      this.propertyDefinitionHandles[this.propertyDefinitionHandles.length - 1];

    this.propertyDefinitionHandles.push(
      this.adapter.createPropertyDefinition(
        lastPropertyDefinitionHandle?.getPropertyDefinition().order ?? 0
      )
    );
  }

  protected handleImportButtonClick(): void {
    assertNotNullOrUndefined(
      this.adapter,
      "can't ManagePropertyDefinitionsWidget.handleImportButtonClick without adapter"
    );

    // Cannot assertNotNullOrUndefined this because TS can't discriminate the types properly then
    if (!isFromProviderAdapter(this.adapter)) return;

    assertNotNullOrUndefined(
      this.selectedPropertyProvider,
      "can't ManagePropertyDefinitionsWidget.handleImportButtonClick without selectedPropertyProvider"
    );

    this.propertyDefinitionHandles.push(
      ...this.adapter.createPropertyDefinitionsFromProvider(
        this.selectedPropertyProvider
      )
    );
  }

  @computed(expression('propertyDefinitionHandles'))
  protected get duplicatePropertyDefinitionHandles(): Array<
    PropertyDefinitionWidgetHandle<TPropertyDefinition>
  > {
    return this.propertyDefinitionHandles.filter(
      (handle, index) =>
        index !==
        this.propertyDefinitionHandles.findIndex((h) => {
          const hPropertyDefinition = h.getPropertyDefinition();
          const handlePropertyDefinition = handle.getPropertyDefinition();

          return this.adapter?.arePropertyDefinitionsEqual(
            hPropertyDefinition,
            handlePropertyDefinition
          );
        })
    );
  }

  @computed(expression('adapter'))
  protected get currentAdapterIsFromProviderAdapter(): boolean {
    if (!this.adapter) return false;
    return isFromProviderAdapter(this.adapter);
  }
}
