import { bindable, Container, autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import {
  logEntryConfigurationByProcessTaskLogEntryAction,
  ProcessTaskLogEntryAction,
  ProcessTaskLogEntryActionInfoLogDataConverterInfo
} from 'common/processTaskLogEntryActions';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { logDataConverterTypeToConstructorMap } from './LogDataConverter/logDataConverterTypeToConstructorMap';
import { ProcessTaskLogEntry } from '../../classes/EntityManager/entities/ProcessTaskLogEntry/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { AbstractLogDataConverter } from './LogDataConverter/AbstractLogDataConverter';

/**
 * a component which displays a descriptive text for a processTaskLogEntry
 */
@autoinject()
export class ProcessTaskLogEntryText {
  @bindable()
  public processTaskLogEntry: ProcessTaskLogEntry | null = null;

  /**
   * the newest log entry should be at the start
   */
  @bindable()
  public sortedProcessTaskLogEntries: Array<ProcessTaskLogEntry> = [];

  private readonly subscriptionManager: SubscriptionManager;
  private readonly logDataConvertersSubscriptionManager: SubscriptionManager;

  private logDataConverterWithInfos: Array<{
    converter: AbstractLogDataConverter;
    info: ProcessTaskLogEntryActionInfoLogDataConverterInfo;
  }> = [];

  private isAttached: boolean = false;
  private text: string | null = null;
  private footer: string | null = null;

  constructor(
    private readonly container: Container,
    private readonly i18n: I18N,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.logDataConvertersSubscriptionManager =
      subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToEvent(
      'i18n:locale:changed',
      this.updateTranslations.bind(this)
    );
    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'sortedProcessTaskLogEntries',
      this.updateTranslations.bind(this)
    );
    this.updateLogDataConverterWithInfos();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
    this.logDataConvertersSubscriptionManager.disposeSubscriptions();
  }

  protected processTaskLogEntryChanged(): void {
    if (this.isAttached) {
      this.updateLogDataConverterWithInfos();
    }
  }

  private updateLogDataConverterWithInfos(): void {
    if (this.processTaskLogEntry) {
      const configuration =
        logEntryConfigurationByProcessTaskLogEntryAction[
          this.processTaskLogEntry.logAction as ProcessTaskLogEntryAction
        ];

      this.logDataConverterWithInfos = configuration.logDataConverterInfos.map(
        (info) => {
          const converterConstructor =
            logDataConverterTypeToConstructorMap[info.converterType];
          if (!converterConstructor) {
            throw new Error(
              `LogActionConverter for ${info.converterType} not found`
            );
          }

          return {
            converter: this.container.get(converterConstructor),
            info
          };
        }
      );
    } else {
      this.logDataConverterWithInfos = [];
    }

    this.updateLogDataConverterSubscriptions();
    this.updateTranslations();
  }

  private updateLogDataConverterSubscriptions(): void {
    this.logDataConvertersSubscriptionManager.disposeSubscriptions();

    for (const { converter } of this.logDataConverterWithInfos) {
      this.logDataConvertersSubscriptionManager.addDisposable(
        converter.subscribe({
          onDependenciesChanged: () => {
            this.updateTranslations();
          }
        })
      );
    }
  }

  private updateTranslations(): void {
    if (this.processTaskLogEntry) {
      this.text = this.generateTextForProcessTaskLogEntry(
        this.processTaskLogEntry
      );
      this.footer = this.generateFooterForProcessTaskLogEntry(
        this.processTaskLogEntry
      );
    } else {
      this.text = null;
      this.footer = null;
    }
  }

  private generateTextForProcessTaskLogEntry(
    processTaskLogEntry: ProcessTaskLogEntry
  ): string {
    const logData = processTaskLogEntry.logDataJson
      ? JSON.parse(processTaskLogEntry.logDataJson)
      : {};

    this.processLogData({
      logData,
      processTaskLogEntry
    });

    return this.i18n.tr(
      `modelsDetail.ProcessTaskLogEntryModel.logAction--${processTaskLogEntry.logAction}`,
      logData
    );
  }

  private generateFooterForProcessTaskLogEntry(
    processTaskLogEntry: ProcessTaskLogEntry
  ): string {
    const logData = {
      ...(processTaskLogEntry.logDataJson
        ? JSON.parse(processTaskLogEntry.logDataJson)
        : {}),
      logDate: processTaskLogEntry.date
    };

    this.processLogData({
      logData,
      processTaskLogEntry
    });

    const key = `modelsDetail.ProcessTaskLogEntryModel.logActionFooter--${processTaskLogEntry.logAction}`;

    return this.i18n.tr(key, {
      replace: logData,
      defaultValue: ''
    });
  }

  private processLogData({
    logData,
    processTaskLogEntry
  }: {
    logData: Record<string, unknown>;
    processTaskLogEntry: ProcessTaskLogEntry;
  }): void {
    for (const { converter, info } of this.logDataConverterWithInfos) {
      converter.convert({
        logData,
        args: info.converterArgs,
        processTaskLogEntry,
        sortedProcessTaskLogEntries: this.sortedProcessTaskLogEntries
      });
    }
  }
}
