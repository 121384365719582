import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskInvoiceToProcessTaskPosition } from './types';

export class ProcessTaskInvoiceToProcessTaskPositionRepository extends AppEntityRepository<EntityName.ProcessTaskInvoiceToProcessTaskPosition> {
  public getByProcessTaskInvoiceIdAndProcessTaskId(
    processTaskInvoiceId: string,
    processTaskId: string
  ): Array<ProcessTaskInvoiceToProcessTaskPosition> {
    return this.getAll().filter((e) => {
      return (
        e.processTaskInvoiceId === processTaskInvoiceId &&
        e.ownerProcessTaskId === processTaskId
      );
    });
  }

  public getByProcessTaskInvoiceId(
    processTaskInvoiceId: string
  ): Array<ProcessTaskInvoiceToProcessTaskPosition> {
    return this.getAll().filter(
      (e) => e.processTaskInvoiceId === processTaskInvoiceId
    );
  }

  public getByProcessTaskPositionId(
    processTaskPositionId: string
  ): Array<ProcessTaskInvoiceToProcessTaskPosition> {
    return this.getByProcessTaskPositionIds([processTaskPositionId]);
  }

  public getByProcessTaskPositionIds(
    processTaskPositionIds: Array<string>
  ): Array<ProcessTaskInvoiceToProcessTaskPosition> {
    return this.getAll().filter(
      (e) => processTaskPositionIds.indexOf(e.processTaskPositionId) >= 0
    );
  }

  public getByProcessTaskInvoiceIdAndProcessTaskPositionId(
    processTaskInvoiceId: string,
    processTaskPositionId: string
  ): ProcessTaskInvoiceToProcessTaskPosition | null {
    return (
      this.getAll().find((e) => {
        return (
          e.processTaskInvoiceId === processTaskInvoiceId &&
          e.processTaskPositionId === processTaskPositionId
        );
      }) ?? null
    );
  }
}
