import { autoinject } from 'aurelia-framework';
import { configureHooks } from '../../hooks/configureHooks';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  ChoiceAddedEvent,
  ChoiceRemovedEvent
} from '../../inputComponents/text-choice-widget/text-choice-widget';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { QuestionUnit } from 'common/Types/Entities/Question/QuestionDto';
import { EntityName } from 'common/Types/BaseEntities/EntityName';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { CreateChecklistEntitiesService } from '../../services/CreateChecklistEntitiesService';
import { ProjectQuestionCategory } from '../../classes/EntityManager/entities/ProjectQuestionCategory/types';
import { I18N } from 'aurelia-i18n';

@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class ChecklistAddInspectionQuestionDialog {
  protected dialog: RecordItDialog | null = null;

  protected project: Project | null = null;
  protected physicalArea: string | null = null;

  protected question: string = '';
  protected choices: Array<string> = [];
  protected units: Array<QuestionUnit> = [];
  protected targetGroups: Array<string> = [];
  protected projectQuestionCategoryId: string | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly createChecklistEntitiesService: CreateChecklistEntitiesService,
    private readonly i18n: I18N
  ) {}

  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private open(options: OpenOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open ChecklistExportInspectionDialog without a RecordItDialog.'
    );

    this.project = options.project;
    this.physicalArea = options.physicalArea;

    this.question = '';
    this.choices = [this.i18n.tr('general.yes'), this.i18n.tr('general.no')];
    this.units = [];
    this.targetGroups = [];
    this.projectQuestionCategoryId = null;

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.project = null;
    this.physicalArea = null;
  }

  protected handleAcceptButtonClicked(): void {
    assertNotNullOrUndefined(
      this.project,
      'cannot handleAcceptButtonClicked without a project'
    );

    assertNotNullOrUndefined(
      this.projectQuestionCategoryId,
      'cannot handleAcceptButtonClicked without a projectQuestionCategoryId'
    );

    this.createChecklistEntitiesService.addCustomProjectQuestionToProject(
      {
        text: this.question,
        choices: this.choices.map((choice) => ({
          choiceText: choice,
          actions: []
        })),
        units: this.units.map((u) => ({ ...u, value: '' })),
        targetGroups: this.targetGroups.map((tg) => ({ name: tg })),
        projectQuestionCategoryId: this.projectQuestionCategoryId,
        physicalArea: this.physicalArea
      },
      this.project
    );
  }

  protected handleChoiceAdded(event: ChoiceAddedEvent): void {
    this.choices.push(event.detail.choice);
  }

  protected handleChoiceRemoved(event: ChoiceRemovedEvent): void {
    ArrayUtils.remove(this.choices, event.detail.choice);
  }

  protected handleAddUnitButtonClicked(): void {
    this.units.push({
      title: '',
      unit: ''
    });
  }

  protected handleDeleteUnitButtonClicked(unit: QuestionUnit): void {
    ArrayUtils.remove(this.units, unit);
  }

  protected handleTargetGroupAdded(event: ChoiceAddedEvent): void {
    this.targetGroups.push(event.detail.choice);
  }

  protected handleTargetGroupRemoved(event: ChoiceRemovedEvent): void {
    ArrayUtils.remove(this.targetGroups, event.detail.choice);
  }

  protected handleProjectQuestionCategorySelected(
    projectQuestionCategoryId: string | null
  ): void {
    this.projectQuestionCategoryId = projectQuestionCategoryId;
  }

  @computed(expression('question'), expression('projectQuestionCategoryId'))
  private get canClickAcceptButton(): boolean {
    return !!this.question && !!this.projectQuestionCategoryId;
  }

  @computed(expression('canClickAcceptButton'))
  protected get disabledButtons(): Array<string> {
    return this.canClickAcceptButton ? [] : ['accept'];
  }

  /**
   * For the autocomplete of the target group input,
   * retrieve all target groups from all questions.
   */
  @computed(model(EntityName.Question))
  protected get targetGroupAutoComplete(): Array<string> {
    const existingTargetGroups = this.entityManager.questionRepository
      .getAll()
      .flatMap((tg) => tg.targetGroups)
      .map((tg) => tg.name);
    return ArrayUtils.unique(existingTargetGroups);
  }

  @computed(model(EntityName.ProjectQuestionCategory), expression('project.id'))
  protected get availableProjectQuestionCategories(): Array<ProjectQuestionCategory> {
    if (!this.project) return [];

    return this.entityManager.projectQuestionCategoryRepository.getByProjectId(
      this.project.id
    );
  }
}

export type OpenOptions = {
  /**
   * The project the question will be added to.
   */
  project: Project;
  /**
   * Whether the question should associated with a physical area.
   */
  physicalArea: string | null;
};
