import { computedFrom } from 'aurelia-framework';
import { ProcessTaskMeasurePoint } from '../../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types';
import { ProcessTaskMeasurePointAdapter } from '../EntityAdapter/ProcessTaskMeasurePointAdapter/ProcessTaskMeasurePointAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ProcessTaskMeasurePointPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ProcessTaskMeasurePointAdapter>,
  ProcessTaskMeasurePoint
> {
  @computedFrom('combinedRevision')
  public get canCreateProcessTaskMeasurePointReadings(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessTaskMeasurePointReadings'
    );
  }

  @computedFrom('combinedRevision')
  public get canEditProcessTaskMeasurePointToPictures(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canEditProcessTaskMeasurePointToPictures'
    );
  }
}
