import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { NavigationInstruction, Router, RouterEvent } from 'aurelia-router';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { Disposable } from './Utils/DisposableContainer';
import { EventDispatcher } from './EventDispatcher/EventDispatcher';

@autoinject()
export class UrlParameterService {
  private openedOverlayInfos = new Map<
    string,
    { navigationStarted: boolean }
  >();

  private overlayOpenedIdRemovedDispatcher = new EventDispatcher<
    Record<string, void>
  >();

  constructor(
    private readonly router: Router,
    private readonly eventAggregator: EventAggregator
  ) {
    let backInstruction: NavigationInstruction | null = null;
    this.eventAggregator.subscribe(
      RouterEvent.Processing,
      ({ instruction }: { instruction: NavigationInstruction }) => {
        if (router.isExplicitNavigationBack) {
          backInstruction = instruction;
        } else {
          backInstruction = null;
        }
      }
    );

    this.eventAggregator.subscribe(
      RouterEvent.Complete,
      ({ instruction }: { instruction: NavigationInstruction }) => {
        if (instruction === backInstruction) {
          return;
        }

        const ids = Array.from(this.openedOverlayInfos.keys());

        for (const id of ids) {
          if (
            !this.router.currentInstruction.queryParams[
              this.generateUrlParameterName(id)
            ]
          ) {
            this.openedOverlayInfos.delete(id);
            this.overlayOpenedIdRemovedDispatcher.dispatchEvent(id);
          }
        }
      }
    );
  }

  public overlayOpened(id: string): void {
    this.openedOverlayInfos.set(id, { navigationStarted: false });

    const currentInstruction = this.router.currentInstruction;

    // For some reason aurelia is dispatching this call as an instruction without any queryParams in some edge cases (e.g. process-task-measure-point-creation-buttons).
    // The issue only occurs if you call `navigateToRoute` after a `navigateBack`.
    // The Browser history sets the hash of window.location, which results in a hashchange event. But the event isn't fired synchronously.
    // So the instructions are bleeding into each other. (Somehow the queryParams of the first navigateToRoute slipped into the second call and vice versa)
    // A simple timeout of 0 helps and makes aurelia dispatch the router events correctly again.
    setTimeout(() => {
      const overlayInfo = this.openedOverlayInfos.get(id);

      // this happens if the overlay has been closed already
      if (!overlayInfo) {
        return;
      }

      assertNotNullOrUndefined(
        currentInstruction.config.name,
        'cannot add route param without a route name'
      );

      this.router.navigateToRoute(
        currentInstruction.config.name,
        {
          ...this.removeIdParams({
            ...currentInstruction.params,
            ...currentInstruction.queryParams
          }),
          ...this.generateIdParams()
        },
        {
          trigger: true
        }
      );
      overlayInfo.navigationStarted = true;
    }, 0);
  }

  /**
   * Returns the parameters & query parameters currently present in the URL.
   *
   * Use this method instead of accessing `this.router.currentInstruction.params`
   * & `this.router.currentInstruction.queryParams` directly, as those may include
   * id parameters not actually present in the URL anymore, and this method returns
   * an object not including those.
   * @see {removeIdParams}
   *
   * @example
   * this.router.navigateToRoute(
   *   instruction.config.name,
   *   {
   *     ...this.urlParameterService.getCurrentParameters(),
   *     // Some new parameters
   *   },
   *   { replace: true }
   * );
   */
  public getCurrentParameters(): Record<string, unknown> {
    const instruction = this.router.currentInstruction;
    return {
      ...this.removeIdParams({
        ...instruction.params,
        ...instruction.queryParams
      }),
      ...this.generateIdParams()
    };
  }

  public overlayClosed(id: string): void {
    const overlayInfo = this.openedOverlayInfos.get(id);
    this.openedOverlayInfos.delete(id);

    if (!overlayInfo?.navigationStarted) {
      return; // nothing to do since nothing changed
    }

    const configName = this.router.currentInstruction.config.name;
    const params = this.removeIdParams({
      ...this.router.currentInstruction.params,
      ...this.router.currentInstruction.queryParams
    });
    assertNotNullOrUndefined(
      configName,
      'cannot add route param without a route name'
    );

    this.router.navigateBack();

    this.router.navigateToRoute(
      configName,
      {
        ...params,
        ...this.generateIdParams()
      },
      {
        trigger: false,
        replace: true
      }
    );

    // This is a workaround, because somehow aurelia doesn't update these themselves.
    // This is needed, for other components which add url params. E.g. the pagination.
    // Without this, the pagination will also add one of the last fullScreenOverlay_ params to the url when navigating to a different page
    this.router.currentInstruction.queryParams = {
      ...this.removeIdParams(this.router.currentInstruction.queryParams),
      ...this.generateIdParams
    };
  }

  public registerUrlParameterRemovedListener(
    id: string,
    callback: () => void
  ): Disposable {
    return this.overlayOpenedIdRemovedDispatcher.addDisposableEventListener(
      id,
      callback
    );
  }

  /**
   * Since aurelia sometimes says that there are params in the url which don't actually exists (anymore), we have to remove them.
   * All necessary id params are created via the `generateIdParams`, we can savely remove everyone of them.
   * And if we don't remove those superflous params, it seems that some history entries were missing/not created and in special cases there would be too much back navigations.
   * (I didn't fully understand the problem though, but there definitely were too much params and those needed to get fixed anyway)
   */
  private removeIdParams(params: Record<string, any>): Record<string, any> {
    const newParams: Record<string, any> = {};

    for (const key of Object.keys(params)) {
      if (!/^fullScreenOverlay_/.test(key)) {
        newParams[key] = params[key];
      }
    }

    return newParams;
  }

  private generateIdParams(): Record<string, boolean> {
    const idParams: Record<string, boolean> = {};

    this.openedOverlayInfos.forEach((_, id) => {
      idParams[this.generateUrlParameterName(id)] = true;
    });

    return idParams;
  }

  private generateUrlParameterName(id: string): string {
    return `fullScreenOverlay_${id}`;
  }
}
