import { Opt } from 'common/Opt';
import {
  MonoTypeOperatorFunction,
  Observable,
  Subscriber,
  Subscription
} from 'rxjs';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import { ShareReplayWithTimeoutUtils } from './ShareReplayWithTimeoutUtils';

/**
 * Functions similar to shareReplay({ bufferSize: 1, refCount: true }), but keeps the underlying subscription for a certain timeout active (even if there are no subscriber anymore),
 * so it can be reused later. If the timeout is reached before a new subscription happened, the underlying subscription will get unsubscribed.
 *
 * This is useful, so subscriptions can outlive state transitions, where e.g. a navigation to a new page would unsubscribe the subscription from the previous page and resubscribe it immediately on the new page.
 *
 * This is also useful for permissionsService.useAdapterOnce, where the adapters are used multiple times in loops etc.
 * for(const entity of entities) {
 *   await permissionsService.useAdapterOnce();
 *   // Without shareReplayWithTimeout, the base subscription will get subscribed/unsubscribed entities.length times which can be very expensive.
 *   // With shareReplayWithTimeout, there will be only one subscribe/unsubscribe and expensive calculations will only be done once
 * }
 */
export function shareReplayWithTimeout<T>(
  timeoutMs: number
): MonoTypeOperatorFunction<T> {
  return (source) => {
    let sourceSubscription: Subscription | null = null;
    let lastSourceValue: Opt<T> = Opt.none();
    let unsubscribeTimeoutDisposable: Disposable | null = null;

    const subscribers = new Set<Subscriber<T>>();

    return new Observable((currentInstanceSubscriber) => {
      subscribers.add(currentInstanceSubscriber);

      if (lastSourceValue.isSome()) {
        currentInstanceSubscriber.next(lastSourceValue.getVal());
      }

      if (!sourceSubscription) {
        sourceSubscription = source.subscribe((value) => {
          for (const subscriber of subscribers.values()) {
            subscriber.next(value);
          }

          lastSourceValue = Opt.some(value);
        });
      }

      if (unsubscribeTimeoutDisposable) {
        unsubscribeTimeoutDisposable.dispose();
        unsubscribeTimeoutDisposable = null;
      }

      return () => {
        subscribers.delete(currentInstanceSubscriber);
        currentInstanceSubscriber.unsubscribe();

        if (subscribers.size === 0) {
          unsubscribeTimeoutDisposable =
            ShareReplayWithTimeoutUtils.registerTimeout({
              callback: () => {
                sourceSubscription?.unsubscribe();
                sourceSubscription = null;
                lastSourceValue = Opt.none();
              },
              timeoutMs
            });
        }
      };
    });
  };
}
