import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SorterSortOption } from '../../aureliaAttributes/sorter';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { UserDefinedEntity } from '../../classes/EntityManager/entities/UserDefinedEntity/types';
import { UserDefinedEntityUtils } from '../../classes/EntityManager/entities/UserDefinedEntity/UserDefinedEntityUtils';
import { FilterHelper } from '../../classes/FilterHelper';
import {
  CopyUserDefinedEntityDialog,
  OnCreateNewUserDefinedEntitiesCallback,
  SupportedTemplateUserDefinedEntity,
  SupportedUserDefinedEntityBaseEntity,
  UserDefinedEntityOfBaseEntity
} from '../../dialogs/copy-user-defined-entity-dialog/copy-user-defined-entity-dialog';
import { computed } from '../../hooks/computed';
import { model, expression, arrayChanges } from '../../hooks/dependencies';
import { MoreButtonChoice } from '../more-button/more-button';
import { Dialogs } from '../../classes/Dialogs';
import { ActionClickedEvent } from '../selectable-item-list/selectable-item-list';
import { EditUserDefinedEntityDialog } from '../../dialogs/edit-user-defined-entity-dialog/edit-user-defined-entity-dialog';
import { UserDefinedEntityConfigPropertyConfigCustomColumn } from 'common/Types/Entities/UserDefinedEntityConfigPropertyConfig/UserDefinedEntityConfigPropertyConfigDto';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntitiesPermissionChecker } from '../../services/PermissionsService/EntitiesPermissionChecker/EntitiesPermissionChecker';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class UserDefinedEntitiesWidget<
  TBaseEntity extends SupportedUserDefinedEntityBaseEntity
> {
  @bindable public selectableUserDefinedEntityTemplates: Array<
    SupportedTemplateUserDefinedEntity<TBaseEntity>
  > = [];

  @bindable public userDefinedEntities: Array<
    UserDefinedEntityOfBaseEntity<TBaseEntity>
  > = [];

  @bindable public baseEntity: TBaseEntity | null = null;

  /**
   * creation of userDefinedEntities is only allowed if a callback is given
   */
  @bindable
  public onCreateNewEntities: OnCreateNewUserDefinedEntitiesCallback<TBaseEntity> | null =
    null;

  @bindable public allowCustomColumns = false;
  @bindable public prefilteredByEntityConfigName: string | null = null;

  private sortOptions = UserDefinedEntityUtils.sortOptions;

  protected sortedUserDefinedEntities: Array<
    UserDefinedEntityOfBaseEntity<TBaseEntity>
  > = [];

  protected selectedUserDefinedEntities: Array<
    UserDefinedEntityOfBaseEntity<TBaseEntity>
  > = [];

  protected filteredUserDefinedEntities: Array<
    UserDefinedEntityOfBaseEntity<TBaseEntity>
  > = [];

  private filterString: string = '';
  private currentSortOption: SorterSortOption<
    UserDefinedEntityOfBaseEntity<TBaseEntity>
  > = this.sortOptions.name;

  protected multiSelectMoreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk: 'general.delete',
      name: 'delete-user-defined-entities',
      iconClass: 'fal fa-trash-alt',
      disabledContext: this,
      disabledPropertyName: 'cannotDeleteSelectedUserDefinedEntities'
    }
  ];

  @subscribableLifecycle()
  protected readonly userDefinedEntitiesPermissionChecker: EntitiesPermissionChecker<EntityName.UserDefinedEntity>;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.userDefinedEntitiesPermissionChecker =
      permissionsService.getEntitiesPermissionChecker({
        entityName: EntityName.UserDefinedEntity
      });
  }

  @computed(
    model(EntityName.UserDefinedEntity),
    expression('filterString'),
    expression('userDefinedEntities')
  )
  protected get searchFilteredUserDefinedEntities(): Array<
    UserDefinedEntityOfBaseEntity<TBaseEntity>
  > {
    return FilterHelper.filterItems(
      this.userDefinedEntities,
      (entity) => entity.name + ' ' + (entity.customId ?? ''),
      this.filterString
    );
  }

  protected handleCreateNewElementButtonClicked(): void {
    assertNotNullOrUndefined(
      this.baseEntity,
      'cannot open dialog without base entity'
    );
    assertNotNullOrUndefined(
      this.onCreateNewEntities,
      'cannot open dialog without onCreateEntities callback'
    );
    const usergroup = this.entityManager.userGroupRepository.getById(
      this.baseEntity.ownerUserGroupId
    );
    assertNotNullOrUndefined(usergroup, 'cannot open dialog without usergroup');

    void CopyUserDefinedEntityDialog.open<TBaseEntity>({
      availableUserDefinedEntities: this.selectableUserDefinedEntityTemplates,
      baseEntity: this.baseEntity,
      onCreateNewEntities: this.onCreateNewEntities,
      entityManager: this.entityManager,
      prefilteredByEntityConfigName: this.prefilteredByEntityConfigName
    });
  }

  protected async handleDeleteSelectedUserDefinedEntities(
    event: ActionClickedEvent<UserDefinedEntityOfBaseEntity<TBaseEntity>>
  ): Promise<void> {
    await Dialogs.deleteDialogTk('');

    event.detail.selectedItems.forEach((x) =>
      this.entityManager.userDefinedEntityRepository.delete(x)
    );
  }

  protected handleUserDefinedEntityDetailActionClick(
    userDefinedEntity: UserDefinedEntity
  ): void {
    void EditUserDefinedEntityDialog.open({
      userDefinedEntity: userDefinedEntity
    });
  }

  @computed(
    expression('userDefinedEntitiesPermissionChecker.revision'),
    arrayChanges('selectedUserDefinedEntities')
  )
  protected get cannotDeleteSelectedUserDefinedEntities(): boolean {
    return !this.userDefinedEntitiesPermissionChecker.allEntitiesHavePermission(
      {
        entities: this.selectedUserDefinedEntities,
        checkPermission: ({ adapter, entity }) => {
          return adapter.canDeleteEntity(entity);
        }
      }
    );
  }

  protected get showCustomColumns(): boolean {
    return (
      this.allowCustomColumns &&
      UserDefinedEntityUtils.allShownEntitiesBelongToSameEntityConfig({
        filteredUserDefinedEntities: this.filteredUserDefinedEntities
      })
    );
  }

  @computed(
    expression('filteredUserDefinedEntities'),
    model(EntityName.UserDefinedEntityConfigPropertyConfig),
    model(EntityName.UserDefinedEntityConfig)
  )
  protected get firstCustomColumnName(): string | null {
    return UserDefinedEntityUtils.getCustomColumnName({
      filteredUserDefinedEntities: this.filteredUserDefinedEntities,
      entityManager: this.entityManager,
      customColumnType: UserDefinedEntityConfigPropertyConfigCustomColumn.FIRST
    });
  }

  @computed(
    expression('filteredUserDefinedEntities'),
    model(EntityName.UserDefinedEntityConfigPropertyConfig),
    model(EntityName.UserDefinedEntityConfig)
  )
  protected get secondCustomColumnName(): string | null {
    return UserDefinedEntityUtils.getCustomColumnName({
      filteredUserDefinedEntities: this.filteredUserDefinedEntities,
      entityManager: this.entityManager,
      customColumnType: UserDefinedEntityConfigPropertyConfigCustomColumn.SECOND
    });
  }
}
