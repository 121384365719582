import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { processTaskOfferToProcessTaskEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTask/processTaskOfferToProcessTaskEntityInfo';
import { ProcessTaskOfferToProcessTask } from '../../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTask/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { ProcessTaskOfferAdapter } from '../ProcessTaskOfferAdapter/ProcessTaskOfferAdapter';

export class ProcessTaskOfferToProcessTaskAdapter
  implements EntityAdapter<ProcessTaskOfferToProcessTask>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private processTaskOfferAdapter: ProcessTaskOfferAdapter | null = null;

  constructor(options: ProcessTaskOfferToProcessTaskAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.ProcessTaskOffer,
        onNextAdapter: ({ adapter }) => {
          this.processTaskOfferAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.processTaskOfferAdapter != null &&
      this.processTaskOfferAdapter.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(
    processTaskOfferToProcessTask: ProcessTaskOfferToProcessTask
  ): boolean {
    return this.canEditProcessTaskOfferToProcessTask(
      processTaskOfferToProcessTask
    );
  }

  public canEditField(
    processTaskOfferToProcessTask: ProcessTaskOfferToProcessTask
  ): boolean {
    return this.canEditProcessTaskOfferToProcessTask(
      processTaskOfferToProcessTask
    );
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskOfferToProcessTask,
    ProcessTaskOfferToProcessTask
  > {
    return processTaskOfferToProcessTaskEntityInfo;
  }

  private canEditProcessTaskOfferToProcessTask(
    processTaskOfferToProcessTask: ProcessTaskOfferToProcessTask
  ): boolean {
    if (!this.processTaskOfferAdapter) {
      return false;
    }

    const processTaskOffer =
      this.entityManager.processTaskOfferRepository.getById(
        processTaskOfferToProcessTask.processTaskOfferId
      );

    if (!processTaskOffer) {
      return false;
    }

    return this.processTaskOfferAdapter.canEditProcessTaskOfferToProcessTasks(
      processTaskOffer
    );
  }
}

export type ProcessTaskOfferToProcessTaskAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
