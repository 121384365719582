import { Disposable } from '@record-it-npm/synchro-client/lib/record-it-utils/src/utils/DisposableContainer/DisposableContainer';

export class ShareReplayWithTimeoutUtils {
  private static readonly timeoutIdToTimeoutInfo = new Map<
    number,
    { callback: () => void }
  >();

  public static skipTimeouts(): void {
    for (const [id, info] of this.timeoutIdToTimeoutInfo.entries()) {
      window.clearTimeout(id);
      info.callback();
    }

    this.timeoutIdToTimeoutInfo.clear();
  }

  /**
   * this function should only be used internally by shareReplayWithTimeout
   */
  public static registerTimeout({
    callback,
    timeoutMs
  }: {
    callback: () => void;
    timeoutMs: number;
  }): Disposable {
    const id = window.setTimeout(callback, timeoutMs);
    this.timeoutIdToTimeoutInfo.set(id, { callback });

    return {
      dispose: () => {
        window.clearTimeout(id);
        this.timeoutIdToTimeoutInfo.delete(id);
      }
    };
  }
}
