import { bindable, autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProcessTaskToProjectRelationInfo } from 'common/EndpointTypes/ProcessTaskToProjectRelationInfoEndpointsHandler';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { RequestWithStatus, Status } from '../../classes/RequestWithStatus';
import { RequestWithStatusService } from '../../services/RequestWithStatusService';
import { AbstractProcessTaskToProjectCrudStrategy } from '../../dialogs/manage-process-task-to-project-relations-dialog/strategies/AbstractProcessTaskToProjectCrudStrategy';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProjectHelper } from 'common/EntityHelper/ProjectHelper';

@autoinject()
export class ProcessTaskToProjectRelationInfoListItem {
  @bindable
  public processTaskToProjectRelationInfo: ProcessTaskToProjectRelationInfo | null =
    null;

  @bindable
  public processTaskToProjectEntityIds: ProcessTaskToProjectEntityIds | null =
    null;

  @bindable
  public crudStrategy: AbstractProcessTaskToProjectCrudStrategy | null = null;

  @bindable()
  public updateProcessTaskToProjectRelationInfos: (() => Promise<void>) | null =
    null;

  private toggleRelationRequestWithStatus: RequestWithStatus;
  private toggleButtonDisabled = false;

  constructor(
    private readonly requestWithStatusService: RequestWithStatusService,
    private readonly entityManager: AppEntityManager
  ) {
    this.toggleRelationRequestWithStatus =
      this.requestWithStatusService.createRequestWithStatus(async () => {
        assertNotNullOrUndefined(
          this.processTaskToProjectRelationInfo,
          'cannot send request to toggle processTaskGroupRelation without processTaskToProjectRelationInfo'
        );
        assertNotNullOrUndefined(
          this.processTaskToProjectEntityIds,
          'cannot send request to toggle processTaskGroupRelation without processTaskToProjectEntityIds'
        );
        assertNotNullOrUndefined(
          this.crudStrategy,
          'cannot send request to toggle processTaskGroupRelation without crudStrategy'
        );
        const response = this.processTaskToProjectRelationInfo
          .processTaskToProjectId
          ? await this.crudStrategy.deleteProcessTaskToProject({
              processTaskId: this.processTaskToProjectEntityIds.processTaskId,
              projectId: this.processTaskToProjectEntityIds.projectId
            })
          : await this.crudStrategy.createProcessTaskToProject({
              processTaskId: this.processTaskToProjectEntityIds.processTaskId,
              projectId: this.processTaskToProjectEntityIds.projectId
            });

        if (response.success) {
          this.processTaskToProjectRelationInfo.processTaskToProjectId =
            response.newProcessTaskToProjectId;
          void this.updateProcessTaskToProjectRelationInfos?.();
          return Status.OK;
        }

        return Status.ERROR;
      });
  }

  @computed(
    expression('processTaskToProjectRelationInfo.processTaskToProjectId')
  )
  protected get signedIconForButton(): 'fa-trash-circle' | 'fa-plus-circle' {
    return !!this.processTaskToProjectRelationInfo?.processTaskToProjectId
      ? 'fa-trash-circle'
      : 'fa-plus-circle';
  }

  @computed(
    expression('processTaskToProjectRelationInfo.projectId'),
    model(EntityName.Project)
  )
  protected get highlightColor(): string | null {
    if (this.processTaskToProjectRelationInfo?.projectId) {
      const project = this.entityManager.projectRepository.getRequiredById(
        this.processTaskToProjectRelationInfo.projectId
      );
      return ProjectHelper.getColorOfProject(project.projectType);
    }

    return 'transparent';
  }

  protected handleToggleRelationButtonClicked(): void {
    if (!this.toggleRelationRequestWithStatus.inProgress) {
      this.toggleButtonDisabled = true;
      this.toggleRelationRequestWithStatus.startRequest();
    }
  }

  protected enableToggleButton(): void {
    this.toggleButtonDisabled = false;
  }
}

export type ProcessTaskToProjectEntityIds = {
  projectId: string;
  processTaskId: string;
};
