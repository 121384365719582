import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { TextBrick } from '../../classes/EntityManager/entities/TextBrick/types';
import { TextBrickTemplate } from '../../classes/EntityManager/entities/TextBrickTemplate/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * This class is here, so we delete/create the TextBricks when we close the dialog.
 * Since the TextBricks contain custom data, we don't want to have it deleted, just because a user accidentally unchecks and then checks the checkbox again
 */
export class TextBrickSelectionData {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private readonly textBrickTemplateIsSelectedDataBindings: Array<TextBrickTemplateIsSelectedDataBinding> =
    [];

  private textBricksOfEntity: Array<TextBrick> = [];

  private readonly newlySelectedTextBrickTemplates =
    new Set<TextBrickTemplate>();

  private readonly textBricksToRemove = new Set<TextBrick>();

  constructor({
    entityManager,
    subscriptionManagerService
  }: {
    entityManager: AppEntityManager;
    subscriptionManagerService: SubscriptionManagerService;
  }) {
    this.entityManager = entityManager;
    this.subscriptionManagerService = subscriptionManagerService;
  }

  public subscribe({
    getTextBricks
  }: {
    getTextBricks: () => Array<TextBrick>;
  }): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.subscribeToModelChanges(EntityName.TextBrick, () => {
      this.textBricksOfEntity = getTextBricks();

      this.updateNewlySelectedTextBrickTemplateIds();
      this.updateTextBricksToRemove();

      this.updateAllBindings();
    });
    this.textBricksOfEntity = getTextBricks();

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
        this.textBricksOfEntity = [];
        this.newlySelectedTextBrickTemplates.clear();
        this.textBricksToRemove.clear();
      }
    };
  }

  public selectTextBrickTemplate({
    textBrickTemplate
  }: {
    textBrickTemplate: TextBrickTemplate;
  }): void {
    const textBrickForTemplateAlreadyExists = this.textBricksOfEntity.some(
      (textBrick) => textBrick.textBrickTemplateId === textBrickTemplate.id
    );

    // no need to create a template if there is already one
    if (!textBrickForTemplateAlreadyExists) {
      this.newlySelectedTextBrickTemplates.add(textBrickTemplate);
    }

    // if a text brick is marked as removed, but then it is selected again, then we don't want to remove it
    for (const textBrickToRemove of Array.from(
      this.textBricksToRemove.values()
    )) {
      if (textBrickToRemove.textBrickTemplateId === textBrickTemplate.id) {
        this.textBricksToRemove.delete(textBrickToRemove);
      }
    }

    this.updateAllBindings();
  }

  public deselectTextBrickTemplate({
    textBrickTemplate
  }: {
    textBrickTemplate: TextBrickTemplate;
  }): void {
    this.newlySelectedTextBrickTemplates.delete(textBrickTemplate);

    // if an existing text brick is deselected, we also want to remove it
    for (const textBrick of this.textBricksOfEntity) {
      if (textBrick.textBrickTemplateId === textBrickTemplate.id) {
        this.textBricksToRemove.add(textBrick);
      }
    }

    this.updateAllBindings();
  }

  public bindTextBrickTemplateIsSelectedData({
    textBrickTemplate,
    callback
  }: {
    textBrickTemplate: TextBrickTemplate;
    callback: TextBrickTemplateIsSelectedDataBindingCallback;
  }): Disposable {
    const binding: TextBrickTemplateIsSelectedDataBinding = {
      textBrickTemplate,
      callback
    };

    this.updateBinding({ binding });

    return {
      dispose: () => {
        this.removeBinding({ binding });
      }
    };
  }

  public getTextBricksModificationData(): {
    textBricksCreationDatas: Array<{
      textBrickTemplateId: string;
      value: string;
    }>;
    textBricksToDelete: Array<TextBrick>;
  } {
    return {
      textBricksCreationDatas: Array.from(
        this.newlySelectedTextBrickTemplates
      ).map((textBrickTemplate) => {
        return {
          textBrickTemplateId: textBrickTemplate.id,
          value: textBrickTemplate.value
        };
      }),
      textBricksToDelete: Array.from(this.textBricksToRemove)
    };
  }

  private updateNewlySelectedTextBrickTemplateIds(): void {
    const textBrickTemplates = Array.from(
      this.newlySelectedTextBrickTemplates.values()
    );

    for (const textBrick of this.textBricksOfEntity) {
      const textBrickTemplate = textBrickTemplates.find(
        (t) => t.id === textBrick.textBrickTemplateId
      );

      if (textBrickTemplate) {
        // a textBrickTemplateId which is already used doesn't need to be newly added
        this.newlySelectedTextBrickTemplates.delete(textBrickTemplate);
      }
    }
  }

  private updateTextBricksToRemove(): void {
    for (const textBrickToRemove of Array.from(
      this.textBricksToRemove.values()
    )) {
      const textBrickExists = this.textBricksOfEntity.some(
        (textBrickOfEntry) => textBrickOfEntry === textBrickToRemove
      );

      // a text brick which doesn't exist anymore doesn't need to get removed
      if (!textBrickExists) {
        this.textBricksToRemove.delete(textBrickToRemove);
      }
    }
  }

  private updateAllBindings(): void {
    for (const binding of this.textBrickTemplateIsSelectedDataBindings) {
      this.updateBinding({ binding });
    }
  }

  private updateBinding({
    binding
  }: {
    binding: TextBrickTemplateIsSelectedDataBinding;
  }): void {
    binding.callback({
      textBrickTemplateIsSelected: this.textBrickTemplateIsSelected({
        textBrickTemplate: binding.textBrickTemplate
      }),
      existingTextBricks: this.textBricksOfEntity.filter(
        (textBrick) =>
          textBrick.textBrickTemplateId === binding.textBrickTemplate.id
      )
    });
  }

  private removeBinding({
    binding
  }: {
    binding: TextBrickTemplateIsSelectedDataBinding;
  }): void {
    const index = this.textBrickTemplateIsSelectedDataBindings.indexOf(binding);
    this.textBrickTemplateIsSelectedDataBindings.splice(index, 1);
  }

  private textBrickTemplateIsSelected({
    textBrickTemplate
  }: {
    textBrickTemplate: TextBrickTemplate;
  }): boolean {
    if (this.newlySelectedTextBrickTemplates.has(textBrickTemplate)) {
      return true;
    }

    return this.textBricksOfEntity.some((textBrick) => {
      // we can't use this textBrick, since it's marked for deletion
      if (this.textBricksToRemove.has(textBrick)) {
        return false;
      }

      return textBrick.textBrickTemplateId === textBrickTemplate.id;
    });
  }
}

type TextBrickTemplateIsSelectedDataBinding = {
  textBrickTemplate: TextBrickTemplate;
  callback: TextBrickTemplateIsSelectedDataBindingCallback;
};

export type TextBrickTemplateIsSelectedDataBindingCallback = (
  data: TextBrickTemplateIsSelectedData
) => void;

export type TextBrickTemplateIsSelectedData = {
  textBrickTemplateIsSelected: boolean;
  existingTextBricks: Array<TextBrick>;
};
