import zod from 'zod';

import { ProcessTaskAppointmentCalendarWidgetMode } from '../../../Enums/ProcessTaskAppointmentCalendarWidgetMode';
import { ProcessTaskGroupFilterMode } from '../../../Enums/ProcessTaskGroupFilterMode';
import { TagSortingMode } from '../../../Enums/TagSortingMode';
import { PersonListItemFirstColumnContent } from '../../../Enums/PersonListItemFirstColumnContent';
import { PreferredOpenMethodInApps } from '../../../Enums/PreferredOpenMethodInApps';
import { OptionalProperties } from '../../utilities';
import { BaseEntity } from '../Base/BaseEntity';
import { IdType, DateType } from '../Base/types';
import { GalleryThingPictureFilterTagMatchMode } from '../../GalleryThingPictureFilter/GalleryThingPictureFilter';
import { BaseTileMapLayer } from '../../../Enums/BaseMapLayer';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
type FullUserCompanySetting<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> & {
  name: string | null;
  logo: UserCompanySettingLogo;
  general: UserCompanySettingGeneral;
  homePage: UserCompanySettingHomePage;
  mainMenu: UserCompanySettingMainMenu;
  operations: UserCompanySettingOperations;
  operationsFieldUse: UserCompanySettingOperationsFieldUse;
  ultraRapidFireWidget: UserCompanySettingUltraRapidFireWidget;
  defectManagement: UserCompanySettingDefectManagement;
  structureProject: UserCompanySettingStructureProject;
  via: UserCompanySettingVia;
  externalSynchronization: UserCompanySettingExternalSynchronization;
  kuk: UserCompanySettingKuK;
  pdfSigning: UserCompanySettingPdfSigning;
};

export type UserCompanySettingDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullUserCompanySetting<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullUserCompanySetting<TId, TDate> = never
> = Omit<
  OptionalProperties<FullUserCompanySetting<TId, TDate>, TOptionalProperties>,
  TPropertiesToRemove
>;

export type UserCompanySettingLogo = {
  svg?: string | null;

  /**
   * in %
   */
  svgBaselineOffset?: number | null;

  /**
   * in px
   */
  height?: number | null;
};

export type UserCompanySettingGeneral = {
  primaryColor?: string | null;
  primaryLightColor?: string | null;
  secondaryColor?: string | null;
  breadcrumbBackgroundColor?: string | null;
  entityCreationUserGroupId?: string | null;
  showModuleName?: boolean | null;
  customTranslations?: string | null;
  /**
   * common/Type/PersonCategories.PersonCategories
   */
  personCategories?: string | null;
  thingGroupOwnerPersonCategory?: string | null;
  useThingGroupMap?: boolean | null;

  /**
   * makes all selection-switches a little bit bigger (to support thick construction worker fingers)
   */
  bigSelectionSwitch?: boolean | null;
  /**
   * common/Types/ThingGroupConfiguration.ThingGroupConfiguration
   */
  thingGroupConfigurationJson?: string | null;
  editEntryInsteadOfRFM?: boolean | null;

  /**
   * enable/show the thing sections
   *
   * this is only temporarily implement here and should be integrated into the role system (when it's implemented)
   */
  thingSections?: boolean | null;

  tagSortingMode?: TagSortingMode | null;
  personListItemFirstColumnContent?: PersonListItemFirstColumnContent | null;

  /**
   * enable/show the editor etc. for StructurePictureAreas
   */
  structurePictureAreas?: boolean | null;

  preferredOpenMethodInApps?: PreferredOpenMethodInApps | null;
  canUploadPictures?: boolean | null;
  showPictureUploadAndTakenDateExplicitly?: boolean | null;

  useKfvPukProjectNameGeneration?: boolean | null;

  /**
   * IDs of report templates for which projects should automatically be created
   * whenever the user creates a new object.
   */
  automaticProjectCreationConfigs?: Array<{
    reportTemplateId: string;
  }> | null;

  canUsePictureRevision?: boolean | null;

  standardBaseTileMapLayer?: BaseTileMapLayer | null;
};

export type UserCompanySettingHomePage = {
  usesLegacyTileHomePage?: boolean | null;
  initialNumberOfThingsShown?: number | null;
  totalNumberOfThingsShown?: number | null;
  initialNumberOfProjectsShown?: number | null;
  totalNumberOfProjectsShown?: number | null;
  showKukDefectShortcut?: boolean | null;
};

export type UserCompanySettingMainMenu = {
  hideThingTypes?: boolean | null;
  hideThingGroups?: boolean | null;
  hideReportTypes?: boolean | null;
  hideMap?: boolean | null;
  hideGlobalSearch?: boolean | null;
  hideEditUserGroups?: boolean | null;
  hideProcessConfiguration?: boolean | null;
  hideGeneralSectionHeader?: boolean | null;
};

export type UserCompanySettingOperations = {
  appointmentPositionCreation?: boolean | null;
  appointmentDeviceCreation?: boolean | null;
  appointmentMeasurePointManaging?: boolean | null;
  appointmentGeneralInfoEditing?: boolean | null;
  appointmentOpenWithNfcTag?: boolean | null;
  appointmentFinishWithNfcTag?: boolean | null;
  appointmentHideSignatureField?: boolean | null;
  appointmentColors?: boolean | null;
  calendarStartHour?: number | null;
  calendarEndHour?: number | null;
  defaultEditProcessTaskGroupFilterMode?: ProcessTaskGroupFilterMode | null;
  defaultProcessTaskAppointmentCalendarWidgetMode?: ProcessTaskAppointmentCalendarWidgetMode | null;
  noteInAppointmentNotificationColor?: string | null;
  noteInAppointmentNotificationEnabled?: boolean | null;
  processTaskAppointmentCalendarWidgetYearModeEnabled?: boolean | null;
  publicHolidayBackgroundColor?: string | null;
  defaultShowDoneChecklistEntries?: boolean | null;
};

export type UserCompanySettingOperationsFieldUse = {
  defaultProcessTaskAppointmentCalendarWidgetMode?: ProcessTaskAppointmentCalendarWidgetMode | null;
  allowCustomSelectionOfCalendarWidgetMode?: boolean | null;
  scrollToFirstAppointmentInDayCalendarWidgetMode?: boolean | null;
};

export type UserCompanySettingUltraRapidFireWidget = {
  useCameraOverlay?: boolean | null;
  useUltraRapidFireWidget?: boolean | null;
  overlayConfiguration?: string | null;
  highlightedPictureBorderColor?: string | null;
};

export type UserCompanySettingDefectManagement = {
  /**
   * Color of the border of MM pictures displayed in Via's image overview.
   */
  pictureBorderColor?: string | null;
  predefinedProperties?: string | null;
  /**
   * public/services/ViaFilterBarConfigurationService/ViaFilterBarConfigurationService.FilterBarConfiguration
   */
  propertyFilterBarConfiguration?: string | null;

  dailyNotificationForAssignedUsers?: boolean | null;
  periodicalNotificationForAssignedUsers?: boolean | null;
  notificationsForOpenDefectsOnly?: boolean | null;

  /**
   * The email of the user to be assigned to newly created defects by default.
   */
  defaultAssignedUserEmail?: string | null;
};

export type UserCompanySettingStructureProject = {
  /**
   * common/Types/RapidFireModeConfiguration.RapidFireModeConfiguration
   */
  rapidFireModeConfigurationJson?: string | null;
};

export type UserCompanySettingVia = {
  useStandardFilterBar?: boolean | null;
  /**
   * public/services/ViaFilterBarConfigurationService/ViaFilterBarConfigurationService.FilterBarConfiguration
   */
  filterBarConfiguration?: string | null;
  tagMatchMode?: GalleryThingPictureFilterTagMatchMode | null;
  groupByRegion?: boolean | null;
  regionDescriptionPerProject?: boolean | null;
  bulkEditConfiguration?: string | null;
  thingStatusConfiguration?: string | null;
  predefinedRegionsConfiguration?: string | null;
  predefinedPropertiesConfiguration?: string | null;
  predefinedEntryProperties?: string | null;
  tagCategoryConfiguration?: string | null;
  /**
   * Controls whether categorized tags are enabled/disabled in Via areas other than the defect management.
   *
   * If true, categorized tags should be available instead of "normal" tags everywhere in Via.
   * If false, categorized tags should only be available in the defect management.
   */
  useCategorizedTags?: boolean | null;
  pictureIconsConfiguration?: string | null;
  shpExportConfig?: string | null;
  /**
   * If true, all created pictures having coordinates will be patched:
   * coordsFromPositionedPictureInfo of the picture is set to position given by coords on the selected picture of the thing.
   */
  automaticallyMarkPicturesOnThingPicture?: boolean | null;
  usePlanOverview?: boolean | null;

  copyPicturesForNewDefect?: boolean | null;
};

export type UserCompanySettingExternalSynchronization = {
  sharepointSynchronizationOfThings?: boolean | null;
};

export type UserCompanySettingKuK = {
  /**
   * The id of the question catalogue to use when creating new checklist projects in a catastrophe.
   */
  questionCatalogueId?: string | null;
  initialNumberOfDefectsShown?: number | null;
  totalNumberOfDefectsShown?: number | null;
  initialNumberOfProjectsShown?: number | null;
  totalNumberOfProjectsShown?: number | null;
  reminders?: KukReminderSettings | null;
};

export const kukReminderSchema = zod.array(
  zod.object({
    propertyName: zod.string().nullable(),
    propertyValue: zod.string().nullable(),
    text: zod.string()
  })
);

/**
 * The configuration for KuK reminders.
 *
 * Depending on the values of a certain property of defects within one KuK catastrophe,
 * different texts need to be displayed in the catastrophe overview.
 */
export type KukReminderSettings = zod.infer<typeof kukReminderSchema>;

export type UserCompanySettingPdfSigning = {
  limitToOneSignedReport?: boolean | null;
  disableExportOfProjectsWithSignedReports?: boolean | null;
  canConfigureSignatureAutoProgress?: boolean | null;
};
