import { bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { RequestWithStatus, Status } from '../../classes/RequestWithStatus';
import { expression } from '../../hooks/dependencies';
import { watch } from '../../hooks/watch';

export class RequestWithStatusWidget {
  @bindable public requestWithStatus: RequestWithStatus | null = null;
  @bindable public fadeOutFinalStatusTimeout: number | null = 3000;
  @bindable public onFadeoutFinished: (() => {}) | null = null;

  private statusIcon: HTMLElement | null = null;
  private showHideAnimator: ShowHideAnimator | null = null;

  protected attached(): void {
    assertNotNullOrUndefined(
      this.statusIcon,
      "can't RequestWithStatusWidget.attached without a statusIcon"
    );
    this.showHideAnimator = new ShowHideAnimator(this.statusIcon);
  }

  @watch(expression('requestWithStatus.status'))
  protected fadeOutFinalState(): void {
    if (
      this.fadeOutFinalStatusTimeout === null ||
      this.requestWithStatus?.status === Status.NO_STATUS
    )
      return;

    this.showHideAnimator?.resetHiddenStatus();

    setTimeout(() => {
      void (async () => {
        await this.showHideAnimator?.fadeOut();
        this.onFadeoutFinished?.();
      })();
    }, this.fadeOutFinalStatusTimeout);
  }
}
