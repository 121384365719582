import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';

import { Dialogs } from '../../../../classes/Dialogs';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { UserDefinedEntityConfig } from '../../../../classes/EntityManager/entities/UserDefinedEntityConfig/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { UserDefinedEntityConfigPropertyDefinitionHandle } from '../../../property-definition-widget/PropertyDefinitionWidgetHandle/UserDefinedEntityConfigPropertyDefinitionHandle/UserDefinedEntityConfigPropertyDefinitionHandle';
import {
  ManagePropertyDefinitionsBaseWidgetAdapter,
  ManagePropertyDefinitionsWidgetAdapterDeleteOptions,
  ManagePropertyDefinitionsBaseWidgetAdapterSubscribeOptions
} from '../ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsBaseWidgetAdapter';
import { ManagePropertyDefinitionsWidgetAdapterHandleCache } from '../ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsWidgetAdapterHandleCache';
import {
  UserDefinedEntityConfigPropertyConfig,
  UserDefinedEntityConfigPropertyConfigCreationEntity
} from '../../../../classes/EntityManager/entities/UserDefinedEntityConfigPropertyConfig/types';
import { RxjsService } from '../../../../services/RxjsService/RxjsService';

export class ManagePropertyDefinitionsWidgetUserDefinedEntityConfigAdapter
  implements
    ManagePropertyDefinitionsBaseWidgetAdapter<UserDefinedEntityConfigPropertyConfig>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly permissionsService: PermissionsService;
  private readonly handleCache: ManagePropertyDefinitionsWidgetAdapterHandleCache<
    UserDefinedEntityConfigPropertyConfig,
    UserDefinedEntityConfigPropertyDefinitionHandle
  >;

  private readonly userDefinedEntityConfig: UserDefinedEntityConfig;

  constructor(
    options: ManageDefinedPropertiesWidgetUserDefinedEntityConfigAdapterOptions
  ) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.permissionsService = options.permissionsService;
    this.userDefinedEntityConfig = options.userDefinedEntityConfig;

    this.handleCache = new ManagePropertyDefinitionsWidgetAdapterHandleCache({
      createPropertyDefinitionHandle: ({ propertyDefinition }) => {
        return new UserDefinedEntityConfigPropertyDefinitionHandle({
          entityManager: options.entityManager,
          rxjsService: options.rxjsService,
          permissionsService: options.permissionsService,
          property: propertyDefinition,
          userDefinedEntityConfig: options.userDefinedEntityConfig
        });
      }
    });
  }

  public subscribe({
    setPropertyDefinitionHandles,
    setCanCreatePropertyDefinitions
  }: ManagePropertyDefinitionsBaseWidgetAdapterSubscribeOptions<UserDefinedEntityConfigPropertyConfig>): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(this.handleCache.subscribe());

    const { updatePropertyDefinitions } =
      this.handleCache.createUpdatePropertyDefinitionsFunction({
        setPropertyDefinitionHandles,
        getPropertyDefinitions: () => {
          return this.entityManager.userDefinedEntityConfigPropertyConfigRepository.getByUserDefinedEntityConfigId(
            this.userDefinedEntityConfig.id
          );
        }
      });

    subscriptionManager.subscribeToModelChanges(
      EntityName.UserDefinedEntityConfigPropertyConfig,
      updatePropertyDefinitions
    );
    updatePropertyDefinitions();

    const handle = this.permissionsService.getPermissionsHandleForEntity({
      entityName: EntityName.UserDefinedEntityConfig,
      entity: this.userDefinedEntityConfig
    });

    subscriptionManager.addDisposable(handle.subscribe());

    subscriptionManager.subscribeToExpression(
      handle,
      'canEditUserDefinedEntityConfigPropertyConfigs',
      () => {
        setCanCreatePropertyDefinitions(
          handle.canEditUserDefinedEntityConfigPropertyConfigs
        );
      }
    );
    setCanCreatePropertyDefinitions(
      handle.canEditUserDefinedEntityConfigPropertyConfigs
    );

    return subscriptionManager.toDisposable();
  }

  public createPropertyDefinition(
    order: number
  ): UserDefinedEntityConfigPropertyDefinitionHandle {
    const propertyCreationData: UserDefinedEntityConfigPropertyConfigCreationEntity =
      {
        name: '',
        type: PropertyType.TEXT,
        ownerUserGroupId: this.userDefinedEntityConfig.ownerUserGroupId,
        userDefinedEntityConfigId: this.userDefinedEntityConfig.id,
        order
      };

    const propertyConfig =
      this.entityManager.userDefinedEntityConfigPropertyConfigRepository.create(
        propertyCreationData
      );

    return this.handleCache.createCachedPropertyDefinitionHandle({
      propertyDefinition: propertyConfig
    });
  }

  public async deletePropertyDefinition({
    propertyDefinition
  }: ManagePropertyDefinitionsWidgetAdapterDeleteOptions<UserDefinedEntityConfigPropertyConfig>): Promise<void> {
    return Dialogs.deleteEntityDialog(
      propertyDefinition,
      EntityName.UserDefinedEntityConfigPropertyConfig
    ).then(() => {
      this.entityManager.userDefinedEntityConfigPropertyConfigRepository.delete(
        propertyDefinition
      );
    });
  }

  public arePropertyDefinitionsEqual(
    p1: UserDefinedEntityConfigPropertyConfig,
    p2: UserDefinedEntityConfigPropertyConfig
  ): boolean {
    return PropertyHelper.isTheSameProperty(p1, p2);
  }
}

export type ManageDefinedPropertiesWidgetUserDefinedEntityConfigAdapterOptions =
  {
    entityManager: AppEntityManager;
    rxjsService: RxjsService;
    subscriptionManagerService: SubscriptionManagerService;
    permissionsService: PermissionsService;
    userDefinedEntityConfig: UserDefinedEntityConfig;
  };
