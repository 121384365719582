import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Utils } from '../../classes/Utils/Utils';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { ProcessTaskMeasurePointMarkingDialog } from '../process-task-measure-point-marking-dialog/process-task-measure-point-marking-dialog';
import { ProcessTaskMeasurePointReadingCreationService } from '../../services/ProcessTaskMeasurePointReadingCreationService';
import { EditProcessTaskMeasurePointReadingDialog } from '../edit-process-task-measure-point-reading-dialog/edit-process-task-measure-point-reading-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskMeasurePoint } from '../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { ProcessTaskMeasurePointToPicture } from '../../classes/EntityManager/entities/ProcessTaskMeasurePointToPicture/types';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { MeasurePointCreatedEvent } from '../process-task-measure-point-creation-buttons/process-task-measure-point-creation-buttons';
import { ProcessTaskMeasurePointListItem } from './process-task-measure-point-list-item';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { ProcessTaskMeasurePointReading } from '../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types';
import { EditProcessTaskMeasurePointDialog } from '../edit-process-task-measure-point-dialog/edit-process-task-measure-point-dialog';

@autoinject()
export class ProcessTaskMeasurePointListWidget {
  @bindable()
  public appointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  /**
   * read-only!
   * count of the displayed measurePoints
   * is null when no measurePoints are loaded
   */
  @bindable()
  public measurePointCount: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  protected measurePoints: Array<ProcessTaskMeasurePoint> = [];
  protected canManageMeasurePoints: boolean = false;
  private displayedPicture: Picture | null = null;
  protected measurePointToPictures: Array<ProcessTaskMeasurePointToPicture> =
    [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly processTaskMeasurePointReadingCreationService: ProcessTaskMeasurePointReadingCreationService,
    private readonly permissionsService: PermissionsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.entityManager = entityManager;
    this.subscriptionManager = subscriptionManagerService.create();
    this.activeUserCompanySettingService = activeUserCompanySettingService;

    this.processTaskMeasurePointReadingCreationService =
      processTaskMeasurePointReadingCreationService;

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTask,
        context: this as ProcessTaskMeasurePointListWidget,
        propertyName: 'processTask'
      });
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'operations.appointmentMeasurePointManaging',
        (appointmentMeasurePointManaging) => {
          this.canManageMeasurePoints = appointmentMeasurePointManaging;
        }
      )
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskMeasurePoint,
      this.updateMeasurePoints.bind(this)
    );
    this.updateMeasurePoints();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected appointmentChanged(): void {
    this.updateMeasurePoints();
  }

  private updateMeasurePoints(): void {
    if (!this.appointment) {
      this.measurePoints = [];
      this.measurePointCount = null;
      return;
    }
    this.measurePoints =
      this.entityManager.processTaskMeasurePointRepository.getByProcessTaskId(
        this.appointment.ownerProcessTaskId
      );
    this.measurePointCount = this.measurePoints.length;
  }

  protected handleNewMeasurePointClick(): void {
    assertNotNullOrUndefined(
      this.appointment,
      "can't ProcessTaskMeasurePointListWidget.handleNewMeasurePointClick without appointment"
    );
    if (!this.appointment) return;

    const measurePoint =
      this.entityManager.processTaskMeasurePointRepository.create({
        ownerProcessTaskGroupId: this.appointment.ownerProcessTaskGroupId,
        ownerProcessTaskId: this.appointment.ownerProcessTaskId,
        ownerUserGroupId: this.appointment.ownerUserGroupId
      });

    this.updateMeasurePoints();
    setTimeout(() => {
      this.focusMeasurePoint(measurePoint);
    }, 20);
  }

  protected handleMarkMeasurePointClicked(
    measurePoint: ProcessTaskMeasurePoint
  ): void {
    if (!this.displayedPicture || !this.processTaskGroup) {
      return;
    }

    void ProcessTaskMeasurePointMarkingDialog.open({
      picture: this.displayedPicture,
      measurePoint: measurePoint,
      processTaskGroup: this.processTaskGroup
    });
  }

  protected handleMeasurePointCreated(): void {
    this.updateMeasurePoints();
  }

  protected async handlePictureMeasurePointClicked(
    event: MeasurePointCreatedEvent
  ): Promise<void> {
    const appointment = this.appointment;
    if (!appointment || !this.processTaskGroup) {
      return;
    }

    const measurePoint = event.detail.measurePoint;

    const measurePointReading = await this.getOrCreateReadingForMeasurePoint({
      measurePoint,
      appointment
    });

    if (measurePointReading) {
      void EditProcessTaskMeasurePointReadingDialog.open({
        processTaskGroup: this.processTaskGroup,
        measurePoint,
        measurePointReading
      });
    } else {
      void EditProcessTaskMeasurePointDialog.open({
        measurePoint
      });
    }
  }

  private async getOrCreateReadingForMeasurePoint({
    measurePoint,
    appointment
  }: {
    measurePoint: ProcessTaskMeasurePoint;
    appointment: ProcessTaskAppointment;
  }): Promise<ProcessTaskMeasurePointReading | null> {
    const readings =
      this.entityManager.processTaskMeasurePointReadingRepository.getByProcessTaskMeasurePointId(
        measurePoint.id
      );

    const existingReading = readings.find(
      (r) => r.processTaskAppointmentId === appointment.id
    );
    if (existingReading) {
      return existingReading;
    }

    const canCreateProcessTaskMeasurePointReadings =
      await this.permissionsService.useAdapterOnce({
        entityName: EntityName.ProcessTaskMeasurePoint,
        useAdapter: (adapter) => {
          return adapter.canCreateProcessTaskMeasurePointReadings(measurePoint);
        }
      });

    if (canCreateProcessTaskMeasurePointReadings) {
      return this.processTaskMeasurePointReadingCreationService.createReadingWithProperties(
        measurePoint,
        appointment
      );
    }

    return null;
  }

  private focusMeasurePoint(measurePoint: ProcessTaskMeasurePoint): void {
    const viewModel = this.getMeasurePointListItemViewModel(measurePoint);

    if (viewModel) {
      viewModel.focus();
    }
  }

  private getMeasurePointListItemViewModel(
    measurePoint: ProcessTaskMeasurePoint
  ): ProcessTaskMeasurePointListItem | null {
    const element = document.getElementById(
      this.getIdOfMeasurePointElement(measurePoint.id)
    );
    if (!element) {
      return null;
    }

    return Utils.getViewModelOfElement<ProcessTaskMeasurePointListItem>(
      element
    );
  }

  private getIdOfMeasurePointElement(measurePointId: string): string {
    return (
      'process-task-measure-point-list-widget--measure-point-' + measurePointId
    );
  }
}
