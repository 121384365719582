import { autoinject } from 'aurelia-framework';
import { ThingAndThingGroupNameService } from '../../services/ThingAndThingGroupNameService/ThingAndThingGroupNameService';

@autoinject()
export class EditViaWorkerDefects {
  constructor(
    private readonly thingAndThingGroupNameService: ThingAndThingGroupNameService
  ) {}

  protected attached(): void {
    void this.thingAndThingGroupNameService.refreshCache();
  }
}
