import { autoinject, bindable } from 'aurelia-framework';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { TooltipContent } from '../tooltip-content/tooltip-content';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event choice.name + '-clicked' - will be fired after the choiceSelected callback has been fired, be careful only lowercase names work
 * @event choice.name + '-changed' - will be fired when a file has been selected for a fileInput choice
 *    detail: Event (the changed event from the fileInput)
 *
 * @attribute data-overlay-style - set this attribute for the more button to take all available place with absolute positioning while staying invisible, useful for e.g. custom more-buttons, where you
 *   want a really specific icon/markup, just place the button in an element with relative positioning
 *
 * @attribute data-color-style - available values 'white', anything else will make it grey
 *
 * @slot default - slot for changing more-button button
 */
@autoinject()
export class MoreButton {
  /**
   * if the choice has a href attribute then the choiceSelected callback will not be called!
   * if no name is given then nothing will be passed to the choiceSelected callback!
   * if the choice is a checkbox the choiceSelected will be called when then checked state changed!
   * do not mutate this array, always change the whole array if you need to update it!
   */
  @bindable public choices: Array<MoreButtonChoice> = [];

  /**
   * available variables in `choice-selected.call`:
   *  name: string
   */
  @bindable public choiceSelected:
    | ((params: { name: string | null }) => void)
    | null = null;

  /**
   * available variables in `choice-file-changed.call`:
   *  name: string,
   *  originalEvent: Event
   */
  @bindable public choiceFileChanged:
    | ((params: { name: string | null; originalEvent: Event }) => void)
    | null = null;

  /**
   * element which the content should be positioned to
   * set to null to position the content relative to this element
   */
  @bindable public targetElement: HTMLElement | null = null;

  @bindable public hugeSize = false;

  private tooltipContent: TooltipContent | null = null;
  private domElement: HTMLElement;
  private opened = false;
  private choicesSubscriptionManager: SubscriptionManager;

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.choicesSubscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.updateBindings();
  }

  protected detached(): void {
    this.choicesSubscriptionManager.disposeSubscriptions();
  }

  public open(): void {
    this.tooltipContent?.open();
  }

  public close(): void {
    this.tooltipContent?.close();
  }

  // /////////// click handler /////////////

  protected handleToggleClick(event: MouseEvent): void {
    this.tooltipContent?.toggle();
    this.tooltipContent?.ignoreClickEvent(event);
  }

  protected handleChoiceClick(
    choice: MoreButtonChoice,
    event: MouseEvent
  ): boolean {
    // obviously when focusing a checkbox and pressing space should fire a click event on this anchor
    // and since canceling the fake click event also cancels the keydown event we have to have an ugly special case here
    // just ignore this fake event
    // also ignore the normal click event on the checkbox
    if (event.target instanceof HTMLInputElement) {
      return true;
    }

    if (!choice.href) {
      // this is in a try catch block because without it the event wouldn't get canceled in case of an error
      // and it will navigate to the 'home' page and you won't see the error then
      try {
        if (choice.isCheckbox) {
          // toggle the checkbox also when click on the anchor normally
          choice.checked = !choice.checked;
          this.updateChoiceChecked(choice);
        }

        this.handleChoiceSelected(choice);
      } catch (e) {
        console.error(e);
      }

      if (!choice.isCheckbox) {
        this.close();
      }
    }

    return !!choice.href; // only cancel the event when a href is given
  }

  private handleChoiceSelected(choice: MoreButtonChoice): void {
    if (typeof this.choiceSelected === 'function')
      this.choiceSelected({ name: choice.name || null });
    DomEventHelper.fireEvent<MoreButtonClickedEvent<any>>(this.domElement, {
      name: choice.name + '-clicked',
      detail: null
    });
  }

  private handleCheckedChanged(choice: MoreButtonChoice): void {
    this.updateChoiceChecked(choice);
    this.handleChoiceSelected(choice);
  }

  private handleFileInputChanged(
    choice: MoreButtonChoice,
    event: Event & { target: HTMLInputElement }
  ): void {
    this.choiceFileChanged &&
      this.choiceFileChanged({
        name: choice.name || null,
        originalEvent: event
      });
    this.close();
    DomEventHelper.fireEvent<MoreButtonFileChangedEvent<any>>(this.domElement, {
      name: choice.name + '-changed',
      detail: event
    });
  }

  private updateChoiceChecked(choice: MoreButtonChoice): void {
    if (choice.context && choice.propertyName) {
      choice.context[choice.propertyName] = choice.checked;
    }
  }

  // ////////// binding handling ///////////

  protected choicesChanged(): void {
    this.updateBindings();
  }

  private updateBindings(): void {
    this.choicesSubscriptionManager.disposeSubscriptions();
    this.choices &&
      this.choices.forEach((choice) => {
        if (choice.isCheckbox) {
          const context = choice.context;
          const propertyName = choice.propertyName;
          if (context && propertyName) {
            this.choicesSubscriptionManager.subscribeToPropertyChange(
              context,
              propertyName,
              () => {
                choice.checked = context[propertyName];
              }
            );
            choice.checked = context[propertyName];
          } else {
            console.error(
              'a checkbox choice needs a context and propertyName',
              choice
            );
          }
        }
        const disabledContext = choice.disabledContext;
        const disabledPropertyName = choice.disabledPropertyName;
        if (disabledContext && disabledPropertyName) {
          this.choicesSubscriptionManager.subscribeToPropertyChange(
            disabledContext,
            disabledPropertyName,
            () => {
              choice.disabled = disabledContext[disabledPropertyName];
            }
          );
          choice.disabled = disabledContext[disabledPropertyName];
        }
      });
  }
}

export type MoreButtonChoice =
  | TranslationKeyLabelMoreButtonChoice
  | LabelMoreButtonChoice;

export type TranslationKeyLabelMoreButtonChoice = {
  labelTk: string;
} & BaseMoreButtonChoice;
export type LabelMoreButtonChoice = { label: string } & BaseMoreButtonChoice;

type BaseMoreButtonChoice = {
  /** set this value if you need to identify a click on this choice in the `choiceSelected` callback */
  name?: string | null;

  /** set this value if your choice is a link and should navigate to a different page */
  href?: string | null;

  /** set this value if an icon should be displayed to the left of the text */
  iconClass?: string | null;

  /** if this is set to true, a propertyName and context must be given, this will bind the checkbox automatically to the context */
  isCheckbox?: boolean;

  /** currently only used for the checkbox */
  context?: Record<string, any> | null;

  /** propertyName of the context which this config should be bound to, see isCheckbox */
  propertyName?: string | null;

  /** context for disabled property value */
  disabledContext?: Record<string, any> | null;

  /** name of disabled property of disabledContext */
  disabledPropertyName?: string | null;

  /** if this is set to true, behaves as a hidden file input */
  isFileInput?: boolean;

  /** acceptable file types of input element */
  fileInputAccept?: string | null;

  /** multiple file input */
  fileInputMultiple?: boolean;

  // internal use only:
  checked?: boolean;
  disabled?: boolean;
};

export type MoreButtonClickedEvent<T extends string> = NamedCustomEvent<
  T,
  null
>;
export type MoreButtonFileChangedEvent<T extends string> = NamedCustomEvent<
  T,
  Event & { target: HTMLInputElement }
>;
