import { autoinject } from 'aurelia-framework';

import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { DateUtils } from '../../../../common/src/DateUtils';
import {
  StructureTemplateType,
  FlawNumberMajor,
  FlawNumberMinor
} from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { StructureTemplateRatingCategory } from '../../classes/EntityManager/entities/StructureTemplateRatingCategory/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { StructureTemplateEntryProperty } from '../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { configureHooks } from '../../hooks/configureHooks';

@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class EditStructureTemplateDialog {
  @subscribableLifecycle()
  protected readonly structureTemplatePermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplate];

  protected structureTemplate: StructureTemplate | null = null;

  protected structureTemplateRatingCategories: Array<StructureTemplateRatingCategory> =
    [];

  protected structureTemplateEntryProperties: Array<StructureTemplateEntryProperty> =
    [];

  protected dialog: RecordItDialog | null = null;
  protected structureTemplateTypes = Object.values(StructureTemplateType);
  protected StructureTemplateType = StructureTemplateType;
  protected DateUtils = DateUtils;

  protected flawNumberMinorOptions: Array<FlawNumberOption> = [
    {
      labelTk:
        'dialogs.editStructureTemplateDialog.flawNumbersMinorSequentialPerMajor',
      value: FlawNumberMinor.SEQUENTIAL_PER_MAJOR
    },
    {
      labelTk:
        'dialogs.editStructureTemplateDialog.flawNumbersMinorSequentialGlobal',
      value: FlawNumberMinor.SEQUENTIAL_GLOBAL
    }
  ];

  protected flawNumberMajorOptions: Array<FlawNumberOption> = [
    {
      labelTk:
        'dialogs.editStructureTemplateDialog.flawNumbersMajorFirstLevelListPosition',
      value: FlawNumberMajor.FIRST_LEVEL_LIST_POSITION
    },
    {
      labelTk: 'dialogs.editStructureTemplateDialog.flawNumbersMajorSequential',
      value: FlawNumberMajor.SEQUENTIAL
    }
  ];

  private onDialogClosed: TOnDialogClosedCallback | null = null;

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.structureTemplatePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.StructureTemplate,
        context: this,
        expression: 'structureTemplate'
      });
  }

  public open(options: IEditStructureTemplateDialogOptions): void {
    this.structureTemplate = options.structureTemplate;
    this.updateStructureTemplateRatingCategories();
    this.updateStructureTemplateEntryProperties();
    this.onDialogClosed = options.onDialogClosed || null;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateRatingCategory,
      this.updateStructureTemplateRatingCategories.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntryProperty,
      this.updateStructureTemplateEntryProperties.bind(this)
    );

    this.dialog?.open();
  }

  public static async open(
    options: IEditStructureTemplateDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private updateStructureTemplateRatingCategories(): void {
    if (!this.structureTemplate) {
      this.structureTemplateRatingCategories = [];
      return;
    }

    this.structureTemplateRatingCategories =
      this.entityManager.structureTemplateRatingCategoryRepository.getByStructureTemplateId(
        this.structureTemplate.id
      );
  }

  private updateStructureTemplateEntryProperties(): void {
    if (!this.structureTemplate) {
      this.structureTemplateEntryProperties = [];
      return;
    }

    this.structureTemplateEntryProperties =
      this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateId(
        this.structureTemplate.id
      );
  }

  protected handleDialogClosed(): void {
    const onDialogClosed = this.onDialogClosed;
    const structureTemplate = this.structureTemplate;

    this.structureTemplate = null;
    this.structureTemplateRatingCategories = [];
    this.structureTemplateEntryProperties = [];
    this.onDialogClosed = null;

    this.subscriptionManager.disposeSubscriptions();

    structureTemplate && onDialogClosed && onDialogClosed(structureTemplate);
  }

  protected handleStructureTemplateChanged(): void {
    if (!this.structureTemplate) return;
    this.entityManager.structureTemplateRepository.update(
      this.structureTemplate
    );
  }

  protected handleAddRatingCategoryButtonClicked(): void {
    assertNotNullOrUndefined(
      this.structureTemplate,
      'cannot handleAddRatingCategoryButtonClicked without a structureTemplate'
    );

    this.entityManager.structureTemplateRatingCategoryRepository.create({
      ownerStructureTemplateId: this.structureTemplate.id,
      ownerUserGroupId: this.structureTemplate.ownerUserGroupId
    });
  }

  protected getUserGroupNameById(userGroupId: string): string {
    const group = this.entityManager.userGroupRepository.getById(userGroupId);
    return (group && group.name) || '';
  }
}

interface IEditStructureTemplateDialogOptions {
  structureTemplate: StructureTemplate;
  onDialogClosed?: TOnDialogClosedCallback | null;
}

type TOnDialogClosedCallback = (structureTemplate: StructureTemplate) => void;

type FlawNumberOption = {
  labelTk: string;
  value: FlawNumberMajor | FlawNumberMinor;
};
