/**
 * Gauss-Jordan elimination
 * originally from package https://www.npmjs.com/package/linear-solve
 *
 * If you want to solve A.x = B,
 * choose data=A and mirror=B.
 */

import { MatrixUtils } from '../MatrixUtils';

export class GaussJordan {
  /**
   * Solves A.x = b
   * @param A
   * @param b
   * @return x
   */
  public static solve(
    matrix: Array<Array<number>>,
    mirror: Array<number>
  ): Array<number> {
    if (!MatrixUtils.isSquareMatrix(matrix) || matrix.length !== mirror.length)
      throw new Error(
        'Gauss Jordan solver requires a square matrix and the resulting array needs to be of the same length.'
      );

    const copiedMatrix = this.copyData(matrix);
    const copiedMirror = this.copyData(mirror);

    const result = this.gauss(copiedMatrix, copiedMirror);
    return this.convertSingleRowMatrixToArray(result);
  }

  public static invertMatrix(
    matrix: Array<Array<number>>
  ): Array<Array<number>> {
    const copiedMatrix = this.copyData(matrix);
    const copiedMirror = this.copyData(
      MatrixUtils.getIdentityMatrix(matrix.length)
    );

    return this.gauss(copiedMatrix, copiedMirror);
  }

  private static copyData(
    data: Array<Array<number>> | Array<number>
  ): Array<Array<number>> {
    if (!Array.isArray(data[0]))
      return this.convertArrayToMatrix(data as Array<number>);

    const copiedData = new Array(data.length);
    const numberOfColumns = data[0].length;

    for (let i = 0; i < data.length; i++) {
      copiedData[i] = new Array(numberOfColumns);

      const col = data[i];
      if (!col || !Array.isArray(col))
        throw new Error(
          'data should be a matrix here. This should never happen.'
        );

      for (let j = 0; j < numberOfColumns; j++) {
        copiedData[i][j] = col[j];
      }
    }

    return copiedData;
  }

  private static convertSingleRowMatrixToArray(
    matrix: Array<Array<number>>
  ): Array<number> {
    const array: Array<number> = [];
    for (const row of matrix) {
      if (row[0] !== undefined) array.push(row[0]);
    }

    return array;
  }

  private static convertArrayToMatrix(
    array: Array<number>
  ): Array<Array<number>> {
    const matrix: Array<Array<number>> = [];
    for (const row of array) {
      matrix.push([row]);
    }

    return matrix;
  }

  private static gauss(
    data: Array<Array<number>>,
    mirror: Array<Array<number>>
  ): Array<Array<number>> {
    let pivot = 0;
    const numberOfRows = data.length;
    if (!data[0])
      throw new Error(
        'data should be a matrix here. This should never happen.'
      );
    const numberOfColumns = data[0].length;
    const nullRows = [];

    for (let j = 0; j < numberOfColumns; j++) {
      const { maxRow, maxValue } = this.findRowWithMaxValueOnCol(
        data,
        pivot,
        j,
        numberOfRows
      );

      if (maxValue === 0) {
        // The matrix is not invertible. The system may still have solutions.
        nullRows.push(pivot);
      } else {
        // The value of the pivot is maxValue
        this.multiplyRow(data, maxRow, 1 / maxValue);
        this.multiplyRow(mirror, maxRow, 1 / maxValue);
        this.swapRows(data, maxRow, pivot);
        this.swapRows(mirror, maxRow, pivot);

        for (let i = 0; i < numberOfRows; i++) {
          if (i !== pivot) {
            const row = data[i];
            if (!row) continue;
            const val = row[j];
            if (!val) continue;

            this.addMultipliedRow(data, i, pivot, -val);
            this.addMultipliedRow(mirror, i, pivot, -val);
          }
        }
      }

      pivot++;
    }

    // Check that the system has null rows where it should
    for (let i = 0; i < nullRows.length; i++) {
      const num: number | undefined = nullRows[i];
      if (!num || !this.isRowComposedOfZeros(mirror, num)) {
        throw new Error('singular matrix');
      }
    }

    return mirror;
  }

  private static findRowWithMaxValueOnCol(
    data: Array<Array<number>>,
    pivot: number,
    currentColumn: number,
    numberOfRows: number
  ): { maxRow: number; maxValue: number } {
    let maxValue = 0;
    let maxRow = 0;

    // Find the row on which there is the maximum value of column j
    for (let k = pivot; k < numberOfRows; k++) {
      const row = data[k];
      if (!row) continue;
      const val = row[currentColumn];
      if (!val) continue;

      if (Math.abs(val) > Math.abs(maxValue)) {
        maxRow = k;
        maxValue = val;
      }
    }

    return { maxRow, maxValue };
  }

  private static swapRows(
    data: Array<Array<number>>,
    i: number,
    j: number
  ): void {
    const rowI = data[i];
    const rowJ = data[j];
    if (!rowI || !rowJ) return;

    data[i] = rowJ;
    data[j] = rowI;
  }

  private static multiplyRow(
    data: Array<Array<number>>,
    i: number,
    l: number
  ): void {
    const row = data[i];
    if (!row) return;

    for (let k = row.length - 1; k >= 0; k--) {
      const v = row[k];
      if (v === undefined) continue;
      row[k] = v * l;
    }
  }

  private static addMultipliedRow(
    data: Array<Array<number>>,
    i: number,
    j: number,
    l: number
  ): void {
    const rowI = data[i];
    const rowJ = data[j];

    if (!rowI || !rowJ) return;

    for (let k = rowI.length - 1; k >= 0; k--) {
      const v1: number | undefined = rowI[k];
      const v2: number | undefined = rowJ[k];
      if (v1 === undefined || v2 === undefined) continue;
      rowI[k] = v1 + l * v2;
    }
  }

  private static isRowComposedOfZeros(
    data: Array<Array<number>>,
    i: number
  ): boolean {
    const row = data[i];
    return row?.every((v) => v === 0) || false;
  }
}
