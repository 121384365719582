import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import moment from 'moment';

import { GroupedDefectEntries } from '../defect-entry-group/defect-entry-group';
import { DefectComment } from '../../classes/EntityManager/entities/DefectComment/types';
import { DefectChangeLogEntry } from '../../classes/EntityManager/entities/DefectChangeLogEntry/types';
import { EntityName } from '../../classes/EntityManager/entities/types';

/**
 * Is given a list of defect entries and displays them sorted into groups with status changes.
 *
 * This is here so the logic of splitting defect entries into defect entry groups doesn't have to be duplicated
 * whenever a list of defect entries is needed.
 */
@autoinject()
export class DefectEntryList {
  @bindable public defectComments: Array<DefectComment> = [];

  @bindable public defectChangeLogEntries: Array<DefectChangeLogEntry> = [];

  // ////////// GETTERS //////////

  @computedFrom('defectComments', 'defectChangeLogEntries')
  private get mappedDefectEntries(): Array<GroupedDefectEntries> {
    const entries: Array<GroupedDefectEntries> = [];
    for (const defectComment of this.defectComments) {
      entries.push({
        type: EntityName.DefectComment,
        date: this.getEntryDate(defectComment),
        createdAt: new Date(defectComment.created).getTime(),
        senderId: defectComment.senderId,
        entries: [defectComment]
      });
    }
    for (const defectChangeLogEntry of this.defectChangeLogEntries) {
      entries.push({
        type: EntityName.DefectChangeLogEntry,
        date: this.getEntryDate(defectChangeLogEntry),
        createdAt: new Date(defectChangeLogEntry.created).getTime(),
        senderId: defectChangeLogEntry.userId,
        entries: [defectChangeLogEntry]
      });
    }
    return entries.sort((a, b) => a.createdAt - b.createdAt);
  }

  // ////////// METHODS //////////

  private getEntryDate(entry: DefectEntry): string {
    return moment(entry.created).format('YYYYMMDD');
  }

  private getLast<T>(groups: Array<T>): T | null {
    return groups[groups.length - 1] ?? null;
  }

  /**
   * Returns true if two groups should be collapsed into one.
   */
  private shouldCollapse(
    a: GroupedDefectEntries,
    b: GroupedDefectEntries
  ): boolean {
    const isSameType = a.type === b.type;
    const isSameDate = a.date === b.date;
    const isSameUser = a.senderId === b.senderId;
    return isSameType && isSameDate && isSameUser;
  }

  // ////////// HTML GETTERS //////////

  /**
   * Returns all comments grouped by date and user, so that it can
   * more easily be passed to the defect-comment-group.
   */
  @computedFrom('defectComments', 'defectChangeLogEntries')
  protected get defectEntryGroups(): Array<GroupedDefectEntries> {
    const groups: Array<GroupedDefectEntries> = [];

    for (const section of this.mappedDefectEntries) {
      const lastGroup = this.getLast(groups);
      if (lastGroup != null && this.shouldCollapse(section, lastGroup)) {
        // @ts-ignore TS thinks we might be pushing into an array that could be the other type,
        // but this.shouldCollapse already makes sure we don't
        lastGroup.entries.push(...section.entries);
      } else {
        groups.push(section);
      }
    }
    return groups;
  }
}

type DefectEntry = DefectComment | DefectChangeLogEntry;
