import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { GisService } from '../../services/GisService';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { BasemapMap } from '../../map/basemap-map/basemap-map';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SelectCoordinatesOnMapDialog } from '../../dialogs/select-coordinates-on-map-dialog/select-coordinates-on-map-dialog';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntryToPerson } from '../../classes/EntityManager/entities/EntryToPerson/types';

@autoinject()
export class GalleryThingEntryToPersonWidget {
  @bindable public entry: Entry | null = null;
  @bindable public entryPicture: Picture | null = null;
  @bindable public thingId: string | null = null;

  /**
   * The `onMapReady` callback for the coordinate-input.
   */
  @bindable public onMapReady: ((map: BasemapMap) => void) | null = null;

  @subscribableLifecycle()
  protected readonly entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  protected municipalityCode: string | null = null;

  protected personWithRelations: Array<PersonWithRelation> = [];

  private personIdToAdd: string | null = null;

  private subscriptionManager: SubscriptionManager;

  constructor(
    private i18n: I18N,
    private entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private gisService: GisService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Entry,
        context: this as GalleryThingEntryToPersonWidget,
        propertyName: 'entry'
      });

    this.entryPermissionsHandle.canEditEntryToPersons;
  }

  public attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.EntryToPerson,
      this.updatePersonWithRelations.bind(this)
    );
    this.updatePersonWithRelations();
  }

  public detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected entryChanged(): void {
    this.updatePersonWithRelations();
  }

  private updatePersonWithRelations(): void {
    if (!this.entry) {
      this.personWithRelations = [];
      return;
    }

    const personWithRelations: Array<PersonWithRelation> = [];

    const entryToPersonEntries =
      this.entityManager.entryToPersonRepository.getByEntryId(this.entry.id);

    for (const entryToPerson of entryToPersonEntries) {
      const person = this.entityManager.personRepository.getById(
        entryToPerson.personId
      );

      if (person) {
        personWithRelations.push({ person, entryToPerson });
      }
    }

    this.personWithRelations = personWithRelations;
  }

  protected async handleNewCoordinatesReceived(
    latitude: number,
    longitude: number
  ): Promise<void> {
    assertNotNullOrUndefined(
      this.thingId,
      "can't handleNewCoordinatesReceived without thingId"
    );
    assertNotNullOrUndefined(
      this.entry,
      "can't handleNewCoordinatesReceived without entry"
    );

    const municipalityCode = this.entityManager.propertyRepository
      .getByThingId(this.thingId)
      .find((property) => property.name === 'Gemeindecode')?.value;
    if (!municipalityCode) {
      throw new Error(`no municipalityCode for "${this.thingId}" found`);
    }

    await this.gisService.syncGstInfoWithEntryForAdditionalOwners(
      this.entry,
      latitude,
      longitude,
      municipalityCode
    );
    this.updatePersonWithRelations();
  }

  protected async handleSelectCoordinateOnMapClick(): Promise<void> {
    const oldLat = this.entryPicture?.coords?.latitude;
    const oldLong = this.entryPicture?.coords?.longitude;

    void SelectCoordinatesOnMapDialog.open({
      lat: oldLat,
      long: oldLong,
      onCoordinateSelected: (newLat, newLong) => {
        if (oldLat !== newLat || oldLong !== newLong) {
          void this.handleNewCoordinatesReceived(newLat, newLong);
        }
      },
      onMapReady: (map) => {
        this.onMapReady && this.onMapReady(map);
      }
    });
  }

  protected handleAddPersonClick(): void {
    const personId = this.personIdToAdd;
    if (!this.entry || !personId) return;
    const entryToPersonEntries =
      this.entityManager.entryToPersonRepository.getByEntryId(this.entry.id);
    const existingEntryToPerson = entryToPersonEntries.find(
      (entryToPerson) => entryToPerson.personId === personId
    );
    if (!existingEntryToPerson) {
      this.entityManager.entryToPersonRepository.create({
        personId: personId,
        entryId: this.entry.id,
        ownerProjectId: this.entry.ownerProjectId,
        ownerUserGroupId: this.entry.ownerUserGroupId
      });
    } else {
      Dialogs.successDialog(
        this.i18n.tr(
          'galleryThing.entryToPersonWidget.personAlreadyLinkedDialogText'
        )
      );
    }
  }
}

type PersonWithRelation = {
  person: Person;
  entryToPerson: EntryToPerson;
};
