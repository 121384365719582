import { computedFrom } from 'aurelia-framework';

import {
  PropertyOption,
  PropertyType
} from 'common/Types/Entities/Property/PropertyDto';

import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { ThingSectionConfigProperty } from '../../../../classes/EntityManager/entities/ThingSectionConfigProperty/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import {
  PropertyDefinitionWidgetHandle,
  PropertyFeatures
} from '../PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';

export class ThingSectionConfigPropertyHandle
  implements PropertyDefinitionWidgetHandle<ThingSectionConfigProperty>
{
  private readonly entityManager: AppEntityManager;
  private readonly configProperty: ThingSectionConfigProperty;
  private readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingSectionConfigProperty];

  constructor(options: {
    entityManager: AppEntityManager;
    permissionsService: PermissionsService;
    configProperty: ThingSectionConfigProperty;
  }) {
    this.entityManager = options.entityManager;
    this.configProperty = options.configProperty;

    this.permissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.ThingSectionConfigProperty,
        entity: options.configProperty
      });
  }

  public subscribe(): Disposable {
    const permissionsDisposable = this.permissionsHandle.subscribe();

    return {
      dispose: () => {
        permissionsDisposable.dispose();
      }
    };
  }

  @computedFrom('configProperty.name')
  public get name(): string | null {
    return this.configProperty.name;
  }

  public setName(name: string | null): void {
    this.configProperty.name = name;
    this.entityManager.thingSectionConfigPropertyRepository.update(
      this.configProperty
    );
  }

  @computedFrom('configProperty.type')
  public get type(): PropertyType {
    return this.configProperty.type;
  }

  public setType(type: PropertyType): void {
    this.configProperty.type = type;
    this.entityManager.thingSectionConfigPropertyRepository.update(
      this.configProperty
    );
  }

  @computedFrom('configProperty.choices')
  public get choices(): Array<string> {
    return this.configProperty.choices;
  }

  public setChoices(choices: Array<string>): void {
    this.configProperty.choices = choices;
    this.entityManager.thingSectionConfigPropertyRepository.update(
      this.configProperty
    );
  }

  @computedFrom('configProperty.options')
  public get options(): Array<PropertyOption> {
    return this.configProperty.options;
  }

  public setOptions(options: Array<PropertyOption>): void {
    this.configProperty.options = options;
    this.entityManager.thingSectionConfigPropertyRepository.update(
      this.configProperty
    );
  }

  @computedFrom('permissionsHandle.canEditField.name')
  public get canEditName(): boolean {
    return this.permissionsHandle.canEditField.name;
  }

  @computedFrom('permissionsHandle.canEditField.type')
  public get canEditType(): boolean {
    return this.permissionsHandle.canEditField.type;
  }

  @computedFrom('permissionsHandle.canEditField.choices')
  public get canEditChoices(): boolean {
    return this.permissionsHandle.canEditField.choices;
  }

  @computedFrom('permissionsHandle.canEditField.options')
  public get canEditOptions(): boolean {
    return this.permissionsHandle.canEditField.options;
  }

  @computedFrom('permissionsHandle.canDeleteEntity')
  public get canBeDeleted(): boolean {
    return this.permissionsHandle.canDeleteEntity;
  }

  public getPropertyDefinition(): ThingSectionConfigProperty {
    return this.configProperty;
  }

  public getPropertyFeatures(): PropertyFeatures {
    return {
      canHavePositions: false,
      canHaveProjectParameter: false,
      canHaveTableEntries: false,
      allowDefaultValues: true
    };
  }

  public createPropertyWidgetConfiguration(): null {
    return null;
  }
}
