import { computedFrom } from 'aurelia-framework';
import { Thing } from '../../../classes/EntityManager/entities/Thing/types';
import { ThingAdapter } from '../EntityAdapter/ThingAdapter/ThingAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ThingPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ThingAdapter>,
  Thing
> {
  @computedFrom('combinedRevision')
  public get canEditPictures(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditPictures');
  }

  @computedFrom('combinedRevision')
  public get canEditNfcTokens(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditNfcTokens');
  }

  @computedFrom('combinedRevision')
  public get canCreateThingSections(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateThingSections');
  }

  @computedFrom('combinedRevision')
  public get canViewThingSections(): boolean {
    return this.checkAdapterSpecificPermissionByName('canViewThingSections');
  }

  @computedFrom('combinedRevision')
  public get canManageThingSectionProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canManageThingSectionProperties'
    );
  }

  @computedFrom('combinedRevision')
  public get canSeeUserDefinedEntities(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canSeeUserDefinedEntities'
    );
  }

  @computedFrom('combinedRevision')
  public get canAdministerValueCalculations(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canAdministerValueCalculations'
    );
  }

  @computedFrom('combinedRevision')
  public get canViewValueCalculationResults(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canViewValueCalculationResults'
    );
  }

  @computedFrom('combinedRevision')
  public get canAdministerValueCalculationsOrViewValueCalculationResults(): boolean {
    return (
      this.checkAdapterSpecificPermissionByName(
        'canAdministerValueCalculations'
      ) ||
      this.checkAdapterSpecificPermissionByName(
        'canViewValueCalculationResults'
      )
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProjects(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateProjects');
  }

  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditField');
  }

  @computedFrom('combinedRevision')
  public get canCreateDefects(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateDefects');
  }

  @computedFrom('combinedRevision')
  public get canCreateThingToPersons(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateThingToPersons');
  }

  @computedFrom('combinedRevision')
  public get canContactPersons(): boolean {
    return this.checkAdapterSpecificPermissionByName('canContactPersons');
  }

  @computedFrom('combinedRevision')
  public get canEditPersons(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditPersons');
  }

  @computedFrom('combinedRevision')
  public get canCreateThingAuthorizations(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateThingAuthorizations'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateRegions(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateRegions');
  }

  @computedFrom('combinedRevision')
  public get canCreateReports(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateReports');
  }

  @computedFrom('combinedRevision')
  public get canCreateUserDefinedEntities(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateUserDefinedEntities'
    );
  }
}
