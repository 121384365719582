import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { DateUtils } from 'common/DateUtils';
import { ProcessTaskNote } from '../../classes/EntityManager/entities/ProcessTaskNote/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { TTextChangedEvent } from '../../inputComponents/clickable-text-input/clickable-text-input';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessTaskInfoNote {
  @bindable()
  public note: ProcessTaskNote | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @subscribableLifecycle()
  protected readonly notePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskNote];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly i18n: I18N,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    permissionsService: PermissionsService
  ) {
    this.notePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskNote,
        context: this as ProcessTaskInfoNote,
        propertyName: 'note'
      });
  }

  @computed(
    expression('note.originallyCreatedByUserId'),
    expression('note.createdByUserId'),
    model(EntityName.User)
  )
  private get noteCreator(): User | null {
    if (!this.note || !this.note.createdByUserId) {
      return null;
    }
    if (this.note.originallyCreatedByUserId) {
      return this.entityManager.userRepository.getById(
        this.note.originallyCreatedByUserId
      );
    }
    return this.entityManager.userRepository.getById(this.note.createdByUserId);
  }

  @computed(expression('note'), expression('noteCreator'))
  protected get detailsTkParams(): DetailsTkParams {
    return {
      creator:
        this.noteCreator?.username ??
        this.i18n.tr('operations.processTaskInfoNote.noUserFallback'),
      datetime: DateUtils.formatToDateWithHourMinuteString(this.note?.createdAt)
    };
  }

  protected handleNoteFinished(): void {
    assertNotNullOrUndefined(
      this.processTask,
      'cannot ProcessTaskInfoNote.handleNoteFinished without processTask'
    );
    assertNotNullOrUndefined(
      this.note,
      'cannot ProcessTaskInfoNote.handleNoteFinished without note'
    );

    this.note.finishedAt = new Date().toISOString();
    this.entityManager.processTaskNoteRepository.update(this.note);

    void this.processTaskLoggingService.logNoteFinished(
      this.processTask,
      this.note
    );
  }

  protected handleNoteContentChanged(event: TTextChangedEvent): void {
    assertNotNullOrUndefined(
      this.processTask,
      'cannot ProcessTaskInfoNote.handleNoteContentChanged without processTask'
    );
    assertNotNullOrUndefined(
      this.note,
      'cannot ProcessTaskInfoNote.handleNoteContentChanged without note'
    );

    const oldContent = this.note.content;

    this.note.content = String(event.detail.value);
    this.entityManager.processTaskNoteRepository.update(this.note);

    void this.processTaskLoggingService.logNoteEdited(
      this.processTask,
      this.note,
      oldContent
    );
  }
}

type DetailsTkParams = { creator: string; datetime: string };
