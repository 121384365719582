import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { StructureTemplateEntryGroup } from '../../../../classes/EntityManager/entities/StructureTemplateEntryGroup/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { structureTemplateEntryGroupEntityInfo } from '../../../../classes/EntityManager/entities/StructureTemplateEntryGroup/structureTemplateEntryGroupEntityInfo';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';

export class StructureTemplateEntryGroupAdapter
  implements EntityAdapter<StructureTemplateEntryGroup>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;

  constructor(options: StructureTemplateEntryGroupAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return this.roleBasedPermissions != null;
  }

  public canDeleteEntity(
    structureTemplateEntryGroup: StructureTemplateEntryGroup
  ): boolean {
    return this.checkPermissionForDraftOnly({
      structureTemplateEntryGroup,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteStructureTemplateEntryGroups()
    });
  }

  public canEditField(
    structureTemplateEntryGroup: StructureTemplateEntryGroup
  ): boolean {
    return this.checkPermission({
      structureTemplateEntryGroup,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateStructureTemplateEntryGroups()
    });
  }

  public canEditStructureTemplateEntryGroupToStructureTemplateEntries(
    structureTemplateEntryGroup: StructureTemplateEntryGroup
  ): boolean {
    return this.checkPermissionForDraftOnly({
      structureTemplateEntryGroup,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateStructureTemplateEntryGroups()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.StructureTemplateEntryGroup,
    StructureTemplateEntryGroup
  > {
    return structureTemplateEntryGroupEntityInfo;
  }

  private checkPermissionForDraftOnly({
    structureTemplateEntryGroup,
    checkRolePermission
  }: {
    structureTemplateEntryGroup: StructureTemplateEntryGroup;
    checkRolePermission: (
      roleBasedUserGroupSpecificPermissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    const structureTemplate =
      this.entityManager.structureTemplateRepository.getById(
        structureTemplateEntryGroup.ownerStructureTemplateId
      );
    if (
      !structureTemplate ||
      structureTemplate.status !== StructureTemplateStatus.DRAFT
    ) {
      return false;
    }

    return this.checkPermission({
      structureTemplateEntryGroup,
      checkRolePermission
    });
  }

  private checkPermission({
    structureTemplateEntryGroup,
    checkRolePermission
  }: {
    structureTemplateEntryGroup: StructureTemplateEntryGroup;
    checkRolePermission: (
      roleBasedUserGroupSpecificPermissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return checkRolePermission(
      this.roleBasedPermissions.inUserGroupId(
        structureTemplateEntryGroup.ownerUserGroupId
      )
    );
  }
}

export type StructureTemplateEntryGroupAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
