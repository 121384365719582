import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { userDefinedEntityConfigPropertyConfigEntityInfo } from '../../../../classes/EntityManager/entities/UserDefinedEntityConfigPropertyConfig/userDefinedEntityConfigPropertyConfigEntityInfo';
import { UserDefinedEntityConfigPropertyConfig } from '../../../../classes/EntityManager/entities/UserDefinedEntityConfigPropertyConfig/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { UserDefinedEntityConfigAdapter } from '../UserDefinedEntityConfigAdapter/UserDefinedEntityConfigAdapter';

export class UserDefinedEntityConfigPropertyConfigAdapter
  implements EntityAdapter<UserDefinedEntityConfigPropertyConfig>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private userDefinedEntityConfigAdapter: UserDefinedEntityConfigAdapter | null =
    null;

  constructor(options: UserDefinedEntityConfigPropertyConfigAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.UserDefinedEntityConfig,
        onNextAdapter: ({ adapter }) => {
          this.userDefinedEntityConfigAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.userDefinedEntityConfigAdapter != null &&
      this.userDefinedEntityConfigAdapter.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(
    userDefinedEntityConfigPropertyConfig: UserDefinedEntityConfigPropertyConfig
  ): boolean {
    return this.canEditUserDefinedEntityConfigPropertyConfig(
      userDefinedEntityConfigPropertyConfig
    );
  }

  public canEditField(
    userDefinedEntityConfigPropertyConfig: UserDefinedEntityConfigPropertyConfig
  ): boolean {
    return this.canEditUserDefinedEntityConfigPropertyConfig(
      userDefinedEntityConfigPropertyConfig
    );
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.UserDefinedEntityConfigPropertyConfig,
    UserDefinedEntityConfigPropertyConfig
  > {
    return userDefinedEntityConfigPropertyConfigEntityInfo;
  }

  private canEditUserDefinedEntityConfigPropertyConfig(
    userDefinedEntityConfigPropertyConfig: UserDefinedEntityConfigPropertyConfig
  ): boolean {
    if (!this.userDefinedEntityConfigAdapter) {
      return false;
    }

    const userDefinedEntityConfig =
      this.entityManager.userDefinedEntityConfigRepository.getById(
        userDefinedEntityConfigPropertyConfig.userDefinedEntityConfigId
      );

    if (!userDefinedEntityConfig) {
      return false;
    }

    return this.userDefinedEntityConfigAdapter.canEditUserDefinedEntityConfigPropertyConfigs(
      userDefinedEntityConfig
    );
  }
}

export type UserDefinedEntityConfigPropertyConfigAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
