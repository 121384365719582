import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  GlobalUserDefinedEntity,
  ProjectUserDefinedEntity,
  ThingUserDefinedEntity,
  UserDefinedEntity
} from '../../classes/EntityManager/entities/UserDefinedEntity/types';

@autoinject()
export class UserDefinedEntitiesFilterService {
  constructor(private readonly entityManager: AppEntityManager) {}

  public filterGlobalUserDefinedEntityTemplates(
    userDefinedEntities: Array<UserDefinedEntity>,
    availableGlobalUserDefinedEntities: Array<GlobalUserDefinedEntity>
  ): Array<GlobalUserDefinedEntity> {
    return this.filterByGlobalUserDefinedEntityId(
      userDefinedEntities,
      availableGlobalUserDefinedEntities
    );
  }

  public filterThingUserDefinedEntityTemplates(
    userDefinedEntities: Array<ProjectUserDefinedEntity>,
    availableThingUserDefinedEntities: Array<ThingUserDefinedEntity>
  ): Array<ThingUserDefinedEntity> {
    const globalUserDefinedEntityIds = availableThingUserDefinedEntities
      .map((x) => x.globalUserDefinedEntityId)
      .filter((x): x is string => x !== null);

    const availableGlobalUserDefinedEntities =
      this.entityManager.userDefinedEntityRepository
        .getByIds(globalUserDefinedEntityIds)
        .map((entity) => entity as GlobalUserDefinedEntity);

    const supportedGlobalTemplateIds = this.filterByGlobalUserDefinedEntityId(
      userDefinedEntities,
      availableGlobalUserDefinedEntities
    ).map((x) => x.id);

    const supportedTemplates = availableThingUserDefinedEntities
      .filter(
        (x) =>
          x.globalUserDefinedEntityId === null ||
          supportedGlobalTemplateIds.includes(x.globalUserDefinedEntityId!)
      )
      .map((entity) => entity as ThingUserDefinedEntity);

    return this.filterByCustomId(userDefinedEntities, supportedTemplates);
  }

  private filterByGlobalUserDefinedEntityId(
    userDefinedEntities: Array<UserDefinedEntity>,
    availableUserDefinedEntities: Array<GlobalUserDefinedEntity>
  ): Array<GlobalUserDefinedEntity> {
    const alreadyExistingIds = userDefinedEntities
      .filter((x) => x.globalUserDefinedEntityId && this.duplicateNotAllowed(x))
      .map((x) => x.globalUserDefinedEntityId);

    return availableUserDefinedEntities.filter(
      (x) => !alreadyExistingIds.includes(x.id)
    );
  }

  private filterByCustomId(
    userDefinedEntities: Array<ProjectUserDefinedEntity>,
    availableUserDefinedEntities: Array<ThingUserDefinedEntity>
  ): Array<ThingUserDefinedEntity> {
    const alreadyExistingIds = userDefinedEntities
      .filter((x) => x.customId && this.duplicateNotAllowed(x))
      .map((x) => x.customId);

    return availableUserDefinedEntities.filter(
      (x) => !alreadyExistingIds.includes(x.customId)
    );
  }

  private duplicateNotAllowed(userDefinedEntity: UserDefinedEntity): boolean {
    assertNotNullOrUndefined(
      userDefinedEntity.userDefinedEntityConfigId,
      'cannot copy userDefinedEntity without userDefinedEntityConfigId'
    );

    const userDefinedEntityConfig =
      this.entityManager.userDefinedEntityConfigRepository.getById(
        userDefinedEntity.userDefinedEntityConfigId
      );
    assertNotNullOrUndefined(
      userDefinedEntityConfig,
      'cannot copy userDefinedEntity without userDefinedEntityConfig'
    );

    return !userDefinedEntityConfig.allowDuplicates;
  }
}
