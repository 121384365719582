import { Utils } from '../../../Utils/Utils';
import { StructureTemplateEntryUtils } from '../StructureTemplateEntry/StructureTemplateEntryUtils';
import { StructureTemplateEntry } from '../StructureTemplateEntry/types';
import { EntityName } from '../types';
import { Entry, EntryCreationEntity } from './types';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntryUtils } from './EntryUtils';

export class EntryRepository extends AppEntityRepository<EntityName.Entry> {
  public create(
    creationEntity: EntryCreationEntity,
    options: { rearrangeEntryList: boolean } = { rearrangeEntryList: true }
  ): Entry {
    const desiredListPosition = creationEntity.list_position;

    if (options.rearrangeEntryList) {
      const siblings = this.getByParentId(
        creationEntity.ownerProjectId,
        creationEntity.page_depth_parent ?? null,
        creationEntity.group_name ?? null
      );
      creationEntity.list_position = siblings.length;
    }

    const createdEntry = super.create(creationEntity);

    if (desiredListPosition != null && options.rearrangeEntryList) {
      this.setListPositionOfEntry(createdEntry, desiredListPosition);
    }

    return createdEntry;
  }

  public deleteEntryAndUpdateListPosition(entry: Entry): void {
    const entryPosition = entry.list_position;
    const parentEntryId = entry.page_depth_parent;
    const subEntries = this.getByParentId(
      entry.ownerProjectId,
      entry.id,
      entry.group_name
    );

    this.moveFollowingSiblingsByOffset(entry, subEntries.length - 1);

    for (const [index, subEntry] of subEntries.entries()) {
      subEntry.page_depth_parent = parentEntryId;
      subEntry.list_position = entryPosition + index;
      this.update(subEntry);
    }

    this.delete(entry);
  }

  public deleteChildrenRecursively(entry: Entry): void {
    const subEntries = this.getByParentId(
      entry.ownerProjectId,
      entry.id,
      entry.group_name
    );
    for (const subEntry of subEntries) {
      this.deleteChildrenRecursively(subEntry);
    }
    this.delete(entry);
  }

  public setListPositionOfEntry(
    entry: Entry,
    newListPosition: number | null
  ): void {
    let siblings = this.getByParentId(
      entry.ownerProjectId,
      entry.page_depth_parent,
      entry.group_name
    );
    const oldIndex = siblings.findIndex((e) => e.id === entry.id);
    const listPosition =
      newListPosition != null ? newListPosition : siblings.length - 1;
    siblings = Utils.moveInArray(siblings, oldIndex, listPosition);
    this.refreshListPositions(siblings);
  }

  public setParentIdOfEntry(entry: Entry, parentId: string | null): void {
    const newParent = parentId ? this.getById(parentId) : null;
    const oldParentId = entry.page_depth_parent;

    const subEntriesOfNewParent = this.getByParentId(
      entry.ownerProjectId,
      parentId,
      entry.group_name
    );

    entry.list_position = subEntriesOfNewParent.length;
    entry.page_depth_parent = newParent ? newParent.id : null;
    this.update(entry);

    const subEntriesOfOldParent = this.getByParentId(
      entry.ownerProjectId,
      oldParentId,
      entry.group_name
    );
    this.refreshListPositions(subEntriesOfOldParent);
  }

  public getByStructureTemplateEntryAndProjectId(
    structureTemplateEntry: StructureTemplateEntry,
    projectId: string
  ): Array<Entry> {
    return this.getByProjectId(projectId, null).filter((e) =>
      StructureTemplateEntryUtils.entryDependsOnStructureTemplateEntry(
        structureTemplateEntry,
        e
      )
    );
  }

  public getNextSiblingOfEntry(entry: Entry): Entry | null {
    const siblings = this.getByParentId(
      entry.ownerProjectId,
      entry.page_depth_parent,
      entry.group_name
    );
    return (
      siblings.find((e) => {
        return e.id !== entry.id && e.list_position > entry.list_position;
      }) ?? null
    );
  }

  public getPreviousSiblingOfEntry(entry: Entry): Entry | null {
    const siblings = this.getByParentId(
      entry.ownerProjectId,
      entry.page_depth_parent,
      entry.group_name
    );
    return (
      siblings.reverse().find((e) => {
        return e.id !== entry.id && e.list_position < entry.list_position;
      }) ?? null
    );
  }

  public getSiblingsOfEntry(entry: Entry): Array<Entry> {
    return this.getByParentId(
      entry.ownerProjectId,
      entry.page_depth_parent,
      entry.group_name
    );
  }

  public getSubEntriesOfEntry(entry: Entry): Array<Entry> {
    return this.getByParentId(entry.ownerProjectId, entry.id, entry.group_name);
  }

  public getByParentId(
    projectId: string,
    parentId: string | null,
    groupName: string | null
  ): Array<Entry> {
    const entries = this.getByProjectId(projectId, groupName).filter((entry) =>
      this.compareParentIds(entry.page_depth_parent, parentId)
    );

    EntryUtils.sortInPlace(entries);

    return entries;
  }

  public getByProjectId(
    projectId: string,
    groupName: string | null
  ): Array<Entry> {
    return this.getAll().filter(
      (entry) => entry.project === projectId && entry.group_name === groupName
    );
  }

  public getPathByEntryId(entryId: string): Array<Entry> {
    let entry = this.getById(entryId);
    if (!entry) return [];

    const returnArray = [];
    do {
      returnArray.push(entry);
      entry = entry.page_depth_parent
        ? this.getById(entry.page_depth_parent)
        : null;
    } while (entry);
    return returnArray;
  }

  /**
   * Ensures that the all entries within the given path are synced to the server
   * (`shadowEntity` is set to `false`).
   *
   * @returns the same path that was supplied.
   */
  public createShadowEntitiesInPath(path: Array<Entry>): Array<Entry>;
  /**
   * Ensures that the entry with the given id and all parent entries are synced to the server
   * (`shadowEntity` is set to `false`).
   *
   * @returns the path of the entry with the given id as returned by {@link getPathByEntryId}
   */
  public createShadowEntitiesInPath(entryId: string): Array<Entry>;
  public createShadowEntitiesInPath(
    pathOrEntryId: Array<Entry> | string
  ): Array<Entry> {
    const path = Array.isArray(pathOrEntryId)
      ? pathOrEntryId
      : this.getPathByEntryId(pathOrEntryId);
    path
      .slice()
      .reverse()
      .forEach((entry) => {
        if (entry.shadowEntity) {
          entry.shadowEntity = false;
          this.update(entry);
        }
      });
    return path;
  }

  public getLevel(entryId: string): number {
    return this.getPathByEntryId(entryId).length;
  }

  private refreshListPositions(siblings: Array<Entry>): void {
    siblings.forEach((entry, index) => {
      if (index !== entry.list_position) {
        entry.list_position = index;
        this.update(entry);
      }
    });
  }

  private compareParentIds(a: string | null, b: string | null): boolean {
    return (
      EntryUtils.getNormalizedPageDepthParent(a) ===
      EntryUtils.getNormalizedPageDepthParent(b)
    );
  }

  private moveFollowingSiblingsByOffset(entry: Entry, offset: number): void {
    let nextSibling: Entry | null = entry;

    do {
      nextSibling = this.getNextSiblingOfEntry(nextSibling);
      if (nextSibling) {
        nextSibling.list_position += offset;
        this.update(nextSibling);
      }
    } while (nextSibling);
  }
}
