import { bindable, autoinject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskMeasurePointReadingCreationService } from '../../services/ProcessTaskMeasurePointReadingCreationService';

import { ProcessTaskMeasurePointMarkingDialog } from '../process-task-measure-point-marking-dialog/process-task-measure-point-marking-dialog';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { EditProcessTaskMeasurePointReadingDialog } from '../edit-process-task-measure-point-reading-dialog/edit-process-task-measure-point-reading-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { ProcessConfigurationMeasurePointType } from '../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskMeasurePoint } from '../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types';
import { ProcessTaskMeasurePointReading } from '../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EditProcessTaskMeasurePointDialog } from '../edit-process-task-measure-point-dialog/edit-process-task-measure-point-dialog';

/**
 * @event {MeasurePointCreatedEvent} measure-point-created
 */
@autoinject()
export class ProcessTaskMeasurePointCreationButtons {
  private static TEMPORARY_GROUP_NAME =
    'ProcessTaskMeasurePointCreationButtons';

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  /**
   * picture where to mark the measure point on
   */
  @bindable()
  public picture: Picture | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  private isAttached: boolean = false;

  protected measurePointTypes: Array<ProcessConfigurationMeasurePointType> = [];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskMeasurePointReadingCreationService: ProcessTaskMeasurePointReadingCreationService,
    private readonly permissionsService: PermissionsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTask,
        context: this as ProcessTaskMeasurePointCreationButtons,
        propertyName: 'processTask'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationMeasurePointType,
      this.updateMeasurePointTypes.bind(this)
    );
    this.updateMeasurePointTypes();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskGroupChanged(): void {
    if (this.isAttached) {
      this.updateMeasurePointTypes();
    }
  }

  private updateMeasurePointTypes(): void {
    if (this.processTaskGroup) {
      this.measurePointTypes =
        this.entityManager.processConfigurationMeasurePointTypeRepository.getByProcessConfigurationId(
          this.processTaskGroup.processConfigurationId
        );
    } else {
      this.measurePointTypes = [];
    }
  }

  protected handleCreateButtonClick(
    measurePointType: ProcessConfigurationMeasurePointType
  ): void {
    if (
      !this.processTask ||
      !this.processTaskAppointment ||
      !this.picture ||
      !this.processTaskGroup
    ) {
      return;
    }

    const measurePoint =
      this.entityManager.processTaskMeasurePointRepository.create({
        processConfigurationMeasurePointTypeId: measurePointType.id,
        ownerProcessTaskId: this.processTask.id,
        ownerProcessTaskGroupId: this.processTask.ownerProcessTaskGroupId,
        ownerUserGroupId: this.processTask.ownerUserGroupId,
        temporaryGroupName:
          ProcessTaskMeasurePointCreationButtons.TEMPORARY_GROUP_NAME,
        shadowEntity: true
      });

    void ProcessTaskMeasurePointMarkingDialog.open({
      measurePoint: measurePoint,
      picture: this.picture,
      processTaskGroup: this.processTaskGroup,
      onDialogClosed: (marked) => {
        void this.handleMarkingDialogClosed({
          measurePoint,
          marked,
          measurePointType
        });
      }
    });
  }

  private async handleMarkingDialogClosed({
    measurePoint,
    marked,
    measurePointType
  }: {
    measurePoint: ProcessTaskMeasurePoint;
    marked: boolean;
    measurePointType: ProcessConfigurationMeasurePointType;
  }): Promise<void> {
    if (!marked || !this.processTask || !this.processTaskAppointment) {
      this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
        ProcessTaskMeasurePointCreationButtons.TEMPORARY_GROUP_NAME
      );
      return;
    }

    this.entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
      ProcessTaskMeasurePointCreationButtons.TEMPORARY_GROUP_NAME,
      this.processTask.temporaryGroupName
    );

    const canCreateProcessTaskMeasurePointReadings =
      await this.permissionsService.useAdapterOnce({
        entityName: EntityName.ProcessTaskMeasurePoint,
        useAdapter: (adapter) => {
          return adapter.canCreateProcessTaskMeasurePointReadings(measurePoint);
        }
      });

    const measurePointReading = canCreateProcessTaskMeasurePointReadings
      ? this.processTaskMeasurePointReadingCreationService.createReadingWithProperties(
          measurePoint,
          this.processTaskAppointment
        )
      : null;

    this.handleMeasurePointCreated({
      measurePointType,
      measurePoint,
      measurePointReading
    });

    this.fireCreatedEvent({ measurePoint, measurePointReading });
  }

  private handleMeasurePointCreated({
    measurePointType,
    measurePoint,
    measurePointReading
  }: {
    measurePointType: ProcessConfigurationMeasurePointType;
    measurePoint: ProcessTaskMeasurePoint;
    measurePointReading: ProcessTaskMeasurePointReading | null;
  }): void {
    if (!this.processTaskGroup) {
      return;
    }

    if (measurePointReading) {
      void EditProcessTaskMeasurePointReadingDialog.open({
        processTaskGroup: this.processTaskGroup,
        measurePoint: measurePoint,
        measurePointReading: measurePointReading,
        onDialogClosed: () => {
          this.handleCreateButtonClick(measurePointType);
        }
      });
    } else {
      void EditProcessTaskMeasurePointDialog.open({
        measurePoint,
        onDialogClosed: () => {
          this.handleCreateButtonClick(measurePointType);
        }
      });
    }
  }

  protected getButtonStyle(
    measurePointType: ProcessConfigurationMeasurePointType
  ): Record<string, string> {
    const style: Record<string, string> = {};

    if (measurePointType.color) {
      style['background-color'] = measurePointType.color;
    }

    return style;
  }

  private fireCreatedEvent({
    measurePoint,
    measurePointReading
  }: {
    measurePoint: ProcessTaskMeasurePoint;
    measurePointReading: ProcessTaskMeasurePointReading | null;
  }): void {
    DomEventHelper.fireEvent<MeasurePointCreatedEvent>(this.element, {
      name: 'measure-point-created',
      detail: {
        measurePoint,
        measurePointReading
      }
    });
  }
}

export type MeasurePointCreatedEvent = NamedCustomEvent<
  'measure-point-created',
  {
    measurePoint: ProcessTaskMeasurePoint;
    measurePointReading: ProcessTaskMeasurePointReading | null;
  }
>;
