import { computedFrom } from 'aurelia-framework';

import {
  PropertyOption,
  PropertyType
} from 'common/Types/Entities/Property/PropertyDto';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';

import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import {
  PropertyWidgetBinding,
  PropertyWidgetBindingFeatureMap,
  PropertyWidgetBindingValueData
} from '../PropertyWidgetBindingConfiguration/PropertyWidgetBindingConfiguration';
import { PropertyWidgetConfiguration } from '../PropertyWidgetConfiguration/PropertyWidgetConfiguration';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../../classes/SubscriptionManager';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { UserDefinedEntityConfigPropertyConfig } from '../../../../classes/EntityManager/entities/UserDefinedEntityConfigPropertyConfig/types';

export function createUserDefinedEntityConfigPropertyDefinitionWidgetConfiguration(): UserDefinedEntityConfigPropertyDefinitionWidgetConfiguration {
  return {
    bindingConfiguration: {
      features: {
        default: true,
        person: false,
        generalFile: false,
        picture: false,
        position: false,
        projectId: false
      },
      createBinding: ({ container, context }) => {
        return new PropertyBinding({
          entityManager: container.get(AppEntityManager),
          subscriptionManagerService: container.get(SubscriptionManagerService),
          permissionsService: container.get(PermissionsService),
          property: context
        });
      }
    }
  };
}

export type UserDefinedEntityConfigPropertyDefinitionWidgetConfiguration =
  PropertyWidgetConfiguration<
    UserDefinedEntityConfigPropertyDefinitionFeatures,
    UserDefinedEntityConfigPropertyConfig
  >;

class PropertyBinding
  implements
    PropertyWidgetBinding<UserDefinedEntityConfigPropertyDefinitionFeatures>
{
  private readonly property: UserDefinedEntityConfigPropertyConfig;
  private readonly subscriptionManager: SubscriptionManager;

  constructor(options: PropertyBindingOptions) {
    this.property = options.property;

    this.subscriptionManager = options.subscriptionManagerService.create();
  }

  @computedFrom('property.name')
  public get name(): string | null {
    return this.property.name;
  }

  @computedFrom('property.type')
  public get type(): PropertyType {
    return PropertyHelper.getTypeOrDefault(this.property.type);
  }

  @computedFrom('property.value')
  public get value(): string | null {
    return null;
  }

  @computedFrom()
  public get customChoice(): string | null {
    return null;
  }

  @computedFrom('property.choices')
  public get choices(): Array<string> {
    return [];
  }

  @computedFrom('property.options')
  public get options(): Array<PropertyOption> {
    return [];
  }

  @computedFrom()
  public get children(): Array<never> {
    return [];
  }

  @computedFrom()
  public get canSetValueData(): false {
    return false;
  }

  @computedFrom()
  public get forceDisabled(): boolean {
    return false;
  }

  @computedFrom()
  public get features(): PropertyWidgetBindingFeatureMap<UserDefinedEntityConfigPropertyDefinitionFeatures> {
    return {
      default: true,
      generalFile: false,
      person: false,
      picture: false,
      position: false,
      projectId: false
    };
  }

  public setValueData(_valueData: PropertyWidgetBindingValueData): void {
    throw new Error(
      'tried to set a value to a userDefinedEntityConfigPropertyConfig'
    );
  }

  public dispose(): void {
    this.subscriptionManager.disposeSubscriptions();
  }
}

type PropertyBindingOptions = {
  entityManager: AppEntityManager;
  property: UserDefinedEntityConfigPropertyConfig;
  subscriptionManagerService: SubscriptionManagerService;
  permissionsService: PermissionsService;
};

type UserDefinedEntityConfigPropertyDefinitionFeatures = 'default';
