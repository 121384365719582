import { EntityInfo } from '@record-it-npm/synchro-common';
import { userDefinedEntityEntityInfo } from '../../../../classes/EntityManager/entities/UserDefinedEntity/userDefinedEntityEntityInfo';
import { UserDefinedEntity } from '../../../../classes/EntityManager/entities/UserDefinedEntity/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';

// TODO: special permission checking for thing/project/global entities
export class UserDefinedEntityAdapter
  implements EntityAdapter<UserDefinedEntity>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private thingIdsWhereUserIsAuthorized: Set<string> | null = null;
  private projectIdsWhereUserIsAuthorized: Set<string> | null = null;

  constructor(options: UserDefinedEntityAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ThingAuthorizationComputer,
        computeData: {},
        callback: ({
          thingIdsWhereUserIsAuthorized,
          projectIdsWhereUserIsAuthorized
        }) => {
          this.thingIdsWhereUserIsAuthorized = thingIdsWhereUserIsAuthorized;
          this.projectIdsWhereUserIsAuthorized =
            projectIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.thingIdsWhereUserIsAuthorized != null &&
      this.projectIdsWhereUserIsAuthorized != null
    );
  }

  public canDeleteEntity(userDefinedEntity: UserDefinedEntity): boolean {
    return this.checkPermission({
      userDefinedEntity,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteUserDefinedEntities()
    });
  }

  public canEditField(userDefinedEntity: UserDefinedEntity): boolean {
    return this.checkPermission({
      userDefinedEntity,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateUserDefinedEntities()
    });
  }

  public canEditProperties(userDefinedEntity: UserDefinedEntity): boolean {
    return this.checkPermission({
      userDefinedEntity,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateUserDefinedEntities()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.UserDefinedEntity,
    UserDefinedEntity
  > {
    return userDefinedEntityEntityInfo;
  }

  private checkPermission({
    userDefinedEntity,
    checkRolePermission
  }: {
    userDefinedEntity: UserDefinedEntity;
    checkRolePermission: (
      roleBasedUserGroupSpecificPermissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    if (userDefinedEntity.ownerProjectId) {
      return EntityAdapterUtils.checkProjectSubEntityPermission({
        entity: userDefinedEntity,
        projectIdsWhereUserIsAuthorized: this.projectIdsWhereUserIsAuthorized,
        roleBasedPermissions: this.roleBasedPermissions,
        checkRolePermission
      });
    }

    if (userDefinedEntity.thingId) {
      return EntityAdapterUtils.checkPermissionBasedOnThingId({
        thingId: userDefinedEntity.thingId,
        ownerUserGroupId: userDefinedEntity.ownerUserGroupId,
        thingIdsWhereUserIsAuthorized: this.thingIdsWhereUserIsAuthorized,
        roleBasedPermissions: this.roleBasedPermissions,
        checkRolePermission
      });
    }

    return checkRolePermission(
      this.roleBasedPermissions.inUserGroupId(
        userDefinedEntity.ownerUserGroupId
      )
    );
  }
}

export type UserDefinedEntityAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
