import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { UserDefinedEntityAllowCopyingOfLevel } from 'common/Types/Entities/ReportType/ReportTypeDto';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { UserDefinedEntityConfig } from '../../classes/EntityManager/entities/UserDefinedEntityConfig/types';
import {
  OnCreateNewUserDefinedEntitiesCallback,
  SupportedTemplateUserDefinedEntity,
  UserDefinedEntityOfBaseEntity
} from '../../dialogs/copy-user-defined-entity-dialog/copy-user-defined-entity-dialog';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import {
  GlobalUserDefinedEntity,
  ThingUserDefinedEntity
} from '../../classes/EntityManager/entities/UserDefinedEntity/types';
import { expression, model } from '../../hooks/dependencies';
import { computed } from '../../hooks/computed';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { UserDefinedEntityCreationService } from '../../classes/EntityManager/entities/UserDefinedEntity/UserDefinedEntityCreationService';
import { UserDefinedEntitiesFilterService } from '../../services/UserDefinedEntitiesFilterService/UserDefinedEntitiesFilterService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProjectUserDefinedEntities {
  @bindable public project: Project | null = null;
  @bindable public currentReportType: ReportType | null = null;

  @subscribableLifecycle()
  protected readonly projectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  protected readonly onCreateNewProjectUserDefinedEntitiesCallback: OnCreateNewUserDefinedEntitiesCallback<Project>;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly i18n: I18N,
    private userDefinedEntitiesFilterService: UserDefinedEntitiesFilterService,
    userDefinedEntityCreationService: UserDefinedEntityCreationService,
    permissionsService: PermissionsService
  ) {
    this.projectPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Project,
        context: this as ProjectUserDefinedEntities,
        propertyName: 'project'
      });

    this.onCreateNewProjectUserDefinedEntitiesCallback =
      userDefinedEntityCreationService.createProjectUserDefinedEntity.bind(
        userDefinedEntityCreationService
      );
  }

  @computed(
    expression('userDefinedEntities'),
    expression('project'),
    expression('currentReportType')
  )
  protected get userDefinedEntityWidgetConfigs(): Array<UserDefinedEntityWidgetConfig> {
    if (!this.currentReportType || !this.project) return [];

    const configs: Array<UserDefinedEntityWidgetConfig> = [];

    const templateConfigs =
      this.currentReportType.features?.userDefinedEntityWidgetConfig ?? [];
    if (!templateConfigs.length) return [];

    const availableGlobalUserDefinedEntities =
      this.entityManager.userDefinedEntityRepository.getGlobalByOwnerUserGroupId(
        this.project.ownerUserGroupId
      );

    const availableThingUserDefinedEntities =
      this.entityManager.userDefinedEntityRepository.getByThingId(
        this.project.thing
      );

    const globalTemplates =
      this.userDefinedEntitiesFilterService.filterGlobalUserDefinedEntityTemplates(
        this.userDefinedEntities,
        availableGlobalUserDefinedEntities
      );
    const thingTemplates =
      this.userDefinedEntitiesFilterService.filterThingUserDefinedEntityTemplates(
        this.userDefinedEntities,
        availableThingUserDefinedEntities
      );

    for (const templateConfig of templateConfigs) {
      const prefilteredEntityConfig =
        this.entityManager.userDefinedEntityConfigRepository
          .getAll()
          .find((x) => x.name === templateConfig.filteredEntityConfig);

      const widgetConfig: UserDefinedEntityWidgetConfig = {
        prefilteredByEntityConfigName: prefilteredEntityConfig?.name ?? null,
        label:
          templateConfig.label ??
          this.i18n.tr(
            'aureliaComponents.projectProperties.userDefinedEntitiesDefaultLabel'
          ),
        selectableUserDefinedEntityTemplates:
          this.getUserDefinedEntityTemplatesForWidgetConfig({
            copyPermission: templateConfig.allowCopyingOfLevel ?? {
              global: true,
              thing: true
            },
            globalTemplates,
            thingTemplates,
            prefilteredEntityConfig
          }),
        userDefinedEntities: this.getUserDefinedEntitiesForWidgetConfig({
          userDefinedEntities: this.userDefinedEntities,
          prefilteredEntityConfig
        })
      };

      configs.push(widgetConfig);
    }

    return configs;
  }

  @computed(model(EntityName.UserDefinedEntity), expression('project'))
  private get userDefinedEntities(): Array<
    UserDefinedEntityOfBaseEntity<Project>
  > {
    if (!this.project) return [];
    return this.entityManager.userDefinedEntityRepository.getByProjectId(
      this.project.id
    );
  }

  private getUserDefinedEntityTemplatesForWidgetConfig({
    copyPermission,
    globalTemplates,
    thingTemplates,
    prefilteredEntityConfig
  }: {
    copyPermission: UserDefinedEntityAllowCopyingOfLevel;
    globalTemplates: Array<GlobalUserDefinedEntity>;
    thingTemplates: Array<ThingUserDefinedEntity>;
    prefilteredEntityConfig: UserDefinedEntityConfig | undefined;
  }): Array<SupportedTemplateUserDefinedEntity<Project>> {
    const filteredGlobalTemplates = prefilteredEntityConfig
      ? globalTemplates.filter(
          (x) => x.userDefinedEntityConfigId === prefilteredEntityConfig.id
        )
      : globalTemplates;
    const filteredThingTemplates = prefilteredEntityConfig
      ? thingTemplates.filter(
          (x) => x.userDefinedEntityConfigId === prefilteredEntityConfig.id
        )
      : thingTemplates;

    const templates = [];
    if (copyPermission.thing) {
      templates.push(...filteredThingTemplates);
    }
    if (copyPermission.global) {
      templates.push(...filteredGlobalTemplates);
    }

    return templates;
  }

  private getUserDefinedEntitiesForWidgetConfig({
    userDefinedEntities,
    prefilteredEntityConfig
  }: {
    userDefinedEntities: Array<UserDefinedEntityOfBaseEntity<Project>>;
    prefilteredEntityConfig: UserDefinedEntityConfig | undefined;
  }): Array<UserDefinedEntityOfBaseEntity<Project>> {
    return prefilteredEntityConfig
      ? userDefinedEntities.filter(
          (x) => x.userDefinedEntityConfigId === prefilteredEntityConfig.id
        )
      : userDefinedEntities;
  }
}

type UserDefinedEntityWidgetConfig = {
  label: string;
  userDefinedEntities: Array<UserDefinedEntityOfBaseEntity<Project>>;
  selectableUserDefinedEntityTemplates: Array<
    SupportedTemplateUserDefinedEntity<Project>
  >;
  prefilteredByEntityConfigName: string | null;
};
