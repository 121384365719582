import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  ProcessTaskLogEntryAction,
  ProcessTaskLogEntryNoteFinishedData
} from 'common/processTaskLogEntryActions';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskLogEntry } from '../../../classes/EntityManager/entities/ProcessTaskLogEntry/types';
import { ProcessTaskNote } from '../../../classes/EntityManager/entities/ProcessTaskNote/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { computed } from '../../../hooks/computed';
import { expression, model } from '../../../hooks/dependencies';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { ProcessTaskLoggingService } from '../../../services/ProcessTaskLoggingService';

@autoinject()
export class ProcessTaskLogEntriesViewerLogEntryCard {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskLogEntry: ProcessTaskLogEntry | null = null;

  @bindable()
  public sortedProcessTaskLogEntries: Array<ProcessTaskLogEntry> = [];

  @subscribableLifecycle()
  protected readonly processTaskNoteToRestorePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskNote];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    permissionsService: PermissionsService
  ) {
    this.processTaskNoteToRestorePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskNote,
        context: this as ProcessTaskLogEntriesViewerLogEntryCard,
        expression: 'processTaskNoteToRestore'
      });
  }

  @computed(
    model(EntityName.ProcessTaskNote),
    expression('processTaskLogEntry.logAction'),
    expression('processTaskLogEntry.logDataJson')
  )
  private get processTaskNoteToRestore(): ProcessTaskNote | null {
    if (
      !this.processTaskLogEntry ||
      this.processTaskLogEntry.logAction !==
        ProcessTaskLogEntryAction.NOTE_FINISHED
    ) {
      return null;
    }

    const logData = JSON.parse(
      this.processTaskLogEntry.logDataJson ?? ''
    ) as Partial<ProcessTaskLogEntryNoteFinishedData>;

    if (logData.restored || !logData.processTaskNoteId) {
      return null;
    }

    const processTaskNote =
      this.entityManager.processTaskNoteRepository.getById(
        logData.processTaskNoteId
      );

    // we don't offer the restore button if the note doesn't need to be restored
    if (processTaskNote && !processTaskNote.finishedAt) {
      return null;
    }

    return processTaskNote;
  }

  protected handleFooterRestoreButtonClick(): void {
    const processTaskNoteToRestore = this.processTaskNoteToRestore;
    assertNotNullOrUndefined(
      processTaskNoteToRestore,
      'cannot ProcessTaskLogEntriesViewerLogEntry.handleFooterRestoreButtonClick without processTaskNoteToRestore'
    );
    assertNotNullOrUndefined(
      this.processTask,
      'cannot ProcessTaskLogEntriesViewerLogEntry.handleFooterRestoreButtonClick without processTask'
    );

    processTaskNoteToRestore.finishedAt = null;
    this.entityManager.processTaskNoteRepository.update(
      processTaskNoteToRestore
    );

    this.saveRestoredStateInLogData();

    void this.processTaskLoggingService.logNoteRestored(
      this.processTask,
      processTaskNoteToRestore
    );
  }

  private saveRestoredStateInLogData(): void {
    if (
      !this.processTaskLogEntry ||
      this.processTaskLogEntry.logAction !==
        ProcessTaskLogEntryAction.NOTE_FINISHED
    ) {
      return;
    }

    const logData = JSON.parse(
      this.processTaskLogEntry.logDataJson ?? ''
    ) as Partial<ProcessTaskLogEntryNoteFinishedData>;

    logData.restored = true;
    this.processTaskLogEntry.logDataJson = JSON.stringify(logData);
    this.entityManager.processTaskLogEntryRepository.update(
      this.processTaskLogEntry
    );
  }
}
