import { CameraPreviewFlashMode } from '@capacitor-community/camera-preview';

import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { UltraRapidFireWidgetEditDialog } from '../ultra-rapid-fire-widget-edit-dialog/ultra-rapid-fire-widget-edit-dialog';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { SavePictureFileDataUrlService } from '../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { CategorizedTagSyncService } from '../../services/CategorizedTagSyncService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { GlobalElements } from '../global-elements/global-elements';
import {
  Picture,
  PictureAdditionalMarking,
  PictureCoords
} from '../../classes/EntityManager/entities/Picture/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { TagIdsChangedEvent } from './urfw-categorized-tags-button/urfw-categorized-tags-button';
import { TagDeletedEvent } from '../tags-widget-list/tags-widget-list';
import { PropertyCreationBaseData } from '../../classes/EntityManager/entities/Property/types';
import { CameraOverlay } from '../camera-overlay/camera-overlay';
import { TUltraRapidFireWidgetOverlayConfiguration } from './ultra-rapid-fire-widget-overlay';
import { RegionIdChangedEvent } from './ultra-rapid-fire-widget-overlay-widget';

@autoinject()
export class UltraRapidFireWidget {
  private options: AnyOptions | null = null;

  private lastPicture: Picture | null = null;

  protected previewPictureAnimationPictureUrl: string | null = null;

  protected canUseDefectManagement: boolean = false;

  private subscriptionManager: SubscriptionManager;
  private permissionBindingHandle: PermissionBindingHandle;

  private removeTagSyncCallback: (() => void) | null = null;

  protected overlayConfiguration: TUltraRapidFireWidgetOverlayConfiguration | null =
    null;

  protected cameraOverlay: CameraOverlay | null = null;

  protected availableFlashModes: Array<CameraPreviewFlashMode> = [];
  protected currentFlashMode: CameraPreviewFlashMode | null = null;

  constructor(
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    permissionBindingService: PermissionBindingService,
    private readonly tagSyncService: CategorizedTagSyncService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canUseDefectManagement: 'canUseDefectManagement'
      }
    });
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'ultraRapidFireWidget.overlayConfiguration',
        (overlayConfiguration) => {
          this.overlayConfiguration = overlayConfiguration;
        }
      )
    );

    assertNotNullOrUndefined(
      this.cameraOverlay,
      'camera overlay is not attached'
    );
    this.subscriptionManager.addDisposable(
      this.cameraOverlay.subscribeToAvailableFlashModeChanges(
        ({ availableFlashModes }) => {
          this.availableFlashModes = availableFlashModes;
        }
      ),
      this.cameraOverlay.subscribeToCurrentFlashModeChanges(
        ({ currentFlashMode }) => {
          this.currentFlashMode = currentFlashMode;
        }
      )
    );

    this.permissionBindingHandle.subscribe();

    this.removeTagSyncCallback = this.tagSyncService.onChange(
      this.updateTags.bind(this)
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.permissionBindingHandle.unsubscribe();

    this.removeTagSyncCallback?.();
  }

  public static async start(options: AnyOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().start(options);
  }

  public start(options: AnyOptions): void {
    this.options = options;
    void this.cameraOverlay?.open();
  }

  protected async handleCapturePictureClick(): Promise<void> {
    assertNotNullOrUndefined(
      this.options,
      'cannot handle a capturePictureClick without options'
    );
    assertNotNullOrUndefined(
      this.cameraOverlay,
      'cannot capture picture without a camera overlay'
    );

    const pictureDataUrl = await this.cameraOverlay?.takePicture();

    const picture = await this.options.savePictureCallback({
      tagIds: this.tagSyncService.getTags(),
      regionId: this.options.options.regionId ?? null
    });
    if (!picture) return;

    this.savePictureFileDataUrlService.saveOriginalPictureDataUrl(
      picture,
      pictureDataUrl,
      false
    );

    this.lastPicture = picture;

    this.previewPictureAnimationPictureUrl = pictureDataUrl;
  }

  protected handleCloseButtonClick(): void {
    void this.cameraOverlay?.close();
    this.tagSyncService.reset();
  }

  protected handlePreviewPictureClick(): void {
    if (!this.lastPicture || !this.options) return;

    const type = this.options.options.type;
    switch (type) {
      case Type.GALLERY_THING:
        const galleryThingOptions = this.options
          .options as SavePictureCallbackThingOptions;

        void UltraRapidFireWidgetEditDialog.open({
          type: Type.GALLERY_THING,
          picture: this.lastPicture,
          thingId: galleryThingOptions.thingId,
          userGroupId: galleryThingOptions.ownerUserGroupId
        });
        break;

      case Type.PROJECT:
        const projectOptions = this.options
          .options as SavePictureCallbackProjectOptions;

        void UltraRapidFireWidgetEditDialog.open({
          type: Type.PROJECT,
          picture: this.lastPicture,
          projectId: projectOptions.projectId,
          userGroupId: projectOptions.ownerUserGroupId
        });
        break;

      default:
        throw new Error(`invalid type "${type}"`);
    }
  }

  protected handleCreateDefectClick(): void {
    assertNotNullOrUndefined(
      this.options?.createDefectCallback,
      'cannot create defect without createDefectCallback'
    );
    this.options.createDefectCallback();
  }

  private updateTags(tagIds: Array<string>): void {
    if (!this.options) return;

    this.options.options.tagIds = tagIds;
  }

  protected handleRegionIdChanged(event: RegionIdChangedEvent): void {
    if (!this.options) return;
    this.options.options.regionId = event.detail.regionId;
  }

  protected handleTagIdsChanged(event: TagIdsChangedEvent): void {
    this.tagSyncService.set(event.detail.tagIds);
  }

  protected handleTagDeleted(event: TagDeletedEvent): void {
    this.tagSyncService.remove(event.detail.tagId);
  }

  protected handleSwitchCamera(): void {
    void this.cameraOverlay?.switchStream();
  }

  protected handleSwitchFlashMode(): void {
    void this.cameraOverlay?.switchFlashMode();
  }
}

type Options<T extends SavePictureCallbackBaseOptions> = {
  savePictureCallback: SavePictureCallback;
  createDefectCallback?: () => void;
  options: T;
};

type SavePictureCallback = ({
  tagIds,
  regionId
}: {
  tagIds: Array<string>;
  regionId: string | null;
}) => Promise<Picture | null>;

export type SavePictureCallbackThingOptions = SavePictureCallbackBaseOptions & {
  type: Type.GALLERY_THING;
  thingId: string;
};

export type SavePictureCallbackProjectOptions =
  SavePictureCallbackBaseOptions & {
    type: Type.PROJECT;
    projectId: string;
  };

type SavePictureCallbackBaseOptions = {
  ownerUserGroupId: string;
  regionId?: string | null;
  personIds?: Array<string> | null;
  tagIds?: Array<string> | null;
  properties: Array<PropertyCreationBaseData>;
  coords?: PictureCoords | null;
  description?: string | null;
  additionalMarkings?: Array<PictureAdditionalMarking> | null;
};

type AnyOptions = Options<SavePictureCallbackAnyOptions>;

export type SavePictureCallbackAnyOptions =
  | SavePictureCallbackThingOptions
  | SavePictureCallbackProjectOptions;

export enum Type {
  GALLERY_THING = 'galleryThing',
  PROJECT = 'project'
}
