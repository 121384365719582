import { autoinject } from 'aurelia-framework';
import { Router, RouteConfig, NavigationInstruction } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';

import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';

import { SiteScrollLocker } from '../../classes/SiteScrollLocker';
import { EditStructureNavigator } from '../../classes/EditStructureNavigator';
import { StructureProjectPageContent } from '../../structureThing/structure-project-page-content/structure-project-page-content';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

// TODO: extract sorting into it's own class
@autoinject()
export class edit_b1300_project {
  private router: Router;
  private activeEntitiesService: ActiveEntitiesService;

  private navigator: EditStructureNavigator;

  protected structureProjectPageContent: StructureProjectPageContent | null =
    null;

  private project: Project | null = null;

  private params: Params | null = null;

  private isAttached = false;

  constructor(
    router: Router,
    private readonly entityManager: AppEntityManager,
    activeEntitiesService: ActiveEntitiesService,
    private readonly i18n: I18N
  ) {
    this.router = router;
    this.activeEntitiesService = activeEntitiesService;
    this.navigator = new EditStructureNavigator(router, {
      routeName: 'edit_b1300_project',
      mainEntityIdParamName: 'project_id'
    });
  }

  protected activate(
    params: Params,
    routeConfig: RouteConfig,
    navigationInstruction: NavigationInstruction
  ): void {
    if (this.navigator.consumeIgnoreNavigation({ navigationInstruction })) {
      return;
    }

    window.scroll(0, 0); // reset scroll

    this.project =
      this.entityManager.projectRepository.getById(params.project_id) || null;

    this.activeEntitiesService.setActiveProject(this.project);

    if (routeConfig.navModel && this.project?.name)
      routeConfig.navModel.title =
        this.i18n.tr(routeConfig.navModel.title) + ' - ' + this.project.name;

    this.params = params;

    if (this.isAttached) {
      this.initializeStructurePageContent();
    }
  }

  protected attached(): void {
    this.isAttached = true;
    this.initializeStructurePageContent();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected deactivate(): void {
    if (this.navigator.isIgnoringNavigation()) {
      return;
    }

    this.activeEntitiesService.setActiveProject(null);
    this.activeEntitiesService.setActiveEntry(null);

    SiteScrollLocker.unlockScrolling('edit_structure_entry_editing');
  }

  protected determineActivationStrategy(): string {
    return 'invoke-lifecycle';
  }

  private initializeStructurePageContent(): void {
    assertNotNullOrUndefined(this.params, 'params should be set');
    assertNotNullOrUndefined(
      this.structureProjectPageContent,
      'structureProjectPageContent is not available'
    );

    const openParameterPanel =
      this.router.currentInstruction.queryParams.open_parameter_panel;
    this.structureProjectPageContent.init(
      this.params.project_id,
      this.params.edit_entry,
      this.params.entry_id,
      this.navigator,
      openParameterPanel
    );
  }

  protected navigateToReportExport(projectId: string): void {
    this.router.navigateToRoute('export_b1300_project', {
      project_id: projectId
    });
  }
}

type Params = {
  project_id: string;
  entry_id?: string;
  edit_entry?: string;
};
