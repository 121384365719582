import { autoinject, bindable } from 'aurelia-framework';
import { TDefaultPropertyConfig } from 'common/Types/DefaultPropertyConfig';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { NumberUtils } from 'common/Utils/NumberUtils';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskPositionDetailEntry } from '../../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types';
import { ProcessTaskPositionDetailEntryProperty } from '../../../classes/EntityManager/entities/Property/types';
import { InstancePreserver } from '../../../classes/InstancePreserver/InstancePreserver';
import { TDetailEntryChangedEvent } from '../process-task-position-detail-entries-widget-table-row/process-task-position-detail-entries-widget-table-row';
import { ProcessTaskPositionRepository } from '../../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionRepository';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessTaskPositionDetailEntriesWidgetTable {
  @bindable()
  public processTaskPosition: ProcessTaskPosition | null = null;

  @bindable()
  public detailEntries: Array<ProcessTaskPositionDetailEntry> = [];

  @bindable()
  public defaultProperties: Array<TDefaultPropertyConfig> = [];

  /**
   * indicates that the defaultProperties have actually been loaded and are not just in the initial state
   */
  @bindable()
  public defaultPropertiesLoaded: boolean = false;

  @bindable()
  public detailEntryPropertiesByDetailEntryId: Map<
    string,
    Array<ProcessTaskPositionDetailEntryProperty>
  > = new Map();

  @subscribableLifecycle()
  protected readonly processTaskPositionPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskPosition];

  private isAttached: boolean = false;
  private rows: Array<Row> = [];
  private defaultProperty: TDefaultPropertyConfig | null = null;
  protected amountSum: number = 0;

  protected boundDeleteDetailEntry = this.deleteDetailEntry.bind(this);

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processTaskPositionPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskPosition,
        context: this as ProcessTaskPositionDetailEntriesWidgetTable,
        propertyName: 'processTaskPosition'
      });
  }

  protected attached(): void {
    this.isAttached = true;
    this.updateDefaultProperty();
    this.updateRows();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected detailEntriesChanged(): void {
    if (this.isAttached) {
      this.updateRows();
      this.updateAmountSum();
    }
  }

  protected detailEntryPropertiesByDetailEntryIdChanged(): void {
    if (this.isAttached) {
      this.updateRows();
    }
  }

  protected defaultPropertiesChanged(): void {
    if (this.isAttached) {
      this.updateDefaultProperty();
    }
  }

  protected defaultPropertiesLoadedChanged(): void {
    if (
      !this.defaultProperty &&
      this.isAttached &&
      this.defaultPropertiesLoaded &&
      this.processTaskPosition
    ) {
      console.error(
        'No fitting defaultProperty found. Using fallback view with no property.'
      );
    }
  }

  private updateDefaultProperty(): void {
    if (this.defaultProperties.length > 1) {
      console.error(
        'Too many default properties. We will just use the first fitting one and ignore the rest'
      );
    }

    this.defaultProperty =
      this.defaultProperties.find((p) => {
        return (
          (p.type === PropertyType.DROPDOWN ||
            p.type === PropertyType.RADIOBUTTON) &&
          p.choices?.length
        );
      }) ?? null;

    if (
      !this.defaultProperty &&
      this.isAttached &&
      this.defaultPropertiesLoaded &&
      this.processTaskPosition
    ) {
      console.error(
        'No fitting defaultProperty found. Using fallback view with no property.'
      );
    }

    this.updateRows();
  }

  private updateRows(): void {
    const defaultPropertyRows = this.createDefaultPropertyRows();
    const missingDetailEntryRows =
      this.createMissingDetailEntryRows(defaultPropertyRows);

    const newRows = [...defaultPropertyRows, ...missingDetailEntryRows];
    this.rows = InstancePreserver.createNewArray({
      originalArray: this.rows,
      newArray: newRows,
      getTrackingValue: (row) => row.combinedId
    });
  }

  private updateAmountSum(): void {
    this.amountSum = NumberUtils.sumNumbersWithMaxPrecision(
      ...this.detailEntries.map((e) => e.amount)
    );
  }

  private createDefaultPropertyRows(): Array<Row> {
    const rows: Array<Row> = [];

    if (this.defaultProperty) {
      const choices = this.defaultProperty.choices ?? [];
      for (const choice of choices) {
        const detailEntry = this.findDetailEntryWithProperty(
          this.defaultProperty.name,
          choice
        );
        const defaultProperty = {
          ...this.defaultProperty,
          value: choice
        };

        rows.push({
          combinedId: this.createCombinedId(defaultProperty, detailEntry),
          detailEntry: detailEntry,
          defaultProperty: defaultProperty
        });
      }
    }

    return rows;
  }

  private createMissingDetailEntryRows(
    defaultPropertyRows: Array<Row>
  ): Array<Row> {
    const rows: Array<Row> = [];

    for (const detailEntry of this.detailEntries) {
      const defaultPropertyRow = defaultPropertyRows.find(
        (row) => row.detailEntry === detailEntry
      );
      if (!defaultPropertyRow) {
        rows.push({
          combinedId: this.createCombinedId(null, detailEntry),
          defaultProperty: null,
          detailEntry: detailEntry
        });
      }
    }

    return rows;
  }

  private findDetailEntryWithProperty(
    propertyName: string | null,
    propertyValue: string
  ): ProcessTaskPositionDetailEntry | null {
    return (
      this.detailEntries.find((entry) => {
        const properties =
          this.detailEntryPropertiesByDetailEntryId.get(entry.id) ?? [];
        return !!properties.find(
          (p) => p.name === propertyName && p.value === propertyValue
        );
      }) ?? null
    );
  }

  protected handleDetailEntryChanged(event: TDetailEntryChangedEvent): void {
    this.entityManager.processTaskPositionDetailEntryRepository.update(
      event.detail.detailEntry
    );
  }

  private deleteDetailEntry(detailEntry: ProcessTaskPositionDetailEntry): void {
    this.entityManager.processTaskPositionDetailEntryRepository.delete(
      detailEntry
    );
  }

  protected createDetailEntryFactory(
    row: Row
  ): () => ProcessTaskPositionDetailEntry {
    return () => {
      const processTaskPositionRepository: ProcessTaskPositionRepository =
        this.entityManager.processTaskPositionRepository;
      processTaskPositionRepository.assertEntityIsTracked(
        this.processTaskPosition,
        "can't ProcessTaskPositionDetailEntriesWidgetTable.createDetailEntryFactory because processTaskPosition"
      );

      const detailEntry =
        this.entityManager.processTaskPositionDetailEntryRepository.create({
          amount: 0,
          processTaskPositionId: this.processTaskPosition.id,
          ownerProcessTaskId: this.processTaskPosition.ownerProcessTaskId,
          ownerProcessTaskGroupId:
            this.processTaskPosition.ownerProcessTaskGroupId,
          ownerUserGroupId: this.processTaskPosition.ownerUserGroupId,
          temporaryGroupName: this.processTaskPosition.temporaryGroupName,
          shadowEntity: this.processTaskPosition.shadowEntity
        });

      this.entityManager.propertyRepository.create({
        ...row.defaultProperty,
        processTaskPositionDetailEntryId: detailEntry.id,
        ownerUserGroupId: detailEntry.ownerUserGroupId,
        ownerProcessTaskId: this.processTaskPosition.ownerProcessTaskId,
        ownerProcessTaskGroupId:
          this.processTaskPosition.ownerProcessTaskGroupId,
        temporaryGroupName: this.processTaskPosition.temporaryGroupName,
        shadowEntity: this.processTaskPosition.shadowEntity
      });

      return detailEntry;
    };
  }

  protected getMaximumPropertyValueLength(
    defaultPropertyChoices: Array<string> | undefined
  ): number {
    return (defaultPropertyChoices || []).reduce((maxLength, choice) => {
      return Math.max(maxLength, choice.length);
    }, 0);
  }

  private createCombinedId(
    defaultProperty: TDefaultPropertyConfig | null,
    detailEntry: ProcessTaskPositionDetailEntry | null
  ): string {
    if (defaultProperty) {
      return `${defaultProperty.name}-${defaultProperty.value}`;
    }

    if (detailEntry) {
      return detailEntry.id.toString();
    }

    return 'empty entry';
  }
}

type RowWithDetailEntry = {
  combinedId: string;
  detailEntry: ProcessTaskPositionDetailEntry;
  defaultProperty: TDefaultPropertyConfig;
};

type RowWithoutDetailEntry = {
  combinedId: string;
  detailEntry: null;
  defaultProperty: TDefaultPropertyConfig;
};

type RowWithoutDetailProperty = {
  combinedId: string;
  detailEntry: ProcessTaskPositionDetailEntry;
  defaultProperty: null;
};

type Row =
  | RowWithDetailEntry
  | RowWithoutDetailEntry
  | RowWithoutDetailProperty;
