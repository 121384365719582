/**
 * TODO: add promise support for all animations when needed
 *
 * Certain animations also modify the display styling of the element to show/hide it.
 * if you want to hide an element by default, then set the display over the style attribute (and not via css),
 * because the show/in animations just remove the per element styling so you can define the display for the visible element by yourself
 */

import $ from 'jquery';

import { Utils } from '../Utils/Utils';
import { GlobalAnimationSettings } from './GlobalAnimationSettings';

export class ShowHideAnimator {
  private options: TShowHideAnimatorOptions = {
    defaultAnimationDuration: 250,
    scaleSmallValue: 0.8,
    scaleNormalValue: 1,
    keepDisplayIntact: false,
    fixedProperties: {}
  };

  private element: HTMLElement;

  private boundHandleAnimationComplete =
    this.handleAnimationComplete.bind(this);

  private oneTimeOnDoneOnly: boolean = false;

  private onDoneCallback: Function | null = null;

  private unscaledWidth = 0;

  constructor(
    element: HTMLElement,
    options?: Partial<TShowHideAnimatorOptions> | null
  ) {
    this.element = element;
    Object.assign(this.options, options);
  }

  /**
   * if oneTime is true the callback will only be called for the next animation that completes
   * if it is false then it will be called for every completed animation
   */
  public onDone(callback: Function, oneTime: boolean = false): void {
    this.onDoneCallback = callback;
    this.oneTimeOnDoneOnly = oneTime;
  }

  public setDefaultAnimationDuration(defaultAnimationDuration: number): void {
    this.options.defaultAnimationDuration = defaultAnimationDuration;
  }

  public scaleFadeIn(): Promise<void> {
    return new Promise((resolve) => {
      this.showElement();
      this.animate(
        {
          opacity: [1, 0],
          scaleX: [this.options.scaleNormalValue, this.options.scaleSmallValue],
          scaleY: [this.options.scaleNormalValue, this.options.scaleSmallValue]
        },
        {
          duration: GlobalAnimationSettings.scaleTime(
            this.options.defaultAnimationDuration
          ),
          complete: () => {
            this.handleAnimationComplete();
            resolve();
          }
        }
      );
    });
  }

  public scaleFadeOut(): Promise<void> {
    return new Promise((resolve) => {
      this.animate(
        {
          opacity: [0, 1],
          scaleX: [this.options.scaleSmallValue, this.options.scaleNormalValue],
          scaleY: [this.options.scaleSmallValue, this.options.scaleNormalValue]
        },
        {
          duration: GlobalAnimationSettings.scaleTime(
            this.options.defaultAnimationDuration
          ),
          complete: () => {
            this.hideElement();
            this.handleAnimationComplete();
            resolve();
          }
        }
      );
    });
  }

  public fadeIn(): Promise<void> {
    return new Promise((resolve) => {
      this.animate(
        {
          opacity: [1, 0]
        },
        {
          // we add a delay so showing the element does not interfere with a potential click handler
          delay: 1,
          duration: this.options.defaultAnimationDuration,
          begin: () => {
            this.showElement();
          },
          complete: () => {
            this.handleAnimationComplete();
            resolve();
          }
        }
      );
    });
  }

  public fadeOut(): Promise<void> {
    return new Promise((resolve) => {
      this.animate(
        {
          opacity: [0, 1]
        },
        {
          duration: this.options.defaultAnimationDuration,
          complete: () => {
            this.hideElement();
            this.handleAnimationComplete();
            resolve();
          }
        }
      );
    });
  }

  public animateBackgroundColor(
    from: ShowHideAnimatorColor | null,
    to: ShowHideAnimatorColor
  ): Promise<void> {
    return new Promise((resolve) => {
      const animationProperties = {};
      this.addBackgroundColorAnimationProperty(
        animationProperties,
        'backgroundColorRed',
        from,
        to,
        'red'
      );
      this.addBackgroundColorAnimationProperty(
        animationProperties,
        'backgroundColorGreen',
        from,
        to,
        'green'
      );
      this.addBackgroundColorAnimationProperty(
        animationProperties,
        'backgroundColorBlue',
        from,
        to,
        'blue'
      );
      this.addBackgroundColorAnimationProperty(
        animationProperties,
        'backgroundColorAlpha',
        from,
        to,
        'alpha'
      );

      this.animate(animationProperties, {
        duration: GlobalAnimationSettings.scaleTime(
          this.options.defaultAnimationDuration
        ),
        complete: () => {
          this.handleAnimationComplete();
          resolve();
        }
      });
    });
  }

  public moveInTop(): void {
    this.showElement();
    this.animate(
      {
        translateY: [0, '-100%']
      },
      {
        duration: this.options.defaultAnimationDuration,
        complete: this.boundHandleAnimationComplete
      }
    );
  }

  public moveOutTop(): void {
    this.animate(
      {
        translateY: ['-100%', 0]
      },
      {
        duration: this.options.defaultAnimationDuration,
        complete: () => {
          this.hideElement();
          this.handleAnimationComplete();
        }
      }
    );
  }

  public fadeMoveInLeft(): void {
    this.showElement();
    this.animate(
      {
        translateX: [0, '-100%'],
        opacity: [1, 0]
      },
      {
        duration: this.options.defaultAnimationDuration,
        complete: this.boundHandleAnimationComplete
      }
    );
  }

  public fadeMoveOutLeft(): void {
    this.animate(
      {
        translateX: ['-100%', 0],
        opacity: [0, 1]
      },
      {
        duration: this.options.defaultAnimationDuration,
        complete: () => {
          this.hideElement();
          this.handleAnimationComplete();
        }
      }
    );
  }

  public fadeMoveInRight(): void {
    this.showElement();
    this.animate(
      {
        translateX: [0, '100%'],
        opacity: [1, 0]
      },
      {
        duration: this.options.defaultAnimationDuration,
        complete: this.boundHandleAnimationComplete
      }
    );
  }

  public fadeMoveOutRight(): void {
    this.animate(
      {
        translateX: ['100%', 0],
        opacity: [0, 1]
      },
      {
        duration: this.options.defaultAnimationDuration,
        complete: () => {
          this.hideElement();
          this.handleAnimationComplete();
        }
      }
    );
  }

  /**
   * this does not change the display property
   */
  public slideIn(targetWidth: number): void {
    this.animate(
      {
        width: [targetWidth, 0]
      },
      {
        duration: this.options.defaultAnimationDuration,
        complete: this.boundHandleAnimationComplete
      }
    );
  }

  /**
   * this does not change the display property, only the width will be 0
   */
  public slideOut(): void {
    this.animate(
      {
        width: 0
      },
      {
        duration: this.options.defaultAnimationDuration,
        complete: this.boundHandleAnimationComplete
      }
    );
  }

  /**
   * this does not change the display property
   */
  public slideFadeIn(targetMaxWidth: number): void {
    this.animate(
      {
        maxWidth: [targetMaxWidth, 0],
        opacity: [1, 0]
      },
      {
        duration: this.options.defaultAnimationDuration,
        complete: this.boundHandleAnimationComplete
      }
    );
  }

  /**
   * this does not change the display property, only the width will be 0
   */
  public slideFadeOut(): void {
    this.animate(
      {
        maxWidth: 0,
        opacity: 0
      },
      {
        duration: this.options.defaultAnimationDuration,
        complete: this.boundHandleAnimationComplete
      }
    );
  }

  /**
   * @param {number|null} [targetHeight] - if this is given, the element will still explicitly have the height set after the animation
   */
  public fadeSlideDown(targetHeight?: number | null): Promise<void> {
    return new Promise((resolve) => {
      let resetHeight = false;
      this.showElement();

      if (targetHeight == null) {
        this.element.style.height = '';
        targetHeight = parseFloat(window.getComputedStyle(this.element).height);
        resetHeight = true; // we don't want to have a fixed height on the element after the animation is finished
      }

      this.animate(
        {
          opacity: [1, 0],
          height: [targetHeight, 0]
        },
        {
          duration: this.options.defaultAnimationDuration,
          complete: () => {
            if (resetHeight) {
              this.element.style.height = '';
            }

            this.handleAnimationComplete();
            resolve();
          }
        }
      );
    });
  }

  public fadeSlideUp(): Promise<void> {
    return new Promise((resolve) => {
      this.animate(
        {
          opacity: [0, 1],
          height: 0
        },
        {
          duration: this.options.defaultAnimationDuration,
          complete: () => {
            this.hideElement();
            this.handleAnimationComplete();
            resolve();
          }
        }
      );
    });
  }

  public slideDown(durationOverride?: number): Promise<void> {
    return new Promise((resolve) => {
      const duration =
        durationOverride != null
          ? durationOverride
          : this.options.defaultAnimationDuration;

      $(this.element).velocity('slideDown', {
        duration: GlobalAnimationSettings.scaleTime(duration),
        complete: () => {
          resolve();
        }
      });
    });
  }

  public slideUp(): Promise<void> {
    return new Promise((resolve) => {
      $(this.element).velocity('slideUp', {
        duration: GlobalAnimationSettings.scaleTime(
          this.options.defaultAnimationDuration
        ),
        complete: () => {
          resolve();
        }
      });
    });
  }

  public scaleToWidth(scaleFactor: number): Promise<void> {
    this.unscaledWidth = parseFloat(
      window.getComputedStyle(this.element).width
    );
    const targetWidth = this.unscaledWidth * scaleFactor;

    return new Promise((resolve) => {
      this.animate(
        {
          width: [targetWidth, this.unscaledWidth]
        },
        {
          duration: this.options.defaultAnimationDuration,
          complete: () => {
            this.handleAnimationComplete();
            resolve();
          }
        }
      );
    });
  }

  public scaleBackToDefaultWidth(): Promise<void> {
    return new Promise((resolve) => {
      this.animate(
        {
          width: this.unscaledWidth
        },
        {
          duration: this.options.defaultAnimationDuration,
          complete: () => {
            this.handleAnimationComplete();
            this.element.style.width = '';
            resolve();
          }
        }
      );
    });
  }

  public showSlideRight(targetWidth?: number | null): Promise<void> {
    this.showElement();
    if (targetWidth == null) {
      this.element.style.width = '';
      targetWidth = parseFloat(window.getComputedStyle(this.element).width);
    }
    return new Promise((resolve) => {
      this.animate(
        {
          width: [targetWidth, 0],
          opacity: [1, 0]
        },
        {
          duration: this.options.defaultAnimationDuration,
          complete: () => {
            this.handleAnimationComplete();
            resolve();
          }
        }
      );
    });
  }

  public hideSlideLeft(): Promise<void> {
    return new Promise((resolve) => {
      this.animate(
        {
          width: 0,
          opacity: [0, 1]
        },
        {
          duration: this.options.defaultAnimationDuration,
          complete: () => {
            this.hideElement();
            this.handleAnimationComplete();
            resolve();
          }
        }
      );
    });
  }

  /**
   * stops all pending animations and removes them from the queue
   */
  public clearAnimations(): void {
    $(this.element).velocity('stop', true);
  }

  public getElement(): HTMLElement {
    return this.element;
  }

  public resetHiddenStatus(): void {
    this.showElement();
  }

  private handleAnimationComplete(): void {
    if (this.onDoneCallback) {
      this.onDoneCallback();
    }

    if (this.oneTimeOnDoneOnly) {
      this.onDoneCallback = null;
    }
  }

  private hideElement(): void {
    if (!this.options.keepDisplayIntact) {
      this.element.style.display = 'none';
    }
  }

  private showElement(): void {
    if (!this.options.keepDisplayIntact) {
      this.element.style.display = '';
    }
    this.element.style.opacity = '1';
  }

  private animate(
    properties: PartialRecord<any>,
    options: jquery.velocity.Options
  ): void {
    this.addFixedProperties(properties);
    // eslint-disable-next-line new-cap
    $.Velocity(this.element, properties, {
      ...options,
      duration:
        typeof options.duration === 'number'
          ? GlobalAnimationSettings.scaleTime(options.duration)
          : options.duration
    });
  }

  private addFixedProperties(properties: PartialRecord<any>): void {
    if (this.options.fixedProperties) {
      for (const key in this.options.fixedProperties) {
        if (!this.options.fixedProperties.hasOwnProperty(key)) {
          continue;
        }

        const value = this.options.fixedProperties[key];
        properties[key] = [value, value]; // force the values from start on
      }
    }

    this.scaleTranslate('x', properties);
    this.scaleTranslate('y', properties);
  }

  /**
   * since translate is also influence by the scale (and we still want items to be centered while animating),
   * we need to scale the translate values
   */
  private scaleTranslate(
    dimension: 'x' | 'y',
    properties: PartialRecord<any>
  ): void {
    const scalePropertyName = `scale${dimension.toLocaleUpperCase()}`;
    const translatePropertyName = `translate${dimension.toLocaleUpperCase()}`;

    const scaleProperty = properties[scalePropertyName];
    const translateProperty = properties[translatePropertyName];

    if (translateProperty && scaleProperty) {
      // retrieve the endScale + startScale if possible
      // all animated value should be an array anyway, so the array check here is just so we don't break things completely
      const endScale =
        Array.isArray(scaleProperty) && scaleProperty[0] != null
          ? scaleProperty[0]
          : scaleProperty;
      const startScale =
        Array.isArray(scaleProperty) && scaleProperty[1] != null
          ? scaleProperty[1]
          : 1;

      // split the values from the unit so we can calculate the new value correctly
      const translateEnd = Utils.parseNumberWithUnit(
        Array.isArray(translateProperty) ? translateProperty[0] : ''
      );
      const translateStart = Utils.parseNumberWithUnit(
        Array.isArray(translateProperty) && translateProperty[1] != null
          ? translateProperty[1]
          : ''
      );

      properties[translatePropertyName] = []; // in case a single value has been passed which is not in an array, we replace it

      if (translateEnd) {
        properties[translatePropertyName][0] =
          translateEnd.value / endScale + translateEnd.unit;
      }

      if (startScale && translateStart) {
        properties[translatePropertyName][1] =
          translateStart.value / startScale + translateStart.unit;
      }
    }
  }

  /**
   * @param {Object} animationProperties - this gets modified in place
   * @param {string} animationPropertyName
   * @param {ShowHideAnimatorColor|null} from
   * @param {ShowHideAnimatorColor} to
   * @param {string} colorName
   * @private
   */
  private addBackgroundColorAnimationProperty(
    animationProperties: PartialRecord<any>,
    animationPropertyName: string,
    from: ShowHideAnimatorColor | null,
    to: ShowHideAnimatorColor,
    colorName: keyof ShowHideAnimatorColor
  ): void {
    if (from && from[colorName] != null) {
      animationProperties[animationPropertyName] = [
        to[colorName],
        from[colorName]
      ];
    } else {
      animationProperties[animationPropertyName] = to[colorName];
    }
  }
}

type TShowHideAnimatorOptions = {
  /** in ms */
  defaultAnimationDuration: number;

  scaleSmallValue: number;
  scaleNormalValue: number;

  /**
   * set this to true if you don't want the ShowHideAnimator to change the display styling of the element
   * (useful if you want to handle that yourself)
   */
  keepDisplayIntact: boolean;

  /**
   * e.g. if the transform is overwriting the css styling, you can force the values of the properties here, e.g. with {translateX: '-50%'} you can still use a
   * scaling animation
   */
  fixedProperties: PartialRecord<any>;
};

export type ShowHideAnimatorColor = {
  red?: number | null;
  green?: number | null;
  blue?: number | null;
  alpha?: number | null;
};

type PartialRecord<T> = Partial<Record<string, T>>;

export type AnimationFunctionNamesWhichDontRequireArguments =
  | 'scaleFadeIn'
  | 'scaleFadeOut'
  | 'fadeIn'
  | 'fadeOut'
  | 'moveInTop'
  | 'moveOutTop'
  | 'fadeMoveInLeft'
  | 'fadeMoveOutLeft'
  | 'fadeMoveInRight'
  | 'fadeMoveOutRight'
  | 'fadeSlideDown'
  | 'fadeSlideUp'
  | 'slideDown'
  | 'slideUp'
  | 'scaleBackToDefaultWidth'
  | 'showSlideRight'
  | 'hideSlideLeft';
