import { HooksContainer } from '@record-it-npm/synchro-client';
import { EntityName } from '../../../../../../common/src/Types/Entities/Base/ClientEntityName';
import { GlobalLoadingOverlay } from '../../../../loadingComponents/global-loading-overlay/global-loading-overlay';
import { JoinEntityQueue } from '../../../JoinEntityQueue/JoinEntityQueue';
import { Logger } from '../../../Logger/Logger';
import {
  Disposable,
  DisposableContainer
} from '../../../Utils/DisposableContainer';
import { IUtilsRateLimitedFunction, Utils } from '../../../Utils/Utils';
import { AppEntityManager } from '../AppEntityManager';

export class JoinedDefectsManager {
  private started: boolean = false;
  private disposableContainer: DisposableContainer = new DisposableContainer();
  private finishedAutoJoiningDefectGroups: boolean = true;

  private readonly hooksContainer: HooksContainer<JoinedDefectManagerHooks> =
    new HooksContainer();

  private readonly joinEntityQueue: JoinEntityQueue;
  private readonly rateLimitedUpdateDefectsWithActualization: IUtilsRateLimitedFunction;
  private readonly rateLimitedUpdateDefectsWithoutActualization: IUtilsRateLimitedFunction;

  constructor(
    private readonly entityManager: AppEntityManager,
    options: Options
  ) {
    this.joinEntityQueue = new JoinEntityQueue({
      sendJoinEntityRequest: options.joinDefect,
      sendLeaveEntityRequest: options.leaveDefect,
      onEntityActivelyJoined: () => {}
    });

    this.rateLimitedUpdateDefectsWithActualization = Utils.rateLimitFunction(
      this.updateDefects.bind(this, true),
      100
    );

    this.rateLimitedUpdateDefectsWithoutActualization = Utils.rateLimitFunction(
      this.updateDefects.bind(this, false),
      100
    );
  }

  public start(): void {
    this.started = true;
    this.joinEntityQueue.start();
    this.autoJoinDefects();

    this.disposableContainer.add(
      this.rateLimitedUpdateDefectsWithActualization.toCancelDisposable()
    );
    this.disposableContainer.add(
      this.rateLimitedUpdateDefectsWithoutActualization.toCancelDisposable()
    );

    this.disposableContainer.add({
      dispose: () => {
        this.rateLimitedUpdateDefectsWithActualization.callImmediatelyIfPending();
      }
    });

    this.disposableContainer.add(
      this.entityManager.defectRepository.registerHooks({
        afterEntityUpdatedLocally:
          this.rateLimitedUpdateDefectsWithActualization,
        afterEntityUpdated: this.rateLimitedUpdateDefectsWithActualization,
        afterEntityDeleted: this.rateLimitedUpdateDefectsWithActualization,
        afterEntityRemovedLocally:
          this.rateLimitedUpdateDefectsWithActualization
      })
    );

    this.disposableContainer.add(
      this.entityManager.entityActualization.registerHooks({
        afterActualization: this.rateLimitedUpdateDefectsWithoutActualization
      })
    );

    this.disposableContainer.add(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.Defect,
        (entity) => {
          void this.joinEntityQueue.entityIdUpgraded({ entity });
        }
      )
    );
  }

  public stop(): void {
    this.started = false;

    this.disposableContainer.disposeAll();
    this.joinEntityQueue.stop();
  }

  public async joinAllDefects(): Promise<void> {
    if (!this.started) {
      throw new Error(
        'cannot use the joinedDefectsManager if it is not started'
      );
    }

    GlobalLoadingOverlay.setLoadingState(this, true);
    await this.updateDefects(true);
  }

  public autoJoiningDefectsIsFinished(): boolean {
    return this.finishedAutoJoiningDefectGroups;
  }

  public registerHooks(hooks: JoinedDefectManagerHooks): Disposable {
    return this.hooksContainer.registerHooks(hooks);
  }

  private autoJoinDefects(): void {
    void this.updateDefects(false).then(() => {
      this.finishedAutoJoiningDefectGroups = true;
      this.hooksContainer.callHooks('autoJoiningDefectsFinished');
    });
  }

  private async updateDefects(actualizeAfterJoining: boolean): Promise<void> {
    this.rateLimitedUpdateDefectsWithActualization.cancel();
    this.rateLimitedUpdateDefectsWithoutActualization.cancel();

    const relevantDefects = this.entityManager.defectRepository.getAll();

    const missingIds = relevantDefects
      .filter((d) => !this.joinEntityQueue.entityIsActivelyJoined(d.id))
      .filter((d) => !d.onlyLocal)
      .map((d) => d.id);

    const superfluousIds = this.joinEntityQueue
      .getActivelyJoinedEntityIds()
      .filter((id) => !relevantDefects.find((d) => d.id === id));

    this.updateRelativeSynchronizationEntityIds(missingIds, superfluousIds);

    const promises = [
      ...missingIds.map((id) => this.joinEntityQueue.joinEntity(id)),
      ...superfluousIds.map((id) => this.joinEntityQueue.leaveEntity(id))
    ].filter((promise) => {
      // Only wait for new promises. If the promises were already queued, they are probably handled elsewhere
      return promise.queuedNewItem;
    });

    try {
      await Promise.all(promises);
      if (promises.length && actualizeAfterJoining) {
        void this.entityManager.entityActualization.actualize();
      }
    } catch (error) {
      Logger.logError({ error });
    } finally {
      GlobalLoadingOverlay.setLoadingState(this, false);
    }
  }

  private updateRelativeSynchronizationEntityIds(
    idsToAdd: Array<string>,
    idsToRemove: Array<string>
  ): void {
    for (const id of idsToAdd) {
      this.entityManager.entityActualization.addRelativeSynchronizationEntityId(
        EntityName.Defect,
        id
      );
    }

    for (const id of idsToRemove) {
      this.entityManager.entityActualization.removeRelativeSynchronizationEntityId(
        EntityName.Defect,
        id
      );
    }
  }
}

export type Options = {
  joinDefect: (defectId: string) => Promise<void>;
  leaveDefect: (defectId: string) => Promise<void>;
};

export type JoinedDefectManagerHooks = {
  autoJoiningDefectsFinished?: () => void;
};
