import { bindable } from 'aurelia-framework';

import { PictureRevisionTransformationCalculator } from 'common/PictureRevision/PictureRevisionTransformationCalculator';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import {
  Picture,
  PictureMarking
} from '../../classes/EntityManager/entities/Picture/types';
import { PictureRevision } from '../../classes/EntityManager/entities/PictureRevision/types';

export class ScaledMarkedPicture {
  /**
   * the picture which should be shown
   */
  @bindable public picture: Picture | null = null;

  /**
   * the picture revision which should be shown
   */
  @bindable public pictureRevision: PictureRevision | null = null;

  /**
   * the current, highlighted marking
   */
  @bindable public currentMarkings: Array<PictureMarking> = [];

  /**
   * other markings which should be shown on the picture
   */
  @bindable public markings: Array<PictureMarking> = [];

  @computed(expression('currentMarkings'))
  protected get transformedCurrentMarkings(): Array<PictureMarking> {
    if (!this.pictureRevision?.markingsTransformation)
      return this.currentMarkings;

    const transformedCurrentMarkings: Array<PictureMarking> = [];
    for (const marking of this.currentMarkings) {
      transformedCurrentMarkings.push(
        PictureRevisionTransformationCalculator.computeMarkingPositionsBasedOnTransformationMatrix(
          marking,
          this.pictureRevision.markingsTransformation
        )
      );
    }

    return transformedCurrentMarkings;
  }

  @computed(expression('markings'))
  protected get transformedMarkings(): Array<PictureMarking> {
    if (!this.pictureRevision?.markingsTransformation) return this.markings;

    const transformedMarkings: Array<PictureMarking> = [];
    for (const marking of this.markings) {
      transformedMarkings.push(
        PictureRevisionTransformationCalculator.computeMarkingPositionsBasedOnTransformationMatrix(
          marking,
          this.pictureRevision.markingsTransformation
        )
      );
    }

    return transformedMarkings;
  }
}
