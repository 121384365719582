import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskNoteDto } from './ProcessTaskNoteDto';
import { ProcessTaskGroupSubEntityUtils } from '../../BaseEntities/ProcessTaskGroupSubEntityUtils';

export function createProcessTaskNoteEntityInfo<
  TEntity extends ProcessTaskNoteDto<string, string>
>(
  options: CreateEntityInfoOptions<ProcessTaskNoteDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskNote,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskNote,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskNoteDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskGroupSubEntityUtils.createBaseFieldInfos(),
        content: { name: 'content', defaultValue: null },
        processConfigurationNoteCategoryId: {
          name: 'processConfigurationNoteCategoryId',
          ref: EntityName.ProcessConfigurationNoteCategory
        },
        finishedAt: { name: 'finishedAt', defaultValue: null },
        processTaskId: {
          name: 'processTaskId',
          ref: EntityName.ProcessTask,
          defaultValue: null,
          cascadeDeleteLocally: true
        },
        originallyCreatedByUserId: {
          name: 'originallyCreatedByUserId',
          ref: EntityName.User,
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
