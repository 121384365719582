import { autoinject, bindable } from 'aurelia-framework';
import { ProcessConfigurationNoteCategory } from '../../classes/EntityManager/entities/ProcessConfigurationNoteCategory/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { ProcessTaskNote } from '../../classes/EntityManager/entities/ProcessTaskNote/types';
import { Utils } from '../../classes/Utils/Utils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessTaskInfoNotes {
  @bindable public processTask: ProcessTask | null = null;

  @bindable public processTaskGroup: ProcessTaskGroup | null = null;

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    permissionsService: PermissionsService
  ) {
    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTask,
        context: this as ProcessTaskInfoNotes,
        propertyName: 'processTask'
      });
  }

  @computed(
    model(EntityName.ProcessConfigurationNoteCategory),
    expression('processTaskGroup')
  )
  protected get processConfigurationNoteCategories(): Array<ProcessConfigurationNoteCategory> {
    if (!this.processTaskGroup?.processConfigurationId) {
      return [];
    }

    return this.entityManager.processConfigurationNoteCategoryRepository.getByProcessConfigurationId(
      this.processTaskGroup.processConfigurationId
    );
  }

  @computed(
    model(EntityName.ProcessTaskNote),
    expression('processConfigurationNoteCategories'),
    expression('processTaskGroup'),
    expression('processTask')
  )
  private get unfinishedProcessTaskNotes(): Array<ProcessTaskNote> {
    if (!this.processTaskGroup || !this.processTask) {
      return [];
    }

    return (
      this.entityManager.processTaskNoteRepository
        .getByCategoryIds(
          this.processConfigurationNoteCategories.map((pcnc) => pcnc.id)
        )
        .filter(
          (ptn) => ptn.ownerProcessTaskGroupId === this.processTaskGroup!.id
        )
        // Since some old entities are not associated with a ProcessTask,
        // and were previously displayed in all ProcessTasks of a ProcessTaskGroup,
        // we continue this behaviour and load them in every process-task-info-notes component
        .filter(
          (ptn) =>
            !ptn.processTaskId || ptn.processTaskId === this.processTask!.id
        )
        .filter((ptn) => !ptn.finishedAt)
    );
  }

  @computed(expression('unfinishedProcessTaskNotes'))
  protected get groupedUnfinishedProcessTaskNotes(): Record<
    string,
    Array<ProcessTaskNote>
  > {
    return Utils.mapToRecord(
      Utils.groupBy(
        this.unfinishedProcessTaskNotes,
        (n) => n.processConfigurationNoteCategoryId ?? ''
      )
    );
  }

  protected handleAddNoteButtonClick(
    category: ProcessConfigurationNoteCategory
  ): void {
    assertNotNullOrUndefined(
      this.processTask,
      'cannot handleAddNoteButtonClick without processTask'
    );
    assertNotNullOrUndefined(
      this.processTaskGroup,
      'cannot handleAddNoteButtonClick without processTaskGroup'
    );

    this.entityManager.processTaskNoteRepository.create({
      content: '',
      processConfigurationNoteCategoryId: category.id,
      finishedAt: null,
      ownerProcessTaskGroupId: this.processTaskGroup.id,
      processTaskId: this.processTask.id,
      ownerUserGroupId: this.processTaskGroup.ownerUserGroupId
    });

    void this.processTaskLoggingService.logNoteCreated(this.processTask);
  }
}
