import { autoinject, bindable } from 'aurelia-framework';

import { Dialogs } from '../../classes/Dialogs';
import { GalleryThingPictureCreatorService } from '../../services/GalleryThingPictureCreatorService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { PropertyCreationBaseData } from '../../classes/EntityManager/entities/Property/types';
import { TRegionSelectEvent } from '../../regionComponents/region-select/region-select';
import { ExifDataHelper } from '../../classes/Picture/ExifDataHelper';

@autoinject()
export class PictureUploadWidget {
  @bindable public thing: Thing | null = null;

  @bindable public properties: Array<PropertyCreationBaseData> | null = null;

  private fileInput: HTMLInputElement | null = null;

  protected numFilesSelected = 0;

  protected regionId: string | null = null;

  constructor(
    private readonly galleryThingPictureCreatorService: GalleryThingPictureCreatorService
  ) {}

  protected async attached(): Promise<void> {
    await this.handleFileInputChanged();
  }

  protected handleRegionChanged(event: TRegionSelectEvent): void {
    this.regionId = event.detail.value;
  }

  protected handleFileInputChanged(): void {
    this.numFilesSelected = this.fileInput?.files?.length ?? 0;
  }

  protected async handleUploadButtonClicked(): Promise<void> {
    const files = this.fileInput?.files;
    if (!files || files.length === 0) {
      return;
    }
    if (!this.regionId) {
      try {
        await Dialogs.yesNoDialogTk(
          'aureliaComponents.pictureUploadWidget.noRegionWarning'
        );
      } catch (error) {
        if (error instanceof Error && error.message === 'DialogCanceled')
          return;
        throw error;
      }
    }
    for (const file of files) {
      await this.upload(file);
    }
    await this.clearFileInput();
  }

  protected async handleCancelButtonClicked(): Promise<void> {
    await this.clearFileInput();
  }

  private async clearFileInput(): Promise<void> {
    if (!this.fileInput) return;

    this.fileInput.value = '';
    await this.handleFileInputChanged();
  }

  private async upload(file: File): Promise<void> {
    if (!this.thing) return;

    await this.galleryThingPictureCreatorService.createPictureFromFile(file, {
      thing: this.thing,
      regionId: this.regionId,
      properties: this.properties,
      coords: await ExifDataHelper.getCoordinatesFromExifData(file)
    });
  }
}
