import { EntityInfo } from '@record-it-npm/synchro-common';

import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';

import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { projectEntityInfo } from '../../../../classes/EntityManager/entities/Project/projectEntityInfo';
import { Project } from '../../../../classes/EntityManager/entities/Project/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../../../classes/EntityManager/entities/User/types';
import { ActiveUserCompanySettingService } from '../../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { PermissionHelper } from '../../../../classes/PermissionHelper';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { LockedByFinishedAppointmentsProjectIdsComputer } from '../../../../computedValues/computers/LockedByFinishedAppointmentsProjectIdsComputer/LockedByFinishedAppointmentsProjectIdsComputer';
import { LockedBySignedReportProjectIdsComputer } from '../../../../computedValues/computers/LockedBySignedReportProjectIdsComputer/LockedBySignedReportProjectIdsComputer';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';

export class ProjectAdapter implements EntityAdapter<Project> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly activeUserCompanySettingService: ActiveUserCompanySettingService;
  private readonly currentUserService: CurrentUserService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private projectIdsWhereUserIsAuthorized: Set<string> | null = null;
  private editableUserGroupIds: Set<string> | null = null;
  private thingSectionsSetting: boolean | null = null;
  private currentUser: User | null = null;

  private lockedByFinishedAppointmentsProjectIdsSet: Set<string> | null = null;
  private lockedExportBySignedReportProjectIds: Set<string> | null = null;

  constructor(options: ProjectAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.activeUserCompanySettingService =
      options.activeUserCompanySettingService;
    this.currentUserService = options.currentUserService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      }),

      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      }),

      this.computedValueService.subscribe({
        valueComputerClass: LockedByFinishedAppointmentsProjectIdsComputer,
        computeData: {},
        callback: (lockedByFinishedAppointmentsProjectIdsSet) => {
          this.lockedByFinishedAppointmentsProjectIdsSet =
            lockedByFinishedAppointmentsProjectIdsSet;
          updateBindings();
        }
      }),

      this.computedValueService.subscribe({
        valueComputerClass: LockedBySignedReportProjectIdsComputer,
        computeData: {},
        callback: ({ lockedExportProjectIds }) => {
          this.lockedExportBySignedReportProjectIds = lockedExportProjectIds;
          updateBindings();
        }
      }),

      this.computedValueService.subscribe({
        valueComputerClass: ThingAuthorizationComputer,
        computeData: {},
        callback: ({ projectIdsWhereUserIsAuthorized }) => {
          this.projectIdsWhereUserIsAuthorized =
            projectIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.thingSections',
        (thingSections) => {
          this.thingSectionsSetting = thingSections ?? false;
          updateBindings();
        }
      )
    );

    subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
        updateBindings();
      })
    );

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      () => updateBindings()
    );

    subscriptionManager.subscribeToModelChanges(EntityName.Report, () => {
      updateBindings();
    });

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.lockedExportBySignedReportProjectIds != null &&
      this.projectIdsWhereUserIsAuthorized != null &&
      this.lockedByFinishedAppointmentsProjectIdsSet != null &&
      this.editableUserGroupIds != null &&
      this.thingSectionsSetting != null &&
      this.currentUser != null
    );
  }

  public canDeleteEntity(project: Project): boolean {
    return this.checkProjectPermission({
      project,
      checkRolePermission: (permissions) => permissions.getCanDeleteProjects()
    });
  }

  public canEditField(project: Project): boolean {
    return this.checkProjectPermission({
      project,
      checkRolePermission: (permissions) => permissions.getCanUpdateProjects()
    });
  }

  public canEditProperties(project: Project): boolean {
    return this.checkProjectPermission({
      project,
      checkRolePermission: (permissions) => permissions.getCanUpdateProjects()
    });
  }

  public canEditPictures(project: Project): boolean {
    return this.checkProjectPermission({
      project,
      checkRolePermission: (permissions) => permissions.getCanUpdateProjects()
    });
  }

  public canMarkOnThingSections(project: Project): boolean {
    if (this.thingSectionsSetting == null) {
      return false;
    }

    return this.entityIsEditableUserGroup(project) && this.thingSectionsSetting;
  }

  public canCreateReports(project: Project): boolean {
    if (
      !this.roleBasedPermissions ||
      !this.lockedExportBySignedReportProjectIds ||
      this.lockedExportBySignedReportProjectIds.has(project.id)
    ) {
      return false;
    }

    return this.checkProjectPermission({
      project,
      checkRolePermission: (permissions) => permissions.getCanCreateReports()
    });
  }

  public canCreateEntries(project: Project): boolean {
    return this.checkProjectPermission({
      project,
      checkRolePermission: (permissions) => permissions.getCanCreateEntries()
    });
  }

  public canDragAndDropEntries(project: Project): boolean {
    return this.checkProjectPermission({
      project,
      checkRolePermission: (permissions) => permissions.getCanUpdateEntries()
    });
  }

  public canViewValueCalculationResults(): boolean {
    return this.currentUser
      ? PermissionHelper.userHasPermission(
          this.currentUser,
          'canViewValueCalculationResults'
        )
      : false;
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.Project,
    Project
  > {
    return projectEntityInfo;
  }

  public canCreateUserDefinedEntities(project: Project): boolean {
    return this.checkProjectPermission({
      project,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateUserDefinedEntities()
    });
  }

  private entityIsEditableUserGroup(entity: Project): boolean {
    if (!this.editableUserGroupIds) {
      return false;
    }

    return this.editableUserGroupIds.has(entity.ownerUserGroupId);
  }

  private checkProjectPermission({
    project,
    checkRolePermission
  }: {
    project: Project;
    checkRolePermission: (
      roleBasedUserGroupSpecificPermissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !this.roleBasedPermissions ||
      !this.lockedByFinishedAppointmentsProjectIdsSet
    ) {
      return false;
    }

    if (this.lockedByFinishedAppointmentsProjectIdsSet.has(project.id)) {
      return false;
    }

    return EntityAdapterUtils.checkPermissionBasedOnProjectId({
      projectId: project.id,
      ownerUserGroupId: project.ownerUserGroupId,
      projectIdsWhereUserIsAuthorized: this.projectIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ProjectAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  activeUserCompanySettingService: ActiveUserCompanySettingService;
  currentUserService: CurrentUserService;
};
