import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { GeneralFileHelper } from 'common/EntityHelper/GeneralFileHelper';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { InlineCreationSection } from '../../aureliaComponents/inline-creation-section/inline-creation-section';
import { DisposableItemsCache } from '../../classes/DisposableItemsCache/DisposableItemsCache';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GeneralFileUploadService } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUploadService';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskComment } from '../../classes/EntityManager/entities/ProcessTaskComment/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { Utils } from '../../classes/Utils/Utils';
import { computed } from '../../hooks/computed';
import {
  arrayChangesWithItemExpression,
  model
} from '../../hooks/dependencies';
import { watch } from '../../hooks/watch';
import { ClickableTextInput } from '../../inputComponents/clickable-text-input/clickable-text-input';
import { MultiFileInput } from '../../inputComponents/multi-file-input/multi-file-input';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { MultiProcessTaskSelectionWidgetProcessTaskHandle } from '../multi-process-task-selection-widget/multi-process-task-selection-widget';

@autoinject()
export class ProcessTaskCommentInlineCreationWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private readonly processTaskHandlesCache: DisposableItemsCache<
    ProcessTask,
    ProcessTaskHandle
  >;

  protected processTaskHandles: Array<ProcessTaskHandle> = [];

  private text: string | null = null;
  private errorTextTks: Array<string> | null = null;
  private textInput: ClickableTextInput | null = null;
  private multiFileInput: MultiFileInput | null = null;
  private inlineCreationSection: InlineCreationSection | null = null;
  private currentUser: User | null = null;
  private isAttached: boolean = false;

  protected readonly GeneralFileHelper = GeneralFileHelper;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    private readonly generalFileUploadService: GeneralFileUploadService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskHandlesCache = new DisposableItemsCache({
      createDisposableForItem: ({ item }) => {
        return new ProcessTaskHandle({
          permissionsService,
          processTask: item
        });
      }
    });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((user) => {
        this.currentUser = user;
      })
    );

    this.updateProcessTaskHandles();
    this.resetProcessTaskSelection();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProcessTaskHandles();
      this.resetProcessTaskSelection();
    }
  }

  @watch(model(EntityName.ProcessTask))
  private updateProcessTaskHandles(): void {
    this.processTaskHandles = this.processTaskHandlesCache.mapItems({
      items: this.processTask
        ? this.entityManager.processTaskRepository.getByProcessTaskGroupId(
            this.processTask.ownerProcessTaskGroupId
          )
        : []
    });
  }

  protected handleCreationSectionOpened(): void {
    if (this.textInput) {
      this.textInput.toggleEditMode();
    }
  }

  protected handleCreationSectionClosed(): void {
    if (this.multiFileInput) {
      this.multiFileInput.selectedFiles = [];
    }
  }

  protected handleCancelNewCommentClick(): boolean {
    this.text = null;
    return true;
  }

  protected handleCreateNewCommentClick(): boolean {
    this.validateNewComment();
    if (this.errorTextTks) {
      return false;
    }

    void this.waitForFilesBeingLoaded().then(() => {
      const selectedProcessTasks = this.processTaskHandles
        .filter((handle) => handle.isSelected)
        .map((handle) => handle.processTask);

      for (const processTask of selectedProcessTasks) {
        const comment = this.createNewComment(processTask);
        this.createCommentFiles(comment);
      }

      this.text = null;
    });

    return false;
  }

  private async waitForFilesBeingLoaded(): Promise<void> {
    assertNotNullOrUndefined(
      this.multiFileInput,
      "can't ProcessTaskCommentInlineCreationWidget.waitForFilesBeingLoaded without multiFileInput"
    );

    this.inlineCreationSection && this.inlineCreationSection.startPreloader();
    await this.multiFileInput.selectedFilesLoadedAwaitable();

    if (this.inlineCreationSection) {
      this.inlineCreationSection.stopPreloader();
      this.inlineCreationSection.close();
    }
  }

  private createNewComment(processTask: ProcessTask): ProcessTaskComment {
    assertNotNullOrUndefined(
      this.currentUser,
      "can't ProcessTaskCommentInlineCreationWidget.createNewComment without a currentUser"
    );

    const comment = this.entityManager.processTaskCommentRepository.create({
      text: this.text,
      userId: this.currentUser.id,
      createdAtProcessConfigurationStepId:
        processTask.currentProcessConfigurationStepId,
      date: new Date().toISOString(),
      ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
      ownerProcessTaskId: processTask.id,
      ownerUserGroupId: processTask.ownerUserGroupId,
      temporaryGroupName: processTask.temporaryGroupName
    });

    return comment;
  }

  private createCommentFiles(comment: ProcessTaskComment): void {
    const selectedFiles = this.multiFileInput
      ? this.multiFileInput.selectedFiles
      : [];
    selectedFiles.forEach((selectedFile) => {
      if (!selectedFile.dataUrl) {
        // should never happen since we wait for all files to be loaded before calling this function
        console.error('selected file has no dataUrl');
        return;
      }

      const { name, extension } = Utils.getFilePathComponents(
        selectedFile.name ?? ''
      );

      const generalFile = this.entityManager.generalFileRepository.create({
        name: name,
        processTaskCommentId: comment.id,
        ownerProcessTaskGroupId: comment.ownerProcessTaskGroupId,
        ownerProcessTaskId: comment.ownerProcessTaskId,
        ownerUserGroupId: comment.ownerUserGroupId,
        temporaryGroupName: comment.temporaryGroupName
      });

      this.generalFileUploadService.uploadGeneralFile(
        generalFile,
        selectedFile.dataUrl,
        extension || ''
      );
    });
  }

  private validateNewComment(): void {
    const keys: Array<string> = [];

    if (this.text == null || this.text.trim().length === 0) {
      keys.push(
        'operationsComponents.processTaskCommentInlineCreationWidget.noCommentTextEnteredErrorText'
      );
    }

    const atLeastOneProcessTaskIsSelected = this.processTaskHandles.some(
      (handle) => handle.isSelected
    );

    if (!atLeastOneProcessTaskIsSelected) {
      keys.push(
        'operationsComponents.processTaskCommentInlineCreationWidget.noProcessTaskSelected'
      );
    }

    this.errorTextTks = keys.length ? keys : null;
  }

  private resetProcessTaskSelection(): void {
    for (const handle of this.processTaskHandles) {
      handle.setSelected(handle.processTask === this.processTask);
    }
  }

  @computed(
    arrayChangesWithItemExpression('processTaskHandles', 'canBeSelected')
  )
  protected get atLeastOneProcessTaskHandleCanBeSelected(): boolean {
    return this.processTaskHandles.some((handle) => handle.canBeSelected);
  }
}

export class ProcessTaskHandle
  implements MultiProcessTaskSelectionWidgetProcessTaskHandle
{
  private internalProcessTask: ProcessTask;

  private readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];
  private readonly processTaskPermissionsHandleDisposable: Disposable;

  private internalSelected = false;

  constructor({
    permissionsService,
    processTask
  }: {
    permissionsService: PermissionsService;
    processTask: ProcessTask;
  }) {
    this.internalProcessTask = processTask;

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTask,
        context: this,
        expression: 'processTask'
      });
    this.processTaskPermissionsHandleDisposable =
      this.processTaskPermissionsHandle.subscribe();
  }

  public setSelected(selected: boolean): void {
    this.internalSelected = selected;
  }

  public dispose(): void {
    this.processTaskPermissionsHandleDisposable.dispose();
  }

  @computedFrom('internalProcessTask')
  public get processTask(): ProcessTask {
    return this.internalProcessTask;
  }

  @computedFrom('processTaskPermissionsHandle.canCreateProcessTaskComments')
  public get canBeSelected(): boolean {
    return this.processTaskPermissionsHandle.canCreateProcessTaskComments;
  }

  @computedFrom('processTaskPermissionsHandle.canCreateProcessTaskComments')
  public get canBeDeselected(): boolean {
    return this.processTaskPermissionsHandle.canCreateProcessTaskComments;
  }

  @computedFrom('internalSelected')
  public get isSelected(): boolean {
    return this.internalSelected;
  }
}
