export class MatrixUtils {
  public static getIdentityMatrix(rowColLength: number): Array<number> {
    const identityMatrix = new Array(rowColLength);

    for (let i = 0; i < rowColLength; i++) {
      identityMatrix[i] = new Array(rowColLength);

      for (let j = 0; j < rowColLength; j++) {
        identityMatrix[i][j] = i === j ? 1 : 0;
      }
    }

    return identityMatrix;
  }

  public static isSquareMatrix(data: Array<Array<number>>): boolean {
    if (data.length === 0) return false;

    for (const row of data) {
      if (row.length !== data.length) return false;
    }

    return true;
  }

  public static multiplyMatrices(
    a: Array<Array<number>>,
    b: Array<Array<number>>
  ): Array<Array<number>> | null {
    const aNumRows = a.length;
    const aNumCols = a[0]?.length;
    const bNumRows = b.length;
    const bNumCols = b[0]?.length;

    this.checkMatrixRowsCols(a);
    this.checkMatrixRowsCols(b);

    if (aNumCols !== bNumRows)
      throw new Error(
        'number of columns of matrix1 does not equal the number of rows of matrix2'
      );

    const m = new Array(aNumRows);
    for (let r = 0; r < aNumRows; ++r) {
      m[r] = new Array(bNumCols);
      for (let cb = 0; cb < bNumCols!; ++cb) {
        m[r][cb] = 0;
        for (let ca = 0; ca < aNumCols; ++ca) {
          const rowA = a[r];
          const rowB = b[ca];
          const valueA = rowA![ca];
          const valueB = rowB![cb];
          m[r][cb] += valueA! * valueB!;
        }
      }
    }

    return m;
  }

  private static checkMatrixRowsCols(m: Array<Array<number>>): void {
    const row1 = m[0];
    if (!row1) throw new Error('matrix is empty');

    const numCols = row1.length;
    for (const row of m) {
      if (row.length !== numCols)
        throw new Error('matrix contains rows of different length');
    }
  }
}
