import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplateEntryProperty } from '../../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import {
  ChoiceAddedEvent,
  ChoiceRemovedEvent
} from '../../../inputComponents/text-choice-widget/text-choice-widget';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class StructureTemplateExcludeFromFlawNumbersWidgetProperty {
  @bindable()
  public readonly structureTemplateEntryProperty: StructureTemplateEntryProperty | null =
    null;

  @subscribableLifecycle()
  protected readonly structureTemplateEntryPropertyPermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplateEntryProperty];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.structureTemplateEntryPropertyPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.StructureTemplateEntryProperty,
        context: this as StructureTemplateExcludeFromFlawNumbersWidgetProperty,
        propertyName: 'structureTemplateEntryProperty'
      });
  }

  protected handleValueTagCreated(event: ChoiceAddedEvent): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntryProperty,
      "can't StructureTemplateExcludeFromFlawNumbersWidgetProperty.handleValueTagCreated without structureTemplateEntryProperty"
    );

    if (
      !this.structureTemplateEntryProperty
        .excludeFromFlawNumberComputationForValues
    ) {
      this.structureTemplateEntryProperty.excludeFromFlawNumberComputationForValues =
        [];
    }

    ArrayUtils.pushUnique(
      this.structureTemplateEntryProperty
        .excludeFromFlawNumberComputationForValues,
      event.detail.choice
    );

    this.entityManager.structureTemplateEntryPropertyRepository.update(
      this.structureTemplateEntryProperty
    );
  }

  protected handleValueTagRemoved(event: ChoiceRemovedEvent): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntryProperty,
      "can't StructureTemplateExcludeFromFlawNumbersWidgetProperty.handleValueTagRemoved without structureTemplateEntryProperty"
    );

    if (
      !this.structureTemplateEntryProperty
        .excludeFromFlawNumberComputationForValues
    ) {
      return;
    }

    ArrayUtils.remove(
      this.structureTemplateEntryProperty
        .excludeFromFlawNumberComputationForValues,
      event.detail.choice
    );

    this.entityManager.structureTemplateEntryPropertyRepository.update(
      this.structureTemplateEntryProperty
    );
  }

  protected handleDeleteButtonClicked(): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntryProperty,
      "can't StructureTemplateExcludeFromFlawNumbersWidgetProperty.handleDeleteButtonClicked without structureTemplateEntryProperty"
    );

    this.structureTemplateEntryProperty.excludeFromFlawNumberComputationForValues =
      [];
    this.entityManager.structureTemplateEntryPropertyRepository.update(
      this.structureTemplateEntryProperty
    );

    DomEventHelper.fireEvent<StructureTemplateEntryPropertyExcludeFromFlawNumbersCleared>(
      this.element,
      {
        name: 'structure-template-entry-property-exclude-from-flaw-numbers-cleared',
        detail: null
      }
    );
  }

  @computedFrom(
    'structureTemplateEntryProperty.choices',
    'structureTemplateEntryProperty.type'
  )
  protected get availableTags(): Array<string> {
    const structureTemplateEntryProperty = this.structureTemplateEntryProperty;
    if (!structureTemplateEntryProperty) {
      return [];
    }

    if (
      PropertyHelper.propertyTypeNeedsChoices(
        structureTemplateEntryProperty.type
      )
    ) {
      return structureTemplateEntryProperty.choices.filter(
        (obj1) =>
          !structureTemplateEntryProperty.excludeFromFlawNumberComputationForValues?.some(
            (obj2) => obj2 === obj1
          )
      );
    }

    if (structureTemplateEntryProperty.type === PropertyType.CHECKBOX) {
      return [
        PropertyHelper.UNCHECKED_BOX_CHAR,
        PropertyHelper.CHECKED_BOX_CHAR
      ];
    }

    return [];
  }

  @computedFrom('structureTemplateEntryProperty')
  protected get propertySelectOptions(): Array<StructureTemplateEntryProperty> {
    return this.structureTemplateEntryProperty
      ? [this.structureTemplateEntryProperty]
      : [];
  }
}

export type StructureTemplateEntryPropertyExcludeFromFlawNumbersCleared =
  NamedCustomEvent<
    'structure-template-entry-property-exclude-from-flaw-numbers-cleared',
    null
  >;
