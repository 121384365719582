import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';

import {
  TZoomBoxResizerHelperPicturePositionInfo,
  ZoomBoxPictureResizer
} from '../../aureliaComponents/zoom-box/ZoomBoxPictureResizer';
import { FullScreenOverlay } from '../../aureliaComponents/full-screen-overlay/full-screen-overlay';
import {
  Picture,
  PictureCoords
} from '../../classes/EntityManager/entities/Picture/types';
import {
  CoordinatesSelectedResult,
  PositionedPictureZoomBoxClickHandler
} from '../../classes/PositionedPicture/PositionedPictureZoomBoxClickHandler/PositionedPictureZoomBoxClickHandler';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

@autoinject()
export class SelectCoordinatesOnPositionedPictureOverlay {
  private fullScreenOverlay: FullScreenOverlay | null = null;

  private zoomBoxElement: HTMLElement | null = null;
  private pictureElement: HTMLElement | null = null;
  private pictureResizer: ZoomBoxPictureResizer | null = null;
  protected picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo | null =
    null;

  /**
   * for performance reasons we only render the zoom-box when the dialog is opened
   */
  private zoomBoxRendered = false;

  private options: SelectCoordinatesOnPositionedPictureOverlayOptions | null =
    null;

  protected zoomBoxClickHandler = new PositionedPictureZoomBoxClickHandler({
    entityManager: this.entityManager,
    getPictureElement: () => {
      assertNotNullOrUndefined(
        this.pictureElement,
        'no picture element available'
      );
      return this.pictureElement;
    },
    onCoordinatesSelected: (result) => {
      this.options?.onCoordinateSelected(result);
      this.fullScreenOverlay?.close();
    }
  });

  constructor(
    private i18n: I18N,
    private readonly entityManager: AppEntityManager
  ) {}

  public static async open(
    options: SelectCoordinatesOnPositionedPictureOverlayOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(
    options: SelectCoordinatesOnPositionedPictureOverlayOptions
  ): void {
    if (!options.pictureNotPositionedText) {
      options.pictureNotPositionedText = this.translate('pictureNotPositioned');
    }

    this.options = options;

    if (this.fullScreenOverlay) this.fullScreenOverlay.open();

    if (this.pictureResizer) {
      this.pictureResizer.destroy();
      this.pictureResizer = null;
    }

    this.zoomBoxRendered = true;

    setTimeout(() => {
      if (!this.zoomBoxRendered) {
        // has been closed in the meantime, no need to initialize it
        return;
      }

      assertNotNullOrUndefined(
        this.pictureElement,
        'cannot create ZoomBoxPictureResizer without picture element'
      );
      assertNotNullOrUndefined(
        this.zoomBoxElement,
        'cannot create ZoomBoxPictureResizer without zoom box element'
      );

      this.pictureResizer = new ZoomBoxPictureResizer(
        this.pictureElement,
        this.zoomBoxElement
      );
      this.pictureResizer.onAfterUpdate((picturePositionInfo) => {
        this.picturePositionInfo = picturePositionInfo;
      });
      this.pictureResizer.update();
    }, 0);
  }

  protected handleFullScreenOverlayClosed(): void {
    this.options = null;
    this.zoomBoxRendered = false;

    if (this.pictureResizer) {
      this.pictureResizer.destroy();
      this.pictureResizer = null;
    }
  }

  protected handleCloseClick(): void {
    if (this.fullScreenOverlay) this.fullScreenOverlay.close();
  }

  private translate(key: string): string {
    return this.i18n.tr(
      'dialogs.positionedPictureCoordinateSelectionOverlay.' + key
    );
  }
}

type SelectCoordinatesOnPositionedPictureOverlayOptions = {
  picture: Picture;

  /** an old position which should be shown as a marker */
  oldPosition: PictureCoords | null;

  pictureNotPositionedText: string;
  onCoordinateSelected: (result: CoordinatesSelectedResult) => void;
};
