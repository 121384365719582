import { HooksContainer } from '@record-it-npm/synchro-client';
import { GlobalLoadingOverlay } from '../../../../loadingComponents/global-loading-overlay/global-loading-overlay';
import { JoinEntityQueue } from '../../../JoinEntityQueue/JoinEntityQueue';
import {
  Disposable,
  DisposableContainer
} from '../../../Utils/DisposableContainer';
import { IUtilsRateLimitedFunction, Utils } from '../../../Utils/Utils';
import { AppEntityManager } from '../AppEntityManager';
import { EntityName } from '../types';

export class JoinedProcessTaskGroupManager {
  private started: boolean = false;
  private disposableContainer: DisposableContainer = new DisposableContainer();
  private finishedAutoJoiningProcessTaskGroups: boolean = false;

  private readonly hooksContainer: HooksContainer<JoinedProcessTaskGroupManagerHooks> =
    new HooksContainer();

  private readonly joinEntityQueue: JoinEntityQueue;
  private readonly rateLimitedUpdateJoinedProcessTaskGroup: IUtilsRateLimitedFunction;

  constructor(
    private readonly entityManager: AppEntityManager,
    options: Options
  ) {
    this.joinEntityQueue = new JoinEntityQueue({
      sendJoinEntityRequest: options.joinProcessTaskGroup,
      sendLeaveEntityRequest: options.leaveProcessTaskGroup,
      onEntityActivelyJoined: () => {}
    });

    this.rateLimitedUpdateJoinedProcessTaskGroup = Utils.rateLimitFunction(
      this.updateJoinedProcessTaskGroups.bind(this, true),
      100
    );
  }

  public start(): void {
    this.started = true;
    this.joinEntityQueue.start();
    this.autoJoinProcessTaskGroups();

    this.disposableContainer.add(
      this.rateLimitedUpdateJoinedProcessTaskGroup.toCancelDisposable()
    );

    this.disposableContainer.add(
      this.entityManager.processTaskGroupRepository.registerHooks({
        afterEntityUpdated: this.rateLimitedUpdateJoinedProcessTaskGroup,
        afterEntityDeleted: this.rateLimitedUpdateJoinedProcessTaskGroup,
        afterEntityRemovedLocally: this.rateLimitedUpdateJoinedProcessTaskGroup,
        afterEntityUpdatedLocally: this.rateLimitedUpdateJoinedProcessTaskGroup,
        afterEntityVisibilityChanged:
          this.rateLimitedUpdateJoinedProcessTaskGroup
      })
    );

    this.disposableContainer.add(
      this.entityManager.entityActualization.registerHooks({
        afterActualization: ({ success }) => {
          if (success) {
            this.rateLimitedUpdateJoinedProcessTaskGroup();
          }
        }
      })
    );

    this.disposableContainer.add(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.ProcessTaskGroup,
        (entity) => {
          void this.joinEntityQueue.entityIdUpgraded({ entity });
        }
      )
    );

    this.updateRelativeSynchronizationEntityIds(); // remove old synchronization entity ids immediately
  }

  public stop(): void {
    this.started = false;

    this.disposableContainer.disposeAll();
    this.joinEntityQueue.stop();
  }

  public isStarted(): boolean {
    return this.started;
  }

  public joinAllProcessTaskGroups(): void {
    if (!this.started) {
      throw new Error(
        "can't use the joinedProcessTaskGroupManager when it's not started"
      );
    }

    GlobalLoadingOverlay.setLoadingState(this, true);
    void this.updateJoinedProcessTaskGroups(true);
  }

  public autoJoiningProcessTaskGroupsIsFinished(): boolean {
    return this.finishedAutoJoiningProcessTaskGroups;
  }

  public registerHooks(hooks: JoinedProcessTaskGroupManagerHooks): Disposable {
    return this.hooksContainer.registerHooks(hooks);
  }

  private autoJoinProcessTaskGroups(): void {
    void this.updateJoinedProcessTaskGroups(false).then(() => {
      this.finishedAutoJoiningProcessTaskGroups = true;
      this.hooksContainer.callHooks('autoJoiningProcessTaskGroupsFinished');
    });
  }

  private async updateJoinedProcessTaskGroups(
    actualizeAfterJoining: boolean
  ): Promise<void> {
    this.rateLimitedUpdateJoinedProcessTaskGroup.cancel();

    this.updateRelativeSynchronizationEntityIds();

    const relevantProcessTaskGroups =
      this.entityManager.processTaskGroupRepository.getAll();

    const missingIds = relevantProcessTaskGroups
      .filter((p) => !this.joinEntityQueue.entityIsActivelyJoined(p.id))
      .map((p) => p.id);

    const superflousIds = this.joinEntityQueue
      .getActivelyJoinedEntityIds()
      .filter((id) => !relevantProcessTaskGroups.find((p) => p.id === id));

    const promises = [
      ...missingIds.map((id) => this.joinEntityQueue.joinEntity(id)),
      ...superflousIds.map((id) => this.joinEntityQueue.leaveEntity(id))
    ].filter((promise) => {
      // Only wait for new promises. If the promises were already queued, they are probably handled elsewhere
      return promise.queuedNewItem;
    });

    Promise.all(promises)
      .then(() => {
        if (promises.length && actualizeAfterJoining) {
          void this.entityManager.entityActualization.actualize();
        }
      })
      .finally(() => {
        GlobalLoadingOverlay.setLoadingState(this, false);
      });
  }

  private updateRelativeSynchronizationEntityIds(): void {
    const processTaskGroupIds = this.entityManager.processTaskGroupRepository
      .getAll()
      .map((pt) => pt.id);
    const registeredIds =
      this.entityManager.entityActualization.getRelativeSynchronizationEntityIdsByEntityName(
        EntityName.ProcessTaskGroup
      );

    const idsToAdd = processTaskGroupIds.filter(
      (id) => !registeredIds.includes(id)
    );
    for (const toAdd of idsToAdd) {
      this.entityManager.entityActualization.addRelativeSynchronizationEntityId(
        EntityName.ProcessTaskGroup,
        toAdd
      );
    }

    const idsToRemove = registeredIds.filter(
      (id) => !processTaskGroupIds.includes(id)
    );
    for (const toRemove of idsToRemove) {
      this.entityManager.entityActualization.removeRelativeSynchronizationEntityId(
        EntityName.ProcessTaskGroup,
        toRemove
      );
    }
  }
}

export type Options = {
  joinProcessTaskGroup: (processTaskGroupId: string) => Promise<void>;
  leaveProcessTaskGroup: (processTaskGroupId: string) => Promise<void>;
};

export type JoinedProcessTaskGroupManagerHooks = {
  autoJoiningProcessTaskGroupsFinished?: () => void;
};
