import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Dialogs } from '../../classes/Dialogs';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { ProcessTaskMeasurePointReadingCreationService } from '../../services/ProcessTaskMeasurePointReadingCreationService';
import { Utils } from '../../classes/Utils/Utils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskMeasurePoint } from '../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskMeasurePointReading } from '../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types';
import { ProcessConfigurationMeasurePointType } from '../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types';
import { CompactClickableTextInput } from '../../inputComponents/compact-clickable-text-input/compact-clickable-text-input';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { ProcessTaskMeasurePointListItemReading } from './process-task-measure-point-list-item-reading/process-task-measure-point-list-item-reading';

/**
 * @event mark-measure-point-clicked
 */
@autoinject()
export class ProcessTaskMeasurePointListItem {
  @bindable()
  public measurePoint: ProcessTaskMeasurePoint | null = null;

  @bindable()
  public appointment: ProcessTaskAppointment | null = null;

  @bindable()
  public canManageMeasurePoints: boolean = false;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected measurePointPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskMeasurePoint];

  private measurePointReadingsHistory: Array<ProcessTaskMeasurePointReading> =
    [];

  private appointmentMeasurePointReadings: Array<ProcessTaskMeasurePointReading> =
    [];

  protected selectedMeasurePointType: ProcessConfigurationMeasurePointType | null =
    null;

  protected newMeasurePointName: string = '';

  protected showLog: boolean = false;

  protected nameInput: CompactClickableTextInput | null = null;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskMeasurePointReadingCreationService: ProcessTaskMeasurePointReadingCreationService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.processTaskMeasurePointReadingCreationService =
      processTaskMeasurePointReadingCreationService;

    this.measurePointPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        context: this as ProcessTaskMeasurePointListItem,
        entityName: EntityName.ProcessTaskMeasurePoint,
        propertyName: 'measurePoint'
      });
  }

  public focus(): void {
    if (this.nameInput) {
      this.nameInput.focus();
    }
  }

  public focusFirstReading(): void {
    this.updateMeasurePointReadings();

    setTimeout(() => {
      const reading = this.appointmentMeasurePointReadings[0];
      if (!reading) {
        return;
      }

      const vm = this.getReadingListItemViewModel(reading);
      if (vm) {
        vm.focus();
      }
    });
  }

  protected attached(): void {
    this.updateMeasurePointReadings();
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskMeasurePointReading,
      this.updateMeasurePointReadings.bind(this)
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected measurePointChanged(): void {
    this.updateMeasurePointReadings();
  }

  protected appointmentChanged(): void {
    this.updateMeasurePointReadings();
  }

  protected updateMeasurePointReadings(): void {
    if (!this.measurePoint) {
      this.measurePointReadingsHistory = [];
      return;
    }
    this.measurePointReadingsHistory =
      this.entityManager.processTaskMeasurePointReadingRepository.getByProcessTaskMeasurePointId(
        this.measurePoint.id
      );
    this.measurePointReadingsHistory.sort((a, b) =>
      a.date.localeCompare(b.date)
    );
    const appointment = this.appointment;
    if (!appointment) {
      this.appointmentMeasurePointReadings = [];
      return;
    }
    this.appointmentMeasurePointReadings =
      this.measurePointReadingsHistory.filter(
        (o) => o.processTaskAppointmentId === appointment.id
      );
    this.measurePointReadingsHistory = this.measurePointReadingsHistory.filter(
      (o) => o.processTaskAppointmentId !== appointment.id
    );
  }

  protected handleDeleteMeasurePointClick(): void {
    const measurePoint = this.measurePoint;
    assertNotNullOrUndefined(
      measurePoint,
      "can't ProcessTaskMeasurePointListItem.handleDeleteMeasurePointClick without measurePoint"
    );

    void Dialogs.deleteDialogTk(
      'operations.processTaskMeasurePointListWidget.measurePointDeleteConfirmation'
    ).then(() => {
      this.entityManager.processTaskMeasurePointRepository.delete(measurePoint);
    });
  }

  protected handleAddMeasurePointReadingClick(): void {
    if (!this.measurePoint) return;
    if (!this.appointment) return;

    const measurePointReading =
      this.processTaskMeasurePointReadingCreationService.createReadingWithProperties(
        this.measurePoint,
        this.appointment
      );
    this.updateMeasurePointReadings();

    setTimeout(() => {
      this.focusMeasurePointReading(measurePointReading);
    }, 20);
  }

  protected focusMeasurePointReading(
    measurePointReading: ProcessTaskMeasurePointReading
  ): void {
    const viewModel = this.getReadingListItemViewModel(measurePointReading);
    if (viewModel) viewModel.focus();
  }

  protected handleMarkMeasurePointClick(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'mark-measure-point-clicked',
      detail: null
    });
  }

  protected handleMeasurePointChanged(): void {
    if (this.measurePoint) {
      this.entityManager.processTaskMeasurePointRepository.update(
        this.measurePoint
      );
    }
  }

  private getReadingListItemViewModel(
    reading: ProcessTaskMeasurePointReading
  ): ProcessTaskMeasurePointListItemReading | null {
    const readingElementId = this.getReadingListItemId(reading.id);
    const readingElement = this.element.querySelector('#' + readingElementId);

    if (!readingElement) {
      return null;
    }

    return Utils.getViewModelOfElement(readingElement);
  }

  private getReadingListItemId(readingId: string): string {
    return 'process-task-measure-point-list-item--reading-' + readingId;
  }
}
