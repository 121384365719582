import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplateUtils } from '../../classes/EntityManager/entities/StructureTemplate/StructureTemplateUtils';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { StructureTemplateEntryUtils } from '../../classes/EntityManager/entities/StructureTemplateEntry/StructureTemplateEntryUtils';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class StructureTemplateRatingLevelsWidget {
  @bindable public structureTemplate: StructureTemplate | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly structureTemplatePermissionsHandle: EntityNameToPermissionsHandle[EntityName.StructureTemplate];

  protected structureTemplateMaxLevel = 0;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.structureTemplatePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.StructureTemplate,
        context: this as StructureTemplateRatingLevelsWidget,
        propertyName: 'structureTemplate'
      });
  }

  protected attached(): void {
    this.updateStructureTemplateMaxLevel();
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntry,
      this.updateStructureTemplateMaxLevel.bind(this)
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateStructureTemplateMaxLevel(): void {
    if (!this.structureTemplate) {
      this.structureTemplateMaxLevel = 0;
      return;
    }

    const structureTemplateEntries =
      this.entityManager.structureTemplateEntryRepository.getByStructureTemplateId(
        this.structureTemplate.id
      );
    this.structureTemplateMaxLevel =
      StructureTemplateEntryUtils.getMaximumLevel(structureTemplateEntries);
  }

  private enableLevel(level: number): void {
    assertNotNullOrUndefined(
      this.structureTemplate,
      'cannot enableLevel without a structureTemplate'
    );

    if (!this.structureTemplate.levelConfigurations) {
      this.structureTemplate.levelConfigurations = [];
    }

    this.structureTemplate.levelConfigurations =
      StructureTemplateUtils.editLevelConfigurationsAsMap(
        this.structureTemplate.levelConfigurations,
        (map) =>
          map.set(level, {
            ...map.get(level),
            ratingEnabled: true
          })
      );

    this.handleStructureTemplateUpdated();
  }

  private disableLevel(level: number): void {
    assertNotNullOrUndefined(
      this.structureTemplate,
      'cannot disableLevel without a structureTemplate'
    );

    if (!this.structureTemplate.levelConfigurations) {
      this.structureTemplate.levelConfigurations = [];
    }

    this.structureTemplate.levelConfigurations =
      StructureTemplateUtils.editLevelConfigurationsAsMap(
        this.structureTemplate.levelConfigurations,
        (map) =>
          map.set(level, {
            ...map.get(level),
            ratingEnabled: false
          })
      );

    this.handleStructureTemplateUpdated();
  }

  private setLevelEnabled(level: number, enabled: boolean): void {
    assertNotNullOrUndefined(
      this.structureTemplate,
      'cannot setLevelEnabled without a structureTemplate'
    );

    enabled ? this.enableLevel(level) : this.disableLevel(level);
  }

  protected isLevelEnabled(level: number): boolean {
    return StructureTemplateUtils.isRatingEnabledOnLevel(
      this.structureTemplate,
      level
    );
  }

  protected handleCheckboxStateChanged(
    event: CustomCheckboxCheckedChangedEvent,
    level: number
  ): void {
    this.setLevelEnabled(level, event.detail.checked);
  }

  protected handleStructureTemplateUpdated(): void {
    assertNotNullOrUndefined(
      this.structureTemplate,
      'cannot handleStructureTemplateUpdated without structureTemplate'
    );

    this.entityManager.structureTemplateRepository.update(
      this.structureTemplate
    );
  }

  /**
   * Returns an array of numbers from 0 to `this.structureTemplateMaxLevel`.
   */
  @computedFrom('structureTemplateMaxLevel')
  protected get structureTemplateLevelValues(): Array<number> {
    return Array.from(
      { length: this.structureTemplateMaxLevel },
      (_, i) => i + 1
    );
  }

  /**
   * Returns whether the widget should be displayed at all.
   */
  @computedFrom('structureTemplateMaxLevel')
  protected get isEnabled(): boolean {
    return this.structureTemplateMaxLevel > 0;
  }
}
