import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskInvoice } from '../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { ProcessTaskInvoiceToProcessTask } from '../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTask/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessConfigurationCategory } from '../../classes/EntityManager/entities/ProcessConfigurationCategory/types';
import { MultiProcessTaskSelectionWidgetProcessTaskHandle } from '../multi-process-task-selection-widget/multi-process-task-selection-widget';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { DisposableItemsCache } from '../../classes/DisposableItemsCache/DisposableItemsCache';
import { watch } from '../../hooks/watch';
import { expression, model } from '../../hooks/dependencies';
import { computed } from '../../hooks/computed';

@autoinject()
export class ProcessTaskInvoiceRelationsWidget {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTaskInvoice: ProcessTaskInvoice | null = null;

  private processTaskHandlesCache: DisposableItemsCache<
    {
      processTask: ProcessTask;
      processTaskInvoice: ProcessTaskInvoice;
      processTaskInvoiceToProcessTask: ProcessTaskInvoiceToProcessTask | null;
    },
    ProcessTaskHandle
  >;

  protected processTaskHandles: Array<ProcessTaskHandle> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processTaskHandlesCache = new DisposableItemsCache({
      createDisposableForItem: ({ item }) => {
        return new ProcessTaskHandle({
          entityManager,
          permissionsService,
          processTask: item.processTask,
          processTaskInvoice: item.processTaskInvoice,
          processTaskInvoiceToProcessTask: item.processTaskInvoiceToProcessTask
        });
      },
      onItemUpdate: ({ item, mappedItem }) => {
        mappedItem.update({
          processTaskInvoice: item.processTaskInvoice,
          processTaskInvoiceToProcessTask: item.processTaskInvoiceToProcessTask
        });
      },
      getKeyForItem: ({ item }) => item.processTask
    });
  }

  protected attached(): void {
    this.updateProcessTaskHandles();
  }

  protected detached(): void {
    this.processTaskHandlesCache.disposeAndClear();
  }

  @watch(
    expression('processTaskInvoice'),
    model(EntityName.ProcessTask),
    model(EntityName.ProcessTaskInvoiceToProcessTask)
  )
  protected updateProcessTaskHandles(): void {
    const processTaskInvoice = this.processTaskInvoice;

    if (processTaskInvoice) {
      const processTasks =
        this.entityManager.processTaskRepository.getByProcessTaskGroupId(
          processTaskInvoice.ownerProcessTaskGroupId
        );

      const processTaskInvoiceToProcessTasks =
        this.entityManager.processTaskInvoiceToProcessTaskRepository.getByProcessTaskInvoiceId(
          processTaskInvoice.id
        );

      this.processTaskHandles = this.processTaskHandlesCache.mapItems({
        items: processTasks.map((processTask) => {
          return {
            processTask,
            processTaskInvoice,
            processTaskInvoiceToProcessTask:
              processTaskInvoiceToProcessTasks.find(
                (relation) => relation.ownerProcessTaskId === processTask.id
              ) ?? null
          };
        })
      });
    } else {
      this.processTaskHandles = this.processTaskHandlesCache.mapItems({
        items: []
      });
    }
  }

  @computed(
    expression('processTaskInvoice.processConfigurationCategoryId'),
    model(EntityName.ProcessConfigurationCategory)
  )
  protected get category(): ProcessConfigurationCategory | null {
    if (this.processTaskInvoice?.processConfigurationCategoryId) {
      return this.entityManager.processConfigurationCategoryRepository.getById(
        this.processTaskInvoice.processConfigurationCategoryId
      );
    } else {
      return null;
    }
  }
}

export class ProcessTaskHandle
  implements MultiProcessTaskSelectionWidgetProcessTaskHandle
{
  private readonly entityManager: AppEntityManager;

  private internalProcessTask: ProcessTask;
  protected processTaskInvoice: ProcessTaskInvoice;
  private processTaskInvoiceToProcessTask: ProcessTaskInvoiceToProcessTask | null;

  private readonly processTaskInvoicePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskInvoice];
  private readonly processTaskInvoicePermissionsHandleDisposable: Disposable;

  private readonly processTaskInvoiceToProcessTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskInvoiceToProcessTask];
  private readonly processTaskInvoiceToProcessTaskPermissionsHandleDisposable: Disposable;

  constructor({
    entityManager,
    permissionsService,
    processTask,
    processTaskInvoice,
    processTaskInvoiceToProcessTask
  }: {
    entityManager: AppEntityManager;
    permissionsService: PermissionsService;
    processTask: ProcessTask;
    processTaskInvoice: ProcessTaskInvoice;
    processTaskInvoiceToProcessTask: ProcessTaskInvoiceToProcessTask | null;
  }) {
    this.entityManager = entityManager;
    this.internalProcessTask = processTask;
    this.processTaskInvoice = processTaskInvoice;
    this.processTaskInvoiceToProcessTask = processTaskInvoiceToProcessTask;

    this.processTaskInvoicePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskInvoice,
        context: this,
        expression: 'processTaskInvoice'
      });
    this.processTaskInvoicePermissionsHandleDisposable =
      this.processTaskInvoicePermissionsHandle.subscribe();

    this.processTaskInvoiceToProcessTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskInvoiceToProcessTask,
        context: this,
        expression: 'processTaskInvoiceToProcessTask'
      });
    this.processTaskInvoiceToProcessTaskPermissionsHandleDisposable =
      this.processTaskInvoiceToProcessTaskPermissionsHandle.subscribe();
  }

  public update({
    processTaskInvoice,
    processTaskInvoiceToProcessTask
  }: {
    processTaskInvoice: ProcessTaskInvoice;
    processTaskInvoiceToProcessTask: ProcessTaskInvoiceToProcessTask | null;
  }): void {
    this.processTaskInvoice = processTaskInvoice;
    this.processTaskInvoiceToProcessTask = processTaskInvoiceToProcessTask;
  }

  public setSelected(selected: boolean): void {
    if (selected) {
      if (!this.processTaskInvoiceToProcessTask) {
        this.processTaskInvoiceToProcessTask =
          this.entityManager.processTaskInvoiceToProcessTaskRepository.create({
            processTaskInvoiceId: this.processTaskInvoice.id,
            ownerProcessTaskId: this.processTask.id,
            ownerProcessTaskGroupId: this.processTask.ownerProcessTaskGroupId,
            ownerUserGroupId: this.processTask.ownerUserGroupId,
            temporaryGroupName: this.processTaskInvoice.temporaryGroupName
          });
      }
    } else {
      if (this.processTaskInvoiceToProcessTask) {
        this.entityManager.processTaskInvoiceToProcessTaskRepository.delete(
          this.processTaskInvoiceToProcessTask
        );
        this.processTaskInvoiceToProcessTask = null;
      }
    }
  }

  public dispose(): void {
    this.processTaskInvoicePermissionsHandleDisposable.dispose();
    this.processTaskInvoiceToProcessTaskPermissionsHandleDisposable.dispose();
  }

  @computedFrom('internalProcessTask')
  public get processTask(): ProcessTask {
    return this.internalProcessTask;
  }

  @computedFrom(
    'processTaskInvoicePermissionsHandle.canEditProcessTaskInvoiceToProcessTasks'
  )
  public get canBeSelected(): boolean {
    return this.processTaskInvoicePermissionsHandle
      .canEditProcessTaskInvoiceToProcessTasks;
  }

  @computedFrom(
    'processTaskInvoiceToProcessTaskPermissionsHandle.canDeleteEntity'
  )
  public get canBeDeselected(): boolean {
    return this.processTaskInvoiceToProcessTaskPermissionsHandle
      .canDeleteEntity;
  }

  @computedFrom('processTaskInvoiceToProcessTask')
  public get isSelected(): boolean {
    return !!this.processTaskInvoiceToProcessTask;
  }
}
