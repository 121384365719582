import { ThingSectionConfigPropertyLocation } from 'common/Types/Entities/ThingSectionConfigProperty/ThingSectionConfigPropertyDto';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';

import { Dialogs } from '../../../../classes/Dialogs';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../../../classes/EntityManager/entities/Thing/types';
import { ThingSectionConfigProperty } from '../../../../classes/EntityManager/entities/ThingSectionConfigProperty/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { ThingSectionConfigPropertyHandle } from '../../../property-definition-widget/PropertyDefinitionWidgetHandle/ThingSectionConfigPropertyHandle/ThingSectionConfigPropertyHandle';
import {
  ManagePropertyDefinitionsBaseWidgetAdapter,
  ManagePropertyDefinitionsWidgetAdapterDeleteOptions,
  ManagePropertyDefinitionsBaseWidgetAdapterSubscribeOptions
} from '../ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsBaseWidgetAdapter';
import { ManagePropertyDefinitionsWidgetAdapterHandleCache } from '../ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsWidgetAdapterHandleCache';

export class ManagePropertyDefinitionsWidgetThingSectionConfigAdapter
  implements
    ManagePropertyDefinitionsBaseWidgetAdapter<ThingSectionConfigProperty>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly permissionsService: PermissionsService;
  private readonly thing: Thing;
  private readonly location: ThingSectionConfigPropertyLocation;
  private readonly handleCache: ManagePropertyDefinitionsWidgetAdapterHandleCache<
    ThingSectionConfigProperty,
    ThingSectionConfigPropertyHandle
  >;

  constructor(
    options: ManagePropertyDefinitionsWidgetThingSectionConfigAdapterOptions
  ) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.permissionsService = options.permissionsService;
    this.thing = options.thing;
    this.location = options.location;

    this.handleCache = new ManagePropertyDefinitionsWidgetAdapterHandleCache({
      createPropertyDefinitionHandle: ({ propertyDefinition }) => {
        return new ThingSectionConfigPropertyHandle({
          entityManager: this.entityManager,
          configProperty: propertyDefinition,
          permissionsService: options.permissionsService
        });
      }
    });
  }

  public subscribe({
    setPropertyDefinitionHandles,
    setCanCreatePropertyDefinitions
  }: ManagePropertyDefinitionsBaseWidgetAdapterSubscribeOptions<ThingSectionConfigProperty>): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(this.handleCache.subscribe());

    const { updatePropertyDefinitions } =
      this.handleCache.createUpdatePropertyDefinitionsFunction({
        setPropertyDefinitionHandles,
        getPropertyDefinitions: () => {
          return this.entityManager.thingSectionConfigPropertyRepository.getOrderedByThingIdAndLocation(
            {
              thingId: this.thing.id,
              location: this.location
            }
          );
        }
      });

    subscriptionManager.subscribeToModelChanges(
      EntityName.ThingSectionConfigProperty,
      updatePropertyDefinitions
    );
    updatePropertyDefinitions();

    const handle = this.permissionsService.getPermissionsHandleForEntity({
      entityName: EntityName.Thing,
      entity: this.thing
    });

    subscriptionManager.addDisposable(handle.subscribe());

    subscriptionManager.subscribeToExpression(
      handle,
      'canManageThingSectionProperties',
      () => {
        setCanCreatePropertyDefinitions(handle.canManageThingSectionProperties);
      }
    );
    setCanCreatePropertyDefinitions(handle.canManageThingSectionProperties);

    return subscriptionManager.toDisposable();
  }

  public async deletePropertyDefinition({
    propertyDefinition
  }: ManagePropertyDefinitionsWidgetAdapterDeleteOptions<ThingSectionConfigProperty>): Promise<void> {
    return Dialogs.deleteEntityDialog(
      propertyDefinition,
      EntityName.ThingSectionConfigProperty
    ).then(() => {
      this.entityManager.thingSectionConfigPropertyRepository.delete(
        propertyDefinition
      );
    });
  }

  public createPropertyDefinition(
    order: number
  ): ThingSectionConfigPropertyHandle {
    const configProperty =
      this.entityManager.thingSectionConfigPropertyRepository.create({
        location: this.location,
        ownerThingId: this.thing.id,
        ownerUserGroupId: this.thing.ownerUserGroupId,
        order
      });

    return this.handleCache.createCachedPropertyDefinitionHandle({
      propertyDefinition: configProperty
    });
  }

  public arePropertyDefinitionsEqual(
    p1: ThingSectionConfigProperty,
    p2: ThingSectionConfigProperty
  ): boolean {
    return PropertyHelper.isTheSameProperty(p1, p2);
  }
}

export type ManagePropertyDefinitionsWidgetThingSectionConfigAdapterOptions = {
  entityManager: AppEntityManager;
  permissionsService: PermissionsService;
  subscriptionManagerService: SubscriptionManagerService;
  thing: Thing;
  location: ThingSectionConfigPropertyLocation;
};
