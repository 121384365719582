import { UserGroupSubEntity } from '../Base/UserGroupSubEntity';
import { OptionalProperties } from '../../utilities';
import { DateType, IdType } from '../Base/types';
import { BaseEntity } from '../Base/BaseEntity';
import { TDefaultPropertyConfig } from '../../DefaultPropertyConfig';

type UserDefinedEntityConfigPropertyConfigDataFields = Required<
  Pick<
    TDefaultPropertyConfig,
    'name' | 'type' | 'choices' | 'options' | 'order'
  >
> & {
  customColumn: UserDefinedEntityConfigPropertyConfigCustomColumn | null;
  customColumnPermission: UserDefinedEntityConfigPropertyConfigCustomColumnPermission | null;
};

type UserDefinedEntityConfigPropertyConfigIdFields<TId extends IdType> = {
  userDefinedEntityConfigId: TId;
};

type FullUserDefinedEntityConfigPropertyConfig<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  UserGroupSubEntity<TId> &
  UserDefinedEntityConfigPropertyConfigDataFields &
  UserDefinedEntityConfigPropertyConfigIdFields<TId>;

export type UserDefinedEntityConfigPropertyConfigDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullUserDefinedEntityConfigPropertyConfig<
    TId,
    TDate
  > = never,
  TPropertiesToRemove extends keyof FullUserDefinedEntityConfigPropertyConfig<
    TId,
    TDate
  > = never
> = Omit<
  OptionalProperties<
    FullUserDefinedEntityConfigPropertyConfig<TId, TDate>,
    TOptionalProperties
  >,
  TPropertiesToRemove
>;

export enum UserDefinedEntityConfigPropertyConfigCustomColumnPermission {
  READ_ONLY = 'readOnly',
  READ_WRITE = 'readWrite'
}

export enum UserDefinedEntityConfigPropertyConfigCustomColumn {
  FIRST = 'first',
  SECOND = 'second'
}
