import { autoinject } from 'aurelia-framework';

import {
  AbstractLogDataConverter,
  ConvertOptions
} from './AbstractLogDataConverter';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../../classes/EntityManager/entities/Person/PersonUtils';

@autoinject()
export class PersonIdLogDataConverter extends AbstractLogDataConverter {
  constructor(private readonly entityManager: AppEntityManager) {
    super();
  }

  public convert({ logData }: ConvertOptions<never>): void {
    const personId = logData.personId;

    if (typeof personId !== 'string') {
      return;
    }

    const person = this.entityManager.personRepository.getById(personId);
    if (!person) return;

    const personFullName = PersonUtils.getPersonDisplayNameForPerson(person);
    logData.personName = personFullName;
  }
}
