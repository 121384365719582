import { bindable, autoinject } from 'aurelia-framework';

import { EntityName } from '../../classes/EntityManager/entities/types';
import {
  PositionCategoryGroup,
  ProcessTaskPositionUtils
} from '../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * @event navigation-triggered
 */
@autoinject()
export class ProcessTaskAppointmentPositionsWidget {
  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTaskAppointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointment];

  private isAttached: boolean = false;
  private availablePositions: Array<ProcessTaskPosition> = [];
  protected categorizedPositions: Array<PositionCategoryGroup> = [];

  protected positionCategoryGroupIdGetter: (
    categoryGroup: PositionCategoryGroup
  ) => string | null = (p) => p.categoryId;

  protected positionCategoryItemCountGetter: (
    items: Array<PositionCategoryGroup>
  ) => number = (items) =>
    items.reduce((sum, group) => sum + group.positions.length, 0);

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskAppointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskAppointment,
        context: this as ProcessTaskAppointmentPositionsWidget,
        propertyName: 'processTaskAppointment'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this.updateAvailablePositions.bind(this)
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'processTaskAppointment.processConfigurationStepId',
      this.updateAvailablePositions.bind(this)
    );
    this.updateAvailablePositions();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected processTaskAppointmentChanged(): void {
    if (this.isAttached) {
      this.updateAvailablePositions();
    }
  }

  private updateAvailablePositions(): void {
    // only let the user select the positions when it has selected a step (to enforce the user to select a step)
    if (
      this.processTaskAppointment &&
      this.processTaskAppointment.processConfigurationStepId
    ) {
      const positions =
        this.entityManager.processTaskPositionRepository.getByProcessTaskIdWithoutSnapshots(
          this.processTaskAppointment.ownerProcessTaskId
        );
      this.availablePositions =
        ProcessTaskPositionUtils.sortProcessTaskPositions(positions);
    } else {
      this.availablePositions = [];
    }

    this.updateCategorizedPositions();
  }

  private updateCategorizedPositions(): void {
    this.categorizedPositions = ProcessTaskPositionUtils.categorizePositions(
      this.availablePositions
    );
  }

  protected handleProcessConfigurationStepIdChanged(): void {
    if (this.processTaskAppointment) {
      this.entityManager.processTaskAppointmentRepository.update(
        this.processTaskAppointment
      );
    }
  }
}
