import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { CheckboxCheckedChangedEvent } from '../../../aureliaComponents/expandable-dual-row-compact-list-item/expandable-dual-row-compact-list-item';
import { Dialogs } from '../../../classes/Dialogs';
import { DomEventHelper } from '../../../classes/DomEventHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskAppointmentToProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskAppointmentToProcessTaskPosition/types';
import { ProcessTaskGroup } from '../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { computed } from '../../../hooks/computed';
import { expression, model } from '../../../hooks/dependencies';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { ProcessTaskLoggingService } from '../../../services/ProcessTaskLoggingService';
import { EditProcessTaskPositionDialog } from '../../edit-process-task-position-dialog/edit-process-task-position-dialog';

@autoinject()
export class ProcessTaskAppointmentPositionsWidgetListItem {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processTaskPosition: ProcessTaskPosition | null = null;

  @subscribableLifecycle()
  protected readonly processTaskAppointmentToProcessTaskPositionPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointmentToProcessTaskPosition];

  @subscribableLifecycle()
  protected readonly processTaskAppointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointment];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    permissionsService: PermissionsService
  ) {
    this.processTaskAppointmentToProcessTaskPositionPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskAppointmentToProcessTaskPosition,
        context: this as ProcessTaskAppointmentPositionsWidgetListItem,
        expression: 'processTaskAppointmentToProcessTaskPosition'
      });

    this.processTaskAppointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskAppointment,
        context: this as ProcessTaskAppointmentPositionsWidgetListItem,
        propertyName: 'processTaskAppointment'
      });
  }

  protected handleCheckedChanged(event: CheckboxCheckedChangedEvent): void {
    assertNotNullOrUndefined(
      this.processTaskAppointment,
      "can't ProcessTaskAppointmentPositionsWidgetListItem.handleCheckedChanged without a processTaskAppointment"
    );
    assertNotNullOrUndefined(
      this.processTaskPosition,
      "can't ProcessTaskAppointmentPositionsWidgetListItem.handleCheckedChanged without a processTaskPosition"
    );

    if (event.detail.checked) {
      if (!this.processTaskAppointmentToProcessTaskPosition) {
        this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.create(
          {
            processTaskPositionId: this.processTaskPosition.id,
            processTaskAppointmentId: this.processTaskAppointment.id,
            ownerProcessTaskGroupId:
              this.processTaskPosition.ownerProcessTaskGroupId,
            ownerProcessTaskId: this.processTaskPosition.ownerProcessTaskId,
            ownerUserGroupId: this.processTaskPosition.ownerUserGroupId
          }
        );
      }
    } else {
      if (this.processTaskAppointmentToProcessTaskPosition) {
        this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.delete(
          this.processTaskAppointmentToProcessTaskPosition
        );
      }
    }
    this.processTaskLoggingService.logProcessTaskSubEntityModified({
      entityName: EntityName.ProcessTaskAppointment,
      entity: this.processTaskAppointment,
      property: null,
      displayNameAtLogTime: this.processTaskAppointment.name
    });
  }

  protected handleEditPositionButtonClicked(
    position: ProcessTaskPosition
  ): void {
    void EditProcessTaskPositionDialog.open({
      position: position,
      onDialogClosed: () => {}
    });
  }

  protected handleDeletePositionButtonClicked(
    position: ProcessTaskPosition
  ): void {
    void Dialogs.deleteEntityDialog(position).then(() => {
      this.entityManager.processTaskPositionRepository.delete(position);
      void this.processTaskLoggingService.logProcessTaskSubEntityDeleted({
        entityName: EntityName.ProcessTaskPosition,
        entity: position,
        displayNameAtLogTime: position.name
      });
    });
  }

  protected handleNavigationTriggered(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'navigation-triggered',
      detail: null,
      bubbles: true
    });
  }

  @computed(
    model(EntityName.ProcessTaskAppointmentToProcessTaskPosition),
    expression('processTaskAppointment.id'),
    expression('processTaskPosition.id')
  )
  protected get processTaskAppointmentToProcessTaskPosition(): ProcessTaskAppointmentToProcessTaskPosition | null {
    if (!this.processTaskAppointment || !this.processTaskPosition) {
      return null;
    }

    return this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskAppointmentIdAndProcessTaskPositionId(
      this.processTaskAppointment.id,
      this.processTaskPosition.id
    );
  }

  @computed(
    expression('processTaskAppointmentToProcessTaskPosition'),
    expression(
      'processTaskAppointmentPermissionsHandle.canCreateProcessTaskAppointmentToProcessTaskPositions'
    ),
    expression(
      'processTaskAppointmentToProcessTaskPositionPermissionsHandle.canDeleteEntity'
    )
  )
  protected get canEditCheckbox(): boolean {
    if (!this.processTaskAppointmentToProcessTaskPosition) {
      return this.processTaskAppointmentPermissionsHandle
        .canCreateProcessTaskAppointmentToProcessTaskPositions;
    }

    return this.processTaskAppointmentToProcessTaskPositionPermissionsHandle
      .canDeleteEntity;
  }
}
