import { TupleArray, TupleArrayWithOtherType } from 'common/Types/utilities';
import {
  ArrayMapCache,
  GetKeyForItem,
  OnItemUpdate
} from '../ArrayMapCache/ArrayMapCache';
import { Disposable } from '../Utils/DisposableContainer';

/**
 * Useful if you need to track disposables parallel to some items.
 * It automatically disposes unused disposables.
 */
export class DisposableItemsCache<TItem, TDisposable extends Disposable> {
  private readonly cache: ArrayMapCache<TItem, TDisposable>;

  constructor({
    createDisposableForItem,
    getKeyForItem,
    onItemUpdate
  }: {
    createDisposableForItem: CreateDisposableForItem<TItem, TDisposable>;
    getKeyForItem?: GetKeyForItem<TItem>;
    onItemUpdate?: OnItemUpdate<TItem, TDisposable>;
  }) {
    this.cache = new ArrayMapCache({
      createMappedItem: ({ item }) => createDisposableForItem({ item }),
      getKeyForItem,
      onItemUpdate,
      onItemRemoved: ({ mappedItem }) => {
        mappedItem.dispose();
      }
    });
  }

  /**
   * Maps the items to disposables.
   * If there already is an disposable, then the existing one will be reused.
   * If there is none, a new one will be generated.
   *
   * Disposables for items which are not in this array will be disposed and discarded.
   */
  public mapItems<TItems extends Array<TItem>>({
    items
  }: {
    items: TupleArray<TItems>;
  }): TupleArrayWithOtherType<TItems, TDisposable> {
    return this.cache.mapItems<TItems>({ items });
  }

  /**
   * Removes all items from the cache and disposes them
   */
  public disposeAndClear(): void {
    this.cache.clear();
  }
}

export type CreateDisposableForItem<
  TItem,
  TDisposable extends Disposable
> = (options: { item: TItem }) => TDisposable;
