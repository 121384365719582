import { EntityInfo } from '@record-it-npm/synchro-common';
import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { structureTemplateEntryPropertyEntityInfo } from '../../../../classes/EntityManager/entities/StructureTemplateEntryProperty/structureTemplateEntryPropertyEntityInfo';
import { StructureTemplateEntryProperty } from '../../../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import {
  CanEditFieldFieldName,
  EntityAdapter,
  SubscribeOptions
} from '../EntityAdapter';
import { StructureTemplateAdapter } from '../StructureTemplateAdapter/StructureTemplateAdapter';
import { StructureTemplateEntryAdapter } from '../StructureTemplateEntryAdapter/StructureTemplateEntryAdapter';

export class StructureTemplateEntryPropertyAdapter
  implements EntityAdapter<StructureTemplateEntryProperty>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private structureTemplateAdapter: StructureTemplateAdapter | null = null;

  private structureTemplateEntryAdapter: StructureTemplateEntryAdapter | null =
    null;

  constructor(options: StructureTemplateEntryPropertyAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.StructureTemplate,
        onNextAdapter: ({ adapter }) => {
          this.structureTemplateAdapter = adapter;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.StructureTemplateEntry,
        onNextAdapter: ({ adapter }) => {
          this.structureTemplateEntryAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.structureTemplateAdapter != null &&
      this.structureTemplateAdapter.getDependenciesAreLoaded() &&
      this.structureTemplateEntryAdapter != null &&
      this.structureTemplateEntryAdapter.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(
    structureTemplateEntryProperty: StructureTemplateEntryProperty
  ): boolean {
    return this.canEditStructureTemplateEntryProperty({
      structureTemplateEntryProperty,
      editableStructureTemplateStatuses: [
        StructureTemplateStatus.DRAFT,
        StructureTemplateStatus.PROVISIONALLY_ACTIVE
      ]
    });
  }

  public canEditField(
    structureTemplateEntryProperty: StructureTemplateEntryProperty,
    fieldName: CanEditFieldFieldName<StructureTemplateEntryProperty>
  ): boolean {
    if (
      fieldName === 'value' ||
      fieldName === 'excludeFromFlawNumberComputationForValues'
    ) {
      return this.canEditStructureTemplateEntryProperty({
        structureTemplateEntryProperty,
        editableStructureTemplateStatuses: [
          StructureTemplateStatus.DRAFT,
          StructureTemplateStatus.PROVISIONALLY_ACTIVE,
          StructureTemplateStatus.ACTIVE
        ]
      });
    }

    // TODO: better api?
    return this.canEditStructureTemplateEntryProperty({
      structureTemplateEntryProperty,
      editableStructureTemplateStatuses: [
        StructureTemplateStatus.DRAFT,
        StructureTemplateStatus.PROVISIONALLY_ACTIVE
      ]
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.StructureTemplateEntryProperty,
    StructureTemplateEntryProperty
  > {
    return structureTemplateEntryPropertyEntityInfo;
  }

  private canEditStructureTemplateEntryProperty({
    structureTemplateEntryProperty,
    editableStructureTemplateStatuses
  }: {
    structureTemplateEntryProperty: StructureTemplateEntryProperty;
    /**
     * null when editing is allowed in all statuses, an Array for a list of statuses where editing is allowed
     */
    editableStructureTemplateStatuses: Array<StructureTemplateStatus> | null;
  }): boolean {
    if (editableStructureTemplateStatuses) {
      const structureTemplate =
        this.entityManager.structureTemplateRepository.getById(
          structureTemplateEntryProperty.ownerStructureTemplateId
        );

      if (
        !structureTemplate ||
        !editableStructureTemplateStatuses.includes(structureTemplate.status)
      ) {
        return false;
      }
    }

    if (structureTemplateEntryProperty.structureTemplateEntryId) {
      return this.checkPermissionsViaStructureTemplateEntry(
        structureTemplateEntryProperty.structureTemplateEntryId
      );
    }

    return this.checkPermissionsViaStructureTemplate(
      structureTemplateEntryProperty.ownerStructureTemplateId
    );
  }

  private checkPermissionsViaStructureTemplateEntry(
    structureTemplateEntryId: string
  ): boolean {
    if (!this.structureTemplateEntryAdapter) {
      return false;
    }

    const structureTemplateEntry =
      this.entityManager.structureTemplateEntryRepository.getById(
        structureTemplateEntryId
      );

    if (!structureTemplateEntry) {
      return false;
    }

    return this.structureTemplateEntryAdapter.canGenerallyEditStructureTemplateEntryProperties(
      structureTemplateEntry
    );
  }

  private checkPermissionsViaStructureTemplate(
    structureTemplateId: string
  ): boolean {
    if (!this.structureTemplateAdapter) {
      return false;
    }

    const structureTemplate =
      this.entityManager.structureTemplateRepository.getById(
        structureTemplateId
      );

    if (!structureTemplate) {
      return false;
    }

    return this.structureTemplateAdapter.canGenerallyEditStructureTemplateEntryProperties(
      structureTemplate
    );
  }
}

export type StructureTemplateEntryPropertyAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
