import { DateUtils } from 'common/DateUtils';

/**
 * Detect changes of a date range and automatically sanitize/improve the result
 * e.g. changing the dateFrom and automatically adapt the dateTo
 *
 * @template {Object} T
 */
export class DateRangeChangeHandler {
  /** @type {TDateRangeChangeHandlerOptions<T>} */
  _options;
  /** @type {T|null} */
  _model = null;

  /** @type {Date|null} */
  _oldDateFrom = null;
  /** @type {Date|null} */
  _oldDateTo = null;

  /**
   * @param {TDateRangeChangeHandlerOptions<T>} options
   */
  constructor(options) {
    this._options = options;
  }

  /**
   * the data in the date(From|To)PropertyName must be parsable by the Date constructor
   *
   * @param {T|null} model
   */
  setModel(model) {
    this._model = model;
    this.acceptCurrentModelState();
  }

  /**
   * call this after changing the dateFrom/dateTo from the model
   */
  autoAdjustAndAccept() {
    this.autoAdjust();
    this.acceptCurrentModelState();
  }

  autoAdjust() {
    if (!this._model) {
      return;
    }

    const newDateFrom = this._getDateFromDate();
    const newDateTo = this._getDateToDate();
    const dateFromChanged = !DateUtils.datesAreEqual(
      newDateFrom,
      this._oldDateFrom
    );
    const dateToChanged = !DateUtils.datesAreEqual(newDateTo, this._oldDateTo);

    if (dateFromChanged === dateToChanged) {
      // there is nothing that we can/should do when nothing or both have been changed
      return;
    }

    if (dateFromChanged) {
      this._autoAdjustDateTo(newDateFrom);
    }

    if (dateToChanged) {
      this._autoAdjustDateFrom(newDateFrom, newDateTo);
    }
  }

  acceptCurrentModelState() {
    this._oldDateFrom = this._getDateFromDate();
    this._oldDateTo = this._getDateToDate();
  }

  /**
   * @param {Date|null} newDateFrom
   */
  _autoAdjustDateTo(newDateFrom) {
    if (newDateFrom == null || this._model == null) {
      return;
    }

    const duration = this._getOldDurationOrDefault();

    this._options.setDateTo(
      this._model,
      this._formatDate(new Date(newDateFrom.getTime() + duration))
    );
  }

  /**
   * @param {Date|null} newDateFrom
   * @param {Date|null} newDateTo
   */
  _autoAdjustDateFrom(newDateFrom, newDateTo) {
    if (newDateTo == null || this._model == null) {
      return;
    }

    if (!newDateFrom || newDateFrom.getTime() > newDateTo.getTime()) {
      const duration = this._getOldDurationOrDefault();
      this._options.setDateFrom(
        this._model,
        this._formatDate(new Date(newDateTo.getTime() - duration))
      );
    }
  }

  /**
   * @returns {number}
   */
  _getOldDurationOrDefault() {
    if (this._oldDateFrom && this._oldDateTo) {
      return this._oldDateTo.getTime() - this._oldDateFrom.getTime();
    }

    return 60 * 60 * 1000; // 1hour
  }

  /**
   * @returns {Date|null}
   */
  _getDateFromDate() {
    /** @type {string|null} */
    let dateString = null;

    if (this._model) {
      dateString = this._options.getDateFrom(this._model);
    }

    return dateString ? new Date(dateString) : null;
  }

  /**
   * @returns {Date|null}
   */
  _getDateToDate() {
    /** @type {string|null} */
    let dateString = null;

    if (this._model) {
      dateString = this._options.getDateTo(this._model);
    }

    return dateString ? new Date(dateString) : null;
  }

  /**
   * @param {Date|null} date
   * @returns {string|null}
   */
  _formatDate(date) {
    switch (this._options.dateFormat) {
      default:
      case 'iso':
        return date ? date.toISOString() : null;
    }
  }
}

/**
 * @typedef {{
 *  getDateFrom: (appointmentData: T) => string|null,
 *  getDateTo: (appointmentData: T) => string|null,
 *  setDateFrom: (appointmentData: T, dateFrom: string|null) => void,
 *  setDateTo: (appointmentData: T, dateTo: string|null) => void,
 *  dateFormat: string
 * }} TDateRangeChangeHandlerOptions
 * @template T
 */
