import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { PictureSketchingService } from '../../services/PictureSketchingService/PictureSketchingService';
import {
  SavePictureCallbackAnyOptions,
  Type
} from '../ultra-rapid-fire-widget/ultra-rapid-fire-widget';
import { TUltraRapidFireWidgetOverlayConfiguration } from '../ultra-rapid-fire-widget/ultra-rapid-fire-widget-overlay';
import {
  PersonSelectOptions,
  RegionIdChangedEvent,
  WidgetConfiguration,
  WidgetType
} from '../ultra-rapid-fire-widget/ultra-rapid-fire-widget-overlay-widget';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { RecordItFullScreenDialog } from '../../dialogs/record-it-full-screen-dialog/record-it-full-screen-dialog';
import { TagIdsChangedEvent } from '../ultra-rapid-fire-widget/urfw-categorized-tags-button/urfw-categorized-tags-button';
import { SelectThingPictureDialog } from '../../dialogs/select-thing-picture-dialog/select-thing-picture-dialog';
import { SelectCoordinatesOnPositionedPictureOverlay } from '../../dialogs/select-coordinates-on-positioned-picture-overlay/select-coordinates-on-positioned-picture-overlay';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Region } from '../../classes/EntityManager/entities/Region/types';
import {
  Picture,
  PictureAdditionalMarking
} from '../../classes/EntityManager/entities/Picture/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import {
  Property,
  PropertyCreationBaseData
} from '../../classes/EntityManager/entities/Property/types';
import { GlobalElements } from '../global-elements/global-elements';
import { EntryDeletionService } from '../../classes/EntityManager/entities/Entry/EntryDeletionService';
import { PictureFullScreenOverlay } from '../picture-full-screen-overlay/picture-full-screen-overlay';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { configureHooks } from '../../hooks/configureHooks';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { DefectCreationService } from '../../classes/EntityManager/entities/Defect/DefectCreationService';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class UltraRapidFireWidgetEditDialog {
  private dialog: RecordItFullScreenDialog | null = null;

  protected options: DialogOptions | null = null;

  @observable protected selectedPicture: Picture | null;

  private entry: Entry | null = null;

  @observable protected region: Region | null;

  private regionDescriptionProperty: Property | null = null;

  protected regionDescription = '';

  @observable
  private overlayConfiguration: TUltraRapidFireWidgetOverlayConfiguration | null;

  protected widgetConfiguration: Array<WidgetConfiguration> = [];

  protected widgetData: SavePictureCallbackAnyOptions | null = null;

  protected pictureContentElement: HTMLElement | null = null;

  protected personListMultiSelectOptions: PersonSelectOptions | null = null;

  private regionDescriptionPerProject = false;

  protected isApp = false;

  protected isEditingPicture = false;

  protected isOnline = false;

  private subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly selectedPicturePermissionsHandle: EntityNameToPermissionsHandle[EntityName.Picture];

  @subscribableLifecycle()
  protected readonly entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  @subscribableLifecycle()
  protected readonly regionPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Region];

  @subscribableLifecycle()
  protected readonly regionDescriptionPropertyPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Property];

  @subscribableLifecycle()
  private readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  private isOpen = false;

  public static async open(options: DialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly entryDeletionService: EntryDeletionService,
    private socketService: SocketService,
    private activeUserCompanySettingService: ActiveUserCompanySettingService,
    private pictureSketchingService: PictureSketchingService,
    private i18n: I18N,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService,
    private readonly defectCreationService: DefectCreationService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.selectedPicture = null;
    this.region = null;
    this.overlayConfiguration = null;

    this.selectedPicturePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Picture,
        context: this,
        expression: 'selectedPicture'
      });

    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Entry,
        context: this,
        expression: 'entry'
      });

    this.regionPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Region,
        context: this,
        expression: 'region'
      });

    this.regionDescriptionPropertyPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Property,
        context: this,
        expression: 'regionDescriptionProperty'
      });

    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForIdExpressionValue({
        entityName: EntityName.Thing,
        context: this,
        expression: 'options.thingId'
      });
  }

  protected attached(): void {
    this.isApp = DeviceInfoHelper.isApp();
  }

  private open(options: DialogOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open UltraRapidFireWidgetEditDialog without a fullscreenDialog'
    );
    this.options = options;

    this.initializeWidgetData();

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'ultraRapidFireWidget.overlayConfiguration',
        (overlayConfiguration) => {
          this.overlayConfiguration = overlayConfiguration;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'via.regionDescriptionPerProject',
        (regionDescriptionPerProject) => {
          this.regionDescriptionPerProject = regionDescriptionPerProject;
        }
      )
    );

    this.selectedPicture = this.options.picture;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Entry,
      this.updateRegion.bind(this)
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Region,
      this.updateRegion.bind(this)
    );
    this.updateRegion();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );
    this.updateProperties();

    this.subscriptionManager.subscribeToDomEvent(
      document,
      'keydown',
      this.handleKeyPress.bind(this)
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.isOpen = true;
    this.dialog.open();
  }

  private initializeWidgetData(): void {
    if (!this.options) return;

    const type = this.options.type;

    switch (type) {
      case Type.GALLERY_THING:
        const thingOptions = this.options as ThingOptions;

        this.widgetData = {
          type: Type.GALLERY_THING,
          thingId: thingOptions.thingId,
          ownerUserGroupId: this.options.userGroupId,
          properties: [],
          tagIds: []
        };
        break;

      case Type.PROJECT:
        const projectOptions = this.options as ProjectOptions;

        this.widgetData = {
          type: Type.PROJECT,
          projectId: projectOptions.projectId,
          ownerUserGroupId: this.options.userGroupId,
          properties: [],
          tagIds: []
        };
        break;

      default:
        throw new Error(`invalid type "${type}"`);
    }
  }

  protected regionChanged(): void {
    if (!this.widgetData || !this.region) return;
    this.widgetData.regionId = this.region.id;
  }

  protected overlayConfigurationChanged(): void {
    this.updateWidgetConfiguration();
  }

  protected selectedPictureChanged(): void {
    this.updatePictureMarking();
    this.updateEntry();
    this.updateRegion();
    this.updateProperties();
  }

  protected async handleDialogClosed(): Promise<void> {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot close UltraRapidFireWidgetEditDialog without a fullscreenDialog'
    );

    this.isOpen = false;
    this.resetDialog();

    this.subscriptionManager.disposeSubscriptions();
  }

  private resetDialog(): void {
    this.selectedPicture = null;
  }

  private handleKeyPress(evt: KeyboardEvent): void {
    if (!DomEventHelper.canUseArrowKeys(evt)) return;

    switch (evt.key) {
      case 'ArrowLeft':
        return this.selectPrevPicture();
      case 'ArrowRight':
        return this.selectNextPicture();
      default:
        break;
    }
  }

  private selectPrevPicture(): void {
    if (this.availablePictures.length > 1 && this.selectedPicture) {
      let selectedPictureIndex = this.availablePictures.findIndex(
        (v) => v === this.selectedPicture
      );
      selectedPictureIndex =
        selectedPictureIndex !== 0 ? selectedPictureIndex - 1 : 0;
      this.selectedPicture =
        this.availablePictures[selectedPictureIndex] ?? null;
    }
  }

  private selectNextPicture(): void {
    if (this.availablePictures.length > 1 && this.selectedPicture) {
      let selectedPictureIndex = this.availablePictures.findIndex(
        (v) => v === this.selectedPicture
      );
      const lastPictureIndex = this.availablePictures.length - 1;
      selectedPictureIndex =
        selectedPictureIndex !== lastPictureIndex
          ? selectedPictureIndex + 1
          : lastPictureIndex;
      this.selectedPicture =
        this.availablePictures[selectedPictureIndex] ?? null;
    }
  }

  protected handlePictureChanged(): void {
    if (this.selectedPicture)
      this.entityManager.pictureRepository.update(this.selectedPicture);
  }

  protected handleRegionDescriptionChanged(): void {
    if (this.regionDescriptionProperty) {
      this.regionDescriptionProperty.value = this.regionDescription;
      this.entityManager.propertyRepository.update(
        this.regionDescriptionProperty
      );
    } else if (this.region) {
      this.region.description = this.regionDescription;
      this.entityManager.regionRepository.update(this.region);
    }
  }

  protected handleTagIdsChanged(event: TagIdsChangedEvent): void {
    if (!this.selectedPicture || !this.widgetData) return;

    this.selectedPicture.tagIds = event.detail.tagIds;
    this.widgetData.tagIds = event.detail.tagIds;
    this.entityManager.pictureRepository.update(this.selectedPicture);
  }

  protected handleRegionIdChanged(event: RegionIdChangedEvent): void {
    if (!this.entry) return;
    this.entry.regionId = event.detail.regionId;
    this.entityManager.entryRepository.update(this.entry);

    this.updateRegion();
  }

  protected handleDeletePictureClick(): void {
    const entry = this.entry;
    if (!entry) return;

    void this.entryDeletionService.deleteEntryWithDialog(entry);
  }

  protected handleSketchPictureClick(): void {
    if (this.selectedPicture) {
      this.pictureSketchingService.sketchPicture(this.selectedPicture);
    }
  }

  protected handleStartEditingPictureClick(): void {
    this.isEditingPicture = true;
  }

  protected handleSelectedPictureClick(): void {
    assertNotNullOrUndefined(
      this.selectedPicture,
      'cannot handleSelectedPictureClick without selectedPicture'
    );

    void PictureFullScreenOverlay.open({
      picture: this.selectedPicture
    });
  }

  protected handlePictureEditorStopped(): void {
    this.isEditingPicture = false;
  }

  private updateEntry(): void {
    if (!this.selectedPicture || !this.selectedPicture.entry) {
      this.entry = null;
      return;
    }
    const entry = this.entityManager.entryRepository.getById(
      this.selectedPicture.entry
    );
    this.entry = entry || null;
  }

  private updateRegion(): void {
    if (this.entry && this.entry.regionId) {
      this.region =
        this.entityManager.regionRepository.getById(this.entry.regionId) ||
        null;
      this.regionDescriptionProperty = this.regionDescriptionPerProject
        ? this.getOrCreateRegionDescriptionProperty(this.entry)
        : null;
    } else {
      this.region = null;
      this.regionDescriptionProperty = null;
    }

    this.updateRegionDescription();

    if (this.widgetData)
      this.widgetData.regionId = this.region ? this.region.id : '';
  }

  private getOrCreateRegionDescriptionProperty(entry: Entry): Property {
    assertNotNullOrUndefined(
      entry.regionId,
      "can't UltraRapidFireWIdgetEditDialog.getOrCreateRegionDescriptionProperty without a regionId"
    );

    const property = this.entityManager.propertyRepository
      .getByRegionId(entry.regionId)
      .find((prop) => prop.name === 'description' + entry.ownerProjectId);
    if (property) {
      return property;
    } else {
      return this.entityManager.propertyRepository.create({
        name: 'description' + entry.ownerProjectId,
        value: '',
        hidden: true,
        regionId: entry.regionId,
        ownerUserGroupId: entry.ownerUserGroupId
      });
    }
  }

  private updateRegionDescription(): void {
    if (this.regionDescriptionProperty) {
      this.regionDescription = this.regionDescriptionProperty.value || '';
    } else if (this.region) {
      this.regionDescription = this.region.description || '';
    } else {
      this.regionDescription = '';
    }
  }

  private updateProperties(): void {
    if (!this.entry || !this.widgetData) return;

    const entryProperties = this.entityManager.propertyRepository.getByEntryId(
      this.entry.id
    );
    this.widgetData.properties = entryProperties;
    this.widgetData.tagIds = this.selectedPicture?.tagIds;
  }

  private updatePictureMarking(): void {
    if (!this.options || !this.selectedPicture || !this.widgetData) return;
    if (this.options.type !== Type.GALLERY_THING) {
      // picture marking is only supported by the galleryThing
      return;
    }

    const galleryThingPictures =
      this.entityManager.pictureRepository.getByGalleryThingId(
        this.options.thingId
      );
    if (!galleryThingPictures.length) {
      this.widgetData.additionalMarkings = null;
      return;
    }

    if (!this.selectedPicture.additional_markings) return;

    const markings = this.selectedPicture.additional_markings;
    if (!markings) {
      this.widgetData.additionalMarkings = null;
    } else {
      this.widgetData.additionalMarkings = markings;
    }
  }

  @computed(
    model(EntityName.Picture),
    expression('options'),
    expression('isOpen')
  )
  protected get availablePictures(): Array<Picture> {
    if (!this.options || !this.isOpen) {
      return [];
    }
    const type = this.options.type;

    switch (type) {
      case Type.GALLERY_THING:
        const thingOptions = this.options as ThingOptions;
        const projectId =
          thingOptions.picture.ownerProjectId ?? thingOptions.picture.project;
        if (!projectId) return [];

        return this.getAvailablePicturesOfProjectId(projectId);
      case Type.PROJECT:
        const projectOptions = this.options as ProjectOptions;
        return this.getAvailablePicturesOfProjectId(projectOptions.projectId);
      default:
        throw new Error(`invalid type "${type}"`);
    }
  }

  private getAvailablePicturesOfProjectId(projectId: string): Array<Picture> {
    const pictures =
      this.entityManager.pictureRepository.getAllPicturesByProjectId(projectId);
    return pictures.filter((picture) => {
      return picture.entry != null;
    });
  }

  private updateWidgetConfiguration(): void {
    const widgetConfiguration: Array<WidgetConfiguration> = [];
    const overlayConfigurationWidgets =
      (this.overlayConfiguration && this.overlayConfiguration.widgets) || [];

    overlayConfigurationWidgets.forEach((widgetConfig) => {
      if (
        'widgetType' in widgetConfig &&
        widgetConfig.widgetType === WidgetType.PERSON_MULTI_SELECT
      ) {
        this.personListMultiSelectOptions = widgetConfig.options;
        return;
      }
      widgetConfiguration.push(widgetConfig);
    });

    this.widgetConfiguration = widgetConfiguration;
  }

  protected handlePropertyValueChanged(
    property: PropertyCreationBaseData
  ): void {
    if (!this.entry) return;

    const entryProperties = this.entityManager.propertyRepository.getByEntryId(
      this.entry.id
    );
    const propertyToChange = entryProperties.find((p) => {
      return p.name === property.name && p.type === property.type;
    });

    if (!propertyToChange) {
      this.entityManager.propertyRepository.create({
        entry: this.entry.id,
        ownerUserGroupId: this.entry.ownerUserGroupId,
        ownerProjectId: this.entry.ownerProjectId,
        alwaysVisible: true,
        ...property
      });
    } else {
      propertyToChange.value = property.value ?? null;
      this.entityManager.propertyRepository.update(propertyToChange);
    }
  }

  protected handleMarkingChanged(
    markings: Array<PictureAdditionalMarking>
  ): void {
    if (!this.selectedPicture) return;

    if (!this.selectedPicture.additional_markings)
      this.selectedPicture.additional_markings = [];

    for (const marking of markings) {
      const markingIndex = this.selectedPicture.additional_markings.findIndex(
        (m) => m.picture_id === marking.picture_id
      );
      if (markingIndex >= 0) {
        this.selectedPicture.additional_markings[markingIndex] = marking;
      } else {
        this.selectedPicture.additional_markings.push(marking);
      }
      this.entityManager.pictureRepository.update(this.selectedPicture);
    }
  }

  protected async handleMarkPictureClick(): Promise<void> {
    if (!this.options || this.options.type !== Type.GALLERY_THING) return;
    if (!this.selectedPicture) return;

    const thingId = this.options.thingId;
    const picture = this.selectedPicture;

    const thingPictures =
      this.entityManager.pictureRepository.getByGalleryThingId(thingId);
    let selectedThingPicture: Picture | null = null;
    if (thingPictures.length === 1 && thingPictures[0]) {
      selectedThingPicture = thingPictures[0];
    } else {
      selectedThingPicture = await new Promise((res) => {
        void SelectThingPictureDialog.open({
          thingId: thingId,
          onDialogClosed: (p) => res(p)
        });
      });
    }

    if (!selectedThingPicture) return;
    void SelectCoordinatesOnPositionedPictureOverlay.open({
      oldPosition: picture.coords,
      picture: selectedThingPicture,
      pictureNotPositionedText: this.i18n.tr(
        'galleryThing.thingPictureIsNotPositioned'
      ),
      onCoordinateSelected: ({ coords, coordsFromPositionedPictureInfo }) => {
        if (!this.selectedPicture) return;
        this.selectedPicture.coords = coords;
        this.selectedPicture.coordsFromPositionedPictureInfo =
          coordsFromPositionedPictureInfo;
        this.handlePictureChanged();
      }
    });
  }

  @computedFrom(
    'regionDescriptionPropertyPermissionsHandle.canEditField.value',
    'regionPermissionsHandle.canEditField.description'
  )
  protected get canEditRegionDescription(): boolean {
    if (this.regionDescriptionProperty) {
      return this.regionDescriptionPropertyPermissionsHandle.canEditField.value;
    }

    return this.regionPermissionsHandle.canEditField.description;
  }

  @computedFrom('options.type', 'thingPermissionsHandle.canCreateDefects')
  protected get canCreateDefectFromPicture(): boolean {
    return (
      this.options?.type === Type.GALLERY_THING &&
      this.thingPermissionsHandle.canCreateDefects
    );
  }

  protected async handleCreateDefectFromPictureClick(): Promise<void> {
    assertNotNullOrUndefined(
      this.options,
      'cannot create defect without dialog options'
    );

    if (this.options.type !== Type.GALLERY_THING)
      throw new Error('cannot create defect in a project');

    assertNotNullOrUndefined(
      this.selectedPicture,
      'cannot create defect without a selected picture'
    );

    await this.defectCreationService.createDefectWithExistingPictures({
      creationEntity: {
        ownerUserGroupId: this.options.userGroupId,
        ownerThingId: this.options.thingId
      },
      pictures: [this.selectedPicture]
    });
  }
}

type BasicOptions = {
  userGroupId: string;
  picture: Picture;
};

type ThingOptionsExtension = {
  type: Type.GALLERY_THING;
  thingId: string;
};

type ProjectOptionsExtension = {
  type: Type.PROJECT;
  projectId: string;
};

type ThingOptions = BasicOptions & ThingOptionsExtension;

type ProjectOptions = BasicOptions & ProjectOptionsExtension;

type DialogOptions = ThingOptions | ProjectOptions;
