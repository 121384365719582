import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { structureTemplateRatingCategoryEntityInfo } from '../../../../classes/EntityManager/entities/StructureTemplateRatingCategory/structureTemplateRatingCategoryEntityInfo';
import { StructureTemplateRatingCategory } from '../../../../classes/EntityManager/entities/StructureTemplateRatingCategory/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { StructureTemplateAdapter } from '../StructureTemplateAdapter/StructureTemplateAdapter';

export class StructureTemplateRatingCategoryAdapter
  implements EntityAdapter<StructureTemplateRatingCategory>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private structureTemplateAdapter: StructureTemplateAdapter | null = null;

  constructor(options: StructureTemplateRatingCategoryAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.StructureTemplate,
        onNextAdapter: ({ adapter }) => {
          this.structureTemplateAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.structureTemplateAdapter != null &&
      this.structureTemplateAdapter.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(
    structureTemplateRatingCategory: StructureTemplateRatingCategory
  ): boolean {
    return this.canEditStructureTemplateRatingCategory(
      structureTemplateRatingCategory
    );
  }

  public canEditField(
    structureTemplateRatingCategory: StructureTemplateRatingCategory
  ): boolean {
    return this.canEditStructureTemplateRatingCategory(
      structureTemplateRatingCategory
    );
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.StructureTemplateRatingCategory,
    StructureTemplateRatingCategory
  > {
    return structureTemplateRatingCategoryEntityInfo;
  }

  private canEditStructureTemplateRatingCategory(
    structureTemplateRatingCategory: StructureTemplateRatingCategory
  ): boolean {
    if (!this.structureTemplateAdapter) {
      return false;
    }

    const structureTemplate =
      this.entityManager.structureTemplateRepository.getById(
        structureTemplateRatingCategory.ownerStructureTemplateId
      );

    if (!structureTemplate) {
      return false;
    }

    return this.structureTemplateAdapter.canEditStructureTemplateRatingCategories(
      structureTemplate
    );
  }
}

export type StructureTemplateRatingCategoryAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
