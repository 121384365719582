import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { pictureRevisionEntityInfo } from '../../../../classes/EntityManager/entities/PictureRevision/pictureRevisionEntityInfo';
import { PictureRevision } from '../../../../classes/EntityManager/entities/PictureRevision/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { PictureAdapter } from '../PictureAdapter/PictureAdapter';

export class PictureRevisionAdapter implements EntityAdapter<PictureRevision> {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private pictureAdapter: PictureAdapter | null = null;

  constructor(options: PictureRevisionAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.Picture,
        onNextAdapter: ({ adapter }) => {
          this.pictureAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.pictureAdapter != null &&
      this.pictureAdapter.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(pictureRevision: PictureRevision): boolean {
    return this.canEditPictureRevision(pictureRevision);
  }

  public canEditField(pictureRevision: PictureRevision): boolean {
    return this.canEditPictureRevision(pictureRevision);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.PictureRevision,
    PictureRevision
  > {
    return pictureRevisionEntityInfo;
  }

  private canEditPictureRevision(pictureRevision: PictureRevision): boolean {
    if (!this.pictureAdapter) {
      return false;
    }

    const picture = this.entityManager.pictureRepository.getById(
      pictureRevision.pictureId
    );

    if (!picture) {
      return false;
    }

    return this.pictureAdapter.canEditPictureRevisions(picture);
  }
}

export type PictureRevisionAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
