import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskAppointmentToProcessTaskPosition } from '../../../../classes/EntityManager/entities/ProcessTaskAppointmentToProcessTaskPosition/types';
import { ProcessTaskGroup } from '../../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { computed } from '../../../../hooks/computed';
import { subscribableLifecycle } from '../../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ShowProcessAppointmentPositionListItem {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processTaskPosition: ProcessTaskPosition | null = null;

  @bindable()
  public showPrice: boolean = false;

  @subscribableLifecycle()
  protected readonly processTaskAppointmentToProcessTaskPositionPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointmentToProcessTaskPosition];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processTaskAppointmentToProcessTaskPositionPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskAppointmentToProcessTaskPosition,
        context: this as ShowProcessAppointmentPositionListItem,
        expression: 'processTaskAppointmentToProcessTaskPosition'
      });
  }

  protected handlePositionCheckboxChanged(): void {
    const processTaskAppointmentToProcessTaskPosition =
      this.processTaskAppointmentToProcessTaskPosition;
    assertNotNullOrUndefined(
      processTaskAppointmentToProcessTaskPosition,
      "can't ShowProcessAppointmentPositionListItem.handlePositionCheckboxChanged without processTaskAppointmentToProcessTaskPosition"
    );

    this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.update(
      processTaskAppointmentToProcessTaskPosition
    );
  }

  @computed()
  protected get processTaskAppointmentToProcessTaskPosition(): ProcessTaskAppointmentToProcessTaskPosition | null {
    if (!this.processTaskAppointment || !this.processTaskPosition) {
      return null;
    }

    return this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskAppointmentIdAndProcessTaskPositionId(
      this.processTaskAppointment.id,
      this.processTaskPosition.id
    );
  }
}
