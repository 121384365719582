import { computedFrom } from 'aurelia-framework';

import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import {
  PropertyOption,
  PropertyType
} from 'common/Types/Entities/Property/PropertyDto';

import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { ThingTypeProperty } from '../../../../classes/EntityManager/entities/Property/types';
import {
  PropertyDefinitionWidgetHandle,
  PropertyFeatures
} from '../PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import {
  createThingTypePropertyDefinitionWidgetConfiguration,
  ThingTypePropertyDefinitionWidgetConfiguration
} from '../../../base-property-widget/config/thingTypePropertyDefinitionWidgetConfiguration/createThingTypePropertyDefinitionWidgetConfiguration';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';

export class ThingTypePropertyDefinitionHandle
  implements PropertyDefinitionWidgetHandle<ThingTypeProperty>
{
  private readonly entityManager: AppEntityManager;
  private readonly property: ThingTypeProperty;
  private readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Property];

  constructor(options: {
    entityManager: AppEntityManager;
    permissionsService: PermissionsService;
    property: ThingTypeProperty;
  }) {
    this.entityManager = options.entityManager;
    this.property = options.property;

    this.permissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Property,
        entity: options.property
      });
  }

  public subscribe(): Disposable {
    const permissionsDisposable = this.permissionsHandle.subscribe();

    return {
      dispose: () => {
        permissionsDisposable.dispose();
      }
    };
  }

  @computedFrom('property.name')
  public get name(): string | null {
    return this.property.name;
  }

  public setName(name: string | null): void {
    this.property.name = name;
    this.entityManager.propertyRepository.update(this.property);
  }

  @computedFrom('property.type')
  public get type(): PropertyType {
    return PropertyHelper.getTypeOrDefault(this.property.type);
  }

  public setType(type: PropertyType): void {
    this.property.type = type;
    this.entityManager.propertyRepository.update(this.property);
  }

  @computedFrom('property.choices')
  public get choices(): Array<string> {
    return this.property.choices;
  }

  public setChoices(choices: Array<string>): void {
    this.property.choices = choices;
    this.entityManager.propertyRepository.update(this.property);
  }

  @computedFrom('property.options')
  public get options(): Array<PropertyOption> {
    return this.property.options;
  }

  public setOptions(options: Array<PropertyOption>): void {
    this.property.options = options;
    this.entityManager.propertyRepository.update(this.property);
  }

  @computedFrom('permissionsHandle.canEditField.name')
  public get canEditName(): boolean {
    return this.permissionsHandle.canEditField.name;
  }

  @computedFrom('permissionsHandle.canEditField.type')
  public get canEditType(): boolean {
    return this.permissionsHandle.canEditField.type;
  }

  @computedFrom('permissionsHandle.canEditField.choices')
  public get canEditChoices(): boolean {
    return this.permissionsHandle.canEditField.choices;
  }

  @computedFrom('permissionsHandle.canEditField.options')
  public get canEditOptions(): boolean {
    return this.permissionsHandle.canEditField.options;
  }

  @computedFrom('permissionsHandle.canDeleteEntity')
  public get canBeDeleted(): boolean {
    return this.permissionsHandle.canDeleteEntity;
  }

  public getPropertyDefinition(): ThingTypeProperty {
    return this.property;
  }

  public getPropertyFeatures(): PropertyFeatures {
    return {
      canHavePositions: false,
      canHaveProjectParameter: true,
      canHaveTableEntries: false,
      allowDefaultValues: true
    };
  }

  public createPropertyWidgetConfiguration(): ThingTypePropertyDefinitionWidgetConfiguration | null {
    return createThingTypePropertyDefinitionWidgetConfiguration();
  }
}
