import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import {
  PositionCategoryGroup,
  ProcessTaskPositionUtils
} from '../../../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { ProcessTaskPosition } from '../../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { InstancePreserver } from '../../../../classes/InstancePreserver/InstancePreserver';
import { SubscriptionManager } from '../../../../classes/SubscriptionManager';
import { Utils } from '../../../../classes/Utils/Utils';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { ShowProcessAppointmentConfiguration } from '../../ShowProcessAppointmentConfiguration';

@autoinject()
export class ShowProcessAppointmentAllOfferedPositions {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public configuration: ShowProcessAppointmentConfiguration | null = null;

  @bindable()
  public expanded: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;

  protected processTasksWithOfferedPositions: Array<ProcessTaskWithOfferedPositions> =
    [];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTask,
      this.updateProcessTasksWithOfferedPositions.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTaskPosition,
      this.updateProcessTasksWithOfferedPositions.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this.updateProcessTasksWithOfferedPositions.bind(this)
    );
    this.updateProcessTasksWithOfferedPositions();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskGroupChanged(): void {
    if (this.isAttached) {
      this.updateProcessTasksWithOfferedPositions();
    }
  }

  private updateProcessTasksWithOfferedPositions(): void {
    if (this.processTaskGroup) {
      const positions = this.getOfferedPositions(this.processTaskGroup);
      const positionsByProcessTaskId: PositionsByProcessTaskId = Utils.groupBy(
        positions,
        (position) => position.ownerProcessTaskId
      );

      const processTasksWithOfferedPositions =
        this.generateProcessTasksWithOfferedPositions(positionsByProcessTaskId);

      processTasksWithOfferedPositions.sort((a, b) => {
        return a.processTask.order - b.processTask.order;
      });

      this.processTasksWithOfferedPositions = processTasksWithOfferedPositions;
    } else {
      this.processTasksWithOfferedPositions = [];
    }
  }

  private getOfferedPositions(
    processTaskGroup: ProcessTaskGroup
  ): Array<ProcessTaskPosition> {
    const allPositions =
      this.entityManager.processTaskPositionRepository.getByProcessTaskGroupIdWithoutSnapshots(
        processTaskGroup.id
      );

    const allPositionRelations =
      this.entityManager.processTaskOfferToProcessTaskPositionRepository.getByProcessTaskGroupId(
        processTaskGroup.id
      );
    const positionIdToPositionRelations = Utils.groupBy(
      allPositionRelations,
      (relation) => relation.processTaskPositionId
    );

    const allProcessTaskRelations =
      this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskGroupId(
        processTaskGroup.id
      );
    const processTaskOfferIdToProcessTaskRelations = Utils.groupBy(
      allProcessTaskRelations,
      (relation) => relation.processTaskOfferId
    );

    return allPositions.filter((position) => {
      const positionRelations =
        positionIdToPositionRelations.get(position.id) ?? [];

      // check if the position has a relation to an offer. If it has a relation, we need to check if the offer has a relation to the processTask
      // This is important since the position relations are retained even if the offer is removed from the processTask
      return positionRelations.some((relation) => {
        const processTaskRelations =
          processTaskOfferIdToProcessTaskRelations.get(
            relation.processTaskOfferId
          ) ?? [];
        return processTaskRelations.find(
          (r) => r.ownerProcessTaskId === position.ownerProcessTaskId
        );
      });
    });
  }

  private generateProcessTasksWithOfferedPositions(
    positionsByProcessTaskId: PositionsByProcessTaskId
  ): Array<ProcessTaskWithOfferedPositions> {
    const processTasksWithOfferedPositions: Array<ProcessTaskWithOfferedPositions> =
      [];

    for (const [
      processTaskId,
      positions
    ] of positionsByProcessTaskId.entries()) {
      const processTask =
        this.entityManager.processTaskRepository.getById(processTaskId);
      if (!processTask) {
        console.error(`no processTask found for ${processTaskId}`);
        continue;
      }

      this.addProcessTaskWithOfferedPosition(processTasksWithOfferedPositions, {
        processTask,
        positions,
        categorizedPositions:
          ProcessTaskPositionUtils.categorizePositions(positions)
      });
    }

    return processTasksWithOfferedPositions;
  }

  private addProcessTaskWithOfferedPosition(
    processTasksWithOfferedPositions: Array<ProcessTaskWithOfferedPositions>,
    processTaskWithOfferedPositions: ProcessTaskWithOfferedPositions
  ): void {
    const existingData = this.processTasksWithOfferedPositions.find(
      (item) => item.processTask === processTaskWithOfferedPositions.processTask
    );

    if (existingData) {
      const categorizedPositions = InstancePreserver.createNewArray({
        originalArray: existingData.categorizedPositions,
        newArray: processTaskWithOfferedPositions.categorizedPositions,
        getTrackingValue: (item) => item.categoryId
      });

      // the existing data gets reused here because we don't want aurelia to discard the rendered dom because we have new object instances
      Object.assign(existingData, {
        ...processTaskWithOfferedPositions,
        categorizedPositions // we also want to reuse the categorized positions
      });
      processTasksWithOfferedPositions.push(existingData);
    } else {
      processTasksWithOfferedPositions.push(processTaskWithOfferedPositions);
    }
  }

  protected handleToggleExpandedClick(): void {
    this.expanded = !this.expanded;
  }

  @computedFrom('processTasksWithOfferedPositions')
  protected get positionsCount(): number {
    return this.processTasksWithOfferedPositions.reduce(
      (sum, item) => sum + item.positions.length,
      0
    );
  }
}

type ProcessTaskWithOfferedPositions = {
  processTask: ProcessTask;
  positions: Array<ProcessTaskPosition>;
  categorizedPositions: Array<PositionCategoryGroup>;
};

type PositionsByProcessTaskId = Map<string, Array<ProcessTaskPosition>>;
