import _ from 'lodash';

import { Utils } from './Utils/Utils';
import { Project } from './EntityManager/entities/Project/types';
import { StructureTemplate } from './EntityManager/entities/StructureTemplate/types';
import { AppEntityManager } from './EntityManager/entities/AppEntityManager';
import { StructureTemplateEntry } from './EntityManager/entities/StructureTemplateEntry/types';
import { Entry } from './EntityManager/entities/Entry/types';

export class ShadowEntriesUpdater {
  private project: Project;

  private structureTemplate: StructureTemplate;

  private existingEntries: Array<Entry> = [];
  private originIdToExistingEntryMap: Record<string, Array<Entry>> = {};
  private structureTemplateEntryIdToExistingEntryMap: Record<
    string,
    Array<Entry>
  > = {};

  private structureTemplateEntriesByParentIdMap: Map<
    string | null,
    Array<StructureTemplateEntry>
  > = new Map();

  private structureTemplateEntriesByGroupIdMap: Map<
    string | null,
    Array<StructureTemplateEntry>
  > = new Map();

  constructor(
    private readonly entityManager: AppEntityManager,
    project: Project,
    structureTemplate: StructureTemplate
  ) {
    this.project = project;
    this.structureTemplate = structureTemplate;
  }

  public update(): void {
    this.createStructureTemplateEntriesMaps();

    this.createIdMaps();
    this.removeSuperfluousShadowEntries();

    this.createIdMaps();
    this.addMissingShadowEntries();
  }

  private createIdMaps(): void {
    this.existingEntries = this.entityManager.entryRepository.getByProjectId(
      this.project.id,
      null
    );

    const entriesWithStructureTemplateEntryId = this.existingEntries.filter(
      (e) => e.structureTemplateEntryId
    );
    this.structureTemplateEntryIdToExistingEntryMap = _.groupBy(
      entriesWithStructureTemplateEntryId,
      'structureTemplateEntryId'
    );

    const entriesWithOnlyAnOriginId = this.existingEntries.filter(
      (e) => !e.structureTemplateEntryId && e.originId
    );
    this.originIdToExistingEntryMap = _.groupBy(
      entriesWithOnlyAnOriginId,
      'originId'
    );

    // manually created entries do not have an originId
    delete this.originIdToExistingEntryMap.undefined;
    delete this.originIdToExistingEntryMap.null;

    delete this.structureTemplateEntryIdToExistingEntryMap.undefined;
    delete this.structureTemplateEntryIdToExistingEntryMap.null;
  }

  private addMissingShadowEntries(): void {
    const rootEntries = this.getStructureTemplateEntriesByParentId(null);
    this.walkThroughStructureTemplate(
      rootEntries,
      null,
      (structureTemplateEntry, parent) => {
        const existingEntry = this.getRightEntryFromIdMaps(
          structureTemplateEntry,
          parent
        );
        if (!existingEntry) {
          const newEntry = this.entityManager.entryRepository.create({
            name: structureTemplateEntry.name || '',
            structureTemplateEntryId: structureTemplateEntry.id,
            originId: structureTemplateEntry.originId,
            project: this.project.id,
            ownerProjectId: this.project.id,
            ownerUserGroupId: this.project.usergroup,
            page_depth_parent: parent?.id,
            list_position: structureTemplateEntry.listPosition,
            shadowEntity: true
          });
          return newEntry;
        }
        return existingEntry;
      }
    );
  }

  private walkThroughStructureTemplate(
    structureTemplateEntries: Array<StructureTemplateEntry>,
    parent: Entry | null,
    callback: (
      structureTemplateEntry: StructureTemplateEntry,
      parent: Entry | null
    ) => Entry | null
  ): void {
    structureTemplateEntries.forEach((structureTemplateEntry) => {
      const newParent = callback(structureTemplateEntry, parent);
      if (!newParent) return;

      const relation =
        this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.getByStructureTemplateEntryId(
          structureTemplateEntry.id
        );
      const children = [];
      if (relation) {
        children.push(
          ...this.getStructureTemplateEntriesByGroupId(
            relation.structureTemplateEntryGroupId
          )
        );
      } else {
        children.push(
          ...this.getStructureTemplateEntriesByParentId(
            structureTemplateEntry.id
          )
        );
      }
      if (children.length)
        this.walkThroughStructureTemplate(children, newParent, callback);
    });
  }

  private getRightEntryFromIdMaps(
    structureTemplateEntry: StructureTemplateEntry,
    parentEntry: Entry | null
  ): Entry | null {
    const entriesWithStructureTemplateEntryId = (
      this.structureTemplateEntryIdToExistingEntryMap[
        structureTemplateEntry.id
      ] || []
    ).filter((entry) => entry.page_depth_parent == parentEntry?.id);
    const entriesWithOriginId = structureTemplateEntry.originId
      ? this.originIdToExistingEntryMap[structureTemplateEntry.originId] || []
      : [];
    const entries = [
      ...entriesWithStructureTemplateEntryId,
      ...entriesWithOriginId
    ];
    let entry = entries
      .filter((e) => {
        return !e.onlyLocal;
      })
      .sort((a, b) => {
        return a.id.localeCompare(b.id);
      })[0];
    if (!entry) entry = entries[0];

    return entry || null;
  }

  private removeSuperfluousShadowEntries(): void {
    const rootEntries = this.getStructureTemplateEntriesByParentId(null);
    this.walkThroughStructureTemplate(
      rootEntries,
      null,
      (structureTemplateEntry, parent) => {
        const rightEntry = this.getRightEntryFromIdMaps(
          structureTemplateEntry,
          parent
        );
        if (!rightEntry) return null;

        const entriesWithStructureTemplateEntryId =
          this.structureTemplateEntryIdToExistingEntryMap[
            structureTemplateEntry.id
          ] ?? [];
        const entriesWithOriginId = structureTemplateEntry.originId
          ? (this.originIdToExistingEntryMap[structureTemplateEntry.originId] ??
            [])
          : [];

        const entries = [
          ...entriesWithStructureTemplateEntryId,
          ...entriesWithOriginId
        ];

        entries.forEach((entry) => {
          if (
            entry !== rightEntry &&
            entry.page_depth_parent === rightEntry.page_depth_parent
          ) {
            const children =
              this.entityManager.entryRepository.getSubEntriesOfEntry(entry);
            this.setPageDepthParentOfEntries(children, rightEntry.id);
            this.entityManager.entryRepository.delete(entry);
          }
        });

        return rightEntry;
      }
    );
  }

  private setPageDepthParentOfEntries(
    entries: Array<Entry>,
    pageDepthParent: string
  ): void {
    for (const entry of entries) {
      entry.page_depth_parent = pageDepthParent;
      this.entityManager.entryRepository.update(entry);
    }
  }

  private createStructureTemplateEntriesMaps(): void {
    const structureTemplateEntries =
      this.entityManager.structureTemplateEntryRepository.getByStructureTemplateId(
        this.structureTemplate.id
      );

    this.structureTemplateEntriesByParentIdMap = Utils.groupBy(
      structureTemplateEntries.filter((e) => !e.structureTemplateEntryGroupId),
      (structureTemplateEntry) => structureTemplateEntry.parentEntryId
    );
    this.structureTemplateEntriesByGroupIdMap = Utils.groupBy(
      structureTemplateEntries.filter((e) => !!e.structureTemplateEntryGroupId),
      (structureTemplateEntry) =>
        structureTemplateEntry.structureTemplateEntryGroupId
    );
  }

  private getStructureTemplateEntriesByParentId(
    parentId: string | null
  ): Array<StructureTemplateEntry> {
    return this.structureTemplateEntriesByParentIdMap.get(parentId) ?? [];
  }

  private getStructureTemplateEntriesByGroupId(
    groupId: string
  ): Array<StructureTemplateEntry> {
    return this.structureTemplateEntriesByGroupIdMap.get(groupId) ?? [];
  }
}
