import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { ProcessTaskNote } from '../../../../classes/EntityManager/entities/ProcessTaskNote/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { processTaskNoteEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskNote/processTaskNoteEntityInfo';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { ProcessTaskGroupAuthorizationComputer } from '../../../../computedValues/computers/ProcessTaskGroupAuthorizationComputer/ProcessTaskGroupAuthorizationComputer';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';

export class ProcessTaskNoteAdapter implements EntityAdapter<ProcessTaskNote> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private processTaskIdsWhereUserIsAuthorized: Set<string> | null = null;
  private processTaskGroupIdsWhereUserIsAuthorized: Set<string> | null = null;

  constructor(options: ProcessTaskNoteAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskGroupAuthorizationComputer,
        computeData: {},
        callback: ({
          processTaskIdsWhereUserIsAuthorized,
          processTaskGroupIdsWhereUserIsAuthorized
        }) => {
          this.processTaskIdsWhereUserIsAuthorized =
            processTaskIdsWhereUserIsAuthorized;

          this.processTaskGroupIdsWhereUserIsAuthorized =
            processTaskGroupIdsWhereUserIsAuthorized;

          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.processTaskIdsWhereUserIsAuthorized != null &&
      this.processTaskGroupIdsWhereUserIsAuthorized != null
    );
  }

  public canDeleteEntity(processTaskNote: ProcessTaskNote): boolean {
    return this.checkPermission({
      processTaskNote,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteProcessTaskNotes()
    });
  }

  public canEditField(processTaskNote: ProcessTaskNote): boolean {
    return this.checkPermission({
      processTaskNote,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskNotes()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskNote,
    ProcessTaskNote
  > {
    return processTaskNoteEntityInfo;
  }

  private checkPermission({
    processTaskNote,
    checkRolePermission
  }: {
    processTaskNote: ProcessTaskNote;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (!processTaskNote.processTaskId) {
      return EntityAdapterUtils.checkProcessTaskGroupSubEntityPermission({
        entity: processTaskNote,
        processTaskGroupIdsWhereUserIsAuthorized:
          this.processTaskGroupIdsWhereUserIsAuthorized,
        roleBasedPermissions: this.roleBasedPermissions,
        checkRolePermission
      });
    }

    return EntityAdapterUtils.checkPermissionBasedOnProcessTaskId({
      processTaskId: processTaskNote.processTaskId,
      ownerUserGroupId: processTaskNote.ownerUserGroupId,
      processTaskIdsWhereUserIsAuthorized:
        this.processTaskIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ProcessTaskNoteAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
